import React, { useContext } from 'react';
import { Box, Grid, Typography, Checkbox } from '@material-ui/core/';
import { TranslateTextComponent, capitalizeFirstLetter, getTranslatedDate } from '../../../translator';
import { LanguageContext } from '../../../contexts/LanguageContext';

import assignChairStyle from '../../../styles/table/tableDetail/assignChair';
import { primaryColor } from '../../../styles/theme';

const GuestDetails = ({ item }) => {
  const classes = assignChairStyle();

  const languageContext = useContext(LanguageContext);

  const currentLanguage = languageContext.language;

  return (
    <Box onClick={(e) => e.preventDefault()}>
      <Grid container>
        <Grid item xs>
          <Typography className={classes.titleofdetail}>
            <TranslateTextComponent capitalize>date-of-birth</TranslateTextComponent>:
          </Typography>
          <Typography className={classes.detailOfGuest}>{item.dob ? getTranslatedDate(item.dob, currentLanguage) : '-'}</Typography>
          <Typography className={classes.titleofdetail} style={{ marginTop: 20 }}>
            <TranslateTextComponent capitalize>mob-phone</TranslateTextComponent>:
          </Typography>
          <Typography className={classes.detailOfGuest}>{item.mobilePhone ? item.mobilePhone : '-'}</Typography>
        </Grid>
        <Grid item xs>
          <Typography className={classes.titleofdetail}>
            <TranslateTextComponent capitalize>email</TranslateTextComponent>:
          </Typography>
          <Typography className={classes.detailOfGuest}>{item.email ? item.email : '-'}</Typography>
          <Typography className={classes.titleofdetail} style={{ marginTop: 20 }}>
            <TranslateTextComponent capitalize>local-adv</TranslateTextComponent>:
          </Typography>
          <Typography className={classes.detailOfGuest}>Celina Kail</Typography>
        </Grid>
        <Grid item xs>
          <Typography className={classes.titleofdetail}>
            <TranslateTextComponent capitalize>allergens</TranslateTextComponent>:
          </Typography>
          <Typography className={classes.detailOfGuest}>
            {item.guestsFbAllergens
              ? item.guestsFbAllergens
                  .filter((guestAllergen) => guestAllergen.type === 'allergen')
                  .map((allergen, index) => (
                    <Grid item xs={12} className={classes.details} key={index}>
                      {capitalizeFirstLetter(allergen.fbAllergen.name)}
                    </Grid>
                  ))
              : '-'}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography className={classes.titleofdetail}>
            <TranslateTextComponent capitalize>intolerances</TranslateTextComponent>:
          </Typography>
          <Typography className={classes.detailOfGuest}>
            {item.guestsFbAllergens
              ? item.guestsFbAllergens
                  .filter((guestAllergen) => guestAllergen.type === 'intolerance')
                  .map((allergen, index) => (
                    <Grid item xs={12} className={classes.details} key={index}>
                      {capitalizeFirstLetter(allergen.fbAllergen.name)}
                    </Grid>
                  ))
              : '-'}
          </Typography>
          <Typography className={classes.titleofdetail} style={{ marginTop: 20 }}>
            <TranslateTextComponent capitalize>repeater</TranslateTextComponent>:
          </Typography>
          <Typography className={classes.detailOfGuest}>
            <Checkbox color="primary" style={{ color: `${primaryColor}`, borderStyle: 0.5 }} />
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography className={classes.titleofdetail}>
            <TranslateTextComponent capitalize>numb-stay</TranslateTextComponent>:
          </Typography>
          <Typography className={classes.detailOfGuest}>2</Typography>
          <Typography className={classes.titleofdetail} style={{ marginTop: 20 }}>
            <TranslateTextComponent capitalize>last-stay-date</TranslateTextComponent>:
          </Typography>
          <Typography className={classes.detailOfGuest}>Data</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GuestDetails;
