import { makeStyles } from '@material-ui/core/styles';

const styles = {
  wrapper: ({ selected }) => ({
    width: '100%',
    height: '100%',
    position: 'relative'
  }),
  container: ({ rotation }) => ({
    width: '100%',
    height: '100%',
    transform: `rotate(${rotation}deg)`,
    transition: 'transform 0.3s'
  }),
  tableDescription: ({ hover }) => ({
    padding: 5,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',
    zIndex: 2
  }),
  round: ({ size, selected }) => ({
    backgroundColor: selected ? '#00B7FF' : '#C0C1C2',
    width: '100%',
    margin: 'auto',
    // height: size || '100%',
    // width: size || '100%',
    borderRadius: '50%'
  }),
  square: ({ size, selected }) => ({
    backgroundColor: selected ? '#00B7FF' : '#C0C1C2',
    // height: size || '100%',
    // width: size || '100%',
    borderRadius: 4
  }),
  rectangle: ({ size, selected }) => ({
    backgroundColor: selected ? '#00B7FF' : '#C0C1C2',
    // height: size || '100%',
    // width: size * 2 || '100%',
    borderRadius: 4
  }),
  table: () => ({
    height: '100%',
    border: '1px solid #C0C1C2',
    background: 'linear-gradient(233deg, rgba(255,255,255,1) 0%, rgba(192,193,194,1) 100%)'
  }),
  rotateButtonContainer: ({ hover }) => ({
    position: 'absolute',
    top: '50%',
    width: '100%',
    zIndex: 5,
    opacity: hover ? 1 : 0,
    transition: 'opacity 0.3s'
  }),
  rotateLeftButton: {
    position: 'absolute',
    left: -32,
    cursor: 'pointer',
    transform: 'translateY(-50%)',
    color: '#00B7FF',
    border: 'none',
    backgroundColor: 'transparent'
  },
  rotateRightButton: {
    position: 'absolute',
    right: -32,
    cursor: 'pointer',
    transform: 'translateY(-50%)',
    color: '#00B7FF',
    border: 'none',
    backgroundColor: 'transparent'
  }
};

export default makeStyles(styles);
