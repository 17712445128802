import React from 'react';
import DailyRevenueForm from '../../components/revenues/DailyRevenueForm';
import Layout from '../../components/shared/Layout';

const EditDailyRevenuePage = ({ match, history }) => {
  return (
    <Layout history={history} section="edit-daily-report">
      <DailyRevenueForm fbServiceRevenueUUID={match.params.uuid} />
    </Layout>
  );
};

export default EditDailyRevenuePage;
