/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';

export const ON_ORDER_CREATED = gql`
  subscription onOrderCreated($outletUUID: String!) {
    orderCreated(outletUUID: $outletUUID) {
      outletUUID
      orderUUID
      orderType
    }
  }
`;

export const ON_ORDER_STATUS_CHANGES = gql`
  subscription onOrderStatusChanged($outletUUID: String!) {
    orderStatusChanged(outletUUID: $outletUUID) {
      outletUUID
      orderUUID
      orderType
      prevOrderStatus
      newOrderStatus
    }
  }
`;
