//* DISCOUNT
export const isDiscountValid = (discountType, discountValue, total) => {
  if (discountType === 'percentage') {
    if (discountValue >= 0 && discountValue <= 100) return true;
    else return false;
  } else {
    if (discountValue <= 0 || discountValue <= total) return true;
    else return false;
  }
};
