import React from 'react';
import { Box } from '@material-ui/core';
import styles from '../../../styles/records/orders/style';
import { groupedByMenuItem } from '../../../constants/order/order';
import OrderItemData from './OrderItemData';

const OrderItem = ({ course, table }) => {
  const classes = styles();

  const grouped = groupedByMenuItem(course.fbCoursesMenuItems);

  return (
    <Box className={classes.dataContainer}>
      <Box style={{ width: 56, gridArea: 'id-area' }} className={classes.idArea}>
        {course.number}
      </Box>
      <Box style={{ gridArea: 'data-area' }}>
        {grouped.map((items, index) => (
          <OrderItemData key={index} items={items} table={table} />
        ))}
      </Box>
    </Box>
  );
};

export default OrderItem;
