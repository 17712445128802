import { makeStyles } from '@material-ui/core/styles';
import { primaryColor, mainTextColor } from '../../common-fe/src';

const styles = {
  container: {
    display: 'flex',
    padding: '0 3px',
    margin: '10px 5px',
    overflow: 'hidden'
  },
  expansionContainer: ({ expanded }) => ({
    '&:before': {
      backgroundColor: 'transparent'
    },
    '&.MuiExpansionPanel-root.Mui-expanded': { margin: 0 },
    borderRadius: expanded ? 10 : 0,
    width: '100%',
    boxShadow: 'none',
    '&.MuiAccordionSummary-content Mui-expanded': {
      margin: 0
    }
  }),
  expansionSummaryContainer: ({ expanded }) => ({
    border: `1px solid ${primaryColor}`,
    borderRadius: 10,
    borderBottomLeftRadius: expanded ? 0 : 10,
    borderBottomRightRadius: expanded ? 0 : 10,
    boxShadow: '0px 3px 3px #e4e4e4',
    overflow: 'hidden'
  }),
  expansionDetailsContainer: ({ withoutPaddingDetails }) => ({
    border: `1px solid ${primaryColor}`,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    padding: withoutPaddingDetails ? 0 : null,
    overflow: withoutPaddingDetails ? 'hidden' : null,
    '& > *:not(:last-child)': {
      marginBottom: 20
    }
  }),
  radio: {
    '&.MuiRadio-root': {
      padding: 2,
      marginRight: 5
    }
  },
  // SummaryWithoutBorderContainer
  summaryWithoutBorderContainer: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
    color: mainTextColor,
    fontSize: 20,
    fontWeight: 'bold',
    width: '100%'
  },
  summaryWithoutBorderRow: {
    margin: '2.5px 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  summaryWithoutBorderSecondRowDetail: {
    display: 'flex',
    flexFlow: 'column',
    '& span:first-child': {
      '& span:last-child': {
        marginLeft: 5,
        color: mainTextColor
      }
    },
    '& span:last-child': {
      color: 'black'
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
      fontSize: 14,
      fontWeight: 'normal',
      margin: '2px 0'
    }
  },
  backgroundIcon: {
    position: 'absolute',
    right: -15,
    bottom: -15,
    opacity: '20%',
    '& > *': {
      width: 80,
      height: 'auto'
    }
  },
  //* Details
  coursesListContainer: {
    width: '100%',
    '& th, td': {
      color: mainTextColor,
      fontSize: 14
    },
    '& td': {
      padding: '3px 24px 3px 16px;'
    }
  },
  paymentStatus: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottom: `1px solid ${primaryColor}`,
    paddingBottom: 10
  },
  // SummaryWithBorderContainer
  //* Details
  closeOutContainer: {
    width: '100%',
    textTransform: 'uppercase',
    '& hr': {
      border: 'none',
      padding: '3px 0'
    }
  },
  closeOutDetailRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '& div': {
      display: 'flex',
      flexFlow: 'column'
    },
    '& div:last-child': {
      textAlign: 'right'
    }
  },
  dailyCloseOutDetailContainer: {
    width: '100%',
    '& hr': {
      border: '1px solid #91C9E8',
      width: '100%'
    }
  },
  dailyCloseOutDetailTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: 10,
    color: mainTextColor
  },
  dailyCloseOutDetailRowsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& div': {
      margin: '2px 0'
    }
  },
  dailyCloseOutDetailBoxVerticalLeft: {
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'column',
    width: '100%',
    textTransform: 'capitalize'
  },
  dailyCloseOutDetailBoxVerticalRight: {
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'column',
    textAlign: 'right',
    textTransform: 'capitalize'
  },
  dailyCloseOutDetailLabelColor: {
    color: mainTextColor
  },
  dailyCloseOutDetailLabelRed: {
    color: '#CC0A0A'
  },
  dailyCloseOutDetailLabelGreen: {
    color: '#008000'
  },
  dailyCloseOutDetailBold: {
    fontWeight: 'bold'
  },
  dailyCloseOutDetailButton: {
    minWidth: 80,
    maxHeight: 20,
    fontSize: 15,
    color: 'white',
    borderRadius: 10
  },
  invoicesDetailsSummaryContainer: {
    display: 'flex',
    flexFlow: 'column',
    marginLeft: 'auto',
    maxWidth: 300,
    color: mainTextColor,
    fontWeight: 'bold'
  },
  invoicesDetailsSummaryArea: {
    display: 'flex',
    justifyContent: 'space-between',
    '& span:first-child': {
      width: '50%',
      textAlign: 'right'
    }
  },
  // PM Details
  pmDetailsTable: {
    whiteSpace: 'nowrap'
  },
  pmDetailsFabButton: {
    width: 25,
    height: 25,
    minHeight: 'unset'
  },
  pmDetailsButton: {
    color: 'white',
    maxWidth: 80,
    maxHeight: 28,
    fontSize: 10
  }
};

export default makeStyles(styles);
