import React from 'react';
import PageLayout from '../../shared/PageLayout';
import Order from './Order';

const Kitchen = () => {
  return (
    <PageLayout>
      <Order />
    </PageLayout>
  );
};

export default Kitchen;
