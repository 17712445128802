import gql from 'graphql-tag';
import { GUEST_FRAGMENT, GUEST_APP_PROFILE_FRAGMENT } from '../fragments';

export const GET_GUESTS = gql`
  query getGuests($orderBy: String, $orderType: String, $page: Int, $pageSize: Int, $keyword: String, $isFbRepeater: Boolean, $fbOutletUUID: String) {
    getGuests(
      orderBy: $orderBy
      orderType: $orderType
      page: $page
      pageSize: $pageSize
      keyword: $keyword
      isFbRepeater: $isFbRepeater
      fbOutletUUID: $fbOutletUUID
    ) {
      totalCount
      data {
        ...guestFragment
      }
    }
  }
  ${GUEST_FRAGMENT}
`;

export const GET_GUEST_PROFILES = gql`
  query getGuestProfiles(
    $orderBy: String
    $orderType: String
    $page: Int
    $pageSize: Int
    $keyword: String
    $roomNumber: String
    $checkinDate: String
    $checkoutDate: String
    $isInHouse: Boolean
  ) {
    getGuestProfiles(
      orderBy: $orderBy
      orderType: $orderType
      page: $page
      pageSize: $pageSize
      keyword: $keyword
      roomNumber: $roomNumber
      checkinDate: $checkinDate
      checkoutDate: $checkoutDate
      isInHouse: $isInHouse
    ) {
      totalCount
      data {
        ...guestAppProfileFragment
      }
    }
  }
  ${GUEST_APP_PROFILE_FRAGMENT}
`;
