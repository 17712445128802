import React, { useState, forwardRef, useContext } from 'react';
import { Formik } from 'formik';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Switch, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isEmpty } from 'validator';
import { useMutation } from '@apollo/react-hooks';
import typy from 'typy';
import moment from 'moment';
import styles from '../../../../styles/shared/pm';
import modalStyle from '../../../../styles/shared/modalStyle';
import { TranslateTextComponent, capitalizeFirstLetter, translate } from '../../../../translator';
import { DataPicker, SearchBar, MaterialIcon, redButton, InputTextLabel } from '../../../../common-fe/src';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { CREATE_PAY_MASTER, EDIT_PAY_MASTER } from '../../../../graphql/cash/mutations';
import { ModalContext } from '../../../../contexts/ModalContext';
import { getApolloErrors } from '../../../../apollo/ApolloProvider';
import { GET_GUESTS } from '../../../../graphql/guests/queries';
import { ADD_GUEST, EDIT_GUEST } from '../../../../graphql/guests/mutations';
import useCustomQuery from '../../../../hooks/useCustomQuery';

const PMForm = forwardRef(({ guestsList, onPMGroupChange, resetGuestsList, closePMForm, pmToEdit, refetchGetPayMaster }, ref) => {
  const [expanded, setExpanded] = useState(true);
  const [results, setResults] = useState([]);
  const [guest, setGuest] = useState(pmToEdit || null);
  const { language } = useContext(LanguageContext);
  const classes = styles({ expanded });
  const modalClasses = modalStyle();

  // Query
  const getGuests = useCustomQuery(GET_GUESTS, { skip: true, fetchPolicy: 'network-only' });

  // Mutation
  const [createPayMaster] = useMutation(CREATE_PAY_MASTER);
  const [editPayMaster] = useMutation(EDIT_PAY_MASTER);
  const [addGuest] = useMutation(ADD_GUEST);
  const [editGuest] = useMutation(EDIT_GUEST);

  // Modal Context
  const modalContext = useContext(ModalContext);

  const onReturnedDataHandler = (res) => {
    setResults(typy(res, 'data.getGuests.data').safeArray);
  };

  const onReturnedResultClick = (res) => {
    setGuest(res);
  };

  const resetData = () => {
    setResults([]);
    setGuest(null);
  };

  return (
    <Box className={classes.container}>
      <Accordion className={classes.panelContainer} expanded={expanded} onChange={() => setExpanded((prev) => !prev)}>
        <AccordionSummary className={classes.panelTitleContainer} expandIcon={<ExpandMoreIcon color="primary" />} aria-controls="panel-content">
          <Typography className={classes.panelTitle}>
            <TranslateTextComponent capitalize>overall</TranslateTextComponent>
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetails}>
          <Formik
            enableReinitialize
            innerRef={ref}
            initialValues={{
              pmType: guest?.type ?? 'individual',
              pmName: guest?.name ?? '',
              birthdate: guest?.dob ? moment(guest.dob).format('YYYY-MM-DD') : null,
              birthplace: guest?.birthplace ?? '',
              vatNumber: guest?.vatNumber ?? '',
              taxCode: guest?.fiscalCode ?? '',
              address: guest?.address ?? '',
              province: guest?.province ?? '',
              phoneNumber: guest?.phone ?? guest?.mobilePhone ?? '',
              certifiedEmail: guest?.certifiedEmail ?? '',
              pmDuration: guest?.pmDuration ?? 'temporary',
              expirationDate: guest?.expirationDate ? moment(guest.expirationDate).format('YYYY-MM-DD') : null,
              firstname: guest?.firstname ?? '',
              lastname: guest?.lastname ?? '',
              company: guest?.company ?? '',
              country: guest?.country ?? '',
              zipCode: guest?.zipCode ?? '',
              city: guest?.city ?? '',
              website: guest?.website ?? '',
              email: guest?.email ?? ''
            }}
            validate={(values) => {
              const errors = {};
              if (isEmpty(values.pmName, { ignore_whitespace: true })) {
                errors.pmName = capitalizeFirstLetter(translate('required', language));
              }

              if (isEmpty(values.firstname, { ignore_whitespace: true }) && isEmpty(values.company, { ignore_whitespace: true })) {
                errors.firstname = capitalizeFirstLetter(translate('required', language));
              }

              if (isEmpty(values.lastname, { ignore_whitespace: true }) && isEmpty(values.company, { ignore_whitespace: true })) {
                errors.lastname = capitalizeFirstLetter(translate('required', language));
              }

              if (values.pmDuration === 'temporary' && values.expirationDate === null) {
                errors.expirationDate = capitalizeFirstLetter(translate('required', language));
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              if (!guest) {
                addGuest({
                  variables: {
                    guestData: {
                      firstname: values.firstname || null,
                      lastname: values.lastname || null,
                      company: values.company || null,
                      vatNumber: values.vatNumber || null,
                      fiscalCode: values.taxCode || null,
                      dob: values.birthdate ? moment(values.birthdate, 'YYYY-MM-DD') : null,
                      birthplace: values.birthplace || null,
                      address: values.address || null,
                      city: values.city || null,
                      province: values.province || null,
                      zipCode: values.zipCode || null,
                      country: values.country || null,
                      email: values.email || null,
                      certifiedEmail: values.certifiedEmail || null,
                      website: values.website || null,
                      phone: values.phoneNumber || null
                    }
                  }
                })
                  .then((res) => {
                    createPayMaster({
                      variables: {
                        guestUUID: typy(res, 'data.addGuest.uuid').safeString,
                        payMasterData: {
                          name: values.pmName,
                          type: values.pmType,
                          expirationDate: values.expirationDate ? moment(values.expirationDate).format('YYYY-MM-DD') : null,
                          users: guestsList.map((user) => ({
                            uuid: user.uuid
                          }))
                        }
                      }
                    })
                      .then((res) => {
                        refetchGetPayMaster();
                        resetGuestsList();
                        modalContext.openModal({
                          class: 'success',
                          title: <TranslateTextComponent capitalize>pm-created-title</TranslateTextComponent>,
                          text: <TranslateTextComponent capitalize>pm-created-description</TranslateTextComponent>,
                          actionButtons: [
                            <Button
                              key={0}
                              variant="contained"
                              color="primary"
                              className={modalClasses.invertedButton}
                              onClick={() => {
                                modalContext.closeModal();
                                closePMForm();
                              }}
                            >
                              <TranslateTextComponent uppercase>ok</TranslateTextComponent>
                            </Button>
                          ]
                        });
                        resetForm();
                        resetData();
                      })
                      .catch((err) => {
                        modalContext.openModal({
                          class: 'danger',
                          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                          text: getApolloErrors(err).join(' - ')
                        });
                      });
                  })
                  .catch((err) => {
                    modalContext.openModal({
                      class: 'danger',
                      title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                      text: getApolloErrors(err).join(' - ')
                    });
                  });
              } else {
                editGuest({
                  variables: {
                    guestUUID: pmToEdit ? pmToEdit.guestUUID : guest.uuid,
                    guestData: {
                      firstname: values.firstname || null,
                      lastname: values.lastname || null,
                      company: values.company || null,
                      vatNumber: values.vatNumber || null,
                      fiscalCode: values.taxCode || null,
                      dob: values.birthdate ? moment(values.birthdate, 'YYYY-MM-DD') : null,
                      birthplace: values.birthplace || null,
                      address: values.address || null,
                      city: values.city || null,
                      province: values.province || null,
                      zipCode: values.zipCode || null,
                      country: values.country || null,
                      email: values.email || null,
                      certifiedEmail: values.certifiedEmail || null,
                      website: values.website || null,
                      phone: values.phoneNumber || null
                    }
                  }
                }).then(() => {
                  if (pmToEdit) {
                    editPayMaster({
                      variables: {
                        payMasterUUID: pmToEdit.payMasterUUID,
                        guestUUID: pmToEdit.guestUUID,
                        payMasterData: {
                          name: values.pmName,
                          type: values.pmType,
                          expirationDate: values.pmDuration === 'temporary' && values.expirationDate ? values.expirationDate : null,
                          users: guestsList.map((user) => ({
                            uuid: user.uuid
                          }))
                        }
                      }
                    })
                      .then((res) => {
                        refetchGetPayMaster();
                        resetGuestsList();
                        modalContext.openModal({
                          class: 'success',
                          title: <TranslateTextComponent capitalize>pm-edited-title</TranslateTextComponent>,
                          text: <TranslateTextComponent capitalize>pm-edited-description</TranslateTextComponent>,
                          actionButtons: [
                            <Button
                              key={0}
                              variant="contained"
                              color="primary"
                              className={modalClasses.invertedButton}
                              onClick={() => {
                                modalContext.closeModal();
                                closePMForm();
                              }}
                            >
                              <TranslateTextComponent uppercase>ok</TranslateTextComponent>
                            </Button>
                          ]
                        });
                        resetForm();
                        resetData();
                      })
                      .catch((err) => {
                        modalContext.openModal({
                          class: 'danger',
                          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                          text: getApolloErrors(err).join(' - ')
                        });
                      });
                  } else {
                    createPayMaster({
                      variables: {
                        guestUUID: guest.uuid,
                        payMasterData: {
                          name: values.pmName,
                          type: values.pmType,
                          expirationDate: values.expirationDate,
                          users: guestsList.map((user) => ({
                            uuid: user.uuid
                          }))
                        }
                      }
                    })
                      .then((res) => {
                        refetchGetPayMaster();
                        resetGuestsList();
                        modalContext.openModal({
                          class: 'success',
                          title: <TranslateTextComponent capitalize>pm-created-title</TranslateTextComponent>,
                          text: <TranslateTextComponent capitalize>pm-created-description</TranslateTextComponent>,
                          actionButtons: [
                            <Button
                              key={0}
                              variant="contained"
                              color="primary"
                              className={modalClasses.invertedButton}
                              onClick={() => {
                                modalContext.closeModal();
                                closePMForm();
                              }}
                            >
                              <TranslateTextComponent uppercase>ok</TranslateTextComponent>
                            </Button>
                          ]
                        });
                        resetForm();
                        resetData();
                      })
                      .catch((err) => {
                        modalContext.openModal({
                          class: 'danger',
                          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                          text: getApolloErrors(err).join(' - ')
                        });
                      });
                  }
                });
                setSubmitting(false);
              }
            }}
          >
            {({ handleChange, setFieldValue, values, errors, touched }) => {
              return (
                <Box className={classes.formContainer}>
                  <Box style={{ gridArea: 'searchbar' }}>
                    <SearchBar
                      autoComplete
                      apolloQuery={getGuests}
                      withResults
                      results={results}
                      style={{ height: 40 }}
                      inputStyle={{ textIndent: 10 }}
                      resultsKeys={['firstname', 'lastname', 'dob', 'email']}
                      onReturnedData={onReturnedDataHandler}
                      onResultClick={onReturnedResultClick}
                    />
                    {guest && (
                      <Box style={{ marginTop: 5, display: 'flex', alignItems: 'center' }}>
                        <TranslateTextComponent capitalize style={{ fontWeight: 'bold' }}>
                          current-pm
                        </TranslateTextComponent>
                        {`: ${guest.firstname} ${guest.lastname}${guest.company ? ` - ${guest.company}` : ''}`}
                        <MaterialIcon
                          style={{ cursor: 'pointer', color: redButton, marginLeft: 5 }}
                          iconName="Close"
                          onClick={() => setGuest(null)}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box style={{ gridArea: 'left', marginTop: 10 }}>
                    <Box>
                      <Typography className={classes.inputTitle}>
                        <TranslateTextComponent capitalize>pm-type</TranslateTextComponent>
                      </Typography>
                      <TranslateTextComponent capitalize>individual</TranslateTextComponent>
                      <Switch
                        name="pmType"
                        className={classes.switch}
                        color="primary"
                        checked={values.pmType !== 'individual'}
                        onChange={() => {
                          if (values.pmType === 'individual') setFieldValue('pmType', 'group');
                          else setFieldValue('pmType', 'individual');
                          onPMGroupChange();
                        }}
                      />
                      <TranslateTextComponent capitalize>group</TranslateTextComponent>
                    </Box>
                    <Box>
                      <Typography className={classes.inputTitle}>
                        <TranslateTextComponent capitalize>pm-name</TranslateTextComponent>
                      </Typography>
                      <InputTextLabel
                        className={classes.pmTextField}
                        name="pmName"
                        color="primary"
                        variant="outlined"
                        required
                        error={touched.pmName && errors.pmName && errors.pmName.length > 0}
                        errorLabel={touched.pmName && errors.pmName}
                        onChange={handleChange}
                        value={values.pmName}
                      />
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box>
                        <Typography className={classes.inputTitle}>
                          <TranslateTextComponent capitalize>birthdate</TranslateTextComponent>
                        </Typography>
                        <DataPicker
                          name="birthdate"
                          dataPickerStyle={{ overflow: 'hidden', maxWidth: 150 }}
                          value={values.birthdate}
                          onChange={(e) => setFieldValue('birthdate', e)}
                        />
                      </Box>
                      <Box>
                        <Typography className={classes.inputTitle}>
                          <TranslateTextComponent capitalize>birthplace</TranslateTextComponent>
                        </Typography>
                        <InputTextLabel
                          className={classes.pmTextField}
                          color="primary"
                          variant="outlined"
                          name="birthplace"
                          value={values.birthplace}
                          onChange={handleChange}
                        />
                      </Box>
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box>
                        <Typography className={classes.inputTitle}>
                          <TranslateTextComponent capitalize>vat-number-shortened</TranslateTextComponent>
                        </Typography>
                        <InputTextLabel
                          className={classes.pmTextField}
                          color="primary"
                          variant="outlined"
                          style={{ maxWidth: 175, marginRight: 25 }}
                          name="vatNumber"
                          value={values.vatNumber}
                          onChange={handleChange}
                        />
                      </Box>
                      <Box>
                        <Typography className={classes.inputTitle}>
                          <TranslateTextComponent capitalize>tax-code-shortened</TranslateTextComponent>
                        </Typography>
                        <InputTextLabel
                          className={classes.pmTextField}
                          color="primary"
                          variant="outlined"
                          style={{ maxWidth: 175 }}
                          name="taxCode"
                          value={values.taxCode}
                          onChange={handleChange}
                        />
                      </Box>
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box>
                        <Typography className={classes.inputTitle}>
                          <TranslateTextComponent capitalize>address</TranslateTextComponent>
                        </Typography>
                        <InputTextLabel
                          className={classes.pmTextField}
                          color="primary"
                          variant="outlined"
                          style={{ maxWidth: 300 }}
                          name="address"
                          value={values.address}
                          onChange={handleChange}
                        />
                      </Box>
                      <Box>
                        <Typography className={classes.inputTitle}>
                          <TranslateTextComponent capitalize>province</TranslateTextComponent>
                        </Typography>
                        <InputTextLabel
                          className={classes.pmTextField}
                          color="primary"
                          variant="outlined"
                          style={{ maxWidth: 95 }}
                          name="province"
                          value={values.province}
                          onChange={handleChange}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Typography className={classes.inputTitle}>
                        <TranslateTextComponent capitalize>phone</TranslateTextComponent>
                      </Typography>
                      <InputTextLabel
                        className={classes.pmTextField}
                        color="primary"
                        variant="outlined"
                        name="phoneNumber"
                        value={values.phoneNumber}
                        onChange={handleChange}
                      />
                    </Box>
                    <Box>
                      <Typography className={classes.inputTitle}>
                        <TranslateTextComponent capitalize>PEC</TranslateTextComponent>
                      </Typography>
                      <InputTextLabel
                        className={classes.pmTextField}
                        color="primary"
                        variant="outlined"
                        name="certifiedEmail"
                        value={values.certifiedEmail}
                        onChange={handleChange}
                      />
                    </Box>
                  </Box>
                  <Box style={{ gridArea: 'right' }}>
                    <Box>
                      <Typography className={classes.inputTitle}>
                        <TranslateTextComponent capitalize>pm-duration</TranslateTextComponent>
                      </Typography>
                      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box>
                          <TranslateTextComponent capitalize>temporary</TranslateTextComponent>

                          <Switch
                            className={classes.switch}
                            color="primary"
                            name="pmDuration"
                            checked={values.pmDuration === 'notDefined'}
                            onChange={() => {
                              if (values.pmDuration === 'temporary') setFieldValue('pmDuration', 'notDefined');
                              else setFieldValue('pmDuration', 'temporary');
                            }}
                          />
                          <TranslateTextComponent capitalize>undefined</TranslateTextComponent>
                        </Box>
                        {values.pmDuration === 'temporary' && (
                          <Box>
                            <DataPicker
                              name="expirationDate"
                              dataPickerStyle={{ maxWidth: 150, overflow: 'hidden' }}
                              value={values.expirationDate}
                              error={touched.expirationDate && values.expirationDate === null}
                              errorLabel={touched.expirationDate && errors.expirationDate}
                              onChange={(e) => {
                                setFieldValue('expirationDate', e);
                                setFieldValue('pmDuration', 'temporary');
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box style={{ flex: 1, marginRight: 20 }}>
                        <Typography className={classes.inputTitle}>
                          <TranslateTextComponent capitalize>name</TranslateTextComponent>
                        </Typography>
                        <InputTextLabel
                          className={classes.pmTextField}
                          color="primary"
                          variant="outlined"
                          name="firstname"
                          value={values.firstname}
                          error={touched.firstname && errors.firstname && errors.firstname.length > 0}
                          errorLabel={touched.firstname && errors.firstname}
                          onChange={handleChange}
                        />
                      </Box>
                      <Box style={{ flex: 1 }}>
                        <Typography className={classes.inputTitle}>
                          <TranslateTextComponent capitalize>surname</TranslateTextComponent>
                        </Typography>
                        <InputTextLabel
                          className={classes.pmTextField}
                          color="primary"
                          variant="outlined"
                          name="lastname"
                          value={values.lastname}
                          error={touched.lastname && errors.lastname && errors.lastname.length > 0}
                          errorLabel={touched.lastname && errors.lastname}
                          onChange={handleChange}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Typography className={classes.inputTitle}>
                        <TranslateTextComponent capitalize>company</TranslateTextComponent>
                      </Typography>
                      <InputTextLabel
                        className={classes.pmTextField}
                        color="primary"
                        variant="outlined"
                        name="company"
                        value={values.company}
                        onChange={handleChange}
                      />
                    </Box>
                    <Box>
                      <Typography className={classes.inputTitle}>
                        <TranslateTextComponent capitalize>country-n</TranslateTextComponent>
                      </Typography>
                      <InputTextLabel
                        className={classes.pmTextField}
                        color="primary"
                        variant="outlined"
                        name="country"
                        value={values.country}
                        onChange={handleChange}
                      />
                    </Box>
                    <Box style={{ display: 'flex' }}>
                      <Box style={{ maxWidth: 120 }}>
                        <Typography className={classes.inputTitle}>
                          <TranslateTextComponent capitalize>zip-code</TranslateTextComponent>
                        </Typography>
                        <InputTextLabel
                          className={classes.pmTextField}
                          color="primary"
                          variant="outlined"
                          name="zipCode"
                          value={values.zipCode}
                          onChange={handleChange}
                        />
                      </Box>
                      <Box style={{ width: '100%' }}>
                        <Box style={{ marginLeft: 15 }}>
                          <Typography className={classes.inputTitle}>
                            <TranslateTextComponent capitalize>city</TranslateTextComponent>
                          </Typography>
                          <InputTextLabel
                            className={classes.pmTextField}
                            color="primary"
                            variant="outlined"
                            name="city"
                            value={values.city}
                            onChange={handleChange}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Typography className={classes.inputTitle}>
                        <TranslateTextComponent>www</TranslateTextComponent>
                      </Typography>
                      <InputTextLabel
                        className={classes.pmTextField}
                        color="primary"
                        variant="outlined"
                        name="website"
                        value={values.website}
                        onChange={handleChange}
                      />
                    </Box>
                    <Box>
                      <Typography className={classes.inputTitle}>
                        <TranslateTextComponent capitalize>Mail</TranslateTextComponent>
                      </Typography>
                      <InputTextLabel
                        className={classes.pmTextField}
                        color="primary"
                        variant="outlined"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </Box>
                  </Box>
                </Box>
              );
            }}
          </Formik>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
});

export default PMForm;
