import React from 'react';
import Layout from '../components/shared/Layout';
import SaveReservation from '../components/saveReservation/SaveReservation';

const SaveReservationPage = ({ history }) => {
  return (
    <Layout history={history} section="new-reservation">
      <SaveReservation history={history} />
    </Layout>
  );
};

export default SaveReservationPage;
