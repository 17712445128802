import React, { useState, useContext, useEffect } from 'react';
import { Box } from '@material-ui/core';
import typy from 'typy';
import ReservationForm from '../saveReservation/ReservationForm';
import { GET_FB_OUTLET } from '../../graphql/outlets/queries';
import { GET_GUESTS } from '../../graphql/guests/queries';
import { GET_CURRENT_SERVICE, GET_FB_SERVICES } from '../../graphql/settings/service/queries';
import { ReservationContext } from '../../contexts/ReservationContext';
import { AuthContext } from '../../contexts/AuthContext';
import { SearchBar, MaterialIcon, Dropdown } from '../../common-fe';
import useCustomQuery from '../../hooks/useCustomQuery';

const FastReservation = ({ table, onNewReservationComplete, onCloseReservationModal }) => {
  const [resData, setResData] = useState([]);
  const reservationContext = useContext(ReservationContext);
  const { appOutletUUID } = useContext(AuthContext);
  const physicalCatResponse = useCustomQuery(GET_FB_OUTLET, {
    variables: { fbOutletUUID: appOutletUUID }
  });
  const errorResMap = reservationContext && reservationContext.errorResMap;

  const physicalCategories = typy(physicalCatResponse, 'data.getFbOutlet.guestPhysicalCategories').safeArray;

  const getGuests = useCustomQuery(GET_GUESTS, {
    variables: { fbOutletUUID: appOutletUUID },
    fetchPolicy: 'network-only'
  });

  const getFbServices = useCustomQuery(GET_FB_SERVICES, {
    variables: { fbOutletUUID: appOutletUUID },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const dropdownServiceItems = typy(getFbServices, 'data.getFbServices.data').safeArray.map((service) => ({
    value: service.uuid,
    label: service.name,
    icon: service.icon ? <MaterialIcon iconName={service.icon} /> : null
  }));

  dropdownServiceItems.unshift({ value: '', label: 'no-service' });

  useEffect(() => {
    return () => {
      reservationContext.reservationResetData();
      reservationContext.resetValidationErrors();
    };
  }, []);

  useCustomQuery(GET_CURRENT_SERVICE, {
    variables: { fbOutletUUID: appOutletUUID },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      reservationContext.handleChangeReservationData('fbServiceUUID', typy(data, 'getCurrentService.uuid').safeString);
    }
  });

  const onReturnedDataHandler = (res) => {
    setResData(typy(res, 'data.getGuests.data').safeArray);
  };

  const onReturnedResultClick = (res) => {
    reservationContext.handleSetGuestData(res);
  };

  return (
    <Box>
      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 40px 5px 40px' }}>
        <Box style={{ width: '45%' }}>
          <Dropdown
            error={errorResMap[0].has('fbServiceUUID')}
            errorLabel={errorResMap[0].get('fbServiceUUID')}
            dropdownStyle={{ height: 40, width: '100%' }}
            value={typy(reservationContext, 'reservationsData[0].fbServiceUUID').safeString}
            onChange={(e) => reservationContext.handleChangeReservationData('fbServiceUUID', e.target.value)}
            items={dropdownServiceItems}
          />
        </Box>
        <Box style={{ width: '45%' }}>
          <SearchBar
            autoComplete
            apolloQuery={getGuests}
            withResults
            results={resData}
            style={{ height: 40 }}
            inputStyle={{ textIndent: 10 }}
            onReturnedData={onReturnedDataHandler}
            onResultClick={onReturnedResultClick}
            resultsKeys={['firstname', 'lastname']}
          />
        </Box>
      </Box>
      <ReservationForm
        isFastReservation
        table={table}
        physicalCategories={physicalCategories}
        onNewReservationComplete={onNewReservationComplete}
        onCloseReservationModal={onCloseReservationModal}
      />
    </Box>
  );
};

export default FastReservation;
