import React from 'react';
import { Box } from '@material-ui/core';
import styles from '../../../styles/viewTables/courses/style';
import coursesColors from '../../../constants/order/orderStatuses';
import ColoredCircle from '../../shared/ColoredCircle';
import { ReactComponent as IsWaitingCourseImg } from '../../../assets/images/order/isWaitingCourse.svg';

const Courses = ({ courses }) => {
  const classes = styles();

  return (
    <Box className={classes.container}>
      {courses.map((course, key) => {
        return course.isWaiting ? (
          <IsWaitingCourseImg key={course.id} className={styles({ status: course.fbCourseStatus }).statusIcon} />
        ) : (
          <ColoredCircle key={course.id} title={course.fbCourseStatus} color={coursesColors[course.fbCourseStatus].color} />
        );
      })}
    </Box>
  );
};

export default Courses;
