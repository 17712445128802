import { makeStyles } from '@material-ui/core/styles';
import { primaryColor, secondaryColor, invertedPrimaryCC80, borderTheme, mainTextColor } from '../theme';

const styles = {
  boxContainer: () => ({
    padding: '15px 40px',
    width: '100%',
    backgroundColor: 'white',
    borderTopLeftRadius: 15
  }),
  button: (props) => ({
    color: 'white',
    borderRadius: 9,
    width: 155,
    height: 50,
    fontSize: 20,
    backgroundColor: props.color,
    '&:hover': {
      backgroundColor: props.hover
    }
  }),
  tabText: () => ({
    textTransform: 'capitalize',
    fontSize: 'large',
    color: invertedPrimaryCC80,
    backgroundColor: secondaryColor,
    marginRight: 5,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5
  }),
  tabTextSelected: () => ({
    textTransform: 'capitalize',
    fontSize: 'large',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: primaryColor,
    marginRight: 5,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5
  }),
  textFieldBorder: () => ({
    borderColor: primaryColor,
    borderWidth: 2
  }),
  buttonListView: () => ({
    backgroundColor: primaryColor
  }),

  footerGridContainer: () => ({
    paddingTop: 20
  }),
  firstBox: () => ({
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    width: '80%'
  }),
  footerBoxes: () => ({
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    width: '80%',
    justifyContent: 'center'
  }),
  formTypography: () => ({
    fontWeight: 'bold',
    marginRight: 5,
    fontSize: 14
  }),

  footerLabelRadioButton: () => ({
    color: 'black',
    paddingRight: 10
  }),
  footerRadioGroup: () => ({
    display: 'flex',
    justifyContent: 'center'
  }),
  buttonList: () => ({
    color: 'white',
    boxShadow: 'none',
    height: 35
  }),
  addButton: () => ({
    width: 23,
    height: 23,
    background: primaryColor,
    borderRadius: '50%',
    padding: '9px 0 0 29px',
    color: 'white'
  }),
  noteTextField: () => ({
    fontSize: 12,
    color: 'black',
    border: borderTheme,
    paddingLeft: 5,
    borderRadius: 4
  }),
  gridDays: (props) => ({
    padding: '13px 0px',
    fontSize: 12,
    borderStyle: 'solid',
    borderTopColor: primaryColor,
    borderBottomColor: primaryColor,
    borderTopWidth: 1.5,
    borderRightWidth: 1.5,
    borderBottomWidth: 1.5,
    borderLeftWidth: 0,
    backgroundColor: props.isSelectedDay ? primaryColor : 'white',
    color: props.isSelectedDay ? 'white' : primaryColor,
    // width: props.dimension === 'big' ? 143 : 60,
    height: props.dimension === 'big' ? null : 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:first-child': {
      borderColor: primaryColor,
      borderRightColor: props.isSelectedDay ? 'white' : primaryColor,
      borderStyle: 'solid',
      borderRadius: '4px 0px 0px 4px',
      border: 1.5
    },
    '&:last-child': {
      borderColor: primaryColor,
      borderLeftColor: props.isSelectedDay ? 'white' : primaryColor,
      borderStyle: 'solid',
      border: '1.5px 1.5px 1.5px 0px',
      borderRadius: '0px 4px 4px 0px'
    }
  }),
  indicator: {
    height: 0
  },
  settingItem: () => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:first-child': {
      justifyContent: 'flex-start'
    },
    '&:last-child': {
      justifyContent: 'flex-end'
    }
  }),
  iconTypography: () => ({
    fontWeight: '600',
    marginRight: 5,
    color: mainTextColor,
    fontSize: 14,
    marginBottom: 5
  }),
  iconBox: () => ({
    width: 40,
    height: 30,
    border: borderTheme,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.error': {
      border: '1.5px solid red'
    }
  }),
  dropdown: () => ({
    border: borderTheme,
    borderRadius: 4,
    minWidth: 130,
    height: 30,

    '& div': {
      '&:last-child': {
        marginRight: 25
      }
    },
    '& > svg': {
      color: 'white',
      padding: 0,
      background: primaryColor,
      height: 'calc(100% + 2px)',
      top: '-1px',
      right: '-1px',
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4
    },
    '&.error': {
      border: '1.5px solid red'
    }
  }),
  radio: () => ({
    '&.error': {
      '& span': {
        '& div': {
          color: 'red'
        }
      }
    }
  }),
  settingTypography: {
    fontWeight: '600',
    marginRight: 5,
    color: mainTextColor,
    fontSize: 14,
    marginBottom: 5
  }
};

export default makeStyles(styles);
