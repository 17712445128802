import React, { useContext } from 'react';
import { Box, Grid, Typography, Accordion, AccordionDetails, AccordionSummary, Button, ButtonBase } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { OrderContext } from '../../contexts/OrderContext';
import { ModalContext } from '../../contexts/ModalContext';
import { TranslateTextComponent } from '../../translator';
import { ReactComponent as PlayCourseImg } from '../../assets/images/order/play_course.svg';
import { ReactComponent as StopCourseImg } from '../../assets/images/order/stop_course.svg';
import { SharedBy, ChairOrHalfPortion, Centerpiece } from './OrderIconComponents';
import OrderNotesComponent from './OrderNotesComponent';
import FB_ORDER_STATUSES from '../../constants/order/orderStatuses';
import { MaterialIcon } from '../../common-fe';
import FbCourseStatusIcon from './FbCourseStatusIcon';
import { redButton, borderTheme } from '../../styles/theme';
import modalStyle from '../../common-fe/src/styles/shared/modalStyle';
import styles from '../../styles/order/fbCourse';
import { getTotalQuantity, groupedByMenuItem } from '../../constants/order/order';

const FbCourseDrink = ({
  fbCoursesDrink,
  expanded,
  expansionKey,
  changingFbCourseStatus,
  handleSetChangingFbCourseStatus,
  notShowSummary,
  onClick,
  onEditFbMenuItem,
  onDeleteFbMenuItem,
  onEditFbCourse,
  onSaveFbCourse,
  onRestoreFbCourse,
  onChangePanel,
  onSetFbCourseStatus,
  onDeleteCourse,
  onPlayFbCourse,
  onStopFbCourse
}) => {
  const classes = styles();
  const classesModal = modalStyle();

  const orderContext = useContext(OrderContext);
  const modalContext = useContext(ModalContext);

  const reduceFbDrinkCourses = () => {
    const filteredDrinksFbCourses = orderContext.orderData.fbCourses.filter((fbCourse) => fbCourse.type === 'drink');
    return filteredDrinksFbCourses.map((drinkFbCourse) => {
      return drinkFbCourse.fbCoursesMenuItems.reduce(
        (objectsByKeyValue, menuItem) => {
          const { fbMenuItemId, name } = menuItem;
          objectsByKeyValue.id = drinkFbCourse.id;

          objectsByKeyValue.type = drinkFbCourse.type;
          objectsByKeyValue.number = drinkFbCourse.number;
          objectsByKeyValue.fbCourseStatus = drinkFbCourse.fbCourseStatus;
          objectsByKeyValue.menuItems[fbMenuItemId] = (objectsByKeyValue.menuItems[fbMenuItemId] || []).concat(menuItem);
          objectsByKeyValue.menuItems[fbMenuItemId].name = name;
          objectsByKeyValue.menuItems[fbMenuItemId].number = drinkFbCourse.number;
          return objectsByKeyValue;
        },
        { number: drinkFbCourse.number, menuItems: {}, fbCourseStatus: FB_ORDER_STATUSES.none.label, type: 'drink' }
      );
    });
  };

  let reducedFbDrinkCourses = reduceFbDrinkCourses();

  const openModalToDeleteCourse = (uuid) => {
    modalContext.openModal({
      class: 'primary',
      text: <TranslateTextComponent capitalize>delete-fb-course-question</TranslateTextComponent>,

      actionButtons: [
        <Button key={1} variant="outlined" color="primary" className={classesModal.invertedButton} onClick={modalContext.closeModal}>
          <TranslateTextComponent>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={2}
          color="primary"
          variant="contained"
          className={classesModal.buttonStyle}
          onClick={() => {
            modalContext.closeModal();
            onDeleteCourse(uuid);
          }}
        >
          <TranslateTextComponent>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  return (
    <Grid key={expansionKey} container style={{ marginBottom: 15 }} onClick={onClick}>
      <Box style={{ flex: 1, paddingRight: 10 }}>
        <Accordion className={classes.expansionPanel} expanded={expanded} onChange={onChangePanel} onClick={notShowSummary}>
          <AccordionSummary
            className={classes.expansionPanelSummary}
            expandIcon={<ExpandMoreIcon className={classes.expandArrow} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container justify="flex-start" alignItems="center">
              <Typography className={classes.drinkCourse}>
                <TranslateTextComponent uppercase>drink</TranslateTextComponent>
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.expansionPanelDetails}>
            {expanded &&
              fbCoursesDrink.map((fbCourseDrink, index) => {
                return (
                  <Grid
                    key={index}
                    container
                    direction="column"
                    alignItems="flex-start"
                    style={{ paddingBottom: 10, borderBottom: reducedFbDrinkCourses.length > 1 && borderTheme }}
                  >
                    <Grid container justify="flex-start" alignItems="baseline">
                      <FbCourseStatusIcon
                        fbCourse={fbCourseDrink}
                        macroCategory="drink"
                        showStatuses={`drink${fbCourseDrink.number}` === changingFbCourseStatus}
                        onClickIcon={() => handleSetChangingFbCourseStatus(`drink${fbCourseDrink.number}`)}
                        onSelectStatus={(status) => {
                          onSetFbCourseStatus(fbCourseDrink.uuid, status);
                        }}
                      />

                      <ButtonBase
                        className={
                          orderContext.activeFbCourseDrinkKey === `${orderContext.fbMacroCategory}${fbCourseDrink.number}`
                            ? [classes.fbCourseDrink, 'selected'].join(' ')
                            : classes.fbCourseDrink
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          notShowSummary();
                          orderContext.updateIsEditingFbCourse(false);
                          orderContext.updateActiveFbCourseDrinkKey(`${orderContext.fbMacroCategory}${fbCourseDrink.number}`);
                        }}
                      >
                        {fbCourseDrink.number}
                        <Typography>
                          <TranslateTextComponent capitalize>course</TranslateTextComponent>
                        </Typography>
                      </ButtonBase>

                      {fbCourseDrink.fbCoursesMenuItems.length === 0 && (
                        <Typography style={{ marginLeft: 10, fontSize: 14 }}>
                          <TranslateTextComponent uppercase>empty-course</TranslateTextComponent>
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      {groupedByMenuItem(fbCourseDrink.fbCoursesMenuItems).map((drinkCourse, i) => {
                        return (
                          <Grid key={i} container direction="column" style={{ paddingTop: 10 }}>
                            <Grid container direction="column">
                              <Typography className={classes.itemName}>
                                {getTotalQuantity(drinkCourse)}x {drinkCourse[0].name}
                              </Typography>
                            </Grid>
                            <Grid container style={{ paddingBottom: 5 }}>
                              {drinkCourse.map((drink, j) => {
                                return (
                                  <Grid key={j} style={{ marginRight: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    {drink.sharedBy && drink.sharedBy.length > 1 ? (
                                      <SharedBy
                                        item={drink}
                                        sharedBy={drink.sharedBy}
                                        fbChairs={orderContext.fbReservationTable.fbTable.fbChairs.sort((prev, next) => prev.number - next.number)}
                                      />
                                    ) : (
                                      <Grid style={{ paddingTop: drink.centerpiece ? 0 : 5 }}>
                                        {drink.fbChairId !== null && (
                                          <ChairOrHalfPortion
                                            item={drink}
                                            isHalfPortion={drink.halfPortion}
                                            chair={orderContext.getChairFromId(drink.fbChairId)}
                                          />
                                        )}
                                        {drink.centerpiece && <Centerpiece item={drink} />}
                                      </Grid>
                                    )}
                                    {drink.quantity && <Typography className={classes.quantityValue}>x{drink.quantity}</Typography>}
                                    {orderContext.isEditingCourse && orderContext.activeFbCourseDrinkKey === `drink${fbCourseDrink.number}` && (
                                      <Grid container direction="column">
                                        <ButtonBase style={{ color: 'red' }} onClick={() => onDeleteFbMenuItem(drink)}>
                                          <MaterialIcon iconName="delete" style={{ color: redButton, fontSize: 'large' }} />
                                        </ButtonBase>
                                        <ButtonBase onClick={() => onEditFbMenuItem(drink)}>
                                          <MaterialIcon iconName="edit" style={{ color: '#707070', fontSize: 'large' }} />
                                        </ButtonBase>
                                      </Grid>
                                    )}
                                  </Grid>
                                );
                              })}
                            </Grid>
                            {drinkCourse.some((drink) => drink.notes !== '' && drink.notes !== null) && (
                              <OrderNotesComponent items={drinkCourse} classes={classes} />
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>
                    {(fbCourseDrink.fbCoursesMenuItems.length !== 0 || orderContext.isEditingCourse) && (
                      <Grid container justify="space-between" alignItems="center" style={{ paddingBottom: 10 }}>
                        <Grid container justify="space-between" alignItems="center" style={{ flex: 1, marginBottom: 10 }}>
                          <Grid item>
                            {!orderContext.isEditingCourse &&
                              orderContext.activeFbCourseDrinkKey === `drink${fbCourseDrink.number}` &&
                              (fbCourseDrink.fbCourseStatus === FB_ORDER_STATUSES.none.label ||
                                fbCourseDrink.fbCourseStatus === FB_ORDER_STATUSES.todo.label ||
                                fbCourseDrink.isWaiting) && (
                                <ButtonBase className={classes.editFbMenuItemButton} onClick={() => onEditFbCourse(fbCourseDrink)}>
                                  <TranslateTextComponent uppercase>edit</TranslateTextComponent>
                                </ButtonBase>
                              )}
                          </Grid>
                          {fbCourseDrink.id && (
                            <Grid item className={classes.statusIconActionsContainer}>
                              <Grid>
                                {!orderContext.isEditingCourse &&
                                  (fbCourseDrink.isWaiting || fbCourseDrink.fbCourseStatus === FB_ORDER_STATUSES.none.label) && (
                                    <PlayCourseImg
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onPlayFbCourse(fbCourseDrink);
                                      }}
                                    />
                                  )}
                              </Grid>
                              {!orderContext.isEditingCourse &&
                                fbCourseDrink.fbCourseStatus !== FB_ORDER_STATUSES.completed.label &&
                                fbCourseDrink.fbCourseStatus !== FB_ORDER_STATUSES.none.label &&
                                !fbCourseDrink.isWaiting && (
                                  <StopCourseImg
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onStopFbCourse(fbCourseDrink.uuid);
                                    }}
                                  />
                                )}
                            </Grid>
                          )}
                        </Grid>

                        <Grid container justify="space-between">
                          {orderContext.isEditingCourse && orderContext.activeFbCourseDrinkKey === `drink${fbCourseDrink.number}` && (
                            <>
                              <ButtonBase className={classes.optionsFbMenuItemButton} onClick={() => onSaveFbCourse(fbCourseDrink)}>
                                <TranslateTextComponent uppercase>save-and-close</TranslateTextComponent>
                              </ButtonBase>
                              <ButtonBase className={classes.optionsFbMenuItemButton} onClick={() => onRestoreFbCourse(fbCourseDrink)}>
                                <TranslateTextComponent uppercase>undo-changes</TranslateTextComponent>
                              </ButtonBase>
                              {fbCourseDrink.id && (
                                <ButtonBase className={classes.optionsFbMenuItemButton} onClick={() => openModalToDeleteCourse(fbCourseDrink.uuid)}>
                                  <TranslateTextComponent uppercase>delete-course</TranslateTextComponent>
                                </ButtonBase>
                              )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                );
              })}
            {orderContext.orderData.fbCourses.some((fbCourse) => {
              return fbCourse.type === 'drink' && fbCourse.fbCoursesMenuItems.length > 0;
            }) && (
              <Button
                color="primary"
                variant="contained"
                className={classes.newCourseButton}
                onClick={() => {
                  orderContext.addNewEmptyDrinkCourse();
                  reducedFbDrinkCourses = reduceFbDrinkCourses();
                }}
              >
                <TranslateTextComponent>new-course</TranslateTextComponent>
              </Button>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Grid>
  );
};

export default FbCourseDrink;
