/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { TranslateTextComponent } from '../../translator';

const textTranslate = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

const getRoomServiceColumns = () => {
  return [
    {
      id: 'roomNumber',
      disablePadding: true,
      width: '10%',
      label: textTranslate('room'),
      isSortable: true,
      render: (item) => null
    },
    {
      id: 'name',
      disablePadding: true,
      width: '15%',
      label: textTranslate('client'),
      isSortable: true,
      render: (item) => null
    },
    {
      id: 'orderTime',
      disablePadding: true,
      width: '10%',
      label: textTranslate('order-time'),
      isSortable: false,
      render: (item) => null
    },
    {
      id: 'requestTime',
      disablePadding: true,
      width: '10%',
      label: textTranslate('request-time'),
      isSortable: false,
      render: (item) => null
    },
    {
      id: 'state',
      disablePadding: true,
      width: '10%',
      label: textTranslate('state'),
      isSortable: false,
      render: (item) => null
    },
    {
      id: 'notes',
      disablePadding: true,
      width: '15%',
      label: textTranslate('notes'),
      isSortable: true,
      render: (item) => null
    }
  ];
};

export default getRoomServiceColumns;
