import React, { useContext } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import typy from 'typy';
import orderBy from 'lodash/orderBy';
import { borderTheme, CommonTable, MaterialIcon, primaryColor, secondaryColor } from '../../common-fe/src';
import billSummaryColumns from '../../constants/cash/billSummaryColumns';
import { LanguageContext } from '../../contexts/LanguageContext';
import styles from '../../styles/cash/cashStyle';
import { translate } from '../../translator/index';
import TotalKeyboard from './TotalKeyboard';

const BillSummary = ({
  isFastCash,
  isPayingPM,
  activeBillTabUUID,
  showKeyboard,
  keyboardLabel,
  selectedRowUUID,
  orderBills,
  onBillTabChange,
  onMoreTabButtonClick,
  onCloseOrderBillTabClick,
  openDiscountModal,
  onDeleteDiscount,
  onDeleteDiscountPercentage,
  onSetQuantity,
  onSetPrice,
  onKeyboardSubmit,
  deleteBillItem,
  deleteAllBillItems,
  onShowKeyboardClick,
  closeKeyboard
}) => {
  const { language } = useContext(LanguageContext);
  const classes = styles({ isFastCash });
  const columns = billSummaryColumns({ deleteBillItem, deleteAllBillItems, keyboardLabel, selectedRowUUID, onSetQuantity, onSetPrice });
  const openOrderBillsCounter = orderBills?.filter((order) => order.status === 'open').length;

  const getTabColor = (orderBill) => {
    if (orderBill.status.toLowerCase() === 'complete' && orderBill.uuid === activeBillTabUUID) {
      return 'rgb(79, 152, 79)';
    }
    if (orderBill.status.toLowerCase() === 'complete' && orderBill.uuid !== activeBillTabUUID) {
      return 'rgb(187, 217, 187)';
    }
    if (orderBill.status.toLowerCase() === 'cancelled' && orderBill.uuid === activeBillTabUUID) {
      return 'rgb(183, 61, 58)';
    }
    if (orderBill.status.toLowerCase() === 'cancelled' && orderBill.uuid !== activeBillTabUUID) {
      return 'rgb(189, 110, 108)';
    }

    if (orderBill.uuid === activeBillTabUUID) {
      return primaryColor;
    }
    return 'rgb(163, 224, 255)';
  };

  return (
    <Box className={classes.billSummaryContainer}>
      <Box className={classes.tabsContainer} style={{ borderBottom: `1px solid ${primaryColor}` }}>
        {!isPayingPM && activeBillTabUUID && orderBills?.length > 0 && (
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={orderBills.find((orderBill) => orderBill.uuid === activeBillTabUUID) ? activeBillTabUUID : orderBills[0].uuid}
          >
            {orderBills
              .filter((order) => order.status === 'open')
              .map((orderBill, index) => (
                <Tab
                  key={orderBill.uuid}
                  className={classes.tab}
                  label={
                    <Box className={classes.tabIcon}>
                      <span>{`${translate('bill', language).toUpperCase()} ${index + 1}`}</span>
                      <MaterialIcon iconName="Cancel" onClick={() => onCloseOrderBillTabClick(orderBill.uuid)} />
                    </Box>
                  }
                  style={{ backgroundColor: getTabColor(orderBill) }}
                  value={orderBill.uuid}
                  onClick={() => onBillTabChange(orderBill.uuid)}
                />
              ))}
            {orderBills
              .filter((order) => order.status !== 'open')
              .map((orderBill, index) => (
                <Tab
                  key={orderBill.uuid}
                  className={classes.tab}
                  label={
                    <Box className={classes.tabIcon}>
                      <div>{`${translate('bill', language).toUpperCase()} ${index + openOrderBillsCounter + 1}`}</div>
                      {orderBill.status === 'open' && <MaterialIcon iconName="Cancel" onClick={() => onCloseOrderBillTabClick(orderBill.uuid)} />}
                    </Box>
                  }
                  style={{ backgroundColor: getTabColor(orderBill) }}
                  value={orderBill.uuid}
                  onClick={() => onBillTabChange(orderBill.uuid)}
                />
              ))}
            <Tab
              className={classes.tab}
              style={{ backgroundColor: '#EDFAFF', color: '#91C9E8' }}
              icon={<MaterialIcon iconName="Add" />}
              onClick={onMoreTabButtonClick}
            />
          </Tabs>
        )}
      </Box>
      <Box
        style={{
          flex: 1,
          boxShadow: '0px 3px 6px #00000029',
          overflowY: 'auto'
        }}
      >
        {orderBills?.map((orderBill) => {
          if (orderBill.uuid === activeBillTabUUID) {
            return (
              <CommonTable
                key={orderBill.uuid}
                size="small"
                headerCellStyle={{ backgroundColor: secondaryColor, borderBottom: borderTheme }}
                items={orderBy(orderBill.fbBillsRows ?? [], ['fbCoursesMenuItem.fbCourse.type', 'name'], ['desc'])}
                columns={columns}
                tableContainerStyle={{ flex: 1, overflowY: 'auto' }}
              />
            );
          }
        })}
      </Box>
      <TotalKeyboard
        showKeyboard={showKeyboard}
        total={
          typy(
            typy(orderBills).safeArray.find((orderBill) => orderBill.uuid === activeBillTabUUID),
            'total'
          ).safeNumber
        }
        keyboardLabel={keyboardLabel}
        activeBillTabUUID={activeBillTabUUID}
        orderBills={orderBills}
        discount={123}
        onShowKeyboardClick={onShowKeyboardClick}
        onSetQuantity={onSetQuantity}
        onSetPrice={onSetPrice}
        openDiscountModal={openDiscountModal}
        onDeleteDiscount={onDeleteDiscount}
        onDeleteDiscountPercentage={onDeleteDiscountPercentage}
        onSubmit={onKeyboardSubmit}
        closeKeyboard={closeKeyboard}
      />
    </Box>
  );
};

export default BillSummary;
