/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import { FB_CHAIR_FRAGMENT } from '../fragments';

export const SET_RESERVATION_TABLES = gql`
  mutation setFbReservationTables($fbTableUUIDs: [String!]!, $fbReservationUUID: String!) {
    setFbReservationTables(fbTableUUIDs: $fbTableUUIDs, fbReservationUUID: $fbReservationUUID) {
      id
    }
  }
`;

export const ASSIGN_FAST_FB_TABLE = gql`
  mutation assignFastFbTable($fbTableUUID: String!) {
    assignFastFbTable(fbTableUUID: $fbTableUUID) {
      id
      uuid
      number
    }
  }
`;

export const MERGE_TABLES = gql`
  mutation mergeFbTables($fbTableUUID1: String!, $fbTableUUID2: String!) {
    mergeFbTables(fbTableUUID1: $fbTableUUID1, fbTableUUID2: $fbTableUUID2)
  }
`;

export const SPLIT_TABLE = gql`
  mutation splitFbTable($fbTableUUID: String!, $capacity1: Int!, $capacity2: Int!) {
    splitFbTable(fbTableUUID: $fbTableUUID, capacity1: $capacity1, capacity2: $capacity2)
  }
`;

export const ADD_CHAIR = gql`
  mutation addFbTableChairs($fbTableUUID: String!, $chairNumber: Int!) {
    addFbTableChairs(fbTableUUID: $fbTableUUID, chairNumber: $chairNumber)
  }
`;

export const ASSIGN_CHAIR = gql`
  mutation assignChair($gender: SexCode, $guestUUID: String, $guestPhysicalCategoryUUID: String, $fbChairUUID: String!) {
    assignChair(gender: $gender, guestUUID: $guestUUID, guestPhysicalCategoryUUID: $guestPhysicalCategoryUUID, fbChairUUID: $fbChairUUID) {
      ...fbChairFragment
    }
  }
  ${FB_CHAIR_FRAGMENT}
`;

export const FREE_TABLE = gql`
  mutation freeTable($fbTableUUID: String!) {
    freeTable(fbTableUUID: $fbTableUUID) {
      id
      number
    }
  }
`;

export const MOVE_TABLE = gql`
  mutation moveTable($fbTableUUIDFrom: String!, $fbTableUUIDTo: String!) {
    moveTable(fbTableUUIDFrom: $fbTableUUIDFrom, fbTableUUIDTo: $fbTableUUIDTo) {
      id
      number
    }
  }
`;

export const MOVE_CHAIR = gql`
  mutation moveChair($fbChairUUIDTo: String!, $fbChairUUIDFrom: String!) {
    moveChair(fbChairUUIDTo: $fbChairUUIDTo, fbChairUUIDFrom: $fbChairUUIDFrom)
  }
`;
