import { makeStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    display: 'grid',
    gridTemplateAreas: '"photo name" "photo reservation" "photo room" "buttons buttons"',
    gridRowGap: 10,
    gridColumnGap: 5,
    maxWidth: 500,
    maxHeight: 180,
    borderRadius: 10,
    border: '1px solid #00B7FF',
    width: '100%',
    height: '100%',
    padding: 10,
    justifySelf: 'center',
    '& *': {
      fontSize: 14,
      color: '#55595C'
    }
  },
  photo: ({ photoUrl }) => ({
    gridArea: 'photo',
    '& img': {
      borderRadius: 10,
      objectFit: 'contain',
      objectPosition: 'initial',
      minHeight: 77,
      minWidth: 77
    }
  }),
  name: {
    gridArea: 'name',
    '& p': {
      fontWeight: 'bold',
      textTransform: 'uppercase'
    }
  },
  reservation: {
    gridArea: 'reservation',
    '& span:last-child': {
      fontWeight: 'bold',
      paddingLeft: 5
    }
  },
  room: {
    gridArea: 'room',
    display: 'flex',
    justifyContent: 'space-between',
    '& span:last-child': {
      fontWeight: 'bold',
      paddingLeft: 5
    }
  },
  buttons: {
    gridArea: 'buttons',
    '& button': {
      padding: '5px 10px',
      borderRadius: 11,
      maxHeight: 29,
      width: 'auto',
      boxShadow: 'none',
      '& span': { color: 'white', font: 'Bold 14px/16px Roboto;' },
      '& img': {
        marginRight: 10
      }
    }
  }
};

export default makeStyles(styles);
