import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import typy from 'typy';
import SingleTableDetail from '../../shared/SingleTableDetail';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translate, capitalizeFirstLetter } from '../../../translator';

const getMood = (unicode) => {
  const cleanedUnicode = unicode.replace(/<\/?[^>]+(>|$)/g, '');
  return <span className="emoji" role="img" aria-label="good" dangerouslySetInnerHTML={{ __html: cleanedUnicode }} />;
};

const TableDetail = ({ fbReservation }) => {
  const { language } = useContext(LanguageContext);

  return (
    <Grid container justify="center" style={{ textAlign: 'center' }}>
      <Grid item md={4} xs={4}>
        <SingleTableDetail
          title={capitalizeFirstLetter(translate('client-type', language))}
          description={typy(fbReservation, 'bookedBy.guestType.name').safeString || '-'}
        />
        <SingleTableDetail
          title={capitalizeFirstLetter(translate('reservation-channel', language))}
          description={typy(fbReservation, 'channel.name').safeString || '-'}
        />
        <SingleTableDetail title={translate('n-pax', language)} description={typy(fbReservation, 'nPax').safeNumber || '-'} />
      </Grid>
      <Grid item md={4} xs={4}>
        <SingleTableDetail
          title={capitalizeFirstLetter(translate('requests', language))}
          description={typy(fbReservation, 'requests').safeString || '-'}
        />
        <SingleTableDetail
          title={capitalizeFirstLetter(translate('mood', language))}
          description={getMood(typy(fbReservation, 'bookedBy.guestMoods[0].mood.unicode').safeString || '-')}
        />
        <SingleTableDetail
          title={capitalizeFirstLetter(translate('package', language))}
          description={typy(fbReservation, 'package').safeString || '-'}
        />
      </Grid>
      <Grid item md={4} xs={4}>
        <SingleTableDetail
          title={capitalizeFirstLetter(translate('arrangement', language))}
          description={typy(fbReservation, 'arrangement').safeString ? typy(fbReservation, 'arrangement').safeString : '-'}
        />
        <SingleTableDetail
          title={capitalizeFirstLetter(translate('room-number', language))}
          description={typy(fbReservation, 'guestRoom').safeBoolean || '-'}
        />
        <SingleTableDetail
          title={capitalizeFirstLetter(translate('repeater', language))}
          description={typy(fbReservation, 'isRepeater').safeBoolean || '-'}
        />
      </Grid>
    </Grid>
  );
};

export default TableDetail;
