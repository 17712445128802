import { gql } from 'apollo-boost';
import { USER_FRAGMENT } from '../../fragments';

export const UPDATE_USER = gql`
  mutation updateUser($userInput: EditUserDto!) {
    updateUser(userInput: $userInput) {
      ...userFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      ...userFragment
    }
  }
  ${USER_FRAGMENT}
`;
