/* eslint-disable no-nested-ternary */
import { makeStyles } from '@material-ui/core/styles';

const styles = {
  container: ({ color, size, highlighted }) => ({
    position: 'relative',
    border: `1px solid ${color}`,
    borderRadius: '50%',
    height: highlighted ? size + 4 : size,
    width: highlighted ? size + 4 : size,
    boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.3)'
  }),
  filled: ({ color, size, highlighted }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: color,
    borderRadius: '50%',
    height: highlighted ? size + 4 - 2.75 : size - 2.75,
    width: highlighted ? size + 4 - 2.75 : size - 2.75,
    transform: 'translate(-50%,-50%)'
  })
};

export default makeStyles(styles);
