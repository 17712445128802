import gql from 'graphql-tag';
import { FbBillRowFragment, FbBillFragment } from './queries';
import { FB_CHAIR_FRAGMENT } from '../fragments';

export const SET_BILL_ITEM_QUANTITY = gql`
  mutation setFbBillItemQuantity($quantity: Float!, $fbBillsRowUUID: String!) {
    setFbBillItemQuantity(quantity: $quantity, fbBillsRowUUID: $fbBillsRowUUID) {
      data {
        ...FbBillFragment
        fbPaymentMethod {
          id
          uuid
          code
        }
        fbBillsRows {
          ...FbBillRowFragment
        }
      }
    }
  }
  ${FbBillFragment}
  ${FbBillRowFragment}
`;

export const TRANSFER_FB_ORDER = gql`
  mutation transferFbOrder($fbOrderUUID: String!, $fbTableUUID: String!) {
    transferFbOrder(fbOrderUUID: $fbOrderUUID, fbTableUUID: $fbTableUUID)
  }
`;

export const SET_BILL_ITEM_PRICE = gql`
  mutation setFbBillItemPrice($price: Float!, $fbBillsRowUUID: String!) {
    setFbBillItemPrice(price: $price, fbBillsRowUUID: $fbBillsRowUUID) {
      data {
        ...FbBillFragment
        fbPaymentMethod {
          id
          uuid
          code
        }
        fbBillsRows {
          ...FbBillRowFragment
        }
      }
    }
  }
  ${FbBillFragment}
  ${FbBillRowFragment}
`;

export const ADD_ITEM_TO_BILL = gql`
  mutation addItemToBill($quantity: Float!, $fbBillUUID: String!, $fbCoursesMenuItemUUID: String!) {
    addItemToBill(quantity: $quantity, fbBillUUID: $fbBillUUID, fbCoursesMenuItemUUID: $fbCoursesMenuItemUUID) {
      data {
        ...FbBillFragment
        fbPaymentMethod {
          id
          uuid
          code
        }
        fbBillsRows {
          ...FbBillRowFragment
        }
      }
    }
  }
  ${FbBillFragment}
  ${FbBillRowFragment}
`;

export const ADD_ALL_ITEMS_TO_BILL = gql`
  mutation addAllItemsToBill($fbBillUUID: String!) {
    addAllItemsToBill(fbBillUUID: $fbBillUUID) {
      data {
        ...FbBillFragment
        fbPaymentMethod {
          id
          uuid
          code
        }
        fbBillsRows {
          ...FbBillRowFragment
        }
        payMaster {
          id
          uuid
          name
          type
        }
        fbBillDocuments {
          id
          uuid
          type
          name
          path
          url
          description
        }
      }
    }
  }
  ${FbBillFragment}
  ${FbBillRowFragment}
`;

export const REMOVE_ITEM_FROM_BILL = gql`
  mutation removeItemFromBill($fbBillRowUUID: String!) {
    removeItemFromBill(fbBillRowUUID: $fbBillRowUUID) {
      data {
        ...FbBillFragment
        fbPaymentMethod {
          id
          uuid
          code
        }
        fbBillsRows {
          ...FbBillRowFragment
        }
      }
    }
  }
  ${FbBillFragment}
  ${FbBillRowFragment}
`;

export const REMOVE_ALL_ITEMS_FROM_BILL = gql`
  mutation removeAllItemsFromBill($fbBillUUID: String!) {
    removeAllItemsFromBill(fbBillUUID: $fbBillUUID) {
      data {
        ...FbBillFragment
        fbPaymentMethod {
          id
          uuid
          code
        }
        fbBillsRows {
          ...FbBillRowFragment
        }
      }
    }
  }
  ${FbBillFragment}
  ${FbBillRowFragment}
`;

export const ADD_ORDER_ITEM_FROM_CASH = gql`
  mutation addOrderItemFromCash($fbOrderUUID: String!, $fbCourseMenuItem: FbCoursesMenuItemsInput!) {
    addOrderItemFromCash(fbOrderUUID: $fbOrderUUID, fbCourseMenuItem: $fbCourseMenuItem) {
      total
      items {
        id
        uuid
        name
        quantity
        price
        fbMenuItemId
        fbChairId
        centerpiece
        halfPortion
        sharedBy
        with
        without
        notes
        fbCourse {
          id
          fbCourseStatus
          type
        }
        fbMenuItem {
          id
          uuid
        }
        fbChair {
          ...fbChairFragment
        }
      }
    }
  }
  ${FB_CHAIR_FRAGMENT}
`;

export const ADD_ITEMS_TO_BILL = gql`
  mutation addItemsToBill($fbBillUUID: String!, $fbCoursesMenuItemUUIDs: [String!]!) {
    addItemsToBill(fbBillUUID: $fbBillUUID, fbCoursesMenuItemUUIDs: $fbCoursesMenuItemUUIDs) {
      data {
        ...FbBillFragment
        fbPaymentMethod {
          id
          uuid
          code
        }
        fbBillsRows {
          ...FbBillRowFragment
        }
      }
    }
  }
  ${FbBillFragment}
  ${FbBillRowFragment}
`;

export const ADD_ITEMS_TO_BILL_BY_CHAIR = gql`
  mutation addItemsToBillByChair($fbBillUUID: String!, $fbChairUUIDs: [String!]!) {
    addItemsToBillByChair(fbBillUUID: $fbBillUUID, fbChairUUIDs: $fbChairUUIDs) {
      data {
        ...FbBillFragment
        fbPaymentMethod {
          id
          uuid
          code
        }
        fbBillsRows {
          ...FbBillRowFragment
        }
      }
    }
  }
  ${FbBillFragment}
  ${FbBillRowFragment}
`;

export const CANCEL_FB_BILL = gql`
  mutation cancelFbBill($fbBillUUID: String!) {
    cancelFbBill(fbBillUUID: $fbBillUUID) {
      totalCount
      data {
        ...FbBillFragment
        fbPaymentMethod {
          id
          uuid
          code
        }
        fbBillsRows {
          ...FbBillRowFragment
        }
        payMaster {
          id
          uuid
          name
          type
        }
        fbBillDocuments {
          id
          uuid
          type
          name
          path
          url
          description
        }
      }
    }
  }
  ${FbBillFragment}
  ${FbBillRowFragment}
`;

export const PAY_BILL = gql`
  mutation payFbBill(
    $paymentMethodCode: FbPaymentMethodsEnum!
    $fbBillUUID: String!
    $creditCardType: CreditCardTypes
    $fiscalPrintType: FiscalPrintTypes
    $zRepNumber: String
    $fiscalReceiptNumber: String
    $fiscalReceiptAmount: String
    $fiscalReceiptDate: String
    $fiscalReceiptTime: String
  ) {
    payFbBill(
      paymentMethodCode: $paymentMethodCode
      fbBillUUID: $fbBillUUID
      creditCardType: $creditCardType
      fiscalPrintType: $fiscalPrintType
      zRepNumber: $zRepNumber
      fiscalReceiptNumber: $fiscalReceiptNumber
      fiscalReceiptAmount: $fiscalReceiptAmount
      fiscalReceiptDate: $fiscalReceiptDate
      fiscalReceiptTime: $fiscalReceiptTime
    ) {
      data {
        ...FbBillFragment
        fbPaymentMethod {
          id
          uuid
          code
        }
        fbBillsRows {
          ...FbBillRowFragment
        }
      }
    }
  }
  ${FbBillFragment}
  ${FbBillRowFragment}
`;

export const CREATE_PAY_MASTER = gql`
  mutation createPayMaster($guestUUID: String!, $payMasterData: CreatePayMastersDto!) {
    createPayMaster(guestUUID: $guestUUID, payMasterData: $payMasterData) {
      id
      uuid
      name
      expirationDate
      type
      guest {
        id
        uuid
        firstname
        lastname
        gender
        dob
        nationality
        address
        city
        zipCode
        country
        email
        phone
        mobilePhone
        favouriteWine
        favouriteWater
        preferences
        isRepeater
        language {
          ISO639_1
          ISO639_3
          name
        }
        guestType {
          id
          uuid
          name
          description
        }
      }
      guestList {
        id
        uuid
        firstname
        lastname
        dob
        email
      }
    }
  }
`;

export const EDIT_PAY_MASTER = gql`
  mutation editPayMaster($guestUUID: String!, $payMasterUUID: String!, $payMasterData: CreatePayMastersDto!) {
    editPayMaster(guestUUID: $guestUUID, payMasterUUID: $payMasterUUID, payMasterData: $payMasterData) {
      id
      uuid
      name
      expirationDate
      type
      guest {
        id
        uuid
        firstname
        lastname
        gender
        dob
        nationality
        address
        city
        zipCode
        country
        email
        phone
        mobilePhone
        favouriteWine
        favouriteWater
        preferences
        isRepeater
        language {
          ISO639_1
          ISO639_3
          name
        }
        guestType {
          id
          uuid
          name
          description
        }
      }
      guestList {
        id
        uuid
        firstname
        lastname
        dob
        email
      }
    }
  }
`;

export const SPLIT_ORDER_EQUALLY = gql`
  mutation splitFbOrderEqually($fbBillsNumber: Int!, $fbOrderUUID: String!) {
    splitFbOrderEqually(fbBillsNumber: $fbBillsNumber, fbOrderUUID: $fbOrderUUID) {
      data {
        ...FbBillFragment
        fbPaymentMethod {
          id
          uuid
          code
        }
        fbBillsRows {
          ...FbBillRowFragment
        }
      }
    }
  }
  ${FbBillFragment}
  ${FbBillRowFragment}
`;

export const SET_BILL_DISCOUNT_PERCENTAGE = gql`
  mutation setFbBillIDiscountPercentage($discountPercentage: Float, $discountPercentageNotes: String, $fbBillUUID: String!) {
    setFbBillIDiscountPercentage(
      discountPercentage: $discountPercentage
      discountPercentageNotes: $discountPercentageNotes
      fbBillUUID: $fbBillUUID
    ) {
      data {
        ...FbBillFragment
        fbPaymentMethod {
          id
          uuid
          code
        }
        fbBillsRows {
          ...FbBillRowFragment
        }
      }
    }
  }
  ${FbBillFragment}
  ${FbBillRowFragment}
`;

export const SET_BILL_DISCOUNT = gql`
  mutation setFbBillIDiscount($discount: Float, $discountNotes: String, $fbBillUUID: String!) {
    setFbBillIDiscount(discount: $discount, discountNotes: $discountNotes, fbBillUUID: $fbBillUUID) {
      data {
        ...FbBillFragment
        fbPaymentMethod {
          id
          uuid
          code
        }
        fbBillsRows {
          ...FbBillRowFragment
        }
      }
    }
  }
  ${FbBillFragment}
  ${FbBillRowFragment}
`;

export const CREATE_ORDER_BILL = gql`
  mutation createOrderBill($fbOrderUUID: String!) {
    createOrderBill(fbOrderUUID: $fbOrderUUID) {
      data {
        ...FbBillFragment
        fbPaymentMethod {
          id
          uuid
          code
        }
        fbBillsRows {
          ...FbBillRowFragment
        }
      }
    }
  }
  ${FbBillFragment}
  ${FbBillRowFragment}
`;

export const CREATE_FAST_ORDER_BILL = gql`
  mutation createFastFbOrder($fbOutletUUID: String!) {
    createFastFbOrder(fbOutletUUID: $fbOutletUUID) {
      data {
        id
        uuid
        status
        fbCourses {
          id
          uuid
        }
      }
    }
  }
`;

export const DELETE_ORDER_BILL = gql`
  mutation deleteOrderBill($fbBillUUID: String!) {
    deleteOrderBill(fbBillUUID: $fbBillUUID) {
      data {
        ...FbBillFragment
        fbPaymentMethod {
          id
          uuid
          code
        }
        fbBillsRows {
          ...FbBillRowFragment
        }
      }
    }
  }
  ${FbBillFragment}
  ${FbBillRowFragment}
`;

export const DELETE_ORDER_ITEMS_FROM_CASH = gql`
  mutation deleteFbOrderItemsFromCash($fbCourseMenuItemUUIDs: [String!]!) {
    deleteFbOrderItemsFromCash(fbCourseMenuItemUUIDs: $fbCourseMenuItemUUIDs) {
      total
      items {
        id
        uuid
        name
        quantity
        price
        fbMenuItemId
        fbChairId
        centerpiece
        halfPortion
        sharedBy
        with
        without
        notes
        fbCourse {
          id
          fbCourseStatus
          type
        }
        fbMenuItem {
          id
          uuid
        }
        fbChair {
          ...fbChairFragment
        }
      }
    }
  }
  ${FB_CHAIR_FRAGMENT}
`;

export const DELETE_ORDER_ITEM_FROM_CASH = gql`
  mutation deleteFbOrderItemFromCash($fbCourseMenuItemUUID: String!) {
    deleteFbOrderItemFromCash(fbCourseMenuItemUUID: $fbCourseMenuItemUUID) {
      total
      items {
        id
        uuid
        name
        quantity
        price
        fbMenuItemId
        fbChairId
        centerpiece
        halfPortion
        sharedBy
        with
        without
        notes
        fbCourse {
          id
          fbCourseStatus
          type
        }
        fbMenuItem {
          id
          uuid
        }
        fbChair {
          ...fbChairFragment
        }
      }
    }
  }
  ${FB_CHAIR_FRAGMENT}
`;

export const CLOSE_FAST_ORDER = gql`
  mutation closeFastFbOrder($fbOrderUUID: String!) {
    closeFastFbOrder(fbOrderUUID: $fbOrderUUID) {
      data {
        id
        uuid
        status
      }
    }
  }
`;

export const ADD_BILL_TO_PAY_MASTER = gql`
  mutation addFbBIllToPayMaster($fbBillUUID: String!, $payMasterUUID: String!, $guestUUID: String) {
    addFbBIllToPayMaster(fbBillUUID: $fbBillUUID, payMasterUUID: $payMasterUUID, guestUUID: $guestUUID) {
      data {
        ...FbBillFragment
        fbPaymentMethod {
          id
          uuid
          code
        }
        fbBillDocuments {
          id
          uuid
          type
          name
          path
          url
          description
        }
        fbBillsRows {
          ...FbBillRowFragment
        }
      }
    }
  }
  ${FbBillFragment}
  ${FbBillRowFragment}
`;

export const UPLOAD_BILL_DOCUMENT = gql`
  mutation uploadFbBillCheck($fbBillUUID: String!, $image: ImageInputDto!, $documentType: FbBillsCheckEnum!) {
    uploadFbBillCheck(fbBillUUID: $fbBillUUID, image: $image, documentType: $documentType) {
      id
      uuid
      type
      name
      path
      url
      description
    }
  }
`;
