import React, { useContext } from 'react';
import { Typography, Grid, Checkbox, Box } from '@material-ui/core';
import moment from 'moment';
import typy from 'typy';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { TranslateTextComponent, translate, capitalizeFirstLetter } from '../../../../translator';
import NPaxBox from './NPaxBox';
import SingleTableDetail from '../../../shared/SingleTableDetail';
import styles from '../../../../styles/shared/tableListDetails';
import { textTranslate } from '../../../../utils/functions';

const ReservationDetails = ({ item }) => {
  const { language } = useContext(LanguageContext);

  const translateText = (title) => {
    return capitalizeFirstLetter(translate(title, language));
  };

  const classes = styles();
  return (
    <Box>
      <Box style={{ display: 'flex' }}>
        <Grid container>
          <SingleTableDetail title={translateText('outlet')} description={item?.fbService?.fbOutlet?.name ?? '-'} />
          <SingleTableDetail title={translateText('reservation-date')} description={moment(item.date, 'YYYY-MM-DD').format('DD-MM-YYYY')} />
          <SingleTableDetail title={translateText('language')} description={typy(item, 'bookedBy.language.name').safeString || '-'} />
          <SingleTableDetail title={translateText('email')} description={typy(item, 'bookedBy.email').safeString || '-'} />
          <SingleTableDetail title={translateText('reservation-time')} description={typy(item, 'reservationTime').safeString || '-'} />
          <Grid item xs={2}>
            <Typography className={classes.titleTypography}>
              <TranslateTextComponent capitalize>repeater</TranslateTextComponent>
            </Typography>
            <Checkbox color="primary" checked={typy(item, 'bookedBy.fbReservations').safeArray.length > 0} className={classes.checkbox} />
          </Grid>
          <SingleTableDetail title={translateText('date-of-birth')} description={typy(item, 'bookedBy.dob').safeString || '-'} />
          <SingleTableDetail
            title={translateText('mobile')}
            description={typy(item, 'bookedBy.mobilePhone').safeString || typy(item, 'bookedBy.phone').safeString || '-'}
          />
          <SingleTableDetail
            title={capitalizeFirstLetter(translate('allergens', language))}
            description={
              item?.bookedBy?.fbProfilePreferences?.allergens?.length > 0
                ? item?.bookedBy?.fbProfilePreferences?.allergens?.map((allergen) => capitalizeFirstLetter(translate(allergen, language)))?.join(', ')
                : '-'
            }
          />
          <SingleTableDetail
            title={capitalizeFirstLetter(translate('intolerances', language))}
            description={
              item?.bookedBy?.fbProfilePreferences?.intolerances?.length > 0
                ? item?.bookedBy?.fbProfilePreferences?.intolerances
                    ?.map((intolerance) => capitalizeFirstLetter(translate(intolerance, language)))
                    ?.join(', ')
                : '-'
            }
          />
          <SingleTableDetail title={translateText('preferences')} description={typy(item, 'bookedBy.preferences').safeString || '-'} />
          <SingleTableDetail title={translateText('type')} description={item.bookedBy?.guestType?.name ?? '-'} />
          <SingleTableDetail title={translateText('mood')} description={item.bookedBy?.guestMoods?.[0]?.mood?.unicode ?? '-'} />
          <SingleTableDetail title={translateText('requests')} description={item.requests ?? '-'} />
        </Grid>
      </Box>
      <Grid container>
        {item.fbGuestList.filter((fbGuest) => fbGuest.guestPhysicalCategory.hasAgeInput).length > 0 && (
          <Grid item xs={2}>
            <NPaxBox component="details" fbGuestList={item.fbGuestList} />
          </Grid>
        )}
        <Grid item xs={2}>
          <Typography className={classes.titleTypography}>
            <TranslateTextComponent capitalize>created-by</TranslateTextComponent>
          </Typography>
          <Typography className={classes.descriptionTypography}>
            {item?.createdBy?.firstName ? `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}` : item?.createdBy?.username}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.titleTypography}>
            <TranslateTextComponent capitalize>created-at</TranslateTextComponent>
          </Typography>
          <Typography className={classes.descriptionTypography}>
            {item?.createdAt ? moment(item?.createdAt).format('DD/MM/YYYY - HH:mm') : ' - '}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            marginBottom: 10
          }}
        >
          <Typography className={classes.titleTypography}>
            <TranslateTextComponent capitalize>notes</TranslateTextComponent>
          </Typography>
          <Typography className={classes.descriptionTypography}>{typy(item, 'notes').safeString || '-'}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReservationDetails;
