import React from 'react';
import styled from 'styled-components/macro';
import { TextField } from '@material-ui/core';
import { CustomModal, CommonTable, mainTextColor } from '../../../common-fe/src';
import { TranslateTextComponent } from '../../../translator';
import InputWithLabel from '../../shared/InputWithLabel';
import orderListColumns from './table/orderListColumns';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;

  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const RowContainer = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 20px;
  }
`;

const OrderDetailModal = ({ isDelivery, orderData, showModal, closeModal }) => {
  const getTotalPrice = () => {
    const totalPrices = orderData?.fbCourses
      .map((fbCourse) => ({ ...fbCourse }))
      .map((course) => course?.fbCoursesMenuItems?.map((item) => item.price * item.quantity))
      .flat();

    return totalPrices?.reduce((p, v) => p + v) ?? 0;
  };

  return (
    <CustomModal
      styledHeader
      height="auto"
      width={857}
      header={
        <TranslateTextComponent vars={{ number: orderData?.id }} uppercase>
          order-#
        </TranslateTextComponent>
      }
      styleBody={{ padding: '35px 60px' }}
      showModal={showModal}
      onClose={closeModal}
    >
      <Container>
        <RowContainer>
          <InputWithLabel label="order-number">
            <TextField fullWidth variant="outlined" color="primary" disabled value={orderData?.id ?? ''} />
          </InputWithLabel>
          <InputWithLabel label={isDelivery ? 'delivery-date-and-time' : 'takeaway-date-and-time'}>
            <TextField fullWidth variant="outlined" color="primary" disabled value={`${orderData?.date ?? ''} - ${orderData?.time ?? ''}`} />
          </InputWithLabel>
          <InputWithLabel label="price">
            <TextField fullWidth variant="outlined" color="primary" disabled value={getTotalPrice()} />
          </InputWithLabel>
        </RowContainer>
        <RowContainer>
          <InputWithLabel label={isDelivery ? 'send-to' : 'client-name'}>
            <TextField
              fullWidth
              variant="outlined"
              color="primary"
              disabled
              value={`${orderData?.profile?.firstName ?? ''} ${orderData?.profile?.lastName ?? ''}`}
            />
          </InputWithLabel>
        </RowContainer>
        {isDelivery && (
          <RowContainer>
            <InputWithLabel label="delivery-address">
              <TextField
                fullWidth
                variant="outlined"
                color="primary"
                disabled
                value={`${orderData?.deliveryAddress?.line1 ?? ''} ${orderData?.deliveryAddress?.addressNumber ?? ''},  ${
                  orderData?.deliveryAddress?.city ?? ''
                } ${orderData?.deliveryAddress?.postalCode ?? ''}`}
              />
            </InputWithLabel>
          </RowContainer>
        )}
        <RowContainer>
          <InputWithLabel label="phone">
            <TextField fullWidth variant="outlined" color="primary" disabled value={orderData?.profile?.phone ?? ''} />
          </InputWithLabel>
        </RowContainer>
        <RowContainer>
          <InputWithLabel label="additional-notes">
            <TextField fullWidth variant="outlined" color="primary" disabled value={orderData?.notes ?? ''} />
          </InputWithLabel>
        </RowContainer>
        <RowContainer>
          <InputWithLabel label="order-list">
            <CommonTable
              tableContainerStyle={{ width: '100%', fontSize: 14, border: `1px solid ${mainTextColor}`, opacity: 0.7, borderRadius: 5 }}
              headerCellStyle={{ color: mainTextColor, fontSize: 14 }}
              headerTextStyle={{ color: mainTextColor, fontSize: 14 }}
              rowCellStyle={{ borderTop: `1px solid ${mainTextColor}` }}
              rowStyle={{ borderBottom: `1px solid ${mainTextColor}` }}
              items={orderData?.fbCourses ?? []}
              columns={orderListColumns()}
            />
          </InputWithLabel>
        </RowContainer>
      </Container>
    </CustomModal>
  );
};

export default OrderDetailModal;
