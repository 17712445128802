import { makeStyles } from '@material-ui/core/styles';

const styles = {
  wrapper: {
    gridArea: 'tabs-container',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #B4DCED'
  },
  container: {
    maxWidth: '50vw',
    maxHeight: 50
  },
  button: {
    textDecoration: 'none',
    maxHeight: 40,
    height: '100%',
    borderRadius: 9,
    lineHeight: 1,
    fontSize: 20,
    margin: '0 10px',
    color: 'white',
    textTransform: 'uppercase'
  }
};

export default makeStyles(styles);
