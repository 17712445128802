import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import FilterOption from './FilterOption';
import { SearchBar, Dropdown, DataPicker, TimePicker } from '../../../common-fe/src';
import { capitalizeFirstLetter, translate } from '../../../translator';
import { LanguageContext } from '../../../contexts/LanguageContext';
import GuestRoomSearchBar from '../GuestRoomSearchBar';
import ResetFilterButton from '../ResetFilterButton';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: ${({ labelsOnTop }) => (labelsOnTop ? '0px' : '10px 0px 20px 0px')};

  & > div:not(:last-child) {
    margin-right: 20px;
  }

  & > div:last-child {
    flex: 1;
  }
`;

const CustomFilters = ({ filters, labelsOnTop, labelsStyles }) => {
  const { language } = useContext(LanguageContext);

  const getFilter = (filter) => {
    if (filter && filter.type) {
      switch (filter.type.toLowerCase()) {
        case 'date':
          return (
            <DataPicker
              value={filter.value}
              disabled={filter.disable}
              dataPickerStyle={{ width: labelsOnTop ? '100%' : 'auto', ...filter.style }}
              inputPropsStyle={{ minHeight: 32 }}
              onChange={filter.onChange}
            />
          );
        case 'time':
          return (
            <TimePicker
              value={filter.value}
              disabled={filter.disable}
              timePickerStyle={{ width: labelsOnTop ? '100%' : 'auto', ...filter.style }}
              inputPropsStyle={{ minHeight: 32 }}
              onChange={filter.onChange}
            />
          );
        case 'dropdown':
          return (
            <Dropdown
              selection
              items={filter.options?.map((option, index) => ({ key: index, label: option.text, value: option.value }))}
              value={filter.value}
              dropdownStyle={{ minHeight: 32, width: labelsOnTop ? '100%' : 'auto', ...filter.style }}
              disabled={filter.disabled}
              onChange={filter.onChange}
            />
          );
        case 'searchbar':
          return (
            <SearchBar
              placeholder={capitalizeFirstLetter(translate('search', language))}
              inputStyle={{ minWidth: 300, ...filter.style, flex: 1 }}
              style={{ flex: 1, width: '100%' }}
              iconStyle={{ height: 32 }}
              value={filter.value}
              disabled={filter.disabled}
              onClear={filter.onClear}
              onChange={filter.onChange}
            />
          );
        case 'guest-room-searchbar':
          return (
            <GuestRoomSearchBar
              value={filter.value}
              onChange={filter.onChange}
              onItemClick={filter.onItemClick}
              inputStyle={{ height: 32 }}
              resetRoomKeyword={filter?.resetRoomKeyword}
            />
          );
        case 'reset-filter':
          return <ResetFilterButton onClick={filter?.onClearFilter} />;
        case 'checkbox':
          return (
            <FormControlLabel
              control={<Checkbox color="primary" checked={filter.value} onClick={filter.onClick} />}
              label={capitalizeFirstLetter(translate(filter.label, language))}
            />
          );
        default:
          return <Dropdown selection options={filter.options} value={filter.value} disabled={filter.disabled} onChange={filter.onChange} />;
      }
    }
  };

  return (
    <Wrapper labelsOnTop={labelsOnTop}>
      {filters.map((filter, index) =>
        filter ? (
          <FilterOption
            key={index}
            title={filter.type === 'checkbox' ? '' : filter.label}
            filterComponent={getFilter(filter)}
            labelsOnTop={labelsOnTop}
            labelsStyles={labelsStyles}
            wrapperStyle={filter.style}
            disabled={filter.disabled}
          />
        ) : null
      )}
    </Wrapper>
  );
};

CustomFilters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.oneOf(['date', 'time', 'dropdown', 'searchbar', 'guest-room-searchbar', 'checkbox', 'reset-filter']).isRequired,
      dateFormat: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.instanceOf(Date)]),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
          value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        })
      ),
      disabled: PropTypes.bool,
      onChange: PropTypes.func,
      /** Valid on searchbar type only */
      onClear: PropTypes.func
    })
  ),
  labelsOnTop: PropTypes.bool
};

CustomFilters.defaultProps = {
  filters: [],
  labelsOnTop: false
};

export default CustomFilters;
