import React from 'react';
import orderBy from 'lodash/orderBy';
import styled from 'styled-components/macro';
import OutletCard from './OutletCard';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

const Dashboard = ({ reservationsSummary, onOutletClick }) => {
  return (
    <Container>
      {orderBy(reservationsSummary ?? [], ['outlet.order', 'outlet.name'], ['asc'])?.map(({ outlet, fbServices }) => (
        <OutletCard key={outlet?.uuid} outlet={outlet} fbServices={fbServices} onOutletClick={onOutletClick} />
      ))}
    </Container>
  );
};

export default Dashboard;
