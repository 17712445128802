import React, { useState, useRef, useContext } from 'react';
import { Button, Box } from '@material-ui/core/';
import { OrderContext } from '../../contexts/OrderContext';
import styles from '../../styles/order/orderStyle';
import ChairOrder from './ChairOrder';

const DishItem = ({ color, dish, dishKey, index, onClick, physicalCategories, refetchFbOrder }) => {
  const orderContext = useContext(OrderContext);

  const ref = useRef(null);
  const [coords, setCoords] = useState({ x: null, y: null });
  const handleClick = (e) => {
    e.stopPropagation();
    const { y, x } = ref.current.getBoundingClientRect();

    setCoords({
      y,
      x
    });

    onClick();
  };
  return (
    <Box>
      <Button
        ref={ref}
        className={
          styles({
            color,
            selectedDish: orderContext.fbSelectedDish,
            dishKey
          }).dishes
        }
        onClick={handleClick}
      >
        {dish.name}
      </Button>
      {orderContext.fbSelectedDish === dishKey && (
        <ChairOrder index={index} coords={coords} dish={dish} physicalCategories={physicalCategories} refetchFbOrder={refetchFbOrder} />
      )}
    </Box>
  );
};

export default DishItem;
