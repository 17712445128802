import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { CustomButton, MaterialIcon, primaryColor } from '../../../common-fe/src';
import styles from '../../../styles/cash/buttonsListStyle';
import { TranslateTextComponent } from '../../../translator';
import SplitChairs from '../SplitChairs/SplitChairs';
import TwoButtonsContainer from './TwoButtonsContainer';
import Receipt from '../../shared/Receipt';
import { FbPaymentMethodsEnum, CreditCardTypes } from '../../../constants/cash/paymentMethods';
import * as payments from '../../../constants/billsManagement/paymentMethods';
import * as documents from '../../../constants/cash/documentTypes';

import mastercardIcon from '../../../assets/images/cash/mastercard.svg';
import americanExpressIcon from '../../../assets/images/cash/american_express.svg';
import maestroIcon from '../../../assets/images/cash/maestro.svg';
import visaIcon from '../../../assets/images/cash/visa.svg';

const ContainerButton = styled('div')({
  cursor: 'pointer',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  backgroundColor: primaryColor,
  maxHeight: 50,
  height: '100%',
  width: '100%',
  textTransform: 'uppercase',
  lineHeight: 1,
  fontSize: 20,
  fontWeight: 500,
  borderRadius: 9,
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  userSelect: 'none',
  transition: 'background-color 250ms',
  '&:hover': {
    backgroundColor: 'rgb(0, 128, 178)'
  }
});

const ButtonsList = ({
  fbOrderUUID,
  isPayingPM,
  showSplitOptions,
  showPayOptions,
  chairs,
  activeBillTabUUID,
  orderBills,
  selectedChairs,
  showSplitChairs,
  currentOrderBill,
  onSplitChairClick,
  onSelectChair,
  onSplitClick,
  onSelectChairSaveButton,
  onSelectChairCancelButton,
  onDiscountClick,
  onPayMethodClick,
  onXPersonsClick,
  onPayClick,
  onCancelFiscalReceipt,
  onBackClick,
  onTablesMapClick,
  onTransferBillClick,
  onChargeClick,
  onAcquireCheck,
  onDisplayCheck
}) => {
  const classes = styles();
  const [disablePayButton, setDisablePayButton] = useState(false);
  const [isShowingReceipt, setIsShowingReceipt] = useState(false);
  const [showDisplayCheck, setShowDisplayCheck] = useState(false);

  useEffect(() => {
    if (orderBills.length > 0 && activeBillTabUUID) {
      const currentOrder = orderBills.find((order) => order.uuid === activeBillTabUUID);
      if (currentOrder) {
        setDisablePayButton(currentOrder.status === 'complete' || currentOrder.fbBillsRows.length <= 0);
      }
    }
  }, [orderBills, activeBillTabUUID]);

  useEffect(() => {
    if (currentOrderBill && currentOrderBill.fbBillDocuments) {
      if (currentOrderBill.fbBillDocuments.length <= 0) setShowDisplayCheck(false);
      currentOrderBill.fbBillDocuments.forEach((order) => {
        if ([documents.DIGITAL_CHECK, documents.PAPER_CHECK].some((doc) => doc === order.type)) setShowDisplayCheck(true);
        else setShowDisplayCheck(false);
      });
    }
  }, [orderBills, currentOrderBill, activeBillTabUUID]);

  const canCancelBill = () =>
    currentOrderBill && currentOrderBill.documentNumber && currentOrderBill.status === 'complete' && currentOrderBill.fiscalPrintType === 'ticket';

  const buttonsRender = () => {
    if (showSplitOptions) {
      return (
        <Box className={classes.container}>
          <CustomButton
            style={{ lineHeight: 1, backgroundColor: '#3399CC' }}
            img={<MaterialIcon iconName="SkipPrevious" />}
            translateText
            onClick={onBackClick}
          >
            <TranslateTextComponent uppercase>back</TranslateTextComponent>
          </CustomButton>
          {/* <CustomButton style={{ lineHeight: 1 }} onClick={onSplitChairsEquallyClick}>
            <TranslateTextComponent uppercase>evenly</TranslateTextComponent>
          </CustomButton> */}
          <ContainerButton onClick={onSplitChairClick}>
            <TranslateTextComponent uppercase>sitting</TranslateTextComponent>
            <SplitChairs
              chairs={chairs}
              selectedChairs={selectedChairs}
              showSplitChairs={showSplitChairs}
              onSelectChair={onSelectChair}
              onSelectChairSaveButton={onSelectChairSaveButton}
              onSelectChairCancelButton={onSelectChairCancelButton}
            />
          </ContainerButton>
          {/* <CustomButton style={{ lineHeight: 1 }}>
            <TranslateTextComponent uppercase>free-seating</TranslateTextComponent>
          </CustomButton> */}
          <CustomButton style={{ lineHeight: 1 }} onClick={onXPersonsClick}>
            <TranslateTextComponent uppercase>x-persons</TranslateTextComponent>
          </CustomButton>
          {/* <CustomButton style={{ lineHeight: 1 }}>
            <TranslateTextComponent uppercase>free-dish</TranslateTextComponent>
          </CustomButton> */}
        </Box>
      );
    }
    if (showPayOptions) {
      return (
        <Box className={classes.container}>
          <CustomButton
            style={{ lineHeight: 1, backgroundColor: '#3399CC' }}
            img={<MaterialIcon iconName="SkipPrevious" />}
            translateText
            onClick={onBackClick}
          >
            <TranslateTextComponent uppercase>back</TranslateTextComponent>
          </CustomButton>
          {/* <TwoButtonsContainer>
            <CustomButton style={{ lineHeight: 1, width: 'auto', margin: '0 5px' }}>
              <TranslateTextComponent uppercase>package</TranslateTextComponent>
            </CustomButton>
            <CustomButton style={{ lineHeight: 1, width: 'auto', margin: '0 5px' }}>
              <TranslateTextComponent uppercase>amenities</TranslateTextComponent>
            </CustomButton>
          </TwoButtonsContainer> */}
          <CustomButton style={{ lineHeight: 1 }} onClick={() => onPayMethodClick(FbPaymentMethodsEnum.CASH)}>
            <TranslateTextComponent uppercase>payment-cash</TranslateTextComponent>
          </CustomButton>
          <CustomButton style={{ lineHeight: 1 }} onClick={() => onPayMethodClick(FbPaymentMethodsEnum.BANK_CHECK)}>
            <TranslateTextComponent uppercase>payment-bank-check</TranslateTextComponent>
          </CustomButton>
          <CustomButton style={{ lineHeight: 1 }} onClick={() => onPayMethodClick(FbPaymentMethodsEnum.BANK_TRANSFER)}>
            <TranslateTextComponent uppercase>payment-bank-transfer</TranslateTextComponent>
          </CustomButton>
          <CustomButton style={{ lineHeight: 1 }} onClick={() => onPayMethodClick(FbPaymentMethodsEnum.CREDIT_CARD, CreditCardTypes.pagobancomat)}>
            <TranslateTextComponent uppercase>pagobancomat</TranslateTextComponent>
          </CustomButton>
          <TwoButtonsContainer>
            <CustomButton
              style={{ lineHeight: 1, width: 'auto', maxWidth: 100, margin: '0 5px' }}
              onClick={() => onPayMethodClick(FbPaymentMethodsEnum.CREDIT_CARD, CreditCardTypes.mastercard)}
            >
              <img src={mastercardIcon} alt="Mastercard badge" />
            </CustomButton>
            <CustomButton
              style={{ lineHeight: 1, width: 'auto', maxWidth: 100, margin: '0 5px' }}
              onClick={() => onPayMethodClick(FbPaymentMethodsEnum.CREDIT_CARD, CreditCardTypes.amex)}
            >
              <img src={americanExpressIcon} alt="American Express badge" />
            </CustomButton>
          </TwoButtonsContainer>
          <TwoButtonsContainer>
            <CustomButton
              style={{ lineHeight: 1, width: 'auto', maxWidth: 100, margin: '0 5px' }}
              onClick={() => onPayMethodClick(FbPaymentMethodsEnum.CREDIT_CARD, CreditCardTypes.maestro)}
            >
              <img src={maestroIcon} alt="Maestro badge" />
            </CustomButton>
            <CustomButton
              style={{ lineHeight: 1, width: 'auto', maxWidth: 100, margin: '0 5px' }}
              onClick={() => onPayMethodClick(FbPaymentMethodsEnum.CREDIT_CARD, CreditCardTypes.visa)}
            >
              <img src={visaIcon} alt="Visa badge" />
            </CustomButton>
          </TwoButtonsContainer>
          <CustomButton disabled style={{ lineHeight: 1 }}>
            <TranslateTextComponent uppercase>pro-forma</TranslateTextComponent>
          </CustomButton>
          <CustomButton disabled style={{ lineHeight: 1 }}>
            <TranslateTextComponent uppercase>send-invoice</TranslateTextComponent>
          </CustomButton>
        </Box>
      );
    }
    return (
      <Box className={classes.container}>
        {isShowingReceipt && <Receipt fbOrderUUID={fbOrderUUID} isOpen={isShowingReceipt} handleClose={() => setIsShowingReceipt(false)} />}
        <CustomButton style={{ lineHeight: 1 }} onClick={onTablesMapClick}>
          <TranslateTextComponent uppercase>tables-map</TranslateTextComponent>
        </CustomButton>
        <CustomButton style={{ lineHeight: 1 }}>
          <TranslateTextComponent uppercase>accounts-record</TranslateTextComponent>
        </CustomButton>
        <CustomButton style={{ lineHeight: 1 }} onClick={() => setIsShowingReceipt(true)}>
          <TranslateTextComponent uppercase>pre-bill</TranslateTextComponent>
        </CustomButton>
        {canCancelBill() ? (
          <CustomButton style={{ lineHeight: 1 }} onClick={onCancelFiscalReceipt}>
            <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
          </CustomButton>
        ) : (
          <TwoButtonsContainer>
            <CustomButton
              style={{ lineHeight: 1, width: 'auto', margin: '0 5px' }}
              translateText
              disabled={disablePayButton || currentOrderBill?.fbPaymentMethod !== null}
              onClick={onPayClick}
            >
              <TranslateTextComponent uppercase>pay</TranslateTextComponent>
            </CustomButton>
            <CustomButton
              style={{ lineHeight: 1, width: 'auto', margin: '0 5px' }}
              disabled={isPayingPM || disablePayButton || currentOrderBill?.fbPaymentMethod !== null}
              onClick={onChargeClick}
            >
              <TranslateTextComponent uppercase>charge</TranslateTextComponent>
            </CustomButton>
          </TwoButtonsContainer>
        )}

        <CustomButton style={{ lineHeight: 1 }} onClick={onSplitClick} disabled={isPayingPM}>
          <TranslateTextComponent uppercase>split</TranslateTextComponent>
        </CustomButton>
        <CustomButton style={{ lineHeight: 1 }} disabled={isPayingPM} onClick={onTransferBillClick}>
          <TranslateTextComponent uppercase>transfer-order</TranslateTextComponent>
        </CustomButton>
        <TwoButtonsContainer>
          <CustomButton style={{ lineHeight: 1, width: 'auto', margin: '0 5px' }} onClick={() => onDiscountClick('percentage')}>
            <TranslateTextComponent uppercase>discount%</TranslateTextComponent>
          </CustomButton>
          <CustomButton style={{ lineHeight: 1, width: 'auto', margin: '0 5px' }} onClick={() => onDiscountClick('money')}>
            <TranslateTextComponent uppercase>discount€</TranslateTextComponent>
          </CustomButton>
        </TwoButtonsContainer>
        {/* <CustomButton style={{ lineHeight: 1 }}>
            <TranslateTextComponent uppercase>in-house-clients</TranslateTextComponent>
          </CustomButton> */}
        <CustomButton
          style={{ lineHeight: 1 }}
          disabled={
            (currentOrderBill?.fbPaymentMethod && currentOrderBill.fbPaymentMethod.code !== payments.PM_CHARGE) ||
            !(currentOrderBill?.fbPaymentMethod && currentOrderBill.fbPaymentMethod.code === payments.PM_CHARGE && currentOrderBill.status === 'open')
          }
          onClick={() => document.getElementById('image-upload').click()}
        >
          <TranslateTextComponent>acquire-check</TranslateTextComponent>
          <input id="image-upload" type="file" accept="image/*" style={{ display: 'none' }} onChange={onAcquireCheck} />
        </CustomButton>
        {currentOrderBill?.fbPaymentMethod !== null && showDisplayCheck && (
          <CustomButton style={{ lineHeight: 1 }} onClick={onDisplayCheck}>
            <TranslateTextComponent>display-check</TranslateTextComponent>
          </CustomButton>
        )}
      </Box>
    );
  };

  return buttonsRender();
};

export default ButtonsList;
