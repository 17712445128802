import React, { useContext, useState } from 'react';
import typy from 'typy';
import * as _ from 'lodash';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';
import { ModalContext } from '../../../contexts/ModalContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { TranslateTextComponent } from '../../../translator';
import { GET_ORDERS } from '../../../graphql/moh/orders/queries';
import { getApolloErrors } from '../../../apollo/ApolloProvider';
import { SET_FB_COURSE_STATUS } from '../../../graphql/order/mutations';
import FbCourse from './FbCourse';
import styles from '../../../styles/kitchen/fbCourse';
import { MOH_ORDERS_STATUSES } from '../../../constants/moh/fbOrdersStatuses';
import FB_COURSE_STATUSES from '../../../constants/kitchen/courseStatus';
import ORDER_TYPE from '../../../constants/moh/orderType';
import { ROUTE_KITCHEN_DETAILED_ORDER } from '../../../router/routes';

const Order = () => {
  const classes = styles();
  const [changingFbCourseStatus, setChangingFbCourseStatus] = useState(null);
  const [setFbCourseStatus] = useMutation(SET_FB_COURSE_STATUS);
  const modalContext = useContext(ModalContext);
  const { appOutletUUID } = useContext(AuthContext);

  const getFbOrderRes = useQuery(GET_ORDERS, {
    variables: {
      fbOutletUUID: appOutletUUID,
      statuses: [MOH_ORDERS_STATUSES.open, MOH_ORDERS_STATUSES.preparing],
      type: [ORDER_TYPE.DELIVERY, ORDER_TYPE.FB, ORDER_TYPE.TAKE_AWAY]
    },
    onError: (err) => {
      modalContext.openModal({
        class: 'danger',
        title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
        text: getApolloErrors(err).join(' - ')
      });
    },
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const fbOrders = typy(getFbOrderRes, 'data.getOrders.data').safeArray;

  const getOrderTime = (order) => {
    if (order) {
      if (order.type !== ORDER_TYPE.FB) return order.time;
      return order?.fbReservationTable?.fbReservation?.reservationTime ?? '-';
    }
  };

  const filteredOrders =
    fbOrders &&
    fbOrders
      .filter((fbOrder) => fbOrder?.fbCourses?.length > 0)
      .map((fbOrder) => ({
        ...fbOrder,
        orderTime: getOrderTime(fbOrder)
      }));
  // .filter((fbOrder) => fbOrder.fbCourses.some((fbC) => fbC.type === 'food') > 0)
  const orderedFoodCourses = (fbOrder) => {
    const filteredFoodCourses = fbOrder.fbCourses.filter((fbCourse) => fbCourse.type === 'food' && fbCourse.fbMenuItemId !== null);
    const orderedCourses = _.orderBy(filteredFoodCourses, ['number'], ['asc']);
    return orderedCourses;
  };

  const handleSetChangingFbCourseStatus = (courseKey) => {
    setChangingFbCourseStatus(courseKey === changingFbCourseStatus ? null : courseKey);
  };

  const onSetFbCourseStatus = (fbCourse, fbCourseStatus) => {
    setFbCourseStatus({
      variables: {
        fbCourseUUID: fbCourse.uuid,
        fbCourseStatus
      }
    })
      .then(() => {
        getFbOrderRes.refetch();
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: 'Error',
          text: getApolloErrors(err).join(' - ')
        });
      });
  };
  return (
    <>
      <Grid container className={classes.mainContentContainer}>
        {_.orderBy(filteredOrders, ['orderTime'], ['asc']).map((fbOrder, index) => (
          <Grid key={index} item className={classes.tableContainer}>
            <Link to={`${ROUTE_KITCHEN_DETAILED_ORDER}/${fbOrder.uuid}`}>
              <Grid container className={classes.coursesTableHeader}>
                {fbOrder && fbOrder.type !== ORDER_TYPE.FB ? (
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography className={classes.tableHeaderText}>
                      <TranslateTextComponent capitalize>type</TranslateTextComponent>:{' '}
                      <span className={classes.tableHeaderTextBold}>
                        {(fbOrder?.type !== ORDER_TYPE.TAKE_AWAY ? fbOrder?.type : 'TAKE AWAY') || '-'}
                      </span>
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography className={classes.tableHeaderText}>
                      <TranslateTextComponent capitalize>table</TranslateTextComponent>:{' '}
                      <span className={classes.tableHeaderTextBold}> {typy(fbOrder, 'fbReservationTable.fbTable.number').safeString || '-'}</span>
                    </Typography>
                  </Grid>
                )}
                {fbOrder && fbOrder.type === ORDER_TYPE.FB && (
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography className={classes.tableHeaderText}>
                      <TranslateTextComponent capitalize>pax</TranslateTextComponent>:{' '}
                      <span className={classes.tableHeaderTextBold}> {typy(fbOrder, 'fbReservationTable.fbReservation.nPax').safeNumber || '-'}</span>
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12} md={6} lg={6}>
                  <Typography className={classes.tableHeaderText}>
                    <TranslateTextComponent capitalize>time</TranslateTextComponent>:
                    <span className={classes.tableHeaderTextBold}> {getOrderTime(fbOrder)}</span>
                  </Typography>
                </Grid>
                {fbOrder && fbOrder.type !== ORDER_TYPE.FB ? (
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography className={classes.tableHeaderText}>
                      <TranslateTextComponent capitalize>client</TranslateTextComponent>:
                      <span className={classes.tableHeaderTextBold}>
                        {fbOrder.clientName || fbOrder.profile?.firstName
                          ? fbOrder.clientName || ` ${fbOrder.profile?.firstName ?? ''} ${fbOrder.profile?.lastName ?? ''}`
                          : '-'}
                      </span>
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item xs={12} md={6} lg={6}>
                    <Typography className={classes.tableHeaderText}>
                      <TranslateTextComponent capitalize>client</TranslateTextComponent>:
                      <span className={classes.tableHeaderTextBold}>
                        {typy(fbOrder, 'fbReservationTable.fbReservation.bookedBy.lastname').safeString || '-'}
                      </span>
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Link>
            <Box className={classes.foodAndDrinks}>
              {orderedFoodCourses(fbOrder)
                .filter((filteredCourse) => filteredCourse.fbCourseStatus !== FB_COURSE_STATUSES.completed.label)
                .map((fbCourse, i) => {
                  const courseKey = `${typy(fbOrder, 'fbReservationTable.fbTable.id').safeNumber || '-'}-${fbCourse.number}`;
                  return (
                    <FbCourse
                      key={i}
                      fbCourse={fbCourse}
                      i={i}
                      courseKey={courseKey}
                      fbOrder={fbOrder}
                      changingFbCourseStatus={changingFbCourseStatus}
                      handleSetChangingFbCourseStatus={handleSetChangingFbCourseStatus}
                      onSetFbCourseStatus={(course, status) => onSetFbCourseStatus(course, status)}
                    />
                  );
                })}
              {orderedFoodCourses(fbOrder)
                .filter((filteredCourse) => filteredCourse.fbCourseStatus === FB_COURSE_STATUSES.completed.label)
                .map((fbCourse, i) => {
                  const counter =
                    orderedFoodCourses(fbOrder).filter((filteredCourse) => filteredCourse.fbCourseStatus !== FB_COURSE_STATUSES.completed.label)
                      .length + i;
                  return <FbCourse key={i} fbCourse={fbCourse} i={counter} />;
                })}
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
export default Order;
