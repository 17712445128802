import React, { useContext } from 'react';
import { Typography, Box, Grid, Radio, RadioGroup, FormControlLabel, Checkbox, Input, MenuItem, ListItemText, Select } from '@material-ui/core/';
import { TranslateTextComponent, translate, capitalizeFirstLetter } from '../../../translator';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { InputTextLabel, MaterialIcon } from '../../../common-fe';
import Footer from '../Footer';
import styles from '../../../styles/settings/settingsStyle';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 230,
      width: 400
    }
  }
};

const inputStyle = { width: 150, height: 30 };
const RoomForm = ({ roomSettings, updateRoomSettings, onClickSave, onClickDelete, errorFormMap, fbServices, handleCheckboxChange }) => {
  const classes = styles();
  const languageContext = useContext(LanguageContext);

  const getService = () => {
    const services = fbServices.filter((fbService) => roomSettings.fbServices.includes(fbService.id));

    return services.map((service, index) => (
      <Box key={index} style={{ display: 'flex', alignItems: 'center', minWidth: 90 }}>
        <MaterialIcon iconName={service.icon} style={{ marginRight: 10 }} />
        <Typography>{service.name} </Typography>
        {index !== services.length - 1 ? <Typography style={{ margin: '0 10px' }}> - </Typography> : null}
      </Box>
    ));
  };

  return (
    <Grid container direction="column">
      <Grid container direction="row" justify="space-between" alignItems="center" className={classes.footerGridContainer}>
        <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <InputTextLabel
            error={errorFormMap.has('name')}
            errorLabel={errorFormMap.get('name')}
            inputType="string"
            inputStyle={inputStyle}
            title={capitalizeFirstLetter(translate('name', languageContext.language))}
            placeholder="Nd"
            className={classes.noteTextField}
            classNameBox={classes.footerBoxes}
            onChange={(event) => updateRoomSettings('name', event.target.value)}
            value={roomSettings.name}
          />
        </Grid>

        <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <InputTextLabel
            error={errorFormMap.has('maxPax')}
            errorLabel={errorFormMap.get('maxPax')}
            inputStyle={{ width: 40 }}
            title={capitalizeFirstLetter(translate('max-covers', languageContext.language))}
            placeholder="Nd"
            className={classes.noteTextField}
            classNameBox={classes.footerBoxes}
            onChange={(event) => updateRoomSettings('maxPax', event.target.value)}
            value={roomSettings.maxPax}
          />
        </Grid>
        <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography className={classes.formTypography}>
            <TranslateTextComponent capitalize>service</TranslateTextComponent>
          </Typography>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <Select
              multiple
              disableUnderline
              value={roomSettings.fbServices}
              onChange={(e) => handleCheckboxChange(e, e.target.value)}
              input={<Input id="select-multiple-checkbox" />}
              className={errorFormMap.get('fbServices') ? [classes.dropdown, 'error'].join(' ') : classes.dropdown}
              renderValue={() => {
                return (
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      width: '100%',
                      flexWrap: 'nowrap'
                    }}
                  >
                    {getService()}
                  </Box>
                );
              }}
              MenuProps={MenuProps}
            >
              {fbServices &&
                fbServices.map((service) => (
                  <MenuItem key={service.id} value={service.id}>
                    <Checkbox color="primary" checked={roomSettings.fbServices.includes(service.id)} />
                    <ListItemText
                      primary={
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                          <MaterialIcon iconName={service.icon} />
                          <Typography style={{ margin: '0 10px' }}>{service.name}</Typography>
                          &nbsp;
                        </Box>
                      }
                    />
                  </MenuItem>
                ))}
            </Select>
            <span style={{ color: 'red', fontSize: 10 }}>{errorFormMap.get('fbServices') && errorFormMap.get('fbServices')}</span>
          </Box>
        </Grid>

        <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography className={classes.formTypography}>
            <TranslateTextComponent capitalize>online-reservations</TranslateTextComponent>
          </Typography>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <RadioGroup
              row
              value={roomSettings.hasOnlineReservations}
              defaultValue={roomSettings.hasOnlineReservations}
              onChange={(e) => updateRoomSettings('hasOnlineReservations', e.target.value)}
              className={classes.footerRadioGroup}
            >
              <FormControlLabel
                value="yes"
                control={
                  <Radio
                    checked={roomSettings.hasOnlineReservations === 'yes'}
                    className={errorFormMap.get('hasOnlineReservations') && [classes.radio, 'error'].join(' ')}
                  />
                }
                label={capitalizeFirstLetter(translate('yes', languageContext.language))}
              />
              <FormControlLabel
                value="no"
                control={
                  <Radio
                    checked={roomSettings.hasOnlineReservations === 'no'}
                    className={errorFormMap.get('hasOnlineReservations') && [classes.radio, 'error'].join(' ')}
                  />
                }
                label={capitalizeFirstLetter(translate('no', languageContext.language))}
              />
            </RadioGroup>
            <span style={{ color: 'red', fontSize: 10 }}>
              {errorFormMap.get('hasOnlineReservations') && errorFormMap.get('hasOnlineReservations')}
            </span>
          </Box>
        </Grid>

        <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography className={classes.formTypography}>
            <TranslateTextComponent capitalize>state</TranslateTextComponent>
          </Typography>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <RadioGroup
              row
              value={roomSettings.isActive}
              defaultValue={roomSettings.isActive}
              onChange={(e) => updateRoomSettings('isActive', e.target.value)}
              className={classes.footerRadioGroup}
            >
              <FormControlLabel
                value="activated"
                control={
                  <Radio
                    checked={roomSettings.isActive === 'activated'}
                    className={errorFormMap.get('isActive') && [classes.radio, 'error'].join(' ')}
                  />
                }
                label={capitalizeFirstLetter(translate('activated', languageContext.language))}
              />
              <FormControlLabel
                value="disabled"
                control={
                  <Radio
                    checked={roomSettings.isActive === 'disabled'}
                    className={errorFormMap.get('isActive') && [classes.radio, 'error'].join(' ')}
                  />
                }
                label={
                  <Typography style={{ fontSize: 14 }}>
                    <TranslateTextComponent capitalize>disabled-state</TranslateTextComponent>
                  </Typography>
                }
              />
            </RadioGroup>
            <span style={{ color: 'red', fontSize: 10 }}>{errorFormMap.get('isActive') && errorFormMap.get('isActive')}</span>
          </Box>
        </Grid>
      </Grid>
      <Footer onClickSave={() => onClickSave()} onClickDelete={() => onClickDelete()} />
    </Grid>
  );
};
export default RoomForm;
