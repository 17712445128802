import React from 'react';
import Layout from '../../components/shared/Layout';
import GuestProfile from '../../components/GuestProfiles/GuestProfile';

const GuestProfileEditPage = ({ history, match }) => {
  return (
    <Layout history={history} section="guest-profiles">
      <GuestProfile profileUUID={match?.params?.uuid} />
    </Layout>
  );
};

export default GuestProfileEditPage;
