/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useContext } from 'react';
import { Box } from '@material-ui/core';
import moment from 'moment';
import styles from '../../../../styles/billsManagement/headerIncome';
import { translate, capitalizeFirstLetter } from '../../../../translator';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import FloatingBox from './FloatingBox';
import { MaterialIcon } from '../../../../common-fe/src';

const HeaderIncome = ({ date, monthly, totalAmount, onDateChange }) => {
  const { language } = useContext(LanguageContext);
  const todayDate = moment().format('DD-MM-YYYY');
  const [currentDate, setCurrentDate] = useState(moment());
  const [currentDateText, setCurrentDateText] = useState(
    monthly
      ? moment()
          .locale(language)
          .format('MMMM YYYY')
      : moment().format('DD-MM-YYYY')
  );
  const [showFloatingBox, setShowFloatingBox] = useState(false);
  const classes = styles();

  useEffect(() => {
    if (monthly) {
      const formattedDate = currentDate.locale(language).format('MMMM YYYY');
      setCurrentDateText(formattedDate);
      onDateChange(currentDate.format(`YYYY-MM-[${currentDate.startOf('month').format('DD')}]`), true);
    } else {
      const formattedDate = currentDate.format('DD-MM-YYYY');
      setCurrentDateText(formattedDate);
      onDateChange(currentDate.format('YYYY-MM-DD'), false);
    }
  }, [currentDate]);

  useEffect(() => {
    if (monthly) {
      setCurrentDateText(currentDate.locale(language).format('MMMM YYYY'));
    }
  }, [language]);

  const onArrowClick = (type) => {
    if (type === 'lesser') {
      if (monthly) {
        setCurrentDate((prevDate) => prevDate.clone().subtract(1, 'month'));
      } else {
        setCurrentDate((prevDate) => prevDate.clone().subtract(1, 'day'));
      }
    } else {
      if (monthly) {
        setCurrentDate((prevDate) => prevDate.clone().add(1, 'month'));
      } else {
        setCurrentDate((prevDate) => prevDate.clone().add(1, 'day'));
      }
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.dateContainer}>
        <span className={classes.arrow} onClick={() => onArrowClick('lesser')}>
          {'<'}
        </span>
        {date ? (
          <span className={classes.date}>{date}</span>
        ) : (
          <span className={classes.date}>
            {currentDateText === todayDate ? capitalizeFirstLetter(translate('today', language)) : currentDateText}
          </span>
        )}
        <span className={classes.arrow} onClick={() => onArrowClick('greater')}>
          {'>'}
        </span>
      </Box>
      {totalAmount !== undefined && (
        <Box className={classes.amount} /* onClick={() => setShowFloatingBox(true)} */>
          € {totalAmount.toFixed(2)} <MaterialIcon iconName="Info" />
        </Box>
      )}
      {showFloatingBox && <FloatingBox closeFloatingBox={() => setShowFloatingBox(false)} />}
    </Box>
  );
};

export default HeaderIncome;
