import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const ViewPhoto = ({ showPhoto, hide, photos }) => {
  const [documentIndex, setDocumentIndex] = useState(0);
  return (
    showPhoto && (
      <Lightbox
        mainSrc={photos?.[documentIndex] ?? ''}
        onCloseRequest={hide}
        reactModalStyle={{ zIndex: 1000000 }}
        onMoveNextRequest={() => {
          setDocumentIndex((documentIndex + 1) % photos.length);
        }}
        onMovePrevRequest={() => {
          setDocumentIndex((documentIndex + photos.length - 1) % photos.length);
        }}
        nextSrc={photos[(documentIndex + 1) % photos.length]}
        prevSrc={photos[(documentIndex + photos.length - 1) % photos.length]}
      />
    )
  );
};

ViewPhoto.propTypes = {
  showPhoto: PropTypes.bool.isRequired,
  /** Photo base64/URI/URL */
  photos: PropTypes.arrayOf(PropTypes.string).isRequired,
  hide: PropTypes.func.isRequired
};

export default ViewPhoto;
