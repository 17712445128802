import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { translate, capitalizeFirstLetter, TranslateTextComponent } from '../../translator';
import { LanguageContext } from '../../contexts/LanguageContext';
import { InputTextLabel, CustomButton, primaryColor } from '../../common-fe/src';
import useCustomQuery from '../../hooks/useCustomQuery';
import { FORGOT_PASSWORD_REQUEST } from '../../graphql/user/queries';
import { ModalContext } from '../../contexts/ModalContext';

const TextField = styled(InputTextLabel).attrs({
  inputStyle: {
    fontSize: 14,
    height: 40,
    color: '#55595C',
    border: '1.5px solid',
    borderColor: primaryColor,
    paddingLeft: 10,
    borderRadius: 4
  }
})``;

const ForgotPasswordForm = () => {
  const { language } = useContext(LanguageContext);
  const modalContext = useContext(ModalContext);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required')
  });
  const forgotPasswordRequest = useCustomQuery(FORGOT_PASSWORD_REQUEST, {
    skip: true
  });

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: { email: '' },
    validationSchema,
    onSubmit: ({ email }) => {
      forgotPasswordRequest
        .refetch({ email })
        .then(() => {
          modalContext.closeModal();
          modalContext.openModal({
            class: 'success',
            title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
            text: <TranslateTextComponent capitalize>check-your-inbox</TranslateTextComponent>
          });
        })
        .catch((errs) => {
          const totalErrors = errs.graphQLErrors
            .map(({ message }) => {
              return message.message;
            })
            .join('; ');
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: totalErrors
          });
        });
    }
  });

  return (
    <Box style={{ width: '100%' }}>
      <TextField
        errorLabel={errors?.email}
        error={errors?.email ? true : false} //for warning
        title={capitalizeFirstLetter(translate('email', language))}
        placeholder={capitalizeFirstLetter(translate('email', language))}
        value={values.email}
        onChange={handleChange('email')}
      />
      <CustomButton
        style={{ width: '100%', borderRadius: 4, marginTop: 10 }}
        onClick={handleSubmit}
        disabled={errors?.email || !values?.email ? true : false} //for warning
      >
        confirm
      </CustomButton>
    </Box>
  );
};

export default ForgotPasswordForm;
