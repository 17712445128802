/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import moment from 'moment';
import { Box, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { TranslateTextComponent } from '../../translator';

const textTranslate = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

const revenuesColumns = ({ editFbServiceRevenue, deleteFbServiceRevenue }) => {
  return [
    {
      id: 'name',
      disablePadding: false,
      label: textTranslate('restaurant'),
      isSortable: false,
      align: 'left',
      render: (item) => item?.fbService?.fbOutlet?.name ?? item.outlet?.name
    },
    {
      id: 'date',
      disablePadding: false,
      label: textTranslate('date'),
      isSortable: false,
      align: 'left',
      render: (item) => moment(item?.date).format('DD-MM-YYYY')
    },
    {
      id: 'createdBy',
      disablePadding: false,
      label: textTranslate('created-by'),
      isSortable: false,
      align: 'left',
      render: (item) => (item?.createdBy?.firstName ? `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}` : item?.createdBy?.username)
    },
    {
      id: 'service',
      disablePadding: false,
      label: textTranslate('service'),
      isSortable: false,
      align: 'left',
      render: (item) => item.fbService?.name
    },
    {
      id: 'totalCoversNumber',
      disablePadding: false,
      label: textTranslate('total-covers-number'),
      isSortable: false,
      align: 'left',
      render: (item) => item?.totalCoversNumber
    },
    {
      id: 'checkAverage',
      disablePadding: false,
      label: textTranslate('check-average'),
      isSortable: false,
      align: 'left',
      render: (item) => item?.checkAverage?.toFixed(2)
    },
    {
      id: 'actions',
      disablePadding: true,
      label: textTranslate('actions'),
      isSortable: false,
      width: 'auto',

      render: (item) => (
        <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <IconButton
            className="buttonCircle"
            style={{ marginRight: 10 }}
            disabled={!!item.arrivalTime}
            onClick={() => editFbServiceRevenue(item?.uuid)}
          >
            <EditIcon />
          </IconButton>
          <IconButton className="buttonCircle" onClick={() => deleteFbServiceRevenue(item?.uuid)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )
    }
  ];
};

export default revenuesColumns;
