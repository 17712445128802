import React from 'react';
import Layout from '../components/shared/Layout';
import Menu from '../components/menu/Menu';

const MenuPage = ({ history }) => {
  return (
    <Layout history={history} section="menu">
      <Menu />
    </Layout>
  );
};

export default MenuPage;
