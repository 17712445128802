import React, { useContext } from 'react';
import { Box, Grid } from '@material-ui/core/';
import { SearchBar } from '../../common-fe';
import { LanguageContext } from '../../contexts/LanguageContext';
import { capitalizeFirstLetter, translate } from '../../translator';
import GuestRoomSearchBar from '../shared/GuestRoomSearchBar';

const InternalCustomerMenu = ({ setNewFilterAndRefetch, guestsQueryFilters }) => {
  const languageContext = useContext(LanguageContext);

  return (
    <Box>
      <Grid container style={{ paddingLeft: 18, paddingRight: 19, marginBottom: 19 }}>
        <Grid item xs md>
          <SearchBar
            style={{ height: 40, paddingTop: 0, paddingBottom: 0 }}
            placeholder={capitalizeFirstLetter(translate('search-by-name', languageContext.language))}
            onChange={(e) => setNewFilterAndRefetch({ keyword: e.target.value })}
            value={guestsQueryFilters.keyword || ''}
            onClear={() => setNewFilterAndRefetch({ keyword: null })}
          />
        </Grid>

        <Grid item xs md style={{ marginLeft: 15 }}>
          <GuestRoomSearchBar
            value={guestsQueryFilters?.roomNumber}
            onChange={(text) => setNewFilterAndRefetch({ roomNumber: text })}
            onItemClick={(room) => {
              if (room?.number !== guestsQueryFilters?.roomNumber) {
                setNewFilterAndRefetch({ roomNumber: room?.number });
              }
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default InternalCustomerMenu;
