import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { FormControlLabel as MUIFormControlLabel, RadioGroup, Radio, TextField, Checkbox } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import { Formik } from 'formik';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { ADD_MENU_ITEM } from '../../../graphql/menu/mutations';
import styles from '../../../styles/menu/menuStyle';
import { TranslateTextComponent, translate, capitalizeFirstLetter } from '../../../translator';
import { LanguageContext } from '../../../contexts/LanguageContext';
import MenuContext from '../../../contexts/MenuContext';
import Button from './Button';
import { Dropdown, CustomModal } from '../../../common-fe';
import ImageUpload from '../../shared/ImageUpload';
import InputWithLabel from '../../shared/InputWithLabel';
import { getApolloErrors } from '../../../apollo/ApolloProvider';
import { ModalContext } from '../../../contexts/ModalContext';

const FormControlLabel = styled(MUIFormControlLabel)`
  & .muiformcontrollabel-label: {
    font-size: 0.8rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-flow: column;
  background-color: white;
  width: 100%;
  max-width: 675px;

  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

const RowContainer = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 30px;
  }
`;

const AddItem = ({ selectedCategoryUUID, closeAddItemModal, categories, vatCodes, nextOrderNumber, refetchCategories }) => {
  const { showAddItemModal } = useContext(MenuContext);
  const { language } = useContext(LanguageContext);
  const modalContext = useContext(ModalContext);
  const [allergens, setAllergens] = useState([]);
  const [uploadedImageURI, setUploadedImageURI] = useState(null);

  const classes = styles();

  // Mutations
  const [addMenuItem] = useMutation(ADD_MENU_ITEM);

  const onImageDropHandler = (imageURI) => {
    setUploadedImageURI(imageURI);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(language === 'EN' ? 'Name is required' : 'Nome obbligatorio'),
    category: Yup.string().required(language === 'EN' ? 'Category is required' : 'Categoria obbligatoria'),
    price: Yup.number()
      .typeError(language === 'EN' ? 'Price not valid' : 'Prezzo non valido')
      .min(0, language === 'EN' ? 'Price not valid' : 'Prezzo non valido'),
    vatCode: Yup.string().required('Required'),
    order: Yup.number()
      .typeError(language === 'EN' ? 'Invalid number' : 'Numero non valido')
      .required('Required')
  });

  return (
    <CustomModal
      showModal={showAddItemModal}
      onClose={closeAddItemModal}
      width="auto"
      height="auto"
      stylePaper={{ background: 'white', minWidth: 530, minHeight: 470, padding: 20 }}
    >
      <Formik
        initialValues={{
          image: uploadedImageURI,
          name: '',
          secondaryName: '',
          category: selectedCategoryUUID,
          description: '',
          secondaryDescription: '',
          price: '0',
          allergens: [],
          isHidden: 'active',
          order: nextOrderNumber,
          vatCode: vatCodes?.filter((vat) => vat.value === 0)?.[0]?.code ?? '',
          isDeliveryEnabled: false,
          isTakeAwayEnabled: false
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          addMenuItem({
            variables: {
              fbMenuCategoryUUID: values.category,
              fbMenuItemData: {
                name: values.name,
                secondaryName: values.secondaryName,
                price: parseFloat(values.price.replace(',', '.')),
                allergens,
                description: values.description,
                secondaryDescription: values.secondaryDescription,
                order: parseFloat(values.order, 10),
                isHidden: values.isHidden === 'disabled',
                images: uploadedImageURI ? [{ base64image: uploadedImageURI, isPrimary: true }] : null,
                vatCode: values.vatCode,
                isDeliveryEnabled: values.isDeliveryEnabled,
                isTakeAwayEnabled: values.isTakeAwayEnabled
              }
            }
          })
            .then(() => {
              modalContext.openModal({
                class: 'success',
                title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
                text: <TranslateTextComponent capitalize>item-created-success</TranslateTextComponent>
              });
              refetchCategories();
              setAllergens();
              closeAddItemModal();
            })
            .catch((err) => {
              modalContext.openModal({
                class: 'danger',
                title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                text: getApolloErrors(err).join(' - ')
              });
            });
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, handleReset, isSubmitting }) => {
          return (
            <Container>
              <RowContainer>
                <ColumnContainer>
                  <ImageUpload onImageDrop={onImageDropHandler} />
                </ColumnContainer>
                <ColumnContainer style={{ justifyContent: 'space-between' }}>
                  <InputWithLabel label="name" error={touched.name && errors.name}>
                    <TextField
                      name="name"
                      fullWidth
                      variant="outlined"
                      color="primary"
                      value={values.name}
                      error={touched.name && !!errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </InputWithLabel>
                  <InputWithLabel label="secondary-name" error={touched.secondaryName && errors.secondaryName}>
                    <TextField
                      name="secondaryName"
                      fullWidth
                      variant="outlined"
                      color="primary"
                      value={values.secondaryName}
                      error={touched.secondaryName && !!errors.secondaryName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </InputWithLabel>
                </ColumnContainer>
              </RowContainer>
              <RowContainer>
                <InputWithLabel label="category">
                  <Dropdown
                    name="category"
                    fullWidth
                    dropdownStyle={{ height: 40, width: '100%' }}
                    value={values.category}
                    items={categories.map((category) => ({ ...category, label: category.name, value: category.uuid }))}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </InputWithLabel>
              </RowContainer>
              <RowContainer>
                <InputWithLabel label="description">
                  <TextField name="description" multiline fullWidth value={values.description} onChange={handleChange} onBlur={handleBlur} />
                </InputWithLabel>
                <InputWithLabel label="secondary-description">
                  <TextField
                    name="secondaryDescription"
                    multiline
                    fullWidth
                    value={values.secondaryDescription}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </InputWithLabel>
              </RowContainer>
              <RowContainer>
                <InputWithLabel label="price" error={touched.price && errors.price}>
                  <TextField
                    name="price"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    value={values.price}
                    error={touched.price && !!errors.price}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </InputWithLabel>
                <InputWithLabel label="vat-number" error={touched.vatCode && errors.vatCode}>
                  <Dropdown
                    name="vatCode"
                    fullWidth
                    dropdownStyle={{ minHeight: 40, width: '100%' }}
                    value={values.vatCode}
                    items={vatCodes.map((vatCode) => ({ label: `${vatCode.value}%`, value: vatCode.code }))}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </InputWithLabel>
              </RowContainer>
              <RowContainer>
                <InputWithLabel label="status">
                  <RadioGroup row aria-label="isVisible" name="isHidden" value={values.isHidden} onChange={handleChange} onBlur={handleBlur}>
                    <FormControlLabel
                      value="active"
                      control={<Radio color="primary" checked={values.isHidden === 'active'} />}
                      label={capitalizeFirstLetter(translate('menu-modal-radio-active', language))}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="disabled"
                      control={<Radio color="primary" checked={values.isHidden === 'disabled'} />}
                      label={capitalizeFirstLetter(translate('menu-modal-radio-hidden', language))}
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </InputWithLabel>
                <InputWithLabel label="order" error={touched.order && errors.order}>
                  <TextField
                    name="order"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    value={values.order}
                    error={touched.order && !!errors.order}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </InputWithLabel>
              </RowContainer>
              <RowContainer>
                <InputWithLabel label="delivery-takeaway">
                  <RowContainer style={{ flexWrap: 'wrap' }}>
                    <FormControlLabel
                      control={<Checkbox name="isDeliveryEnabled" checked={values.isDeliveryEnabled} onChange={handleChange} color="primary" />}
                      label={capitalizeFirstLetter(translate('delivery', language))}
                    />
                    <FormControlLabel
                      control={<Checkbox name="isTakeAwayEnabled" checked={values.isTakeAwayEnabled} onChange={handleChange} color="primary" />}
                      label={capitalizeFirstLetter(translate('takeaway', language))}
                    />
                  </RowContainer>
                </InputWithLabel>
                <InputWithLabel label="allergens">
                  <ChipInput
                    classes={classes.chipInput}
                    InputProps={{ disableUnderline: true, margin: 'none' }}
                    fullWidth
                    defaultValue={values.allergens}
                    newChipKeyCodes={[188, 9]}
                    onChange={(chips) => setAllergens(chips)}
                  />
                </InputWithLabel>
              </RowContainer>
              <RowContainer style={{ justifyContent: 'space-between' }}>
                <Button variant="contained" color="primary" type="reset" onClick={handleReset}>
                  <TranslateTextComponent uppercase>menu-button-reset</TranslateTextComponent>
                </Button>
                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  <TranslateTextComponent uppercase>menu-button-submit</TranslateTextComponent>
                </Button>
              </RowContainer>
            </Container>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default AddItem;
