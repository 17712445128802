/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';
import { SMART_SERVICES_FRAGMENT } from '../../fragments';

export const GET_SMART_SERVICES = gql`
  query getSmartServices($fbOutletUUID: String!, $date: String, $isActive: Boolean, $page: Int, $pageSize: Int) {
    getSmartServices(fbOutletUUID: $fbOutletUUID, date: $date, isActive: $isActive, page: $page, pageSize: $pageSize) {
      totalCount
      data {
        ...smartServicesFragment
      }
    }
  }
  ${SMART_SERVICES_FRAGMENT}
`;
