import React from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { redButton } from '../../../../styles/theme';
import { MaterialIcon } from '../../../../common-fe/src';

const UserRow = ({ data, onRemoveGuestClick }) => {
  return (
    data.length > 0 && (
      <Grid container alignItems="center" style={{ marginTop: 10, borderTop: '1px solid black', borderBottom: '1px solid black' }}>
        {data.map((d) => (
          <React.Fragment key={d.uuid}>
            <Grid item xs={2}>
              {`${d?.firstname} ${d.lastname}`}
            </Grid>
            <Grid item xs={2}>
              {d.dob ? moment(d.dob, 'YYYY-MM-DD').format('DD-MM-YYYY') : null}
            </Grid>
            <Grid item xs={2}>
              {d.phone}
            </Grid>
            <Grid item xs={2}>
              {d.email}
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'right' }}>
              <MaterialIcon style={{ cursor: 'pointer', color: redButton }} iconName="Close" onClick={() => onRemoveGuestClick(d.uuid)} />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    )
  );
};

export default UserRow;
