import { makeStyles } from '@material-ui/core/styles';

const ageStyle = {
  parentBox: () => ({
    position: 'relative',
    widows: '100%'
  }),
  paperParent: () => ({
    position: 'absolute',
    top: -30,
    right: 0,
    left: 10,
    width: 90,
    color: '#55595C',
    size: 10
  }),
  ageBoxHeader: () => ({
    color: '#55595C',
    margin: 10,
    fontSize: 10,
    justifyContent: 'center'
  }),
  contentContainer: () => ({
    paddingTop: 5,
    paddingBottom: 5
  }),
  img: () => ({
    paddingLeft: 3,
    paddingTop: 5
  })
};
export default makeStyles(ageStyle);
