import React from 'react';
import typy from 'typy';
import { Box, Grid, Typography } from '@material-ui/core';
import groupedByMenuItem from '../../../utils/menuGroupBy';
import styles from '../../../styles/kitchen/fbCourse';
import FbCourseStatusIcon from './FbCourseStatusIcon';
import { SharedBy, ChairOrHalfPortion, Centerpiece } from './OrderIconComponents';
import { secondaryColor } from '../../../styles/theme';
import { TranslateTextComponent } from '../../../translator';
import ORDER_TYPE from '../../../constants/moh/orderType';

const DetailedSummaryOrder = ({ tableFbOrders, changingFbCourseStatus, handleSetChangingFbCourseStatus, onSetFbCourseStatus }) => {
  const classes = styles();

  const tableFbCourses = typy(tableFbOrders, 'fbCourses').safeArray;

  const filteredCourses = tableFbCourses
    .filter((filteredCourse) => filteredCourse.type === 'food' && filteredCourse?.fbCoursesMenuItems?.map((item) => item.fbMenuItemId !== null))
    .sort((prev, next) => prev.number - next.number);

  const getTotalQuantity = (items) => {
    return items.reduce((quantity, menuItem) => {
      quantity += menuItem.quantity;
      return quantity;
    }, 0);
  };

  return (
    <Grid container className={classes.tableInfoHeader}>
      {tableFbOrders?.type === ORDER_TYPE.FB ? (
        <Box className={classes.detailedHeaderContainer} style={{ marginBottom: 30 }}>
          <Typography className={classes.tableInfoTypo}>
            <TranslateTextComponent uppercase>table</TranslateTextComponent>:{' '}
            {typy(tableFbOrders, 'fbReservationTable.fbTable.number').safeString || '-'}
          </Typography>
          <Typography className={classes.tableInfoTypo}>{typy(tableFbOrders, 'fbReservationTable.fbTable.fbRoom.name').safeString}</Typography>
          <Typography className={classes.tableInfoTypo}>
            <TranslateTextComponent uppercase>n-pax</TranslateTextComponent>:{' '}
            {typy(tableFbOrders, 'fbReservationTable.fbReservation.nPax').safeNumber || '-'}
          </Typography>
          <Typography className={classes.tableInfoTypo}>
            <TranslateTextComponent uppercase>time</TranslateTextComponent>:{' '}
            {tableFbOrders?.fbReservationTable?.fbReservation?.reservationTime ?? '-'}
          </Typography>
          <Typography className={classes.tableInfoTypo}>
            {typy(tableFbOrders, 'fbReservationTable.fbReservation.bookedBy.lastname').safeString || null}
          </Typography>
        </Box>
      ) : (
        <Box className={classes.detailedHeaderContainer} style={{ marginBottom: 30 }}>
          <Typography className={classes.tableInfoTypo}>
            <TranslateTextComponent capitalize>type</TranslateTextComponent>:{' '}
            <span>{(tableFbOrders?.type !== ORDER_TYPE.TAKE_AWAY ? tableFbOrders?.type : 'TAKE AWAY') || '-'}</span>
          </Typography>
          <Typography className={classes.tableInfoTypo}>
            <TranslateTextComponent capitalize>time</TranslateTextComponent>: <span>{tableFbOrders?.time ?? '-'}</span>
          </Typography>
          {tableFbOrders?.profile && (
            <Typography className={classes.tableInfoTypo}>
              <TranslateTextComponent capitalize>client</TranslateTextComponent>:
              <span>{` ${tableFbOrders?.profile?.firstName ?? '-'} ${tableFbOrders?.profile?.lastName ?? '-'}`}</span>
            </Typography>
          )}
        </Box>
      )}

      <Grid container style={{ minHeight: '100%' }}>
        <Grid className={classes.courseTableHeader}>
          <Typography style={{ width: '10%' }} className={classes.tableHeaderTypo}>
            <TranslateTextComponent capitalize>course</TranslateTextComponent>
          </Typography>
          <Typography style={{ width: '9%' }} className={classes.tableHeaderTypo}>
            <TranslateTextComponent capitalize>quantity-shortened</TranslateTextComponent>
          </Typography>
          <Typography style={{ width: '43%' }} className={classes.tableHeaderTypo}>
            <TranslateTextComponent capitalize>order</TranslateTextComponent>
          </Typography>
          <Typography style={{ width: '22%' }} className={classes.tableHeaderTypo}>
            <TranslateTextComponent capitalize>allergens</TranslateTextComponent>
          </Typography>
          <Typography style={{ width: '16%' }} className={classes.tableHeaderTypo}>
            <TranslateTextComponent capitalize>chair</TranslateTextComponent>
          </Typography>
        </Grid>
        {filteredCourses.map((fbCourse, i) => {
          const courseKey = `${typy(tableFbOrders, 'fbReservationTable.fbTable.id').safeNumber}-${fbCourse.number}`;
          return (
            <Grid container key={i} className={classes.tableRowContainer} style={{ backgroundColor: i % 2 === 0 ? 'white' : secondaryColor }}>
              <Grid item className={classes.courseStatusContainer}>
                <Typography className={classes.courseNumberTypo}> {fbCourse.number}</Typography>
                <FbCourseStatusIcon
                  fbCourse={fbCourse}
                  showStatuses={courseKey === changingFbCourseStatus}
                  onClickIcon={() => handleSetChangingFbCourseStatus && handleSetChangingFbCourseStatus(courseKey)}
                  onSelectStatus={(status) => {
                    onSetFbCourseStatus(fbCourse, status);
                  }}
                />
              </Grid>
              <Grid container className={classes.detailedCourseRow}>
                {groupedByMenuItem(fbCourse.fbCoursesMenuItems).map((menuItem, k) => {
                  return (
                    <Grid item key={k} className={classes.detailedCourseInfoContainer}>
                      <Grid style={{ width: '10%' }} className={classes.detailedCourseInfo}>
                        {getTotalQuantity(menuItem)}x
                      </Grid>
                      <Grid style={{ width: '48.5%' }} className={classes.detailedCourseInfo}>
                        {menuItem[0]?.name}
                        <Grid style={{ color: '#449D44', fontSize: 12, fontWeight: 'bold' }}>
                          {menuItem[0]?.with?.length > 0 ? `CON: ${menuItem[0].with.join(', ')}` : ''}
                        </Grid>
                        <Grid style={{ color: '#CB2D24', fontSize: 12, fontWeight: 'bold' }}>
                          {menuItem[0]?.without?.length > 0 ? `SENZA: ${menuItem[0].without.join(', ')}` : ''}
                        </Grid>
                        <Grid style={{ fontSize: 12, fontStyle: 'italic' }}> {menuItem[0].notes} </Grid>
                      </Grid>
                      <Grid style={{ width: '24.5%' }} className={classes.detailedCourseInfo}>
                        {menuItem[0]?.fbMenuItem?.fbAllergens?.length > 0 ? (
                          menuItem[0]?.fbMenuItem?.fbAllergens
                            .sort((prev, next) => {
                              if (prev.name < next.name) {
                                return -1;
                              }
                              if (prev.name > next.name) {
                                return 1;
                              }
                              return 0;
                            })
                            .map((allergen, j) => {
                              return (
                                <Typography key={j} style={{ color: '#CB2D24' }}>
                                  {allergen.name}
                                </Typography>
                              );
                            })
                        ) : (
                          <Typography style={{ color: '#CB2D24' }}>/</Typography>
                        )}
                      </Grid>
                      <Grid className={classes.orderIconContainer}>
                        {menuItem.map((item, j) => (
                          <Box key={j} className={classes.orderIconPosition}>
                            {item.sharedBy && item.sharedBy.length > 1 ? (
                              <SharedBy item={item} />
                            ) : (
                              <Box style={{ fontSize: 10 }}>
                                {item.fbChairId !== null && <ChairOrHalfPortion item={item} />}
                                {item.centerpiece && <Centerpiece />}
                              </Box>
                            )}
                            <Typography className={classes.dishQuantity}>{item.quantity}x</Typography>
                          </Box>
                        ))}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default DetailedSummaryOrder;
