import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core/';

const useStyles = makeStyles(() => ({
  imgBox: () => ({
    width: '100%',
    height: '100%',
    alignSelf: 'center',
    textAlign: 'end'
  }),
  typographyDescr: () => ({
    fontSize: 12,
    color: '#55595C',
    width: '100%'
  }),
  titleCatStyle: () => ({
    fontWeight: 'bold',
    color: '#55595C',
    fontSize: 12
  })
}));

const ImagesCategoryPanel = (props) => {
  const classes = useStyles();
  return (
    <Box>
      <Grid container style={{ flexFlow: 'nowrap' }}>
        <Grid item xs={4} className={classes.imgBox}>
          {props.img}
        </Grid>
        <Grid item xs style={{ paddingLeft: 10 }}>
          <Grid item xs>
            <Typography className={classes.titleCatStyle}>{props.title}</Typography>
          </Grid>
          <Grid item xs>
            <Typography className={classes.typographyDescr}>{props.description}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ImagesCategoryPanel;
