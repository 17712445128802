/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { TranslateTextComponent } from '../../../../translator';
import { ReactComponent as BinIcon } from '../../../../assets/images/orders/bin.svg';
import TableButton from '../../../shared/TableButton';
import { Dropdown } from '../../../../common-fe/src';

const textTranslate = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

const inventoryColumns = ({ onShowItem, onInventoryStatusChange }) => {
  return [
    {
      id: 'name',
      disablePadding: false,
      label: textTranslate('name'),
      isSortable: false,
      align: 'left',
      render: (item) => item.name
    },
    {
      id: 'id',
      disablePadding: false,
      label: textTranslate('ID'),
      isSortable: false,
      align: 'left',
      render: (item) => item.id
    },
    {
      id: 'tax',
      disablePadding: false,
      label: textTranslate('tax'),
      isSortable: false,
      align: 'left',
      render: (item) => item.vatCode?.value
    },
    {
      id: 'price',
      disablePadding: false,
      label: textTranslate('price'),
      isSortable: false,
      align: 'left',
      render: (item) => item.price
    },
    {
      id: 'inventoryQuantity',
      disablePadding: false,
      label: textTranslate('inventory-quantity'),
      isSortable: false,
      align: 'left',
      render: (item) => item.stockQuantity ?? '-'
    },
    {
      id: 'status',
      disablePadding: false,
      label: textTranslate('status'),
      isSortable: false,
      align: 'left',
      render: (item) => textTranslate(item.isHidden ? 'inactive' : 'active')
    },
    {
      id: 'inventory',
      disablePadding: false,
      label: textTranslate('inventory'),
      isSortable: false,
      align: 'left',
      render: (item) => (
        <Dropdown
          dropdownStyle={{ backgroundColor: 'white' }}
          items={[
            { label: textTranslate('active'), value: 'active' },
            { label: textTranslate('not-active'), value: 'inactive' }
          ]}
          value={item.isStockEnabled ? 'active' : 'inactive'}
          onChange={({ target: { value } }) => {
            if ((item.isStockEnabled && value !== 'active') || (!item.isStockEnabled && value !== 'inactive'))
              onInventoryStatusChange({ menuItemUUID: item.uuid, isStockEnabled: value === 'active' });
          }}
        />
      )
    },
    {
      id: 'edit',
      disablePadding: false,
      label: textTranslate('edit'),
      isSortable: false,
      align: 'left',
      width: 'auto',
      render: (item) => (
        <TableButton
          style={{ width: 'auto' }}
          disabled={!item.isStockEnabled}
          onClick={() => {
            onShowItem(item);
          }}
        >
          show
        </TableButton>
      )
    },
    {
      id: 'delete',
      disablePadding: false,
      label: textTranslate('delete'),
      isSortable: false,
      align: 'left',
      render: (item) => (
        <BinIcon
          fill="gray"
          style={{ cursor: item.isSmartOrderServicePaid ? 'default' : 'pointer' }}
          onClick={() => {
            // ENABLE DELETE ONLY IF PAY IS IN PENDING
            // deleteSmartService(item.uuid);
          }}
        />
      )
    }
  ];
};

export default inventoryColumns;
