import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Avatar, Box } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import styles from '../../styles/shared/headerStyle';

const HOME_SVG = require('../../assets/images/home.svg');

const useOutsideAlerter = (menuRef, languageRef, handleClose, handleLanguageSwitcherClose) => {
  /**
   * Alert if clicked on outside of element
   */
  const handleClickOutside = (event) => {
    if ((menuRef.current && !menuRef.current.contains(event.target)) || (languageRef.current && !languageRef.current.contains(event.target))) {
      handleClose();
      handleLanguageSwitcherClose();
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

const Header = ({ section, user, menuContent, languageSwitcher, iconLanguageSelected, languageSelected }) => {
  const classes = styles();

  const [isOpen, setIsOpen] = React.useState(false);
  const [isLanguageSwitcherOpen, setLanguageSwitcherIsOpen] = React.useState(false);

  const handleIsOpen = () => {
    setIsOpen((prevState) => !prevState);
    handleLanguageSwitcherClose();
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleLanguageSwitcherIsOpen = () => {
    setLanguageSwitcherIsOpen((prevState) => !prevState);
    handleClose();
  };

  const handleLanguageSwitcherClose = () => {
    setLanguageSwitcherIsOpen(false);
  };

  const menuRef = useRef(null);
  const languageRef = useRef(null);
  useOutsideAlerter(menuRef, languageRef, handleClose, handleLanguageSwitcherClose);

  const getTitle = (titleSection) => {
    return (
      <Box className={classes.textTitle}>
        <Typography className={classes.homeTypography}>Home &gt;</Typography>
        <Typography className={classes.sectionTypography}>{titleSection}</Typography>
      </Box>
    );
  };

  return (
    <Box ref={menuRef}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Link to="/">
                <img alt="home" src={HOME_SVG} />
              </Link>
              {getTitle(section)}
            </Box>

            <Box style={{ display: 'flex' }}>
              <Box className={classes.languageContainer} onClick={handleLanguageSwitcherIsOpen}>
                <Box style={{ display: 'flex', alignItems: 'center', width: 34, justifyContent: 'center', paddingLeft: 5 }}>
                  {iconLanguageSelected}
                </Box>
                <Box style={{ width: 33, display: 'flex', justifyContent: 'flex-start', paddingLeft: 10 }}>
                  <Typography style={{ color: 'white', fontSize: 14 }}>{languageSelected}</Typography>
                </Box>
                <Box style={{ width: 33 }}>
                  {isLanguageSwitcherOpen ? (
                    <KeyboardArrowUp className={classes.keyArrowStyle} />
                  ) : (
                    <KeyboardArrowDown className={classes.keyArrowStyle} />
                  )}
                </Box>
                {isLanguageSwitcherOpen && <Box className={classes.languageItems}>{languageSwitcher}</Box>}
              </Box>

              {user && (
                <Box className={classes.userContainer} onClick={handleIsOpen}>
                  <Box className={classes.userInfoContainer}>
                    <Typography className={classes.nameText}>{user.username || 'name'}</Typography>
                    {isOpen ? <KeyboardArrowUp className={classes.keyArrowStyle} /> : <KeyboardArrowDown className={classes.keyArrowStyle} />}
                  </Box>
                  <Avatar src={user.avatar} />
                  {isOpen && <Box className={classes.menuItems}>{menuContent}</Box>}
                </Box>
              )}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
export default Header;
