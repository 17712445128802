/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Box, Button, Typography } from '@material-ui/core';
import 'moment/locale/en-gb';
import 'moment/locale/it';
import { getApolloErrors } from '../../apollo/ApolloProvider';
import { CommonTable } from '../../common-fe';
import { ModalContext } from '../../contexts/ModalContext';
import { CREATE_STAFF_USER, DELETE_STAFF_USER, UPDATE_STAFF_USER } from '../../graphql/user/mutations';
import { GET_ROLES, GET_STAFF_USERS } from '../../graphql/user/queries';
import modalStyle from '../../styles/shared/modalStyle';
import useCustomQuery from '../../hooks/useCustomQuery';
import { TranslateTextComponent, capitalizeFirstLetter, translate } from '../../translator';
import CustomFilters from '../shared/CustomFilters/CustomFilters';
import PageLayout from '../shared/PageLayout';
import StaffUserModal from './modals/StaffUserModal';
import staffUsersColumns from './table/staffUsersColumns';
import { LanguageContext } from '../../contexts/LanguageContext';
import { ROLES } from '../../constants/users/usersRole';

const initialFilters = {
  orderBy: 'id',
  orderType: 'DESC',
  page: 1,
  pageSize: 10,
  keyword: '',
  role: null
};

const StaffUsersList = () => {
  const { language } = useContext(LanguageContext);
  const modalContext = useContext(ModalContext);
  const [queryFilters, setQueryFilters] = useState(initialFilters);
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const modalClasses = modalStyle();

  // Queries
  const getStaffUsers = useCustomQuery(GET_STAFF_USERS, {
    variables: {
      orderBy: queryFilters.orderBy,
      orderType: queryFilters.orderType,
      page: queryFilters.page,
      pageSize: queryFilters.pageSize,
      keyword: queryFilters.keyword,
      role: queryFilters.role
    },
    onError: (err) => {
      modalContext.openModal({
        class: 'danger',
        title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
        text: getApolloErrors(err).join(' - ')
      });
    },
    fetchPolicy: 'network-only'
  });

  const getRoles = useCustomQuery(GET_ROLES, { fetchPolicy: 'network-only' });

  // Mutations
  const [createStaffUser, { loading: createStaffUserLoading }] = useMutation(CREATE_STAFF_USER);
  const [updateStaffUser, { loading: updateStaffUserLoading }] = useMutation(UPDATE_STAFF_USER);
  const [deleteStaffUser] = useMutation(DELETE_STAFF_USER);

  const createUser = (values) => {
    createStaffUser({
      variables: {
        fbOutletUUIDs: values.fbOutletUUIDs?.map((outlet) => outlet.value),
        userInput: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password
        },
        role: values.role
      }
    })
      .then(() => {
        modalContext.openModal({
          class: 'success',
          title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
          text: <TranslateTextComponent capitalize>user-added</TranslateTextComponent>
        });
        getStaffUsers.refetch();
        setSelectedUser(null);
        setShowUserModal(false);
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const updateUser = (values) => {
    updateStaffUser({
      variables: {
        uuid: selectedUser.uuid,
        fbOutletUUIDs: values.fbOutletUUIDs?.map((outlet) => outlet.value),
        userInput: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password
        },
        roles: [values.role]
      }
    })
      .then(() => {
        modalContext.openModal({
          class: 'success',
          title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
          text: <TranslateTextComponent capitalize>user-added</TranslateTextComponent>
        });
        getStaffUsers.refetch();
        setSelectedUser(null);
        setShowUserModal(false);
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const onDeleteStaffUser = (uuid) => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>delete-staff-user-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>delete-staff-user-text</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            modalContext.setModalLoading(true);
            deleteStaffUser({ variables: { userUUID: uuid } })
              .then(() => {
                modalContext.setModalLoading(false);
                getStaffUsers.refetch();
                modalContext.openModal({
                  class: 'success',
                  title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
                  text: <TranslateTextComponent capitalize>success-modal-text</TranslateTextComponent>
                });
              })
              .catch((err) => {
                modalContext.setModalLoading(false);
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const handleSearchChange = (value) => {
    setQueryFilters((prev) => ({ ...prev, keyword: value }));
  };

  const setOrderType = (orderBy, orderType) => {
    const isDesc = queryFilters.orderBy === orderBy && orderType === 'desc';
    return isDesc ? 'ASC' : 'DESC';
  };

  const setTableSort = (orderBy, orderType) => {
    setQueryFilters((prev) => ({
      ...prev,
      orderBy,
      orderType: setOrderType(orderBy, orderType)
    }));
  };

  const setTablePages = (page, pageSize) => {
    setQueryFilters((prev) => ({
      ...prev,
      page,
      pageSize
    }));
  };

  return (
    <PageLayout>
      <CustomFilters
        filters={[
          {
            type: 'dropdown',
            label: 'role',
            options: getRoles.data?.getRoles?.data
              ?.map((role) => ({
                key: role.uuid,
                text: capitalizeFirstLetter(translate(role.description?.toLowerCase()?.replace('/_/g', '-')?.replace('role', ''), language)),
                value: role.name
              }))
              ?.concat({ key: -1, text: capitalizeFirstLetter(translate('all', language)), value: null })
              .filter((role) => role.value !== ROLES.ROLE_GUEST),
            value: queryFilters.role,
            onChange: ({ target: { value } }) => setQueryFilters((prev) => ({ ...prev, role: value }))
          },
          { type: 'searchbar', onChange: ({ target: { value } }) => handleSearchChange(value) }
        ]}
      />
      <CommonTable
        isLoading={getStaffUsers.loading}
        headerCellStyle={{ padding: '20px 0px' }}
        isPaginationEnabled
        items={getStaffUsers.data?.getStaffUsers?.data ?? []}
        orderBy={queryFilters.orderBy}
        orderType={queryFilters.orderType.toLocaleLowerCase()}
        page={queryFilters.page}
        pageSize={queryFilters.pageSize}
        setTableSort={setTableSort}
        setTablePages={setTablePages}
        columns={staffUsersColumns({
          onDeleteStaffUser,
          openUserModal: (user) => {
            setSelectedUser(user);
            setShowUserModal(true);
          }
        })}
        totalRows={getStaffUsers?.data?.getStaffUsers?.totalCount ?? 0}
        rowCellStyle={{ paddingLeft: 0 }}
        tableHeader={
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#EDFAFF',
              minHeight: 28,
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
            }}
          >
            <TranslateTextComponent capitalize>users-tot</TranslateTextComponent>: {getStaffUsers?.data?.getStaffUsers?.totalCount ?? 0}
          </Typography>
        }
      />
      <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '30px 10px 20px 0px', minWidth: 325, alignSelf: 'flex-end', fontSize: 20, fontWeight: 500, color: 'white' }}
          onClick={() => setShowUserModal(true)}
        >
          <TranslateTextComponent uppercase>new-account</TranslateTextComponent>
        </Button>
      </Box>
      {showUserModal && (
        <StaffUserModal
          data={selectedUser}
          showModal={showUserModal}
          roles={getRoles.data?.getRoles?.data ?? []}
          loading={createStaffUserLoading || updateStaffUserLoading}
          addNewUser={createUser}
          updateUser={updateUser}
          closeModal={() => {
            setSelectedUser(null);
            setShowUserModal(false);
          }}
        />
      )}
    </PageLayout>
  );
};

export default StaffUsersList;
