import React from 'react';
import Services from '../../../components/Moh/services/Services';
import Layout from '../../../components/shared/Layout';

const MohServicesPage = ({ history }) => {
  return (
    <Layout history={history} section="services-list">
      <Services />
    </Layout>
  );
};

export default MohServicesPage;
