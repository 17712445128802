import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { TextField, RadioGroup, FormControlLabel, Radio, Button } from '@material-ui/core';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import { weekdays } from 'moment';
import InputWithLabel from '../../../shared/InputWithLabel';
import ButtonsGroup from '../../../shared/ButtonsGroup';
import { DataPicker, CustomModal } from '../../../../common-fe/src';
import Buttons from '../../../shared/Buttons';
import { TranslateTextComponent } from '../../../../translator';
import { ADD_SMART_SERVICE, EDIT_SMART_SERVICE } from '../../../../graphql/moh/services/mutations';
import { AuthContext } from '../../../../contexts/AuthContext';
import modalStyle from '../../../../styles/shared/modalStyle';
import { ModalContext } from '../../../../contexts/ModalContext';
import { getApolloErrors } from '../../../../apollo/ApolloProvider';

const Container = styled.form`
  display: flex;
  flex-flow: column;
  width: 100%;

  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  width: 100%;

  & > div:not(:last-child) {
    margin-right: 30px;
  }
`;

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  price: Yup.number().typeError('Number not valid').required('Required'),
  dailyQuantity: Yup.number().typeError('Number not valid').required('Required'),
  dateStart: Yup.string().typeError('Date not valid').required('Required'),
  dateEnd: Yup.string().typeError('Date not valid').required('Required')
});

const ServiceForm = ({ data, showModal, isEditing, closeModal, refetchQuery }) => {
  const modalClasses = modalStyle();
  const { appOutletUUID } = useContext(AuthContext);
  const modalContext = useContext(ModalContext);
  const [days, setDays] = useState(new Array(weekdays().length).fill(false));
  const [initialWeekdays, setInitialWeekdays] = useState(null);

  useEffect(() => {
    setDays([...new Array(weekdays().length).fill(false).map((v, i) => !!data?.days?.includes(i))]);
    setInitialWeekdays([...new Array(weekdays().length).fill(false).map((v, i) => !!data?.days?.includes(i))]);
  }, [data]);

  // Mutation
  const [addSmartService] = useMutation(ADD_SMART_SERVICE);
  const [editSmartService] = useMutation(EDIT_SMART_SERVICE);

  const addService = ({ values }) => {
    addSmartService({
      variables: {
        fbOutletUUID: appOutletUUID,
        smartServiceData: {
          ...values,
          price: parseFloat(values.price, 10),
          dailyQuantity: parseFloat(values.dailyQuantity, 10),
          days: days.reduce((out, bool, i) => (bool ? out.concat(i) : out), [])
        }
      }
    })
      .then(() => {
        refetchQuery();
        closeModal();
        modalContext.openModal({
          class: 'success',
          title: <TranslateTextComponent capitalize>new-moh-service-success</TranslateTextComponent>,
          text: <TranslateTextComponent capitalize>new-moh-service-description-success</TranslateTextComponent>,
          actionButtons: [
            <Button
              key={0}
              variant="contained"
              color="primary"
              className={modalClasses.invertedButton}
              onClick={() => {
                modalContext.closeModal();
              }}
            >
              <TranslateTextComponent uppercase>ok</TranslateTextComponent>
            </Button>
          ]
        });
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const editService = ({ values }) => {
    editSmartService({
      variables: {
        smartServiceUUID: data.uuid,
        smartServiceData: {
          ...values,
          price: parseFloat(values.price, 10),
          dailyQuantity: parseFloat(values.dailyQuantity, 10),
          days: days.reduce((out, bool, i) => (bool ? out.concat(i) : out), [])
        }
      }
    })
      .then(() => {
        refetchQuery();
        closeModal();
        modalContext.openModal({
          class: 'success',
          title: <TranslateTextComponent capitalize>edit-moh-service-success</TranslateTextComponent>,
          text: <TranslateTextComponent capitalize>edit-moh-service-description-success</TranslateTextComponent>,
          actionButtons: [
            <Button
              key={0}
              variant="contained"
              color="primary"
              className={modalClasses.invertedButton}
              onClick={() => {
                modalContext.closeModal();
              }}
            >
              <TranslateTextComponent uppercase>ok</TranslateTextComponent>
            </Button>
          ]
        });
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleReset, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name ?? '',
      price: data?.price ?? '',
      dailyQuantity: data?.dailyQuantity ?? '',
      description: data?.description ?? '',
      dateStart: data?.dateStart ?? null,
      dateEnd: data?.dateEnd ?? null,
      isActive: data?.isActive ?? true
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (isEditing) editService({ values });
      else addService({ values });
      setSubmitting(false);
    }
  });

  return (
    <CustomModal
      showModal={showModal}
      hideCloseButton
      onClose={closeModal}
      width="auto"
      height="auto"
      styledHeader
      styleBody={{
        borderTop: 'none',
        backgroundColor: 'white',
        borderRadius: 20,
        justifyContent: 'flex-start',
        padding: '25px 60px'
      }}
      header={<TranslateTextComponent uppercase>new-service</TranslateTextComponent>}
    >
      <Container>
        <RowContainer>
          <InputWithLabel label="name" error={touched.name && errors.name}>
            <TextField
              name="name"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.name}
              error={touched.name && !!errors.name}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
        </RowContainer>
        <RowContainer>
          <InputWithLabel label="price" error={touched.price && errors.price}>
            <TextField
              name="price"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.price}
              error={touched.price && !!errors.price}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
          <InputWithLabel label="daily-amount" error={touched.dailyQuantity && errors.dailyQuantity}>
            <TextField
              name="dailyQuantity"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.dailyQuantity}
              error={touched.dailyQuantity && !!errors.dailyQuantity}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
        </RowContainer>
        <RowContainer>
          <InputWithLabel label="description" error={touched.description && errors.description}>
            <TextField
              name="description"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.description}
              error={touched.description && !!errors.description}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
        </RowContainer>
        <RowContainer style={{ justifyContent: 'center' }}>
          <InputWithLabel containerStyle={{ justifyContent: 'center' }}>
            <ButtonsGroup
              buttons={[
                ...days.slice(1).map((value, i) => ({ label: weekdays()[i + 1].toLowerCase(), checked: value, buttonStyle: { width: '100%' } })),
                { label: weekdays()[0].toLowerCase(), checked: days[0], buttonStyle: { width: '100%' } }
              ]}
              onChange={(index) => {
                const newDays = days;
                if (index + 1 > days.length - 1) {
                  newDays[0] = !newDays[0];
                } else newDays[index + 1] = !newDays[index + 1];
                setDays([...newDays]);
              }}
            />
          </InputWithLabel>
        </RowContainer>
        <RowContainer style={{ justifyContent: 'space-between' }}>
          <InputWithLabel
            label="start-date"
            containerStyle={{ width: 'auto' }}
            labelStyle={{ fontSize: 16 }}
            horizontal
            error={touched.dateStart && errors.dateStart}
          >
            <DataPicker
              dataPickerStyle={{ minHeight: 30, maxWidth: 180 }}
              value={values.dateStart}
              onChange={(value) => setFieldValue('dateStart', value)}
            />
          </InputWithLabel>
          <InputWithLabel
            label="end-date"
            containerStyle={{ justifyContent: 'flex-end', width: 'auto' }}
            labelStyle={{ fontSize: 16 }}
            horizontal
            error={touched.dateEnd && errors.dateEnd}
          >
            <DataPicker
              dataPickerStyle={{ minHeight: 30, maxWidth: 180 }}
              value={values.dateEnd}
              onChange={(value) => setFieldValue('dateEnd', value)}
            />
          </InputWithLabel>
        </RowContainer>
        <RowContainer>
          <InputWithLabel label="status:" labelStyle={{ fontSize: 16 }} horizontal>
            <RadioGroup
              style={{ display: 'flex', flexDirection: 'row' }}
              value={values.isActive}
              onChange={({ target: { value } }) => setFieldValue('isActive', value === 'enabled')}
            >
              <FormControlLabel
                checked={values.isActive}
                value="enabled"
                control={<Radio color="primary" />}
                label={<TranslateTextComponent capitalize>enabled-check</TranslateTextComponent>}
                labelPlacement="end"
              />
              <FormControlLabel
                checked={!values.isActive}
                value="disabled"
                control={<Radio color="primary" />}
                label={<TranslateTextComponent capitalize>disabled-check</TranslateTextComponent>}
                labelPlacement="end"
              />
            </RadioGroup>
          </InputWithLabel>
          <Buttons
            buttons={[
              {
                buttonType: 'reset',
                type: 'cancel',
                onClick: () => {
                  setDays([...initialWeekdays]);
                  handleReset();
                }
              },
              { buttonType: 'submit', type: 'confirm', disabled: isSubmitting, onClick: handleSubmit }
            ]}
          />
        </RowContainer>
      </Container>
    </CustomModal>
  );
};

export default ServiceForm;
