import React, { useRef } from 'react';
import typy from 'typy';
import { Box } from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import { CustomModal, CustomButton } from '../../../common-fe/src';
import * as documents from '../../../constants/cash/documentTypes';
import { TranslateTextComponent } from '../../../translator';
import FetchImage from '../../shared/FetchImage';

const Check = ({ showCheckModal, currentOrderBill, closeModal }) => {
  const document = currentOrderBill.fbBillDocuments.filter((doc) => [documents.DIGITAL_CHECK, documents.PAPER_CHECK].some((d) => d === doc.type))[0];
  const imagePath = typy(document, 'images[0].path').safeString;
  const imageRef = useRef(null);

  return (
    <CustomModal showModal={showCheckModal} onClose={closeModal} width="auto" height="auto">
      <Box style={{ display: 'flex', flexFlow: 'column' }}>
        {imagePath && <FetchImage imgRef={imageRef} imagePath={imagePath} />}
        <ReactToPrint
          trigger={() => (
            <CustomButton>
              <TranslateTextComponent>print</TranslateTextComponent>
            </CustomButton>
          )}
          content={() => imageRef.current}
        />
      </Box>
    </CustomModal>
  );
};

export default Check;
