import { gql } from 'apollo-boost';
import { VAT_CODES_FRAGMENT, FB_MENU_ITEMS_FRAGMENT } from '../../fragments';

export const GET_VAT_CODES = gql`
  query getVatCodes($page: Int, $pageSize: Int) {
    getVatCodes(page: $page, pageSize: $pageSize) {
      totalCount
      data {
        ...vatCodesFragment
      }
    }
  }
  ${VAT_CODES_FRAGMENT}
`;

export const GET_FB_MENU_ITEMS = gql`
  query getFbMenuItems($fbOutletUUID: String!, $fbMenuCategoryUUID: String, $page: Int, $pageSize: Int) {
    getFbMenuItems(fbOutletUUID: $fbOutletUUID, fbMenuCategoryUUID: $fbMenuCategoryUUID, page: $page, pageSize: $pageSize) {
      totalCount
      data {
        ...fbMenuItemsFragment
      }
    }
  }
  ${FB_MENU_ITEMS_FRAGMENT}
`;
