import { makeStyles } from '@material-ui/core/styles';
import { primaryColor, invertedPrimaryCC80 } from '../../common-fe/src';

const styles = {
  container: {
    margin: '10px 0',
    padding: '5px 10px',
    border: `1px solid ${primaryColor}`,
    borderRadius: 5,
    backgroundColor: 'white',
    maxWidth: 910,
    width: '100%'
  },
  panelContainer: {
    boxShadow: 'none'
  },
  panelTitleContainer: ({ expanded }) => ({
    borderBottom: expanded ? `1px solid ${invertedPrimaryCC80}` : 'none'
  }),
  panelTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: primaryColor
  },
  panelDetails: {
    display: 'flex',
    flexFlow: 'column'
  },
  formContainer: {
    display: 'grid',
    gridTemplateAreas: '"searchbar searchbar" "left right"',
    gridColumnGap: 30,
    width: '100%',
    alignItems: 'end'
  },
  inputTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#55595C'
  },
  input: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      padding: '8px 0',
      textIndent: 10
    }
  },
  switch: {
    '& .MuiSwitch': {
      opacity: 1
    },
    '& .MuiSwitch-track': {
      backgroundColor: primaryColor,
      opacity: 0.5
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: primaryColor
    }
  },
  usersButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0'
  },
  pmTextField: {
    fontSize: 14,
    height: 35,
    color: '#55595C',
    border: '1.5px solid',
    borderColor: primaryColor,
    borderRadius: 4
  }
};

export default makeStyles(styles);
