import React, { forwardRef, useContext } from 'react';
import styled from 'styled-components/macro';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { TranslateTextComponent, translate, capitalizeFirstLetter } from '../../translator';
import { mainTextColor } from '../../styles/theme';
import { ReactComponent as AdultIcon } from '../../assets/images/adult.svg';
import { ReactComponent as KidIcon } from '../../assets/images/kid.svg';
import { ReactComponent as EnfantIcon } from '../../assets/images/enfant.svg';
import { LanguageContext } from '../../contexts/LanguageContext';

const COLUMNS = ['client', 'room', 'pax', 'time', 'allergens', 'intolerances', 'notes', 'arr.', 'mobile-phone'];

const getNPax = (guestsList) => {
  if (!guestsList) return '-';

  const PhysicalCategoryBox = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-right: 10px;
  `;

  const nPax = {
    adults: { number: 0, icon: <AdultIcon style={{ width: 10, height: 10 }} /> },
    kids: { number: 0, icon: <KidIcon style={{ width: 10, height: 10 }} /> },
    enfants: { number: 0, icon: <EnfantIcon style={{ width: 10, height: 10 }} /> }
  };
  guestsList.forEach((guest) => {
    nPax[guest.guestPhysicalCategory.name].number += 1;
  });

  return (
    <div style={{ color: mainTextColor }}>
      <span style={{ fontSize: 16 }}>{guestsList.length}</span>
      <br />
      <div style={{ display: 'flex' }}>
        {Object.keys(nPax).map((key) => {
          if (nPax[key].number > 0)
            return (
              <PhysicalCategoryBox key={key}>
                {nPax[key].icon}
                <span style={{ fontSize: 11 }}>{nPax[key].number}</span>
              </PhysicalCategoryBox>
            );

          return null;
        })}
      </div>
    </div>
  );
};

const PrintableReservations = forwardRef(({ reservations }, ref) => {
  const { language } = useContext(LanguageContext);

  return (
    <TableContainer
      ref={ref}
      component={Paper}
      style={{
        boxShadow: 'none'
      }}
    >
      <Table aria-label="reservations table">
        <TableHead>
          <TableRow>
            {COLUMNS.map((col, index) => (
              <TableCell key={index} style={{ border: '1px solid black' }}>
                <TranslateTextComponent capitalize>{col}</TranslateTextComponent>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {reservations?.map((reservation) => (
            <TableRow key={reservation.uuid} style={{ border: '1px solid black' }}>
              <TableCell style={{ border: '1px solid black' }}>
                {`${reservation.bookedBy?.firstname ?? ''} ${reservation.bookedBy?.lastname ?? ''}`.trim()}
              </TableCell>
              <TableCell style={{ border: '1px solid black' }}>{reservation.guestRoom}</TableCell>
              <TableCell style={{ border: '1px solid black' }}>{getNPax(reservation.fbGuestList)}</TableCell>
              <TableCell style={{ border: '1px solid black' }}>{reservation.reservationTime}</TableCell>
              <TableCell style={{ border: '1px solid black' }}>
                {reservation.fbProfilePreferences?.allergens?.map((allergen) => capitalizeFirstLetter(translate(allergen, language))).join(', ')}
              </TableCell>
              <TableCell style={{ border: '1px solid black' }}>
                {reservation.fbProfilePreferences?.intolerances
                  ?.map((intolerance) => capitalizeFirstLetter(translate(intolerance, language)))
                  .join(', ')}
              </TableCell>
              <TableCell style={{ border: '1px solid black', minWidth: 200 }}>{reservation.notes}</TableCell>
              <TableCell style={{ border: '1px solid black' }}>{reservation.arrangement}</TableCell>
              <TableCell style={{ border: '1px solid black' }}>{reservation.bookedBy?.mobilePhone}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default PrintableReservations;
