import React, { useState, useContext, useRef } from 'react';
import { Button, Grid, Box, Typography } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { EDIT_MENU_ITEM, DELETE_MENU_ITEM } from '../../graphql/menu/mutations';
import { TranslateTextComponent } from '../../translator';
import menuStyle from '../../styles/menu/menuStyle';
import ColoredCircle from './ColoredCircle';
import { Dropdown } from '../../common-fe';
import modalStyle from '../../common-fe/src/styles/shared/modalStyle';
import MenuContext from '../../contexts/MenuContext';
import editIcon from '../../assets/images/menu/edit.svg';
import deleteIcon from '../../assets/images/menu/delete.svg';
import { ModalContext } from '../../contexts/ModalContext';
import { primaryColor } from '../../styles/theme';
import FetchImage from '../shared/FetchImage';
import { getApolloErrors } from '../../apollo/ApolloProvider';

const MenuItem = ({ item, categories, categoryUUID, openEditItemModal, refetchCategories }) => {
  const modalContext = useContext(ModalContext);
  const { view } = useContext(MenuContext);
  const [fbMenuCategoryUUID, setFbMenuCategoryUUID] = useState(categoryUUID);
  const imageRef = useRef(null);

  const classes = menuStyle({ view, showAllergens: item?.fbAllergens?.length > 0 });
  const modalClasses = modalStyle();

  // Mutations
  const [editMenuItem] = useMutation(EDIT_MENU_ITEM);
  const [deleteMenuItem] = useMutation(DELETE_MENU_ITEM);

  const onCategoryChange = (uuid) => {
    setFbMenuCategoryUUID(uuid);
    editMenuItem({
      variables: { fbMenuItemUUID: item?.uuid, fbMenuCategoryUUID: uuid, fbMenuItemData: { name: item?.name, vatCode: item?.vatCode?.code } }
    })
      .then(() => {
        refetchCategories();
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>Error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const onDeleteMenuItemHandler = () => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent uppercase>delete-menu-item-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>delete-menu-item</TranslateTextComponent>,
      actionButtons: [
        <Button key={0} variant="outlined" color="primary" className={modalClasses.invertedButton} onClick={modalContext.closeModal}>
          <TranslateTextComponent>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            deleteMenuItem({ variables: { fbMenuItemUUID: item?.uuid } })
              .then(() => {
                modalContext.openModal({
                  class: 'success',
                  title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
                  text: <TranslateTextComponent capitalize>item-deleted</TranslateTextComponent>
                });

                refetchCategories();
              })
              .catch((err) => {
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  return view === 'box' ? (
    <Grid container className={classes.containerBox} view={view}>
      <Grid item className={classes.imageAreaFlat}>
        {item?.images?.filter((image) => image.isPrimary)?.[0] ? (
          <FetchImage
            imgRef={imageRef}
            imagePath={item?.images?.filter((image) => image.isPrimary)?.[0]?.path}
            style={{ height: 70, width: 70, borderRadius: 9 }}
          />
        ) : (
          <img src="https://via.placeholder.com/70x70?text=No+Image" alt="no img" style={{ borderRadius: 9, height: 68, width: 70 }} />
        )}
      </Grid>
      <Box className={classes.nameAreaBox}>
        <Box style={{ gridArea: 'title', overflow: 'hidden' }}>{item?.name}</Box>
        <Box className={classes.priceAreaBox}>
          <Typography>€ {item?.price.toFixed(2)}</Typography>
        </Box>
        <Box className={classes.categoryAreaBox}>
          <Dropdown
            dropdownStyle={{ marginTop: 5 }}
            value={fbMenuCategoryUUID}
            items={categories?.map((category) => ({
              ...category,
              label: (
                <Grid container direction="row" alignItems="center" wrap="nowrap">
                  <ColoredCircle color={category.color} size={20} margin="0 5px 0 0" />{' '}
                  {category.name.length > 5 ? `${category.name.substring(0, 5)}...` : category.name}
                </Grid>
              ),
              value: category.uuid
            }))}
            onChange={(e) => {
              onCategoryChange(e.target.value);
            }}
          />
        </Box>
      </Box>
      {item?.fbAllergens?.map((allergen) => allergen.name)?.length > 0 && (
        <Box className={classes.allergensAreaBox}>
          <Box style={{ gridArea: 'title' }}>
            <Typography style={{ fontWeight: 'bold', color: '#55595C' }}>
              <TranslateTextComponent uppercase>allergens</TranslateTextComponent>
            </Typography>
          </Box>
          <Box style={{ gridArea: 'allergens-list' }}>
            <Typography style={{ fontStyle: 'italic', color: '#55595C' }}>
              {item?.fbAllergens?.map((allergen) => allergen.name).join(', ')}
            </Typography>
          </Box>
        </Box>
      )}
      <Box className={classes.buttonsAreaBox}>
        <Button className={classes.menuItemButton} onClick={() => openEditItemModal(item)}>
          <img src={editIcon} alt="Edit" />
        </Button>
        <Button className={classes.menuItemButton} onClick={onDeleteMenuItemHandler}>
          <img src={deleteIcon} alt="Delete" />
        </Button>
      </Box>
    </Grid>
  ) : (
    <Grid container className={classes.containerFlat} view={view}>
      <Box className={classes.imageAreaFlat}>
        {item?.images?.filter((image) => image.isPrimary)?.[0] ? (
          <FetchImage
            imgRef={imageRef}
            imagePath={item?.images?.filter((image) => image.isPrimary)?.[0]?.path}
            style={{ height: 70, width: 70, borderRadius: 9 }}
          />
        ) : (
          <img src="https://via.placeholder.com/70x70?text=No+Image" alt="no img" style={{ borderRadius: 9, height: 68, width: 70 }} />
        )}
      </Box>
      <Box className={classes.nameAreaFlat}>
        <Box style={{ gridArea: 'name', display: 'flex', alignItems: 'center', height: '100%' }}>{item?.name}</Box>
      </Box>
      <Box className={classes.infoAreaFlat}>
        <Box style={{ gridArea: 'price', marginTop: 'auto', fontWeight: 500, paddingBottom: 5, borderBottom: `1px solid ${primaryColor}` }}>
          € {item?.price.toFixed(2)}
        </Box>
        <Box style={{ gridArea: 'notes', paddingTop: 5 }}>
          <span style={{ fontWeight: 500 }}>
            <TranslateTextComponent uppercase>notes</TranslateTextComponent>
          </span>
          <span>{item?.notes}</span>
        </Box>
      </Box>
      <Box className={classes.allergensAreaFlat}>
        <span style={{ fontWeight: 500 }}>
          <TranslateTextComponent uppercase>allergens</TranslateTextComponent>
        </span>
        <Box>
          {item?.fbAllergens
            ?.map((allergen) => allergen.name)
            ?.map((allergen, index) => (
              <div key={index}>{allergen}</div>
            ))}
        </Box>
      </Box>
      <Box className={classes.categoryAreaFlat}>
        <Box style={{ fontWeight: 500 }}>
          <TranslateTextComponent uppercase>move-in</TranslateTextComponent>
        </Box>
        <Dropdown
          dropdownStyle={{ marginTop: 5 }}
          value={fbMenuCategoryUUID}
          items={categories?.map((category) => ({
            ...category,
            label: (
              <Grid container direction="row" alignItems="center" wrap="nowrap">
                <ColoredCircle color={category.color} size={20} margin="0 5px 0 0" />{' '}
                {category.name.length > 5 ? `${category.name.substring(0, 5)}...` : category.name}
              </Grid>
            ),
            value: category.uuid
          }))}
          onChange={(e) => {
            onCategoryChange(e.target.value);
          }}
        />
      </Box>
      <Box className={classes.buttonsAreaFlat}>
        <Button onClick={() => openEditItemModal(item)} style={{ minWidth: 0, paddingRight: 0 }}>
          <img src={editIcon} alt="Edit" />
        </Button>
        <Button onClick={onDeleteMenuItemHandler} style={{ minWidth: 0, paddingRight: 0 }}>
          <img src={deleteIcon} alt="Delete" />
        </Button>
      </Box>
    </Grid>
  );
};

export default MenuItem;
