import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import { LanguageContext } from '../contexts/LanguageContext';
import EN from './translations/en.json';
import IT from './translations/it.json';

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getTranslatedDate = (date, language) => {
  if (language === 'IT') moment.locale(language.toLowerCase());
  else moment.locale(`${language.toLowerCase()}'-gb'`);
  const format = moment().localeData().longDateFormat('L');
  return moment(date).format(format);
};

export const TranslateTextComponent = ({ children, vars, uppercase, capitalize, suffix, isHtml, className, style, onClick }) => {
  const languageContext = useContext(LanguageContext);

  const { language } = languageContext;

  let translation = translate(children, language);
  if (vars && Object.keys(vars)) {
    Object.keys(vars).forEach((key) => {
      const regex = new RegExp(key, 'g');

      if (moment(vars[key], 'YYYY/MM/DD', true).isValid()) {
        vars[key] = getTranslatedDate(vars[key], languageContext.language);
      }
      translation = translation.replace(regex, vars[key]);
      translation = translation.replace(`%${vars[key]}%`, vars[key]);
    });
  }

  if (uppercase) {
    translation = translation.toUpperCase();
  }
  if (capitalize) {
    translation = capitalizeFirstLetter(translation);
  }

  if (suffix) {
    translation = `${translation}${suffix}`;
  }

  return isHtml ? (
    <span className={className} style={style} dangerouslySetInnerHTML={{ __html: translation }} onClick={onClick} />
  ) : (
    <span className={className} style={style} onClick={onClick}>
      {translation}
    </span>
  );
};

export const translate = (key, language = 'EN') => {
  const languages = {
    EN,
    IT
  };

  const selectedLanguageKeys = languages[language];

  return selectedLanguageKeys?.[key] || key;
};
TranslateTextComponent.defaultProps = {
  uppercase: false,
  capitalize: false,
  suffix: ''
};

TranslateTextComponent.propTypes = {
  uppercase: PropTypes.bool,
  capitalize: PropTypes.bool,
  suffix: PropTypes.string
};
