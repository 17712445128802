import gql from 'graphql-tag';
import { FB_CHAIR_FRAGMENT } from '../fragments';

export const GET_ROOMS = gql`
  query getFbRooms($fbOutletUUID: String!) {
    getFbRooms(fbOutletUUID: $fbOutletUUID) {
      data {
        id
        uuid
        name
        maxPax
        description
        isActive
        fbTables {
          id
          number
          description
          shape
          positionX
          positionY
          capacity
          rotate
        }
      }
    }
  }
`;

export const GET_TABLES = gql`
  query getFbTables($fbRoomUUID: String!) {
    getFbTables(fbRoomUUID: $fbRoomUUID) {
      data {
        id
        uuid
        isActive
        fbRoomId
        number
        description
        shape
        positionX
        positionY
        capacity
        tabletop
        rotate
        fbReservationTables {
          id
          fbReservationId
          fbReservation {
            id
            uuid
            bookedBy {
              id
              firstname
              lastname
            }
            reservationTime
            guestRoom
          }
          fbOrder {
            id
            uuid
            fbCourses {
              id
              uuid
              number
              type
              fbCourseStatus
              isWaiting
            }
          }
        }
        fbChairs {
          ...fbChairFragment
        }
      }
    }
  }
  ${FB_CHAIR_FRAGMENT}
`;

export const GET_FB_TABLE = gql`
  query getFbTable($fbTableUUID: String!) {
    getFbTable(fbTableUUID: $fbTableUUID) {
      id
      uuid
      number
      description
      shape
      capacity
      rotate
      tabletop
      isActive
      positionX
      positionY
      fbRoom {
        id
        uuid
        name
        maxPax
        description
      }
      fbChairs {
        ...fbChairFragment
      }
      fbReservationTables {
        id
        fbReservationId
        fbTableId
        fbTable {
          id
          isActive
        }
        fbOrder {
          uuid
          id
        }
        fbReservation {
          id
          uuid
          reservationTime
          guestRoom
          nPax
          fbGuestList {
            id
            guestPhysicalCategoryId
            age
            gender
            guestPhysicalCategory {
              id
              name
              ageRange
            }
          }
          bookedBy {
            id
            firstname
            lastname
            gender
            dob
            nationality
            address
            city
            country
            email
            favouriteWine
            favouriteWater
            preferences
            guestsFbAllergens {
              type
              fbAllergen {
                id
                name
                description
              }
            }
          }
          guestRoom
          tableNumber
        }
      }
      fbChairs {
        ...fbChairFragment
      }
    }
  }
  ${FB_CHAIR_FRAGMENT}
`;
