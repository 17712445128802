import React from 'react';
import { TableBody, TableRow, Table, TableCell, Checkbox, Button, Fab } from '@material-ui/core';
import moment from 'moment';
import typy from 'typy';
import ColoredCircle from '../../../../shared/ColoredCircle';
import { TranslateTextComponent } from '../../../../../translator';
import { MaterialIcon, secondaryColor, greenButton, redButton } from '../../../../../common-fe/src';
import styles from '../../../../../styles/billsManagement/wideCard';

const PMDetails = ({ pmData, checkedFbBills, onShowCheckHandler, onCheckPm, onCreatePayMasterBillFast }) => {
  const classes = styles();

  return (
    <Table className={classes.pmDetailsTable} size="small">
      <TableBody>
        {pmData.fbBills && pmData.fbBills.length <= 0 ? (
          <TableRow>
            <TableCell>-</TableCell>
          </TableRow>
        ) : (
          pmData.fbBills.map((d, index) => (
            <TableRow key={d.uuid} style={{ backgroundColor: index % 2 === 0 ? secondaryColor : 'white' }}>
              <TableCell>
                {!d.isPaid && <Checkbox color="primary" checked={checkedFbBills.includes(d.uuid)} onClick={() => onCheckPm(pmData.uuid, d.uuid)} />}
              </TableCell>
              <TableCell>
                {index + 1} (#{d.id})
              </TableCell>
              <TableCell>{moment(d.datetime).format('DD/MM/YYYY')}</TableCell>
              <TableCell>{moment(d.datetime).format('HH:mm')}</TableCell>
              <TableCell style={{ width: '100%' }}>{d.paidBy && `${d.paidBy.firstname} ${d.paidBy.lastname}`}</TableCell>
              <TableCell>€ {typy(d, 'total').safeNumber.toFixed(2).replace('.', ',')}</TableCell>
              <TableCell>
                <ColoredCircle color={d.isPaid === true ? greenButton : redButton} />
              </TableCell>
              <TableCell>
                <Fab
                  className={classes.pmDetailsFabButton}
                  color="primary"
                  size="small"
                  onClick={() => onShowCheckHandler(d)}
                  disabled={d.fbBillDocuments.length === 0}
                >
                  <MaterialIcon style={{ color: 'white', fontSize: '1.2rem' }} iconName="Receipt" />
                </Fab>
              </TableCell>
              <TableCell>
                <Button
                  className={classes.pmDetailsButton}
                  color="primary"
                  variant="contained"
                  disabled={d.isPaid === true}
                  onClick={() => onCreatePayMasterBillFast(d.uuid)}
                >
                  <TranslateTextComponent>pay</TranslateTextComponent>
                </Button>
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
};

export default PMDetails;
