import gql from 'graphql-tag';
import { FB_RESERVATION_FRAGMENT } from '../fragments';

export const ADD_FB_RESERVATION = gql`
  mutation addFbReservation(
    $fbGuestUUID: String!
    $fbServiceUUID: String!
    $fbReservationsData: FbReservationInput!
    $packageUUID: String
    $channelUUID: String
  ) {
    addFbReservation(
      fbGuestUUID: $fbGuestUUID
      fbServiceUUID: $fbServiceUUID
      fbReservationsData: $fbReservationsData
      packageUUID: $packageUUID
      channelUUID: $channelUUID
    ) {
      ...fbReservationFragment
    }
  }
  ${FB_RESERVATION_FRAGMENT}
`;

export const EDIT_FB_RESERVATION_MUTATION = gql`
  mutation editFbReservation(
    $fbReservationUUID: String!
    $fbServiceUUID: String
    $fbReservationsData: FbReservationEditInput!
    $fbGuestUUID: String!
    $channelUUID: String
    $packageUUID: String
  ) {
    editFbReservation(
      fbReservationUUID: $fbReservationUUID
      fbServiceUUID: $fbServiceUUID
      fbReservationsData: $fbReservationsData
      fbGuestUUID: $fbGuestUUID
      channelUUID: $channelUUID
      packageUUID: $packageUUID
    ) {
      ...fbReservationFragment
    }
  }
  ${FB_RESERVATION_FRAGMENT}
`;

export const DELETE_FB_RESERVATION_MUTATION = gql`
  mutation deleteFbReservation($fbReservationUUID: String!) {
    deleteFbReservation(fbReservationUUID: $fbReservationUUID) {
      id
      uuid
    }
  }
`;

export const CLOSE_FB_RESERVATION_SERVICE_MUTATION = gql`
  mutation closeFbReservationsService($fbServiceUUID: String!, $date: String!) {
    closeFbReservationsService(fbServiceUUID: $fbServiceUUID, date: $date)
  }
`;

export const SET_FB_RESERVATION_GUEST_ARRIVED_MUTATION = gql`
  mutation setFbReservationGuestArrived($fbReservationUUID: String!) {
    setFbReservationGuestArrived(fbReservationUUID: $fbReservationUUID) {
      id
      uuid
    }
  }
`;

export const EDIT_FB_RESERVATION_CHANNEL = gql`
  mutation editFbReservationChannel($fbReservationUUID: String!, $channelUUID: String!) {
    editFbReservationChannel(fbReservationUUID: $fbReservationUUID, channelUUID: $channelUUID) {
      id
      uuid
    }
  }
`;

export const EDIT_FB_RESERVATION_PACKAGE = gql`
  mutation editFbReservationPackage($fbReservationUUID: String!, $packageUUID: String) {
    editFbReservationPackage(fbReservationUUID: $fbReservationUUID, packageUUID: $packageUUID) {
      id
      uuid
    }
  }
`;
