import gql from 'graphql-tag';

export const GET_ROOMS = gql`
  query getFbRooms($fbOutletUUID: String!) {
    getFbRooms(fbOutletUUID: $fbOutletUUID) {
      data {
        id
        uuid
        name
        maxPax
        description
        isActive
        fbTables {
          id
          number
          description
          shape
          isActive
          positionX
          positionY
          capacity
          rotate
        }
      }
    }
  }
`;

export const GET_TABLES = gql`
  query getFbTables($fbRoomUUID: String!) {
    getFbTables(fbRoomUUID: $fbRoomUUID) {
      data {
        id
        uuid
        number
        isActive
        description
        shape
        positionX
        positionY
        capacity
        tabletop
        rotate
      }
    }
  }
`;

export const GET_DEFAULT_TABLES = gql`
  query getFbDefaultTables($fbRoomUUID: String!) {
    getFbDefaultTables(fbRoomUUID: $fbRoomUUID) {
      data {
        id
        uuid
        number
        description
        shape
        isActive
        positionX
        positionY
        capacity
        tabletop
        rotate
      }
    }
  }
`;
