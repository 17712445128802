import React from 'react';
import Layout from '../components/shared/Layout';
import AssignChair from '../components/tables/tableDetail/AssignChair';

const AssignChairPage = ({ match, history }) => {
  return (
    <Layout history={history} section="assign-chair">
      <AssignChair fbTableUUID={match.params.uuid} history={history} />
    </Layout>
  );
};

export default AssignChairPage;
