import React, { useContext, useState, useEffect } from 'react';
import { Typography, Button, Grid } from '@material-ui/core';
import axios from 'axios';
import { useMutation, useSubscription } from '@apollo/react-hooks';
import moment from 'moment';
import PageLayout from '../shared/PageLayout';
import { CommonTable } from '../../common-fe/src';
import { translate, TranslateTextComponent } from '../../translator';
import { LanguageContext } from '../../contexts/LanguageContext';
import { AuthContext } from '../../contexts/AuthContext';
import CheckInModal from './modals/CheckInModal';
import checkInColumns from './table/checkInColumns';
import ViewPhoto from '../shared/ViewPhoto';
import useCustomQuery from '../../hooks/useCustomQuery';
import { GET_CHECKINS } from '../../graphql/user/queries';
import { SAVE_CHECKIN } from '../../graphql/user/mutations';
import { ON_CHECKIN_SAVED } from '../../graphql/user/subscriptions';
import modalStyle from '../../styles/shared/modalStyle';
import { ModalContext } from '../../contexts/ModalContext';
import { getApolloErrors } from '../../apollo/ApolloProvider';
import CustomFilters from '../shared/CustomFilters/CustomFilters';
import CSVCheckbox from '../GuestProfiles/CSVCheckbox';
import checkinsCSVFields from './checkinsCSVFields';

const initialFilters = {
  orderBy: 'id',
  orderType: 'DESC',
  page: 1,
  pageSize: 10,
  keyword: '',
  checkinDate: null,
  checkoutDate: null,
  roomNumber: null
};

const initialCsvFields = checkinsCSVFields.map(({ label }) => label);

const CheckInList = () => {
  const { user } = useContext(AuthContext);
  const authToken = user?.access_token;
  const { language } = useContext(LanguageContext);
  const modalContext = useContext(ModalContext);
  const [queryFilters, setQueryFilters] = useState(initialFilters);
  const [modalData, setModalData] = useState(null);
  const [showDocument, setShowDocument] = useState(false);
  const [documentImages, setDocumentImages] = useState([]);
  const [profileUUID, setProfileUUID] = useState();
  const [csvFields, setCsvFields] = useState(initialCsvFields);

  const modalClasses = modalStyle();

  useEffect(() => {
    if (modalData?.documentImages?.length > 0) {
      const allImages = [];
      for (let i = 0; i < modalData?.documentImages?.length; i++) {
        const image = axios.get(process.env.REACT_APP_SERVER_URL + modalData?.documentImages?.[i]?.path, {
          headers: { Authorization: `Bearer ${user.access_token}` },
          responseType: 'arraybuffer'
        });
        allImages.push(image);
      }
      Promise.all(allImages).then((res) => {
        const updatedImages = [];
        res.forEach((data) => {
          const image = Buffer.from(data?.data, 'binary').toString('base64');
          updatedImages.push(`data:image/jpeg;base64,${image}`);
        });
        setDocumentImages(updatedImages);
      });
    }
  }, [modalData]);

  // Queries
  const getCheckins = useCustomQuery(GET_CHECKINS, { variables: queryFilters, fetchPolicy: "no-cache" });
  // Mutations
  const [saveCheckin] = useMutation(SAVE_CHECKIN);

  // Subscriptions
  useSubscription(ON_CHECKIN_SAVED, {
    onSubscriptionData: () => {
      getCheckins.refetch({
        orderBy: queryFilters.orderBy,
        orderType: queryFilters.orderType,
        page: queryFilters.page,
        pageSize: queryFilters.pageSize,
        keyword: queryFilters.keyword
      });
    }
  });

  const handleSearchChange = (value) => {
    setQueryFilters((prev) => ({ ...prev, keyword: value }));
  };

  const setOrderType = (orderBy, orderType) => {
    const isDesc = queryFilters.orderBy === orderBy && orderType === 'desc';
    return isDesc ? 'ASC' : 'DESC';
  };

  const setTableSort = (orderBy, orderType) => {
    setQueryFilters((prev) => ({
      ...prev,
      orderBy,
      orderType: setOrderType(orderBy, orderType)
    }));
  };

  const setTablePages = (page, pageSize) => {
    setQueryFilters((prev) => ({
      ...prev,
      page,
      pageSize
    }));
  };

  const updateQueryFilters = (key, value) => {
    setQueryFilters((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const exportCheckins = () => {
    modalContext.openModal({
      class: 'primary',
      paperStyle: { maxWidth: '70%' },
      title: 'Select export fields',
      content: (
        <Grid container style={{ width: '60%' }}>
          {checkinsCSVFields.map(({ label }, i) => (
            <Grid key={i}>
              <CSVCheckbox
                label={label}
                isCheckedDefault={csvFields.indexOf(label) > -1}
                onChange={() => {
                  const fields = csvFields;
                  if (fields.indexOf(label) === -1) fields.push(label);
                  else fields.splice(fields.indexOf(label), 1);
                  setCsvFields([...fields]);
                }}
              />
            </Grid>
          ))}
        </Grid>
      ),
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            modalContext.setModalLoading(true);
            axios
              .post(
                `${process.env.REACT_APP_SERVER_URL}/profiles/download-checkins-csv`,
                {
                  ...queryFilters,
                  page: null,
                  pageSize: null,
                  csvFields
                },
                { headers: { Authorization: `Bearer ${authToken}` } }
              )
              .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `checkins_export${Date.now()}.csv`);
                document.body.appendChild(link);
                link.click();
                modalContext.closeModal();
                modalContext.setModalLoading(false);
                setCsvFields(initialCsvFields);
              })
              .catch((error) => {
                modalContext.setModalLoading(false);
                modalContext.closeModal();
                console.log(error);
                window.alert('exporting error');
              });
          }}
        >
          <TranslateTextComponent>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const onCheckInStatusChange = ({ user, checkInStatus }) => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>change-check-in-status-title</TranslateTextComponent>,
      text: (
        <TranslateTextComponent capitalize vars={{ checkInStatus: translate(checkInStatus?.toLowerCase()?.replace(/_/g, '-'), language) }}>
          change-check-in-status-description
        </TranslateTextComponent>
      ),
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            modalContext.setModalLoading(true);
            saveCheckin({
              variables: {
                profileUUID: user?.profile?.uuid,
                roomUUID: user.roomNumber,
                checkinUUID: user?.profile?.checkins?.[0]?.uuid || user?.uuid,
                checkinInput: {
                  checkinDate: moment(user.checkinDate).format('YYYY-MM-DD'),
                  checkoutDate: moment(user.checkoutDate).format('YYYY-MM-DD'),
                  staffPhoneNumber: user.staffPhoneNumber,
                  status: checkInStatus
                }
              }
            })
              .then(() => {
                modalContext.setModalLoading(false);
                modalContext.openModal({
                  class: 'success',
                  title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
                  text: <TranslateTextComponent capitalize>check-in-status-changed-successfully</TranslateTextComponent>
                });
              })
              .catch((err) => {
                modalContext.setModalLoading(false);
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  return (
    <PageLayout>
      <CustomFilters
        filters={[
          { type: 'searchbar', onChange: ({ target: { value } }) => handleSearchChange(value), style: { flex: 1 }, value: queryFilters?.keyword },
          {
            type: 'date',
            label: 'check-in',
            value: queryFilters?.checkinDate,
            onChange: (value) => updateQueryFilters('checkinDate', value),
            style: { flex: 1 }
          },
          {
            type: 'date',
            label: 'check-out',
            value: queryFilters?.checkoutDate,
            onChange: (value) => updateQueryFilters('checkoutDate', value),
            style: { flex: 1 }
          },
          {
            type: 'guest-room-searchbar',
            label: 'guest-room',
            value: queryFilters?.roomNumber,
            onChange: (text) => updateQueryFilters('roomNumber', text),
            onItemClick: (room) => {
              updateQueryFilters('roomNumber', room?.number);
            },
            resetRoomKeyword: JSON.stringify(initialFilters) === JSON.stringify(queryFilters),
            style: { flex: 1 }
          },
          {
            type: 'reset-filter',
            style: { flex: 0.3 },
            onClearFilter: () => setQueryFilters(initialFilters)
          }
        ]}
      />
      <CommonTable
        isLoading={false}
        headerCellStyle={{ padding: '20px 0px' }}
        isPaginationEnabled
        items={getCheckins.data?.getCheckins?.data ?? []}
        orderBy={queryFilters.orderBy}
        orderType={queryFilters.orderType.toLocaleLowerCase()}
        page={queryFilters.page}
        pageSize={queryFilters.pageSize}
        setTableSort={setTableSort}
        setTablePages={setTablePages}
        columns={checkInColumns({
          changeCheckInStatus: ({ user, checkInStatus }) => {
            onCheckInStatusChange({ user, checkInStatus });
          },
          openDocument: (item) => {
            setModalData(item);
            setShowDocument(true);
          },
          openCheckInModal: (user) => {
            setModalData(user);
            setProfileUUID(user?.profile?.uuid);
          }
        })}
        totalRows={getCheckins.data?.getCheckins?.totalCount ?? 0}
        rowCellStyle={{ paddingLeft: 0 }}
        tableHeader={
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#EDFAFF',
              minHeight: 28,
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
            }}
          >
            <TranslateTextComponent capitalize>users-tot</TranslateTextComponent>: {getCheckins.data?.getCheckins?.totalCount ?? 0}
          </Typography>
        }
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          disabled={!getCheckins.data?.getCheckins?.totalCount}
          variant="contained"
          color="primary"
          style={{ margin: '30px 10px 20px 0px', minWidth: 325, alignSelf: 'flex-end', fontSize: 20, fontWeight: 500, color: 'white' }}
          onClick={exportCheckins}
        >
          <TranslateTextComponent uppercase>export</TranslateTextComponent>
        </Button>
      </div>
      <CheckInModal
        data={modalData}
        documentImages={documentImages}
        profileUUID={profileUUID}
        onClose={() => {
          setProfileUUID(null);
          setDocumentImages([]);
          setModalData(null);
        }}
      />
      <ViewPhoto
        showPhoto={showDocument}
        photos={documentImages ?? []}
        hide={() => {
          setShowDocument(false);
          setDocumentImages([]);
          setModalData(null);
        }}
      />
    </PageLayout>
  );
};

export default CheckInList;
