import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import RotateLeft from '@material-ui/icons/RotateLeft';
import RotateRight from '@material-ui/icons/RotateRight';
import style from '../../../styles/roomEditor/table/style';
import Chairs from '../../shared/Chairs';

const TABLE_UNIT_PIXELS = 50;

const getTableSizes = (table) => {
  const sizes = {
    width: 1,
    height: 1
  };

  const capacity = table.capacity < 2 ? 2 : table.capacity;

  if (table.shape === 'rectangle') {
    sizes.width = TABLE_UNIT_PIXELS * capacity * 0.4;
    sizes.height = TABLE_UNIT_PIXELS;
  } else {
    sizes.width = TABLE_UNIT_PIXELS * capacity * 0.5;
    sizes.height = TABLE_UNIT_PIXELS * capacity * 0.5;
  }
  return sizes;
};

const Table = ({ table, updateTable }) => {
  const [rotation, setRotation] = useState(table.rotate);
  const [hover, setHover] = useState(false);
  const classes = style({ capacity: table.capacity, rotation, hover });

  const onRotateLeftButton = () => {
    let newTable;
    if (rotation > -360) {
      setRotation(rotation - 45);
      newTable = { ...table, rotate: rotation - 45 };
    } else {
      setRotation(0);
      newTable = { ...table, rotate: 0 };
    }
    updateTable(newTable);
  };

  const onRotateRightButton = () => {
    let newTable;
    if (rotation < 360) {
      setRotation(rotation + 45);
      newTable = { ...table, rotate: rotation + 45 };
    } else {
      setRotation(0);
      newTable = { ...table, rotate: rotation + 45 };
    }
    updateTable(newTable);
  };

  return (
    <Box
      style={{ ...getTableSizes(table), position: 'relative' }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onFocus={() => {}}
    >
      <Chairs isInEditor table={table} rotate={rotation} />
      <Box className={classes.tableDescription}>
        <Typography style={{ fontWeight: 'bold', width: '100%' }}>{table.name || table.number}</Typography>
        <Typography style={{ width: '100%' }}>{table.capacity} pax</Typography>
      </Box>
      <Box className={classes.rotateButtonContainer}>
        <button type="button" className={classes.rotateLeftButton} onClick={onRotateLeftButton}>
          <RotateLeft />
        </button>
        <button type="button" className={classes.rotateRightButton} onClick={onRotateRightButton}>
          <RotateRight />
        </button>
      </Box>
      <Box className={classes.container}>
        <Box className={[classes.table, classes[table.shape]].join(' ')} />
      </Box>
    </Box>
  );
};

export default Table;
