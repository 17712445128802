import React from 'react';
import Layout from '../components/shared/Layout';
import Order from '../components/order/Order';

const OrderPage = ({ history, match }) => {
  return (
    <Layout history={history} section="add-modify-course">
      <Order fbTableUUID={match.params.uuid} history={history} />
    </Layout>
  );
};

export default OrderPage;
