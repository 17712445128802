import React from 'react';
import { Box } from '@material-ui/core';
import style from '../../../styles/viewTables/guests/style';
import GuestItem from './GuestItem/GuestItem';

const GuestsContainer = ({ guests, selectedGuestUUID, onGuestItemAddButtonClickHandler }) => {
  const classes = style();

  return (
    <Box className={classes.container} style={{ display: 'flex', justifyItems: 'flex-start', flexDirection: 'column', justifyContent: 'flex-start' }}>
      {guests.map((guest) => (
        <GuestItem key={guest.id} guest={guest} selectedGuestUUID={selectedGuestUUID} onAdd={onGuestItemAddButtonClickHandler} />
      ))}
    </Box>
  );
};

export default GuestsContainer;
