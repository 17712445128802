import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import styles from '../../styles/records/filters/style';
import { DataPicker, SearchBar, Dropdown } from '../../common-fe';
import { TranslateTextComponent, capitalizeFirstLetter, translate } from '../../translator';
import useCustomQuery from '../../hooks/useCustomQuery';
import { GET_FB_SERVICES } from '../../graphql/settings/service/queries';
import { AuthContext } from '../../contexts/AuthContext';
import { LanguageContext } from '../../contexts/LanguageContext';

const Filters = ({ selectedTab, date, onDateChange, service, apolloQuery, onServiceChange, onValueChange }) => {
  const { appOutletUUID } = useContext(AuthContext);
  const { language } = useContext(LanguageContext);
  const classes = styles();

  const getFbServices = useCustomQuery(GET_FB_SERVICES, {
    variables: { fbOutletUUID: appOutletUUID },
    onCompleted: (res) => {
      onServiceChange(res.getFbServices.data?.[0]?.uuid);
    }
  });

  return (
    <Box className={classes.container}>
      <Box className={classes.filterInputContainer}>
        <TranslateTextComponent capitalize>data</TranslateTextComponent>
        <DataPicker dataPickerStyle={{ minHeight: 40, maxWidth: 221 }} inputTextAlign="center" value={date} onChange={(res) => onDateChange(res)} />
      </Box>
      {selectedTab !== 'tables' && (
        <Box className={classes.filterInputContainer}>
          <TranslateTextComponent capitalize>service</TranslateTextComponent>
          <Dropdown
            items={getFbServices.data?.getFbServices?.data?.map((service) => ({ label: service.name, value: service.uuid })) ?? []}
            value={service}
            onChange={({ target: { value } }) => onServiceChange(value)}
          />
        </Box>
      )}
      <Box>
        <SearchBar
          apolloQuery={apolloQuery}
          placeholder={capitalizeFirstLetter(translate('search', language))}
          style={{ height: 40, maxWidth: 482, width: '100%' }}
          onChange={onValueChange}
        />
      </Box>
    </Box>
  );
};

export default Filters;
