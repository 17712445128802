import React, { useState, createContext } from 'react';

import { ReactComponent as ItFlag } from '../assets/images/flags/it_flag.svg';
import { ReactComponent as EnFlag } from '../assets/images/flags/en_flag.svg';

const storageLanguage = localStorage.getItem('language');

export const AVAILABLE_LANGUAGES = ['EN', 'IT'];

const flagStyle = { width: 20 };
export const languageConfig = {
  EN: {
    languageLabel: 'EN',
    icon: <EnFlag style={flagStyle} />
  },
  IT: {
    languageLabel: 'IT',
    icon: <ItFlag style={flagStyle} />
  }
};

const DEFAULT_LANGUAGE = storageLanguage || AVAILABLE_LANGUAGES[0];

export const LanguageContext = createContext();

const initialState = DEFAULT_LANGUAGE;

export const LanguageProvider = ({ children }) => {
  const [language, setLang] = useState(initialState);

  const setLanguage = (lang = DEFAULT_LANGUAGE) => {
    setLang(lang);
    localStorage.setItem('language', lang);
  };

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const LanguageConsumer = LanguageContext.Consumer;

export const withLanguageContext = (Component) => (props) => (
  <LanguageConsumer>{(providerProps) => <Component {...props} languageContextProps={providerProps} />}</LanguageConsumer>
);
