import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Button } from '@material-ui/core';
import Spinner from 'react-loader-spinner';
import { TranslateTextComponent } from '../../translator';
import { greenButton, redButton, primaryColor } from '../../styles/theme';

const Container = styled.div`
  display: flex;
  justify-content: ${({ position }) => (position === 'right' ? 'flex-end' : 'flex-start')};
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
`;

const Buttons = ({ buttons, position, containerStyle }) => {
  const getButton = ({ button, index, withMarginRight }) => {
    switch (button.type) {
      case 'confirm':
        return (
          <Button
            key={index}
            style={{
              borderRadius: 9,
              padding: '4px 17px',
              fontSize: 15,
              fontWeight: 600,
              marginRight: withMarginRight ? 15 : 0,
              backgroundColor: greenButton,
              color: 'white',
              textTransform: 'capitalize',
              ...button.style
            }}
            disabled={button.disabled}
            type={button.buttonType}
            onClick={button.onClick}
          >
            {button.isLoading ? (
              <Spinner
                type="TailSpin"
                color={button.spinnerColor || '#00BFFF'}
                height={26}
                width={26}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ...button.spinnerStyle }}
              />
            ) : (
              <TranslateTextComponent>{button.label || 'confirm'}</TranslateTextComponent>
            )}
          </Button>
        );
      case 'cancel':
        return (
          <Button
            key={index}
            style={{
              borderRadius: 9,
              padding: '4px 17px',
              fontSize: 15,
              fontWeight: 600,
              marginRight: withMarginRight ? 15 : 0,
              backgroundColor: redButton,
              color: 'white',
              textTransform: 'capitalize',
              border: `1px solid ${redButton}`,
              ...button.style
            }}
            disabled={button.disabled}
            type={button.buttonType}
            onClick={button.onClick}
          >
            {button.isLoading ? (
              <Spinner
                type="TailSpin"
                color={button.spinnerColor || '#00BFFF'}
                height={26}
                width={26}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ...button.spinnerStyle }}
              />
            ) : (
              <TranslateTextComponent>{button.label || 'cancel'}</TranslateTextComponent>
            )}
          </Button>
        );
      case 'preview':
        return (
          <Button
            key={index}
            style={{
              borderRadius: 9,
              padding: '4px 17px',
              fontSize: 15,
              fontWeight: 600,
              marginRight: withMarginRight ? 15 : 0,
              backgroundColor: primaryColor,
              color: 'white',
              textTransform: 'capitalize',
              ...button.style
            }}
            disabled={button.disabled}
            type={button.buttonType}
            onClick={button.onClick}
          >
            {button.isLoading ? (
              <Spinner
                type="TailSpin"
                color={button.spinnerColor || '#00BFFF'}
                height={26}
                width={26}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ...button.spinnerStyle }}
              />
            ) : (
              <TranslateTextComponent>{button.label || 'preview'}</TranslateTextComponent>
            )}
          </Button>
        );
      default:
        return (
          <Button
            key={index}
            style={{
              borderRadius: 9,
              padding: '4px 17px',
              fontSize: 15,
              fontWeight: 600,
              marginRight: withMarginRight ? 15 : 0,
              backgroundColor: greenButton,
              color: 'white',
              textTransform: 'capitalize',
              ...button.style
            }}
            disabled={button.disabled}
            type={button.buttonType}
            onClick={button.onClick}
          >
            {button.isLoading ? (
              <Spinner
                type="TailSpin"
                color={button.spinnerColor || '#00BFFF'}
                height={26}
                width={26}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ...button.spinnerStyle }}
              />
            ) : (
              <TranslateTextComponent>{button.label || 'confirm'}</TranslateTextComponent>
            )}
          </Button>
        );
    }
  };

  return (
    <Container position={position} style={containerStyle}>
      {buttons
        .sort((a, b) => a.position - b.position)
        .map((button, index) => {
          if (button.custom) {
            return button.custom;
          }
          if (button.type) {
            return getButton({ button, index, withMarginRight: index < buttons.length - 1 });
          }
          return (
            <Button
              key={index}
              style={{
                borderRadius: 9,
                padding: '4px 17px',
                fontSize: 15,
                fontWeight: 600,
                marginRight: index < buttons.length - 1 ? 15 : 0,
                textTransform: 'capitalize',
                ...button.style
              }}
              disabled={button.disabled}
              type={button.buttonType}
              onClick={button.onClick}
            >
              {button.isLoading ? (
                <Spinner
                  type="TailSpin"
                  color={button.spinnerColor || '#00BFFF'}
                  height={26}
                  width={26}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ...button.spinnerStyle }}
                />
              ) : (
                <TranslateTextComponent capitalize>{button.label}</TranslateTextComponent>
              )}
            </Button>
          );
        })}
    </Container>
  );
};

Buttons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      /** One of "confirm", "cancel" or "preview" */
      type: PropTypes.oneOf(['confirm', 'cancel', 'preview']),
      /** One of "button", "submit", "reset" */
      buttonType: PropTypes.oneOf(['button', 'submit', 'reset']),
      /** If you need a custom button */
      custom: PropTypes.element,
      label: PropTypes.string,
      style: PropTypes.object,
      disabled: PropTypes.bool,
      /** A number starting from 0 (zero) */
      position: PropTypes.number,
      isLoading: PropTypes.bool,
      spinnerStyle: PropTypes.object,
      spinnerColor: PropTypes.string,
      onClick: PropTypes.func
    })
  ).isRequired,
  /** One of "left" or "right" */
  position: PropTypes.oneOf(['left', 'right'])
};

Buttons.defaultProps = { position: 'right' };

export default Buttons;
