import React from 'react';
// import * as Icons from '@material-ui/icons/';
import Icon from '@material-ui/core/Icon';

const MaterialIcon = ({ iconName, style, onClick }) => {
  // // get all indexes of uppercase letter in a word expect the first char charAt(0)
  // const indexesOfUpperCase = [];

  // // get the name of the material icon
  // iconName = Icons[iconName] && Icons[iconName].displayName;

  // if (!iconName) {
  //   return null;
  // }

  // for (let i = 0; i < iconName.length; i++) {
  //   if (iconName.charAt(i) === iconName.charAt(i).toUpperCase() && isNaN(iconName.charAt(i)) && i !== 0) {
  //     indexesOfUpperCase.push(i);
  //   }
  // }

  // // insert an _ before every upper case letter
  // for (let i = 0; i < indexesOfUpperCase.length; i++) {
  //   iconName = [iconName.slice(0, indexesOfUpperCase[i] + i), '_', iconName.slice(indexesOfUpperCase[i] + i)].join('');
  // }

  // // insert an _ before the first number in a string
  // const indexed = iconName.indexOf(iconName.match(/\d/));
  // if (indexed > -1) {
  //   iconName = [iconName.slice(0, indexed), '_', iconName.slice(indexed)].join('');
  // }

  // iconName = iconName.toLowerCase();

  return (
    <Icon style={{ ...style }} onClick={onClick}>
      {iconName.toLowerCase()}
    </Icon>
  );
};

export default MaterialIcon;
