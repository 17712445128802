import React, { useState, useEffect } from 'react';
import orderBy from 'lodash/orderBy';
import { Box } from '@material-ui/core';
import style from '../../styles/shared/chairs';
import ChairItem from './ChairItem';
import getChairs from '../../utils/getChairs';

const Chairs = ({ isInEditor = false, table, rotate, onChairClick, isGuestNameShowed }) => {
  const [chairs, setChairs] = useState({ top: 0, bottom: 0, right: 0, left: 0 });
  const [chairSize, setChairSize] = useState(20);
  const classes = style({ chairSize, rotate });
  let chairsIdCounter = 0;
  useEffect(() => {
    const calculateChairSize = () => {
      setChairSize(table.w * 9);
    };

    const getChairsDisposition = () => {
      const disposedChairs = getChairs(table);
      setChairs({ ...disposedChairs });
    };

    calculateChairSize();
    getChairsDisposition();
  }, [table]);

  const renderChairs = (n, borderPosition) => {
    const sortedChairs = !isInEditor && orderBy(table.fbChairs, ['number'], ['asc']);
    const renderedChairs = [];
    const verticalMargin = '-35px 0';
    const horizontalMargin = '0 -35px';

    for (let i = 0; i < n; i++) {
      renderedChairs.push(
        <ChairItem
          key={i}
          isGuestNameShowed={isGuestNameShowed}
          isInEditor={isInEditor}
          tableBusy={!isInEditor && table.fbReservationTables.length > 0}
          chair={!isInEditor && sortedChairs[chairsIdCounter]}
          chairSize={chairSize}
          rotate={rotate}
          borderPosition={borderPosition}
          margin={borderPosition === 'top' || borderPosition === 'bottom' ? verticalMargin : horizontalMargin}
          onChairClick={onChairClick}
        />
      );
      chairsIdCounter++;
    }

    return renderedChairs;
  };

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.chairHorizontal}>{renderChairs(chairs.top, 'top')}</Box>
      <Box className={classes.chairVertical} alignItems="flex-end">
        {renderChairs(chairs.right, 'right')}
      </Box>
      <Box className={[classes.chairHorizontal, classes.rowReverse].join(' ')} alignItems="flex-end">
        {renderChairs(chairs.bottom, 'bottom')}
      </Box>
      <Box className={[classes.chairVertical, classes.columnReverse].join(' ')}>{renderChairs(chairs.left, 'left')}</Box>
    </Box>
  );
};

export default Chairs;
