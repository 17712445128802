import React from 'react';
import PropTypes from 'prop-types';
import { Tabs as MUITabs } from '@material-ui/core';
import TabItem from './TabItem';
import styles from '../../../styles/records/tabs/style';

const Tabs = ({ tabValueKey, tabs, currentTab, variant, style, centered, tabItemsStyle, changeTab }) => {
  const classes = styles();

  return (
    <MUITabs className={classes.container} centered={centered} variant={variant} value={currentTab} style={style}>
      {tabs.map((tab, index) => (
        <TabItem
          key={index}
          className={classes.tabItem}
          label={tab.label}
          value={tabValueKey ? tab[tabValueKey] : tab.label}
          notificationCounter={tab.notificationCounter}
          tabItemsStyle={tabItemsStyle}
          style={{ flex: 1, ...tab.style }}
          currentTab={currentTab}
          onClick={() => changeTab(tabValueKey ? tab[tabValueKey] : tab.label)}
        />
      ))}
    </MUITabs>
  );
};

Tabs.propTypes = {
  tabValueKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      notificationCounter: PropTypes.number,
      style: PropTypes.object
    })
  ).isRequired,
  currentTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  centered: PropTypes.bool,
  variant: PropTypes.oneOf(['fullWidth', 'scrollable', 'standard']),
  tabItemsStyle: PropTypes.shape({}),
  changeTab: PropTypes.func.isRequired
};

Tabs.defaultProps = {
  tabValueKey: null,
  variant: 'fullWidth',
  tabItemsStyle: {},
  centered: false
};

export default Tabs;
