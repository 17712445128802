/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import { FbBillFragment } from '../cash/queries';

const FbClosureFragment = `
      id
      uuid
      date
      datetime
      documentNumber
      totalPaxAmount
      totalAmount
      totalCancellationAmount
      totalCancellationNumber
      totalCashAmount
      totalCashNumber
      totalCardAmount
      totalCardNumber
      totalCardVisaAmount
      totalCardVisaNumber
      totalCardMaestroAmount
      totalCardMaestroNumber
      totalCardAmexAmount
      totalCardAmexNumber
      totalCardMastercardAmount
      totalCardMastercardNumber
      totalBankTransferAmount
      totalBankTransferNumber
      totalBankCheckAmount
      totalBankCheckNumber
      totalCustomerTypeAmount
      totalCustomerTypeNumber
      totalPMAmount
      totalPMNumber
      totalRoomChargeAmount
      totalRoomChargeNumber
      totalInvoiceAmount
      totalInvoiceNumber
      totalTicketAmount
      totalTicketNumber`;

export const FbDailyClosureFragment = gql`
    fragment FbDailyClosureFragment on FbDailyClosuresDto {
      ${FbClosureFragment}
    }
`;
export const FbAccountingClosureFragment = gql`
    fragment FbAccountingClosureFragment on FbAccountingClosuresDto {
      ${FbClosureFragment}
    }
`;

export const CONFIRM_FB_BILL_PAYMENT = gql`
  mutation confirmFbBillPayment($fbBillUUID: String!, $isConfirmed: Boolean!) {
    confirmFbBillPayment(fbBillUUID: $fbBillUUID, isConfirmed: $isConfirmed) {
      ...FbBillFragment
      fbPaymentMethod {
        id
        uuid
        code
      }
    }
  }
  ${FbBillFragment}
`;

export const CREATE_PAY_MASTER_BILL = gql`
  mutation createPayMasterBill($fbBillUUIDs: [String!]!) {
    createPayMasterBill(fbBillUUIDs: $fbBillUUIDs) {
      ...FbBillFragment
      fbPaymentMethod {
        id
        uuid
        code
      }
    }
  }
  ${FbBillFragment}
`;

export const REGISTER_FB_CLOSURE = gql`
  mutation registerFbClosures($fbOutletUUID: String!, $date: String!, $zRepNumber: String!, $fiscalPrinterAmount: Float!) {
    registerFbClosures(fbOutletUUID: $fbOutletUUID, date: $date, zRepNumber: $zRepNumber, fiscalPrinterAmount: $fiscalPrinterAmount) {
      dailyClosure {
        ...FbDailyClosureFragment
      }
      accountingClosure {
        ...FbAccountingClosureFragment
      }
    }
  }
  ${FbDailyClosureFragment}
  ${FbAccountingClosureFragment}
`;
