/* eslint-disable prefer-destructuring */
import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core/';
import { ReactComponent as ChairImg } from '../../assets/images/order/chair.svg';
import { ReactComponent as HalfPortionImg } from '../../assets/images/order/halfPortion.svg';
import { ReactComponent as CenterpieceImg } from '../../assets/images/order/centerpiece.svg';
import styles from '../../styles/order/fbCourse';
import { primaryColor } from '../../styles/theme';

export const SharedBy = ({ item, sharedBy, withoutNotes = false }) => {
  const classes = styles();
  return (
    <Grid className={classes.sharedChairsContainer}>
      {!withoutNotes && item.notes !== '' && <span style={{ color: primaryColor, position: 'absolute', right: -5, top: '-6px' }}>*</span>}
      {sharedBy &&
        sharedBy.map((chariNumber, index) => {
          return (
            <Box key={index} className={classes.sharedChairsBox}>
              <ChairImg className={classes.chairImg} />
              <Typography className={classes.fbChairNumber}>{chariNumber}</Typography>
            </Box>
          );
        })}
    </Grid>
  );
};
export const ChairOrHalfPortion = ({ isHalfPortion, chair, item, withoutNotes = false, containerStyle, chairNumberStyle }) => {
  let gender = 'default';
  if (chair && chair.guest && chair.guest.gender) {
    gender = chair.guest.gender;
  } else if (chair && chair.gender) {
    gender = chair.gender;
  }
  const classes = styles({ gender });

  return (
    <Grid container direction="column" alignItems="center" style={{ position: 'relative', ...containerStyle }}>
      {!withoutNotes && item.notes !== '' && <span style={{ color: primaryColor, position: 'absolute', left: 15, top: '-10px' }}>*</span>}
      {isHalfPortion ? <HalfPortionImg className={classes.chairImg} /> : <ChairImg className={classes.chairImg} />}
      <Typography className={classes.fbChairNumber} style={chairNumberStyle}>
        {chair && chair.number}
      </Typography>
    </Grid>
  );
};

export const Centerpiece = ({ item, withoutNotes }) => {
  const classes = styles();

  return (
    <Grid container direction="column" alignItems="center" style={{ position: 'relative' }}>
      {!withoutNotes && item.notes && <span style={{ color: primaryColor, position: 'absolute', left: 32, top: '-6px' }}>*</span>}
      <CenterpieceImg className={classes.centerpieceImg} />
    </Grid>
  );
};
