import React from 'react';
import { Box } from '@material-ui/core';
import typy from 'typy';
import styles from '../../../styles/viewTables/floatingGuests/floatingGuestItem/style';
import { TranslateTextComponent } from '../../../translator';

const FloatingGuestItem = ({ guest, onGuestItemClick }) => {
  const classes = styles();

  return (
    <Box className={classes.container} onClick={() => onGuestItemClick(guest.uuid)}>
      <Box className={classes.photoArea}>
        <img src={typy(guest, 'photoUrl').safeObject || 'https://via.placeholder.com/77x77/e1e1e1/FFFFFF?text=No%20Picture'} alt="Guest profile" />
      </Box>
      <Box className={classes.nameArea}>{`${guest.bookedBy.firstname} ${guest.bookedBy.lastname}`}</Box>
      <Box className={classes.reservationTimeArea}>
        <TranslateTextComponent capitalize>time</TranslateTextComponent>: {guest.reservationTime.substring(0, guest.reservationTime.lastIndexOf(':'))}
      </Box>
      <Box className={classes.roomArea}>
        <TranslateTextComponent capitalize>room</TranslateTextComponent>: {guest.guestRoom}
      </Box>
      <Box className={classes.personsNumberArea}>
        <TranslateTextComponent capitalize>n-persons</TranslateTextComponent>: {guest.fbGuestList.length}
      </Box>
    </Box>
  );
};

export default FloatingGuestItem;
