export default (table) => {
  let chairs = { top: 0, bottom: 0, left: 0, right: 0 };

  if (table.shape === 'rectangle') {
    chairs = calcRectangle(table);
  } else {
    chairs = calcSquare(table);
  }
  return chairs;
};

const calcRectangle = (table) => {
  const vertical = table.h > table.w;

  const chairs = { top: 0, bottom: 0, left: 0, right: 0 };
  let currentSeat = vertical ? 'left' : 'top';

  if (table.tabletop) {
    if (table.capacity >= 2) {
      if (table.capacity % 2 === 0) {
        chairs.left++;
        chairs.right++;
      } else {
        chairs.left++;
      }
    } else {
      chairs.left++;
    }
  }

  while (areSeatsAdded(chairs, table.capacity)) {
    switch (currentSeat) {
      case 'top':
        chairs.top++;
        currentSeat = 'bottom';
        break;
      case 'bottom':
        chairs.bottom++;
        currentSeat = 'top';
        break;
      default:
        chairs.top++;
        currentSeat = 'bottom';
        break;
    }
  }
  return chairs;
};

const calcSquare = ({ capacity }) => {
  const chairs = { top: 0, bottom: 0, left: 0, right: 0 };
  let currentSeat = 'top';

  while (areSeatsAdded(chairs, capacity)) {
    switch (currentSeat) {
      case 'top':
        chairs.top++;
        currentSeat = 'bottom';
        break;
      case 'bottom':
        chairs.bottom++;
        currentSeat = 'left';
        break;
      case 'left':
        chairs.left++;
        currentSeat = 'right';
        break;
      default:
        chairs.right++;
        currentSeat = 'top';
        break;
    }
  }
  return chairs;
};

const areSeatsAdded = (chairs, capacity) => {
  const result = chairs.top + chairs.bottom + chairs.left + chairs.right;
  return result < capacity;
};
