import { makeStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    display: 'grid',
    gridTemplateAreas: '"left right"',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: '1fr',
    height: '100vh'
  }
};

export default makeStyles(styles);
