import React, { useContext, useState } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { AuthContext } from '../../contexts/AuthContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import { GET_FB_OUTLETS } from '../../graphql/outlets/queries';
import { GET_FB_SERVICE_REVENUES } from '../../graphql/revenues/queries';
import { CommonTable } from '../../common-fe/src';
import CustomFilters from '../shared/CustomFilters/CustomFilters';
import PageLayout from '../shared/PageLayout';
import revenuesColumns from './revenuesColumns';
import { capitalizeFirstLetter, translate, TranslateTextComponent } from '../../translator';
import useCustomQuery from '../../hooks/useCustomQuery';
import { ROUTE_EDIT_DAILY_REPORT, ROUTE_SAVE_DAILY_REPORT } from '../../router/routes';
import { ModalContext } from '../../contexts/ModalContext';
import modalStyle from '../../styles/shared/modalStyle';
import { DELETE_FB_SERVICE_REVENUE } from '../../graphql/revenues/mutations';
import { getApolloErrors } from '../../apollo/ApolloProvider';
import Buttons from '../shared/Buttons';

const DailyRevenues = () => {
  const history = useHistory();
  const { appOutletUUID } = useContext(AuthContext);
  const { language } = useContext(LanguageContext);
  const modalContext = useContext(ModalContext);
  const modalClasses = modalStyle();

  // FILTERS
  const [queryFilters, setQueryFilters] = useState({
    fbOutletUUID: appOutletUUID,
    date: null,
    orderBy: 'date',
    orderType: 'DESC',
    page: 1,
    pageSize: 10
  });

  // QUERIES
  const getFbOutlets = useCustomQuery(GET_FB_OUTLETS);
  const getFbServiceRevenues = useCustomQuery(GET_FB_SERVICE_REVENUES, { variables: queryFilters });

  // MUTATION
  const [deleteFbServiceRevenue] = useMutation(DELETE_FB_SERVICE_REVENUE);

  const onTablePageChanges = ({ page, pageSize }) => {
    setQueryFilters((prev) => ({ ...prev, page, pageSize }));
  };

  const onTableSort = (column) => {
    const orderType = queryFilters.orderType === 'ASC' ? 'DESC' : 'ASC';
    setQueryFilters((prev) => ({ ...prev, orderType, orderBy: column }));
  };

  const onDeleteDailyRevenue = (uuid) => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>delete-reservation-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>delete-reservation-text</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            modalContext.setModalLoading(true);
            deleteFbServiceRevenue({ variables: { fbServiceRevenueUUID: uuid } })
              .then(() => {
                modalContext.setModalLoading(false);
                getFbServiceRevenues.refetch();
                modalContext.openModal({
                  class: 'success',
                  title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
                  text: <TranslateTextComponent capitalize>success-modal-text</TranslateTextComponent>
                });
              })
              .catch((err) => {
                modalContext.setModalLoading(false);
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  return (
    <PageLayout>
      <CustomFilters
        labelsStyles={{ fontSize: 14 }}
        filters={[
          {
            type: 'dropdown',
            label: 'outlet',
            options: getFbOutlets.data?.getFbOutlets?.data
              ?.map((outlet) => ({ text: outlet.name, value: outlet.uuid }))
              .concat({ text: capitalizeFirstLetter(translate('all', language)), value: null }),
            value: queryFilters.fbOutletUUID,
            onChange: ({ target: { value } }) => {
              setQueryFilters((prev) => ({ ...prev, fbOutletUUID: value, page: 1, pageSize: 10 }));
            }
          },
          {
            type: 'date',
            label: 'date',
            value: queryFilters?.date,
            onChange: (value) => setQueryFilters((prev) => ({ ...prev, date: value, page: 1, pageSize: 10 })),
            style: { flex: 1 }
          }
        ]}
      />
      <CommonTable
        isLoading={getFbServiceRevenues.loading}
        headerCellStyle={{ padding: '20px 0px' }}
        isPaginationEnabled
        items={getFbServiceRevenues.data?.getFbServiceRevenues?.data ?? []}
        orderBy={queryFilters.orderBy}
        orderType={queryFilters.orderType.toLocaleLowerCase()}
        page={queryFilters.page}
        pageSize={queryFilters.pageSize}
        setTablePages={(page, pageSize) => onTablePageChanges({ page, pageSize })}
        setTableSort={onTableSort}
        rowsPerPageOptions={[5, 10, 25, 200]}
        columns={revenuesColumns({
          editFbServiceRevenue: (uuid) => history.push(`${ROUTE_EDIT_DAILY_REPORT}/${uuid}`),
          deleteFbServiceRevenue: onDeleteDailyRevenue
        })}
        totalRows={getFbServiceRevenues?.data?.getFbServiceRevenues?.totalCount}
        rowCellStyle={{ paddingLeft: 0 }}
        tableHeader={
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#EDFAFF',
              minHeight: 28,
              divShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
            }}
          >
            <TranslateTextComponent capitalize>reports-tot</TranslateTextComponent>: {getFbServiceRevenues?.data?.getFbServiceRevenues?.totalCount}
          </span>
        }
      />

      <Buttons
        containerStyle={{ marginBottom: 20 }}
        buttons={[
          {
            custom: (
              <Button
                key={0}
                variant="contained"
                color="primary"
                style={{
                  color: 'white',
                  marginRight: 10,
                  borderRadius: 9,
                  minWidth: 325,
                  minHeight: 50,
                  fontSize: 20,
                  fontWeight: 'bold',
                  textTransform: 'uppercase'
                }}
                onClick={() => history.push(ROUTE_SAVE_DAILY_REPORT)}
              >
                <TranslateTextComponent capitalize>new-report</TranslateTextComponent>
              </Button>
            )
          }
        ]}
      />
    </PageLayout>
  );
};

export default DailyRevenues;
