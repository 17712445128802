import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from '../theme';

const styles = {
  pageContainer: () => ({
    background: 'white',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignContent: 'center'
  }),
  appName: () => ({
    backgroundColor: primaryColor,
    borderRadius: 4,
    fontSize: 84,
    color: 'white',
    fontWeight: 'bold',
    marginLeft: 10,
    marginRight: 10,
    minHeight: 110,
    width: 'auto',
    padding: '0px 10px',
    textAlign: 'center'
  }),
  loginText: () => ({
    color: '#373A3C',
    fontSize: 31,
    fontWeight: 'medium',
    marginTop: 43
  }),
  containerStyle: () => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }),
  noteTextField: () => ({
    fontSize: 14,
    height: 70,
    width: 400,
    color: '#55595C',
    border: '1.5px solid',
    borderColor: primaryColor,
    paddingLeft: 10,
    borderRadius: 4
  }),
  checkTitle: () => ({
    color: '#40403F',
    fontSize: 24,
    fontWeight: 300
  }),

  loginButton: () => ({
    width: 400,
    height: 50,
    fontSize: 24,
    fontWeight: 'bold',
    color: 'white'
  })
};
export default makeStyles(styles);
