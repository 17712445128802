import { makeStyles } from '@material-ui/core/styles';
import { invertedPrimary } from '../theme';

const styles = {
  tabs: {
    backgroundColor: invertedPrimary,
    maxWidth: 82,
    minWidth: 82,
    paddingRight: 10
  },
  indicator: {
    display: 'block',
    backgroundColor: 'white',
    borderRadius: 5,
    width: 5,
    height: '70px !important',
    marginTop: 10
  }
};

export default makeStyles(styles);
