import React from 'react';
import { styled } from '@material-ui/core/styles';

const StyledBackdrop = styled('div')(({ filter, backgroundcolor }) => ({
  position: 'absolute',
  top: 0,
  height: '100%',
  width: '100%',
  filter: filter || 'blur(10px) opacity(0.8)',
  backgroundColor: backgroundcolor || 'white'
}));

const Backdrop = ({ onClick, filter, backgroundcolor }) => {
  return <StyledBackdrop onClick={onClick} filter={filter} backgroundcolor={backgroundcolor} />;
};

export default Backdrop;
