import React from 'react';
import DailyRevenueForm from '../../components/revenues/DailyRevenueForm';
import Layout from '../../components/shared/Layout';

const SaveDailyRevenuePage = ({ history }) => {
  return (
    <Layout history={history} section="save-daily-report">
      <DailyRevenueForm />
    </Layout>
  );
};

export default SaveDailyRevenuePage;
