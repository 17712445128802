/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import moment from 'moment';
import { TranslateTextComponent } from '../../../translator';
import { ReactComponent as BinIcon } from '../../../assets/images/orders/bin.svg';
import TableButton from '../../shared/TableButton';
import { primaryColor } from '../../../styles/theme';

const textTranslate = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

const guestProfilesColumns = ({ editProfile, deleteGuest, openCheckInModal }) => {
  return [
    {
      id: 'fullName',
      label: textTranslate('full-name'),
      isSortable: false,
      align: 'left',
      width: '10%',
      render: (item) => (item.fullName ? item.fullName : `${item.firstName} ${item.lastName}`.trim())
    },
    {
      id: 'room_number_cast',
      label: textTranslate('room-number'),
      isSortable: true,
      align: 'left',
      width: '10%',
      render: (item) => item.currentCheckin?.room?.number ?? '-'
    },
    {
      id: 'email',
      label: textTranslate('email'),
      isSortable: false,
      align: 'left',
      width: '15%',
      render: (item) => item.email
    },
    {
      id: 'telephone',
      label: textTranslate('telephone'),
      isSortable: false,
      align: 'left',
      width: '7%',
      render: (item) => item?.phone ?? '-'
    },
    {
      id: 'currentCheckin',
      label: textTranslate('stay'),
      isSortable: false,
      align: 'left',
      width: '10%',
      render: (item) => {
        if (!item.currentCheckin) return '-';
        return (
          moment(item.currentCheckin?.checkinDate).isValid() &&
          moment(item.currentCheckin?.checkoutDate).isValid() &&
          `${moment(item.currentCheckin?.checkinDate).format('DD/MM/YYYY')} - ${moment(item.currentCheckin?.checkoutDate).format('DD/MM/YYYY')}`
        );
      }
    },
    {
      id: 'local',
      label: textTranslate('local-adviser'),
      isSortable: false,
      align: 'left',
      width: '10%',
      render: (item) => item?.currentCheckin?.checkinPreferences?.localAdvisers?.map((local) => local).join(', ') ?? '-'
    },
    {
      id: 'currentCheckin',
      label: textTranslate('checkin'),
      isSortable: false,
      align: 'left',
      width: '10%',
      render: (item) => (
        <TableButton style={{ cursor: 'pointer' }} onClick={() => openCheckInModal(item)}>
          check-in
        </TableButton>
      )
    },
    {
      id: 'edit',
      disablePadding: false,
      label: textTranslate('edit'),
      width: '8%',
      isSortable: false,
      align: 'left',
      render: (item) => (
        <TableButton
          onClick={() => {
            editProfile(item.uuid);
          }}
        >
          edit
        </TableButton>
      )
    },
    {
      id: 'delete',
      disablePadding: false,
      label: textTranslate('delete'),
      isSortable: false,
      width: '5%',
      align: 'right',
      render: (item) => <BinIcon fill={primaryColor} style={{ cursor: 'pointer' }} onClick={() => deleteGuest({ guestUUID: item.uuid })} />
    }
  ];
};

export default guestProfilesColumns;
