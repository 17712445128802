/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { Button, IconButton, Grid, Typography } from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import * as moment from 'moment';
import { TranslateTextComponent } from '../../translator';
import { MaterialIcon } from '../../common-fe';

const getSegmentsColumns = ({ deleteSegment, editSegment }) => {
  return [
    {
      id: 'isActive',
      disablePadding: false,
      width: '5%',
      label: <TranslateTextComponent capitalize>state</TranslateTextComponent>,
      isSortable: true,
      render: (item) =>
        item.isActive ? (
          <TranslateTextComponent capitalize>opened</TranslateTextComponent>
        ) : (
          <TranslateTextComponent capitalize>closed</TranslateTextComponent>
        )
    },
    {
      id: 'name',
      disablePadding: false,
      width: '15%',
      label: <TranslateTextComponent capitalize>name</TranslateTextComponent>,
      isSortable: true,

      render: (item) => item.name
    },
    {
      id: 'period',
      disablePadding: false,
      width: '20%',
      label: <TranslateTextComponent capitalize>period</TranslateTextComponent>,
      isSortable: true,
      render: (item) =>
        moment(item.startDate).isValid() && moment(item.endDate).isValid()
          ? `${moment(item.startDate).format('DD/MM/YYYY')} - ${moment(item.endDate).format('DD/MM/YYYY')}`
          : `${item.startDate} - ${item.endDate}`
    },
    {
      id: 'service',
      disablePadding: false,
      width: '20%',
      label: <TranslateTextComponent capitalize>service</TranslateTextComponent>,
      isSortable: true,
      render: (item) => (
        <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
          {item.fbSegmentsServices.map((service, index) => (
            <Grid key={`service${index}`} display="flex" item xs={4} md={3} style={{ display: 'flex', alignItems: 'center', direction: 'row' }}>
              <MaterialIcon iconName={service.fbService.icon} /> &nbsp;
              <Typography>
                <TranslateTextComponent capitalize>{service.fbService.name}</TranslateTextComponent>
              </Typography>
            </Grid>
          ))}
        </Grid>
      )
    },
    {
      id: 'edit',
      disablePadding: false,
      width: '10%',
      label: <TranslateTextComponent capitalize>edit</TranslateTextComponent>,
      isSortable: false,

      render: (item) => (
        <Button color="primary" variant="contained" className="buttonTable" onClick={() => editSegment(item)}>
          <TranslateTextComponent>open</TranslateTextComponent>
        </Button>
      )
    },
    {
      id: 'delete',
      disablePadding: false,
      width: '10%',
      label: <TranslateTextComponent capitalize>delete-icon</TranslateTextComponent>,
      isSortable: false,

      render: (item) => (
        <IconButton className="buttonCircle" onClick={() => deleteSegment(item)}>
          <DeleteIcon />
        </IconButton>
      )
    }
  ];
};

export default getSegmentsColumns;
