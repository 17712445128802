import React, { useState, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Avatar, Box, Grid, ClickAwayListener } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import styles from '../../styles/header/headerStyle';
import HeaderMenuItems from './HeaderMenuItems';
import { AuthContext } from '../../contexts/AuthContext';
import { ReactComponent as ProfileIcon } from '../../assets/images/profiles-header.svg';
import { ReactComponent as CheckInIcon } from '../../assets/images/home.svg';
import { TranslateTextComponent } from '../../translator';
import { ROUTE_GUEST_PROFILES, ROUTE_CHECK_IN } from '../../router/routes';

const HOME_SVG = require('../../assets/images/home.svg');

const Header = ({ section, menuContent, languageSwitcher, iconLanguageSelected, languageSelected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLanguageSwitcherOpen, setLanguageSwitcherIsOpen] = useState(false);

  const { user, appOutletUUID, handleSetAppOutletUUID } = useContext(AuthContext);

  const history = useHistory();
  const classes = styles();

  const handleIsOpen = () => {
    setIsOpen((prevState) => !prevState);
    handleLanguageSwitcherClose();
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleLanguageSwitcherIsOpen = () => {
    setLanguageSwitcherIsOpen((prevState) => !prevState);
    handleClose();
  };

  const handleLanguageSwitcherClose = () => {
    setLanguageSwitcherIsOpen(false);
  };

  const getTitle = (titleSection) => {
    return (
      <Box className={classes.textTitle}>
        <Typography className={classes.sectionTypography}>{titleSection}</Typography>
      </Box>
    );
  };

  return (
    <Box>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Link to="/">
                <img alt="home" src={HOME_SVG} />
              </Link>
              {getTitle(section)}
            </Box>
            <Box style={{ display: 'flex' }}>
              <ClickAwayListener onClickAway={() => setLanguageSwitcherIsOpen(false)}>
                <Box className={classes.languageContainer} onClick={handleLanguageSwitcherIsOpen}>
                  <Grid
                    container
                    className={classes.userInfoContainer}
                    style={{ justifyContent: 'space-between', paddingLeft: 10, paddingRight: 10 }}
                  >
                    {iconLanguageSelected}
                    <Typography style={{ color: 'white', fontSize: 14 }}>{languageSelected}</Typography>
                    {isLanguageSwitcherOpen ? (
                      <KeyboardArrowUp className={classes.keyArrowStyle} />
                    ) : (
                      <KeyboardArrowDown className={classes.keyArrowStyle} />
                    )}
                  </Grid>
                  {isLanguageSwitcherOpen && <Box className={classes.languageItems}>{languageSwitcher}</Box>}
                </Box>
              </ClickAwayListener>
              <Box
                className={classes.userContainer}
                style={{ width: 'auto', padding: '0px 10px' }}
                onClick={() => history.push(ROUTE_GUEST_PROFILES)}
              >
                <ProfileIcon fill="white" style={{ width: 25, height: 25, marginRight: 5 }} />
                <TranslateTextComponent capitalize style={{ color: 'white' }}>
                  profiles
                </TranslateTextComponent>
              </Box>
              <Box className={classes.userContainer} style={{ width: 'auto', padding: '0px 10px' }} onClick={() => history.push(ROUTE_CHECK_IN)}>
                <CheckInIcon fill="white" style={{ width: 25, height: 25, marginRight: 5 }} />
                <TranslateTextComponent capitalize style={{ color: 'white' }}>
                  check-in
                </TranslateTextComponent>
              </Box>
              <HeaderMenuItems
                withBorderLeft
                width={250}
                value={appOutletUUID}
                items={
                  user?.outlets
                    ?.sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1))
                    ?.map((outlet) => ({
                      label: outlet.name,
                      value: outlet.uuid
                    })) ?? []
                }
                onChange={(outletUUID) => {
                  handleSetAppOutletUUID(outletUUID);
                  history.push('/');
                }}
              />
              <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                <Box className={classes.userContainer} onClick={handleIsOpen}>
                  <Grid
                    container
                    className={classes.userInfoContainer}
                    style={{ justifyContent: 'space-between', paddingLeft: 10, paddingRight: 10 }}
                  >
                    <Typography className={classes.nameText}>{user.username || 'name'}</Typography>
                    {isOpen ? <KeyboardArrowUp className={classes.keyArrowStyle} /> : <KeyboardArrowDown className={classes.keyArrowStyle} />}
                  </Grid>
                  <Avatar src={user.avatar} />
                  {isOpen && <Box className={classes.menuItems}>{menuContent}</Box>}
                </Box>
              </ClickAwayListener>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
export default Header;
