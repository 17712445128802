import { makeStyles } from '@material-ui/core/styles';
import { primaryColor, borderTheme } from '../theme';

const styles = {
  paperSearchBar: (props) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    border: borderTheme,
    boxShadow: 'none'
  }),
  iconSearchBar: (props) => ({
    color: 'white',
    backgroundColor: primaryColor,
    borderRadius: 0,
    padding: 8,

    borderTopRightRadius: 4,
    borderBottomRightRadius: 4
  }),
  resultsContainer: {
    position: 'absolute',
    top: 42,
    width: '100%',
    maxHeight: 250,
    backgroundColor: 'white',
    border: '1px solid rgb(0, 183, 255)',
    boxShadow: '2px 2px 2px #a0a0a0',
    borderRadius: 10,
    overflow: 'auto',
    zIndex: 5
  },
  resultItem: {
    cursor: 'pointer',
    width: '100%',
    backgroundColor: 'white',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: 'rgb(0, 183, 255)'
    }
  }
};

export default makeStyles(styles);
