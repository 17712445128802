import React, { useContext } from 'react';
import { Avatar, Box, Typography } from '@material-ui/core/';
import typy from 'typy';
import { CustomButton, SearchBar } from '../../common-fe/src';
import styles from '../../styles/cash/cashStyle';
import { TranslateTextComponent, translate, capitalizeFirstLetter } from '../../translator';
import { GET_FB_ORDER } from '../../graphql/order/queries';
import { LanguageContext } from '../../contexts/LanguageContext';
import useCustomQuery from '../../hooks/useCustomQuery';

const GuestReservation = ({ fbOrderUUID }) => {
  const classes = styles();
  const { language } = useContext(LanguageContext);

  // Queries
  const order = useCustomQuery(GET_FB_ORDER, { variables: { fbOrderUUID }, fetchPolicy: 'network-only' });

  // Vars from query
  const fullName = `${typy(order, 'data.getFbOrder.fbReservationTable.fbReservation.bookedBy.firstname').safeString} ${
    typy(order, 'data.getFbOrder.fbReservationTable.fbReservation.bookedBy.lastname').safeString
  }`;

  const roomNumber = typy(order, 'data.getFbOrder.fbReservationTable.fbReservation.guestRoom').safeString;
  const reservationTime = typy(order, 'data.getFbOrder.fbReservationTable.fbReservation.reservationTime').safeString;
  const tableNumber = typy(order, 'data.getFbOrder.fbReservationTable.fbTable.number').safeString || null;
  const paxNumber = typy(order, 'data.getFbOrder.fbReservationTable.fbReservation.nPax').safeNumber || null;
  const packages = typy(order, 'data.getFbOrder.fbReservationTable.fbReservation.package.name').safeString;

  return (
    <Box className={classes.guestInfoArea}>
      <Box style={{ gridArea: 'avatar' }}>
        <Avatar />
      </Box>
      <Box style={{ gridArea: 'info', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', maxWidth: 533 }}>
        {fullName && (
          <Typography className={classes.guestNameTypography} style={{ fontSize: 14, marginRight: 10 }}>
            {fullName}
          </Typography>
        )}
        {roomNumber && (
          <Typography className={classes.titleTypography} style={{ fontWeight: 'bold' }}>
            <TranslateTextComponent capitalize>room</TranslateTextComponent>:<span className={classes.dataTypography}> {roomNumber}</span>
          </Typography>
        )}
        {reservationTime && (
          <Typography className={classes.titleTypography} style={{ fontWeight: 'bold' }}>
            <TranslateTextComponent capitalize>time</TranslateTextComponent>:<span className={classes.dataTypography}> {reservationTime}</span>
          </Typography>
        )}
        {tableNumber && (
          <Typography className={classes.titleTypography} style={{ fontWeight: 'bold' }}>
            <TranslateTextComponent capitalize>table-number</TranslateTextComponent>:<span className={classes.dataTypography}> {tableNumber}</span>
          </Typography>
        )}
        {paxNumber && (
          <Typography className={classes.titleTypography} style={{ fontWeight: 'bold' }}>
            <TranslateTextComponent capitalize>n-pax</TranslateTextComponent>:<span className={classes.dataTypography}> {paxNumber}</span>
          </Typography>
        )}
        <Typography className={classes.titleTypography} style={{ fontWeight: 'bold' }}>
          <TranslateTextComponent capitalize>amenities</TranslateTextComponent>:
          {/* <span className={classes.dataTypography}> {typy(order, 'data.getOrderSummary.bookedBy.guestRoom').safeString}</span> */}
        </Typography>
        {packages && (
          <Typography className={classes.titleTypography} style={{ fontWeight: 'bold' }}>
            <TranslateTextComponent capitalize>package</TranslateTextComponent>:<span className={classes.dataTypography}> {packages}</span>
          </Typography>
        )}
      </Box>
      <Box style={{ gridArea: 'searchbar' }}>
        <SearchBar style={{ height: 40 }} placeholder={capitalizeFirstLetter(translate('searchbar-guest-reservation-placeholder', language))} />
      </Box>
      <Box style={{ gridArea: 'button' }}>
        <CustomButton translateText style={{ width: 200, boxShadow: 'unset', height: 40 }}>
          <TranslateTextComponent>registry</TranslateTextComponent>
        </CustomButton>
      </Box>
    </Box>
  );
};
export default GuestReservation;
