import React from 'react';
import Layout from '../components/shared/Layout';
import RecordsContainer from '../components/Records/RecordsContainer';

const RecordsPage = ({ history }) => {
  return (
    <Layout history={history} section="records">
      <RecordsContainer />
    </Layout>
  );
};

export default RecordsPage;
