import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import { Box, ClickAwayListener, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import styles from '../../../styles/menu/menuStyle';
import Input from './Input';
import { TranslateTextComponent } from '../../../translator';

const Container = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'flex-start',
  gridColumnGap: 5,
  position: 'relative',
}));

const ColorPicker = ({ title = '', color = '#00b7ff', size = 40, error, style, onChange }) => {
  const [displayPicker, setDisplayPicker] = useState(false);
  const [chosenColor, setChosenColor] = useState(color);
  const classes = styles({ size });
  const onColorChange = (selectedColor) => {
    setChosenColor(selectedColor);
  };

  const closeColorPicker = () => {
    if (displayPicker) {
      setDisplayPicker(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={closeColorPicker}>
      <Container position="relative" style={style}>
        {title && (
          <Typography style={{ fontsize: '0.8rem' }}>
            <TranslateTextComponent capitalize>{title}</TranslateTextComponent>
          </Typography>
        )}
        <Box display="flex" marginTop={title ? '5px' : '0px'}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginRight="10px!important"
            height={size}
            width={size}
            border="1px solid #00b7ff"
          >
            <button
              type="button"
              className={classes.colorPicker}
              style={{ backgroundColor: chosenColor }}
              onClick={() => setDisplayPicker(!displayPicker)}
            />
          </Box>
          <Box>
            <Input
              id="color-picker-input"
              name="color"
              height={40}
              width={84}
              value={chosenColor.toUpperCase()}
              error={error}
              onClick={() => setDisplayPicker(!displayPicker)}
            />
          </Box>
          <Box position="absolute" top={50} left={0} zIndex={2}>
            {displayPicker && (
              <ChromePicker
                color={chosenColor}
                onChangeComplete={(v) => {
                  onColorChange(v.hex);
                  onChange(v.hex);
                }}
                disableAlpha
                value={chosenColor}
              />
            )}
          </Box>
        </Box>
      </Container>
    </ClickAwayListener>
  );
};

export default ColorPicker;
