import React from 'react';
import { Button as MUIButton } from '@material-ui/core';
import styles from '../../../styles/menu/menuStyle';

const Button = ({ children, type, variant, color, onClick }) => {
  const classes = styles();

  return (
    <MUIButton className={classes.modalButton} variant={variant} color={color} type={type || 'button'} onClick={onClick}>
      {children}
    </MUIButton>
  );
};

export default Button;
