import React from 'react';
import { Typography, Box, Grid } from '@material-ui/core/';
import { TranslateTextComponent } from '../../../../translator';
import styles from '../../../../styles/reservation/reservation';
import { MaterialIcon } from '../../../../common-fe';

const NPaxBox = ({ fbGuestList, component }) => {
  const classes = styles();
  return (
    <Box className={component === 'details' ? null : classes.gridKidsEnfantsNPax}>
      <Grid>
        <Typography className={classes.ageTypographyNPax}>
          <TranslateTextComponent capitalize>ages</TranslateTextComponent>
        </Typography>
      </Grid>
      {fbGuestList
        .filter((fbGuest) => fbGuest.guestPhysicalCategory.hasAgeInput)
        .sort((catA, catB) => catA.guestPhysicalCategory.id - catB.guestPhysicalCategory.id)
        .map((cat, index) => {
          return (
            <Grid key={`cat${index}`} container direction="row" alignItems="center" className={classes.gridNPaxIcon}>
              <MaterialIcon iconName={cat.guestPhysicalCategory.icon} />
              <Typography className={cat.age && classes.typographyNPaxBox}>{cat.age}</Typography>
            </Grid>
          );
        })}
    </Box>
  );
};

export default NPaxBox;
