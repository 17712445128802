import React from 'react';
import { Box, Grid } from '@material-ui/core';
import typy from 'typy';
import styles from '../../../styles/records/orders/style';
import { getTotalQuantity } from '../../../constants/order/order';
import OrderName from './OrderName';
import { SharedBy, ChairOrHalfPortion, Centerpiece } from '../../order/OrderIconComponents';

const OrderItemData = ({ items, table }) => {
  const classes = styles();

  return (
    <Box className={classes.dataRow}>
      <Box className={classes.quantityArea} style={{ width: 56 }}>
        {getTotalQuantity(items) > 1 ? `${getTotalQuantity(items)}x` : getTotalQuantity(items)}
      </Box>
      <Box className={classes.dishArea}>
        <Box style={{ display: 'flex', flexFlow: 'column' }}>
          <OrderName name={items[0].name} withIngredient={items[0].with} withoutIngredient={items[0].without} />
        </Box>
      </Box>
      <Box className={classes.priceArea}>
        <span>
          €{' '}
          {items[0].price
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
            .toString()
            .replace('.', ',')}
        </span>
      </Box>
      <Box className={classes.chairArea}>
        {items.map((fbMenuItem, index) => {
          return (
            <Box key={index} style={{ display: 'flex', flexDirection: 'column' }}>
              {fbMenuItem.sharedBy && fbMenuItem.sharedBy.length > 1 ? (
                <SharedBy
                  item={fbMenuItem}
                  sharedBy={fbMenuItem.sharedBy}
                  fbChairs={typy(table, 'fbChairs').safeArray.sort((prev, next) => prev.number - next.number)}
                  withoutNotes
                />
              ) : (
                <Grid style={{ paddingTop: fbMenuItem.centerpiece ? 0 : 5 }}>
                  {fbMenuItem.fbChairId !== null && (
                    <ChairOrHalfPortion
                      containerStyle={{ margin: '10px 0', flexFlow: 'row-reverse' }}
                      chairNumberStyle={{ margin: '0 3px' }}
                      item={fbMenuItem}
                      chair={typy(table, 'fbChairs').safeArray.find((fbChair) => fbChair.id === fbMenuItem.fbChairId)}
                      isHalfPortion={fbMenuItem.halfPortion}
                      withoutNotes
                    />
                  )}
                  {fbMenuItem.centerpiece && <Centerpiece item={fbMenuItem} withoutNotes />}
                </Grid>
              )}
            </Box>
          );
        })}
      </Box>
      <Box className={classes.clientArea}>
        {items.map((fbMenuItem, index) => {
          const firstName = fbMenuItem.fbChair?.guest?.firstname ?? '';
          const lastName = fbMenuItem.fbChair?.guest?.lastName ?? '';
          return <span key={index}>{`${firstName} ${lastName}`}</span>;
        })}
      </Box>
    </Box>
  );
};

export default OrderItemData;
