import React from 'react';
import Layout from '../components/shared/Layout';
import NewGuest from '../components/newGuest/NewGuest';

const NewGuestPage = ({ history }) => {
  return (
    <Layout history={history} section="new guest">
      <NewGuest />
    </Layout>
  );
};

export default NewGuestPage;
