import gql from 'graphql-tag';
import { FB_CHAIR_FRAGMENT } from '../fragments';

export const SET_FB_ORDER_MUTATION = gql`
  mutation setFbOrder($fbTableUUID: String!, $fbOrderData: FbOrdersInput!) {
    setFbOrder(fbTableUUID: $fbTableUUID, fbOrderData: $fbOrderData) {
      id
      uuid
      fbCourses {
        id
        uuid
        number
        fbCourseStatus
        isWaiting
        type
        fbCoursesMenuItems {
          id
          uuid
          name
          quantity
          fbMenuItemId
          centerpiece
          halfPortion
          notes
          with
          without
          sharedBy
          fbMenuItemId
          fbMenuItem {
            id
            fbMenuCategoryId
          }
          fbChairId
          fbChair {
            ...fbChairFragment
          }
        }
      }
      fbReservationTable {
        id
        fbTableId
        fbTable {
          id
          number
          fbChairs {
            ...fbChairFragment
          }
        }
        fbReservation {
          id
          guestRoom
          bookedBy {
            id
            firstname
            lastname
          }
        }
      }
    }
  }
  ${FB_CHAIR_FRAGMENT}
`;

export const DELETE_FB_COURSE = gql`
  mutation deleteFbCourse($fbCourseUUID: String!) {
    deleteFbCourse(fbCourseUUID: $fbCourseUUID) {
      id
      uuid
      fbCourses {
        id
        uuid
        number
        fbCourseStatus
        isWaiting
        type
        fbCoursesMenuItems {
          id
          uuid
          name
          quantity
          fbMenuItemId
          centerpiece
          halfPortion
          notes
          with
          without
          sharedBy
          fbMenuItemId
          fbMenuItem {
            id
            fbMenuCategoryId
          }
          fbChairId
          fbChair {
            ...fbChairFragment
          }
        }
      }
      fbReservationTable {
        id
        fbTableId
        fbTable {
          id
          number
          fbChairs {
            ...fbChairFragment
          }
        }
        fbReservation {
          id
          guestRoom
          bookedBy {
            id
            firstname
            lastname
          }
        }
      }
    }
  }
  ${FB_CHAIR_FRAGMENT}
`;

export const STOP_FB_COURSE = gql`
  mutation stopFbCourse($fbCourseUUID: String!) {
    stopFbCourse(fbCourseUUID: $fbCourseUUID) {
      id
      uuid
      fbCourses {
        id
        uuid
        number
        fbCourseStatus
        isWaiting
        type
        fbCoursesMenuItems {
          id
          uuid
          name
          quantity
          fbMenuItemId
          centerpiece
          halfPortion
          notes
          with
          without
          sharedBy
          fbMenuItemId
          fbMenuItem {
            id
            fbMenuCategoryId
          }
          fbChairId
          fbChair {
            ...fbChairFragment
          }
        }
      }
      fbReservationTable {
        id
        fbTableId
        fbTable {
          id
          number
          fbChairs {
            ...fbChairFragment
          }
        }
        fbReservation {
          id
          guestRoom
          bookedBy {
            id
            firstname
            lastname
          }
        }
      }
    }
  }
  ${FB_CHAIR_FRAGMENT}
`;

export const PLAY_FB_COURSE = gql`
  mutation playFbCourse($fbCourseUUID: String!) {
    playFbCourse(fbCourseUUID: $fbCourseUUID) {
      id
      uuid
      fbCourses {
        id
        uuid
        number
        fbCourseStatus
        isWaiting
        type
        fbCoursesMenuItems {
          id
          uuid
          name
          quantity
          fbMenuItemId
          centerpiece
          halfPortion
          notes
          with
          without
          sharedBy
          fbMenuItemId
          fbMenuItem {
            id
            fbMenuCategoryId
          }
          fbChairId
          fbChair {
            ...fbChairFragment
          }
        }
      }
      fbReservationTable {
        id
        fbTableId
        fbTable {
          id
          number
          fbChairs {
            ...fbChairFragment
          }
        }
        fbReservation {
          id
          guestRoom
          bookedBy {
            id
            firstname
            lastname
          }
        }
      }
    }
  }
  ${FB_CHAIR_FRAGMENT}
`;

export const SET_FB_COURSE_STATUS = gql`
  mutation setFbCourseStatus($fbCourseUUID: String!, $fbCourseStatus: String!) {
    setFbCourseStatus(fbCourseUUID: $fbCourseUUID, fbCourseStatus: $fbCourseStatus) {
      id
      uuid
      fbCourses {
        id
        uuid
        number
        fbCourseStatus
        isWaiting
        type
        fbCoursesMenuItems {
          id
          uuid
          name
          quantity
          fbMenuItemId
          centerpiece
          halfPortion
          notes
          with
          without
          sharedBy
          fbMenuItemId
          fbMenuItem {
            id
            fbMenuCategoryId
          }
          fbChairId
          fbChair {
            ...fbChairFragment
          }
        }
      }
      fbReservationTable {
        id
        fbTableId
        fbTable {
          id
          number
          fbChairs {
            ...fbChairFragment
          }
        }
        fbReservation {
          id
          guestRoom
          bookedBy {
            id
            firstname
            lastname
          }
        }
      }
    }
  }
  ${FB_CHAIR_FRAGMENT}
`;
