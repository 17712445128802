import { makeStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    minHeight: 50,
    fontSize: 20,
    fontStyle: 'italic',
    boxShadow: '0px -2px 15px #00000029'
  },
  discountArea: {
    display: 'grid',
    gridTemplateAreas: '"title discount buttons"',
    gridTemplateColumns: '1fr 1fr auto',
    gridColumnGap: 5,
    backgroundColor: '#BFE6BF',
    fontStyle: 'normal',
    fontSize: 14,
    padding: 15
  },
  titleArea: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#EDFAFF',
    padding: 15
  },
  priceArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: '#91C9E8',
    padding: 15,
    fontWeight: 'bold'
  },
  showKeyboard: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    background: 'transparent linear-gradient(360deg, #3399CC 0%, #00B7FF 100%) 0% 0% no-repeat padding-box',
    minHeight: 85,
    fontSize: 20,
    fontWeight: 'bold',
    color: 'white'
  },
  '@keyframes blink': {
    '0%': { color: 'black' },
    '25%': { color: 'rgba(113, 113, 113, 0.87)' },
    '50%': { color: 'white' },
    '75%': { color: 'rgba(113, 113, 113, 0.87)' },
    '100%': { color: 'black' }
  },
  blink: {
    color: 'red',
    animation: '$blink 2s steps(5, start) infinite;'
  }
};

export default makeStyles(styles);
