import React from 'react';
import { Typography as MUITypography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const StyledContainer = styled('div')(({ gridarea, height }) => ({
  gridArea: gridarea,
  display: 'flex',
  flexFlow: 'column',
  height
}));

const StyledInput = styled('input')(({ width, height, title, error }) => ({
  border: `1px solid ${error ? 'red' : '#00b7ff'}`,
  borderRadius: 3,
  width,
  height,
  fontSize: '1rem',
  textIndent: 10,
  marginTop: title ? 5 : 0
}));

const Typography = styled(MUITypography)(({ fontsize }) => ({
  fontSize: fontsize
}));

const Input = ({ id, gridarea, placeholder, style, width, height, containerHeight, numericValue, title, name, error, value, onChange, onClick }) => {
  return (
    <StyledContainer autoCorrect="off" gridarea={gridarea} width={width} height={containerHeight} style={{ ...style }}>
      {title && <Typography style={{ fontsize: '0.8rem' }}>{title}</Typography>}
      <StyledInput
        id={id}
        name={name}
        value={value}
        error={error}
        width={width}
        height={height}
        title={title}
        placeholder={placeholder}
        onChange={(e) => {
          if (numericValue) {
            e.target.value = e.target.value.replace(/,/g, '.');
          }
          onChange(e);
        }}
        onClick={onClick}
      />
      <Typography color="error" style={{ fontsize: '0.8rem' }}>
        {error}
      </Typography>
    </StyledContainer>
  );
};

export default Input;
