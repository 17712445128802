import React, { createContext, useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import validator from 'validator';
import * as moment from 'moment';

import customObjectValidator from '../utils/validator';

import { ADD_FB_RESERVATION, EDIT_FB_RESERVATION_MUTATION } from '../graphql/reservations/mutations';
import { ADD_GUEST, EDIT_GUEST } from '../graphql/guests/mutations';
import { AuthContext } from './AuthContext';
import { CHANNELS } from '../constants/saveReservation/channels';
import { phoneNumberRegex } from '../utils/regex';

const today = moment().format('YYYY-MM-DD');

// Subtract 30 minutes for add reservation
const time = moment().subtract(30, 'minutes').format('HH:mm');

export const ReservationContext = createContext();

const initialReservationData = {
  id: null,
  fbOutletUUID: null,
  fbServiceUUID: null,
  channelUUID: null,
  packageUUID: null,
  channelId: null,
  fbRoom: null,
  reservationDate: null,
  reservationTime: null,
  selectedTime: null,
  notes: null,
  tableNumber: null,
  guestRoom: null,
  arrangement: null,
  fbGuestList: []
};

const newGuestData = {
  id: null,
  uuid: null,
  firstName: null,
  lastName: null,
  languageCode: null,
  dob: null,
  nationality: null,
  address: null,
  city: null,
  zipCode: null,
  country: null,
  email: null,
  phone: null,
  allergens: [],
  intolerances: []
};

const GUEST_VALIDATION = {
  firstName: {
    isRequired: true
  },
  lastName: {
    isRequired: true
  },
  email: {
    isRequired: false,
    validateFunctions: [{ fn: (value) => validator.isEmail(value), errorMessage: 'Not valid email' }]
  },
  phone: {
    isRequired: false,
    validateFunctions: [{ fn: (value) => validator.matches(value, phoneNumberRegex), errorMessage: 'Insert a valid phone number' }]
  },
  arrangement: {
    isRequired: true
  }
};

const RESERVATION_VALIDATION = {
  fbOutletUUID: {
    isRequired: true
  },
  fbGuestList: {
    isRequired: false,
    validateFunctions: [{ fn: (value) => value.length > 0, errorMessage: 'Insert number of people' }]
  },
  reservationDate: {
    isRequired: true,
    validateFunctions: [
      { fn: (value, form) => !(form?.uuid && !value) || moment(value).format('YYYY-MM-DD') >= today, errorMessage: 'Error in reservation date' }
    ]
  },
  reservationTime: {
    isRequired: true,
    validateFunctions: [
      {
        fn: (value, form) => (moment(form.reservationDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? value > time : true),
        errorMessage: 'Error in reservation time'
      }
    ]
  },
  selectedTime: {
    isRequired: true,
    validateFunctions: [
      {
        fn: (value, form) => (moment(form.reservationDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? value > time : true),
        errorMessage: 'Error in reservation time'
      }
    ]
  },
  fbServiceUUID: {
    isRequired: true
  },
  channelUUID: {
    isRequired: true
  }
};

export const ReservationProvider = (props) => {
  const { appOutletUUID } = useContext(AuthContext);
  const [reservationsData, setReservationsData] = useState([initialReservationData]);
  const [isEditingReservationIndex, setIsEditingReservationIndex] = useState(0);
  const [guestData, setGuestData] = useState(newGuestData);
  const [selectedOutlet, setSelectedOutlet] = useState({});
  const [errorGuestMap, setErrorGuestMap] = useState(new Map());
  const [errorResMap, setErrorResMap] = useState([new Map()]);
  // Mutations
  const [addNewReservationMutation] = useMutation(ADD_FB_RESERVATION);
  const [addNewGuestMutation] = useMutation(ADD_GUEST);
  const [editGuestMutation] = useMutation(EDIT_GUEST);
  const [editReservationMutation] = useMutation(EDIT_FB_RESERVATION_MUTATION);

  useEffect(() => {
    setReservationsData([{ ...initialReservationData }]);
  }, [appOutletUUID]);

  const handleChangeOutlet = (outlet) => {
    setSelectedOutlet(outlet);
  };

  const addNewReservation = () => {
    setReservationsData([...reservationsData, { ...initialReservationData }]);
    setErrorResMap([...errorResMap, new Map()]);
  };

  const reservationResetData = () => {
    setReservationsData([initialReservationData]);
    setGuestData({ ...newGuestData });
  };

  const removeRes = (reservationDataIndex) => {
    const indexToRemove = reservationDataIndex;
    const reservationListData = reservationsData;
    if (indexToRemove != null && reservationsData.length > 1) {
      reservationListData.splice(indexToRemove, 1);
      setReservationsData([...reservationListData]);
    }
    if (errorResMap && errorResMap.length > 1) {
      errorResMap.splice(indexToRemove, 1);
      setErrorResMap([...errorResMap]);
    }
    setIsEditingReservationIndex(reservationListData.length - 1);
  };

  const handleSetIsEditingReservationIndex = (index) => {
    setIsEditingReservationIndex(index);
  };

  const handleChangeReservationData = (key, value, reservationDataIndex = isEditingReservationIndex) => {
    const updatedReservation = {
      ...reservationsData[reservationDataIndex],
      [key]: value
    };
    const updatedReservations = [...reservationsData];
    updatedReservations[reservationDataIndex] = updatedReservation;
    setReservationsData(updatedReservations);
  };

  const handleChangeReservationDataFields = (objectValues, reservationDataIndex = isEditingReservationIndex) => {
    const updatedReservation = {
      ...reservationsData[reservationDataIndex],
      ...objectValues
    };
    const updatedReservations = [...reservationsData];
    updatedReservations[reservationDataIndex] = updatedReservation;
    setReservationsData(updatedReservations);
  };

  const handleSetGuestData = (data) => {
    setGuestData(data);
  };

  const handleSetFirstReservationData = (reservationData) => {
    setReservationsData([{ ...reservationData }]);
  };

  const handleChangeGuestData = (key, value) => {
    guestData[key] = value;
    setGuestData({
      ...guestData
    });
  };

  const restoreNewGuestData = () => {
    setGuestData({
      ...newGuestData
    });
  };
  const restoreReservationsData = () => {
    setReservationsData([initialReservationData]);
  };

  const isValidGuest = () => {
    const guestToValidate = guestData;
    const validation = customObjectValidator(GUEST_VALIDATION, guestToValidate);
    setErrorGuestMap(validation.errorMap);
    return validation.isValid;
  };

  const isValidReservation = () => {
    const reservationToValidate = reservationsData;
    const allValidated = reservationToValidate.map((resToValidate) => {
      return customObjectValidator(RESERVATION_VALIDATION, resToValidate);
    });
    setErrorResMap(allValidated.map((validation) => validation.errorMap));
    return !allValidated.some((valid) => valid.isValid === false);
  };

  const saveGuest = () => {
    if (guestData.uuid !== null) {
      return editGuestMutation({
        variables: {
          guestUUID: guestData.uuid,
          guestData: {
            firstname: guestData.firstName,
            lastname: guestData.lastName,
            email: guestData.email === '' ? null : guestData.email,
            phone: guestData.phone,
            allergens: guestData.allergens || [],
            intolerances: guestData.intolerances || []
          }
        }
      });
    }
    return addNewGuestMutation({
      variables: {
        guestData: {
          firstname: guestData.firstName,
          lastname: guestData.lastName,
          email: guestData.email,
          phone: guestData.phone,
          allergens: guestData.allergens,
          intolerances: guestData.intolerances
        }
      }
    });
  };

  const saveReservations = (fbGuestUUID = null) => {
    return Promise.all(
      reservationsData.map((reservation) => {
        if (!fbGuestUUID) {
          fbGuestUUID = guestData.uuid;
        }
        const fbGuestList = reservation.fbGuestList.map((guest) => {
          return { guestPhysicalCategoryId: Number(guest.guestPhysicalCategoryId), age: Number(guest.age) || null };
        });
        if (reservation.id !== null) {
          return editReservationMutation({
            variables: {
              fbReservationUUID: reservation.uuid,
              fbGuestUUID,
              fbServiceUUID: reservation.fbServiceUUID,
              packageUUID: reservation?.packageUUID,
              channelUUID: reservation?.channelUUID,
              fbReservationsData: {
                date: moment(reservation.reservationDate).format('YYYY-MM-DD'),
                reservationTime: reservation.reservationTime,
                notes: reservation.notes,
                tableNumber: reservation.tableNumber,
                guestRoom: reservation?.guestRoom,
                arrangement: reservation?.arrangement,
                fbGuestList
              }
            }
          });
        }
        return addNewReservationMutation({
          variables: {
            fbGuestUUID,
            fbServiceUUID: reservation.fbServiceUUID,
            packageUUID: reservation?.packageUUID,
            channelUUID: reservation?.channelUUID,
            channelName: CHANNELS.FB,
            fbReservationsData: {
              date: moment(reservation.reservationDate).format('YYYY-MM-DD'),
              reservationTime: reservation.reservationTime,
              notes: reservation.notes,
              tableNumber: reservation.tableNumber,
              guestRoom: reservation?.guestRoom,
              arrangement: reservation?.arrangement,
              fbGuestList
            }
          }
        });
      })
    );
  };

  const resetValidationErrors = () => {
    setErrorGuestMap(new Map());
    setErrorResMap([new Map()]);
  };

  return (
    <ReservationContext.Provider
      value={{
        reservationsData,
        isEditingReservationIndex,
        guestData,
        errorGuestMap,
        errorResMap,
        handleSetIsEditingReservationIndex,
        handleChangeReservationData,
        handleSetGuestData,
        handleChangeGuestData,
        handleChangeOutlet,
        handleSetFirstReservationData,
        handleChangeReservationDataFields,
        selectedOutlet,
        removeRes,
        reservationResetData,
        addNewReservation,
        restoreNewGuestData,
        isValidGuest,
        isValidReservation,
        saveReservations,
        saveGuest,
        resetValidationErrors,
        restoreReservationsData
      }}
    >
      {props.children}
    </ReservationContext.Provider>
  );
};

export const ReservationConsumer = ReservationContext.Consumer;
