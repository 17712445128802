import React from 'react';
import { Grid, Typography } from '@material-ui/core/';

import { TranslateTextComponent } from '../../translator';
import { ReactComponent as MaleImg } from '../../assets/images/order/male.svg';
import { ReactComponent as FemaleImg } from '../../assets/images/order/female.svg';

import styles from '../../styles/shared/physicalCategoriesBoxGender';
import { invertedPrimary } from '../../styles/theme';
import { SEX_CODES } from './ChairItem';

const PhysicalCategoriesBoxGender = ({
  physicalCategories,
  guestFbReservationGender,
  guestFbReservationPhysicalCategoryId,
  isClickable,
  handleClick
}) => {
  const classesMale = styles({ color: invertedPrimary, isClickable });
  const classesFemale = styles({ color: '#b93dc9', isClickable });
  return (
    <Grid container alignItems="center">
      {physicalCategories.map((category, index) => {
        return (
          <Grid key={index} container justify="center" alignItems="center" style={{ marginBottom: index !== physicalCategories.length - 1 && 5 }}>
            <Grid item xs={4}>
              <Typography style={{ fontWeight: category.id === guestFbReservationPhysicalCategoryId && 'bold', paddingLeft: 3, fontSize: 12 }}>
                <TranslateTextComponent capitalize>{category.description.toLowerCase()}</TranslateTextComponent>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ fontWeight: category.id === guestFbReservationPhysicalCategoryId && 'bold', textAlign: 'center', fontSize: 10 }}>
                {category.ageRange && category.ageRange}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              onClick={() => isClickable && handleClick(category, SEX_CODES.male)}
            >
              <MaleImg
                className={
                  guestFbReservationGender && guestFbReservationGender === SEX_CODES.male && category.id === guestFbReservationPhysicalCategoryId
                    ? [classesMale.genderIcon, 'selected'].join(' ')
                    : classesMale.genderIcon
                }
              />
            </Grid>
            <Grid
              item
              xs={2}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              onClick={() => isClickable && handleClick(category, SEX_CODES.female)}
            >
              <FemaleImg
                className={
                  guestFbReservationGender && guestFbReservationGender === SEX_CODES.female && category.id === guestFbReservationPhysicalCategoryId
                    ? [classesFemale.genderIcon, 'selected'].join(' ')
                    : classesFemale.genderIcon
                }
              />
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PhysicalCategoriesBoxGender;
