import React, { useState } from 'react';
import { Box, Grid, List, ListItem, Button, TextField } from '@material-ui/core';
import FiscalPrinter from '../FiscalPrinter';
import * as utils from '../FiscalPrinter/utils/utils';

const fiscalPrinter = new FiscalPrinter('http://192.168.0.456465', 'test');

const FiscalPrinterTestPage = () => {
  const [documentNumber, setDocumentNumber] = useState('');
  const [date, setDate] = useState('');

  const queryPrinterStatus = async () => {
    const test = await fiscalPrinter.queryPrinterStatus();
    console.log(utils.decodeFpStatus(test.addInfo.fpStatus._text));
  };

  const printFiscalReceipt = async () => {
    const items = [
      { description: 'Varie', quantity: 1, unitPrice: 1, department: '01', justification: '' },
      { description: 'Piatto', quantity: 3, unitPrice: 10, department: '01', justification: '' },
      { description: 'Acqua naturale', quantity: 2, unitPrice: 3, department: '01', justification: '' }
    ];
    const test = await fiscalPrinter.printFiscalReceipt(items, '0', '0', 'Contanti');
    console.log(test);
  };
  const cancelFiscalReceipt = async () => {
    const test = await fiscalPrinter.cancelFiscalReceipt(documentNumber, date);
    console.log(test);
  };

  return (
    <Grid container justify="space-around" style={{ padding: 50 }}>
      <Grid item>
        <Button variant="contained" color="secondary" onClick={queryPrinterStatus}>
          STATUS
        </Button>
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={printFiscalReceipt}>
          SCONTRINO
        </Button>
      </Grid>
      <Grid item>
        <Box>
          <Button variant="contained" onClick={cancelFiscalReceipt}>
            ANNULLA SCONTRINO
          </Button>
        </Box>
        <TextField placeholder="Document number" value={documentNumber} onChange={(e) => setDocumentNumber(e.target.value)} />
        <TextField placeholder="Date" value={date} onChange={(e) => setDate(e.target.value)} />
      </Grid>
    </Grid>
  );
};

export default FiscalPrinterTestPage;
