import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { primaryColor, secondaryColor } from '../../styles/theme';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ isActive, activeColor, inactiveColor }) => (isActive ? inactiveColor : activeColor)};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
`;

const Counter = styled.span`
  color: ${({ isActive, activeColor, inactiveColor }) => (isActive ? activeColor : inactiveColor)};
`;

const RoundedNotification = ({ counter, isActive, size, activeColor, inactiveColor, containerStyle, counterStyle }) => {
  return counter > 0 ? (
    <Container isActive={isActive} size={size} activeColor={activeColor} inactiveColor={inactiveColor} style={containerStyle}>
      <Counter isActive={isActive} activeColor={activeColor} inactiveColor={inactiveColor} style={counterStyle}>
        {counter}
      </Counter>
    </Container>
  ) : null;
};

RoundedNotification.propTypes = {
  counter: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  size: PropTypes.number,
  activeColor: PropTypes.string,
  inactiveColor: PropTypes.string
};

RoundedNotification.defaultProps = {
  isActive: true,
  size: 25,
  activeColor: primaryColor,
  inactiveColor: secondaryColor
};

export default RoundedNotification;
