import React from 'react';
import { Box, Button } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles({
  circleStyle: (props) => ({
    backgroundColor: `${props.color}`,
    borderRadius: '50%',
    width: 30,
    height: 30,
    marginLeft: 0.5,
    marginBottom: 0.5,
  }),
  border: (props) => ({
    border: `2px solid ${props.color}`,
    borderRadius: '50%',
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  noHover: () => ({
    '&:hover': {
      backgroundColor: 'white',
    },
  }),
});
const Circle = ({ category, onClick }) => {
  const classes = styles();

  return (
    <Button className={classes.noHover} onClick={onClick}>
      <Box className={styles({ color: category.color }).border}>
        <Box className={styles({ color: category.color }).circleStyle} />
      </Box>
    </Button>
  );
};
export default Circle;
