import React from 'react';
import { Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';
import { TranslateTextComponent } from '../../../../translator';

const PMDetails = ({ data, selectedGuestUUID, onPayMasterDetailCheck }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>
            <TranslateTextComponent capitalize>name</TranslateTextComponent>
          </TableCell>
          <TableCell>
            <TranslateTextComponent capitalize>email</TranslateTextComponent>
          </TableCell>
          <TableCell>
            <TranslateTextComponent capitalize>phone</TranslateTextComponent>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.guestList.map((guest) => (
          <TableRow key={guest.uuid} style={{ borderTop: '1px solid gray' }}>
            <TableCell>
              <input
                id={guest.uuid}
                className="pm-detail-checkbox"
                type="checkbox"
                value={guest.uuid === selectedGuestUUID ? 'on' : 'off'}
                onChange={() => onPayMasterDetailCheck({ payMasterUUID: data.uuid, guestUUID: guest.uuid })}
              />
            </TableCell>
            <TableCell>{guest.firstname + ' ' + guest.lastname}</TableCell>
            <TableCell>{guest.email}</TableCell>
            <TableCell>{guest.phone}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default PMDetails;
