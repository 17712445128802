import { makeStyles } from '@material-ui/core/styles';

const styles = {
  area: {
    display: 'flex',
    justifyContent: 'center',
    color: '#4D4D4D'
  },
  stepper: {
    fontSize: 20,
    justifyContent: 'space-around',
    alignItems: 'center',
    minWidth: 170,
    maxWidth: 200,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  buttons: {
    justifyContent: 'space-around'
  }
};

export default makeStyles(styles);
