import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { TextField as MUITextField, Button, Box } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import InputWithLabel from '../../shared/InputWithLabel';
import Buttons from '../../shared/Buttons';
import Switch from '../../shared/Switch';
import useCustomQuery from '../../../hooks/useCustomQuery';
import { GET_FB_OUTLET } from '../../../graphql/outlets/queries';
import { AuthContext } from '../../../contexts/AuthContext';
import { EDIT_FB_OUTLET } from '../../../graphql/outlets/mutations';
import { TranslateTextComponent } from '../../../translator';
import { getApolloErrors } from '../../../apollo/ApolloProvider';
import { ModalContext } from '../../../contexts/ModalContext';
import { AppOutletContext } from '../../../contexts/AppOutletContext';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const TextField = styled(MUITextField).attrs({ variant: 'outlined', color: 'primary' })``;

const validationSchema = Yup.object().shape({
  ip: Yup.string()
  // .required('Required')
});

const Advanced = () => {
  const { appOutletUUID } = useContext(AuthContext);
  const { reloadAppOutlet } = useContext(AppOutletContext);
  const modalContext = useContext(ModalContext);

  // Queries
  const getOutlet = useCustomQuery(GET_FB_OUTLET, {
    variables: { fbOutletUUID: appOutletUUID }
  });

  // Mutations
  const [editOutlet] = useMutation(EDIT_FB_OUTLET);

  const { values, errors, touched, isSubmitting, setFieldValue, handleBlur, handleChange, handleReset, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ip: getOutlet.data?.getFbOutlet?.fiscalPrinterIpAddress ?? '',
      useFiscalPrinter: getOutlet.data?.getFbOutlet?.useFiscalPrinter ?? false
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      editOutlet({
        variables: {
          fbOutletUUID: appOutletUUID,
          fbOutletData: {
            fiscalPrinterIpAddress: values.ip === '' ? null : values.ip,
            useFiscalPrinter: values.useFiscalPrinter === '' ? false : values.useFiscalPrinter
          }
        }
      })
        .then(() => {
          reloadAppOutlet();
          getOutlet.refetch();
          modalContext.openModal({
            class: 'success',
            title: <TranslateTextComponent capitalize>outlet-edit-success</TranslateTextComponent>,
            text: <TranslateTextComponent capitalize>outlet-edit-success-description</TranslateTextComponent>,
            actionButtons: [
              <Button
                key={0}
                variant="contained"
                color="primary"
                className="success"
                onClick={() => {
                  modalContext.closeModal();
                }}
              >
                <TranslateTextComponent uppercase>ok</TranslateTextComponent>
              </Button>
            ]
          });
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        });
      setSubmitting(false);
    }
  });

  return (
    <Container>
      <RowContainer>
        <InputWithLabel label="ip-fiscal-printer" withMarginBottom error={touched.ip && errors.ip}>
          <TextField
            name="ip"
            fullWidth
            variant="outlined"
            color="primary"
            error={touched.ip && !!errors.ip}
            value={values.ip}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
      </RowContainer>
      <Box>
        <Switch
          name="useFiscalPrinter"
          leftLabel="no"
          rightLabel="yes"
          value={values.useFiscalPrinter}
          leftComponent={<TranslateTextComponent capitalize>use-fiscal-printer</TranslateTextComponent>}
          onChange={(isChecked) => {
            setFieldValue('useFiscalPrinter', isChecked);
          }}
        />
      </Box>
      <Buttons
        containerStyle={{ justifyContent: 'space-between', marginTop: 30 }}
        buttons={[
          {
            buttonType: 'reset',
            style: { padding: '5px 10px', minWidth: 155, fontSize: 20, fontWeight: 600 },
            type: 'cancel',
            onClick: handleReset
          },
          {
            buttonType: 'submit',
            style: { padding: '5px 10px', minWidth: 155, fontSize: 20, fontWeight: 600 },
            label: 'save',
            type: 'confirm',
            disabled: isSubmitting,
            onClick: handleSubmit
          }
        ]}
      />
    </Container>
  );
};

export default Advanced;
