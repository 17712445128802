import React from 'react';
import { Button } from '@material-ui/core/';
import styles from '../../styles/order/orderStyle';

const ButtonMenu = ({ selectedCategory, disabled, onClick, id }) => {
  return (
    <Button
      className={
        styles({
          selectedCategory,
          id,
          color: selectedCategory.color
        }).buttonOrder
      }
      onClick={onClick}
      disabled={disabled}
    >
      {selectedCategory.name}
    </Button>
  );
};

export default ButtonMenu;
