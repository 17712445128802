import { gql } from 'apollo-boost';

export const FB_CHAIR_FRAGMENT = gql`
  fragment fbChairFragment on FbChairsDto {
    id
    uuid
    number
    description
    gender
    guestPhysicalCategory {
      id
      uuid
      name
      icon
    }
    guest {
      id
      uuid
      firstname
      lastname
      gender
    }
  }
`;

export const LANGUAGE_FRAGMENT = gql`
  fragment languageFragment on LanguageDto {
    id
    uuid
    createdAt
    updatedAt
    name
    # culture
    # dateFormat
    ISO639_1
    # ISO639_3
  }
`;

export const USER_FRAGMENT = gql`
  fragment userFragment on UserDto {
    id
    uuid
    createdAt
    updatedAt
    firstName
    lastName
    username
    email
    phoneNumber
    avatar
    hasTempPassword
  }
`;

export const MOODS_FRAGMENT = gql`
  fragment moodsFragment on MoodsDto {
    id
    uuid
    name
    unicode
    description
  }
`;

export const STAFF_USER_FRAGMENT = gql`
  fragment staffUserFragment on StaffUserDto {
    id
    uuid
    createdAt
    updatedAt
    firstName
    lastName
    username
    email
    phoneNumber
    avatar
  }
`;

export const ROOM_TYPE_FRAGMENT = gql`
  fragment roomTypeFragment on RoomTypeDto {
    id
    uuid
    name
    description
    code
  }
`;

export const ROOM_FRAGMENT = gql`
  fragment roomFragment on RoomDto {
    id
    uuid
    name
    description
    # code
    # externalRef
    # status
    number
  }
`;

export const PERMISSION_FRAGMENT = gql`
  fragment permissionFragment on PermissionDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    name
    description
  }
`;

export const ROLE_FRAGMENT = gql`
  fragment roleFragment on RoleDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    name
    description
    isStatic
    permissions {
      ...permissionFragment
    }
  }
  ${USER_FRAGMENT}
  ${PERMISSION_FRAGMENT}
`;

export const IMAGE_FRAGMENT = gql`
  fragment imageFragment on ImageDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    name
    path
    isPrimary
  }
  ${USER_FRAGMENT}
`;

export const PROFILE_DOCUMENT_IMAGE_FRAGMENT = gql`
  fragment profileDocumentImageFragment on ProfileDocumentImageDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    name
    path
    isPrimary
  }
  ${USER_FRAGMENT}
`;

export const PAYMENT_CARD_FRAGMENT = gql`
  fragment paymentCardFragment on PaymentCardDto {
    cardNumber
    cardHolder
    expirationDate
    cvc
  }
`;

export const PROFILE_EMAIL_FRAGMENT = gql`
  fragment profileEmailFragment on ProfileEmailDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    emailAddress
    isPrimary
  }
  ${USER_FRAGMENT}
`;

export const PROFILE_ADDRESS_FRAGMENT = gql`
  fragment profileAddressFragment on ProfileAddressDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    firstName
    lastName
    line1
    line2
    city
    phone
    province
    postalCode
    addressNumber
    region
    countryCode
    isPrimary
  }
  ${USER_FRAGMENT}
`;

export const PROFILE_PHONE_NUMBER_FRAGMENT = gql`
  fragment profilePhoneNumberFragment on ProfilePhoneNumberDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    number
    isPrimary
  }
  ${USER_FRAGMENT}
`;

export const PROFILE_DOCUMENT_FRAGMENT = gql`
  fragment profileDocumentFragment on ProfileDocumentDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    type
    number
    placeOfIssue
    dateOfIssue
    expirationDate
    isPrimary
    images {
      ...profileDocumentImageFragment
    }
  }
  ${USER_FRAGMENT}
  ${PROFILE_DOCUMENT_IMAGE_FRAGMENT}
`;

export const CHECKIN_PREFERENCES_FRAGMENT = gql`
  fragment checkinPreferencesFragment on CheckinPreferencesDto {
    transportationInfo
    checkinMood {
      ...moodsFragment
    }
    checkoutMood {
      ...moodsFragment
    }
    checkoutFeedback
    housewife
    reservedLocalAdviser
    checkinNotes
    requestedServices
    complaints
    reasonOfTrip
    localAdvisers
  }
  ${MOODS_FRAGMENT}
`;

export const CHECKIN_FRAGMENT = gql`
  fragment checkinFragment on CheckinDto {
    id
    uuid
    createdAt
    updatedAt
    checkinDate
    checkoutDate
    staffPhoneNumber
    arrangement
    status
    checkinPreferences {
      ...checkinPreferencesFragment
    }
    documentImages {
      ...imageFragment
    }
    room {
      ...roomFragment
    }
    profile {
      id
      uuid
      firstName
      lastName
      email
      phone
    }
  }
  ${IMAGE_FRAGMENT}
  ${ROOM_FRAGMENT}
  ${CHECKIN_PREFERENCES_FRAGMENT}
`;

export const PROFILE_INFO_FRAGMENT = gql`
  fragment profileInfoFragment on ProfileInfoDto {
    vip
    partnerInfo
    childrenInfo
    characterInfo
    employment
    otherPersonalInfo
    physicalLimitations
    favoriteExperiences
  }
`;

export const FB_PROFILE_PREFERENCES_FRAGMENT = gql`
  fragment fbProfilePreferencesFragment on FbProfilePreferencesDto {
    profileId
    favouriteWine
    favouriteWater
    preferences
    foodRestrictions
    allergens
    intolerances
    favoriteInternalRestaurants
    favoriteExternalRestaurants
  }
`;

export const PROFILE_FRAGMENT = gql`
  fragment profileFragment on ProfileDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    bestAttention
    notes
    otherNotes
    email
    addresses {
      ...profileAddressFragment
    }
    phone
    mobilePhone
    paymentCards {
      ...paymentCardFragment
    }
    title
    firstName
    middleName
    lastName
    fullName
    dateOfBirth
    placeOfBirth
    maritalStatus
    nationalityCode
    sexCode
    language {
      ...languageFragment
    }
    spokenLanguages {
      ...languageFragment
    }
    phone
    addresses {
      ...profileAddressFragment
    }
    checkins {
      ...checkinFragment
    }
    currentCheckin {
      ...checkinFragment
    }
    profileInfo {
      ...profileInfoFragment
    }
    fbProfilePreferences {
      ...fbProfilePreferencesFragment
    }
    permissionContactBookingTroublesViaMail
    permissionContactBookingTroublesViaPhone
    permissionContactBookingTroublesViaEmail
    permissionContactBookingTroublesViaSms
    permissionContactInvoiceTroublesViaMail
    permissionContactInvoiceTroublesViaPhone
    permissionContactInvoiceTroublesViaEmail
    permissionContactInvoiceTroublesViaSms
    permissionContactAdvertisingViaMail
    permissionContactAdvertisingViaPhone
    permissionContactAdvertisingViaEmail
    permissionContactAdvertisingViaSms
  }
  ${USER_FRAGMENT}
  ${PROFILE_ADDRESS_FRAGMENT}
  ${PAYMENT_CARD_FRAGMENT}
  ${PROFILE_ADDRESS_FRAGMENT}
  ${CHECKIN_FRAGMENT}
  ${PROFILE_INFO_FRAGMENT}
  ${FB_PROFILE_PREFERENCES_FRAGMENT}
  ${LANGUAGE_FRAGMENT}
`;

export const GUEST_USER_FRAGMENT = gql`
  fragment guestUserFragment on GuestUserDto {
    id
    uuid
    createdAt
    updatedAt
    firstName
    lastName
    username
    email
    emailConfirmed
    avatar
    phoneNumber
    profile {
      ...profileFragment
    }
  }
  ${PROFILE_FRAGMENT}
`;

export const COMPANY_PROFILE_FRAGMENT = gql`
  fragment companyProfileFragment on CompanyProfileDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    externalRef
    notes
    emails {
      ...profileEmailFragment
    }
    addresses {
      ...profileAddressFragment
    }
    phoneNumbers {
      ...profilePhoneNumberFragment
    }
    name
    companyType
    webSite
    vatNumber
  }
  ${USER_FRAGMENT}
  ${PROFILE_EMAIL_FRAGMENT}
  ${PROFILE_ADDRESS_FRAGMENT}
  ${PROFILE_PHONE_NUMBER_FRAGMENT}
`;

export const RESERVATION_GROUP_ARRIVAL_FRAGMENT = gql`
  fragment reservationGroupArrivalFragment on ReservationGroupArrivalDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    nPax
    arrivedOn
    ownCar
    carBrand
    carColor
    carLicensePlate
    transferType
    transferCompanyName
    transferDriverFullName
    luggageCount
    notes
  }
`;

export const RESERVATION_FRAGMENT = gql`
  fragment reservationFragment on ReservationDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    externalRef
    checkinDate
    checkoutDate
    contact {
      ... on IndividualProfileDto {
        ...profileFragment
      }
      ... on CompanyProfileDto {
        ...companyProfileFragment
      }
    }
    roomType {
      ...roomTypeFragment
    }
    room {
      ...roomFragment
    }
    adultCount
    childCount
    infantCount
    isFullCredit
    separatePayments
  }
  ${USER_FRAGMENT}
  ${ROOM_TYPE_FRAGMENT}
  ${ROOM_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${COMPANY_PROFILE_FRAGMENT}
`;

export const RESERVATION_GROUP_CHECKIN_MODE_FRAGMENT = gql`
  fragment reservationGroupCheckinModeFragment on ReservationGroupCheckinModeDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    contact {
      ... on IndividualProfileDto {
        ...profileFragment
      }
      ... on CompanyProfileDto {
        ...companyProfileFragment
      }
    }
    name
    externalRef
    status
    expectedPaxCount
    reservations {
      ...reservationFragment
    }
    reservationGroupArrivals {
      ...reservationGroupArrivalFragment
    }
  }
  ${USER_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${COMPANY_PROFILE_FRAGMENT}
  ${RESERVATION_FRAGMENT}
  ${RESERVATION_GROUP_ARRIVAL_FRAGMENT}
`;

export const RESERVATION_GROUP_ARRIVAL_MODE_FRAGMENT = gql`
  fragment reservationGroupArrivalModeFragment on ReservationGroupArrivalModeDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    contact {
      ... on IndividualProfileDto {
        ...profileFragment
      }
      ... on CompanyProfileDto {
        ...companyProfileFragment
      }
    }
    name
    externalRef
    status
    arrivedPaxCount
    expectedPaxCount
    reservations {
      ...reservationFragment
    }
    reservationGroupArrivals {
      ...reservationGroupArrivalFragment
    }
  }
  ${USER_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${COMPANY_PROFILE_FRAGMENT}
  ${RESERVATION_FRAGMENT}
  ${RESERVATION_GROUP_ARRIVAL_FRAGMENT}
`;

export const RESERVATION_GROUP_DEPARTURE_MODE_FRAGMENT = gql`
  fragment reservationGroupDepartureModeFragment on ReservationGroupDepartureModeDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    contact {
      ... on IndividualProfileDto {
        ...profileFragment
      }
      ... on CompanyProfileDto {
        ...companyProfileFragment
      }
    }
    name
    externalRef
    status
    reservations {
      ...reservationFragment
    }
    reservationGroupArrivals {
      ...reservationGroupArrivalFragment
    }
  }
  ${USER_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${COMPANY_PROFILE_FRAGMENT}
  ${RESERVATION_FRAGMENT}
  ${RESERVATION_GROUP_ARRIVAL_FRAGMENT}
`;

export const CLUSTER_FRAGMENT = gql`
  fragment clusterFragment on ClusterDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    name
    description
    code
  }
`;

export const STRUCTURE_SMTP_CONFIG_FRAGMENT = gql`
  fragment structureSMTPConfigFragment on StructureSMTPConfig {
    isActive
    service
    host
    port
    secure
    auth {
      user
      pass
    }
  }
`;

export const STRUCTURE_FRAGMENT = gql`
  fragment structureFragment on StructureDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    name
    description
    code
    externalRef
    smtpConfig {
      ...structureSMTPConfigFragment
    }
    website
    logo
    mobileApp
    emailAddresses
    phoneNumber
    latitude
    longitude
  }
  ${USER_FRAGMENT}
  ${STRUCTURE_SMTP_CONFIG_FRAGMENT}
`;

export const STRUCTURE_DEPARTMENT_FRAGMENT = gql`
  fragment structureDepartmentFragment on StructureDepartmentDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    isEnabled
    structure {
      ...structureFragment
    }
  }
  ${USER_FRAGMENT}
  ${STRUCTURE_FRAGMENT}
`;

export const SERVICE_FRAGMENT = gql`
  fragment serviceFragment on ServiceDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    code
    name
    description
    subtitle
    cancellationPolicy
    reservationEmail
    isFree
    images {
      ...imageFragment
    }
    price
    adultsPrice
    kidsPrice
    minPax
    maxPax
    minAge
    maxAge
    adultsAllowed
    kidsAllowed
    infantsAllowed
    disabledAllowed
    showInApp
    showInPublicApp
  }
  ${USER_FRAGMENT}
  ${IMAGE_FRAGMENT}
`;

export const SERVICE_TRANSLATION_FRAGMENT = gql`
  fragment serviceTranslationFragment on ServiceTranslationDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    service {
      ...serviceFragment
    }
    language {
      ...languageFragment
    }
    name
    description
    subtitle
    cancellationPolicy
  }
  ${USER_FRAGMENT}
  ${LANGUAGE_FRAGMENT}
`;

export const GEOLOCATION_TRANSLATIONS_FRAGMENT = gql`
  fragment geolocationTranslationsFragment on GeolocationTranslationsDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    language {
      ...languageFragment
    }
    name
    description
  }
  ${LANGUAGE_FRAGMENT}
`;

export const GEOLOCATION_FRAGMENT = gql`
  fragment geolocationFragment on GeolocationDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    name
    description
    showInApp
    latitude
    longitude
    type
    images {
      ...imageFragment
    }
    externalRef
    services {
      ...serviceFragment
    }
    structure {
      ...structureFragment
    }
    translations {
      ...geolocationTranslationsFragment
    }
  }
  ${USER_FRAGMENT}
  ${SERVICE_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${STRUCTURE_FRAGMENT}
  ${GEOLOCATION_TRANSLATIONS_FRAGMENT}
`;

export const PAGINATED_RESERVATION_GROUP_CHECKIN_MODE_FRAGMENT = gql`
  fragment paginatedReservationGroupCheckinModeFragment on PaginatedReservationGroupCheckinModeDto {
    totalCount
    data {
      ...reservationGroupCheckinModeFragment
    }
  }
  ${RESERVATION_GROUP_CHECKIN_MODE_FRAGMENT}
`;

export const PAGINATED_RESERVATION_GROUP_ARRIVAL_MODE_FRAGMENT = gql`
  fragment paginatedReservationGroupArrivalModeFragment on PaginatedReservationGroupArrivalModeDto {
    totalCount
    data {
      ...reservationGroupArrivalModeFragment
    }
  }
  ${RESERVATION_GROUP_ARRIVAL_MODE_FRAGMENT}
`;

export const PAGINATED_RESERVATION_GROUP_DEPARTURE_MODE_FRAGMENT = gql`
  fragment paginatedReservationGroupDepartureModeFragment on PaginatedReservationGroupDepartureModeDto {
    totalCount
    data {
      ...reservationGroupDepartureModeFragment
    }
  }
  ${RESERVATION_GROUP_DEPARTURE_MODE_FRAGMENT}
`;

export const PAGINATED_GEOLOCATION = gql`
  fragment paginatedGeolocationFragment on PaginatedGeolocationDto {
    totalCount
    data {
      ...geolocationFragment
    }
  }
  ${GEOLOCATION_FRAGMENT}
`;

export const PROFILE_PHYSICAL_CATEGORIES_FRAGMENT = gql`
  fragment profilePhysicalCategoriesFragment on ProfilePhysicalCategoriesDto {
    id
    uuid
    name
    icon
    hasAgeInput
    ageRange
    isEligibleForAvailability
    ageMin
    ageMax
    description
  }
`;

export const FB_SEGMENTS_SERVICES_FRAGMENT = gql`
  fragment fbSegmentsServicesFragment on FbSegmentsServicesDto {
    days
  }
`;

export const FB_SERVICES_FRAGMENT = gql`
  fragment fbServicesFragment on FbServicesDto {
    id
    uuid
    name
    description
    icon
    startTime
    endTime
    isActive
    lastReservation
    slotSizeMinutes
    maxGuestsForSlot
  }
`;

export const FB_ROOMS_FRAGMENT = gql`
  fragment fbRoomsFragment on FbRoomsDto {
    id
    uuid
    name
    isActive
    maxPax
    description
    hasOnlineReservations
  }
`;

export const OUTLET_SMART_SETTINGS_FRAGMENT = gql`
  fragment outletSmartSettingsFragment on OutletSmartSettingsDto {
    deliveryStartTime
    deliveryEndTime
    takeAwayStartTime
    takeAwayEndTime
    deliveryOrderTimeGap
    sharedMaxOrdersForSlot
    sharedSlotSize
    deliveryMaxOrdersForSlot
    deliverySlotSize
    deliveryDays
    maxDeliveryDistance
    takeAwayMaxOrdersForSlot
    takeAwaySlotSize
    takeAwayDays
    smartServiceTermsConditions
    smartServiceCancellationPolicy
    smartServiceServicesDescription
    regulation
  }
`;

export const POINT_FRAGMENT = gql`
  fragment pointFragment on Point {
    type
    coordinates #[ longitude, latitude ]
  }
`;

export const OUTLET_CATEGORIES_FRAGMENT = gql`
  fragment outletCategoriesFragment on OutletCategoriesDto {
    id
    uuid
    createdAt
    updatedAt
    name
    description
    type
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    outlets {
      id
      uuid
      name
    }
  }
  ${USER_FRAGMENT}
`;

export const FB_OUTLETS_FRAGMENT = gql`
  fragment fbOutletsFragment on FbOutletsDto {
    id
    uuid
    uuid
    name
    email
    notificationEmail
    deliveryPrice
    description
    address
    addressNumber
    city
    zipCode
    country
    phoneNumber
    maxPax
    order
    isDeliveryEnabled
    isTakeAwayEnabled
    isSmartServiceEnabled
    maxDaysBeforeReservation
    minimumDeliveryOrderAmount
    isOnlineReservationAccepted
    minPaxForReservation
    maxPaxForReservation
    isReservationEditableByGuest
    fiscalPrinterIpAddress
    useFiscalPrinter
    avoidEditingOrDeleteBeforeMinutes
    images {
      ...imageFragment
    }
    fbRooms {
      ...fbRoomsFragment
    }
    fbServices {
      ...fbServicesFragment
    }
    guestPhysicalCategories {
      ...profilePhysicalCategoriesFragment
    }
    outletCategories {
      ...outletCategoriesFragment
    }
    deliveryPrice
    geolocation {
      ...pointFragment
    }
    smartSettings {
      ...outletSmartSettingsFragment
    }
  }
  ${FB_ROOMS_FRAGMENT}
  ${FB_SERVICES_FRAGMENT}
  ${PROFILE_PHYSICAL_CATEGORIES_FRAGMENT}
  ${POINT_FRAGMENT}
  ${OUTLET_SMART_SETTINGS_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${OUTLET_CATEGORIES_FRAGMENT}
`;

export const RESERVATION_GROUP_FRAGMENT = gql`
  fragment reservationGroupFragment on ReservationGroupDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    name
    externalRef
    contact {
      ... on IndividualProfileDto {
        ...profileFragment
      }
      ... on CompanyProfileDto {
        ...companyProfileFragment
      }
    }
    reservations {
      ...reservationFragment
    }
    reservationGroupArrivals {
      ...reservationGroupArrivalFragment
    }
  }
  ${USER_FRAGMENT}
  ${RESERVATION_FRAGMENT}
  ${RESERVATION_GROUP_ARRIVAL_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${COMPANY_PROFILE_FRAGMENT}
`;

export const DEPARTMENTS_FRAGMENT = gql`
  fragment departmentsFragment on DepartmentsDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    name
    description
    code
  }
  ${USER_FRAGMENT}
`;

export const EMPLOYEE_FRAGMENT = gql`
  fragment employeeFragment on EmployeeDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    firstName
    lastName
    dateOfBirth
    placeOfBirth
    nationalityCode
    sexCode
    phone
    # structureDepartments {
    #   ...structureDepartmentFragment
    # }
    # fbOutlets {
    #   ...fbOutletsFragment
    # }
    # user {
    #   ...userFragment
    # }
  }
  ${USER_FRAGMENT}
  #$#{STRUCTURE_DEPARTMENT_FRAGMENT}
  #$#{FB_OUTLETS_FRAGMENT}
`;

export const TEMPLATE_TRANSLATION_FRAGMENT = gql`
  fragment templateTranslationFragment on TemplateTranslationDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    content
  }
`;

export const TEMPLATE_FRAGMENT = gql`
  fragment templateFragment on TemplateDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    code
    name
    description
    content
    variables
    translations {
      ...templateTranslationFragment
    }
  }
  ${USER_FRAGMENT}
  ${TEMPLATE_TRANSLATION_FRAGMENT}
`;

export const SMART_SERVICES_FRAGMENT = gql`
  fragment smartServicesFragment on SmartServicesDto {
    id
    uuid
    createdAt
    updatedAt
    # createdBy {
    #   ...userFragment
    # }
    # updatedBy {
    #   ...userFragment
    # }
    name
    description
    dateStart
    dateEnd
    isActive
    price
    dailyQuantity
    days
    # fbOutlet {
    #   ...fbOutletsFragment
    # }
  }
  #$#{USER_FRAGMENT}
  #$#{FB_OUTLETS_FRAGMENT}
`;

export const ORDER_SMART_SERVICE_FRAGMENT = gql`
  fragment orderSmartServiceFragment on OrderSmartServiceDto {
    id
    uuid
    createdAt
    updatedAt
    name
    quantity
    price
    smartServiceId
    smartService {
      ...smartServicesFragment
    }
  }
  ${SMART_SERVICES_FRAGMENT}
`;

export const FB_COURSES_MENU_ITEMS_FRAGMENT = gql`
  fragment fbCoursesMenuItemsFragment on FbCoursesMenuItemsDto {
    id
    uuid
    name
    quantity
    price
    fbMenuItemId
    fbChairId
    centerpiece
    halfPortion
    sharedBy
    with
    without
    notes
    fbChair {
      ...fbChairFragment
    }
  }
  ${FB_CHAIR_FRAGMENT}
`;

export const FB_COURSES_FRAGMENT = gql`
  fragment fbCoursesFragment on FbCoursesDto {
    id
    uuid
    type
    isWaiting
    number
    fbCourseStatus
    fbCoursesMenuItems {
      ...fbCoursesMenuItemsFragment
    }
  }
  ${FB_COURSES_MENU_ITEMS_FRAGMENT}
`;

export const FB_ORDERS_FRAGMENT = gql`
  fragment fbOrdersFragment on FbOrdersDto {
    id
    uuid
    createdAt
    updatedAt
    externalOrderId
    clientName
    date
    time
    deliveryTime
    status
    type
    notes
    isSmartOrderServicePaid
    guestRoom
    geolocation {
      ...pointFragment
    }
    checkin {
      ...checkinFragment
    }
    orderSmartServices {
      ...orderSmartServiceFragment
    }
    fbCourses {
      ...fbCoursesFragment
    }
    fbReservationTable {
      id
      fbReservationId
      fbReservation {
        id
        uuid
        reservationTime
        reservationName
        nPax
        bookedBy {
          id
          uuid
          firstname
          lastname
        }
      }
      fbTableId
      fbTable {
        id
        uuid
        number
        # fbRoom {
        #   id
        #   uuid
        #   name
        # }
      }
    }
    fbOutlet {
      ...fbOutletsFragment
    }
    profile {
      ...profileFragment
    }
    driver {
      ...userFragment
    }
  }
  ${ORDER_SMART_SERVICE_FRAGMENT}
  ${PROFILE_FRAGMENT}
  ${FB_COURSES_FRAGMENT}
  ${USER_FRAGMENT}
  ${FB_OUTLETS_FRAGMENT}
  ${POINT_FRAGMENT}
  ${CHECKIN_FRAGMENT}
`;

export const VAT_CODES_FRAGMENT = gql`
  fragment vatCodesFragment on VatCodesDto {
    id
    code
    value
    description
  }
`;

export const FB_MENU_MACRO_CATEGORIES_FRAGMENT = gql`
  fragment fbMenuMacroCategoriesFragment on FbMenuMacroCategoriesDto {
    id
    uuid
    name
    description
  }
`;

export const FB_MENU_ITEMS_FRAGMENT = gql`
  fragment fbMenuItemsFragment on FbMenuItemsDto {
    id
    uuid
    fbMenuCategoryId
    name
    secondaryName
    price
    description
    secondaryDescription
    order
    isHidden
    isStockEnabled
    stockQuantity
    vatCode {
      ...vatCodesFragment
    }
    isDeliveryEnabled
    isTakeAwayEnabled
    imageUrl
    images {
      ...imageFragment
    }
  }
  ${VAT_CODES_FRAGMENT}
  ${IMAGE_FRAGMENT}
`;

export const MENU_ITEM_STOCK_FRAGMENT = gql`
  fragment menuItemStockFragment on MenuItemStockDto {
    id
    uuid
    menuItemId
    quantity
    menuItem {
      ...fbMenuItemsFragment
    }
  }
  ${FB_MENU_ITEMS_FRAGMENT}
`;

export const FB_MENU_CATEGORIES_FRAGMENT = gql`
  fragment fbMenuCategoriesFragment on FbMenuCategoriesDto {
    id
    uuid
    name
    description
    color
    order
    isHidden
    fbMenuMacrocategory {
      ...fbMenuMacroCategoriesFragment
    }
    fbMenuItems {
      ...fbMenuItemsFragment
    }
    images {
      ...imageFragment
    }
  }
  ${IMAGE_FRAGMENT}
  ${FB_MENU_MACRO_CATEGORIES_FRAGMENT}
  ${FB_MENU_ITEMS_FRAGMENT}
`;

export const ERROR_RESPONSE_FRAGMENT = gql`
  fragment errorResponseFragment on ErrorResponse {
    path
    message
  }
`;

export const FB_LOGIN_RESPONSE_FRAGMENT = gql`
  fragment fbLoginResponseFragment on FBLoginResponseDto {
    id
    username
    firstName
    lastName
    email
    access_token
    avatar
    roles {
      ...roleFragment
    }
    outlets {
      ...fbOutletsFragment
    }
    errors {
      ...errorResponseFragment
    }
  }
  ${ROLE_FRAGMENT}
  ${FB_OUTLETS_FRAGMENT}
  ${ERROR_RESPONSE_FRAGMENT}
`;

export const TAG_FRAGMENT = gql`
  fragment tagFragment on TagDto {
    id
    uuid
    createdAt
    updatedAt
    entityName
    fieldName
    tags
    isFreeTagAllowed
  }
`;

export const PROFILE_CATEGORY_FRAGMENT = gql`
  fragment profileCategoryFragment on ProfileCategoryDto {
    id
    uuid
    name
    description
  }
`;

export const GUESTS_MOODS_FRAGMENT = gql`
  fragment guestsMoodsFragment on GuestsMoodsDto {
    mood {
      ...moodsFragment
    }
  }
  ${MOODS_FRAGMENT}
`;

export const GUEST_FRAGMENT = gql`
  fragment guestFragment on Guest {
    id
    uuid
    firstname
    lastname
    company
    vatNumber
    fiscalCode
    gender
    dob
    nationality
    birthplace
    address
    city
    province
    zipCode
    country
    email
    certifiedEmail
    website
    phone
    mobilePhone
    favouriteWine
    favouriteWater
    preferences
    isRepeater
    language {
      ...languageFragment
    }
    guestType {
      ...profileCategoryFragment
    }
    guestsFbAllergens {
      type
    }
    guestMoods {
      ...guestsMoodsFragment
    }
    fbProfilePreferences {
      ...fbProfilePreferencesFragment
    }
    fbReservations {
      id
      guestRoom
    }
  }
  ${LANGUAGE_FRAGMENT}
  ${PROFILE_CATEGORY_FRAGMENT}
  ${GUESTS_MOODS_FRAGMENT}
  ${FB_PROFILE_PREFERENCES_FRAGMENT}
`;

export const PACKAGES_FRAGMENT = gql`
  fragment packagesFragment on Packages {
    id
    uuid
    name
    description
  }
`;

export const CHANNEL_FRAGMENT = gql`
  fragment channelFragment on Channels {
    id
    uuid
    name
    description
  }
`;

export const GUESTS_FB_RESERVATIONS_FRAGMENT = gql`
  fragment guestsFbReservationsFragment on GuestsFbReservationsDto {
    id
    uuid
    guestPhysicalCategoryId
    name
    gender
    age
    notes
    guestPhysicalCategory {
      ...profilePhysicalCategoriesFragment
    }
  }
  ${PROFILE_PHYSICAL_CATEGORIES_FRAGMENT}
`;

export const FB_TABLES_FRAGMENT = gql`
  fragment fbTablesFragment on FbTablesDto {
    id
    uuid
    fbRoomId
    number
    isLiveTable
    description
    shape
    positionX
    positionY
    capacity
    rotate
    tabletop
    isActive
  }
`;

export const FB_RESERVATIONS_TABLES_FRAGMENT = gql`
  fragment fbReservationsTablesFragment on FbReservationTables {
    id
    fbReservationId
    fbTableId
    fbTable {
      ...fbTablesFragment
    }
    fbOrder {
      ...fbOrdersFragment
    }
  }
  ${FB_TABLES_FRAGMENT}
  ${FB_ORDERS_FRAGMENT}
`;

export const FB_RESERVATION_FRAGMENT = gql`
  fragment fbReservationFragment on FbReservation {
    id
    uuid
    date
    arrangement
    reservationNumber
    reservationName
    reservationPhoneNumber
    reservationEmail
    reservationTime
    hasDisabled
    arrivalTime
    requests
    notes
    nPax
    tableNumber
    guestRoom
    status
    createdAt
    createdBy {
      ...userFragment
    }
    updatedAt
    updatedBy {
      ...userFragment
    }
    bookedBy {
      ...guestFragment
    }
    fbService {
      ...fbServicesFragment
      fbOutlet {
        id
        uuid
        name
      }
    }
    package {
      ...packagesFragment
    }
    channel {
      ...channelFragment
    }
    fbGuestList {
      ...guestsFbReservationsFragment
    }
    fbReservationTables {
      ...fbReservationsTablesFragment
    }
    checkin {
      ...checkinFragment
    }
  }
  ${USER_FRAGMENT}
  ${GUEST_FRAGMENT}
  ${FB_SERVICES_FRAGMENT}
  ${PACKAGES_FRAGMENT}
  ${CHANNEL_FRAGMENT}
  ${GUESTS_FB_RESERVATIONS_FRAGMENT}
  ${FB_RESERVATIONS_TABLES_FRAGMENT}
  ${CHECKIN_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const GUEST_APP_PROFILE_FRAGMENT = gql`
  fragment guestAppProfileFragment on GuestAppProfileDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    firstName
    middleName
    lastName
    fullName
    email
    phone
    languageCode
    language {
      ...languageFragment
    }
    spokenLanguages {
      ...languageFragment
    }
    checkins {
      ...checkinFragment
    }
    currentCheckin {
      ...checkinFragment
    }
    guestsFbAllergens {
      type
      fbAllergen {
        id
        uuid
        name
      }
    }
    fbProfilePreferences {
      ...fbProfilePreferencesFragment
    }
  }
  ${USER_FRAGMENT}
  ${CHECKIN_FRAGMENT}
  ${LANGUAGE_FRAGMENT}
  ${FB_PROFILE_PREFERENCES_FRAGMENT}
`;

export const FB_SERVICE_REVENUE_FRAGMENT = gql`
  fragment fbServiceRevenueFragment on FbServiceRevenueDto {
    id
    uuid
    createdAt
    updatedAt
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    date
    totalRevenue
    individualCoversNumber
    groupCoversNumber
    walkInCoversNumber
    complementaryCoversNumber
    notes
    totalCoversNumber
    checkAverage
    fbService {
      ...fbServicesFragment
      fbOutlet {
        ...fbOutletsFragment
      }
    }
  }
  ${USER_FRAGMENT}
  ${FB_SERVICES_FRAGMENT}
  ${FB_OUTLETS_FRAGMENT}
`;
