import React from 'react';
import { Box, Typography, Grid, Button } from '@material-ui/core';
import typy from 'typy';
import style from '../../../../styles/viewTables/guests/guestItem/style';
import addIcon from '../../../../assets/images/table/table_add.svg';
import optionsIcon from '../../../../assets/images/table/table_options.svg';
import { TranslateTextComponent } from '../../../../translator';
import ColoredCircle from '../../../shared/ColoredCircle';

const GuestItem = ({ guest, selectedGuestUUID, onAdd }) => {
  const classes = style();

  return (
    <Box className={classes.container} style={{ marginBottom: 20 }}>
      <Box className={classes.photo}>
        <img src={typy(guest, 'photoUrl').safeString || 'https://via.placeholder.com/77x77/e1e1e1/FFFFFF?text=No%20Picture'} alt="Guest profile" />
      </Box>
      <Box className={classes.name}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <ColoredCircle color={guest.arrivalTime ? '#449D44' : '#CB2D24'} size={8} style={{ marginRight: 5 }} />
          <Typography>
            {typy(guest, 'bookedBy.firstname').safeString} {typy(guest, 'bookedBy.lastname').safeString}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.reservation}>
        <Box>
          <TranslateTextComponent capitalize>reservation-time</TranslateTextComponent>:<span>{guest.reservationTime}</span>
        </Box>
      </Box>
      <Box className={classes.room}>
        <Typography>
          <TranslateTextComponent capitalize>room</TranslateTextComponent>: <span>{guest.guestRoom}</span>
        </Typography>
        <Typography>
          N. Pax.: <span>{typy(guest.fbGuestList).safeArray.length}</span>
        </Typography>
      </Box>
      <Grid className={classes.buttons} container justify="space-between">
        <Button
          className="guest-item-button"
          variant="contained"
          color="primary"
          style={{ backgroundColor: selectedGuestUUID === guest.uuid ? 'rgb(0, 128, 178)' : null }}
          tabIndex="0"
          onClick={() => {
            if (selectedGuestUUID === guest.uuid) onAdd(null);
            else onAdd(guest.uuid);
          }}
        >
          <img src={addIcon} alt="add" />
          <TranslateTextComponent uppercase>add</TranslateTextComponent>
        </Button>
        <Button
          className="guest-item-button"
          variant="contained"
          color="primary"
          tabIndex="0"
          onClick={() => {
            window.location.href = `/edit_reservation/${guest.uuid}`;
          }}
        >
          <img src={optionsIcon} alt="options" />
          <TranslateTextComponent uppercase>details</TranslateTextComponent>
        </Button>
      </Grid>
    </Box>
  );
};

export default GuestItem;
