/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import {
  FB_LOGIN_RESPONSE_FRAGMENT,
  ROLE_FRAGMENT,
  FB_OUTLETS_FRAGMENT,
  STAFF_USER_FRAGMENT,
  GUEST_USER_FRAGMENT,
  CHECKIN_FRAGMENT,
  PROFILE_FRAGMENT,
  MOODS_FRAGMENT,
  TAG_FRAGMENT
} from '../fragments';

export const LOGIN_QUERY = gql`
  query login($loginInput: LoginInputDto!) {
    login(loginInput: $loginInput) {
      ...fbLoginResponseFragment
    }
  }
  ${FB_LOGIN_RESPONSE_FRAGMENT}
`;

export const GET_STAFF_USERS = gql`
  query getStaffUsers(
    $orderBy: String
    $orderType: String
    $page: Int
    $pageSize: Int
    $roles: [RolesEnum!]
    $role: RolesEnum
    $fbOutletUUID: String
    $keyword: String
  ) {
    getStaffUsers(
      orderBy: $orderBy
      orderType: $orderType
      page: $page
      pageSize: $pageSize
      roles: $roles
      role: $role
      fbOutletUUID: $fbOutletUUID
      keyword: $keyword
    ) {
      totalCount
      data {
        ...staffUserFragment
        roles {
          ...roleFragment
        }
        outlets {
          ...fbOutletsFragment
        }
      }
    }
  }
  ${STAFF_USER_FRAGMENT}
  ${ROLE_FRAGMENT}
  ${FB_OUTLETS_FRAGMENT}
`;

export const GET_GUEST_USERS = gql`
  query getGuestUsers($orderBy: String, $orderType: String, $page: Int, $pageSize: Int, $checkinStatus: CheckinStatus, $keyword: String) {
    getGuestUsers(orderBy: $orderBy, orderType: $orderType, page: $page, pageSize: $pageSize, checkinStatus: $checkinStatus, keyword: $keyword) {
      totalCount
      data {
        ...guestUserFragment
      }
    }
  }
  ${GUEST_USER_FRAGMENT}
`;

export const GET_ROLES = gql`
  query getRoles {
    getRoles {
      totalCount
      data {
        ...roleFragment
      }
    }
  }
  ${ROLE_FRAGMENT}
`;

export const GET_CHECKINS = gql`
  query getCheckins(
    $orderBy: String
    $orderType: String
    $page: Int
    $pageSize: Int
    $keyword: String
    $status: CheckinStatus
    $checkinDate: String
    $checkoutDate: String
    $roomNumber: String
    $inHouseOnly: Boolean
  ) {
    getCheckins(
      orderBy: $orderBy
      orderType: $orderType
      page: $page
      pageSize: $pageSize
      keyword: $keyword
      status: $status
      checkinDate: $checkinDate
      checkoutDate: $checkoutDate
      roomNumber: $roomNumber
      inHouseOnly: $inHouseOnly
    ) {
      totalCount
      data {
        ...checkinFragment
      }
    }
  }
  ${CHECKIN_FRAGMENT}
`;

export const GET_PROFILE_DATA = gql`
  query getProfileData($profileUUID: String!) {
    getProfileData(profileUUID: $profileUUID) {
      ...profileFragment
    }
  }
  ${PROFILE_FRAGMENT}
`;

export const GET_MOODS = gql`
  query getMoods {
    getMoods {
      totalCount
      data {
        ...moodsFragment
      }
    }
  }
  ${MOODS_FRAGMENT}
`;

export const GET_TAGS = gql`
  query getTags($page: Int, $pageSize: Int, $entityName: String, $fieldName: String) {
    getTags(page: $page, pageSize: $pageSize, entityName: $entityName, fieldName: $fieldName) {
      totalCount
      data {
        ...tagFragment
      }
    }
  }
  ${TAG_FRAGMENT}
`;

export const FORGOT_PASSWORD_REQUEST = gql`
  query forgotPasswordRequest($email: String!) {
    forgotPasswordRequest(email: $email) {
      id
    }
  }
`;
