import React from 'react';
import cashIcon, { ReactComponent as CashRegister } from '../assets/images/billsManagement/cash_register.svg';
import hotelIcon, { ReactComponent as Hotel } from '../assets/images/billsManagement/hotel.svg';
import pmIcon, { ReactComponent as PM } from '../assets/images/billsManagement/pm.svg';
import cardIcon, { ReactComponent as Card } from '../assets/images/billsManagement/card.svg';
import templeIcon, { ReactComponent as Temple } from '../assets/images/billsManagement/temple.svg';
import { ReactComponent as Clock } from '../assets/images/billsManagement/clock.svg';
import { ReactComponent as HandHoldingUSD } from '../assets/images/billsManagement/hand_holding_usd.svg';
import { ReactComponent as Man } from '../assets/images/billsManagement/man.svg';
import { ReactComponent as Document } from '../assets/images/billsManagement/document.svg';
import { ReactComponent as Clients } from '../assets/images/billsManagement/clients.svg';
import { ReactComponent as Ticket } from '../assets/images/billsManagement/ticket.svg';
import { ReactComponent as InvoicePaper } from '../assets/images/billsManagement/invoice_paper.svg';
import { ReactComponent as Printer } from '../assets/images/billsManagement/printer.svg';
import { ReactComponent as CashBlue } from '../assets/images/billsManagement/cash_blue.svg';
import { ReactComponent as BankCheck } from '../assets/images/billsManagement/bank_check.svg';
import { ReactComponent as BankTransfer } from '../assets/images/billsManagement/bank_transfer.svg';
import { ReactComponent as Mastercard } from '../assets/images/billsManagement/mastercard.svg';
import { ReactComponent as Maestro } from '../assets/images/billsManagement/maestro.svg';
import { ReactComponent as Amex } from '../assets/images/billsManagement/amex.svg';
import { ReactComponent as Visa } from '../assets/images/billsManagement/visa.svg';
import { ReactComponent as ClientBadge } from '../assets/images/billsManagement/client_badge.svg';
import { ReactComponent as HotelBlue } from '../assets/images/billsManagement/hotel_blue.svg';
import { ReactComponent as PMBlue } from '../assets/images/billsManagement/pm_blue.svg';
import { ReactComponent as Vat } from '../assets/images/billsManagement/vat.svg';
import { ReactComponent as MultiplePersons } from '../assets/images/billsManagement/multiple_persons.svg';
import { ReactComponent as OneBluePerson } from '../assets/images/billsManagement/one_blue_person.svg';
import { ReactComponent as PMDuration } from '../assets/images/billsManagement/pm_duration.svg';
import { ReactComponent as OpenList } from '../assets/images/billsManagement/open_list.svg';
import * as payments from '../constants/billsManagement/paymentMethods';

export const getIcon = (iconName) => {
  switch (iconName) {
    case payments.CASH:
      return <CashRegister />;
    case payments.ROOM_CHARGE:
      return <Hotel />;
    case payments.PM_CHARGE:
      return <PM />;
    case payments.BANK_CHECK:
      return <Card />;
    case payments.BANK_TRANSFER:
      return <Temple />;
    // case payments.CASH:
    //   return <Invoice />;
    case 'clock':
      return <Clock />;
    case 'hand':
      return <HandHoldingUSD />;
    case 'man':
      return <Man />;
    case 'document':
      return <Document />;
    case 'clients':
      return <Clients />;
    case 'ticket':
      return <Ticket />;
    case 'bank-transfer':
      return <BankTransfer />;
    case 'bank-check':
      return <BankCheck />;
    case 'invoice-paper':
      return <InvoicePaper />;
    case 'printer':
      return <Printer />;
    case 'cash-blue':
      return <CashBlue />;
    case 'mastercard':
      return <Mastercard />;
    case 'maestro':
      return <Maestro />;
    case 'amex':
      return <Amex />;
    case 'visa':
      return <Visa />;
    case 'client-badge':
      return <ClientBadge />;
    case 'hotel-blue':
      return <HotelBlue />;
    case 'pm-blue':
      return <PMBlue />;
    case 'vat':
      return <Vat />;
    case 'multiple-persons':
      return <MultiplePersons />;
    case 'one-blue-person':
      return <OneBluePerson />;
    case 'pm-duration':
      return <PMDuration />;
    case 'open-list':
      return <OpenList />;
    default:
      return <CashRegister />;
  }
};

export const getPaymentTranslation = (payment) => {
  switch (payment) {
    case payments.CASH:
      return 'cash';
    case payments.CREDIT_CARD:
      return 'credit-cards';
    case payments.ROOM_CHARGE:
      return 'room-charges';
    case payments.PM_CHARGE:
      return 'pm-charges';
    case payments.BANK_CHECK:
      return 'bank-checks';
    case payments.BANK_TRANSFER:
      return 'bank-transfers';
    default:
      return 'cash';
  }
};

export const getPaymentTranslationFromCode = (code) => {
  if (code === payments.CASH) return 'cash-payment';
  else if (code === payments.CREDIT_CARD) return 'credit-card';
  else if (code === payments.BANK_TRANSFER) return 'bank-transfer';
  else if (code === payments.BANK_CHECK) return 'bank-check';
  else if (code === payments.ROOM_CHARGE) return 'room-charge';
  else return 'PM';
};

export const getSVGIconURI = (code) => {
  if (code === payments.CASH) return cashIcon;
  else if (code === payments.CREDIT_CARD) return cardIcon;
  else if (code === payments.BANK_TRANSFER) return templeIcon;
  else if (code === payments.BANK_CHECK) return cardIcon;
  else if (code === payments.ROOM_CHARGE) return hotelIcon;
  else if (code === payments.PM_CHARGE) return pmIcon;
};
