import React from 'react';
import { Box, Button } from '@material-ui/core';
import style from '../../../styles/viewTables/options/style';
import { TranslateTextComponent } from '../../../translator';

const Options = ({ selectedOptionButton, onOptionButtonClick }) => {
  const classes = style();

  return (
    <Box className={classes.container}>
      <Button
        className="guests-option-button"
        style={{ pointerEvents: 'auto', backgroundColor: selectedOptionButton === 'merge-table' ? 'rgb(0, 128, 178)' : null }}
        fullWidth={false}
        variant="contained"
        color="primary"
        onClick={() => {
          if (selectedOptionButton === 'merge-table') onOptionButtonClick(null);
          else onOptionButtonClick('merge-table');
        }}
      >
        <TranslateTextComponent uppercase>merge-table</TranslateTextComponent>
      </Button>
      <Button
        className="guests-option-button"
        style={{ pointerEvents: 'auto', backgroundColor: selectedOptionButton === 'split-table' ? 'rgb(0, 128, 178)' : null }}
        variant="contained"
        color="primary"
        onClick={() => {
          if (selectedOptionButton === 'split-table') onOptionButtonClick(null);
          else onOptionButtonClick('split-table');
        }}
      >
        <TranslateTextComponent uppercase>split-table</TranslateTextComponent>
      </Button>
      <Button
        className="guests-option-button"
        style={{ pointerEvents: 'auto', backgroundColor: selectedOptionButton === 'free-table' ? 'rgb(0, 128, 178)' : null }}
        variant="contained"
        color="primary"
        onClick={() => {
          if (selectedOptionButton === 'free-table') onOptionButtonClick(null);
          else onOptionButtonClick('free-table');
        }}
      >
        <TranslateTextComponent uppercase>free-table</TranslateTextComponent>
      </Button>
      <Button
        className="guests-option-button"
        style={{ pointerEvents: 'auto', backgroundColor: selectedOptionButton === 'transfer-chair' ? 'rgb(0, 128, 178)' : null }}
        variant="contained"
        color="primary"
        onClick={() => {
          if (selectedOptionButton === 'transfer-chair') onOptionButtonClick(null);
          else onOptionButtonClick('transfer-chair');
        }}
      >
        <TranslateTextComponent uppercase>transfer-chair</TranslateTextComponent>
      </Button>
      <Button
        className="guests-option-button"
        style={{ pointerEvents: 'auto', backgroundColor: selectedOptionButton === 'transfer-table' ? 'rgb(0, 128, 178)' : null }}
        variant="contained"
        color="primary"
        onClick={() => {
          if (selectedOptionButton === 'transfer-table') onOptionButtonClick(null);
          else onOptionButtonClick('transfer-table');
        }}
      >
        <TranslateTextComponent uppercase>transfer-table</TranslateTextComponent>
      </Button>
      <Button
        className="guests-option-button"
        style={{ pointerEvents: 'auto', backgroundColor: selectedOptionButton === 'add-chair' ? 'rgb(0, 128, 178)' : null }}
        variant="contained"
        color="primary"
        onClick={() => {
          if (selectedOptionButton === 'add-chair') onOptionButtonClick(null);
          else onOptionButtonClick('add-chair');
        }}
      >
        <TranslateTextComponent uppercase>add-chair</TranslateTextComponent>
      </Button>
      <Button
        className="guests-option-button"
        style={{ pointerEvents: 'auto', backgroundColor: selectedOptionButton === 'free-chair' ? 'rgb(0, 128, 178)' : null }}
        variant="contained"
        color="primary"
        onClick={() => {
          if (selectedOptionButton === 'free-chair') onOptionButtonClick(null);
          else onOptionButtonClick('free-chair');
        }}
      >
        <TranslateTextComponent uppercase>free-chair</TranslateTextComponent>
      </Button>
    </Box>
  );
};

export default Options;
