import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Centerpiece, ChairOrHalfPortion, SharedBy } from '../order/OrderIconComponents';
import { MaterialIcon, redButton } from '../../common-fe/src';
import FB_ORDER_STATUSES from '../../constants/order/orderStatuses';

const IconContainer = styled('div')({
  cursor: 'pointer',
  height: '100%',
  '& > *': {
    height: 'inherit',
  },
});

const GridChairContainer = styled(Grid)({
  cursor: 'pointer',
  marginRight: 5,
  display: 'flex',
  justifyContent: 'space-evenly',
  borderRadius: 5,
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: '#d9d8dc',
  },
});

const ChairsDetail = ({ selectedItem, onChairClick, onDeleteClick }) => {
  const getDeleteIcon = (status, uuid) => {
    if ([FB_ORDER_STATUSES.todo.label, FB_ORDER_STATUSES.none.label].some((stat) => status === stat))
      return <MaterialIcon style={{ cursor: 'pointer', color: redButton }} iconName="Close" onClick={() => onDeleteClick(uuid)} />;
  };

  return (
    <Grid container justify="flex-start">
      {selectedItem.map((fbChair) => (
        <React.Fragment key={fbChair.id}>
          <GridChairContainer item xs={1} onClick={() => onChairClick(fbChair)}>
            <Grid item style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              {fbChair.quantity && <Typography style={{ fontSize: 12, color: '#55595' }}>x{fbChair.quantity}</Typography>}{' '}
            </Grid>
            {fbChair.sharedBy && fbChair.sharedBy.length > 1 ? (
              <IconContainer>
                <SharedBy item={fbChair} />
              </IconContainer>
            ) : (
              <Grid key={fbChair.id} style={{ paddingTop: fbChair.centerpiece ? 0 : 5 }}>
                {fbChair.fbChairId !== null && (
                  <IconContainer>
                    <ChairOrHalfPortion item={fbChair} isHalfPortion={fbChair.halfPortion} chair={fbChair.fbChair} />
                  </IconContainer>
                )}
                {fbChair.centerpiece && (
                  <IconContainer>
                    <Centerpiece item={fbChair} />
                  </IconContainer>
                )}
              </Grid>
            )}
          </GridChairContainer>
          {getDeleteIcon(fbChair.fbCourse.fbCourseStatus, fbChair.uuid)}
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default ChairsDetail;
