/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { Button, IconButton } from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import * as moment from 'moment';
import { TranslateTextComponent } from '../../translator';
import { MaterialIcon } from '../../common-fe';

const getServiceColumns = ({ deleteService, editService }) => {
  return [
    {
      id: 'isActive',
      disablePadding: false,
      width: 50,
      label: <TranslateTextComponent capitalize>state</TranslateTextComponent>,
      isSortable: false,
      render: (item) =>
        item.isActive ? (
          <TranslateTextComponent capitalize>opened</TranslateTextComponent>
        ) : (
          <TranslateTextComponent capitalize>closed</TranslateTextComponent>
        )
    },
    {
      id: 'icon',
      disablePadding: false,
      width: 50,
      label: <TranslateTextComponent capitalize>icon</TranslateTextComponent>,
      isSortable: false,
      render: (item) => <MaterialIcon iconName={item.icon} />
    },
    {
      id: 'name',
      disablePadding: false,
      width: 70,
      label: <TranslateTextComponent capitalize>name</TranslateTextComponent>,
      isSortable: false,
      render: (item) => item.name
    },
    {
      id: 'startTime',
      disablePadding: false,
      width: 60,
      label: <TranslateTextComponent capitalize>start</TranslateTextComponent>,
      isSortable: false,
      render: (item) => (moment(item.startTime).isValid() ? moment(item.startTime).format('HH:mm:ss') : item.startTime)
    },
    {
      id: 'endTime',
      disablePadding: false,
      width: 60,
      label: <TranslateTextComponent capitalize>end</TranslateTextComponent>,
      isSortable: false,
      render: (item) => (moment(item.endTime).isValid() ? moment(item.endTime).format('HH:mm:ss') : item.endTime)
    },
    {
      id: 'lastReservation',
      disablePadding: false,
      width: 60,
      label: <TranslateTextComponent capitalize>last-reservation</TranslateTextComponent>,
      isSortable: false,
      render: (item) => (moment(item.lastReservation).isValid() ? moment(item.lastReservation).format('HH:mm:ss') : item.lastReservation)
    },
    {
      id: 'edit',
      disablePadding: false,
      width: 60,
      label: <TranslateTextComponent capitalize>edit</TranslateTextComponent>,
      isSortable: false,

      render: (item) => (
        <Button color="primary" variant="contained" className="buttonTable" onClick={() => editService(item)}>
          <TranslateTextComponent>open</TranslateTextComponent>
        </Button>
      )
    },
    {
      id: 'delete',
      disablePadding: false,
      width: 40,
      label: <TranslateTextComponent capitalize>delete-icon</TranslateTextComponent>,
      isSortable: false,

      render: (item) => (
        <IconButton className="buttonCircle" onClick={() => deleteService(item)}>
          <DeleteIcon />
        </IconButton>
      )
    }
  ];
};

export default getServiceColumns;
