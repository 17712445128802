import React, { useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const CSVCheckbox = ({ label, onChange, isCheckedDefault }) => {
  const [isChecked, setIsChecked] = useState(isCheckedDefault);
  return (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          name="both"
          color="primary"
          checked={isChecked}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          onChange={() => {
            setIsChecked((prev) => !prev);
            onChange();
          }}
        />
      }
    />
  );
};

export default CSVCheckbox;
