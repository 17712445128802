/* eslint-disable no-unused-expressions */
/* eslint-disable no-loop-func */
import React, { useContext, useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Box, Button } from '@material-ui/core';
import orderBy from 'lodash/orderBy';
import Masonry from 'react-masonry-css';
import { getApolloErrors } from '../../apollo/ApolloProvider';
import { CustomButton, SearchBar } from '../../common-fe';
import modalStyle from '../../common-fe/src/styles/shared/modalStyle';
import MenuContext from '../../contexts/MenuContext';
import { ModalContext } from '../../contexts/ModalContext';
import { DELETE_MENU_CATEGORY } from '../../graphql/menu/mutations';
import { GET_MENU_CATEGORIES } from '../../graphql/menu/queries';
import { GET_VAT_CODES } from '../../graphql/moh/menu/queries';
import useCustomQuery from '../../hooks/useCustomQuery';
import layoutStyle from '../../styles/leftSidebarLayout/leftSidebarLayoutStyle';
import menuStyle from '../../styles/menu/menuStyle';
import { TranslateTextComponent } from '../../translator';
import ItemsCounter from './ItemsCounter';
import MenuItem from './MenuItem';
import MenuList from './MenuList';
import AddCategory from './Modals/AddCategory';
import AddItem from './Modals/AddItem';
import EditCategory from './Modals/EditCategory';
import EditItem from './Modals/EditItem';
import { AuthContext } from '../../contexts/AuthContext';

const Menu = () => {
  const modalContext = useContext(ModalContext);
  const { appOutletUUID } = useContext(AuthContext);

  const [selectedCategoryUUID, setSelectedCategoryUUID] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [searchedItems, setSearchedItems] = useState(null);
  const [searchBarValue, setSearchBarValue] = useState('');
  const [view, setView] = useState('box');
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [showEditItemModal, setShowEditItemModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const classes = menuStyle();
  const layoutClasses = layoutStyle();
  const classesModal = modalStyle();

  const breakpointColumnsObj = {
    default: 2,
    1400: 1
  };

  // Queries
  const getMenuCategories = useCustomQuery(GET_MENU_CATEGORIES, {
    variables: { fbOutletUUID: appOutletUUID },
    onCompleted: (data) => {
      const sortedCategories = orderBy(data?.getFbMenuCategories?.data ?? [], ['order', 'fbMenuMacrocategory.id', 'name']) ?? [];
      if (selectedCategoryUUID) {
        setSelectedCategory(data?.getFbMenuCategories?.data?.find((category) => category.uuid === selectedCategoryUUID));
      } else {
        setSelectedCategoryUUID(sortedCategories?.[0]?.uuid ?? '');
      }
      setCategories(sortedCategories);
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
  const getVatCodes = useCustomQuery(GET_VAT_CODES);

  // Mutations
  const [deleteFbMenuCategory] = useMutation(DELETE_MENU_CATEGORY);

  useEffect(() => {
    setSelectedCategory(getMenuCategories.data?.getFbMenuCategories?.data?.find((category) => category.uuid === selectedCategoryUUID));
    setSearchBarValue('');
  }, [selectedCategoryUUID]);

  useEffect(() => {
    if (searchBarValue?.length > 0) {
      const items = [];
      // eslint-disable-next-line no-unused-expressions
      getMenuCategories.data?.getFbMenuCategories?.data?.forEach((category) => {
        return category?.fbMenuItems?.forEach((item) => {
          if (item?.name?.toLowerCase().includes(searchBarValue?.toLowerCase())) {
            items.push(item);
          }
        });
      });
      setSearchedItems(items);
    } else {
      setSearchedItems(null);
    }
  }, [searchBarValue, getMenuCategories]);

  const getSortedCategories = () => {
    return (
      orderBy(
        getMenuCategories.data?.getFbMenuCategories?.data?.filter((category) => category.uuid === selectedCategoryUUID)?.[0]?.fbMenuItems ?? [],
        [(item) => item.name.toLowerCase()],
        ['asc']
      ) ?? []
    );
  };

  const refetchCategories = () => {
    getMenuCategories.refetch();
  };

  const onCategoryButtonClick = (categoryUUID) => {
    setSelectedCategoryUUID(categoryUUID);
  };

  const openModalDeleteCategory = () => {
    setShowEditItemModal(false);
    setShowAddCategoryModal(false);
    setShowAddItemModal(false);
    setShowEditCategoryModal(false);
    modalContext.openModal({
      class: 'primary',
      text: <TranslateTextComponent capitalize>delete-category-question</TranslateTextComponent>,
      actionButtons: [
        <Button key={1} variant="outlined" color="primary" className={classesModal.invertedButton} onClick={modalContext.closeModal}>
          <TranslateTextComponent>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={2}
          color="primary"
          variant="contained"
          className={classesModal.buttonStyle}
          onClick={() => {
            modalContext.closeModal();
            onDeleteMenuCategory();
          }}
        >
          <TranslateTextComponent>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const onDeleteMenuCategory = () => {
    deleteFbMenuCategory({
      variables: {
        fbMenuCategoryUUID: selectedCategoryUUID
      }
    })
      .then(() => {
        modalContext.openModal({
          class: 'success',
          title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
          text: <TranslateTextComponent capitalize>category-deleted</TranslateTextComponent>
        });

        refetchCategories();
        setSelectedCategoryUUID(null);
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const updateSelectedCategoryUUID = (uuid) => {
    setSelectedCategoryUUID(uuid);
  };

  const onChangeView = (type) => {
    setView(type);
  };

  const openAddItemModal = () => {
    setShowEditItemModal(false);
    setShowAddCategoryModal(false);
    setShowEditCategoryModal(false);
    setShowAddItemModal(true);
  };

  const closeAddItemModal = () => {
    setShowAddItemModal(false);
  };

  const openEditCategoryModal = () => {
    setShowEditItemModal(false);
    setShowAddCategoryModal(false);
    setShowAddItemModal(false);
    setShowEditCategoryModal(true);
  };

  const closeEditCategoryModal = () => {
    setShowEditCategoryModal(false);
  };

  const openEditItemModal = (item) => {
    setSelectedItem(item);
    setShowAddItemModal(false);
    setShowAddCategoryModal(false);
    setShowEditItemModal(true);
  };

  const closeEditItemModal = () => {
    setShowEditItemModal(false);
    setSelectedItem(null);
  };

  const openAddCategoryModal = () => {
    setShowAddItemModal(false);
    setShowEditItemModal(false);
    setShowEditCategoryModal(false);
    setShowAddCategoryModal(true);
  };

  const closeAddCategoryModal = () => {
    setShowAddCategoryModal(false);
  };

  const getSearchedItemsCategoryUUID = (itemUUID) => {
    let categoryUUID;
    for (let i = 0; i < getMenuCategories.data?.getFbMenuCategories?.data?.length; i++) {
      if (!categoryUUID) {
        getMenuCategories.data.getFbMenuCategories.data[i]?.fbMenuItems.forEach((item) => {
          if (item.uuid === itemUUID) {
            categoryUUID = getMenuCategories.data?.getFbMenuCategories?.data?.[i]?.uuid;
          }
        });
      } else break;
    }
    return categoryUUID;
  };

  return (
    <MenuContext.Provider value={{ selectedCategoryUUID, view, showAddItemModal, showAddCategoryModal, showEditItemModal, showEditCategoryModal }}>
      <Box className={layoutClasses.layoutContainer}>
        <Box className={layoutClasses.leftSide}>
          <Box className={classes.leftContainer}>
            <Box style={{ gridArea: 'searchbar' }}>
              <SearchBar
                style={{ padding: 0, marginBottom: 10, height: 40 }}
                value={searchBarValue}
                onClear={() => setSearchBarValue('')}
                onChange={({ target: { value } }) => setSearchBarValue(value)}
              />
            </Box>
            <MenuList
              data={getMenuCategories.data?.getFbMenuCategories?.data?.sort((prev, next) => prev.order - next.order) ?? []}
              selectedCategoryUUID={selectedCategoryUUID}
              categoryButtonClick={onCategoryButtonClick}
            />
            <Box className={classes.addButtonsContainer}>
              <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <CustomButton
                  disabled={!selectedCategoryUUID}
                  translateText
                  style={{ boxShadow: 'unset', height: 50, lineHeight: 1, width: 140 }}
                  onClick={openAddItemModal}
                >
                  <TranslateTextComponent uppercase>new-menu-item</TranslateTextComponent>
                </CustomButton>
                <CustomButton translateText style={{ boxShadow: 'unset', height: 50, lineHeight: 1, width: 140 }} onClick={openAddCategoryModal}>
                  <TranslateTextComponent uppercase>new-menu-category</TranslateTextComponent>
                </CustomButton>
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <CustomButton
                  disabled={!selectedCategoryUUID}
                  translateText
                  style={{ boxShadow: 'unset', height: 50, lineHeight: 1, width: 140 }}
                  onClick={openEditCategoryModal}
                >
                  <TranslateTextComponent uppercase>edit-menu-category</TranslateTextComponent>
                </CustomButton>
                <CustomButton
                  disabled={!selectedCategoryUUID}
                  translateText
                  style={{ boxShadow: 'unset', height: 50, lineHeight: 1, width: 140 }}
                  onClick={openModalDeleteCategory}
                >
                  <TranslateTextComponent uppercase>delete-menu-category</TranslateTextComponent>
                </CustomButton>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={layoutClasses.rightSide}>
          <Box className={classes.rightContainer}>
            <ItemsCounter itemsLength={getSortedCategories().length} openAddItemModal={openAddItemModal} changeView={onChangeView} />
            <Masonry className={classes.dishesContainer} breakpointCols={view === 'box' ? breakpointColumnsObj : 1} columnClassName="">
              {searchedItems
                ? searchedItems
                    ?.sort((prev, next) => prev.order - next.order)
                    .map((item) => {
                      return (
                        <MenuItem
                          key={item.uuid}
                          item={item}
                          categories={categories}
                          categoryUUID={getSearchedItemsCategoryUUID(item.uuid)}
                          openEditItemModal={openEditItemModal}
                          closeEditItemModal={closeEditItemModal}
                          refetchCategories={refetchCategories}
                        />
                      );
                    })
                : getSortedCategories()
                    .sort((prev, next) => prev.order - next.order)
                    .map((item) => {
                      return (
                        <MenuItem
                          key={item.uuid}
                          item={item}
                          categories={categories}
                          categoryUUID={selectedCategoryUUID}
                          openEditItemModal={openEditItemModal}
                          closeEditItemModal={closeEditItemModal}
                          refetchCategories={refetchCategories}
                        />
                      );
                    })}
            </Masonry>
            <AddItem
              selectedCategoryUUID={selectedCategoryUUID}
              categories={categories}
              vatCodes={getVatCodes.data?.getVatCodes?.data}
              nextOrderNumber={
                getMenuCategories.data?.getFbMenuCategories?.data?.find((category) => category.uuid === selectedCategoryUUID)?.fbMenuItems?.length +
                  1 ?? 1
              }
              refetchCategories={refetchCategories}
              closeAddItemModal={closeAddItemModal}
            />
            <EditItem
              item={selectedItem}
              categoryUUID={getSearchedItemsCategoryUUID(selectedItem?.uuid)}
              categories={categories}
              vatCodes={getVatCodes.data?.getVatCodes?.data}
              closeEditItemModal={closeEditItemModal}
              refetchCategories={refetchCategories}
            />
            <AddCategory
              nextOrderNumber={getMenuCategories.data?.getFbMenuCategories?.totalCount + 1 ?? 1}
              closeAddCategoryModal={closeAddCategoryModal}
              refetchCategories={refetchCategories}
              updateSelectedCategoryUUID={(uuid) => updateSelectedCategoryUUID(uuid)}
            />
            <EditCategory
              closeEditCategoryModal={closeEditCategoryModal}
              selectedCategoryUUID={selectedCategoryUUID}
              selectedCategory={selectedCategory}
              refetchCategories={refetchCategories}
            />
          </Box>
        </Box>
      </Box>
    </MenuContext.Provider>
  );
};

export default Menu;
