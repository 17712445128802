import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { TextField, TextareaAutosize, CircularProgress } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputWithLabel from '../../../shared/InputWithLabel';
import { Dropdown, primaryColor, redButton, DataPicker } from '../../../../common-fe/src';
import { SEX_CODES } from '../../../../constants/user/sexCodes';
import Buttons from '../../../shared/Buttons';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { capitalizeFirstLetter, translate } from '../../../../translator';
import { phoneNumberRegex } from '../../../../utils/regex';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  width: 100%;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

const ProfileForm = ({ data, formValues, onFormValuesChange, tags, loading, saveProfileData }) => {
  const { language } = useContext(LanguageContext);

  const validationSchema = Yup.object().shape({
    phone: Yup.string().matches(phoneNumberRegex, translate('invalid-number', language)),
    mobilePhone: Yup.string().matches(phoneNumberRegex, translate('invalid-number', language)),
    email: Yup.string().email(capitalizeFirstLetter(translate('invalid-email', language)))
  });

  const { values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit, handleReset, setFieldValue, getFieldProps } = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: formValues?.firstName || data?.firstName || '',
      lastName: formValues?.lastName || data?.lastName || '',
      fullName: formValues?.fullName || data?.fullName || '',
      sexCode: formValues?.sexCode || data?.sexCode || 'NOT_KNOWN',
      dateOfBirth: formValues?.dateOfBirth || data?.dateOfBirth || null,
      line1: formValues?.line1 || data?.addresses?.[0]?.line1 || '',
      postalCode: formValues?.postalCode || data?.addresses?.[0]?.postalCode || '',
      city: formValues?.city || data?.addresses?.[0]?.city || '',
      province: formValues?.province || data?.addresses?.[0]?.province || '',
      countryCode: formValues?.countryCode || data?.addresses?.[0]?.countryCode || '',
      maritalStatus: formValues?.maritalStatus || data?.maritalStatus || '',
      phone: formValues?.phone || data?.phone || '',
      mobilePhone: formValues?.mobilePhone || data?.mobilePhone || '',
      email: formValues?.email || data?.email || '',
      otherNotes: formValues?.otherNotes || data?.otherNotes || '',
      notes: formValues?.notes || data?.notes || '',
      partnerInfo: formValues?.partnerInfo || data?.profileInfo?.partnerInfo || '',
      childrenInfo: formValues?.childrenInfo || data?.profileInfo?.childrenInfo || ''
    },
    validationSchema,
    onSubmit: (_, { setSubmitting }) => {
      saveProfileData(formValues);
      setSubmitting(false);
    }
  });

  useEffect(() => {
    onFormValuesChange({ ...getFieldProps().value });
  }, [values]);

  return (
    <Container>
      <RowContainer>
        <InputWithLabel label="first-name" error={touched.firstName && errors.firstName} containerStyle={{ flex: 0.35 }}>
          <TextField
            name="firstName"
            fullWidth
            variant="outlined"
            color="primary"
            value={values.firstName}
            error={touched.firstName && !!errors.firstName}
            InputProps={{ endAdornment: loading ? <CircularProgress style={{ width: 20, height: 20, marginRight: 10 }} /> : null }}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
        <InputWithLabel label="last-name" error={touched.lastName && errors.lastName} containerStyle={{ flex: 0.35 }}>
          <TextField
            name="lastName"
            fullWidth
            variant="outlined"
            color="primary"
            value={values.lastName}
            error={touched.lastName && !!errors.lastName}
            InputProps={{ endAdornment: loading ? <CircularProgress style={{ width: 20, height: 20, marginRight: 10 }} /> : null }}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
        <InputWithLabel label="full-name" error={touched.fullName && errors.fullName} containerStyle={{ flex: 0.35 }}>
          <TextField
            name="fullName"
            fullWidth
            variant="outlined"
            color="primary"
            value={values.fullName}
            error={touched.fullName && !!errors.fullName}
            InputProps={{ endAdornment: loading ? <CircularProgress style={{ width: 20, height: 20, marginRight: 10 }} /> : null }}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
        <InputWithLabel label="sex" error={touched.sexCode && errors.sexCode} containerStyle={{ flex: 0.1 }}>
          <Dropdown
            name="sexCode"
            items={Object.keys(SEX_CODES).map((key) => ({
              label: capitalizeFirstLetter(translate(SEX_CODES[key]?.toLowerCase()?.replace(/_/g, '-'), language)),
              value: SEX_CODES[key]
            }))}
            value={values.sexCode}
            dropdownStyle={{ width: '100%', minHeight: 40 }}
            onChange={handleChange}
          />
        </InputWithLabel>
        <InputWithLabel label="date-of-birth" error={touched.dateOfBirth && errors.dateOfBirth} containerStyle={{ flex: 0.2 }}>
          <DataPicker
            fullWidth
            error={touched.dateOfBirth && !!errors.dateOfBirth}
            value={values.dateOfBirth}
            onChange={(value) => setFieldValue('dateOfBirth', value)}
          />
        </InputWithLabel>
      </RowContainer>
      <RowContainer>
        <InputWithLabel label="address" error={touched.line1 && errors.line1} containerStyle={{ flex: 0.7 }}>
          <TextField
            name="line1"
            fullWidth
            variant="outlined"
            color="primary"
            value={values.line1}
            error={touched.line1 && !!errors.line1}
            InputProps={{ endAdornment: loading ? <CircularProgress style={{ width: 20, height: 20, marginRight: 10 }} /> : null }}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
        <InputWithLabel label="zip-code" error={touched.postalCode && errors.postalCode} containerStyle={{ flex: 0.1 }}>
          <TextField
            name="postalCode"
            fullWidth
            variant="outlined"
            color="primary"
            value={values.postalCode}
            error={touched.postalCode && !!errors.postalCode}
            InputProps={{ endAdornment: loading ? <CircularProgress style={{ width: 20, height: 20, marginRight: 10 }} /> : null }}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
        <InputWithLabel label="city" error={touched.city && errors.city} containerStyle={{ flex: 0.2 }}>
          <TextField
            name="city"
            fullWidth
            variant="outlined"
            color="primary"
            value={values.city}
            error={touched.city && !!errors.city}
            InputProps={{ endAdornment: loading ? <CircularProgress style={{ width: 20, height: 20, marginRight: 10 }} /> : null }}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
      </RowContainer>
      <RowContainer>
        <InputWithLabel label="province" error={touched.province && errors.province}>
          <TextField
            name="province"
            fullWidth
            variant="outlined"
            color="primary"
            value={values.province}
            error={touched.province && !!errors.province}
            InputProps={{ endAdornment: loading ? <CircularProgress style={{ width: 20, height: 20, marginRight: 10 }} /> : null }}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
        <InputWithLabel label="country" error={touched.countryCode && errors.countryCode}>
          <TextField
            name="countryCode"
            fullWidth
            variant="outlined"
            color="primary"
            value={values.countryCode}
            error={touched.countryCode && !!errors.countryCode}
            InputProps={{ endAdornment: loading ? <CircularProgress style={{ width: 20, height: 20, marginRight: 10 }} /> : null }}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
        <InputWithLabel label="marital-status" error={touched.maritalStatus && errors.maritalStatus}>
          <Dropdown
            name="maritalStatus"
            items={
              tags
                ?.find?.((tag) => tag.fieldName === 'maritalStatus')
                ?.tags?.map((tag) => ({ label: capitalizeFirstLetter(translate(tag, language)), value: tag })) ?? []
            }
            value={values.maritalStatus}
            error={touched.maritalStatus && !!errors.maritalStatus}
            dropdownStyle={{ minHeight: 40, width: '100%' }}
            onChange={handleChange}
          />
        </InputWithLabel>
      </RowContainer>
      <RowContainer>
        <InputWithLabel label="phone" error={touched.phone && errors.phone}>
          <TextField
            name="phone"
            fullWidth
            variant="outlined"
            color="primary"
            value={values.phone}
            error={touched.phone && !!errors.phone}
            InputProps={{ endAdornment: loading ? <CircularProgress style={{ width: 20, height: 20, marginRight: 10 }} /> : null }}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
        <InputWithLabel label="mobile-phone" error={touched.mobilePhone && errors.mobilePhone}>
          <TextField
            name="mobilePhone"
            fullWidth
            variant="outlined"
            color="primary"
            value={values.mobilePhone}
            error={touched.mobilePhone && !!errors.mobilePhone}
            InputProps={{ endAdornment: loading ? <CircularProgress style={{ width: 20, height: 20, marginRight: 10 }} /> : null }}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
        <InputWithLabel label="email" error={touched.email && errors.email}>
          <TextField
            name="email"
            fullWidth
            variant="outlined"
            color="primary"
            value={values.email}
            error={touched.email && !!errors.email}
            InputProps={{ endAdornment: loading ? <CircularProgress style={{ width: 20, height: 20, marginRight: 10 }} /> : null }}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
      </RowContainer>
      <RowContainer>
        <InputWithLabel label="notes" error={touched.notes && errors.notes}>
          <TextareaAutosize
            name="notes"
            color="primary"
            style={{
              fontFamily: 'roboto',
              width: '100%',
              minHeight: 110,
              padding: 10,
              resize: 'vertical',
              border: `1px solid ${primaryColor}`,
              borderRadius: 4
            }}
            value={values.notes}
            onChange={handleChange}
          />
        </InputWithLabel>
        <InputWithLabel label="other-notes" error={touched.otherNotes && errors.otherNotes}>
          <TextareaAutosize
            name="otherNotes"
            color="primary"
            style={{
              fontFamily: 'roboto',
              width: '100%',
              minHeight: 110,
              padding: 10,
              resize: 'vertical',
              border: `1px solid ${primaryColor}`,
              borderRadius: 4
            }}
            value={values.otherNotes}
            onChange={handleChange}
          />
        </InputWithLabel>
        <InputWithLabel label="partner-first-name-last-name-city-dob" error={touched.partnerInfo && errors.partnerInfo}>
          <TextareaAutosize
            name="partnerInfo"
            color="primary"
            style={{
              fontFamily: 'roboto',
              width: '100%',
              minHeight: 110,
              padding: 10,
              resize: 'vertical',
              border: `1px solid ${primaryColor}`,
              borderRadius: 4
            }}
            value={values.partnerInfo}
            onChange={handleChange}
          />
        </InputWithLabel>
        <InputWithLabel label="children-name-first-name-last-name-city-dob" error={touched.childrenInfo && errors.childrenInfo}>
          <TextareaAutosize
            name="childrenInfo"
            color="primary"
            style={{
              fontFamily: 'roboto',
              width: '100%',
              minHeight: 110,
              padding: 10,
              resize: 'vertical',
              border: `1px solid ${primaryColor}`,
              borderRadius: 4
            }}
            value={values.childrenInfo}
            onChange={handleChange}
          />
        </InputWithLabel>
      </RowContainer>
      <Buttons
        buttons={[
          {
            buttonType: 'button',
            type: 'cancel',
            label: 'delete',
            style: {
              padding: 0,
              marginLeft: 80,
              backgroundColor: 'transparent',
              border: 'none',
              color: redButton,
              textDecoration: 'underline',
              minHeight: 50,
              fontSize: 20,
              fontWeight: 'medium',
              textTransform: 'uppercase'
            },
            onClick: handleReset
          },
          {
            buttonType: 'button',
            type: 'confirm',
            label: 'confirm',
            disabled: isSubmitting,
            style: { minHeight: 50, minWidth: 227, fontSize: 20, fontWeight: 'medium', textTransform: 'uppercase' },
            onClick: handleSubmit
          }
        ]}
      />
    </Container>
  );
};

export default ProfileForm;
