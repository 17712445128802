import gql from 'graphql-tag';
import { FbBillFragment, FbBillRowFragment } from '../cash/queries';
import { FB_OUTLETS_FRAGMENT } from '../fragments';

const getFbClosureFragment = (type = 'Daily') => gql`
  fragment Fb${type}ClosureFragment on Fb${type}ClosuresDto {
    id
    uuid
    date
    datetime
    documentNumber
    totalPaxAmount
    totalAmount
    totalDiscountAmount
    totalCashAmount
    totalCashNumber
    totalCardAmount
    totalCardNumber
    totalCardVisaAmount
    totalCardVisaNumber
    totalCardPagobancomatAmount
    totalCardPagobancomatNumber
    totalCardMaestroAmount
    totalCardMaestroNumber
    totalCardAmexAmount
    totalCardAmexNumber
    totalCardMastercardAmount
    totalCardMastercardNumber
    totalBankTransferAmount
    totalBankTransferNumber
    totalBankCheckAmount
    totalBankCheckNumber
    totalCustomerTypeAmount
    totalCustomerTypeNumber
    totalPMAmount
    totalPMNumber
    totalRoomChargeAmount
    totalRoomChargeNumber
    totalInvoiceAmount
    totalInvoiceNumber
    totalTicketAmount
    totalTicketNumber
  }
`;

export const GET_PAYMENT_METHODS = gql`
  query getFbPaymentMethods {
    getFbPaymentMethods {
      data {
        id
        uuid
        name
        code
        description
      }
    }
  }
`;

export const GET_FB_BILLS = gql`
  query getFbBills(
    $fbOutletUUID: String!
    $userUUID: String
    $paymentMethodCodes: [FbPaymentMethodsEnum!]
    $documentNumber: String
    $date: String
    $month: String
    $year: String
    $userKeyword: String
    $keyword: String
    $page: Int
    $pageSize: Int
  ) {
    getFbBills(
      fbOutletUUID: $fbOutletUUID
      userUUID: $userUUID
      paymentMethodCodes: $paymentMethodCodes
      documentNumber: $documentNumber
      date: $date
      month: $month
      year: $year
      userKeyword: $userKeyword
      keyword: $keyword
      page: $page
      pageSize: $pageSize
    ) {
      totalCount
      data {
        ...FbBillFragment
        fbPaymentMethod {
          id
          uuid
          name
          code
          description
        }
        # createdBy {
        #   id
        #   firstName
        #   lastName
        #   username
        #   email
        # }
        fbBillsRows {
          ...FbBillRowFragment
        }
        fbBillDocuments {
          id
          uuid
          type
          name
          path
          url
          description
          images {
            id
            uuid
            path
          }
        }
        fbOrder {
          id
          fbReservationTable {
            id
            fbTable {
              id
              number
            }
            fbReservation {
              id
              reservationTime
              nPax
              bookedBy {
                id
                firstname
                lastname
              }
            }
          }
        }
      }
    }
  }
  ${FbBillFragment}
  ${FbBillRowFragment}
`;

export const GET_FB_DAILY_CLOSURES = gql`
  query getFbDailyClosures(
    $fbOutletUUID: String!
    $date: String
    $month: String
    $year: String
    $orderBy: String
    $orderType: String
    $page: Int
    $pageSize: Int
  ) {
    getFbDailyClosures(
      fbOutletUUID: $fbOutletUUID
      date: $date
      month: $month
      year: $year
      orderBy: $orderBy
      orderType: $orderType
      page: $page
      pageSize: $pageSize
    ) {
      totalCount
      data {
        ...FbDailyClosureFragment
        fbOutlet {
          ...fbOutletsFragment
        }
        createdBy {
          id
          uuid
          firstName
          lastName
          username
          email
        }
      }
    }
  }
  ${getFbClosureFragment('Daily')}
  ${FB_OUTLETS_FRAGMENT}
`;

export const GET_FB_ACCOUNTING_CLOSURES = gql`
  query getFbAccountingClosures(
    $fbOutletUUID: String!
    $date: String
    $month: String
    $year: String
    $orderBy: String
    $orderType: String
    $page: Int
    $pageSize: Int
  ) {
    getFbAccountingClosures(
      fbOutletUUID: $fbOutletUUID
      date: $date
      month: $month
      year: $year
      orderBy: $orderBy
      orderType: $orderType
      page: $page
      pageSize: $pageSize
    ) {
      totalCount
      data {
        ...FbAccountingClosureFragment
        fbOutlet {
          ...fbOutletsFragment
        }
        createdBy {
          id
          uuid
          firstName
          lastName
          username
          email
        }
      }
    }
  }
  ${getFbClosureFragment('Accounting')}
  ${FB_OUTLETS_FRAGMENT}
`;

export const GET_FB_BILLS_TOTAL = gql`
  query getFbBillsTotal(
    $fbOutletUUID: String!
    $userUUID: String
    $paymentMethodCodes: [FbPaymentMethodsEnum!]
    $documentNumber: String
    $status: FbBillsStatuses
    $date: String
    $month: String
    $year: String
    $userKeyword: String
    $keyword: String
  ) {
    getFbBillsTotal(
      fbOutletUUID: $fbOutletUUID
      userUUID: $userUUID
      paymentMethodCodes: $paymentMethodCodes
      documentNumber: $documentNumber
      status: $status
      date: $date
      month: $month
      year: $year
      userKeyword: $userKeyword
      keyword: $keyword
    ) {
      sum
    }
  }
`;
