import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from '../../../common-fe/src';

const styles = {
  container: {
    gridArea: 'tables-container',
    height: 'calc(100vh - 64px)',
    paddingTop: 50
  },
  gridMsgBox: {
    marginLeft: 10,
    marginTop: 10,
    position: 'absolute',
    top: 130,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 276,
    borderBottom: '4px solid orange',
    boxShadow: '0px 3px 6px #00000029',
    backgroundColor: 'white',
    zIndex: 10
  },
  secondGridMsgBox: {
    marginLeft: 10,
    marginTop: 10,
    position: 'absolute',
    top: 130,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 277,
    borderBottom: `4px solid ${primaryColor}`,
    boxShadow: `0px 3px 6px  #00000029`
  },
  exclamationPoint: {
    paddingLeft: 12,
    fontSize: 30,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    color: '#F7A445'
  },
  SecExclamationPoint: {
    paddingLeft: 12,
    fontSize: 30,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    color: primaryColor
  },
  selectFirstTxt: {
    paddingLeft: 12,
    fontSize: 15,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    color: '#777777'
  },
  secondChair: {
    paddingLeft: 5,
    fontSize: 15,
    fontFamily: 'Roboto',
    color: '#777777',
    paddingRight: 12
  }
};

export default makeStyles(styles);
