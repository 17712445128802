import { makeStyles } from '@material-ui/core/styles';

const styles = {
  appBar: () => ({
    padding: 0
  }),
  textTitle: () => ({
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '50px',
    textAlign: 'left',
    paddingLeft: 27
  }),
  iconSize: () => ({
    height: '40px',
    width: '40px'
  }),
  nameText: () => ({
    color: 'white',
    textTransform: 'capitalize',
    fontSize: 14
  }),
  userContainer: () => ({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderLeft: '1px solid white',
    width: 200,
    position: 'relative'
  }),
  userInfoContainer: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  }),
  languageContainer: () => ({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderLeft: '1px solid white',
    width: 100,
    position: 'relative'
  }),
  languageItems: () => ({
    position: 'absolute',
    boxShadow: '0px 3px 20px #00000059',
    width: 100,
    top: 45,
    backgroundColor: 'white',
    zIndex: 1,
    left: '-1.3px'
  }),
  menuItems: () => ({
    position: 'absolute',
    boxShadow: '0px 3px 20px #00000059',
    width: 200,
    top: 45,
    backgroundColor: 'white',
    zIndex: 1,
    left: '-1.3px'
  }),
  keyArrowStyle: () => ({
    color: 'white'
  }),
  homeTypography: () => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: 21
  }),
  sectionTypography: () => ({
    paddingLeft: 10,
    fontWeight: 'bold',
    fontSize: 29
  })
};

export default makeStyles(styles);
