import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { TextareaAutosize, TextField, Button } from '@material-ui/core/';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import omit from 'lodash/omit';
import { primaryColor, MaterialIcon } from '../../../common-fe/src';
import { TranslateTextComponent } from '../../../translator';
import Buttons from '../../shared/Buttons';
import ImageUpload from '../../shared/ImageUpload';
import InputWithLabel from '../../shared/InputWithLabel';
import Switch from '../../shared/Switch';
import useCustomQuery from '../../../hooks/useCustomQuery';
import { GET_GUEST_PHYSICAL_CATEGORIES } from '../../../graphql/reservations/queries';
import { GET_FB_OUTLET } from '../../../graphql/outlets/queries';
import { EDIT_FB_OUTLET } from '../../../graphql/outlets/mutations';
import { AuthContext } from '../../../contexts/AuthContext';
import { ModalContext } from '../../../contexts/ModalContext';
import modalStyle from '../../../styles/shared/modalStyle';
import { getApolloErrors } from '../../../apollo/ApolloProvider';

const Container = styled.form`
  display: flex;
  flex-flow: column;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

const RowContainer = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 20px;
  }

  & > div:not(:last-child) {
    margin-bottom: ${({ withoutMarginBottom }) => (!withoutMarginBottom ? '20px' : '0px')};
  }
`;

const validationSchema = Yup.object().shape({
  name: Yup.string(),
  address: Yup.string(),
  addressNumber: Yup.number().typeError('Invalid address number'),
  description: Yup.string(),
  city: Yup.string(),
  latitude: Yup.number()
    .typeError('Invalid latitude')
    .test('Latitude validation', 'Invalid latitude', (value) => value >= -90 && value <= 90),
  longitude: Yup.number()
    .typeError('Invalid longitude')
    .test('Longitude validation', 'Invalid longitude', (value) => value >= -180 && value <= 180),
  country: Yup.string(),
  email: Yup.string().email('Invalid email'),
  zipCode: Yup.number().typeError('Invalid zip code'),
  maxPax: Yup.number().typeError('Invalid number'),
  order: Yup.number().typeError('Invalid number'),
  phoneNumber: Yup.number().typeError('Invalid phone number')
});

const Generals = () => {
  const modalClasses = modalStyle();
  const { appOutletUUID } = useContext(AuthContext);
  const modalContext = useContext(ModalContext);

  // Queries
  const getGuestPhysicalCategories = useCustomQuery(GET_GUEST_PHYSICAL_CATEGORIES, { variables: { page: 1 } });
  const getOutlet = useCustomQuery(GET_FB_OUTLET, { variables: { fbOutletUUID: appOutletUUID } });

  // Mutations
  const [editOutlet] = useMutation(EDIT_FB_OUTLET);

  const { values, errors, touched, isSubmitting, setFieldValue, handleBlur, handleChange, handleReset, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: getOutlet.data?.getFbOutlet?.name ?? '',
      photo: { path: getOutlet.data?.getFbOutlet?.images?.[0]?.path ?? '', base64image: null },
      address: getOutlet.data?.getFbOutlet?.address ?? '',
      addressNumber: getOutlet.data?.getFbOutlet?.addressNumber ?? '',
      description: getOutlet.data?.getFbOutlet?.description ?? '',
      city: getOutlet.data?.getFbOutlet?.city ?? '',
      latitude: getOutlet.data?.getFbOutlet?.geolocation?.coordinates?.[1] ?? '',
      longitude: getOutlet.data?.getFbOutlet?.geolocation?.coordinates?.[0] ?? '',
      country: getOutlet.data?.getFbOutlet?.country ?? '',
      email: getOutlet.data?.getFbOutlet?.email ?? '',
      zipCode: getOutlet.data?.getFbOutlet?.zipCode ?? '',
      maxPax: getOutlet.data?.getFbOutlet?.maxPax ?? '',
      order: getOutlet.data?.getFbOutlet?.order ?? '',
      phoneNumber: getOutlet.data?.getFbOutlet?.phoneNumber ?? '',
      guestPhysicalCategories: getOutlet.data?.getFbOutlet?.guestPhysicalCategories?.map((category) => category.id) ?? []
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      let fbOutletData = {};
      if (values.photo.base64image) {
        fbOutletData = {
          ...omit(values, ['photo', 'latitude', 'longitude']),
          maxPax: parseFloat(values.maxPax, 10),
          images: [{ base64image: values.photo.base64image, isPrimary: true }],
          geolocation: { coordinates: [parseFloat(values.longitude, 10), parseFloat(values.latitude, 10)] },
          order: Number(values.order)
        };
      } else if (!values.photo.path) {
        fbOutletData = {
          ...omit(values, ['photo', 'latitude', 'longitude']),
          maxPax: parseFloat(values.maxPax, 10),
          images: [],
          geolocation: { coordinates: [parseFloat(values.longitude, 10), parseFloat(values.latitude, 10)] },
          order: Number(values.order)
        };
      } else {
        fbOutletData = {
          ...omit(values, ['photo', 'latitude', 'longitude']),
          maxPax: parseFloat(values.maxPax, 10),
          geolocation: { coordinates: [parseFloat(values.longitude, 10), parseFloat(values.latitude, 10)] },
          order: Number(values.order)
        };
      }

      editOutlet({
        refetchQueries: [{ query: GET_FB_OUTLET, variables: { fbOutletUUID: appOutletUUID } }],
        variables: {
          fbOutletUUID: appOutletUUID,
          fbOutletData
        }
      })
        .then(() => {
          modalContext.openModal({
            class: 'success',
            title: <TranslateTextComponent capitalize>outlet-edit-success</TranslateTextComponent>,
            text: <TranslateTextComponent capitalize>outlet-edit-success-description</TranslateTextComponent>,
            actionButtons: [
              <Button
                key={0}
                variant="contained"
                color="primary"
                className={modalClasses.invertedButton}
                onClick={() => {
                  modalContext.closeModal();
                }}
              >
                <TranslateTextComponent uppercase>ok</TranslateTextComponent>
              </Button>
            ]
          });
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        });
      setSubmitting(false);
    }
  });

  return (
    <Container>
      <RowContainer>
        <ColumnContainer>
          <RowContainer withoutMarginBottom>
            <ColumnContainer>
              <InputWithLabel label="name" error={touched.name && errors.name} withMarginBottom>
                <TextField
                  name="name"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  value={values.name}
                  error={touched.name && !!errors.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </InputWithLabel>
              <InputWithLabel label="email" error={touched.email && errors.email} withMarginBottom>
                <TextField
                  name="email"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  value={values.email}
                  error={touched.email && !!errors.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </InputWithLabel>
              <InputWithLabel label="tel" error={touched.phoneNumber && errors.phoneNumber}>
                <TextField
                  name="phoneNumber"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  value={values.phoneNumber}
                  error={touched.phoneNumber && !!errors.phoneNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </InputWithLabel>
            </ColumnContainer>
            <InputWithLabel label="photo">
              <ImageUpload
                containerStyle={{ width: '100%', height: '100%' }}
                uploadContainerStyle={{ width: '100%', height: '100%' }}
                currentImage={values.photo.path}
                onImageDrop={(value) => setFieldValue('photo', { path: '', base64image: value })}
                onImageReset={() => setFieldValue('photo', { path: '', base64image: null })}
              />
            </InputWithLabel>
          </RowContainer>
        </ColumnContainer>
        <ColumnContainer>
          <InputWithLabel label="description" containerStyle={{ height: '100%' }} error={touched.description && errors.description}>
            <TextareaAutosize
              name="description"
              style={{
                padding: 10,
                fontFamily: 'Roboto',
                fontSize: 14,
                height: '100%',
                width: '100%',
                border: `1px solid ${primaryColor}`,
                borderRadius: 4,
                marginBottom: 20
              }}
              value={values.description}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
        </ColumnContainer>
      </RowContainer>
      <RowContainer>
        <ColumnContainer>
          <InputWithLabel label="address" error={touched.address && errors.address}>
            <TextField
              name="address"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.address}
              error={touched.address && !!errors.address}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
        </ColumnContainer>
        <ColumnContainer>
          <RowContainer>
            <InputWithLabel label="latitude" error={touched.latitude && errors.latitude}>
              <TextField
                name="latitude"
                fullWidth
                variant="outlined"
                color="primary"
                value={values.latitude}
                error={touched.latitude && !!errors.latitude}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputWithLabel>
            <InputWithLabel label="longitude" error={touched.longitude && errors.longitude}>
              <TextField
                name="longitude"
                fullWidth
                variant="outlined"
                color="primary"
                value={values.longitude}
                error={touched.longitude && !!errors.longitude}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputWithLabel>
          </RowContainer>
        </ColumnContainer>
      </RowContainer>
      <RowContainer>
        <InputWithLabel label="address-number" error={touched.addressNumber && errors.addressNumber}>
          <TextField
            name="addressNumber"
            fullWidth
            variant="outlined"
            color="primary"
            value={values.addressNumber}
            error={touched.addressNumber && !!errors.addressNumber}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
        <InputWithLabel label="country" error={touched.country && errors.country}>
          <TextField
            name="country"
            fullWidth
            variant="outlined"
            color="primary"
            value={values.country}
            error={touched.country && !!errors.country}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
      </RowContainer>
      <RowContainer>
        <InputWithLabel label="city" error={touched.city && errors.city}>
          <TextField
            name="city"
            fullWidth
            variant="outlined"
            color="primary"
            value={values.city}
            error={touched.city && !!errors.city}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
        <InputWithLabel label="zip-code" error={touched.zipCode && errors.zipCode}>
          <TextField
            name="zipCode"
            fullWidth
            variant="outlined"
            color="primary"
            value={values.zipCode}
            error={touched.zipCode && !!errors.zipCode}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
      </RowContainer>
      <RowContainer>
        <InputWithLabel label="total-max-pax" error={touched.maxPax && errors.maxPax}>
          <TextField
            name="maxPax"
            fullWidth
            variant="outlined"
            color="primary"
            value={values.maxPax}
            error={touched.maxPax && !!errors.maxPax}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
        <InputWithLabel label="allowed-guests">
          <RowContainer style={{ alignItems: 'flex-start' }}>
            {getGuestPhysicalCategories.data?.getGuestPhysicalCategories?.data.map((category) => (
              <Switch
                key={category.uuid}
                leftLabel="no"
                rightLabel="yes"
                value={values.guestPhysicalCategories.includes(category.id)}
                leftComponent={
                  <RowContainer style={{ alignItems: 'center' }}>
                    <MaterialIcon style={{ marginRight: 5 }} iconName={category.icon} />
                    <ColumnContainer>
                      <TranslateTextComponent capitalize style={{ fontWeight: 400, fontSize: 14, color: '#55595C' }}>
                        {category.name}
                      </TranslateTextComponent>
                      <TranslateTextComponent capitalize style={{ fontSize: 10, color: '#939393' }} vars={{ years: category.ageRange }}>
                        generic-years
                      </TranslateTextComponent>
                    </ColumnContainer>
                  </RowContainer>
                }
                onChange={() => {
                  const newCategories = [...values.guestPhysicalCategories];
                  if (values.guestPhysicalCategories.includes(category.id)) {
                    newCategories.splice(newCategories.indexOf(category.id), 1);
                  } else {
                    newCategories.push(category.id);
                  }
                  setFieldValue('guestPhysicalCategories', newCategories);
                }}
              />
            ))}
          </RowContainer>
        </InputWithLabel>
      </RowContainer>

      <RowContainer style={{ marginBottom: 20 }}>
        <InputWithLabel label="order" error={touched.order && errors.order}>
          <TextField
            name="order"
            fullWidth
            variant="outlined"
            color="primary"
            value={values.order}
            error={touched.order && !!errors.order}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
      </RowContainer>

      <Buttons
        containerStyle={{ justifyContent: 'space-between' }}
        buttons={[
          { buttonType: 'reset', style: { padding: '5px 10px', minWidth: 155, fontSize: 20, fontWeight: 600 }, type: 'cancel', onClick: handleReset },
          {
            buttonType: 'submit',
            style: { padding: '5px 10px', minWidth: 155, fontSize: 20, fontWeight: 600 },
            label: 'save',
            type: 'confirm',
            disabled: isSubmitting,
            onClick: handleSubmit
          }
        ]}
      />
    </Container>
  );
};

export default Generals;
