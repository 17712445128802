import React from 'react';
import Layout from '../../../components/shared/Layout';
import DetailedOrder from '../../../components/Moh/kitchen/DetailedOrder';

const DetailedOrderPage = ({ history, match }) => {
  return (
    <Layout history={history} section="order-details">
      <DetailedOrder fbOrderUUID={match.params.uuid} />
    </Layout>
  );
};

export default DetailedOrderPage;
