import React from 'react';
import { Box } from '@material-ui/core';

const TwoButtonsContainer = ({ children }) => (
  <Box display="flex" justifyContent="space-between" width="100%">
    {children}
  </Box>
);

export default TwoButtonsContainer;
