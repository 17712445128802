import React from 'react';
import Layout from '../components/shared/Layout';
import Account from '../components/settings/Account/Account';

const AccountPage = ({ history }) => {
  return (
    <Layout history={history} section="account">
      <Account />
    </Layout>
  );
};

export default AccountPage;
