import { makeStyles } from '@material-ui/core/styles';
import { borderTheme } from '../theme';

const modalStyle = {
  modal: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }),
  paper: () => ({
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: ' white',
    padding: 20,
    outlineColor: 'transparent'
  }),
  modalDescriptionInfo: () => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  }),
  invertedButton: () => ({
    borderRadius: 9,
    letterSpacing: 0,
    border: `${borderTheme} !important`,
    '&:hover': {
      border: `${borderTheme} !important`
    }
  }),
  buttonStyle: () => ({
    color: 'white',
    boxShadow: 'none',
    borderRadius: 9,
    letterSpacing: 0
  }),
  modalWinesOrdersHistory: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }),
  paperWinesOrdersHistory: () => ({
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: ' white',
    padding: 20,
    outlineColor: 'transparent',
    maxHeight: '90vh'
  }),
  typographyWinesOrderHistory: () => ({
    fontWeight: 'bold',
    fontSize: 12
  }),
  closeModalButton: () => ({
    height: 40,
    width: 40,
    position: 'absolute',
    top: '-15px',
    right: '-13px',
    borderRadius: '50%',
    backgroundColor: 'white',
    cursor: 'pointer'
  })
};

export default makeStyles(modalStyle);
