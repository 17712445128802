import { makeStyles } from '@material-ui/core/styles';
import { invertedPrimary, primaryColor, mainTextColor } from '../../common-fe/src';

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    color: mainTextColor
  },
  dateContainer: {
    color: invertedPrimary
  },
  arrow: {
    cursor: 'pointer',
    fontSize: 20,
    fontWeight: 'bold',
    transition: 'color 0.3s',
    '&:hover': {
      color: primaryColor
    }
  },
  date: {
    fontSize: 18,
    fontWeight: 500,
    margin: '0 5px',
    textTransform: 'capitalize'
  },
  amount: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 'bold',
    '& span': {
      margin: '0 5px',
      color: '#757B80',
      fontSize: 15
    }
  },
  floatingBoxContainer: {
    position: 'absolute',
    top: 25,
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
    borderRadius: 4,
    backgroundColor: 'white',
    boxShadow: '1px 1px 3px 1px #d3d3d3',
    padding: '5px 10px'
  },
  triangle: {
    cursor: 'default',
    color: 'white',
    fontSize: '2rem',
    textShadow: '5px 2px 25px #c4c1c1',
    lineHeight: 1
  },
  floatingBoxContent: {
    minWidth: 185,
    zIndex: 1,
    '& div:nth-of-type(-n + 2):not(:last-child)': {
      borderBottom: '0.5px solid #707070'
    }
  },
  floatingBoxItem: {
    display: 'grid',
    gridTemplateAreas: '"icon type amount quantity"',
    gridTemplateColumns: '"auto 1fr 1fr auto"',
    alignItems: 'center',
    padding: '5px 0',
    fontSize: 12,
    color: mainTextColor
  }
};

export default makeStyles(styles);
