import React, { useState, useEffect } from 'react';
import { Paper, InputBase, List, ListItem, Box } from '@material-ui/core/';
import debounce from 'lodash/debounce';
import styles from '../../styles/shared/searchBar';
import MaterialIcon from './MaterialIcon';
import { primaryColor } from '../../styles/theme';

const SearchBar = ({
  dimensions,
  inputStyle,
  style,
  iconStyle,
  customIcon = 'Search',
  value,
  disabled,
  placeholder,
  autoComplete,
  apolloQuery,
  withResults,
  results,
  resultsKeys,
  onReturnedData,
  onResultClick,
  onValueChange,
  onChange,
  onReset,
  onKeyDown,
  onClear
}) => {
  const classes = styles({ dimensions });
  const [data, setData] = useState(null);
  const [showResultsContainer, setShowResultsContainer] = useState(false);

  useEffect(() => {
    if (data) {
      onReturnedData(data);
      setShowResultsContainer(true);
    }
  }, [data]);

  const onChangeHandler = debounce((e) => {
    const query = e.target.value;
    if (onValueChange) onValueChange(query);
    if (query.length <= 2) {
      setData([]);
    } else {
      apolloQuery
        .refetch({
          keyword: query
        })
        .then((res) => setData(res));
    }
  }, 1000);

  return (
    (autoComplete && (
      <Paper className={classes.paperSearchBar} style={{ ...style }}>
        <InputBase
          style={{ flex: 1, paddingLeft: 5, ...inputStyle }}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={(e) => {
            e.persist();
            onChangeHandler(e);
          }}
          onClick={() => setShowResultsContainer(true)}
          onReset={onReset}
          onKeyDown={onKeyDown}
        />
        <Box
          style={{
            cursor: 'pointer',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: primaryColor,
            color: 'white',
            width: 40,
            ...iconStyle
          }}
        >
          <MaterialIcon iconName={customIcon} />
        </Box>
        {withResults && results.length > 0 && showResultsContainer && (
          <List className={classes.resultsContainer}>
            {results.map((result) => (
              <ListItem
                key={result.id}
                className={classes.resultItem}
                onClick={() => {
                  setShowResultsContainer(false);
                  onResultClick(result);
                }}
              >
                {resultsKeys
                  .map((resultKey) => {
                    return result[resultKey];
                  })
                  .join(' ')}
              </ListItem>
            ))}
          </List>
        )}
      </Paper>
    )) || (
      <Paper className={classes.paperSearchBar} style={{ ...style }}>
        <InputBase
          style={{ flex: 1, paddingLeft: 5, ...inputStyle }}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={(e) => {
            e.persist();
            onChange(e);
          }}
          onReset={onReset}
          onKeyDown={onKeyDown}
        />
        {value && value.length > 0 ? (
          <MaterialIcon iconName="cancel" style={{ cursor: 'pointer', color: primaryColor, marginRight: 10 }} onClick={onClear} />
        ) : null}
        <Box
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: primaryColor,
            color: 'white',
            width: 40,
            ...iconStyle
          }}
        >
          <MaterialIcon iconName={customIcon} />
        </Box>
      </Paper>
    )
  );
};
export default SearchBar;
