import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';

const Container = styled.div`
  width: 100%;

  & .MuiFormControl-root {
    width: 100%;
  }
`;

const MultiSelect = ({
  options,
  defaultValue,
  autoComplete,
  autoHighlight,
  disableClearable,
  multiple,
  native,
  freeSolo,
  loading,
  loadingText,
  placeholder,
  size,
  containerStyle,
  style,
  chipParams,
  value,
  classes,
  limitTags,
  getLimitTagsText,
  getOptionLabel,
  getOptionSelected,
  onChange,
  error
}) => {
  return (
    <Container containerStyle={containerStyle}>
      <Autocomplete
        className={classes}
        autoComplete={autoComplete}
        autoHighlight={autoHighlight}
        disableClearable={disableClearable}
        multiple={multiple}
        native={native}
        freeSolo={freeSolo}
        loading={loading}
        loadingText={loadingText}
        options={options}
        limitTags={limitTags}
        getLimitTagsText={getLimitTagsText}
        getOptionSelected={getOptionSelected}
        getOptionLabel={(option) => {
          return getOptionLabel?.(option) ?? option?.label ?? '';
        }}
        defaultValue={defaultValue}
        placeholder={placeholder}
        size={size}
        style={style}
        value={value}
        renderTags={(values, getTagProps) =>
          values.map((option, index) => <Chip variant="outlined" label={option?.label ?? ''} {...getTagProps({ index })} {...chipParams} />)
        }
        renderInput={(params) => <TextField error={error} {...params} variant="outlined" />}
        onChange={onChange}
      />
    </Container>
  );
};

MultiSelect.propTypes = {
  /** An array of objects with two keys: "label" and "value" */
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
      PropTypes.string
    ])
  ),
  autoComplete: PropTypes.bool,
  autoHighlight: PropTypes.bool,
  disableClearable: PropTypes.bool,
  /** Allows to add custom options */
  freeSolo: PropTypes.bool,
  loading: PropTypes.bool,
  loadingText: PropTypes.element,
  multiple: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium'])
};

MultiSelect.defaultProps = {
  options: [],
  autoComplete: true,
  autoHighlight: true,
  disableClearable: true,
  freeSolo: false,
  loading: false,
  loadingText: null,
  multiple: true,
  size: 'small'
};

export default MultiSelect;
