import React from 'react';
import Layout from '../../../components/shared/Layout';
import Orders from '../../../components/Moh/orders/Orders';

const MohOrdersPage = ({ history }) => {
  return (
    <Layout history={history} section="app-orders">
      <Orders />
    </Layout>
  );
};

export default MohOrdersPage;
