import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import MUISwitch from '@material-ui/core/Switch';
import { TranslateTextComponent } from '../../translator';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled(TranslateTextComponent)`
font-size:14px;
text-transform:capitalize;
margin-right: ${({ right }) => (right ? '0px' : '12px')}
margin-left: ${({ right }) => (right ? '12px' : '0px')};
`;

const LeftComponent = styled.div`
  margin-right: 15px;
`;

const Switch = ({ name, leftLabel, rightLabel, labelsStyle, leftLabelStyle, rightLabelStyle, value, leftComponent, onChange }) => {
  return (
    <Container>
      {leftComponent && <LeftComponent>{leftComponent}</LeftComponent>}
      {leftLabel && <Label style={{ ...labelsStyle, leftLabelStyle }}>{leftLabel}</Label>}
      <MUISwitch
        name={name}
        color="primary"
        checked={value}
        value={value}
        onChange={({ target: { checked } }) => {
          onChange(checked);
        }}
      />
      {rightLabel && (
        <Label right style={{ ...labelsStyle, rightLabelStyle }}>
          {rightLabel}
        </Label>
      )}
    </Container>
  );
};

Switch.propTypes = {
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

Switch.defaultProps = {
  leftLabel: '',
  rightLabel: ''
};

export default Switch;
