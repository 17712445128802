/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { TranslateTextComponent } from '../../../../translator';
import { ReactComponent as BinIcon } from '../../../../assets/images/orders/bin.svg';

const textTranslate = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

const driversColumns = () => {
  return [
    {
      id: 'fullName',
      // disablePadding: false,
      label: textTranslate('full-name'),
      width: '65%',
      isSortable: false,
      align: 'left',
      render: (item) => `${item.firstName} ${item.lastName}`
    },
    {
      id: 'phoneNumber',
      label: textTranslate('phone-number'),
      width: '10%',
      isSortable: false,
      align: 'left',
      render: (item) => item.phoneNumber
    },
    {
      id: 'email',
      disablePadding: false,
      label: textTranslate('email'),
      width: '10%',
      isSortable: false,
      align: 'left',
      render: (item) => item.email
    },
    // {
    //   id: 'status',
    //   disablePadding: false,
    //   label: textTranslate('status'),
    //   width: '10%',
    //   isSortable: false,
    //   align: 'left',
    //   render: (item) => (
    //     <Dropdown
    //       dropdownStyle={{ backgroundColor: 'white', fontSize: 11, textTransform: 'uppercase' }}
    //       value="off"
    //       items={[{ value: 'off', label: textTranslate('off') }]}
    //     />
    //   )
    // },
    {
      id: 'delete',
      disablePadding: false,
      label: textTranslate('delete'),
      width: '5%',
      isSortable: false,
      align: 'left',
      render: (item) => <BinIcon fill="gray" style={{ cursor: 'default' }} />
    }
  ];
};
export default driversColumns;
