import React from 'react';
import { Box, Typography, Input } from '@material-ui/core/';
import { mainTextColor } from '../../styles/theme';

const InputTextLabel = ({
  classNameBox,
  title,
  placeholder,
  inputType,
  value,
  name,
  inputStyle,
  className,
  error,
  errorLabel,
  inputProps,
  disabled,
  onChange,
  onBlur
}) => {
  let inputStyleObj = {
    ...inputStyle
  };

  if (error) {
    inputStyleObj = {
      ...inputStyleObj,
      borderColor: 'red'
    };
  }

  return (
    <Box className={classNameBox || null}>
      {title && <Typography style={{ fontWeight: '600', marginRight: 5, color: mainTextColor, fontSize: 14, marginBottom: 5 }}>{title}</Typography>}
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Input
          disabled={disabled}
          disableUnderline
          fullWidth
          placeholder={placeholder}
          variant="outlined"
          type={inputType}
          value={value || ''}
          style={inputStyleObj}
          className={className}
          onChange={onChange}
          onBlur={onBlur}
          error={error}
          name={name}
          inputProps={{
            ...inputProps
          }}
        />
        <span style={{ color: 'red', fontSize: 10 }}>{error ? errorLabel : null}&nbsp;</span>
      </Box>
    </Box>
  );
};

export default InputTextLabel;
