/* eslint-disable class-methods-use-this */
import axios from 'axios';
import convert from 'xml-js';
import moment from 'moment';
import { setXmlBody } from './json';
import fakeResponses from './utils/fakeResponses';

interface PrintRecItem {
  description: string;
  quantity: number;
  unitPrice: number;
  department: string;
  justification?: string;
}

enum FiscalPrinterModes {
  production = 'production',
  test = 'test'
}

export enum PaymentTypes {
  cash = '0',
  cheque = '1',
  credit_card = '2',
  ticket = '3'
}

const getXmlString = (xmlObj: any) => {
  return convert.json2xml(xmlObj, { compact: true, ignoreComment: true, spaces: 4 });
};

const getBodyResponse = (response: any) => {
  return (
    (response['soapenv:Envelope'] && response['soapenv:Envelope']['soapenv:Body'] && response['soapenv:Envelope']['soapenv:Body'].response) ||
    response
  );
};

class FiscalPrinter {
  public requestUrl: string;
  public operator: string;
  public mode: FiscalPrinterModes;
  constructor(fiscalPrinterAddress: string, mode: FiscalPrinterModes = FiscalPrinterModes.test, timeout: number = 5000, operator: string = '01') {
    this.requestUrl = `${fiscalPrinterAddress}/cgi-bin/fpmate.cgi?timeout=${timeout}`;
    this.operator = operator;
    this.mode = mode;
  }

  isTesting = () => this.mode === FiscalPrinterModes.test;

  async send(body: any): Promise<any> {
    const config = {
      headers: { 'Content-Type': 'text/xml' }
    };
    return new Promise((resolve, reject) => {
      axios
        .post(this.requestUrl, convert.json2xml(body, { compact: true, ignoreComment: true, spaces: 4 }), config)
        .then((res) => {
          resolve(getBodyResponse(convert.xml2js(res.data, { ignoreComment: true, alwaysChildren: true, compact: true })));
        })
        .catch((err) => reject(err));
    });
  }

  async sendfake(body: any, fakeResponse: string): Promise<any> {
    console.log(convert.json2xml(body, { compact: true, ignoreComment: true, spaces: 4 }));
    return new Promise((resolve, reject) => {
      resolve(getBodyResponse(convert.xml2js(fakeResponse, { ignoreComment: true, alwaysChildren: true, compact: true })));
    });
  }

  async queryPrinterStatus(): Promise<any> {
    const body = setXmlBody({
      printerCommand: {
        queryPrinterStatus: {
          _attributes: {
            operator: this.operator,
            statusType: ''
          }
        }
      }
    });
    const res = await this.send(body);
    console.log('queryPrinterStatus', res);
    return res;
  }

  async cancelFiscalReceipt(documentNumber: string, formattedDate: string /* DD-MM-YYYY */) {
    const body = setXmlBody({
      printerCommand: {
        directIO: {
          _attributes: {
            command: '1078',
            data: `0140001ANNULLAMENTO N.${documentNumber} del ${formattedDate} `,
            // data: `0140001ANNULLAMENTO N.0758-0001 del 24-01-2020 `,
            timeout: '10000'
          }
        }
      }
    });
    if (this.isTesting()) {
      return this.sendfake(body, fakeResponses.cancelFiscalReceipt);
    } else {
      return this.send(body);
    }
  }

  async printZReport() {
    const body = setXmlBody({
      printerFiscalReport: {
        printZReport: {
          __attributes: {
            operator: this.operator
          }
        }
      }
    });
    if (this.isTesting()) {
      return this.sendfake(body, fakeResponses.printZReport);
    } else {
      // console.log(getXmlString(body));
      return this.send(body);
    }
  }

  async printFiscalReceipt(items: PrintRecItem[], paymentType: PaymentTypes, index: string, paymentDescription: string, discountValue: number) {
    const printRecItem = items.map((item) => ({
      _attributes: {
        operator: this.operator,
        ...item
      }
    }));

    let printRecSubtotalAdjustment = null;
    if (discountValue) {
      printRecSubtotalAdjustment = {
        _attributes: {
          operator: this.operator,
          adjustmentType: '1',
          description: 'SCONTO EUR',
          amount: discountValue
        }
      };
    }
    /**
     * <printRecSubtotalAdjustment operator="" adjustmentType="1" description="SCONTO EUR" amount="" justification="" />
     * <printRecSubtotal operator="1" option="1" />
     * <printRecTotal operator="10" description="PAGAMENTO                       " payment="0" paymentType="2" index="0" justification = "1" />
     * <printRecMessage  operator="10" messageType="3" index="1" font="4" message="Arrivederci e Grazie" />
     */
    const body = {
      printerFiscalReceipt: {
        beginFiscalReceipt: {},
        printRecItem,
        printRecSubtotalAdjustment: printRecSubtotalAdjustment ? printRecSubtotalAdjustment : undefined,
        printRecSubtotal: {
          _attributes: {
            operator: this.operator,
            option: '1'
          }
        },
        printRecTotal: {
          _attributes: {
            operator: this.operator,
            description: paymentDescription, // : 'PAGAMENTO                       ',                 ',
            payment: '0',
            paymentType, // metodo di pagamento: 0 => cash, 1 => Cheque (assegno), 2 => carta di credito, 3 => ticket,
            index // cash (0-5), card (type of cards 1 -10)
          }
        },
        // printRecMessage: {

        // },
        endFiscalReceipt: {}
      }
    };

    if (!discountValue) {
      delete body.printerFiscalReceipt.printRecSubtotalAdjustment;
    }

    if (this.isTesting()) {
      return this.sendfake(setXmlBody(body), fakeResponses.printFiscalReceipt);
    } else {
      // console.log(getXmlString(body));
      return this.send(setXmlBody(body));
    }
  }
}

export default FiscalPrinter;
