import React from 'react';
import Layout from '../../../components/shared/Layout';
import Kitchen from '../../../components/Moh/kitchen/Kitchen';

const MohKitchenPage = ({ history }) => {
  return (
    <Layout history={history} section="kitchen">
      <Kitchen />
    </Layout>
  );
};

export default MohKitchenPage;
