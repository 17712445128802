import React from 'react';
import { List, ListItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import typy from 'typy';
import styles from '../../../styles/viewTables/floatingBusyTableOptions/style';
import { ROUTE_ORDER, ROUTE_ASSIGN_CHAIR, ROUTE_CASH } from '../../../router/routes';
import { TranslateTextComponent } from '../../../translator';

const FloatingBusyTableOptions = ({ coords, table, onPlayFbCourse }) => {
  const classes = styles({ top: coords.top, left: coords.left });
  const fbOrderUUID = typy(table, 'fbReservationTables[0].fbOrder.uuid').safeString;

  return (
    <List id="floating-busy-table-menu" className={classes.container}>
      <ListItem className={classes.item} onClick={onPlayFbCourse} style={{ cursor: 'pointer' }}>
        <TranslateTextComponent capitalize>call</TranslateTextComponent>
      </ListItem>
      <ListItem className={classes.item}>
        <Link to={`${ROUTE_ORDER}/${table.uuid}`}>
          <TranslateTextComponent capitalize>add-modify-course</TranslateTextComponent>
        </Link>
      </ListItem>
      <ListItem className={classes.item}>
        <Link to={`${ROUTE_ASSIGN_CHAIR}/${table.uuid}`}>
          <TranslateTextComponent capitalize>assign-chair</TranslateTextComponent>
        </Link>
      </ListItem>
      <ListItem className={classes.item}>
        <Link to={`${ROUTE_CASH}/${fbOrderUUID}`}>
          <TranslateTextComponent capitalize>pay</TranslateTextComponent>
        </Link>
      </ListItem>
    </List>
  );
};

export default FloatingBusyTableOptions;
