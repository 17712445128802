import React from 'react';
import { Box } from '@material-ui/core';
import Layout from '../components/shared/Layout';
import ViewTablesContainer from '../components/ViewTables/ViewTablesContainer';

const TableReservationPage = ({ history }) => {
  // const ref = useRef(null);
  // useEffect(() => {
  //   document.body.style.backgroundColor = 'white';
  //   console.log('ADD EVEN TLISTENER');
  //   ref.current.addEventListener(
  //     'touchmove',
  //     function(e) {
  //       e.preventDefault();
  //     },
  //     false
  //   );

  //   return () =>
  //     ref.current.removeEventListener(
  //       'touchmove',
  //       function(e) {
  //         e.preventDefault();
  //       },
  //       false
  //     );
  // }, []);

  return (
    <Box>
      <Layout history={history} section="panoramic-tables">
        <ViewTablesContainer />
      </Layout>
    </Box>
  );
};

export default TableReservationPage;
