import { makeStyles } from '@material-ui/core/styles';

const styles = {
  buttonsContainer: {
    position: 'fixed',
    bottom: 25,
    right: 25,
    display: 'flex'
  },
  button: {
    margin: '0 10px'
  },
  buttonIcon: {
    marginRight: 10
  }
};

export default makeStyles(styles);
