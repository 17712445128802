import { makeStyles } from '@material-ui/core/styles';
import { mainTextColor, primaryColor } from '../../common-fe';

const styles = {
  container: {
    color: mainTextColor,
    display: 'flex',
    flexFlow: 'column',
    margin: '8px 0'
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold'
  },
  radio: {
    '&.MuiRadio-root': {
      padding: 2,
      marginLeft: 5
    }
  },
  radioText: {
    fontSize: 12
  },
  switch: {
    '& .MuiSwitch': {
      opacity: 1
    },
    '& .MuiSwitch-track': {
      backgroundColor: primaryColor,
      opacity: 0.5
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: primaryColor
    }
  }
};

export default makeStyles(styles);
