import React, { useContext, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import typy from 'typy';
import moment from 'moment';
import { Box, Grid, Typography, Avatar, Button, Fab } from '@material-ui/core/';
import { getApolloErrors } from '../../apollo/ApolloProvider';
import { SET_FB_RESERVATION_GUEST_ARRIVED_MUTATION } from '../../graphql/reservations/mutations';
import { SET_RESERVATION_TABLES } from '../../graphql/tables/mutations';
import { ReservationContext } from '../../contexts/ReservationContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import { ModalContext } from '../../contexts/ModalContext';
import { TranslateTextComponent, translate, capitalizeFirstLetter } from '../../translator';
import { InputTextLabel, CustomButton, Dropdown } from '../../common-fe';
import ExternalPanelReservation from './ExternalPanelReservation';
import ecStyles from '../../styles/saveReservation/AvailabilityStyle';
import modalStyles from '../../common-fe/src/styles/shared/modalStyle';
import { AuthContext } from '../../contexts/AuthContext';
import MultiSelect from '../shared/MultiSelect';
import styles from '../../styles/settings/tabs';
import InputWithLabel from '../shared/InputWithLabel';
import GuestRoomSearchBar from '../shared/GuestRoomSearchBar';

const ReservationForm = ({
  isFastReservation,
  table,
  isLoadingPhysicalCategories,
  physicalCategories,
  onNewReservationComplete,
  fbReservationUUID,
  onCloseReservationModal,
  outlets,
  tags
}) => {
  const reservationContext = useContext(ReservationContext);
  const { language } = useContext(LanguageContext);
  const modalContext = useContext(ModalContext);
  const { appOutletUUID } = useContext(AuthContext);

  const reservationsData = reservationContext?.reservationsData;
  const guestData = reservationContext?.guestData;
  const handleChangeGuestData = reservationContext?.handleChangeGuestData;
  const handleChangeReservationData = reservationContext && reservationContext.handleChangeReservationData;
  const errorGuestMap = reservationContext?.errorGuestMap;

  const [setReservationTable, { loading: mutationLoading }] = useMutation(SET_RESERVATION_TABLES);
  const [setReservationGuestArrived] = useMutation(SET_FB_RESERVATION_GUEST_ARRIVED_MUTATION);

  const classes = styles();
  const classesAvailability = ecStyles();
  const modalClasses = modalStyles();

  useEffect(() => {
    if (isFastReservation) {
      reservationContext.reservationResetData();
      reservationContext.handleChangeReservationDataFields({
        selectedTime: moment(new Date()).format('HH:mm'),
        reservationTime: moment(new Date()).format('HH:mm'),
        reservationDate: moment().format('YYYY-MM-DD'),
        fbOutletUUID: appOutletUUID
      });
    }
  }, []);

  const getTags = (fieldName) => {
    return (
      tags
        .find((tag) => tag.fieldName === fieldName)
        ?.tags?.map((tag) => ({ label: capitalizeFirstLetter(translate(tag?.toLowerCase(), language)), value: tag })) ?? []
    );
  };

  const textTranslate = (text) => {
    return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
  };

  const onSaveFastReservationHandler = () => {
    const isValidReservation = reservationContext.isValidReservation();
    const isValidGuest = reservationContext.isValidGuest();

    if (isValidReservation && isValidGuest) {
      modalContext.openModal({
        class: 'primary',
        title: <TranslateTextComponent capitalize>new-reservation</TranslateTextComponent>,
        text: <TranslateTextComponent capitalize>save-reservation-text</TranslateTextComponent>,
        actionButtons: [
          <Button
            key={0}
            variant="outlined"
            color="primary"
            className={modalClasses.invertedButton}
            onClick={() => {
              modalContext.closeModal();
            }}
          >
            <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
          </Button>,
          <Button
            key={1}
            color="primary"
            variant="contained"
            className={modalClasses.buttonStyle}
            onClick={() => {
              reservationContext.saveGuest().then((response) => {
                if (response && response.data) {
                  const guest = response.data.addGuest || response.data.editGuest;
                  reservationContext.handleSetGuestData(guest);
                  reservationContext.saveReservations(guest.uuid).then((res) => {
                    const newReservationUUID = typy(res[0], 'data.addFbReservation.uuid').safeString;
                    setReservationGuestArrived({ variables: { fbReservationUUID: newReservationUUID } }).then(() => {
                      setReservationTable({ variables: { fbTableUUIDs: [table.uuid], fbReservationUUID: newReservationUUID } })
                        .then(() => onNewReservationComplete())
                        .catch((err) => {
                          onCloseReservationModal();
                          modalContext.openModal({
                            class: 'danger',
                            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                            text: getApolloErrors(err).join(' - ')
                          });
                        });
                    });
                  });
                }
              });

              modalContext.closeModal();
            }}
          >
            <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
          </Button>
        ]
      });
    }
  };

  return (
    <Box style={{ paddingLeft: 40, paddingRight: 40 }}>
      <Box>
        <Typography className={classesAvailability.headerForm} style={{ marginBottom: (!isFastReservation && 20) || 0 }}>
          {textTranslate('general')}
        </Typography>
        <Grid container style={{ display: 'flex' }}>
          <Box style={{ paddingTop: 10, margin: (!isFastReservation && 0) || 'auto' }}>
            <Avatar style={{ height: (!isFastReservation && 200) || 100, width: (!isFastReservation && 200) || 100 }} />
          </Box>
          <Grid item xs style={{ paddingTop: 10 }}>
            <Grid item xs={12} style={{ paddingLeft: 30, paddingBottom: (!isFastReservation && 20) || 5, display: 'flex' }}>
              <Grid item xs style={{ marginRight: 50 }}>
                <InputTextLabel
                  error={errorGuestMap.has('firstName')}
                  errorLabel={errorGuestMap.get('firstName')}
                  title={capitalizeFirstLetter(translate('name', language))}
                  value={guestData?.firstName}
                  onChange={(e) => handleChangeGuestData('firstName', e.target.value)}
                  placeholder={capitalizeFirstLetter(translate('name', language))}
                  style={{ paddingRight: 20 }}
                  className={classesAvailability.noteTextField}
                />
              </Grid>
              <Grid item xs>
                <InputTextLabel
                  error={errorGuestMap.has('lastName')}
                  errorLabel={errorGuestMap.get('lastName')}
                  title={capitalizeFirstLetter(translate('surname', language))}
                  onChange={(e) => handleChangeGuestData('lastName', e.target.value)}
                  placeholder={capitalizeFirstLetter(translate('surname', language))}
                  value={guestData?.lastName}
                  style={{ paddingRight: 20 }}
                  className={classesAvailability.noteTextField}
                />
              </Grid>
            </Grid>
            <Grid item xs style={{ paddingLeft: 30, display: 'flex' }}>
              <Grid item xs style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid item xs style={{ width: '100%', marginRight: 25 }}>
                  <InputTextLabel
                    error={errorGuestMap.has('email')}
                    errorLabel={errorGuestMap.get('email')}
                    title={capitalizeFirstLetter(translate('email', language))}
                    placeholder={capitalizeFirstLetter(translate('email', language))}
                    value={guestData?.email}
                    onChange={(e) => handleChangeGuestData('email', e.target.value)}
                    className={classesAvailability.noteTextField}
                  />
                </Grid>
                <Grid item xs style={{ width: '100%', marginRight: 25 }}>
                  <InputWithLabel label="arrangement" error={errorGuestMap.has('arrangement') && errorGuestMap.get('arrangement')}>
                    <Dropdown
                      dropdownStyle={{ height: 40, width: '100%' }}
                      items={getTags('arrangement').map((a) => ({ label: a.label.toUpperCase(), value: a.value }))}
                      value={guestData?.arrangement}
                      onChange={({ target: { value } }) => handleChangeGuestData('arrangement', value)}
                    />
                  </InputWithLabel>
                </Grid>
              </Grid>
              <Grid item xs style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid item style={{ width: '100%', marginLeft: 25 }}>
                  <InputTextLabel
                    error={errorGuestMap.has('phone')}
                    errorLabel={errorGuestMap.get('phone')}
                    title={capitalizeFirstLetter(translate('phone', language))}
                    placeholder={capitalizeFirstLetter(translate('phone', language))}
                    value={guestData?.phone}
                    className={classesAvailability.noteTextField}
                    onChange={(e) => handleChangeGuestData('phone', e.target.value)}
                  />
                </Grid>
                <Grid item style={{ marginLeft: 20, width: '100%' }}>
                  <GuestRoomSearchBar
                    title="guest-room"
                    value={reservationsData?.[0]?.guestRoom}
                    onChange={() => handleChangeReservationData('guestRoom', null)}
                    onItemClick={(room) => handleChangeReservationData('guestRoom', room?.number)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Typography className={classesAvailability.headerForm} style={{ marginTop: 20, marginBottom: 11 }}>
            {textTranslate('preference-tollerance')}
          </Typography>
          <Grid item xs style={{ paddingLeft: 5, display: 'flex', marginTop: (!isFastReservation && 30) || 0 }}>
            <Grid item xs style={{ marginRight: 50 }}>
              <Typography className={classesAvailability.placeholderChip}>{capitalizeFirstLetter(translate('intolerances', language))}</Typography>
              <Box>
                <MultiSelect
                  className={classes.dropdown}
                  freeSolo={tags?.find((tag) => tag.fieldName === 'fbProfilePreferences.intolerances')?.isFreeTagAllowed}
                  options={getTags('fbProfilePreferences.intolerances')}
                  chipParams={{ variant: 'default' }}
                  value={
                    guestData?.intolerances?.map((intolerance) => ({
                      label: capitalizeFirstLetter(translate(intolerance, language)),
                      value: intolerance
                    })) ?? []
                  }
                  getOptionSelected={(option, { value }) => option.value === value}
                  onChange={(e, value) => {
                    const intolerances = [];
                    value.forEach((intolerance) => {
                      if (typeof intolerance === 'string') {
                        intolerances.push({ label: intolerance, value: intolerance });
                      } else {
                        intolerances.push(intolerance);
                      }
                    });
                    handleChangeGuestData(
                      'intolerances',
                      intolerances.map((intolerance) => intolerance.value)
                    );
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs>
              <Typography className={classesAvailability.placeholderChip}>{capitalizeFirstLetter(translate('allergens', language))}</Typography>
              <Box>
                <MultiSelect
                  className={classes.dropdown}
                  freeSolo={tags?.find((tag) => tag.fieldName === 'fbProfilePreferences.allergens')?.isFreeTagAllowed}
                  options={getTags('fbProfilePreferences.allergens')}
                  chipParams={{ variant: 'default' }}
                  value={
                    guestData?.allergens?.map((allergen) => ({
                      label: capitalizeFirstLetter(translate(allergen, language)),
                      value: allergen
                    })) ?? []
                  }
                  getOptionSelected={(option, { value }) => option.value === value}
                  onChange={(e, value) => {
                    const allergens = [];
                    value.forEach((allergen) => {
                      if (typeof allergen === 'string') {
                        allergens.push({ label: allergen, value: allergen });
                      } else {
                        allergens.push(allergen);
                      }
                    });
                    handleChangeGuestData(
                      'allergens',
                      allergens.map((allergen) => allergen.value)
                    );
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs>
            <Typography className={classesAvailability.headerForm} style={{ marginTop: 20, marginBottom: 11 }}>
              {textTranslate('reservations')}
            </Typography>
            {reservationsData.map((reservationData, reservationDataIndex) => (
              <ExternalPanelReservation
                key={reservationDataIndex}
                isFastReservation={isFastReservation}
                isLoadingPhysicalCategories={isLoadingPhysicalCategories}
                physicalCategories={physicalCategories}
                reservationDataIndex={reservationDataIndex}
                outlets={outlets}
              />
            ))}
            {(isFastReservation && (
              <Grid item xs style={{ display: 'flex', justifyContent: 'flex-end', margin: '20px 0' }}>
                <CustomButton isLoading={mutationLoading} style={{ width: '20%' }} onClick={onSaveFastReservationHandler}>
                  <TranslateTextComponent uppercase>save</TranslateTextComponent>
                </CustomButton>
              </Grid>
            )) ||
              (!fbReservationUUID && (
                <Grid item xs style={{ textAlign: 'right', marginRight: 35 }}>
                  <Fab
                    color="primary"
                    aria-label="add"
                    className={classesAvailability.fabAddButton}
                    onClick={() => reservationContext.addNewReservation()}
                  >
                    +1
                  </Fab>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ReservationForm;
