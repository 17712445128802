import React from 'react';
import { Box, ClickAwayListener } from '@material-ui/core';
import styles from '../../../../styles/billsManagement/headerIncome';
import { MaterialIcon } from '../../../../common-fe/src';

const FloatingBox = ({ closeFloatingBox }) => {
  const classes = styles();

  return (
    <ClickAwayListener onClickAway={closeFloatingBox}>
      <Box style={{ position: 'absolute', top: 50, display: 'flex', justifyContent: 'center' }}>
        <Box className={classes.triangle}>▲</Box>
        <Box className={classes.floatingBoxContainer}>
          <Box className={classes.floatingBoxContent}>
            <Box className={classes.floatingBoxItem}>
              <span style={{ gridArea: 'icon', display: 'flex' }}>
                <MaterialIcon style={{ fontSize: 12 }} iconName="Close" />
              </span>
              <span style={{ gridArea: 'type', color: 'black' }}>Fatture</span>
              <span style={{ gridArea: 'amount' }}>€ 1.780,00</span>
              <span style={{ gridArea: 'quantity' }}>(10)</span>
            </Box>
            <Box className={classes.floatingBoxItem}>
              <span style={{ gridArea: 'icon', display: 'flex' }}>
                <MaterialIcon style={{ fontSize: 12 }} iconName="Close" />
              </span>
              <span style={{ gridArea: 'type', color: 'black' }}>Fatture</span>
              <span style={{ gridArea: 'amount' }}>€ 1.780,00</span>
              <span style={{ gridArea: 'quantity' }}>(10)</span>
            </Box>
          </Box>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default FloatingBox;
