import React, { useState, useContext } from 'react';
import { Typography, Button } from '@material-ui/core';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import PageLayout from '../../shared/PageLayout';
import CustomFilters from '../../shared/CustomFilters/CustomFilters';
import { CommonTable } from '../../../common-fe/src';
import servicesColumns from './table/servicesColumns';
import { TranslateTextComponent } from '../../../translator';
import ServiceForm from './forms/ServiceForm';
import useCustomQuery from '../../../hooks/useCustomQuery';
import { GET_SMART_SERVICES } from '../../../graphql/moh/services/queries';
import { AuthContext } from '../../../contexts/AuthContext';
import { DELETE_SMART_SERVICE, EDIT_SMART_SERVICE } from '../../../graphql/moh/services/mutations';
import { ModalContext } from '../../../contexts/ModalContext';
import { getApolloErrors } from '../../../apollo/ApolloProvider';

const Services = () => {
  const [date, setDate] = useState(null);
  const [searchBarValue, setSearchBarValue] = useState('');
  const [filtersPage, setFiltersPage] = useState({
    page: 1,
    pageSize: 10
  });
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const { appOutletUUID } = useContext(AuthContext);
  const modalContext = useContext(ModalContext);

  // Queries
  const getSmartServices = useCustomQuery(GET_SMART_SERVICES, {
    variables: {
      fbOutletUUID: appOutletUUID,
      page: filtersPage.page,
      pageSize: filtersPage.pageSize,
      date: date !== null ? moment(date).format('YYYY-MM-DD') : null
    }
  });
  // Mutations
  const [editSmartService] = useMutation(EDIT_SMART_SERVICE);
  const [deleteSmartService] = useMutation(DELETE_SMART_SERVICE);

  const onStatusEdit = ({ service, isActive }) => {
    editSmartService({
      variables: {
        smartServiceUUID: service.uuid,
        smartServiceData: {
          name: service.name,
          dateStart: service.dateStart,
          dateEnd: service.dateEnd,
          price: service.price,
          dailyQuantity: service.dailyQuantity,
          isActive
        }
      }
    }).then(() => getSmartServices.refetch());
  };

  const onDeleteSmartService = (uuid) => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>delete-moh-service</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>delete-moh-service-description</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="contained"
          color="primary"
          style={{ color: 'white' }}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          variant="contained"
          color="primary"
          style={{ color: 'white' }}
          onClick={() => {
            deleteSmartService({ variables: { smartServiceUUID: uuid } })
              .then(() => {
                getSmartServices.refetch();
                modalContext.closeModal();
              })
              .catch((err) => {
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
          }}
        >
          <TranslateTextComponent uppercase>ok</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const setTablePages = (page, pageSize) => {
    setFiltersPage({
      ...filtersPage,
      page,
      pageSize
    });
  };

  return (
    <PageLayout>
      <CustomFilters
        filters={[
          { type: 'date', label: 'services-of', value: date, onChange: (value) => setDate(value) },
          { type: 'searchbar', onChange: ({ target: { value } }) => setSearchBarValue(value), value: searchBarValue }
        ]}
      />
      <CommonTable
        isLoading={getSmartServices.loading}
        key={1}
        page={filtersPage.page}
        pageSize={filtersPage.pageSize}
        setTablePages={setTablePages}
        // orderType={tableFilters.orderType.toLocaleLowerCase()}
        // orderBy={tableFilters.orderBy}
        // setTableSort={setTableSort}
        totalRows={getSmartServices.data?.getSmartServices?.totalCount ?? 0}
        isPaginationEnabled
        items={getSmartServices.data?.getSmartServices?.data ?? []}
        columns={servicesColumns({
          openModal: (data) => {
            setIsEditing(true);
            setModalData(data);
            setShowModal(true);
          },
          editSmartServiceStatus: ({ service, isActive }) => onStatusEdit({ service, isActive }),
          deleteSmartService: (uuid) => onDeleteSmartService(uuid)
        })}
        tableContainerStyle={{ marginTop: 13 }}
        rowStyle={{ borderColor: '#B4DCED' }}
        tableHeader={
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#EDFAFF',
              minHeight: 28,
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
            }}
          >
            <TranslateTextComponent capitalize>services-tot</TranslateTextComponent>: {getSmartServices.data?.getSmartServices?.totalCount ?? 0}
          </Typography>
        }
      />
      <Button
        variant="contained"
        color="primary"
        style={{ margin: '30px 10px 20px 0px', minWidth: 325, alignSelf: 'flex-end', fontSize: 20, fontWeight: 500, color: 'white' }}
        onClick={() => {
          setIsEditing(false);
          setShowModal(true);
        }}
      >
        <TranslateTextComponent uppercase>new-service</TranslateTextComponent>
      </Button>
      <ServiceForm
        data={modalData}
        showModal={showModal}
        isEditing={isEditing}
        closeModal={() => {
          setShowModal(false);
          setModalData(null);
        }}
        refetchQuery={() => getSmartServices.refetch()}
      />
    </PageLayout>
  );
};

export default Services;
