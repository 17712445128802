import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from '../../common-fe';

const styles = {
  container: {
    gridArea: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'column',
    maxWidth: 250,
    padding: 10,
    borderRight: `3px solid ${primaryColor}`
  }
};

export default makeStyles(styles);
