/* eslint-disable no-nested-ternary */
import { makeStyles } from '@material-ui/core/styles';

const styles = {
  container: ({ borderPosition, background }) => ({
    position: 'relative',
    background,
    border: '2px solid rgb(192, 193, 194)',
    borderStyle: 'solid solid none',
    borderRadius:
      borderPosition === 'top'
        ? '50% 50% 0 0'
        : borderPosition === 'left'
        ? '50% 0 0 50%'
        : borderPosition === 'right'
        ? '0 50% 50% 0'
        : '0 0 50% 50%',
    height: 55,
    width: 55
  }),
  genderBadge: ({ borderPosition, rotate }) => ({
    position: 'absolute',
    top: borderPosition === 'top' ? '35%' : borderPosition === 'bottom' ? '65%' : '50%',
    left: borderPosition === 'right' ? '65%' : borderPosition === 'left' ? '35%' : '50%',
    transform: `translate(-50%, -50%) rotate(${-rotate}deg)`
  }),
  nameLabelGuest: ({ marginTop, left, borderLeft, zIndex }) => ({
    zIndex,
    marginTop,
    left,
    position: 'absolute',
    fontSize: 9,
    textAlign: 'center',
    borderLeft,
    flexDirection: 'row',
    backgroundColor: 'white',
    boxShadow: '0px 3px 6px #00000029',
    minWidth: 75,
    minHeight: 30,
    maxHeight: 100,
    maxWidth: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  })
};

export default makeStyles(styles);
