/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import typy from 'typy';
import { TranslateTextComponent } from '../../translator';

const textTranslate = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

const getTablesColumns = () => {
  return [
    {
      id: 'bookedBy.lastname',
      disablePadding: false,
      width: '20%',
      label: textTranslate('client-fullName'),
      isSortable: true,
      render: (item) =>
        `${typy(item, 'fbReservationTables[0].fbReservation.bookedBy.firstname').safeString} ${
          typy(item, 'fbReservationTables[0].fbReservation.bookedBy.lastname').safeString
        }`
    },
    {
      id: 'fbReservation.date',
      disablePadding: false,
      label: textTranslate('reservation-date'),
      isSortable: true,
      render: (item) => typy(item, 'fbReservationTables[0].fbReservation.date').safeString
    },
    {
      id: 'fbReservation.reservationTime',
      disablePadding: false,
      label: textTranslate('reservation-time'),
      isSortable: true,
      render: (item) => typy(item, 'fbReservationTables[0].fbReservation.reservationTime').safeString
    },
    {
      id: 'fbReservation.arrivalTime',
      disablePadding: false,
      label: textTranslate('arrival-time'),
      isSortable: true,
      render: (item) => typy(item, 'fbReservationTables[0].fbReservation.arrivalTime').safeString
    },
    {
      id: 'fbTables.number',
      disablePadding: false,
      label: textTranslate('table-number'),
      isSortable: true,
      render: (item) => typy(item, 'number').safeString || null
    },
    {
      id: 'fbReservation.nPax',
      disablePadding: false,
      label: textTranslate('covers-number'),
      isSortable: true,
      hidden: {
        mdDown: true
      },
      render: (item) => typy(item, 'fbReservationTables[0].fbReservation.nPax').safeNumber || null
    },
    {
      id: 'fbReservation.guestRoom',
      disablePadding: false,
      label: textTranslate('repeater'),
      isSortable: false,
      hidden: {
        mdDown: true,
        lgDown: true
      },
      render: (item) => `${typy(item, 'bookedBy.firstname').safeString} ${typy(item, 'bookedBy.lastname').safeString}`
    }
  ];
};

export default getTablesColumns;
