import React, { useContext } from 'react';
import { FormControl, Typography as MUITypography, RadioGroup, Radio, FormControlLabel as MUIFormControlLabel } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components/macro';
import { TranslateTextComponent, translate, capitalizeFirstLetter } from '../../../translator';
import { GET_MENU_MACRO_CATEGORIES } from '../../../graphql/menu/queries';
import { EDIT_MENU_CATEGORY } from '../../../graphql/menu/mutations';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ModalContext } from '../../../contexts/ModalContext';
import MenuContext from '../../../contexts/MenuContext';
import Input from './Input';
import { Dropdown, CustomModal } from '../../../common-fe';
import ColorPicker from './ColorPicker';
import Button from './Button';
import { getApolloErrors } from '../../../apollo/ApolloProvider';
import useCustomQuery from '../../../hooks/useCustomQuery';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  min-width: 450px;
  padding: 20px;

  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

const RowContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  & > div:nth-child(1) {
    margin-right: 20px;
  }
`;

const FormControlLabel = styled(MUIFormControlLabel)(() => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '0.8rem'
  }
}));

const Typography = styled(MUITypography)(() => ({
  width: '100%',
  fontSize: '0.8rem'
}));

const EditCategory = ({ closeEditCategoryModal, refetchCategories, selectedCategory }) => {
  const { language } = useContext(LanguageContext);
  const modalContext = useContext(ModalContext);
  const { showEditCategoryModal } = useContext(MenuContext);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(language === 'EN' ? 'Required' : 'Obbligatorio'),
    order: Yup.number()
      .typeError(language === 'EN' ? 'Invalid number' : 'Numero non valido')
      .min(1, language === 'EN' ? 'Invalid number' : 'Numero non valido')
      .required(language === 'EN' ? 'Required' : 'Obbligatorio')
  });

  // Queries
  const getMacroCategories = useCustomQuery(GET_MENU_MACRO_CATEGORIES);

  // Mutations
  const [editCategory] = useMutation(EDIT_MENU_CATEGORY);
  return (
    <CustomModal showModal={showEditCategoryModal} onClose={closeEditCategoryModal} width="auto" height="auto" stylePaper={{ background: 'white' }}>
      <Formik
        enableReinitialize
        initialValues={{
          name: selectedCategory?.name ?? '',
          secondaryName: selectedCategory?.secondaryName ?? '',
          color: selectedCategory?.color ?? '',
          order: selectedCategory?.order ?? 1,
          category: selectedCategory?.fbMenuMacrocategory?.uuid ?? '',
          isHidden: selectedCategory?.isHidden ? 'active' : 'disabled',
          fbMenuMacrocategory: selectedCategory?.fbMenuMacrocategory
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          editCategory({
            variables: {
              fbMenuCategoryUUID: selectedCategory.uuid,
              fbMenuCategoryData: {
                name: values.name,
                secondaryName: values.secondaryName,
                order: parseFloat(values.order, 10),
                color: values.color,
                isHidden: values.isHidden !== 'disabled',
                fbMenuMacrocategoryUUID: values?.fbMenuMacrocategory
              }
            }
          })
            .then(() => {
              refetchCategories();
              closeEditCategoryModal();
            })
            .catch((err) => {
              modalContext.openModal({
                class: 'danger',
                title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                text: getApolloErrors(err).join(' - ')
              });
            });
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, handleReset, isSubmitting, setFieldValue }) => {
          return (
            <Container>
              <RowContainer>
                <Input
                  name="name"
                  title={capitalizeFirstLetter(translate('name', language))}
                  height={40}
                  placeholder="-"
                  value={values.name}
                  error={errors.name && touched.name && errors.name}
                  style={{ flex: 1 }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ColorPicker title="color" color={values.color} size={40} style={{ flex: 1 }} onChange={(value) => setFieldValue('color', value)} />
              </RowContainer>
              <RowContainer style={{ justifyContent: 'flex-start' }}>
                <Input
                  name="secondaryName"
                  title={capitalizeFirstLetter(translate('secondary-name', language))}
                  height={40}
                  placeholder="-"
                  value={values.secondaryName}
                  error={errors.secondaryName && touched.secondaryName && errors.secondaryName}
                  style={{ flex: 1 }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  name="order"
                  title={capitalizeFirstLetter(translate('order', language))}
                  height={40}
                  placeholder="-"
                  value={values.order}
                  error={errors.order && touched.order && errors.order}
                  style={{ flex: 1 }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </RowContainer>
              <RowContainer>
                <FormControl fullWidth component="fieldset">
                  <Typography>
                    <TranslateTextComponent capitalize>status</TranslateTextComponent>
                  </Typography>
                  <RadioGroup
                    style={{ marginTop: 5 }}
                    row
                    aria-label="isVisible"
                    name="isHidden"
                    value={values.isHidden}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <FormControlLabel
                      value="active"
                      control={<Radio color="primary" checked={values.isHidden === 'active'} />}
                      label={capitalizeFirstLetter(translate('menu-modal-radio-active', language))}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="disabled"
                      control={<Radio color="primary" checked={values.isHidden === 'disabled'} />}
                      label={capitalizeFirstLetter(translate('menu-modal-radio-hidden', language))}
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
                <ColumnContainer>
                  <Typography>
                    <TranslateTextComponent capitalize>category</TranslateTextComponent>
                  </Typography>
                  <Dropdown
                    name="category"
                    dropdownStyle={{ width: 'auto', height: 40, marginTop: 5 }}
                    value={values.category || getMacroCategories.data?.getFbMenuMacroCategories?.data?.[0].uuid}
                    items={getMacroCategories.data?.getFbMenuMacroCategories?.data?.map((category) => ({
                      ...category,
                      label: category.name,
                      value: category.uuid
                    }))}
                    height={40}
                    onChange={(e) => setFieldValue('fbMenuMacrocategory', e.target.value)}
                    onBlur={handleBlur}
                  />
                  <Typography color="error" style={{ fontSize: '0.7rem' }}>
                    {errors.category && touched.category && errors.category}
                  </Typography>
                </ColumnContainer>
              </RowContainer>
              <RowContainer>
                <Button variant="contained" color="primary" type="reset" onClick={handleReset}>
                  <TranslateTextComponent uppercase>menu-button-reset</TranslateTextComponent>
                </Button>
                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  <TranslateTextComponent uppercase>menu-button-submit</TranslateTextComponent>
                </Button>
              </RowContainer>
            </Container>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default EditCategory;
