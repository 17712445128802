import React from 'react';
import Layout from '../../../components/shared/Layout';
import Settings from '../../../components/settings/Settings';

const MohSettingsPage = ({ history }) => {
  return (
    <Layout history={history} section="settings">
      <Settings />
    </Layout>
  );
};

export default MohSettingsPage;
