import * as moment from 'moment';

const fakeResponses = {
  cancelFiscalReceipt: `
  <?xml version="1.0" encoding="utf-8"?>
  <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/">
    <soapenv:Body>
        <response success="true" code="" status="2">
          <addInfo>
              <elementList>lastCommand,printerStatus,fiscalReceiptNumber,fiscalReceiptAmount,fiscalReceiptDate,fiscalReceiptTime,zRepNumber</elementList>
              <lastCommand>74</lastCommand>
              <printerStatus>20110</printerStatus>
              <fiscalReceiptNumber>1</fiscalReceiptNumber>
              <fiscalReceiptAmount>1,00</fiscalReceiptAmount>
              <fiscalReceiptDate>${moment().format('DD/MM/YYYY')}</fiscalReceiptDate>
              <fiscalReceiptTime>${moment().format('HH:MM')}</fiscalReceiptTime>
              <zRepNumber>764</zRepNumber>
          </addInfo>
        </response>
      </soapenv:Body>
</soapenv:Envelope>`,
  printFiscalReceipt: `
  <?xml version="1.0" encoding="utf-8"?>
  <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/">
    <soapenv:Body>
        <response success="true" code="" status="2">
          <addInfo>
              <elementList>lastCommand,printerStatus,fiscalReceiptNumber,fiscalReceiptAmount,fiscalReceiptDate,fiscalReceiptTime,zRepNumber</elementList>
              <lastCommand>74</lastCommand>
              <printerStatus>20110</printerStatus>
              <fiscalReceiptNumber>1</fiscalReceiptNumber>
              <fiscalReceiptAmount>1,00</fiscalReceiptAmount>
              <fiscalReceiptDate>27/1/2020</fiscalReceiptDate>
              <fiscalReceiptTime>16:51</fiscalReceiptTime>
              <zRepNumber>764</zRepNumber>
          </addInfo>
        </response>
      </soapenv:Body>
</soapenv:Envelope>`,
  printZReport: `
  <?xml version="1.0" encoding="utf-8"?>
  <soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/">
    <soapenv:Body>
        <response success="true" code="" status="2">
          <addInfo>
              <elementList>lastCommand,printerStatus,zRepNumber,dailyAmount</elementList>
              <lastCommand>74</lastCommand>
              <printerStatus>20110</printerStatus>
              <zRepNumber>764</zRepNumber>
              <dailyAmount>176,40</dailyAmount>
          </addInfo>
        </response>
      </soapenv:Body>
</soapenv:Envelope>`
};

export default fakeResponses;
