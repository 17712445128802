import React, { useState, useEffect, useContext } from 'react';
import GridLayout from 'react-grid-layout';
import { Box } from '@material-ui/core';
import typy from 'typy';
import { GET_ROOMS, GET_TABLES } from '../../../graphql/tables/queries';
import TableItem from '../../ViewTables/Tables/TableItem';
import { CustomModal } from '../../../common-fe/src';
import Tabs from '../../ViewTables/Tables/Tabs';
import { AuthContext } from '../../../contexts/AuthContext';
import useCustomQuery from '../../../hooks/useCustomQuery';

const TablesModal = ({ showTablesModal, closeModal, onTableClick }) => {
  const [selectedRoomUUID, setSelectedRoomUUID] = useState(null);
  const { appOutletUUID } = useContext(AuthContext);

  // Queries
  const tables = useCustomQuery(GET_TABLES, {
    variables: { fbRoomUUID: selectedRoomUUID },
    skip: !selectedRoomUUID
  });

  const rooms = useCustomQuery(GET_ROOMS, {
    variables: { fbOutletUUID: appOutletUUID },
    onCompleted: (data) => {
      const roomUUID = data.getFbRooms?.data[0]?.uuid ?? null;
      setSelectedRoomUUID(roomUUID);
      if (roomUUID) {
        tables.refetch({ fbRoomUUID: typy(data, 'getFbRooms.data[0].uuid').safeString });
      }
    }
  });

  useEffect(() => {
    if (selectedRoomUUID) {
      rooms.refetch({ fbRoomUUID: selectedRoomUUID });
    }
  }, [selectedRoomUUID]);

  return (
    <CustomModal
      showModal={showTablesModal}
      onClose={closeModal}
      styleBody={{ overflow: 'auto', flexFlow: 'column', justifyContent: 'flex-start' }}
      stylePaper={{ width: '80%' }}
    >
      <Tabs
        showIsEditable={false}
        selectedTab={selectedRoomUUID}
        tabsRooms={typy(rooms, 'data.getFbRooms.data').safeArray}
        containerStyles={{ maxWidth: 'unset' }}
        wrapperStyles={{ borderBottom: 'none' }}
        onTabClickHandler={(tabUUID) => setSelectedRoomUUID(tabUUID)}
      />
      <GridLayout
        isDraggable={false}
        measureBeforeMount
        isResizable={false}
        margin={[50, 50]}
        rowHeight={40}
        className="layout"
        compactType={null}
        cols={20}
        width={4000}
      >
        {typy(tables, 'data.getFbTables.data').safeArray.map((table) => {
          return (
            <Box key={table.id} data-grid={{ x: table.positionX, y: table.positionY, w: 1, h: 1 }}>
              <TableItem table={table} onTableClick={() => onTableClick(table)} onChairClick={() => {}} />
            </Box>
          );
        })}
      </GridLayout>
    </CustomModal>
  );
};

export default TablesModal;
