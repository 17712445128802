import { gql } from 'apollo-boost';
import { USER_FRAGMENT } from '../../fragments';

export const GET_USER_INFO = gql`
  query getUserInfo {
    getUserInfo {
      ...userFragment
    }
  }
  ${USER_FRAGMENT}
`;
