import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';
import { Box, Typography } from '@material-ui/core';
import { TranslateTextComponent } from '../../translator';
import { AuthContext } from '../../contexts/AuthContext';
import { MaterialIcon } from '../../common-fe';
import { invertedPrimary } from '../../styles/theme';
import styles from '../../styles/shared/headerOptions';
import { ROUTE_ACCOUNT, ROUTE_OUTLETS_LIST, ROUTE_GUEST_USERS_LIST, ROUTE_STAFF_USERS_LIST } from '../../router/routes';
import { ROLES } from '../../constants/users/usersRole';
import Guard from './Guard';

const HeaderMenuContent = () => {
  const authContext = useContext(AuthContext);
  const client = useApolloClient();
  const classes = styles();

  const handleLogOut = () => {
    if (authContext) {
      authContext.logout();
      client.resetStore();
    }
  };

  return (
    <Box>
      <Link to={ROUTE_ACCOUNT} style={{ textDecoration: 'none' }}>
        <Box className={classes.userOptionsItems}>
          <MaterialIcon iconName="account_circle" style={{ color: invertedPrimary, fontSize: 20 }} />
          <Typography style={{ color: invertedPrimary, fontSize: 14, marginLeft: 5 }}>
            <TranslateTextComponent capitalize>account</TranslateTextComponent>
          </Typography>
        </Box>
      </Link>
      <Guard roles={[ROLES.ROLE_SUPER_ADMIN]}>
        <Link to={ROUTE_OUTLETS_LIST} style={{ textDecoration: 'none' }}>
          <Box className={classes.userOptionsItems}>
            <MaterialIcon iconName="location_on" style={{ color: invertedPrimary, fontSize: 20 }} />
            <Typography style={{ color: invertedPrimary, fontSize: 14, marginLeft: 5 }}>
              <TranslateTextComponent capitalize>outlets</TranslateTextComponent>
            </Typography>
          </Box>
        </Link>
      </Guard>
      <Guard roles={[ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_SUPERVISOR]}>
        <Link to={ROUTE_STAFF_USERS_LIST} style={{ textDecoration: 'none' }}>
          <Box className={classes.userOptionsItems}>
            <MaterialIcon iconName="group" style={{ color: invertedPrimary, fontSize: 20 }} />
            <Typography style={{ color: invertedPrimary, fontSize: 14, marginLeft: 5 }}>
              <TranslateTextComponent capitalize>staff-users</TranslateTextComponent>
            </Typography>
          </Box>
        </Link>
      </Guard>
      <Guard roles={[ROLES.ROLE_LOCAL, ROLES.ROLE_SUPERVISOR, ROLES.ROLE_FRONT_OFFICE, ROLES.ROLE_GUEST_SERVICE]}>
        <Link to={ROUTE_GUEST_USERS_LIST} style={{ textDecoration: 'none' }}>
          <Box className={classes.userOptionsItems}>
            <MaterialIcon iconName="group" style={{ color: invertedPrimary, fontSize: 20 }} />
            <Typography style={{ color: invertedPrimary, fontSize: 14, marginLeft: 5 }}>
              <TranslateTextComponent capitalize>guest-users</TranslateTextComponent>
            </Typography>
          </Box>
        </Link>
      </Guard>
      <Box className={classes.userOptionsItems} onClick={handleLogOut}>
        <MaterialIcon iconName="power_settings_new" style={{ color: invertedPrimary, fontSize: 20 }} />
        <Box>
          <Typography style={{ color: invertedPrimary, fontSize: 14, marginLeft: 5 }}>
            <TranslateTextComponent capitalize>logout</TranslateTextComponent>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderMenuContent;
