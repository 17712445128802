import { makeStyles } from '@material-ui/core/styles';

const styles = {
  genderIcon: ({ color, isClickable }) => ({
    cursor: isClickable ? 'pointer' : 'default',
    borderRadius: 6,
    height: 20,
    width: 20,
    '&:hover .filled': { fill: isClickable && color },
    '&.selected .filled': { fill: color }
  })
};

export default makeStyles(styles);
