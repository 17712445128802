/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';
import {
  FB_OUTLETS_FRAGMENT,
  FB_ROOMS_FRAGMENT,
  PROFILE_PHYSICAL_CATEGORIES_FRAGMENT,
  OUTLET_CATEGORIES_FRAGMENT,
  ROOM_FRAGMENT
} from '../fragments';

export const GET_FB_OUTLET = gql`
  query getFbOutlet($fbOutletUUID: String!) {
    getFbOutlet(fbOutletUUID: $fbOutletUUID) {
      ...fbOutletsFragment
      fbRooms {
        ...fbRoomsFragment
      }
      guestPhysicalCategories {
        ...profilePhysicalCategoriesFragment
      }
      outletCategories {
        ...outletCategoriesFragment
      }
    }
  }
  ${FB_OUTLETS_FRAGMENT}
  ${FB_ROOMS_FRAGMENT}
  ${PROFILE_PHYSICAL_CATEGORIES_FRAGMENT}
  ${OUTLET_CATEGORIES_FRAGMENT}
`;

export const GET_OUTLET_STRIPE_BALANCE = gql`
  query getOutletStripeBalance($fbOutletUUID: String!) {
    getOutletStripeBalance(fbOutletUUID: $fbOutletUUID) {
      total
    }
  }
`;

export const GET_FB_OUTLETS = gql`
  query getFbOutlets(
    $page: Int
    $pageSize: Int
    $orderBy: String
    $orderType: String
    $isTakeAwayEnabled: Boolean
    $outletCategoryUUID: String
    $isDeliveryEnabled: Boolean
    $keyword: String
  ) {
    getFbOutlets(
      page: $page
      pageSize: $pageSize
      orderBy: $orderBy
      orderType: $orderType
      isTakeAwayEnabled: $isTakeAwayEnabled
      outletCategoryUUID: $outletCategoryUUID
      isDeliveryEnabled: $isDeliveryEnabled
      keyword: $keyword
    ) {
      totalCount
      data {
        ...fbOutletsFragment
        fbRooms {
          ...fbRoomsFragment
        }
        guestPhysicalCategories {
          ...profilePhysicalCategoriesFragment
        }
        outletCategories {
          ...outletCategoriesFragment
        }
      }
    }
  }
  ${FB_OUTLETS_FRAGMENT}
  ${FB_ROOMS_FRAGMENT}
  ${PROFILE_PHYSICAL_CATEGORIES_FRAGMENT}
  ${OUTLET_CATEGORIES_FRAGMENT}
`;

export const GET_ROOMS = gql`
  query getRooms($orderBy: String, $orderType: String, $page: Int, $pageSize: Int) {
    getRooms(orderBy: $orderBy, orderType: $orderType, page: $page, pageSize: $pageSize) {
      totalCount
      data {
        ...roomFragment
      }
    }
  }
  ${ROOM_FRAGMENT}
`;
