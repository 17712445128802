import React from 'react';
import { TranslateTextComponent } from '../../translator';

const tabElements = [
  {
    key: 'generals',
    label: <TranslateTextComponent uppercase>generals-tab</TranslateTextComponent>
  },
  {
    key: 'reservations',
    label: <TranslateTextComponent uppercase>reservations-tab</TranslateTextComponent>
  },
  {
    key: 'room',
    label: <TranslateTextComponent uppercase>room-tab</TranslateTextComponent>
  },
  {
    key: 'service',
    label: <TranslateTextComponent uppercase>service-tab</TranslateTextComponent>
  },
  {
    key: 'segments',
    label: <TranslateTextComponent uppercase>segments-tab</TranslateTextComponent>
  },
  {
    key: 'moh-delivery',
    label: <TranslateTextComponent uppercase>app-delivery</TranslateTextComponent>
  },
  {
    key: 'moh-takeaway',
    label: <TranslateTextComponent uppercase>app-takeaway</TranslateTextComponent>
  },
  {
    key: 'moh-services',
    label: <TranslateTextComponent uppercase>services-list</TranslateTextComponent>
  },
  // {
  //   key: 'qr-code',
  //   label: <TranslateTextComponent uppercase>qr-code</TranslateTextComponent>
  // },
  {
    key: 'advanced',
    label: <TranslateTextComponent uppercase>advanced</TranslateTextComponent>
  }
];

export default tabElements;
