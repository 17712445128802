import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { ReactComponent as CalendarImg } from '../../assets/images/calendar.svg';
import styles from '../../styles/shared/dataPicker';

const DataPicker = ({
  value,
  onChange,
  containerStyle,
  dataPickerStyle,
  inputPropsStyle,
  fullWidth,
  inputTextAlign,
  error,
  errorLabel,
  disabled
}) => {
  const svgHeight = dataPickerStyle && dataPickerStyle.height ? dataPickerStyle.height : 40;
  const classes = styles({ svgHeight, inputTextAlign });

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div style={{ display: 'flex', flexFlow: 'column', width: '100%', ...containerStyle }}>
        <KeyboardDatePicker
          className={classes.keyboardDatePicker}
          fullWidth={fullWidth}
          autoComplete="off"
          autoOk
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          placeholder="GG/MM/AAAA"
          style={dataPickerStyle}
          value={value}
          disabled={disabled}
          onChange={onChange}
          inputVariant="outlined"
          inputProps={{
            style: { padding: 0, minHeight: 30, textIndent: 10, ...inputPropsStyle }
          }}
          keyboardIcon={<CalendarImg />}
        />
        {error ? <span style={{ color: 'red', fontSize: 10, display: 'flex', flexDirection: 'column' }}>{errorLabel}</span> : null}
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default DataPicker;
