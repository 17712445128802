/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const ADD_FB_SERVICE_MUTATION = gql`
  mutation addFbService($fbOutletUUID: String!, $fbServiceData: FbServicesInput!) {
    addFbService(fbOutletUUID: $fbOutletUUID, fbServiceData: $fbServiceData) {
      id
      uuid
      name
      description
      icon
      startTime
      endTime
      isActive
      lastReservation
      slotSizeMinutes
      maxGuestsForSlot
    }
  }
`;

export const DELETE_FB_SERVICE_MUTATION = gql`
  mutation deleteFbService($fbServiceUUID: String!) {
    deleteFbService(fbServiceUUID: $fbServiceUUID)
  }
`;

export const EDIT_FB_SERVICE_MUTATION = gql`
  mutation editFbService($fbServiceUUID: String!, $fbServiceData: FbServicesInput!) {
    editFbService(fbServiceUUID: $fbServiceUUID, fbServiceData: $fbServiceData) {
      id
      uuid
      name
      description
      icon
      startTime
      endTime
      isActive
      lastReservation
      slotSizeMinutes
      maxGuestsForSlot
    }
  }
`;
