import React from 'react';
import { Typography, Box, Modal, Fade, Paper, Grid } from '@material-ui/core/';
import { TranslateTextComponent } from '../../translator';
import { CommonTable, MaterialIcon, SearchBar } from '../../common-fe';
import wineHistory from '../../fixtures/cash/wineHistory';
import getHistoryColumns from '../../constants/shared/winesHistoryColumns';
import { primaryColor } from '../../styles/theme';
import modalStyle from '../../styles/shared/modalStyle';

const WinesHistoryModal = ({ open, onClose }) => {
  const classes = modalStyle();

  const getDetailComponent = (row) => {
    return (
      <p>
        {row.name} --- {row.fbMenuItem.fbMenuCategory.description}
      </p>
    );
  };

  const getTableHeader = () => {
    return (
      <Box>
        <Typography
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: primaryColor,
            color: 'white',
            fontWeight: 'bold',
            fontSize: 30,
            height: 60
          }}
        >
          <TranslateTextComponent uppercase>wine history</TranslateTextComponent>
        </Typography>
        <Grid
          container
          direction="row"
          style={{
            margin: '15px 0',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Grid>
            <SearchBar style={{ height: 40, fontSize: 12 }} />
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Modal className={classes.modalWinesOrdersHistory} open={open} onClose={onClose}>
      <Fade in style={{ width: '90%', borderRadius: 10 }}>
        <Paper className={classes.paperWinesOrdersHistory}>
          <Box className={classes.closeModalButton} onClick={onClose}>
            <MaterialIcon iconName="Cancel" style={{ fontSize: 40, color: primaryColor }} />
          </Box>
          <CommonTable
            isPaginationEnabled
            size="small"
            tableHeader={getTableHeader()}
            items={wineHistory}
            columns={getHistoryColumns()}
            tableContainerStyle={{ maxHeight: '70vh', overflowY: 'scroll' }}
            headerTextStyle={
              {
                // color: 'red',
                // fontWeight: 'bold'
              }
            }
            rowStyle={
              {
                // border: 0
              }
            }
            rowCellStyle={{
              height: 20
              // paddingLeft: 10
            }}
            renderDetailsComponent={[
              {
                buttonLabel: 'ordine',
                renderFunction: (row) => getDetailComponent(row)
              },
              {
                buttonLabel: 'dettagli',
                renderFunction: (row) => getDetailComponent(row)
              }
            ]}
          />
        </Paper>
      </Fade>
    </Modal>
  );
};

export default WinesHistoryModal;
