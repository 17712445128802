import gql from 'graphql-tag';

export const GET_CHANNELS = gql`
  query getChannels($page: Int, $pageSize: Int) {
    getChannels(page: $page, pageSize: $pageSize) {
      totalCount
      data {
        id
        uuid
        name
        description
      }
    }
  }
`;
