import React from 'react';
import { Tabs as MUITabs } from '@material-ui/core';
import TabItem from './TabItem';
import styles from '../../../styles/records/tabs/style';

const Tabs = ({ selectedTab, changeTab }) => {
  const classes = styles();

  return (
    <MUITabs className={classes.container} variant="fullWidth" value={selectedTab}>
      <TabItem className={classes.tabItem} label="tables" value="tables" selectedTab={selectedTab} onClick={() => changeTab('tables')} />
      <TabItem
        className={classes.tabItem}
        label="reservations"
        value="reservations"
        selectedTab={selectedTab}
        onClick={() => changeTab('reservations')}
      />
    </MUITabs>
  );
};

export default Tabs;
