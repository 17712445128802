import React, { useState, useContext } from 'react';
import Spinner from 'react-loader-spinner';
import typy from 'typy';
import moment from 'moment';
import * as _ from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { Box, RadioGroup, FormControlLabel, Radio, Switch, Button, TablePagination } from '@material-ui/core';
import MainContainer from '../shared/MainContainer';
import Wrapper from '../shared/Wrapper';
import LeftContainer from '../shared/LeftContainer';
import Input from '../shared/Input';
import { DataPicker, redButton } from '../../../common-fe/src';
import { TranslateTextComponent } from '../../../translator';
import WideCardContainer from '../shared/WideCard/WideCardContainer';
import SummaryWithoutBorder from '../shared/WideCard/Summaries/SummaryWithoutBorder';
import { getIcon } from '../../../utils/billsManagement';
import styles from '../../../styles/billsManagement/input';
import buttonsStyles from '../../../styles/billsManagement/buttons';
import { PM_CHARGE } from '../../../constants/billsManagement/paymentMethods';
import NewPmModal from './NewPmModal';
import PMDetails from '../shared/WideCard/Details/PMDetails';
import Check from '../../cash/Modals/Check';
import { CREATE_PAY_MASTER_BILL } from '../../../graphql/billsManagement/mutations';
import { ModalContext } from '../../../contexts/ModalContext';
import { ROUTE_PM_CASH } from '../../../router/routes';

const PM = ({
  isLoading,
  data,
  refetchGetPayMaster,
  pageSize,
  page,
  onPageChange,
  onPageSizeChange,
  totalCount,
  onFilterChange,
  onCheckBoxFilterChange
}) => {
  const [date, setDate] = useState(null);
  const [showCheckModalBill, setShowCheckModalBill] = useState(null);
  const [checkedPM, setCheckedPM] = useState(null);
  const [checkedFbBills, setCheckedFbBills] = useState([]);
  const [showNewPmModal, setShowNewPmModal] = useState(false);
  const [pmToEdit, setPmToEdit] = useState(null);
  // const [selectedPayMasterUUID, setSelectedPayMasterUUID] = useState(null);
  const [createPayMasterBill] = useMutation(CREATE_PAY_MASTER_BILL);

  const history = useHistory();
  const modalContext = useContext(ModalContext);

  const onCheckPm = (pmUUID, fbBillUUID) => {
    if (pmUUID !== checkedPM) {
      setCheckedPM(pmUUID);
      setCheckedFbBills([fbBillUUID]);
    } else {
      const newFbBills = _.xor(checkedFbBills, [fbBillUUID]);
      setCheckedFbBills(newFbBills);
    }
  };

  const onPMCheckBoxChange = (pm) => {
    setCheckedPM(pm.uuid);
    const fbBillUUIDs = pm.fbBills.filter((fbB) => !fbB.isPaid).map((fbB) => fbB.uuid);
    setCheckedFbBills(fbBillUUIDs);
  };

  const createPayMasterBillFast = (fbBillUUID) => {
    onCreatePayMasterBill(fbBillUUID);
  };

  const onCreatePayMasterBill = (fbBillUUID = null) => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>confirm</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>confirm-pay-pay-master</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="contained"
          color="primary"
          onClick={() => {
            modalContext.closeModal();
            setCheckedPM(null);
            setCheckedFbBills([]);
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          variant="contained"
          color="primary"
          onClick={() => {
            modalContext.closeModal();
            createPayMasterBill({
              variables: {
                fbBillUUIDs: fbBillUUID ? [fbBillUUID] : checkedFbBills
              }
            })
              .then((res) => {
                history.push(`${ROUTE_PM_CASH}/${res.data.createPayMasterBill.uuid}`);
              })
              .catch((err) => console.log(err));
          }}
        >
          <TranslateTextComponent uppercase>ok</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const classes = styles();
  const classesButtons = buttonsStyles();

  const onNewPmClickHandler = () => {
    setShowNewPmModal(true);
  };

  const closeModals = () => {
    setShowNewPmModal(false);
  };

  return (
    <Wrapper>
      <LeftContainer>
        <Box>
          <Input title="search" placeholder="search" onChange={(e) => onFilterChange('client', e.target.value)} />
          <Input
            title="date-filter"
            component={
              <DataPicker
                dataPickerStyle={{ overflow: 'hidden' }}
                value={date}
                onChange={(e) => {
                  if (moment(e).isValid()) {
                    setDate(e);
                    onFilterChange('date', e);
                  }
                }}
              />
            }
          />
          <Input
            title="search-document-number-shortened"
            customIcon="Receipt"
            placeholder="placeholder-search-check-ticket"
            onChange={(e) => onFilterChange('document', e.target.value)}
          />
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Input
              title="display"
              type="radio"
              component={
                <RadioGroup defaultValue="all" onChange={(e) => onCheckBoxFilterChange({ type: 'duration', pmDuration: e.target.value })}>
                  <FormControlLabel
                    value="all"
                    control={<Radio className={classes.radio} size="small" />}
                    label={
                      <TranslateTextComponent className={classes.radioText} style={{ fontSize: 'red' }} capitalize>
                        all
                      </TranslateTextComponent>
                    }
                  />
                  <FormControlLabel
                    value="temporary"
                    control={<Radio className={classes.radio} size="small" />}
                    label={
                      <TranslateTextComponent className={classes.radioText} style={{ fontSize: 'red' }} capitalize>
                        temporary
                      </TranslateTextComponent>
                    }
                  />
                  <FormControlLabel
                    value="undefined"
                    control={<Radio className={classes.radio} size="small" />}
                    label={
                      <TranslateTextComponent className={classes.radioText} style={{ fontSize: 'red' }} capitalize>
                        undefined
                      </TranslateTextComponent>
                    }
                  />
                </RadioGroup>
              }
            />
          </Box>
          <Input
            title="display-paid-pm"
            component={
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <TranslateTextComponent capitalize>yes</TranslateTextComponent>
                <Switch className={classes.switch} color="primary" />
                <TranslateTextComponent capitalize>no</TranslateTextComponent>
              </Box>
            }
            style={{ margin: 0 }}
          />
        </Box>
        <Box className={classesButtons.container}>
          <Button
            className={classesButtons.button}
            color="primary"
            variant="contained"
            style={{ margin: '2px 0' }}
            disabled={!checkedPM}
            onClick={() => {
              const selectedPM = data.find((pm) => pm.uuid === checkedPM);

              const newPm = {
                payMasterUUID: typy(selectedPM, 'uuid').safeString,
                guestUUID: typy(selectedPM, 'guest.uuid').safeString,
                type: typy(selectedPM, 'type').safeString,
                name: typy(selectedPM, 'name').safeString,
                dob: typy(selectedPM, 'guest.dob').safeString,
                birthplace: typy(selectedPM, 'guest.birthplace').safeString,
                vatNumber: typy(selectedPM, 'guest.vatNumber').safeString,
                fiscalCode: typy(selectedPM, 'guest.fiscalCode').safeString,
                address: typy(selectedPM, 'guest.address').safeString,
                province: typy(selectedPM, 'guest.province').safeString,
                phone: typy(selectedPM, 'guest.phone').safeString,
                mobilePhone: typy(selectedPM, 'guest.mobilePhone').safeString,
                certifiedEmail: typy(selectedPM, 'guest.certifiedEmail').safeString,
                pmDuration: typy(selectedPM, 'expirationDate').safeString === '' ? 'notDefined' : 'temporary',
                expirationDate: typy(selectedPM, 'expirationDate').safeString,
                firstname: typy(selectedPM, 'guest.firstname').safeString,
                lastname: typy(selectedPM, 'guest.lastname').safeString,
                company: typy(selectedPM, 'guest.company').safeString,
                country: typy(selectedPM, 'guest.country').safeString,
                zipCode: typy(selectedPM, 'guest.zipCode').safeString,
                city: typy(selectedPM, 'guest.city').safeString,
                website: typy(selectedPM, 'guest.website').safeString,
                email: typy(selectedPM, 'guest.email').safeString,
                guestList: typy(selectedPM, 'guestList').safeArray
              };
              setPmToEdit(newPm);
              onNewPmClickHandler();
            }}
          >
            <TranslateTextComponent>edit</TranslateTextComponent>
          </Button>
          <Box className={classesButtons.doubleContainer}>
            <Button
              className={classesButtons.button}
              disabled={checkedFbBills.length === 0}
              color="primary"
              variant="contained"
              style={{ margin: '2px 0' }}
              onClick={() => onCreatePayMasterBill()}
            >
              <TranslateTextComponent>pay</TranslateTextComponent>
            </Button>
            <Button className={classesButtons.button} color="primary" variant="contained" style={{ margin: '2px 0' }}>
              <TranslateTextComponent>proforma</TranslateTextComponent>
            </Button>
          </Box>
          <Button
            className={classesButtons.button}
            color="primary"
            variant="contained"
            style={{ margin: '2px 0' }}
            onClick={() => {
              setPmToEdit(null);
              onNewPmClickHandler();
            }}
          >
            <TranslateTextComponent>new-pm</TranslateTextComponent>
          </Button>
        </Box>
      </LeftContainer>
      {showNewPmModal && (
        <NewPmModal pmToEdit={pmToEdit} showNewPmModal={showNewPmModal} closeModal={closeModals} refetchGetPayMaster={refetchGetPayMaster} />
      )}
      <MainContainer>
        <Box style={{ overflow: 'auto' }}>
          {isLoading ? (
            <Box style={{ display: 'flex', justifyContent: 'center', border: 'none', margin: 'auto', paddingTop: 50 }}>
              <Spinner type="TailSpin" color="#00BFFF" height={100} width={100} />
            </Box>
          ) : (
            data.map((d) => {
              let totalPaid = 0;
              if (d.fbBills && d.fbBills.length) {
                d.fbBills.forEach((bill) => {
                  totalPaid += bill.isPaid ? bill.total : 0;
                });
              }
              const total = d.fbBills.length && d.fbBills.reduce((prev, current) => ({ total: prev.total + current.total }));
              return (
                <WideCardContainer
                  key={d.uuid}
                  withCheckBox
                  withoutPaddingDetails
                  checked={d.uuid === checkedPM}
                  onCheckBoxChange={() => onPMCheckBoxChange(d)}
                  summaryComponent={
                    <SummaryWithoutBorder
                      data={d}
                      summaryType={PM_CHARGE}
                      leftComponent={
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                          {getIcon('multiple-persons')}
                          <span style={{ marginLeft: 10 }}>{d.name}</span>
                        </Box>
                      }
                      rightComponent={
                        <Box>
                          <span style={{ color: redButton }}>€ {totalPaid.toFixed(2).replace('.', ',')}</span> /{' '}
                          <span>€ {total.total.toFixed(2).replace('.', ',') || 0}</span>
                        </Box>
                      }
                    />
                  }
                  detailsComponent={
                    <PMDetails
                      pmData={d}
                      checkedFbBills={checkedFbBills}
                      onShowCheckHandler={(fbBill) => setShowCheckModalBill(fbBill)}
                      onCreatePayMasterBillFast={createPayMasterBillFast}
                      onCheckPm={onCheckPm}
                    />
                  }
                />
              );
            })
          )}
        </Box>
        {showCheckModalBill !== null && (
          <Check showCheckModal={showCheckModalBill !== null} currentOrderBill={showCheckModalBill} closeModal={() => setShowCheckModalBill(null)} />
        )}
        <TablePagination
          style={{ display: 'flex', justifyContent: 'center' }}
          component="div"
          count={totalCount}
          rowsPerPageOptions={[5, 10, 25]}
          rowsPerPage={pageSize}
          page={page - 1}
          onChangePage={(e, page) => onPageChange(page)}
          onChangeRowsPerPage={(e) => onPageSizeChange(e.target.value)}
        />
      </MainContainer>
    </Wrapper>
  );
};

export default PM;
