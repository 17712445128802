import { makeStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    gridArea: 'right',
    padding: '8px 10px'
  }
};

export default makeStyles(styles);
