import { primaryColor } from '../../styles/theme';

const FB_RESERVATION_STATUSES = {
  open: { color: primaryColor, label: 'open' },
  working: { color: '#EE9800', label: 'working' },
  completed: { color: '#449D44', label: 'completed' },
  missed: { color: '#55595C', label: 'missed' },
  deleted: { color: '#CB2D24', label: 'deleted' }
};

export default FB_RESERVATION_STATUSES;
