import React, { useState, useRef } from 'react';
import { Grid, Box } from '@material-ui/core';
import typy from 'typy';
import { borderTheme, CommonTable, CustomButton, CustomModal, SearchBar, secondaryColor, redButton, greenButton } from '../../../common-fe/src';
import getChargeModalColumns from './columns/getChargeModalColumns';
import PMForm from '../PM/PMForm/PMForm';
import PMUsers from '../PM/PMUsers/PMUsers';
import BillReceipt from '../../shared/BillReceipt';
import { GET_PAY_MASTERS } from '../../../graphql/cash/queries';
import PMDetails from './columns/PMDetails';
import useCustomQuery from '../../../hooks/useCustomQuery';
import { TranslateTextComponent } from '../../../translator';

const initialFilters = {
  keyword: ''
};

const ChargeModal = ({
  activeBillTabUUID,
  showChargeModal,
  selectedPayMasterUUID,
  selectedGuestUUID,
  currentOrderBill,
  closeModal,
  onPayMasterCheck,
  onPayMasterDetailCheck,
  onPayMasterCharge
}) => {
  const [showPMForm, setShowPMForm] = useState(false);
  const [isShowingBillReceipt, setIsShowingBillReceipt] = useState(false);
  const [isShowingDigitalBillReceipt, setIsShowingDigitalBillReceipt] = useState(false);
  const [isPMGroup, setIsPMGroup] = useState(false);
  const [guestsList, setGuestsList] = useState([]);
  const [queryFilters, setQueryFilters] = useState(initialFilters);
  const formRef = useRef(null);

  // Query
  const getPayMasters = useCustomQuery(GET_PAY_MASTERS, {
    variables: { keyword: queryFilters.keyword },
    fetchPolicy: 'network-only'
  });

  const setNewFilter = (key, value) => {
    setQueryFilters({
      ...queryFilters,
      keyword: key === 'keyword' ? null : queryFilters.keyword,
      [key]: value || null
    });
  };

  const chargeModalColumns = getChargeModalColumns({ onPayMasterCheck, selectedPayMasterUUID });

  const onNewPMClickHandler = () => {
    setShowPMForm(true);
  };

  const onPMFormSubmit = () => {
    formRef.current.handleSubmit();
  };

  const onAddGuestClickHandler = (guest) => {
    if (!guestsList.some((g) => g.uuid === guest.uuid)) setGuestsList((prev) => [...prev, guest]);
  };

  const onRemoveGuestClickHandler = (guestUUID) => {
    setGuestsList((prev) => prev.filter((guest) => guest.uuid !== guestUUID));
  };

  const resetGuestsList = () => {
    setGuestsList([]);
  };

  return (
    <CustomModal
      showModal={showChargeModal}
      hideCloseButton
      onClose={closeModal}
      width="auto"
      height="auto"
      styledHeader
      stylePaper={{ background: 'none', maxWidth: !showPMForm ? 750 : 'unset' }}
      styleBody={{
        backgroundColor: 'white',
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        minHeight: 306,
        maxHeight: 461,
        minWidth: 665,
        overflow: 'auto'
      }}
      header="PM"
    >
      {(!showPMForm && (
        <Grid style={{ width: '95%' }}>
          <Grid item style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SearchBar
              style={{ height: 40, minWidth: 430 }}
              onClickIcon={() => setNewFilter('keyword', '')}
              value={queryFilters.keyword || ''}
              onChange={(event) => setNewFilter('keyword', event.target.value)}
            />
            <CustomButton style={{ height: 40, maxWidth: 175 }} onClick={onNewPMClickHandler}>
              <TranslateTextComponent>new-pm</TranslateTextComponent>
            </CustomButton>
          </Grid>
          <Grid item style={{ maxHeight: 300, overflow: 'auto' }}>
            <CommonTable
              isLoading={getPayMasters && getPayMasters.loading}
              size="small"
              headerCellStyle={{ backgroundColor: secondaryColor, borderBottom: borderTheme }}
              tableContainerStyle={{ marginTop: 10, flex: 1, overflowY: 'auto' }}
              items={typy(getPayMasters, 'data.getPayMasters.data').safeArray}
              columns={chargeModalColumns}
              renderDetailsComponent={[
                {
                  showIf: (row) => row.type === 'group',
                  columnLabel: <TranslateTextComponent capitalize>details</TranslateTextComponent>,
                  buttonLabel: <TranslateTextComponent capitalize>open</TranslateTextComponent>,
                  renderFunction: (data) => {
                    return (
                      <PMDetails
                        data={data}
                        selectedPayMasterUUID={selectedPayMasterUUID}
                        selectedGuestUUID={selectedGuestUUID}
                        onPayMasterCheck={onPayMasterCheck}
                        onPayMasterDetailCheck={onPayMasterDetailCheck}
                      />
                    );
                  }
                }
              ]}
            />
          </Grid>
        </Grid>
      )) || (
        <Box>
          <PMForm
            ref={formRef}
            refetchGetPayMaster={() => {
              getPayMasters.refetch();
            }}
            guestsList={guestsList}
            onPMGroupChange={() => setIsPMGroup((prev) => !prev)}
            onPayMasterCheck={onPayMasterCheck}
            resetGuestsList={resetGuestsList}
            closePMForm={() => setShowPMForm(false)}
          />
          {isPMGroup && <PMUsers guestsList={guestsList} onAddGuestClick={onAddGuestClickHandler} onRemoveGuestClick={onRemoveGuestClickHandler} />}
          <Box style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
            <CustomButton
              style={{ width: 'auto', minWidth: 155, backgroundColor: redButton }}
              onClick={() => {
                setShowPMForm(false);
                setIsPMGroup((prev) => (!prev ? prev : !prev));
              }}
            >
              <TranslateTextComponent>cancel</TranslateTextComponent>
            </CustomButton>
            <Box>
              <CustomButton style={{ width: 'auto', minWidth: 155, marginLeft: 5, backgroundColor: greenButton }} onClick={onPMFormSubmit}>
                <TranslateTextComponent>save</TranslateTextComponent>
              </CustomButton>
            </Box>
          </Box>
        </Box>
      )}
      {!showPMForm && (
        <Grid container style={{ width: '95%' }}>
          {isShowingBillReceipt && (
            <BillReceipt
              fbBillUUID={activeBillTabUUID}
              isPaid={currentOrderBill.fbPaymentMethod !== null}
              isOpen={isShowingBillReceipt}
              handleClose={() => setIsShowingBillReceipt(false)}
              onPayMasterCharge={onPayMasterCharge}
            />
          )}
          {isShowingDigitalBillReceipt && (
            <BillReceipt
              fbBillUUID={activeBillTabUUID}
              isPaid={currentOrderBill.fbPaymentMethod !== null}
              isDigitalCheck
              isOpen={isShowingDigitalBillReceipt}
              handleClose={() => setIsShowingDigitalBillReceipt(false)}
              onPayMasterCharge={onPayMasterCharge}
            />
          )}
          <Grid item xs style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', margin: '10px 0' }}>
            <CustomButton style={{ minWidth: 155, maxWidth: 180, backgroundColor: '#CB2D24' }} onClick={closeModal}>
              <TranslateTextComponent style={{ whiteSpace: 'nowrap' }}>delete</TranslateTextComponent>
            </CustomButton>
            <CustomButton
              style={{ minWidth: 155, maxWidth: 180, backgroundColor: '#449D44' }}
              disabled={!selectedPayMasterUUID}
              onClick={() => setIsShowingDigitalBillReceipt(true)}
            >
              <TranslateTextComponent style={{ whiteSpace: 'nowrap' }}>digital-check</TranslateTextComponent>
            </CustomButton>
            <CustomButton
              style={{ minWidth: 155, maxWidth: 180, backgroundColor: '#449D44' }}
              disabled={!selectedPayMasterUUID}
              onClick={() => setIsShowingBillReceipt(true)}
            >
              <TranslateTextComponent style={{ whiteSpace: 'nowrap' }}>paper-check</TranslateTextComponent>
            </CustomButton>
          </Grid>
        </Grid>
      )}
    </CustomModal>
  );
};

export default ChargeModal;
