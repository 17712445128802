import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { ButtonGroup as MUIButtonGroup, Button as MUIButton } from '@material-ui/core';
import { TranslateTextComponent } from '../../translator';
import { primaryColor } from '../../styles/theme';

const ButtonGroup = styled(MUIButtonGroup)`
  & .MuiButtonGroup-groupedContainedPrimary:not(:last-child) {
    border-right: ${() => primaryColor};
  }
`;

const Button = styled(MUIButton)`
  color: ${({ checked }) => (checked ? 'white' : primaryColor)};
  background-color: ${({ checked }) => (checked ? primaryColor : 'white')};

  &:hover {
    color: ${({ checked }) => (checked ? 'white' : primaryColor)};
  }
`;

const ButtonsGroup = ({ buttons, containerStyle, onChange }) => {
  return (
    <ButtonGroup color="primary" size="large" aria-label="large outlined primary button group" style={containerStyle}>
      {buttons.map((button, index) => (
        <Button
          key={index}
          variant={button.checked ? 'contained' : 'outlined'}
          color="primary"
          style={button.buttonStyle}
          checked={button.checked}
          onClick={() => onChange(index)}
        >
          <TranslateTextComponent style={button.labelStyle}>{button.label}</TranslateTextComponent>
        </Button>
      ))}
    </ButtonGroup>
  );
};

ButtonsGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      checked: PropTypes.bool,
      labelStyle: PropTypes.object,
      buttonStyle: PropTypes.object
    })
  ).isRequired
};

export default ButtonsGroup;
