import React from 'react';
import Cash from '../components/cash/Cash';
import Layout from '../components/shared/Layout';

const PMCashPage = ({ history, match }) => {
  return (
    <Layout fullScreenContainer history={history} section="cash">
      <Cash fbBillUUID={match.params.uuid} isPayingPM />
    </Layout>
  );
};

export default PMCashPage;
