import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { InputAdornment, CircularProgress, ClickAwayListener, Box } from '@material-ui/core';
import { InputTextLabel, primaryColor } from '../../common-fe/src';
import { capitalizeFirstLetter, translate } from '../../translator';
import { LanguageContext } from '../../contexts/LanguageContext';
import useCustomQuery from '../../hooks/useCustomQuery';
import { GET_ROOMS } from '../../graphql/reservations/queries';

const ResultsContainer = styled.ul`
  position: absolute;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 170px;
  background-color: white;
  border: 1px solid #d9d9d9;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: auto;
  z-index: 1;

  li {
    cursor: pointer;
    padding: 10px 5px;

    transition: background-color 0.3s;

    &:hover {
      background-color: ${() => primaryColor};
    }
  }
`;

const GuestRoomSearchBar = ({ title, value, onChange, onItemClick, inputStyle, resetRoomKeyword }) => {
  const { language } = useContext(LanguageContext);

  const [showResults, setShowResults] = useState(false);
  const [roomKeyword, setRoomKeyword] = useState('');

  const getRooms = useCustomQuery(GET_ROOMS, { variables: { page: 1, pageSize: 500 }, skip: roomKeyword === '' || roomKeyword === null });
  return (
    <Box style={{ width: '100%', position: 'relative' }}>
      <InputTextLabel
        value={value?.length > 0 || resetRoomKeyword ? value : roomKeyword}
        title={title && capitalizeFirstLetter(translate(title, language))}
        placeholder={capitalizeFirstLetter(translate('guest-room', language))}
        InputProps={{
          endAdornment: getRooms.loading && (
            <InputAdornment position="end">
              <CircularProgress color="primary" style={{ marginRight: 10, width: 25, height: 25 }} />
            </InputAdornment>
          )
        }}
        inputStyle={{
          fontSize: 14,
          height: 40,
          color: '#55595C',
          border: '1.5px solid',
          borderColor: primaryColor,
          paddingLeft: 10,
          borderRadius: 4,
          ...inputStyle
        }}
        onClick={() => {
          if (roomKeyword.length >= 1) {
            setShowResults(true);
          }
        }}
        onChange={(e) => {
          e.persist();
          onChange(e.target.value);
          setShowResults(true);
          setRoomKeyword(e.target.value);
        }}
      />

      {roomKeyword.length >= 1 && showResults && (
        <ClickAwayListener onClickAway={() => setShowResults(false)}>
          <ResultsContainer>
            {getRooms?.data?.getRooms?.data
              ?.sort((a, b) => a.number - b.number)
              ?.map((room, index) => {
                if (
                  room?.number.toLowerCase().includes(roomKeyword?.replace(/\s/g, '')?.toLowerCase()) ||
                  room?.name.toLowerCase().includes(roomKeyword?.replace(/\s/g, '')?.toLowerCase()) ||
                  `${room?.number.toLowerCase()}${room?.name.toLowerCase()}`.includes(roomKeyword?.replace(/\s/g, '')?.toLowerCase())
                )
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        onItemClick(room);
                        setShowResults(false);
                      }}
                      role="presentation"
                    >
                      {room?.number} - {room?.name}
                    </li>
                  );
                return [];
              })}
          </ResultsContainer>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default GuestRoomSearchBar;
