import React, { useState } from 'react';
import { Box, Typography, Popover } from '@material-ui/core';
import { TranslateTextComponent } from '../../../translator';
import { ReactComponent as FoodImg } from '../../../assets/images/kitchen/order/food.svg';
import { ReactComponent as FoodFilledImg } from '../../../assets/images/kitchen/order/food_filled.svg';
import { ReactComponent as IsWaitingCourseImg } from '../../../assets/images/kitchen/order/isWaitingCourse.svg';
import styles from '../../../styles/kitchen/fbCourse';
import FB_COURSE_STATUSES from '../../../constants/kitchen/courseStatus';

const FbCourseStatusIcon = ({ fbCourse, iconSize, onSelectStatus, onClickIcon, hideStatusBox }) => {
  const classes = styles({ status: fbCourse.fbCourseStatus, ...iconSize });
  const classesTodo = styles({ status: FB_COURSE_STATUSES.todo.label });
  const classesPreparing = styles({ status: FB_COURSE_STATUSES.preparing.label });
  const classesReady = styles({ status: FB_COURSE_STATUSES.ready.label });
  const [showStatuses, setShowStatuses] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const currentStatusStyle = { fontWeight: 'bold', textTransform: 'uppercase', marginLeft: 10 };
  const notCurrentStatusStyle = { textTransform: 'capitalize', marginLeft: 10 };

  const closeStatusBox = () => {
    setShowStatuses(false);
  };

  const getStatusIconBox = () => {
    if (fbCourse.isWaiting) {
      return <IsWaitingCourseImg className={[classes.iconSize, classes.statusFilledIcon].join(' ')} />;
    }
    return fbCourse.fbCourseStatus === FB_COURSE_STATUSES.none.label ? (
      <FoodImg
        className={classes.iconSize}
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.target);
          if (!hideStatusBox) {
            setShowStatuses(!showStatuses);
          }
        }}
      />
    ) : (
      <FoodFilledImg
        className={[classes.iconSize, classes.statusFilledIcon].join(' ')}
        style={{
          cursor: FB_COURSE_STATUSES.completed.label === fbCourse.fbCourseStatus || hideStatusBox === true ? 'default' : 'pointer'
        }}
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.target);
          if (!hideStatusBox) {
            setShowStatuses(!showStatuses);
          }
        }}
      />
    );
  };

  const getFbCourseStatusRow = (status, classesStatus) => {
    return (
      <Box
        className={classes.changeStatusIcon}
        style={{
          cursor: hideStatusBox === true ? 'default' : 'pointer'
        }}
        onClick={(e) => {
          e.stopPropagation();
          onSelectStatus(FB_COURSE_STATUSES[status].label);
          onClickIcon(closeStatusBox());
        }}
      >
        <FoodFilledImg className={[classes.iconSize, classesStatus.statusFilledIcon].join(' ')} />
        <Typography style={FB_COURSE_STATUSES[status].label === fbCourse.fbCourseStatus ? currentStatusStyle : notCurrentStatusStyle}>
          <TranslateTextComponent>{FB_COURSE_STATUSES[status].label}</TranslateTextComponent>
        </Typography>
      </Box>
    );
  };

  return (
    <Box>
      {getStatusIconBox()}
      {(fbCourse.fbCourseStatus === FB_COURSE_STATUSES.todo.label ||
        fbCourse.fbCourseStatus === FB_COURSE_STATUSES.preparing.label ||
        fbCourse.fbCourseStatus === FB_COURSE_STATUSES.ready.label) &&
        showStatuses && (
          <Popover
            open={showStatuses}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right'
            }}
            onClose={() => setShowStatuses(false)}
          >
            <Box className={classes.courseIconContainer}>
              {getFbCourseStatusRow('todo', classesTodo)}
              {getFbCourseStatusRow('preparing', classesPreparing)}
              {getFbCourseStatusRow('ready', classesReady)}
            </Box>
          </Popover>
        )}
    </Box>
  );
};
export default FbCourseStatusIcon;
