/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { TranslateTextComponent, capitalizeFirstLetter } from '../../../../translator';

const textTranslate = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

const bookingServicesListColumns = () => {
  return [
    {
      id: 'name',
      label: textTranslate('name'),
      isSortable: false,
      align: 'left',
      render: (item) => capitalizeFirstLetter(item.name ?? '-')
    },
    {
      id: 'quantity',
      label: textTranslate('quantity'),
      isSortable: false,
      align: 'left',
      render: (item) => item.quantity ?? '-'
    },
    {
      id: 'price',
      label: textTranslate('unit-price'),
      isSortable: false,
      align: 'left',
      render: (item) => item.price?.toFixed(2) ?? '-'
    }
  ];
};
export default bookingServicesListColumns;
