import { makeStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    gridArea: 'options-container',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    borderTop: '2px solid #00B7FF',
    '& button': {
      borderRadius: 10,
      backgroundColor: '#00B7FF',
      maxHeight: 50,
      maxWidth: 130,
      margin: 5,
      color: 'white',
      lineHeight: 1,
      fontSize: 20,
      textTransform: 'uppercase',
      textAlign: 'center'
    }
  }
};

export default makeStyles(styles);
