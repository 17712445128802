/* eslint-disable import/prefer-default-export */

export const suggestionsIngredients = [
  'grana padano',
  'pepe',
  'peperoncino',
  'formaggio',
  'pomodoro',
  'cacio ricotta',
  'rucola',
  'mozzarella',
  'ricotta',
  'panna',
  'carote',
  'funghi',
  'basilico',
  'bresaola',
  'capperi',
  'cetrioli',
  'cipolla',
  'finocchio',
  'olive',
  'tonno',
  'uovo sodo'
];
