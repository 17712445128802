import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { TranslateTextComponent } from '../../translator';
import { ReactComponent as FoodImg } from '../../assets/images/order/food.svg';
import { ReactComponent as FoodFilledImg } from '../../assets/images/order/food_filled.svg';
import { ReactComponent as DrinkImg } from '../../assets/images/order/drink.svg';
import { ReactComponent as DrinkFilledImg } from '../../assets/images/order/drink_filled.svg';
import { ReactComponent as IsWaitingCourseImg } from '../../assets/images/order/isWaitingCourse.svg';
import styles from '../../styles/order/fbCourse';
import FB_ORDER_STATUSES from '../../constants/order/orderStatuses';
import { borderTheme } from '../../styles/theme';

const useOutsideAlerter = (windowStatusesRef, iconRef, onClickIcon) => {
  /**
   * Alert if clicked on outside of element
   */
  const handleClickOutside = (event) => {
    if (
      windowStatusesRef.current &&
      !windowStatusesRef.current.contains(event.target) &&
      iconRef.current &&
      !iconRef.current.contains(event.target)
    ) {
      onClickIcon(null);
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

const FbCourseStatusIcon = ({ onSelectStatus, macroCategory, fbCourse, showStatuses, onClickIcon }) => {
  const classes = styles({ status: fbCourse.fbCourseStatus });
  const classesTodo = styles({ status: FB_ORDER_STATUSES.todo.label });
  const classesCompleted = styles({ status: FB_ORDER_STATUSES.completed.label });
  const iconRef = useRef(null);
  const currentStatusStyle = { fontWeight: 'bold', textTransform: 'uppercase', marginLeft: 10 };
  const notCurrentStatusStyle = { textTransform: 'capitalize', marginLeft: 10 };

  const windowStatusesRef = useRef(null);
  useOutsideAlerter(windowStatusesRef, iconRef, onClickIcon);

  const getStatusIconBox = () => {
    if (fbCourse.isWaiting) {
      return <IsWaitingCourseImg className={classes.statusIcon} style={{ marginTop: 5 }} />;
    }
    if (macroCategory === 'food') {
      return fbCourse.fbCourseStatus === FB_ORDER_STATUSES.none.label ? (
        <FoodImg
          onClick={(e) => {
            e.stopPropagation();
            onClickIcon();
          }}
          ref={iconRef}
        />
      ) : (
        <FoodFilledImg
          className={classes.statusIcon}
          onClick={(e) => {
            e.stopPropagation();
            onClickIcon();
          }}
          ref={iconRef}
        />
      );
    }

    return fbCourse.fbCourseStatus === FB_ORDER_STATUSES.none.label ? (
      <DrinkImg
        style={{ position: 'relative' }}
        onClick={(e) => {
          e.stopPropagation();
          onClickIcon();
        }}
        ref={iconRef}
      />
    ) : (
      <DrinkFilledImg
        className={classes.statusIcon}
        style={{ position: 'relative' }}
        onClick={(e) => {
          e.stopPropagation();
          onClickIcon();
        }}
        ref={iconRef}
      />
    );
  };
  return (
    <Box style={{ position: 'relative' }}>
      {getStatusIconBox()}
      {showStatuses && fbCourse.id && (
        <Box
          style={{
            padding: 10,
            position: 'absolute',
            border: borderTheme,
            display: 'flex',
            flexDirection: 'column',
            zIndex: 1,
            backgroundColor: 'white',
            left: 25,
            borderRadius: 10,
            boxShadow: '0px 3px 10px #0000004B'
          }}
          ref={windowStatusesRef}
        >
          <Box
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              onSelectStatus(FB_ORDER_STATUSES.none.label);
              onClickIcon(null);
            }}
          >
            {macroCategory === 'food' ? <FoodImg /> : <DrinkImg />}

            <Typography style={FB_ORDER_STATUSES.none.label === fbCourse.fbCourseStatus ? currentStatusStyle : notCurrentStatusStyle}>
              <TranslateTextComponent>{FB_ORDER_STATUSES.none.label}</TranslateTextComponent>
            </Typography>
          </Box>
          <Box
            style={{ display: 'flex', alignItems: 'center', padding: '10px 0', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();

              onSelectStatus(FB_ORDER_STATUSES.todo.label);
              onClickIcon(null);
            }}
          >
            {macroCategory === 'food' ? <FoodFilledImg className={classesTodo.statusIcon} /> : <DrinkFilledImg className={classesTodo.statusIcon} />}

            <Typography style={FB_ORDER_STATUSES.todo.label === fbCourse.fbCourseStatus ? currentStatusStyle : notCurrentStatusStyle}>
              <TranslateTextComponent>{FB_ORDER_STATUSES.todo.label}</TranslateTextComponent>
            </Typography>
          </Box>
          <Box
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();

              onSelectStatus(FB_ORDER_STATUSES.completed.label);
              onClickIcon(null);
            }}
          >
            {macroCategory === 'food' ? (
              <FoodFilledImg className={classesCompleted.statusIcon} />
            ) : (
              <DrinkFilledImg className={classesCompleted.statusIcon} />
            )}

            <Typography style={FB_ORDER_STATUSES.completed.label === fbCourse.fbCourseStatus ? currentStatusStyle : notCurrentStatusStyle}>
              <TranslateTextComponent>{FB_ORDER_STATUSES.completed.label}</TranslateTextComponent>
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default FbCourseStatusIcon;
