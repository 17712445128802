import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { TextField as MUITextField, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import InputWithLabel from '../../shared/InputWithLabel';
import Switch from '../../shared/Switch';
import Buttons from '../../shared/Buttons';
import MultiSelect from '../../shared/MultiSelect';
import useCustomQuery from '../../../hooks/useCustomQuery';
import { GET_FB_OUTLET } from '../../../graphql/outlets/queries';
import { AuthContext } from '../../../contexts/AuthContext';
import { EDIT_FB_OUTLET } from '../../../graphql/outlets/mutations';
import { TranslateTextComponent } from '../../../translator';
import { getApolloErrors } from '../../../apollo/ApolloProvider';
import { ModalContext } from '../../../contexts/ModalContext';
import { GET_OUTLET_CATEGORIES } from '../../../graphql/moh/categories/queries';
import styles from '../../../styles/settings/tabs';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

const TextField = styled(MUITextField).attrs({ variant: 'outlined', color: 'primary' })``;

const validationSchema = Yup.object().shape({
  terms: Yup.string(),
  policies: Yup.string(),
  structureServices: Yup.string(),
  regulation: Yup.string(),
  categories: Yup.array().min(1, 'Required')
});

const Services = () => {
  const classes = styles();

  const { appOutletUUID } = useContext(AuthContext);
  const modalContext = useContext(ModalContext);

  // Queries
  const getOutlet = useCustomQuery(GET_FB_OUTLET, {
    variables: { fbOutletUUID: appOutletUUID }
  });

  const outletCategoriesResponse = useCustomQuery(GET_OUTLET_CATEGORIES);
  const outletCategories = outletCategoriesResponse?.data?.getOutletCategories?.data ?? [];

  // Mutations
  const [editOutlet] = useMutation(EDIT_FB_OUTLET);

  const { values, errors, touched, isSubmitting, setFieldValue, handleBlur, handleChange, handleReset, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      services: getOutlet.data?.getFbOutlet?.isSmartServiceEnabled ?? false,
      categories: getOutlet.data?.getFbOutlet?.outletCategories?.map((cat) => ({ value: cat.id, label: cat.name, ...cat })) ?? [],
      terms: getOutlet.data?.getFbOutlet?.smartSettings?.smartServiceTermsConditions ?? '',
      policies: getOutlet.data?.getFbOutlet?.smartSettings?.smartServiceCancellationPolicy ?? '',
      structureServices: getOutlet.data?.getFbOutlet?.smartSettings?.smartServiceServicesDescription ?? '',
      regulation: getOutlet.data?.getFbOutlet?.smartSettings?.regulation ?? ''
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      let categoriesIds = {};
      if (values.categories.length > 0) {
        categoriesIds = values.categories.map((category) => parseInt(category.id, 10));
      }
      editOutlet({
        variables: {
          fbOutletUUID: appOutletUUID,
          fbOutletData: {
            isSmartServiceEnabled: values.services,
            outletCategories: categoriesIds,
            smartSettings: {
              smartServiceTermsConditions: values.terms,
              smartServiceCancellationPolicy: values.policies,
              smartServiceServicesDescription: values.structureServices,
              regulation: values.regulation
            }
          }
        }
      })
        .then(() => {
          getOutlet.refetch();
          modalContext.openModal({
            class: 'success',
            title: <TranslateTextComponent capitalize>outlet-edit-success</TranslateTextComponent>,
            text: <TranslateTextComponent capitalize>outlet-edit-success-description</TranslateTextComponent>,
            actionButtons: [
              <Button
                key={0}
                variant="contained"
                color="primary"
                className="success"
                onClick={() => {
                  modalContext.closeModal();
                }}
              >
                <TranslateTextComponent uppercase>ok</TranslateTextComponent>
              </Button>
            ]
          });
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        });
      setSubmitting(false);
    }
  });

  return (
    <Container>
      <RowContainer>
        <InputWithLabel label="services" containerStyle={{ width: '100%' }}>
          <Switch leftLabel="no" rightLabel="yes" value={values.services} onChange={(value) => setFieldValue('services', value)} />
        </InputWithLabel>
        <InputWithLabel label="category" error={touched.categories && errors.categories} withMarginBottom>
          <MultiSelect
            classes={classes.dropdown}
            options={outletCategories.map((category) => ({ ...category, label: category.name, value: category.uuid }))}
            value={values.categories}
            onChange={(e, value) => setFieldValue('categories', value)}
          />
        </InputWithLabel>
      </RowContainer>
      <InputWithLabel label="terms-and-conditions" withMarginBottom error={touched.terms && errors.terms}>
        <TextField
          name="terms"
          fullWidth
          variant="outlined"
          color="primary"
          error={touched.terms && !!errors.terms}
          value={values.terms}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </InputWithLabel>
      <InputWithLabel label="cancellation-policies" withMarginBottom error={touched.policies && errors.policies}>
        <TextField
          name="policies"
          fullWidth
          variant="outlined"
          color="primary"
          error={touched.policies && !!errors.policies}
          value={values.policies}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </InputWithLabel>
      <InputWithLabel label="structure-services" withMarginBottom error={touched.structureServices && errors.structureServices}>
        <TextField
          name="structureServices"
          fullWidth
          variant="outlined"
          color="primary"
          error={touched.structureServices && !!errors.structureServices}
          value={values.structureServices}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </InputWithLabel>
      <InputWithLabel label="regulation" withMarginBottom error={touched.regulation && errors.regulation}>
        <TextField
          name="regulation"
          fullWidth
          variant="outlined"
          color="primary"
          error={touched.regulation && !!errors.regulation}
          value={values.regulation}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </InputWithLabel>
      <Buttons
        containerStyle={{ justifyContent: 'space-between' }}
        buttons={[
          {
            buttonType: 'reset',
            style: { padding: '5px 10px', minWidth: 155, fontSize: 20, fontWeight: 600 },
            type: 'cancel',
            onClick: handleReset
          },
          {
            buttonType: 'submit',
            style: { padding: '5px 10px', minWidth: 155, fontSize: 20, fontWeight: 600 },
            label: 'save',
            type: 'confirm',
            disabled: isSubmitting,
            onClick: handleSubmit
          }
        ]}
      />
    </Container>
  );
};

export default Services;
