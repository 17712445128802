export const GREEN_DOOR = require('../assets/images/new_reservation/green_door.svg');
export const RED_DOOR = require('../assets/images/new_reservation/red_door.svg');
export const WHITE_FILTER = require('../assets/images/new_reservation/white_filter.svg');
export const PRIMARY_COLOR_FILTER = require('../assets/images/filter.svg');
export const FLAG = require('../assets/images/new_reservation/flag.svg');
export const LANGUAGE = require('../assets/images/new_reservation/language.svg');
export const MAIL = require('../assets/images/new_reservation/mail.svg');
export const PHONE = require('../assets/images/new_reservation/phone.svg');
export const ROOM = require('../assets/images/new_reservation/room.svg');
export const CHRIS = require('../assets/images/new_reservation/ch.png');
