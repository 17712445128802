import { makeStyles } from '@material-ui/core/styles';

const styles = {
  wrapper: ({ selected }) => ({
    width: '100%',
    height: '100%'
  }),
  container: ({ rotation }) => ({
    width: '100%',
    height: '100%',
    transform: `rotate(${rotation}deg)`,
    transition: 'transform 0.3s'
  }),
  tableDescription: ({ hover, reserved }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gridTemplateAreas: '"reservation-time reservation-time" "name room" "course-status course-status"',
    gridColumnGap: reserved ? 10 : 0,
    minWidth: 100,
    padding: 5,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    textAlign: 'center',
    zIndex: 2,
    paddingBottom: 40
  }),
  reservationTimeArea: {
    gridArea: 'reservation-time',
    fontSize: 16,
    fontWeight: 400,
    color: '#55595C'
  },
  nameArea: {
    gridArea: 'name',
    fontSize: 12,
    fontWeight: 450,
    color: '#55595C'
  },
  roomArea: {
    gridArea: 'room',
    fontSize: 12,
    fontWeight: 450,
    color: '#55595C'
  },
  courseStatusArea: {
    gridArea: 'course-status'
    // marginBottom: 30
  },
  round: ({ size, selected }) => ({
    backgroundColor: selected ? '#00B7FF' : '#C0C1C2',
    width: '100%',
    margin: 'auto',
    borderRadius: '50%'
  }),
  square: ({ size, selected }) => ({
    backgroundColor: selected ? '#00B7FF' : '#C0C1C2',
    borderRadius: 4
  }),
  rectangle: ({ size, selected }) => ({
    backgroundColor: selected ? '#00B7FF' : '#C0C1C2',
    borderRadius: 4
  }),
  table: () => ({
    height: '100%',
    border: '1px solid #C0C1C2',
    backgroundColor: 'white'
    // background: 'linear-gradient(233deg, rgba(255,255,255,1) 0%, rgba(192,193,194,1) 100%)'
  }),
  badge: ({ rotation, background }) => ({
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
    top: '70%',
    transform: 'translateY(-50%)',
    background: background
      ? 'rgba(0, 0, 0, 0) linear-gradient(233deg, rgb(68, 157, 68) 0%, rgb(47, 64, 53) 100%) repeat scroll 0% 0%'
      : 'rgba(0, 0, 0, 0) linear-gradient(233deg, rgb(254, 0, 0) 0%, rgb(64, 47, 47) 100%) repeat scroll 0% 0%',
    width: 25,
    height: 25,
    borderRadius: '50%',
    zIndex: 5
  })
};

export default makeStyles(styles);
