import React, { useState, useContext } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, TextField, Fab } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useMutation } from '@apollo/react-hooks';
import typy from 'typy';
import { Formik } from 'formik';
import { isEmail, isEmpty, normalizeEmail } from 'validator';
import moment from 'moment';
import styles from '../../../../styles/shared/pm';
import { TranslateTextComponent, translate } from '../../../../translator';
import { DataPicker, SearchBar } from '../../../../common-fe/src';
import UserRow from './UserRow';
import { GET_GUESTS } from '../../../../graphql/guests/queries';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { ADD_GUEST } from '../../../../graphql/guests/mutations';
import useCustomQuery from '../../../../hooks/useCustomQuery';

const PMUsers = ({ guestsList, onAddGuestClick, onRemoveGuestClick }) => {
  const [expanded, setExpanded] = useState(true);
  const [results, setResults] = useState([]);

  const { language } = useContext(LanguageContext);
  const classes = styles({ expanded });

  // Query
  const getGuests = useCustomQuery(GET_GUESTS, { skip: true, fetchPolicy: 'network-only' });

  // Mutation
  const [addNewGuest] = useMutation(ADD_GUEST);

  const onReturnedDataHandler = (res) => {
    setResults(typy(res, 'data.getGuests.data').safeArray);
  };

  const onReturnedResultClick = (res) => {
    onAddGuestClick(res);
  };

  return (
    <Formik
      initialValues={{
        firstname: '',
        lastname: '',
        dob: null,
        email: '',
        phone: ''
      }}
      validate={(values) => {
        const errors = {};

        if (isEmpty(values.firstname, { ignore_white_space: true })) {
          errors.firstname = translate('required', language);
        }

        if (isEmpty(values.lastname, { ignore_white_space: true })) {
          errors.lastname = translate('required', language);
        }

        if (!isEmpty(values.email) && !isEmail(values.email)) {
          errors.email = translate('email-not-valid', language);
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        addNewGuest({
          variables: {
            guestData: {
              firstname: values.firstname,
              lastname: values.lastname,
              dob: values.dob ? moment(values.dob, 'DD-MM-YYYY').format('YYYY-MM-DD') : null,
              email: values.email ? normalizeEmail(values.email) : null,
              phone: values.phone
            }
          }
        }).then((res) => {
          onAddGuestClick(res.data.addGuest);
          resetForm();
        });
        setSubmitting(false);
      }}
    >
      {({ values, touched, errors, setFieldValue, isSubmitting, handleSubmit, handleChange }) => (
        <Box className={classes.container}>
          <Accordion className={classes.panelContainer} expanded={expanded} onChange={() => setExpanded((prev) => !prev)}>
            <AccordionSummary className={classes.panelTitleContainer} expandIcon={<ExpandMoreIcon color="primary" />} aria-controls="panel-content">
              <Typography className={classes.panelTitle}>
                <TranslateTextComponent capitalize>users</TranslateTextComponent> (100)
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.panelDetails} style={{ display: 'flex', flexFlow: 'column' }}>
              <Box className={classes.inputContainer} style={{ width: '100%' }}>
                <SearchBar
                  style={{ width: '100%', height: 40 }}
                  autoComplete
                  apolloQuery={getGuests}
                  withResults
                  results={results}
                  inputStyle={{ textIndent: 10 }}
                  resultsKeys={['firstname', 'lastname', 'dob', 'email']}
                  onReturnedData={onReturnedDataHandler}
                  onResultClick={onReturnedResultClick}
                />
              </Box>
              <Box className={classes.inputContainer} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box style={{ flex: 1, marginRight: 10 }}>
                  <Typography className={classes.inputTitle}>
                    <TranslateTextComponent capitalize>name</TranslateTextComponent>
                  </Typography>
                  <TextField
                    name="firstname"
                    className={classes.input}
                    color="primary"
                    variant="outlined"
                    value={values.firstname}
                    error={touched.firstname && errors.firstname && errors.firstname.length > 0}
                    helperText={touched.firstname && errors.firstname}
                    onChange={handleChange}
                  />
                </Box>
                <Box style={{ flex: 1, marginRight: 10 }}>
                  <Typography className={classes.inputTitle}>
                    <TranslateTextComponent capitalize>lastname</TranslateTextComponent>
                  </Typography>
                  <TextField
                    name="lastname"
                    className={classes.input}
                    color="primary"
                    variant="outlined"
                    value={values.lastname}
                    error={touched.lastname && errors.lastname && errors.lastname.length > 0}
                    helperText={touched.lastname && errors.lastname}
                    onChange={handleChange}
                  />
                </Box>
                <Box style={{ flex: 1, marginRight: 10 }}>
                  <Typography className={classes.inputTitle}>
                    <TranslateTextComponent capitalize>date-of-birth</TranslateTextComponent>
                  </Typography>
                  <DataPicker
                    name="dob"
                    dataPickerStyle={{ maxWidth: 150, overflow: 'hidden' }}
                    value={values.dob}
                    onChange={(e) => setFieldValue('dob', e)}
                  />
                </Box>
                <Box style={{ flex: 1, marginRight: 10 }}>
                  <Typography className={classes.inputTitle}>
                    <TranslateTextComponent capitalize>phone</TranslateTextComponent>
                  </Typography>
                  <TextField name="phone" className={classes.input} color="primary" variant="outlined" value={values.phone} onChange={handleChange} />
                </Box>
                <Box style={{ flex: 1, marginRight: 10 }}>
                  <Typography className={classes.inputTitle}>
                    <TranslateTextComponent capitalize>mail</TranslateTextComponent>
                  </Typography>
                  <TextField
                    name="email"
                    className={classes.input}
                    color="primary"
                    variant="outlined"
                    value={values.email}
                    error={touched.email && errors.email && errors.email.length > 0}
                    helperText={touched.email && errors.email}
                    onChange={handleChange}
                  />
                </Box>
                <Box>
                  <Fab
                    size="small"
                    color="primary"
                    style={{ color: 'white', fontWeight: 'bold', marginTop: 10 }}
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    +1
                  </Fab>
                </Box>
              </Box>
              <UserRow data={guestsList} guestsList={guestsList} onRemoveGuestClick={onRemoveGuestClick} />
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </Formik>
  );
};

export default PMUsers;
