import gql from 'graphql-tag';

export const GET_PACKAGES = gql`
  query getPackages($page: Int, $pageSize: Int) {
    getPackages(page: $page, pageSize: $pageSize) {
      totalCount
      data {
        id
        uuid
        name
        description
      }
    }
  }
`;
