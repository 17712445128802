/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { IconButton } from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import { TranslateTextComponent } from '../../../../translator';
import { Dropdown } from '../../../../common-fe/src';
import TableButton from '../../../shared/TableButton';
import getOrderBookingStatuses from '../../../../utils/getOrderBookingStatuses';
import { MOH_ORDERS_STATUSES } from '../../../../constants/moh/fbOrdersStatuses';

const textTranslate = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

const bookingsColumns = ({ openModal, changeOrderStatus, language }) => {
  return [
    {
      id: 'client',
      // disablePadding: false,
      label: textTranslate('client'),
      isSortable: false,
      align: 'left',
      render: (item) => {
        const fullName = `${item.profile?.firstName ?? ''} ${item.profile?.lastName ?? ''}`;
        return fullName.trim().length > 0 ? fullName : item.clientName;
      }
    },
    {
      id: 'service',
      label: textTranslate('service'),
      isSortable: false,
      align: 'left',
      render: (item) => item?.orderSmartServices?.[0]?.name ?? ''
    },
    {
      id: 'price',
      disablePadding: false,
      label: textTranslate('price'),
      isSortable: false,
      align: 'left',
      render: (item) => item?.orderSmartServices?.[0]?.price ?? ''
    },
    {
      id: 'quantity',
      disablePadding: false,
      label: textTranslate('quantity'),
      isSortable: false,
      align: 'left',
      render: (item) => item?.orderSmartServices?.[0]?.quantity ?? ''
    },
    {
      id: 'notes',
      disablePadding: false,
      label: textTranslate('notes'),
      isSortable: false,
      align: 'left',
      render: (item) => item.notes
    },
    {
      id: 'roomNumber',
      disablePadding: false,
      label: textTranslate('room-number'),
      isSortable: false,
      render: (item) => item.guestRoom
    },
    {
      id: 'details',
      disablePadding: false,
      label: textTranslate('details'),
      isSortable: false,
      align: 'left',
      render: (item) => <TableButton onClick={() => openModal(item)}>open</TableButton>
    },
    {
      id: 'status',
      disablePadding: false,
      label: textTranslate('status'),
      isSortable: false,
      width: '10%',
      align: 'left',
      render: (item) => (
        <Dropdown
          dropdownStyle={{ backgroundColor: 'white', fontSize: 11, textTransform: 'uppercase' }}
          value={item.status}
          items={getOrderBookingStatuses({ type: 'bookings', currentStatus: item.status, language, profile: item.profile })}
          onChange={({ target: { value } }) => {
            if (value !== item.status) changeOrderStatus({ selectedStatus: value, fbOrderUUID: item.uuid });
          }}
        />
      )
    },
    {
      id: 'delete',
      disablePadding: false,
      label: textTranslate('delete'),
      isSortable: false,
      align: 'left',
      render: (item) => {
        return (
          <IconButton
            className="buttonCircle"
            disabled={
              item.status !== MOH_ORDERS_STATUSES.open &&
              item.status !== MOH_ORDERS_STATUSES.to_confirm &&
              item.status !== MOH_ORDERS_STATUSES.preparing
            }
            onClick={() => changeOrderStatus({ selectedStatus: MOH_ORDERS_STATUSES.deleted, fbOrderUUID: item.uuid })}
          >
            <DeleteIcon />
          </IconButton>
        );
      }
    }
  ];
};

export default bookingsColumns;
