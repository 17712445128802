export const ROLES = {
  ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ROLE_FB_MANAGER: 'ROLE_FB_MANAGER',
  ROLE_MANAGER: 'ROLE_MANAGER',
  ROLE_FB_OPERATOR: 'ROLE_FB_OPERATOR',
  ROLE_FB_DRIVER: 'ROLE_FB_DRIVER',
  ROLE_GUEST: 'ROLE_GUEST',
  ROLE_MAITRE: 'ROLE_MAITRE',
  ROLE_HOSTESS: 'ROLE_HOSTESS',
  ROLE_ROOM_SERVICE: 'ROLE_ROOM_SERVICE',
  ROLE_SUPERVISOR: 'ROLE_SUPERVISOR',
  ROLE_LOCAL: 'ROLE_LOCAL',
  ROLE_FRONT_OFFICE: 'ROLE_FRONT_OFFICE',
  ROLE_GUEST_SERVICE: 'ROLE_GUEST_SERVICE'
};

export const ALL_ROLES = Object.keys(ROLES).map((roleKey) => ROLES[roleKey]);

export const RESTAURANT_ROLES = [
  { label: 'FB MANAGER', value: 'ROLE_FB_MANAGER' },
  { label: 'FB OPERATOR', value: 'ROLE_FB_OPERATOR' },
  { label: 'FB DRIVER', value: 'ROLE_FB_DRIVER' }
];
