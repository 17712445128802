import React, { useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { Dropdown, redButton } from '../../../../common-fe/src';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { capitalizeFirstLetter, translate, TranslateTextComponent } from '../../../../translator';
import Buttons from '../../../shared/Buttons';
import InputWithLabel from '../../../shared/InputWithLabel';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  width: 100%;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;

  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const SectionTitle = styled(TranslateTextComponent)`
  font-weight: bold;
  font-size: 1.1rem;
  text-transform: capitalize;
  margin-bottom: 20px;
`;

const OPTIONS = {
  NONE: '-',
  YES: 'YES',
  NO: 'NO',
  NOT_ASKED: 'NOT_ASKED'
};
const PersonalDataForm = ({ data, formValues, onFormValuesChange, saveProfileData }) => {
  const { language } = useContext(LanguageContext);

  // Dropdown Options
  const dropdownOptions = Object.values(OPTIONS).map((o) => ({
    label: capitalizeFirstLetter(translate(o.toLocaleLowerCase().replaceAll('_', '-'), language)),
    value: o
  }));

  const validationSchema = Yup.object({
    permissionContactAdvertisingViaMail: Yup.string().test(
      'Cannot be null',
      capitalizeFirstLetter(translate('required', language)),
      (value) => value !== OPTIONS.NONE
    ),
    permissionContactAdvertisingViaPhone: Yup.string().test(
      'Cannot be null',
      capitalizeFirstLetter(translate('required', language)),
      (value) => value !== OPTIONS.NONE
    ),
    permissionContactAdvertisingViaEmail: Yup.string().test(
      'Cannot be null',
      capitalizeFirstLetter(translate('required', language)),
      (value) => value !== OPTIONS.NONE
    ),
    permissionContactAdvertisingViaSms: Yup.string().test(
      'Cannot be null',
      capitalizeFirstLetter(translate('required', language)),
      (value) => value !== OPTIONS.NONE
    )
  });

  const { values, touched, errors, dirty, handleChange, handleBlur, handleReset, handleSubmit, getFieldProps } = useFormik({
    enableReinitialize: true,
    initialValues: {
      permissionContactBookingTroublesViaMail:
        formValues?.permissionContactBookingTroublesViaMail || data?.permissionContactBookingTroublesViaMail || OPTIONS.NONE,
      permissionContactBookingTroublesViaPhone:
        formValues?.permissionContactBookingTroublesViaPhone || data?.permissionContactBookingTroublesViaPhone || OPTIONS.NONE,
      permissionContactBookingTroublesViaEmail:
        formValues?.permissionContactBookingTroublesViaEmail || data?.permissionContactBookingTroublesViaEmail || OPTIONS.NONE,
      permissionContactBookingTroublesViaSms:
        formValues?.permissionContactBookingTroublesViaSms || data?.permissionContactBookingTroublesViaSms || OPTIONS.NONE,
      permissionContactInvoiceTroublesViaMail:
        formValues?.permissionContactInvoiceTroublesViaMail || data?.permissionContactInvoiceTroublesViaMail || OPTIONS.NONE,
      permissionContactInvoiceTroublesViaPhone:
        formValues?.permissionContactInvoiceTroublesViaPhone || data?.permissionContactInvoiceTroublesViaPhone || OPTIONS.NONE,
      permissionContactInvoiceTroublesViaEmail:
        formValues?.permissionContactInvoiceTroublesViaEmail || data?.permissionContactInvoiceTroublesViaEmail || OPTIONS.NONE,
      permissionContactInvoiceTroublesViaSms:
        formValues?.permissionContactInvoiceTroublesViaSms || data?.permissionContactInvoiceTroublesViaSms || OPTIONS.NONE,
      permissionContactAdvertisingViaMail:
        formValues?.permissionContactAdvertisingViaMail || data?.permissionContactAdvertisingViaMail || OPTIONS.NONE,
      permissionContactAdvertisingViaPhone:
        formValues?.permissionContactAdvertisingViaPhone || data?.permissionContactAdvertisingViaPhone || OPTIONS.NONE,
      permissionContactAdvertisingViaEmail:
        formValues?.permissionContactAdvertisingViaEmail || data?.permissionContactAdvertisingViaEmail || OPTIONS.NONE,
      permissionContactAdvertisingViaSms: formValues?.permissionContactAdvertisingViaSms || data?.permissionContactAdvertisingViaSms || OPTIONS.NONE
    },
    validationSchema,
    onSubmit: () => {
      saveProfileData(formValues);
    }
  });

  useEffect(() => {
    const newValues = { ...getFieldProps().value };
    Object.keys(newValues).forEach((key) => {
      if (newValues[key] === '-') {
        newValues[key] = null;
      }
    });
    onFormValuesChange(newValues);
  }, [values]);

  return (
    <Container>
      <RowContainer>
        <ColumnContainer>
          <SectionTitle>allowed-contact-booking-troubles</SectionTitle>
          <InputWithLabel label="via-mail">
            <Dropdown
              name="permissionContactBookingTroublesViaMail"
              items={dropdownOptions}
              value={values.permissionContactBookingTroublesViaMail}
              dropdownStyle={{ width: '100%', height: 40 }}
              onChange={handleChange}
            />
          </InputWithLabel>
          <InputWithLabel label="via-phone">
            <Dropdown
              name="permissionContactBookingTroublesViaPhone"
              items={dropdownOptions}
              value={values.permissionContactBookingTroublesViaPhone}
              dropdownStyle={{ width: '100%', height: 40 }}
              onChange={handleChange}
            />
          </InputWithLabel>
          <InputWithLabel label="via-email">
            <Dropdown
              name="permissionContactBookingTroublesViaEmail"
              items={dropdownOptions}
              value={values.permissionContactBookingTroublesViaEmail}
              dropdownStyle={{ width: '100%', height: 40 }}
              onChange={handleChange}
            />
          </InputWithLabel>
          <InputWithLabel label="via-sms">
            <Dropdown
              name="permissionContactBookingTroublesViaSms"
              items={dropdownOptions}
              value={values.permissionContactBookingTroublesViaSms}
              dropdownStyle={{ width: '100%', height: 40 }}
              onChange={handleChange}
            />
          </InputWithLabel>
        </ColumnContainer>
        <ColumnContainer>
          <SectionTitle>allowed-contact-invoice-troubles</SectionTitle>
          <InputWithLabel label="via-mail">
            <Dropdown
              name="permissionContactInvoiceTroublesViaMail"
              items={dropdownOptions}
              value={values.permissionContactInvoiceTroublesViaMail}
              dropdownStyle={{ width: '100%', height: 40 }}
              onChange={handleChange}
            />
          </InputWithLabel>
          <InputWithLabel label="via-phone">
            <Dropdown
              name="permissionContactInvoiceTroublesViaPhone"
              items={dropdownOptions}
              value={values.permissionContactInvoiceTroublesViaPhone}
              dropdownStyle={{ width: '100%', height: 40 }}
              onChange={handleChange}
            />
          </InputWithLabel>
          <InputWithLabel label="via-email">
            <Dropdown
              name="permissionContactInvoiceTroublesViaEmail"
              items={dropdownOptions}
              value={values.permissionContactInvoiceTroublesViaEmail}
              dropdownStyle={{ width: '100%', height: 40 }}
              onChange={handleChange}
            />
          </InputWithLabel>
          <InputWithLabel label="via-sms">
            <Dropdown
              name="permissionContactInvoiceTroublesViaSms"
              items={dropdownOptions}
              value={values.permissionContactInvoiceTroublesViaSms}
              dropdownStyle={{ width: '100%', height: 40 }}
              onChange={handleChange}
            />
          </InputWithLabel>
        </ColumnContainer>
        <ColumnContainer>
          <SectionTitle>allowed-contact-advertising</SectionTitle>
          <InputWithLabel label="via-mail-required" error={touched.permissionContactAdvertisingViaMail && errors.permissionContactAdvertisingViaMail}>
            <Dropdown
              name="permissionContactAdvertisingViaMail"
              items={dropdownOptions}
              value={values.permissionContactAdvertisingViaMail}
              dropdownStyle={{ width: '100%', height: 40 }}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
          <InputWithLabel
            label="via-phone-required"
            error={touched.permissionContactAdvertisingViaPhone && errors.permissionContactAdvertisingViaPhone}
          >
            <Dropdown
              name="permissionContactAdvertisingViaPhone"
              items={dropdownOptions}
              value={values.permissionContactAdvertisingViaPhone}
              dropdownStyle={{ width: '100%', height: 40 }}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
          <InputWithLabel
            label="via-email-required"
            error={touched.permissionContactAdvertisingViaEmail && errors.permissionContactAdvertisingViaEmail}
          >
            <Dropdown
              name="permissionContactAdvertisingViaEmail"
              items={dropdownOptions}
              value={values.permissionContactAdvertisingViaEmail}
              dropdownStyle={{ width: '100%', height: 40 }}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
          <InputWithLabel label="via-sms-required" error={touched.permissionContactAdvertisingViaSms && errors.permissionContactAdvertisingViaSms}>
            <Dropdown
              name="permissionContactAdvertisingViaSms"
              items={dropdownOptions}
              value={values.permissionContactAdvertisingViaSms}
              dropdownStyle={{ width: '100%', height: 40 }}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
        </ColumnContainer>
      </RowContainer>
      <Buttons
        buttons={[
          {
            buttonType: 'button',
            type: 'cancel',
            label: 'delete',
            style: {
              padding: 0,
              marginLeft: 80,
              backgroundColor: 'transparent',
              border: 'none',
              color: redButton,
              textDecoration: 'underline',
              minHeight: 50,
              fontSize: 20,
              fontWeight: 'medium',
              textTransform: 'uppercase'
            },
            onClick: handleReset
          },
          {
            buttonType: 'button',
            type: 'confirm',
            label: 'confirm',
            style: { minHeight: 50, minWidth: 227, fontSize: 20, fontWeight: 'medium', textTransform: 'uppercase' },
            onClick: handleSubmit
          }
        ]}
      />
    </Container>
  );
};

export default PersonalDataForm;
