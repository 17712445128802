import { makeStyles } from '@material-ui/core/styles';
import { primaryColor, invertedPrimaryCC80 } from '../../common-fe';

const styles = {
  container: {
    backgroundColor: 'white',
    borderTopLeftRadius: 15,
    overflow: 'hidden',
    width: '100%'
  },
  tabsContainer: {
    backgroundColor: '#EDFAFF',
    minHeight: 65,
    boxShadow: '3px 3px 3px #d9d9d9',
    '& .MuiTabs-indicator': {
      display: 'block',
      border: `2px solid ${primaryColor}`,
      backgroundColor: primaryColor
    },
    '& .MuiTabs-flexContainer': {
      height: '100%'
    },
    '& .MuiTab-root': {
      fontSize: 16,
      color: invertedPrimaryCC80
    }
  },
  selectedTab: {
    fontSize: 16,
    fontWeight: 'bold',
    color: `${primaryColor}!important`
  },
  content: {
    height: '100vh'
  }
};

export default makeStyles(styles);
