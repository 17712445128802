import { makeStyles } from '@material-ui/core/styles';
import { primaryColor, secondaryColor } from '../../theme';

const assignChairStyle = {
  boxContainer: () => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  }),
  cardName: () => ({
    borderBottom: `1px solid ${primaryColor}`,
    paddingBottom: 15,
    marginTop: 15
  }),
  typographyName: () => ({
    fontSize: 17,
    fontWeight: 'bold',
    color: '#55595C',
    textAlign: 'left',
    textTransform: 'uppercase',
    marginBottom: 10
  }),
  cardNameDetail: () => ({
    fontWeight: 'bold',
    color: '#55595C'
  }),
  allergensContainer: () => ({
    marginTop: 15,
    paddingLeft: 10,
    paddingRight: 10,
    borderBottom: `1px solid ${primaryColor}`
  }),
  titles: () => ({
    fontWeight: 'bold',
    color: '#55595C',
    fontSize: 14,
    marginBottom: 5
  }),
  details: () => ({
    color: '#55595C',
    fontSize: 14,
    marginBottom: 5
  }),
  othersContainer: () => ({
    paddingLeft: 10,
    paddingRight: 10,
    borderBottom: `1px solid ${primaryColor}`,
    paddingBottom: 15,
    marginTop: 15
  }),
  listenerStyle: () => ({
    borderRadius: 20,
    width: 'auto'
  }),
  header: () => ({
    backgroundColor: primaryColor,
    color: 'white',
    fontSize: 14,
    fontWeight: 'bold',
    height: 30,
    textAlign: 'center',
    alignItems: 'center',
    display: 'grid',
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5
  }),
  detailPair: () => ({
    backgroundColor: 'white',
    color: '#55595C',
    height: 'auto',
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 12,
    cursor: 'pointer'
  }),
  detailOdd: () => ({
    backgroundColor: secondaryColor,
    color: '#55595C',
    paddingTop: 10,
    paddingBottom: 10,
    height: 'auto',
    paddingLeft: 10,
    fontSize: 12,
    cursor: 'pointer'
  }),
  buttons: () => ({
    borderRadius: 9,
    height: 22,
    fontSize: 12,
    backgroundColor: primaryColor,
    color: 'white',
    width: '100%',
    marginTop: 5,
    marginBottom: 5,
    paddingRight: 8,
    paddingLeft: 8,
    paddingTop: 2
  }),
  searchBar: () => ({
    '& .MuiInputBase-root': {
      '& .MuiInputBase-input': {
        width: 100
      }
    }
  }),

  gridList: () => ({
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    wordBreak: 'break-word'
  }),
  buttonsGender: () => ({
    padding: 0,
    width: 25,
    height: 25,
    '&.MuiButton-root': {
      '&:hover': {
        background: 'none'
      }
    }
  }),
  categoryCounter: () => ({
    color: '#565A5D',
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: '-1px'
  }),
  labelsDescr: () => ({
    fontSize: 12,
    color: '#565A5D',
    lineHeight: 'normal'
  }),
  labelRangeAge: () => ({
    color: '#939596',
    lineHeight: 'normal',
    fontSize: 10
  }),
  table: () => ({
    width: '100%',
    justifyContent: 'center'
  }),
  headerContainer: () => ({
    paddingTop: 20,
    paddingBottom: 20
  }),
  paperStyle: () => ({
    paddingBottom: 10,
    borderRadius: 20
  }),
  gridButton: () => ({
    paddingTop: 15,
    paddingLeft: 45,
    paddingRight: 45
  }),
  titleofdetail: () => ({
    fontSize: 11,
    fontWeight: 'bold',
    color: '#5A5A5A'
  }),
  detailOfGuest: () => ({
    fontSize: 11,
    color: '#979797'
  }),
  tableGuestContainer: () => ({
    // Change the color of Chrome scrollBar
    '&::-webkit-scrollbar': {
      width: 0
    }
  })
};
export default makeStyles(assignChairStyle);
