import React from 'react';
import { Button as MUIButton } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const Button = ({ children, category, onClick, isSelected, style }) => {
  const StyledCategoryButton = styled(MUIButton)(() => ({
    color: isSelected ? 'white' : '#6a6d70',
    width: '100%',
    maxWidth: 300,
    height: 40,
    backgroundColor: isSelected ? category.color : 'white',
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: `2px solid ${category.color}`,
    borderLeft: `20px solid ${category.color}`,
    textShadow: '0px 3px 6px #00000029',
    boxShadow: '2px 3px 6px #00000040',
    transition: 'background-color 0.3s',
    '& span': {
      display: 'block',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    '&:hover': {
      backgroundColor: category.color,
      color: 'white'
    },
    ...style
  }));

  return <StyledCategoryButton onClick={() => onClick(category.uuid)}>{category.name || children}</StyledCategoryButton>;
};

export default Button;
