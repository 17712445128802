/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import { FB_CHAIR_FRAGMENT } from '../fragments';

export const GET_TABLE_FB_ORDER = gql`
  query getTableFbOrder($fbTableUUID: String!) {
    getTableFbOrder(fbTableUUID: $fbTableUUID) {
      id
      uuid
      fbCourses {
        id
        uuid
        number
        fbCourseStatus
        isWaiting
        type
        fbCoursesMenuItems {
          id
          uuid
          name
          price
          quantity
          price
          fbMenuItemId
          centerpiece
          halfPortion
          notes
          with
          without
          sharedBy
          fbMenuItemId
          fbMenuItem {
            id
            fbMenuCategoryId
            name
            price
            fbMenuCategory {
              id
              name
            }
          }
          fbChairId
          fbChair {
            ...fbChairFragment
          }
        }
      }
      fbReservationTable {
        id
        fbTableId
        fbTable {
          id
          number
          fbChairs {
            ...fbChairFragment
          }
        }
        fbReservation {
          id
          uuid
          guestRoom
          date
          reservationTime
          nPax
          tableNumber
          bookedBy {
            id
            firstname
            lastname
            guestsFbAllergens {
              type
              fbAllergen {
                id
                name
              }
            }
            preferences
            favouriteWine
            favouriteWater
          }
        }
      }
    }
  }
  ${FB_CHAIR_FRAGMENT}
`;

export const GET_FB_ORDER = gql`
  query getFbOrder($fbOrderUUID: String!) {
    getFbOrder(fbOrderUUID: $fbOrderUUID) {
      id
      uuid
      createdAt
      type
      time
      profile {
        id
        uuid
        firstName
        lastName
      }
      fbCourses {
        id
        uuid
        number
        fbCourseStatus
        isWaiting
        type
        fbCoursesMenuItems {
          id
          uuid
          name
          price
          quantity
          price
          fbMenuItemId
          centerpiece
          halfPortion
          notes
          with
          without
          sharedBy
          fbMenuItemId
          fbMenuItem {
            id
            fbMenuCategoryId
            fbAllergens {
              id
              uuid
              name
            }
            name
            price
            fbMenuCategory {
              id
              name
            }
          }
          fbChairId
          fbChair {
            ...fbChairFragment
          }
        }
      }
      fbReservationTable {
        id
        fbTableId
        fbTable {
          id
          number
          fbRoom {
            id
            uuid
            name
          }
          fbChairs {
            ...fbChairFragment
          }
        }

        fbReservation {
          id
          uuid
          guestRoom
          id
          date
          reservationTime
          nPax
          tableNumber
          bookedBy {
            id
            firstname
            lastname
            guestsFbAllergens {
              type
              fbAllergen {
                id
                name
              }
            }
            preferences
            favouriteWine
            favouriteWater
          }
          package {
            id
            uuid
            name
            description
          }
        }
      }
    }
  }
  ${FB_CHAIR_FRAGMENT}
`;
