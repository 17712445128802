/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import { useMutation, useSubscription } from '@apollo/react-hooks';
import { Box, Button, Typography } from '@material-ui/core';
import axios from 'axios';
import { getApolloErrors } from '../../apollo/ApolloProvider';
import { CREATE_GUEST_USER, UPDATE_GUEST_USER_EMAIL_CONFIRMED } from '../../graphql/user/mutations';
import { ModalContext } from '../../contexts/ModalContext';
import { CommonTable } from '../../common-fe';
import PageLayout from '../shared/PageLayout';
import guestUsersColumns from './table/guestUsersColumns';
import NewGuestUserModal from './modals/NewGuestUserModal';
import { TranslateTextComponent, capitalizeFirstLetter, translate } from '../../translator';
import CheckInModal from './modals/CheckInModal';
import { ROUTE_CHECK_IN } from '../../router/routes';
import CustomFilters from '../shared/CustomFilters/CustomFilters';
import { AuthContext } from '../../contexts/AuthContext';
import useCustomQuery from '../../hooks/useCustomQuery';
import { GET_GUEST_USERS } from '../../graphql/user/queries';
import { ON_CHECKIN_BY_GUEST_CREATED, ON_CHECKIN_SAVED } from '../../graphql/user/subscriptions';
import { LanguageContext } from '../../contexts/LanguageContext';
import modalStyle from '../../styles/shared/modalStyle';
import { CHECK_IN_STATUS } from '../../constants/user/checkInStatus';

const initialFilters = {
  orderBy: 'id',
  orderType: 'DESC',
  page: 1,
  pageSize: 10,
  checkinStatus: null,
  keyword: ''
};

const GuestUsersList = () => {
  const { user } = useContext(AuthContext);
  const { language } = useContext(LanguageContext);
  const modalContext = useContext(ModalContext);
  const [queryFilters, setQueryFilters] = useState(initialFilters);
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [checkInModalData, setCheckInModalData] = useState(null);
  const [documentImages, setDocumentImages] = useState([]);
  const [profileUUID, setProfileUUID] = useState(null);

  const history = useHistory();

  const modalClasses = modalStyle();

  useEffect(() => {
    const checkinIndexWithPhoto = checkInModalData?.profile?.checkins?.findIndex((checkin) => checkin?.documentImages?.length > 0);
    if (checkinIndexWithPhoto > -1 && checkInModalData?.profile?.checkins?.[checkinIndexWithPhoto]?.documentImages?.length > 0) {
      const allImages = [];
      for (let i = 0; i < checkInModalData?.profile?.checkins?.[0]?.documentImages?.length; i++) {
        const image = axios.get(process.env.REACT_APP_SERVER_URL + checkInModalData?.profile?.checkins?.[0]?.documentImages?.[i]?.path, {
          headers: { Authorization: `Bearer ${user.access_token}` },
          responseType: 'arraybuffer'
        });
        allImages.push(image);
      }
      Promise.all(allImages).then((res) => {
        const updatedImages = [];
        res.forEach((data) => {
          const image = Buffer.from(data?.data, 'binary').toString('base64');

          updatedImages.push(`data:image/jpeg;base64,${image}`);
        });
        setDocumentImages(updatedImages);
      });
    }
  }, [checkInModalData]);

  // Queries
  const getGuestUsers = useCustomQuery(GET_GUEST_USERS, {
    variables: {
      orderBy: queryFilters.orderBy,
      orderType: queryFilters.orderType,
      page: queryFilters.page,
      pageSize: queryFilters.pageSize,
      checkinStatus: queryFilters.checkinStatus,
      keyword: queryFilters.keyword
    },
    onError: (err) => {
      modalContext.openModal({
        class: 'danger',
        title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
        text: getApolloErrors(err).join(' - ')
      });
    },
    fetchPolicy: 'network-only'
  });

  // Mutations
  const [createGuestUser, { loading }] = useMutation(CREATE_GUEST_USER);
  const [updateGuestUserEmailConfirmed] = useMutation(UPDATE_GUEST_USER_EMAIL_CONFIRMED);

  const createUser = (values) => {
    createGuestUser({
      variables: {
        profileUUID: values.profileUUID,
        userInput: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password
        }
      }
    })
      .then(() => {
        modalContext.openModal({
          class: 'success',
          title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
          text: <TranslateTextComponent capitalize>user-added</TranslateTextComponent>
        });
        getGuestUsers.refetch();
        setShowNewUserModal(false);
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  // Subscriptions
  useSubscription(ON_CHECKIN_BY_GUEST_CREATED, {
    onSubscriptionData: () => {
      getGuestUsers.refetch({
        orderBy: queryFilters.orderBy,
        orderType: queryFilters.orderType,
        page: queryFilters.page,
        pageSize: queryFilters.pageSize,
        checkinStatus: queryFilters.checkinStatus,
        keyword: queryFilters.keyword
      });
    }
  });

  useSubscription(ON_CHECKIN_SAVED, {
    onSubscriptionData: () => {
      getGuestUsers.refetch({
        orderBy: queryFilters.orderBy,
        orderType: queryFilters.orderType,
        page: queryFilters.page,
        pageSize: queryFilters.pageSize,
        checkinStatus: queryFilters.checkinStatus,
        keyword: queryFilters.keyword
      });
    }
  });

  const onUpdateGuestUserEmailHandler = ({ emailConfirmedValue, userUUID }) => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>update-guest-user-confirmed-email-title</TranslateTextComponent>,
      text: emailConfirmedValue ? (
        <TranslateTextComponent capitalize>update-guest-user-confirmed-email-description</TranslateTextComponent>
      ) : (
        <TranslateTextComponent capitalize>update-guest-user-not-confirmed-email-description</TranslateTextComponent>
      ),
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            modalContext.setModalLoading(true);
            updateGuestUserEmailConfirmed({ variables: { emailConfirmedValue, userUUID } })
              .then(() => {
                getGuestUsers.refetch();
                modalContext.setModalLoading(false);
                modalContext.openModal({
                  class: 'success',
                  title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
                  text: <TranslateTextComponent capitalize>update-guest-user-confirmed-email-success</TranslateTextComponent>
                });
              })
              .catch((err) => {
                modalContext.setModalLoading(false);
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const handleSearchChange = (value) => {
    setQueryFilters((prev) => ({ ...prev, keyword: value }));
  };

  const setOrderType = (orderBy, orderType) => {
    const isDesc = queryFilters.orderBy === orderBy && orderType === 'desc';
    return isDesc ? 'ASC' : 'DESC';
  };

  const setTableSort = (orderBy, orderType) => {
    setQueryFilters((prev) => ({
      ...prev,
      orderBy,
      orderType: setOrderType(orderBy, orderType)
    }));
  };

  const setTablePages = (page, pageSize) => {
    setQueryFilters((prev) => ({
      ...prev,
      page,
      pageSize
    }));
  };

  return (
    <PageLayout>
      <CustomFilters
        filters={[
          {
            type: 'dropdown',
            options: Object.keys(CHECK_IN_STATUS)
              ?.map((key, index) => ({
                key: index,
                text: capitalizeFirstLetter(translate(CHECK_IN_STATUS[key]?.toLowerCase()?.replace(/_/g, '-'), language)),
                value: CHECK_IN_STATUS[key]
              }))
              ?.concat({ key: -1, text: capitalizeFirstLetter(translate('all', language)), value: null }),
            label: 'check-in-status',
            value: queryFilters.checkinStatus,
            onChange: ({ target: { value } }) => setQueryFilters((prev) => ({ ...prev, checkinStatus: value }))
          },
          { type: 'searchbar', onChange: ({ target: { value } }) => handleSearchChange(value) }
        ]}
      />
      <CommonTable
        isLoading={getGuestUsers.loading}
        headerCellStyle={{ padding: '20px 0px' }}
        isPaginationEnabled
        items={getGuestUsers.data?.getGuestUsers?.data ?? []}
        orderBy={queryFilters.orderBy}
        orderType={queryFilters.orderType.toLocaleLowerCase()}
        page={queryFilters.page}
        pageSize={queryFilters.pageSize}
        setTableSort={setTableSort}
        setTablePages={setTablePages}
        columns={guestUsersColumns({
          updateGuestUserEmailHandler: onUpdateGuestUserEmailHandler,
          openCheckInModal: (user) => {
            setCheckInModalData(user);
            setProfileUUID(user?.profile?.uuid);
          }
        })}
        totalRows={getGuestUsers.data?.getGuestUsers?.totalCount ?? 0}
        rowCellStyle={{ paddingLeft: 0 }}
        tableHeader={
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#EDFAFF',
              minHeight: 28,
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
            }}
          >
            <TranslateTextComponent capitalize>users-tot</TranslateTextComponent>: {getGuestUsers.data?.getGuestUsers?.totalCount ?? 0}
          </Typography>
        }
      />
      <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '30px 38px 20px 0px', minWidth: 325, alignSelf: 'flex-end', fontSize: 20, fontWeight: 500, color: 'white' }}
          onClick={() => history.push(ROUTE_CHECK_IN)}
        >
          <TranslateTextComponent uppercase>check-in-list</TranslateTextComponent>
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '30px 10px 20px 0px', minWidth: 325, alignSelf: 'flex-end', fontSize: 20, fontWeight: 500, color: 'white' }}
          onClick={() => setShowNewUserModal(true)}
        >
          <TranslateTextComponent uppercase>new-account</TranslateTextComponent>
        </Button>
      </Box>
      {showNewUserModal && <NewGuestUserModal loading={loading} closeModal={() => setShowNewUserModal(false)} addNewUser={createUser} />}
      <CheckInModal
        profileUUID={profileUUID}
        data={checkInModalData}
        documentImages={documentImages}
        onClose={() => {
          setProfileUUID(null);
          setDocumentImages([]);
          setCheckInModalData(null);
        }}
      />
    </PageLayout>
  );
};

export default GuestUsersList;
