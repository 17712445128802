/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const SET_DEFAULT_ROOM_TABLES = gql`
  mutation setDefaultFbRoomTables($fbRoomUUID: String!, $fbTables: [FbTablesInput!]!) {
    setDefaultFbRoomTables(fbRoomUUID: $fbRoomUUID, fbTables: $fbTables) {
      name
      fbTables {
        id
        isActive
        positionX
        positionY
      }
    }
  }
`;

export const MOVE_FB_ROOM_TABLES = gql`
  mutation moveFbRoomTables($fbRoomUUID: String!, $fbTables: [FbTablesInput!]!) {
    moveFbRoomTables(fbRoomUUID: $fbRoomUUID, fbTables: $fbTables) {
      name
      fbTables {
        id
        uuid
        isActive
        positionX
        positionY
      }
    }
  }
`;
