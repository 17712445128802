/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import typy from 'typy';
import { TranslateTextComponent } from '../../translator';
import { MaterialIcon } from '../../common-fe/src';
import styles from '../../styles/cash/totalKeyboardStyle';

const textTranslate = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

const courseSummaryColumns = ({ deleteBillItem, deleteAllBillItems, keyboardLabel, selectedRowUUID, onSetQuantity, onSetPrice }) => {
  const classes = styles();

  return [
    {
      id: 'quantity',
      disablePadding: false,
      width: '15%',
      label: textTranslate('quantity'),
      isSortable: false,
      align: 'center',
      render: (item) => {
        return (
          <span
            className={selectedRowUUID === item.uuid && keyboardLabel === 'quantity' ? classes.blink : ''}
            style={{
              cursor: 'pointer',
              paddingLeft: 20,
              paddingRight: 20
            }}
            onClick={() => onSetQuantity(item.uuid)}
          >
            {Math.round(typy(item, 'quantity').safeNumber * 100) / 100}
          </span>
        );
      }
    },
    {
      id: 'description',
      disablePadding: false,
      width: '40%',
      label: textTranslate('description'),
      isSortable: false,
      render: (item) => typy(item, 'name').safeString
    },
    {
      id: 'singlePrice',
      disablePadding: false,
      width: '20%',
      label: textTranslate('price'),
      isSortable: false,
      render: (item) => (
        <span
          className={selectedRowUUID === item.uuid && keyboardLabel === 'price' ? classes.blink : ''}
          style={{ cursor: 'pointer' }}
          onClick={() => onSetPrice(item.uuid)}
        >
          €{' '}
          {typy(item, 'price')
            .safeNumber.toFixed(2)
            .toString()
            .replace('.', ',')}
        </span>
      )
    },
    {
      id: 'amount',
      disablePadding: false,
      width: '25%',
      label: textTranslate('amount'),
      isSortable: false,
      align: 'center',
      render: (item) => (
        <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
          €{' '}
          {typy(item, 'total')
            .safeNumber.toFixed(2)
            .toString()
            .replace('.', ',')}
        </span>
      )
    },
    {
      id: 'delete',
      disablePadding: false,
      width: '25%',
      label: <MaterialIcon iconName="Clear" style={{ display: 'flex', cursor: 'pointer', color: '#cb2d24' }} onClick={deleteAllBillItems} />,
      isSortable: false,
      align: 'center',
      render: (item) => (
        <MaterialIcon iconName="Clear" style={{ display: 'flex', cursor: 'pointer', color: '#cb2d24' }} onClick={() => deleteBillItem(item.uuid)} />
      )
    }
  ];
};

export default courseSummaryColumns;
