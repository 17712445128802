/* eslint-disable indent */
import React, { useState, useContext } from 'react';
import { Button, IconButton, Box } from '@material-ui/core/';
import typy from 'typy';
import AddIcon from '@material-ui/icons/Add';
import { useMutation } from '@apollo/react-hooks';
import SegmentsForm from './SegmentsForm';
import { CommonTable } from '../../../common-fe';
import { GET_FB_SEGMENTS } from '../../../graphql/settings/segments/queries';
import { GET_FB_SERVICES } from '../../../graphql/settings/service/queries';
import { DELETE_FB_SEGMENT_MUTATION, EDIT_FB_SEGMENT_MUTATION, ADD_FB_SEGMENT_MUTATION } from '../../../graphql/settings/segments/mutations';
import { TranslateTextComponent } from '../../../translator';
import { ModalContext } from '../../../contexts/ModalContext';
import getSegmentsColumns from '../../../constants/settings/segmentsColumns';
import { getApolloErrors } from '../../../apollo/ApolloProvider';
import { AuthContext } from '../../../contexts/AuthContext';
import { DAYS } from '../../../constants/settings/segments';
import modalStyle from '../../../common-fe/src/styles/shared/modalStyle';
import useCustomQuery from '../../../hooks/useCustomQuery';

const newSegmentInitialState = {
  startDate: null,
  endDate: null,
  name: '',
  isActive: '',
  fbSegmentsServices: []
};

const SegmentsSettings = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const { appOutletUUID } = useContext(AuthContext);

  const classesModal = modalStyle();

  const [errorFormMap, setErrorFormMap] = useState(new Map());

  const [newSegment, setNewSegment] = useState({ ...newSegmentInitialState, fbSegmentsServices: [...newSegmentInitialState.fbSegmentsServices] });

  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);

  const [addFbSegment] = useMutation(ADD_FB_SEGMENT_MUTATION);
  const [editFbSegment] = useMutation(EDIT_FB_SEGMENT_MUTATION);
  const [deleteFbSegment] = useMutation(DELETE_FB_SEGMENT_MUTATION);

  const [editItemUUID, setEditItemUUID] = useState(null);

  const modalContext = useContext(ModalContext);

  const textTranslate = (text) => {
    return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
  };

  const getFbServices = useCustomQuery(GET_FB_SERVICES, {
    variables: { fbOutletUUID: appOutletUUID },

    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const getFbSegments = useCustomQuery(GET_FB_SEGMENTS, {
    variables: { fbOutletUUID: appOutletUUID },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const handleAddButton = () => {
    setFormEmpty();
    setEditItemUUID(null);

    if (isFormVisible) {
      setIsFormVisible(false);
    } else {
      setIsFormVisible(true);
    }
  };

  const deleteSegment = (item) => {
    modalContext.openModal({
      class: 'warning',
      title: textTranslate('delete-service-title'),
      text: textTranslate('delete-service-text'),
      actionButtons: [
        <Button key={1} variant="outlined" color="primary" className={classesModal.invertedButton} onClick={modalContext.closeModal}>
          {textTranslate('cancel')}
        </Button>,
        <Button
          key={2}
          color="primary"
          variant="contained"
          className={classesModal.buttonStyle}
          onClick={() => {
            modalContext.closeModal();
            deleteFbSegment({ variables: { fbSegmentUUID: item.uuid } })
              .then(() => getFbSegments.refetch())
              .catch((err) => {
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
          }}
        >
          {textTranslate('confirm')}
        </Button>
      ]
    });
  };

  const onClickSave = () => {
    const checkInputValidity = checkFormValidity();

    if (!checkInputValidity) {
      modalContext.openModal({
        class: 'danger',
        title: textTranslate('form-not-valid-title'),
        text: textTranslate('form-not-valid-text'),
        actionButtons: null
      });
    } else {
      modalContext.openModal({
        class: 'primary',
        title: textTranslate('form-valid-title'),
        text: textTranslate('form-valid-text'),
        actionButtons: [
          <Button key={1} variant="outlined" color="primary" className={classesModal.invertedButton} onClick={modalContext.closeModal}>
            {textTranslate('cancel')}
          </Button>,
          <Button
            key={2}
            color="primary"
            variant="contained"
            className={classesModal.buttonStyle}
            onClick={() => {
              modalContext.closeModal();
              fetchForm();
            }}
          >
            {textTranslate('confirm')}
          </Button>
        ]
      });
    }
    setIsFormVisible(false);
  };

  const fetchForm = () => {
    const newFbSegment = {
      ...newSegment,
      isActive: newSegment.isActive === 'activated'
    };
    if (newFbSegment.id) {
      delete newFbSegment.id;
    }
    if (editItemUUID) {
      editFbSegment({
        variables: {
          fbSegmentUUID: editItemUUID,
          fbSegmentData: newFbSegment
        }
      })
        .then(() => {
          modalContext.closeModal();
          modalContext.openModal({
            class: 'success',
            title: textTranslate('success-modal-title'),
            text: textTranslate('success-modal-text'),
            actionButtons: null
          });
          setFormEmpty();
          getFbSegments.refetch();
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        });
    } else {
      addFbSegment({
        variables: {
          fbOutletUUID: appOutletUUID,
          fbSegmentData: newFbSegment
        }
      })
        .then(() => {
          modalContext.closeModal();
          modalContext.openModal({
            class: 'success',
            title: textTranslate('success-modal-title'),
            text: textTranslate('success-modal-text'),
            actionButtons: null
          });
          setFormEmpty();
          setNewSegment({
            ...newSegment,
            fbSegmentsServices: []
          });
          getFbSegments.refetch();
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        });
    }
  };

  const editSegment = (item) => {
    setEditItemUUID(item.uuid);
    const updatedSegmentSettings = {
      id: item.id,
      name: item.name,
      isActive: item.isActive ? 'activated' : 'disabled',
      startDate: item.startDate,
      endDate: item.endDate,
      fbSegmentsServices: [
        ...item.fbSegmentsServices.map((fbSegmentsService) => {
          const newFbSegmentsService = {};
          newFbSegmentsService.fbServiceId = fbSegmentsService.fbService.id;
          newFbSegmentsService.days = [...fbSegmentsService.days];
          return newFbSegmentsService;
        })
      ]
    };
    setNewSegment(updatedSegmentSettings);
    setIsFormVisible(true);
  };

  const onClickDelete = () => {
    modalContext.openModal({
      class: 'warning',
      title: textTranslate('delete-form-title'),
      text: textTranslate('delete-form-text'),
      actionButtons: [
        <Button key={1} variant="outlined" color="primary" className={classesModal.invertedButton} onClick={modalContext.closeModal}>
          {textTranslate('cancel')}
        </Button>,
        <Button
          key={2}
          color="primary"
          variant="contained"
          className={classesModal.buttonStyle}
          onClick={() => {
            modalContext.closeModal();
            setFormEmpty();
          }}
        >
          {textTranslate('confirm')}
        </Button>
      ]
    });
  };

  const setFormEmpty = () => {
    setNewSegment({
      ...newSegmentInitialState,
      fbSegmentsServices: []
    });
    setSelectedDays([]);
    setSelectedServices([]);
    setIsFormVisible(false);
    setErrorFormMap(new Map());
  };

  const checkFormValidity = () => {
    if (newSegment.fbSegmentsServices.length > 0 && checkInputValidityForDays()) return true;
    return false;
  };

  const checkInputValidityForDays = () => {
    if (
      newSegment.isActive === '' ||
      newSegment.name === '' ||
      newSegment.startDate === null ||
      newSegment.endDate === null ||
      newSegment.startDate > newSegment.endDate
    ) {
      return false;
    }
    return true;
  };

  const checkIfOneDayIsSelected = () => {
    return selectedDays.length > 0;
  };

  const onDayClick = (index, day) => {
    if (checkInputValidityForDays()) {
      const dayIndex = selectedDays.findIndex((dayN) => {
        return Number(dayN) === Number(day.id);
      });

      if (dayIndex > -1) {
        selectedDays.splice(dayIndex, 1);
      } else {
        selectedDays.push(day.id);
      }
      setSelectedDays([...selectedDays]);
    }
  };

  const handleCheckboxChange = (event, serviceId) => {
    setSelectedServices(serviceId);
  };

  const deleteCurrentSegment = () => {
    setSelectedServices([]);
    setSelectedDays([]);
  };

  const addNewSegment = () => {
    selectedServices.forEach((serv) => {
      const newService = { fbServiceId: null, days: [] };
      newService.fbServiceId = serv;
      newService.days = [...selectedDays];

      newSegment.fbSegmentsServices.push(newService);

      setNewSegment({ ...newSegment, fbSegmentsServices: [...newSegment.fbSegmentsServices] });
    });
    setSelectedDays([]);

    setSelectedServices([]);
  };

  const setAllDaysSelected = () => {
    DAYS.forEach((day) => {
      if (!selectedDays.includes(day.id)) {
        selectedDays.push(day.id);
      }
    });
    setSelectedDays([...selectedDays]);
  };

  const updateSegmentsSettings = (key, value) => {
    setNewSegment({
      ...newSegment,
      [key]: value
    });
  };

  const deleteFromSegmentsService = (serviceIndex) => {
    if (newSegment.fbSegmentsServices && newSegment.fbSegmentsServices.length) {
      newSegment.fbSegmentsServices.splice(serviceIndex, 1);
      setNewSegment({
        ...newSegment,
        fbSegmentsServices: [...newSegment.fbSegmentsServices]
      });
    }
  };

  const saveEditedSegmentsService = (serviceIndex, serviceData) => {
    if (newSegment.fbSegmentsServices && newSegment.fbSegmentsServices.length) {
      newSegment.fbSegmentsServices[serviceIndex] = serviceData;
      setNewSegment({
        ...newSegment,
        fbSegmentsServices: [...newSegment.fbSegmentsServices]
      });
    }
  };
  const columns = getSegmentsColumns({ deleteSegment, editSegment });
  return (
    <Box>
      <CommonTable
        size="small"
        items={typy(getFbSegments, 'data.getFbSegments.data').safeArray.sort((prev, next) => prev.id - next.id)}
        columns={columns}
      />
      <Box style={{ padding: 10 }}>
        <IconButton className="buttonCircle" onClick={handleAddButton}>
          <AddIcon />
        </IconButton>
      </Box>
      {isFormVisible && (
        <SegmentsForm
          setIsFormVisible={setIsFormVisible}
          newSegment={newSegment}
          handleCheckboxChange={handleCheckboxChange}
          updateSegmentsSettings={updateSegmentsSettings}
          onClickSave={onClickSave}
          onClickDelete={onClickDelete}
          errorFormMap={errorFormMap}
          selectedDays={selectedDays}
          selectedServices={selectedServices}
          fbServices={typy(getFbServices, 'data.getFbServices.data').safeArray}
          checkInputValidityForDays={checkInputValidityForDays}
          onDayClick={onDayClick}
          checkIfOneDayIsSelected={checkIfOneDayIsSelected}
          setAllDaysSelected={setAllDaysSelected}
          deleteCurrentSegment={deleteCurrentSegment}
          addNewSegment={addNewSegment}
          saveEditedSegmentsService={saveEditedSegmentsService}
          deleteFromSegmentsService={deleteFromSegmentsService}
          editItemUUID={editItemUUID}
        />
      )}
    </Box>
  );
};

export default SegmentsSettings;
