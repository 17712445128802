/* eslint-disable no-nested-ternary */
import { makeStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    display: 'grid',
    // tax-code = codice fiscale (scontrino parlante)
    gridTemplateAreas: '"one two three cancel" "four five six tax-code" "seven eight nine return" "backspace zero dot return"',
    gridTemplateColumns: '1fr 1fr 1fr auto'
  },
  button: {
    fontSize: 31,
    fontWeight: 'bold',
    color: 'white',
    background: 'transparent linear-gradient(360deg, #3399CC 0%, #00B7FF 100%) 0% 0% no-repeat padding-box',
    borderRadius: 0,
    minWidth: 80,
    transition: 'background-color 0.3s',
    '&:hover': {
      background: 'transparent linear-gradient(360deg, #065b7c 0%, #00B7FF 100%) 0% 0% no-repeat padding-box'
    }
  }
};

export default makeStyles(styles);
