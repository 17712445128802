/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_FB_ROOMS = gql`
  query getFbRooms($fbOutletUUID: String!, $page: Int, $pageSize: Int) {
    getFbRooms(fbOutletUUID: $fbOutletUUID, page: $page, pageSize: $pageSize) {
      totalCount
      data {
        id
        uuid
        name
        isActive
        maxPax
        hasOnlineReservations
        fbServices {
          id
          uuid
          name
          startTime
          endTime
          icon
        }
      }
    }
  }
`;
