import React, { useState, useContext } from 'react';
import { TextField, Box, styled, TextareaAutosize } from '@material-ui/core';
import { isNumeric } from 'validator';
import { CustomButton, CustomModal, greenButton, primaryColor } from '../../../common-fe/src';
import { TranslateTextComponent, translate, capitalizeFirstLetter } from '../../../translator';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { isDiscountValid } from '../../../utils/cash';

const PERCENTAGES = ['5', '10', '20', '30', '40', '50', '60', '70'];

const SubContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  margin: '10px 0'
});

const DiscountModal = ({
  showDiscountModal,
  closeModal,
  currentDiscount,
  currentNotes,
  discountModalType,
  total,
  onDiscountSubmit,
  onDiscountPercentageSubmit
}) => {
  const [initialized, setInitialized] = useState(false);
  const [discount, setDiscount] = useState(currentDiscount || '');
  const [notes, setNotes] = useState(currentNotes || '');
  const { language } = useContext(LanguageContext);

  return (
    <CustomModal
      width="auto"
      height="auto"
      showModal={showDiscountModal}
      onClose={closeModal}
      styleBody={{ display: 'block', minWidth: 500, maxWidth: 600 }}
    >
      <SubContainer>
        <TextField
          error={initialized && !isNumeric(discount || '')}
          label={<TranslateTextComponent capitalize>{discountModalType === 'percentage' ? 'discount%' : 'discount€'}</TranslateTextComponent>}
          variant="outlined"
          style={{ width: '100%' }}
          value={discount || ''}
          onClick={() => setInitialized(true)}
          onChange={(e) => {
            if (discount.length <= 0 && e.target.value === '0') {
              setDiscount('0.');
            } else if (!e.target.value) {
              setDiscount('');
            } else if (isDiscountValid(discountModalType, e.target.value.replace(',', '.'), total)) {
              setDiscount(e.target.value.replace(',', '.'));
            }
          }}
        />
      </SubContainer>
      <SubContainer>
        <TextareaAutosize
          placeholder={capitalizeFirstLetter(translate('notes', language))}
          rowsMax={4}
          style={{
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            height: 'auto',
            width: '100%',
            padding: 10,
            border: `1px solid ${primaryColor}`
          }}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </SubContainer>
      {discountModalType === 'percentage' && (
        <SubContainer style={{ flexWrap: 'wrap' }}>
          {PERCENTAGES.map((percentage) => (
            <CustomButton key={percentage} style={{ width: 'auto', margin: '5px 5px' }} onClick={() => setDiscount(percentage)}>
              {`${percentage}%`}
            </CustomButton>
          ))}
        </SubContainer>
      )}
      <SubContainer style={{ justifyContent: 'space-between' }}>
        <CustomButton style={{ width: 'auto' }} onClick={closeModal}>
          <TranslateTextComponent>cancel</TranslateTextComponent>
        </CustomButton>
        <CustomButton
          style={{ width: 'auto', backgroundColor: greenButton }}
          disabled={!isNumeric(discount || '')}
          onClick={() => {
            if (discountModalType === 'percentage') onDiscountPercentageSubmit({ value: parseFloat(discount, 10), notes });
            else onDiscountSubmit({ value: parseFloat(discount, 10), notes });
          }}
        >
          <TranslateTextComponent>save</TranslateTextComponent>
        </CustomButton>
      </SubContainer>
    </CustomModal>
  );
};

export default DiscountModal;
