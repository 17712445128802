import React, { useState, useContext, useEffect } from 'react';
import { TextField, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import styled from 'styled-components/macro';
import moment from 'moment';
import { Howl } from 'howler';
import { MaterialIcon, primaryColor, CommonTable, mainTextColor, CustomModal, redButton } from '../../../common-fe/src';
import InputWithLabel from '../../shared/InputWithLabel';
import orderListColumns from './table/orderListColumns';
import Buttons from '../../shared/Buttons';
import ORDER_TYPE from '../../../constants/moh/orderType';
import { AppOutletContext } from '../../../contexts/AppOutletContext';
import { TranslateTextComponent, translate, capitalizeFirstLetter } from '../../../translator';
import { LanguageContext } from '../../../contexts/LanguageContext';
import sound from '../../../assets/sounds/order-to-confirm-sound.mp3';
import { getLatestCheckin } from '../../../utils/getLatestCheckin';

const Container = styled.div`
  max-width: 860px;
  width: 100%;
  height: 100%;
  border-radius: 10px;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 30px;
  color: white;
  background-color: ${() => primaryColor};
  height: 60px;
  padding: 40px 30px;
  text-transform: uppercase;
`;

const RowContainer = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

const DELIVERY_TAKEAWAY_TIME_OFFSET = 50;

const OrderConfirmationModal = ({ order, confirmOrder, deleteOrder }) => {
  const { appOutlet } = useContext(AppOutletContext);
  const { language } = useContext(LanguageContext);
  const currentOrderDeliveryDateAndTime = moment(`${order?.date} ${order?.time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
  const [deliveryDateAndTime, setDeliveryDateAndTime] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const notification = new Howl({
      src: [sound],
      loop: true
    });
    notification.play();

    return () => notification.stop();
  }, []);

  const getTotalPrice = () => {
    const totalPrices = order?.fbCourses
      .map((fbCourse) => ({ ...fbCourse }))
      .map((course) => course?.fbCoursesMenuItems?.map((item) => item.price * item.quantity))
      .flat();

    return totalPrices?.reduce((p, v) => p + v, 0) ?? 0;
  };

  const getDateAndTime = ({ offset = 0 }) => {
    if (!currentOrderDeliveryDateAndTime) return ' - ';
    return moment(currentOrderDeliveryDateAndTime).add(offset, 'minutes').format('DD-MM-YYYY HH:mm');
  };

  const getAddress = () => {
    let address = `${order?.deliveryAddress?.line1 ?? ''} ${order?.deliveryAddress?.addressNumber ?? ''}`.trim();

    if (order?.deliveryAddress?.city) {
      address += `, ${order.deliveryAddress.city}`;
      if (order?.deliveryAddress?.postalCode) {
        address += ` - ${order.deliveryAddress.postalCode}`;
      }
    }

    if (order?.deliveryAddress?.region) {
      address += `, ${order.deliveryAddress.region}`;
    }

    return address;
  };

  const isOutOfTime = () => {
    const outOfTime =
      moment(parseInt(order?.time, 10), 'HH:mm').format('HH:mm') <=
      moment(order?.type === ORDER_TYPE.DELIVERY ? appOutlet?.mohSettings?.deliveryStartTime : appOutlet?.mohSettings?.takeAwayStartTime, 'HH:mm')
        .add(DELIVERY_TAKEAWAY_TIME_OFFSET, 'minutes')
        .format('HH:mm');

    return !order?.deliveryTime && outOfTime;
  };

  const getDeliveryTime = () => {
    if (deliveryDateAndTime) return moment(deliveryDateAndTime, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm');
    if (order?.deliveryTime) return moment(parseInt(order?.deliveryTime, 10)).format('YYYY-MM-DD HH:mm');
    if (currentOrderDeliveryDateAndTime) return currentOrderDeliveryDateAndTime;
    return null;
  };

  return (
    <CustomModal
      showModal
      hideCloseButton
      width="auto"
      height="auto"
      styleBody={{ maxWidth: 580, padding: 20 }}
      header={
        <Header>
          <MaterialIcon iconName="notifications" style={{ fontSize: 45 }} />
          <div style={{ flex: 1, textAlign: 'center' }}>
            <TranslateTextComponent capitalize>new-order</TranslateTextComponent> #{order?.id}
          </div>
        </Header>
      }
    >
      <Container>
        <RowContainer>
          <InputWithLabel label="order-number">
            <TextField variant="outlined" value={order?.id} />
          </InputWithLabel>
          <InputWithLabel label="delivery-date-and-time">
            <TextField variant="outlined" value={`${order?.date} - ${order?.time}`} />
          </InputWithLabel>
          <InputWithLabel label="price">
            <TextField variant="outlined" value={`${getTotalPrice()}€`} />
          </InputWithLabel>
        </RowContainer>
        {order?.type === ORDER_TYPE.DELIVERY ? (
          <RowContainer>
            <InputWithLabel label="send-to">
              <TextField fullWidth variant="outlined" style={{ textTransform: 'capitalize' }} value={getAddress()} />
            </InputWithLabel>
            <InputWithLabel label="room-number">
              <TextField
                fullWidth
                variant="outlined"
                style={{ textTransform: 'capitalize' }}
                value={getLatestCheckin(order?.profile?.checkins)?.room?.number ?? order?.guestRoom}
              />
            </InputWithLabel>
          </RowContainer>
        ) : (
          <RowContainer>
            <InputWithLabel label="client-name">
              <TextField
                fullWidth
                variant="outlined"
                value={
                  order?.profile?.lastName || order?.profile?.firstName
                    ? `${order?.profile?.firstName} ${order?.profile?.lastName}`
                    : order?.clientName
                }
              />
            </InputWithLabel>
          </RowContainer>
        )}
        <RowContainer>
          <InputWithLabel label="additional-notes">
            <TextField fullWidth variant="outlined" value={order?.notes ?? ''} />
          </InputWithLabel>
        </RowContainer>
        <RowContainer>
          <InputWithLabel label="order-list">
            <CommonTable
              tableContainerStyle={{ width: '100%', fontSize: 14, border: `1px solid ${mainTextColor}`, opacity: 0.7, borderRadius: 5 }}
              headerCellStyle={{ color: mainTextColor, fontSize: 14 }}
              headerTextStyle={{ color: mainTextColor, fontSize: 14 }}
              rowCellStyle={{ borderTop: `1px solid ${mainTextColor}` }}
              rowStyle={{ borderBottom: `1px solid ${mainTextColor}` }}
              items={order?.fbCourses ?? []}
              columns={orderListColumns()}
            />
          </InputWithLabel>
        </RowContainer>
        {isOutOfTime() ? (
          <RowContainer>
            <InputWithLabel label="delivery-time" error={error}>
              <RadioGroup
                aria-label="delivery date and time"
                value={deliveryDateAndTime}
                style={{ flexFlow: 'row' }}
                onChange={({ target: { value } }) => setDeliveryDateAndTime(value)}
              >
                <FormControlLabel
                  value={getDateAndTime({})}
                  disabled={!currentOrderDeliveryDateAndTime}
                  control={<Radio />}
                  label={getDateAndTime({})}
                />
                <FormControlLabel
                  value={getDateAndTime({ offset: 15 })}
                  disabled={!currentOrderDeliveryDateAndTime}
                  control={<Radio />}
                  label={getDateAndTime({ offset: 15 })}
                />
                <FormControlLabel
                  value={getDateAndTime({ offset: 30 })}
                  disabled={!currentOrderDeliveryDateAndTime}
                  control={<Radio />}
                  label={getDateAndTime({ offset: 30 })}
                />
                <FormControlLabel
                  value={getDateAndTime({ offset: 45 })}
                  disabled={!currentOrderDeliveryDateAndTime}
                  control={<Radio />}
                  label={getDateAndTime({ offset: 45 })}
                />
              </RadioGroup>
            </InputWithLabel>
          </RowContainer>
        ) : null}
        <RowContainer>
          <Buttons
            containerStyle={{ justifyContent: 'space-between', marginTop: 20 }}
            buttons={[
              {
                buttonType: 'button',
                type: 'cancel',
                label: 'delete',
                style: {
                  padding: 0,
                  marginLeft: 80,
                  backgroundColor: 'transparent',
                  border: 'none',
                  color: redButton,
                  textDecoration: 'underline',
                  minHeight: 50,
                  fontSize: 20,
                  fontWeight: 'medium',
                  textTransform: 'uppercase'
                },
                onClick: () => deleteOrder({ uuid: order?.uuid })
              },
              {
                buttonType: 'button',
                type: 'confirm',
                label: 'confirm',
                style: { flex: 0.5, minHeight: 50, minWidth: 227, fontSize: 20, fontWeight: 'medium', textTransform: 'uppercase' },
                onClick: () => {
                  if (isOutOfTime() && !deliveryDateAndTime) {
                    setError(capitalizeFirstLetter(translate('required', language)));
                  } else {
                    setError(null);
                    confirmOrder({
                      uuid: order?.uuid,
                      deliveryTime: getDeliveryTime()
                    });
                  }
                }
              }
            ]}
          />
        </RowContainer>
      </Container>
    </CustomModal>
  );
};

export default OrderConfirmationModal;
