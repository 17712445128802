/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { TranslateTextComponent } from '../../../translator';

const textTranslate = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

const staffUsersColumns = ({ openUserModal, onDeleteStaffUser }) => {

  return [
    {
      id: 'first-name',
      label: textTranslate('first-name'),
      isSortable: false,
      align: 'left',
      render: (item) => item.firstName
    },
    {
      id: 'last-name',
      label: textTranslate('last-name'),
      isSortable: false,
      align: 'left',
      render: (item) => item.lastName
    },
    {
      id: 'email',
      disablePadding: false,
      label: textTranslate('email'),
      isSortable: false,
      align: 'left',
      render: (item) => item.email
    },
    {
      id: 'actions',
      disablePadding: true,
      label: textTranslate('actions'),
      isSortable: false,
      render: (item) => (
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <IconButton className="buttonCircle" style={{ marginRight: 5 }} onClick={() => openUserModal(item)}>
            <EditIcon />
          </IconButton>
          <IconButton className="buttonCircle" onClick={() => onDeleteStaffUser(item.uuid)}>
            <DeleteIcon />
          </IconButton>
        </div>
      )
    }
  ];
};

export default staffUsersColumns;
