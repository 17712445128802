import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from '../theme';

const gridStyles = {
  gridContainer: () => ({
    marginTop: 30
  }),
  tableImgText: () => ({
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center'
  }),
  tableContentImg: () => ({
    marginBottom: 5,
    marginTop: 3,
    marginRight: 10,
    alignContent: 'center'
  }),
  avatarImg: () => ({
    marginRight: 8,
    width: 78,
    height: 78
  }),
  boxGuestName: () => ({
    alignSelf: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10
  }),
  tableCell: () => ({
    borderRightStyle: 'solid',
    borderRightColor: primaryColor
  }),
  paperStyle: () => ({
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 20,
    marginBottom: 30,
    border: 'solid',
    flex: 2,
    borderRadius: 20,
    borderColor: primaryColor,
    width: '100%'
  }),
  gridContainerStyle: () => ({
    paddingRight: 18,
    paddingLeft: 18
  }),
  borderBox: () => ({
    borderRightStyle: 'solid',
    borderRightColor: primaryColor,
    flex: 2,
    display: 'flex',
    fontSize: 13,
    color: '#55595C',
    alignContent: 'center',
    alignItems: 'center'
  }),
  borderStyle: () => ({
    borderRightStyle: 'solid',
    borderRightColor: primaryColor,
    marginLeft: 18,
    marginRight: 18
  }),
  phoneSection: () => ({
    borderRightStyle: 'solid',
    borderRightColor: primaryColor,
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    paddingLeft: 10,
    paddingRight: 5,
    fontSize: 13,
    color: '#55595C',
    alignSelf: 'center'
  }),

  txtStyle: () => ({
    fontSize: 13,
    color: '#55595C',
    fontFamily: 'Roboto'
  }),
  gridCheckIn: () => ({
    display: 'flex',
    flex: 1,
    alignSelf: 'center',
    alignItems: 'center',
    alignContent: 'flex-start',
    paddingBottom: 10
  }),
  languageSection: () => ({
    paddingRight: 18,
    paddingLeft: 18,
    fontSize: 13,
    color: '#55595C',
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center'
  }),

  repeater: () => ({
    fontSize: 13,
    color: '#55595C'
  }),
  repeaterSection: () => ({
    alignSelf: 'center',
    textAlign: 'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }),
  reservationSection: () => ({
    flex: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRightStyle: 'solid',
    borderRightColor: primaryColor,
    paddingLeft: 10,
    fontSize: 13,
    color: '#55595C',
    textAlign: '-webkit-center',
    paddingRight: 5
  }),
  reservationSectionTablet: () => ({
    flex: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRightStyle: 'solid',
    borderRightColor: primaryColor,
    paddingLeft: 10,
    fontSize: 13,
    color: '#55595C',
    textAlign: '-webkit-center'
  }),
  extReservationSectionTablet: () => ({
    flex: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRightStyle: 'solid',
    borderRightColor: primaryColor,
    paddingLeft: 10,
    fontSize: 13,
    color: '#55595C',
    textAlign: '-webkit-center'
  }),
  roomImg: () => ({
    display: 'block',
    paddingBottom: 6
  })
};

export default makeStyles(gridStyles);
