import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import PageLayout from '../shared/PageLayout';
import { CustomButton, invertedPrimaryCC80 } from '../../common-fe/src';
import CustomFilters from '../shared/CustomFilters/CustomFilters';
import Dashboard from './Dashboard/Dashboard';
import useCustomQuery from '../../hooks/useCustomQuery';
import { GET_FB_RESERVATIONS, GET_FB_RESERVATIONS_SUMMARY } from '../../graphql/reservations/queries';
import { TranslateTextComponent, capitalizeFirstLetter, translate } from '../../translator';
import ReservationsList from './ReservationsList/ReservationsList';
import { GET_FB_OUTLETS } from '../../graphql/outlets/queries';
import { AuthContext } from '../../contexts/AuthContext';
import FB_RESERVATION_STATUSES from '../../constants/showReservations/reservationsStatuses';
import { LanguageContext } from '../../contexts/LanguageContext';
import { GET_FB_SERVICES } from '../../graphql/settings/service/queries';
import Guard from '../shared/Guard';
import { ROLES } from '../../constants/users/usersRole';

const RowContainer = styled.div`
  display: flex;
`;

const SECTIONS = { RESERVATIONS_LIST: 'RESERVATIONS_LIST', DASHBOARD: 'DASHBOARD' };
const Reservations = () => {
  const { appOutletUUID } = useContext(AuthContext);
  const { language } = useContext(LanguageContext);
  const [currentSection, setCurrentSection] = useState(SECTIONS.RESERVATIONS_LIST);
  const [filters, setFilters] = useState({
    date: new Date(),
    toDate: new Date(),
    outlet: appOutletUUID,
    status: null,
    service: null,
    searchBarValue: '',
    orderBy: 'createdAt',
    orderType: 'ASC',
    page: 1,
    pageSize: 10
  });

  useEffect(() => {
    setFilters((prev) => ({ ...prev, status: null, service: null, searchBarValue: '', page: 1, pageSize: 10 }));
  }, [currentSection]);

  useEffect(() => {
    if (moment(filters.toDate).isBefore(moment(filters.date))) {
      setFilters((prev) => ({ ...prev, toDate: filters.date }));
    }
  }, [filters.date, filters.toDate]);

  // Queries
  const getFbReservationsSummary = useCustomQuery(GET_FB_RESERVATIONS_SUMMARY, {
    variables: { date: filters.date, toDate: filters.toDate },
    skip: currentSection === SECTIONS.RESERVATIONS_LIST || !filters.date,
    pollInterval: 10000
  });

  const getFbReservations = useCustomQuery(GET_FB_RESERVATIONS, {
    variables: {
      orderBy: filters.orderBy,
      orderType: filters.orderType,
      date: filters.date,
      toDate: filters.toDate,
      fbOutletUUID: filters.outlet,
      status: filters.status,
      fbServiceUUID: filters.service,
      keyword: filters.searchBarValue,
      page: filters.page,
      pageSize: filters.pageSize
    },
    skip: currentSection === SECTIONS.DASHBOARD,
    pollInterval: 10000
  });
  const getPrintableReservations = useCustomQuery(GET_FB_RESERVATIONS, {
    variables: {
      orderBy: filters.orderBy,
      orderType: filters.orderType,
      date: filters.date,
      toDate: filters.toDate,
      fbOutletUUID: filters.outlet,
      status: filters.status,
      fbServiceUUID: filters.service,
      keyword: filters.searchBarValue
    },
    skip: currentSection === SECTIONS.DASHBOARD
  });
  const getFbOutlets = useCustomQuery(GET_FB_OUTLETS);
  const getFbServices = useCustomQuery(GET_FB_SERVICES, { variables: { fbOutletUUID: filters.outlet || appOutletUUID } });

  const onOutletClick = (outletUUID) => {
    setFilters((prev) => ({ ...prev, outlet: outletUUID }));
    setCurrentSection(SECTIONS.RESERVATIONS_LIST);
  };

  const onTablePageChanges = ({ page, pageSize }) => {
    setFilters((prev) => ({ ...prev, page, pageSize }));
  };

  const onTableSort = (column) => {
    const orderType = filters.orderType === 'ASC' ? 'DESC' : 'ASC';
    setFilters((prev) => ({ ...prev, orderType, orderBy: column }));
  };

  const getSection = () => {
    switch (currentSection) {
      case SECTIONS.RESERVATIONS_LIST:
        return (
          <ReservationsList
            reservations={getFbReservations}
            maxPax={getFbOutlets.data?.getFbOutlets?.data?.find((o) => o.uuid === filters.outlet || o.uuid === appOutletUUID)?.maxPax ?? 0}
            printableReservations={
              getPrintableReservations.data?.getFbReservations?.data?.filter((r) => r.status !== FB_RESERVATION_STATUSES.deleted.label) ?? []
            }
            page={filters.page}
            pageSize={filters.pageSize}
            onTablePageChanges={onTablePageChanges}
            onTableSort={onTableSort}
            onPackageChange
          />
        );
      case SECTIONS.DASHBOARD:
        return (
          <Dashboard
            reservationsSummary={
              getFbReservationsSummary.data?.getFbReservationsSummary?.filter(
                (fbReservationSummary) =>
                  fbReservationSummary?.outlet?.outletCategories?.length > 0 &&
                  fbReservationSummary?.outlet?.outletCategories?.some((outletCategory) => outletCategory?.type === 'restaurant')
              ) ?? []
            }
            onOutletClick={onOutletClick}
          />
        );
      default:
        return (
          <ReservationsList
            reservations={getFbReservations}
            maxPax={getFbOutlets.data?.getFbOutlets?.data?.find((o) => o.uuid === filters.outlet || o.uuid === appOutletUUID)?.maxPax ?? 0}
            printableReservations={
              getPrintableReservations.data?.getFbReservations?.data?.filter((r) => r.status !== FB_RESERVATION_STATUSES.deleted.label) ?? []
            }
            page={filters.page}
            pageSize={filters.pageSize}
            onTablePageChanges={onTablePageChanges}
            onTableSort={onTableSort}
            onOutletClick={onOutletClick}
          />
        );
    }
  };

  return (
    <PageLayout>
      <RowContainer style={{ borderBottom: `1px solid ${invertedPrimaryCC80}`, paddingBottom: 10 }}>
        <Guard
          roles={[
            ROLES.ROLE_SUPER_ADMIN,
            ROLES.ROLE_LOCAL,
            ROLES.ROLE_SUPERVISOR,
            ROLES.ROLE_FRONT_OFFICE,
            ROLES.ROLE_GUEST_SERVICE,
            ROLES.ROLE_FB_OPERATOR,
            ROLES.ROLE_MAITRE,
            ROLES.ROLE_HOSTESS,
            ROLES.ROLE_ROOM_SERVICE
          ]}
        >
          <CustomButton
            style={{ width: 'auto', padding: '12px 28px', marginRight: 20 }}
            onClick={() =>
              currentSection === SECTIONS.DASHBOARD ? setCurrentSection(SECTIONS.RESERVATIONS_LIST) : setCurrentSection(SECTIONS.DASHBOARD)
            }
          >
            <TranslateTextComponent>{currentSection === SECTIONS.DASHBOARD ? 'reservations' : 'dashboard'}</TranslateTextComponent>
          </CustomButton>
        </Guard>
        <CustomFilters
          labelsOnTop
          labelsStyles={{ fontSize: 14 }}
          filters={[
            {
              type: 'date',
              label: 'bookings-from',
              value: filters.date,
              onChange: (value) => setFilters((prev) => ({ ...prev, date: value, page: 1, pageSize: 10 }))
            },
            {
              type: 'date',
              label: 'bookings-to',
              value: filters.toDate,
              onChange: (value) => setFilters((prev) => ({ ...prev, toDate: value, page: 1, pageSize: 10 }))
            },
            {
              type: 'dropdown',
              label: 'outlet',
              options: getFbOutlets.data?.getFbOutlets?.data
                ?.map((outlet) => ({ text: outlet.name, value: outlet.uuid }))
                .concat({ text: capitalizeFirstLetter(translate('all', language)), value: null }),
              value: filters.outlet,
              disabled: currentSection === SECTIONS.DASHBOARD,
              onChange: ({ target: { value } }) => {
                if (!value) {
                  setFilters((prev) => ({ ...prev, outlet: value, page: 1, pageSize: 10 }));
                } else {
                  setFilters((prev) => ({ ...prev, outlet: value, page: 1, pageSize: 10 }));
                }
              }
            },
            {
              type: 'dropdown',
              label: 'status',
              options: Object.keys(FB_RESERVATION_STATUSES)
                .map((key) => ({
                  text:
                    FB_RESERVATION_STATUSES[key].label === 'open'
                      ? capitalizeFirstLetter(translate('status-open', language))
                      : capitalizeFirstLetter(translate(FB_RESERVATION_STATUSES[key].label, language)),
                  value: FB_RESERVATION_STATUSES[key].label
                }))
                .concat({ text: capitalizeFirstLetter(translate('all', language)), value: null }),
              value: filters.status,
              disabled: currentSection === SECTIONS.DASHBOARD,
              onChange: ({ target: { value } }) => setFilters((prev) => ({ ...prev, status: value, page: 1, pageSize: 10 }))
            },
            {
              type: 'dropdown',
              label: 'service',
              options: getFbServices.data?.getFbServices?.data
                ?.map((service) => ({
                  text: capitalizeFirstLetter(translate(service.name, language)),
                  value: service.uuid
                }))
                .concat({ text: capitalizeFirstLetter(translate('all', language)), value: null }),
              value: filters.service,
              disabled: currentSection === SECTIONS.DASHBOARD,
              onChange: ({ target: { value } }) => setFilters((prev) => ({ ...prev, service: value, page: 1, pageSize: 10 }))
            },
            {
              type: 'searchbar',
              label: 'search',
              value: filters.searchBarValue,
              disabled: currentSection === SECTIONS.DASHBOARD,
              onClear: () => setFilters((prev) => ({ ...prev, searchBarValue: '', page: 1, pageSize: 10 })),
              onChange: ({ target: { value } }) => setFilters((prev) => ({ ...prev, searchBarValue: value, page: 1, pageSize: 10 }))
            }
          ]}
        />
      </RowContainer>
      {getSection()}
    </PageLayout>
  );
};

export default Reservations;
