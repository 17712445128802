import { makeStyles } from '@material-ui/core/styles';

const styles = {
  dropdown: {
    '& div .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 5,
      '& .MuiAutocomplete-input': {
        padding: 2
      }
    }
  },
  rangeTextField: {
    '& div': {
      minHeight: 44
    }
  }
};

export default makeStyles(styles);
