import React, { useState } from 'react';
import Tabs from '../../shared/Tabs/Tabs';
import PageLayout from '../../shared/PageLayout';
import { primaryColor } from '../../../styles/theme';
import ProfileForm from './Forms/ProfileForm';
import PasswordForm from './Forms/PasswordForm';

const TABS = { PROFILE: 'profile', PASSWORD: 'password' };
const Account = () => {
  const [currentTab, setCurrentTab] = useState(TABS.PROFILE);

  const getContent = () => {
    switch (currentTab) {
      case TABS.PROFILE:
        return <ProfileForm />;
      case TABS.PASSWORD:
        return <PasswordForm />;
      default:
        return <ProfileForm />;
    }
  };

  return (
    <PageLayout>
      <Tabs
        tabs={Object.keys(TABS).map((key) => ({ label: TABS[key] }))}
        variant="standard"
        currentTab={currentTab}
        style={{ borderBottom: `1px solid ${primaryColor}`, marginBottom: 20 }}
        tabItemsStyle={{ width: 'auto' }}
        changeTab={(tabIndex) => setCurrentTab(tabIndex)}
      />
      {getContent()}
    </PageLayout>
  );
};

export default Account;
