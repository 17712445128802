import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import styled from 'styled-components/macro';
import { ClickAwayListener, Grid } from '@material-ui/core';
import { headerHeight, primaryColor } from '../../common-fe/src/styles/theme';
import { TranslateTextComponent } from '../../translator';

const Container = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border-left: ${({ withBorderLeft }) => (withBorderLeft ? '1px solid white' : 'none')};
  border-right: ${({ withBorderRight }) => (withBorderRight ? '1px solid white' : 'none')};
  color: white;
  font-size: 16px;
  &:hover {
    box-shadow: 0 0 10px 100px #1392bf inset;
  }
`;

const ItemsContainer = styled.ul`
  position: absolute;
  top: ${() => `${headerHeight - 20}px`};
  left: 0;
  padding: 0;
  margin: 0;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 20px;
  z-index: 10;
  max-height: 300px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

const Item = styled.li`
  color: ${() => primaryColor};
  list-style: none;
  padding: 10px 25px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ebebeb;
  }
`;

const HeaderMenuItems = ({
  withBorderLeft,
  withBorderRight,
  withoutArrow,
  label,
  value,
  items,
  width,
  onChange,
  mainContent,
  containerStyle,
  labelStyle,
  itemsContainerStyle,
  itemContainerStyle
}) => {
  const [showItems, setShowItems] = useState(false);
  const selectedValue = items.find((item) => item.value === value);
  return (
    <Container
      style={{ width, ...containerStyle }}
      withBorderLeft={withBorderLeft}
      withBorderRight={withBorderRight}
      onClick={() => setShowItems((prev) => !prev)}
    >
      {mainContent ? (
        <Grid container style={{ paddingTop: 10 }}>
          <Grid item style={{ flex: 1 }}>
            {mainContent}
          </Grid>
          <Grid style={{ width: 20 }} item>
            {!showItems ? !withoutArrow && <KeyboardArrowDown /> : !withoutArrow && <KeyboardArrowUp />}
          </Grid>
        </Grid>
      ) : (
        <Grid container style={{ paddingTop: 5 }}>
          <Grid item style={{ flex: 1 }}>
            {selectedValue?.label ?? label}
          </Grid>
          <Grid style={{ width: 20 }} item>
            {!showItems ? !withoutArrow && <KeyboardArrowDown /> : !withoutArrow && <KeyboardArrowUp />}
          </Grid>
        </Grid>
      )}
      {showItems && (
        <ClickAwayListener onClickAway={() => setShowItems(false)}>
          <ItemsContainer style={{ width, ...itemsContainerStyle }}>
            {items.map((item, index) => (
              <Item key={index} style={itemContainerStyle} onClick={() => onChange(item.value)}>
                {item.content || (
                  <TranslateTextComponent style={labelStyle} capitalize>
                    {item.label}
                  </TranslateTextComponent>
                )}
              </Item>
            ))}
          </ItemsContainer>
        </ClickAwayListener>
      )}
    </Container>
  );
};

HeaderMenuItems.propTypes = {
  withBorderLeft: PropTypes.bool,
  withBorderRight: PropTypes.bool,
  withoutArrow: PropTypes.bool,
  label: PropTypes.string,
  mainContent: PropTypes.element,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      content: PropTypes.element,
      onClick: PropTypes.func
    })
  )
};

HeaderMenuItems.defaultProps = {
  withBorderLeft: false,
  withBorderRight: false,
  withoutArrow: false,
  label: '',
  mainContent: null,
  items: []
};

export default HeaderMenuItems;
