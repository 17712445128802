import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from '../../theme';

const styles = {
  container: {
    width: '100%',
    maxWidth: 280,
    borderRadius: 4,
    boxShadow: 'rgba(0, 0, 0, 0.294) 0px 3px 10px',
    '& > div:not(:last-child)': {
      borderBottom: `2px solid ${primaryColor}`
    },
    '& > div': {
      padding: '20px 0',
      width: '100%'
    }
  },
  name: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textOverflow: 'ellipsis'
  },
  quantity: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: 'auto',
    padding: 0,
    '& div': {
      display: 'flex'
    }
  },
  quantityButton: {
    backgroundColor: 'white',
    color: 'black',
    border: `2px solid ${primaryColor}`,
    height: 30,
    minWidth: 30,
    width: 30,
    '&:hover': {
      border: `2px solid ${primaryColor}`
    }
  },
  withOrWithout: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column'
  },
  withOrWithoutButtons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%'
  },
  withButton: {
    color: 'rgb(203, 45, 36)',
    border: '1px solid white',
    '&:hover': {
      backgroundColor: 'transparent',
      border: '1px solid rgb(203, 45, 36)'
    }
  },
  withoutButton: {
    color: 'rgb(68, 157, 68)',
    border: '1px solid white',
    '&:hover': {
      backgroundColor: 'transparent',
      border: '1px solid rgb(68, 157, 68)'
    }
  },
  notes: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
    '& button': {
      borderRadius: 9,
      minWidth: 126
    }
  },
  textField: {
    '& div': {
      height: 30
    },
    '& label': {
      fontSize: 12,
      transform: 'translate(8px, 10px) scale(1)'
    }
  },
  autocompleteRoot: { width: '90%' },
  autocompletePaper: {
    '&.MuiAutocomplete-paper .MuiAutocomplete-listbox': { maxHeight: 120 }
  },
  autocompleteInputRoot: {
    '&.MuiAutocomplete-inputRoot': { padding: 4 }
  }
};

export default makeStyles(styles);
