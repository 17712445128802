import React, { useContext } from 'react';
import * as moment from 'moment';
import { Accordion, AccordionSummary, AccordionDetails, Box, Grid, Typography, Button, IconButton, CircularProgress } from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReservationContext } from '../../contexts/ReservationContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import { ModalContext } from '../../contexts/ModalContext';
import { TranslateTextComponent, translate, capitalizeFirstLetter, getTranslatedDate } from '../../translator';
import { DataPicker, InputTextLabel, InputStepper, MaterialIcon, TimePicker, Dropdown } from '../../common-fe';
import BoxImgCategories from './BoxImgCategories';
import ImagesCategoryPanel from './ImagesCategoryPanel';
import modalStyle from '../../common-fe/src/styles/shared/modalStyle';
import ecStyles from '../../styles/saveReservation/AvailabilityStyle';
import { ReactComponent as Clock } from '../../assets/images/clock.svg';
import { ReactComponent as Calendar } from '../../assets/images/calendar.svg';
import { ReactComponent as Table } from '../../assets/images/new_reservation/table.svg';
import useCustomQuery from '../../hooks/useCustomQuery';
import { GET_PACKAGES } from '../../graphql/packages/queries';
import { GET_CHANNELS } from '../../graphql/channels/queries';

const PEOPLE = require('../../assets/images/people.svg');

const timePickerStyle = { minHeight: 35 };
const dataPickerStyle = { minHeight: 35 };

const ExternalPanelReservation = ({ isFastReservation, reservationDataIndex, isLoadingPhysicalCategories, physicalCategories, outlets }) => {
  const classesAvailability = ecStyles();
  const classesModal = modalStyle();

  const reservationContext = useContext(ReservationContext);
  const languageContext = useContext(LanguageContext);
  const modalContext = useContext(ModalContext);

  const getPackagesRes = useCustomQuery(GET_PACKAGES);
  const getChannelsRes = useCustomQuery(GET_CHANNELS);

  const reservationsData = reservationContext && reservationContext.reservationsData;
  const reservationActiveIndex = reservationContext && reservationContext.isEditingReservationIndex;
  const handleChangeReservationData = reservationContext && reservationContext.handleChangeReservationData;
  const errorResMap = reservationContext && reservationContext.errorResMap;
  const errorDetector = errorResMap && errorResMap[reservationDataIndex].size;

  const textTranslate = (text) => {
    return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
  };

  const onIncrementHandler = (category) => {
    const { fbGuestList } = reservationsData[reservationDataIndex];
    const newFbGuestList = [
      ...fbGuestList,
      {
        guestPhysicalCategoryId: category.id
      }
    ];
    reservationContext.handleChangeReservationData('fbGuestList', newFbGuestList);
  };

  const onDecrementHandler = (category) => {
    const { fbGuestList } = reservationsData[reservationDataIndex];
    if (fbGuestList.length >= 0) {
      for (let i = 0; i < fbGuestList.length; i++) {
        if (category.id === fbGuestList[i].guestPhysicalCategoryId) {
          fbGuestList.splice(i, 1);
          break;
        }
      }
      reservationContext.handleChangeReservationData('fbGuestList', fbGuestList);
    }
  };

  const deleteReservation = () => {
    modalContext.openModal({
      class: 'primary',
      title: textTranslate('delete-reservation'),
      text: textTranslate('delete-res'),
      actionButtons: [
        <Button key={1} variant="outlined" color="primary" className={classesModal.invertedButton} onClick={modalContext.closeModal}>
          {textTranslate('cancel')}
        </Button>,
        <Button
          key={2}
          color="primary"
          variant="contained"
          className={classesModal.buttonStyle}
          onClick={() => {
            modalContext.closeModal();
            reservationContext.removeRes(reservationDataIndex);
          }}
        >
          {textTranslate('confirm')}
        </Button>
      ]
    });
  };

  return (
    <Box>
      <Grid container style={{ marginBottom: 30 }}>
        <Grid item xs style={{ flex: 10 }}>
          <Accordion
            className={errorDetector === 0 ? classesAvailability.reservationPanel : classesAvailability.reservationPanelError}
            expanded={reservationDataIndex === reservationActiveIndex}
            onChange={(e, isExpanding) => {
              if (isExpanding) {
                reservationContext.handleSetIsEditingReservationIndex(reservationDataIndex);
              }
            }}
          >
            <AccordionSummary
              expandIcon={!isFastReservation && <ExpandMoreIcon style={{ color: '#00B7FF', width: 50, height: 50 }} />}
              aria-controls="panel1a-content"
              id="res-panel"
              style={{ paddingLeft: 0, paddingRight: 20 }}
            >
              <Grid container>
                <Grid item xs className={classesAvailability.styleBorderRight} style={{ paddingRight: 5, paddingLeft: 5 }}>
                  <ImagesCategoryPanel
                    title={capitalizeFirstLetter(translate('data', languageContext.language))}
                    description={
                      reservationsData[reservationDataIndex].reservationDate
                        ? getTranslatedDate(reservationsData[reservationDataIndex].reservationDate, languageContext.language)
                        : null
                    }
                    img={<Calendar alt="calendar" className={classesAvailability.imgStyle} />}
                  />
                </Grid>
                <Grid item xs className={classesAvailability.styleBorderRight}>
                  <ImagesCategoryPanel
                    title={capitalizeFirstLetter(translate('time', languageContext.language))}
                    description={reservationsData[reservationDataIndex].selectedTime ? reservationsData[reservationDataIndex].selectedTime : null}
                    img={<Clock alt="time" className={classesAvailability.imgStyle} />}
                  />
                </Grid>
                {physicalCategories?.map((category, index) => (
                  <Grid item xs key={index}>
                    <ImagesCategoryPanel
                      title={category.description}
                      description={
                        reservationsData[reservationDataIndex].fbGuestList.length === 0
                          ? 0
                          : reservationsData[reservationDataIndex].fbGuestList.filter((fbGuest) => fbGuest.guestPhysicalCategoryId === category.id)
                              .length
                      }
                      img={category.icon && <MaterialIcon iconName={category.icon} />}
                    />
                  </Grid>
                ))}
                <Grid item xs className={!isFastReservation ? classesAvailability.styleBorderRight : null}>
                  <ImagesCategoryPanel
                    title={capitalizeFirstLetter(translate('total', languageContext.language))}
                    description={reservationsData[reservationDataIndex].fbGuestList.length}
                    img={<img src={PEOPLE} alt="Vuoto" />}
                  />
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails className={errorDetector === 0 ? classesAvailability.panelDetail : classesAvailability.panelDetailError}>
              <Grid container>
                <Grid item xs style={{ marginRight: 39, marginBottom: 5 }}>
                  <Typography className={classesAvailability.placeholderTypography}>
                    {capitalizeFirstLetter(translate('outlet', languageContext.language))}
                  </Typography>
                  <Dropdown
                    dropdownStyle={{ width: '100%' }}
                    items={outlets.map((outlet) => ({ label: outlet.name, value: outlet.uuid }))}
                    value={reservationContext.reservationsData?.[reservationDataIndex]?.fbOutletUUID}
                    error={errorResMap ? errorResMap[reservationDataIndex].has('fbOutletUUID') : null}
                    errorLabel={errorResMap ? errorResMap[reservationDataIndex].get('fbOutletUUID') : null}
                    onChange={({ target: { value } }) => {
                      reservationContext.handleChangeReservationDataFields({
                        fbOutletUUID: value,
                        fbServiceUUID: null,
                        fbGuestList: [],
                        selectedTime: null,
                        reservationTime: null,
                        reservationDataIndex
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs style={{ marginRight: 39 }}>
                  <Typography className={classesAvailability.placeholderTypography}>
                    {capitalizeFirstLetter(translate('reservation-data', languageContext.language))}
                  </Typography>
                  <DataPicker
                    fullWidth
                    disabled={isFastReservation}
                    onChange={(data) => handleChangeReservationData('reservationDate', data)}
                    error={errorResMap[reservationDataIndex].has('reservationDate')}
                    errorLabel={errorResMap[reservationDataIndex].get('reservationDate')}
                    value={reservationsData[reservationDataIndex] && reservationsData[reservationDataIndex].reservationDate}
                    dataPickerStyle={dataPickerStyle}
                  />
                </Grid>
                <Grid item xs style={{ marginRight: 39 }}>
                  <Typography className={classesAvailability.placeholderTypography}>
                    {capitalizeFirstLetter(translate('reservation-time', languageContext.language))}
                  </Typography>
                  <TimePicker
                    error={errorResMap[reservationDataIndex].has('selectedTime')}
                    errorLabel={errorResMap[reservationDataIndex].get('selectedTime')}
                    onChange={(time) => {
                      reservationContext.handleChangeReservationDataFields({
                        selectedTime: time ? moment(time).format('HH:mm') : null,
                        reservationTime: null
                      });
                    }}
                    value={
                      (reservationsData[reservationDataIndex] && reservationsData[reservationDataIndex].selectedTime) ||
                      (reservationsData[reservationDataIndex] && reservationsData[reservationDataIndex].reservationTime)
                    }
                    timePickerStyle={timePickerStyle}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs style={{ marginRight: 39 }}>
                  <Typography className={classesAvailability.placeholderTypography}>
                    {capitalizeFirstLetter(translate('package', languageContext.language))}
                  </Typography>
                  <Dropdown
                    dropdownStyle={{ width: '100%' }}
                    items={getPackagesRes?.data?.getPackages?.data?.map((pckg) => {
                      return {
                        label: pckg?.name,
                        value: pckg?.uuid
                      };
                    })}
                    value={reservationsData?.[reservationDataIndex]?.packageUUID}
                    onChange={(e) => handleChangeReservationData('packageUUID', e.target.value)}
                  />
                </Grid>
                <Grid item xs style={{ marginRight: 39 }}>
                  <Typography className={classesAvailability.placeholderTypography}>
                    {capitalizeFirstLetter(translate('channel', languageContext.language))}
                  </Typography>
                  <Dropdown
                    dropdownStyle={{ width: '100%' }}
                    items={getChannelsRes?.data?.getChannels?.data?.map((channel) => {
                      return {
                        label: channel?.name?.replace(/_/g, ' '),
                        value: channel?.uuid
                      };
                    })}
                    value={reservationsData?.[reservationDataIndex]?.channelUUID}
                    onChange={(e) => {
                      handleChangeReservationData('channelUUID', e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: 20, paddingLeft: 10 }}>
                <Grid item xs style={{ marginLeft: 0, marginRight: 0 }}>
                  <BoxImgCategories
                    style={{ marginLeft: 0, marginRight: 0 }}
                    /** count={reservationData.fbGuestList.length} */
                    title={capitalizeFirstLetter(translate('t-numb', languageContext.language))}
                    img={<Table alt="Table" className={classesAvailability.imgTable} />}
                    count={
                      reservationsData && reservationsData[reservationDataIndex] && reservationsData[reservationDataIndex].tableNumber
                        ? reservationsData[reservationDataIndex].tableNumber
                        : ''
                    }
                  />
                </Grid>
                {isLoadingPhysicalCategories && (
                  <Grid item xs>
                    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', height: '100%' }}>
                      <CircularProgress />
                    </Box>
                  </Grid>
                )}
                {!isLoadingPhysicalCategories &&
                  physicalCategories?.map((category, index) => (
                    <Grid item xs key={index}>
                      <BoxImgCategories
                        count={
                          reservationsData[reservationDataIndex].fbGuestList.length === 0
                            ? 0
                            : reservationsData[reservationDataIndex].fbGuestList.filter((fbGuest) => fbGuest.guestPhysicalCategoryId === category.id)
                                .length
                        }
                        title={category.description}
                        img={category.icon && <MaterialIcon iconName={category.icon} />}
                      >
                        <InputStepper
                          category={category}
                          onIncrement={() => {
                            onIncrementHandler(category);
                          }}
                          onDecrement={() => {
                            onDecrementHandler(category);
                          }}
                        />
                      </BoxImgCategories>
                    </Grid>
                  ))}
                <Grid item xs>
                  <BoxImgCategories
                    count={reservationsData[reservationDataIndex].fbGuestList.length}
                    title={capitalizeFirstLetter(translate('tot-people', languageContext.language))}
                    img={<img src={PEOPLE} alt="Tot people" />}
                  />
                  <span style={{ fontSize: 10, color: 'red', marginTop: 5 }}>{errorResMap[reservationDataIndex].get('fbGuestList')}</span>
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: 20 }}>
                <InputTextLabel
                  title={capitalizeFirstLetter(translate('notes', languageContext.language))}
                  placeholder={capitalizeFirstLetter(translate('notes', languageContext.language))}
                  value={
                    reservationsData && reservationsData[reservationDataIndex] && reservationsData[reservationDataIndex].notes
                      ? reservationsData[reservationDataIndex].notes
                      : ''
                  }
                  onChange={(e) => handleChangeReservationData('notes', e.target.value)}
                  className={classesAvailability.noteTextField}
                  classNameBox={classesAvailability.txtNote}
                />
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {!isFastReservation && (
          <Grid item xs style={{ flex: 1, alignSelf: 'center', textAlign: 'center' }}>
            <IconButton
              aria-label="delete"
              className={classesAvailability.delButton}
              onClick={reservationsData.length > 1 ? deleteReservation : null}
            >
              <DeleteIcon className={classesAvailability.delIcon} />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ExternalPanelReservation;
