import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, InputAdornment, CircularProgress, ClickAwayListener } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { CustomModal, primaryColor, mainTextColor } from '../../../common-fe/src';
import InputWithLabel from '../../shared/InputWithLabel';
import { TranslateTextComponent, capitalizeFirstLetter, translate } from '../../../translator';
import Buttons from '../../shared/Buttons';
import { LanguageContext } from '../../../contexts/LanguageContext';
import useCustomQuery from '../../../hooks/useCustomQuery';
import { GET_PROFILES } from '../../../graphql/moh/bookings/queries';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;

  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const RowContainer = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 20px;
  }
`;

const ClientSearchContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  flex: 1;
`;

const ResultsContainer = styled.ul`
  position: absolute;
  top: 65px;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 170px;
  background-color: white;
  border: 1px solid #d9d9d9;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: auto;
  z-index: 1;

  li {
    cursor: pointer;
    padding: 10px 5px;

    transition: background-color 0.3s;

    &:hover {
      background-color: ${() => primaryColor};
    }
  }
`;

const NewGuestUserModal = ({ loading, closeModal, addNewUser }) => {
  const { language } = useContext(LanguageContext);
  const [profilesFilters, setProfilesFilters] = useState({ keyword: '', pageSize: 10, page: 1 });
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(capitalizeFirstLetter(translate('required', language))),
    lastName: Yup.string().required(capitalizeFirstLetter(translate('required', language))),
    email: Yup.string()
      .email(capitalizeFirstLetter(translate('invalid-email', language)))
      .required(capitalizeFirstLetter(translate('required', language)))
  });

  const { values, errors, touched, isSubmitting, setFieldValue, handleBlur, handleChange, handleReset, handleSubmit, resetForm } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      profileUUID: ''
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      addNewUser(values);
      setSubmitting(false);
    }
  });

  // Queries
  const getProfiles = useCustomQuery(GET_PROFILES, { variables: profilesFilters, skip: searchValue.length < 3 });

  const onProfileSearchChangeHandler = debounce(({ target: { value } }) => {
    if (value?.trim()?.length > 0) {
      setProfilesFilters((prev) => ({ ...prev, keyword: value }));
    } else {
      setProfilesFilters((prev) => ({ ...prev, keyword: '' }));
    }
  }, 1000);

  return (
    <CustomModal
      styledHeader
      height="auto"
      width={600}
      stylePaper
      header={<TranslateTextComponent uppercase>new-user</TranslateTextComponent>}
      styleBody={{ padding: '10px 60px' }}
      showModal
      onClose={() => {
        closeModal();
        setSelectedProfile(null);
        setSearchValue('');
        resetForm();
      }}
    >
      <Container>
        <RowContainer>
          <ClientSearchContainer>
            <InputWithLabel label="look-for-profile">
              <TextField
                name="profileUUID"
                fullWidth
                variant="outlined"
                color="primary"
                value={`${selectedProfile?.firstName ?? ''} ${selectedProfile?.lastName ?? ''}`.trim() || searchValue}
                InputProps={{
                  // eslint-disable-next-line no-nested-ternary
                  endAdornment: getProfiles.loading ? (
                    <InputAdornment position="end">
                      <CircularProgress color="primary" style={{ marginRight: 10, width: 25, height: 25 }} />
                    </InputAdornment>
                  ) : values.profileUUID ? (
                    <InputAdornment position="end">
                      <Cancel
                        color="primary"
                        style={{ cursor: 'pointer', marginRight: 5 }}
                        onClick={() => {
                          setSelectedProfile(null);
                          setSearchValue('');
                          setFieldValue('firstName', '');
                          setFieldValue('lastName', '');
                          setFieldValue('profileUUID', '');
                          setFieldValue('guestRoom', '');
                        }}
                      />
                    </InputAdornment>
                  ) : null
                }}
                onClick={() => {
                  if (!values.profileUUID) {
                    setShowResults(true);
                  }
                }}
                onBlur={handleBlur}
                onChange={(e) => {
                  e.persist();
                  onProfileSearchChangeHandler(e);
                  if (values.profileUUID) {
                    setSelectedProfile(null);
                    setFieldValue('profileUUID', '');
                  }
                  setShowResults(true);
                  setSearchValue(e.target.value);
                }}
              />
            </InputWithLabel>
            {searchValue.length >= 3 && !values.profileUUID && getProfiles.data?.getProfiles?.data?.length > 0 && showResults && (
              <ClickAwayListener onClickAway={() => setShowResults(false)}>
                <ResultsContainer>
                  {getProfiles.data?.getProfiles?.data?.map((p) => (
                    <li
                      key={p.uuid}
                      onClick={() => {
                        setSelectedProfile(p);
                        setFieldValue('profileUUID', p.uuid);
                        setFieldValue('firstName', p.firstName);
                        setFieldValue('lastName', p.lastName);
                        setShowResults(false);
                      }}
                      role="presentation"
                    >
                      {p.firstName} {p.lastName}
                      {moment(p.dateOfBirth).isValid()
                        ? ` - ${capitalizeFirstLetter(translate('born-on', language))} ${moment(p.dateOfBirth).format('DD/MM/YYYY')}`
                        : null}
                      {p.placeOfBirth ? ` - ${capitalizeFirstLetter(translate('born-in', language))} ${p.placeOfBirth}` : null}
                    </li>
                  ))}
                </ResultsContainer>
              </ClickAwayListener>
            )}
          </ClientSearchContainer>
        </RowContainer>
        {selectedProfile && (
          <RowContainer>
            <div style={{ fontWeight: 'bold', color: mainTextColor }}>
              <TranslateTextComponent capitalize>selected-profile</TranslateTextComponent>:{' '}
              {`${selectedProfile.firstName} ${selectedProfile.lastName}`}
            </div>
          </RowContainer>
        )}
        <RowContainer>
          <InputWithLabel label="first-name" error={touched.firstName && errors.firstName}>
            <TextField
              name="firstName"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.firstName}
              error={touched.firstName && !!errors.firstName}
              disabled={selectedProfile}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
        </RowContainer>
        <RowContainer>
          <InputWithLabel label="last-name" error={touched.lastName && errors.lastName}>
            <TextField
              name="lastName"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.lastName}
              error={touched.lastName && !!errors.lastName}
              disabled={selectedProfile}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
        </RowContainer>
        <InputWithLabel label="email" error={touched.email && errors.email}>
          <TextField
            name="email"
            fullWidth
            variant="outlined"
            color="primary"
            value={values.email}
            error={touched.email && !!errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
        <Buttons
          buttons={[
            {
              buttonType: 'reset',
              type: 'cancel',
              disabled: loading,
              style: {
                textTransform: 'uppercase',
                minWidth: 125,
                minHeight: 40,
                fontSize: 18,
                fontWeight: 500
              },
              onClick: () => {
                setSelectedProfile(null);
                setSearchValue('');
                handleReset();
              }
            },
            {
              buttonType: 'submit',
              type: 'confirm',
              label: 'save',
              disabled: loading || isSubmitting,
              isLoading: loading,
              style: {
                textTransform: 'uppercase',
                minWidth: 125,
                minHeight: 40,
                fontSize: 18,
                fontWeight: 500
              },
              onClick: handleSubmit
            }
          ]}
        />
      </Container>
    </CustomModal>
  );
};

export default NewGuestUserModal;
