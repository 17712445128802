import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { CustomModal, MaterialIcon, primaryColor, CustomButton } from '../../../common-fe/src';
import styles from '../../../styles/cash/modals/selectPersonsNumberStyle';
import { TranslateTextComponent } from '../../../translator';

const SelectPersonNumberModal = ({ showSelectPersonsNumberModal, closeModal, onSplitEqually }) => {
  const classes = styles();
  const [quantity, setQuantity] = useState(1);

  return (
    <CustomModal
      width={450}
      height={286}
      styleBody={{ display: 'grid', gridTemplateAreas: '"icon" "label" "stepper" "buttons"', gridTemplateColumns: '1fr' }}
      showModal={showSelectPersonsNumberModal}
      onClose={closeModal}
    >
      <Box className={classes.area} style={{ gridArea: 'icon' }}>
        <MaterialIcon iconName="Help" style={{ color: primaryColor, fontSize: '4rem' }} />
      </Box>
      <Box className={classes.area} style={{ gridArea: 'label', fontSize: 18 }}>
        <TranslateTextComponent capitalize>select-persons-number</TranslateTextComponent>
      </Box>
      <Box className={[classes.area, classes.stepper].join(' ')} style={{ gridArea: 'stepper' }}>
        <MaterialIcon iconName="People" style={{ fontSize: '2.5rem' }} />
        <MaterialIcon
          iconName="RemoveCircle"
          style={{ cursor: 'pointer', fontSize: '2.5rem' }}
          onClick={() =>
            setQuantity((prevValue) => {
              if (prevValue - 1 <= 0) return 0;
              else return prevValue - 1;
            })
          }
        />
        <span>{quantity}</span>
        <MaterialIcon
          iconName="AddCircle"
          style={{ cursor: 'pointer', fontSize: '2.5rem' }}
          onClick={() => setQuantity((prevValue) => prevValue + 1)}
        />
      </Box>
      <Box className={[classes.area, classes.buttons].join(' ')} style={{ gridArea: 'buttons' }}>
        <CustomButton
          style={{ width: 'auto', backgroundColor: 'white', color: primaryColor, border: `1px solid ${primaryColor}` }}
          onClick={closeModal}
        >
          <TranslateTextComponent>cancel</TranslateTextComponent>
        </CustomButton>
        <CustomButton style={{ width: 'auto' }} onClick={() => onSplitEqually(quantity)}>
          <TranslateTextComponent>confirm</TranslateTextComponent>
        </CustomButton>
      </Box>
    </CustomModal>
  );
};

export default SelectPersonNumberModal;
