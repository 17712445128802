import React from 'react';
import { Grid, Button } from '@material-ui/core/';
import styles from '../../styles/settings/settingsStyle';
import { hoverRedButton, greenButton, hoverGreenButton, redButton } from '../../styles/theme';
import { TranslateTextComponent } from '../../translator';

const Footer = ({ onClickDelete, onClickSave, footerStyle }) => {
  return (
    <Grid container direction="row" justify="space-between" style={{ ...footerStyle, paddingTop: 20 }}>
      <Button className={styles({ color: redButton, hover: hoverRedButton }).button} onClick={onClickDelete}>
        <TranslateTextComponent>delete</TranslateTextComponent>
      </Button>
      <Button className={styles({ color: greenButton, hover: hoverGreenButton }).button} onClick={onClickSave}>
        <TranslateTextComponent>save</TranslateTextComponent>
      </Button>
    </Grid>
  );
};

export default Footer;
