import React from 'react';
import { Box, Tabs as MUITabs, Button } from '@material-ui/core';
import TabItem from './TabItem/TabItem';
import style from '../../../styles/viewTables/tabs/style';
import { TranslateTextComponent } from '../../../translator';

const Tabs = ({ tabsRooms, selectedTab, showIsEditable = true, wrapperStyles, containerStyles, onRoomTableEditButtonClick, onTabClickHandler }) => {
  const classes = style();

  return (
    <Box className={classes.wrapper} style={wrapperStyles}>
      <MUITabs className={classes.container} style={containerStyles} value={0} variant="scrollable" scrollButtons="auto">
        {tabsRooms.map((room) => (
          <TabItem key={room.uuid} id={room.uuid} active={selectedTab} label={room.name} isActive={room.isActive} changeTab={onTabClickHandler} />
        ))}
        {/* <TabItem label="room service" id="roomService" active={selectedTab} changeTab={onTabClickHandler} /> */}
      </MUITabs>
      {showIsEditable && (
        <Button className={classes.button} variant="contained" color="primary" onClick={onRoomTableEditButtonClick}>
          <TranslateTextComponent uppercase>room-editor</TranslateTextComponent>
        </Button>
      )}
    </Box>
  );
};

export default Tabs;
