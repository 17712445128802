import React from 'react';
import { Box } from '@material-ui/core';
import { Responsive, WidthProvider } from 'react-grid-layout';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
import Table from './Table/Table';

const ResponsiveGridLayout = WidthProvider(Responsive);

const RoomEditor = ({ roomTables, layout, marginTop, onLayoutChangeHandler, onTableClick, onTableShift, updateTable }) => {
  return (
    <ResponsiveGridLayout
      style={{ marginTop }}
      measureBeforeMount
      isResizable={false}
      margin={[50, 50]}
      rowHeight={40}
      onLayoutChange={(layout, layouts) => {
        onLayoutChangeHandler(layout, layouts);
        onTableShift(layout);
      }}
      layouts={layout}
      className="layout"
      compactType={null}
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
    >
      {roomTables.map((table) => {
        return (
          <Box key={table.id} data-grid={{ x: table.positionX, y: table.positionY, w: 1, h: 1 }} onClick={() => onTableClick({ ...table })}>
            <Table table={table} updateTable={updateTable} />
          </Box>
        );
      })}
    </ResponsiveGridLayout>
  );
};

export default RoomEditor;
