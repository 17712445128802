import { makeStyles } from '@material-ui/core/styles';
import { primaryColor, borderTheme } from '../theme';

const styles = {
  formControl: () => ({
    border: borderTheme,
    borderRadius: 4,
    minWidth: 130
  }),
  selectValue: () => ({
    border: borderTheme,
    borderRadius: 4,
    minWidth: 130,
    height: 30,
    '& div': {
      padding: '0 0 0 5px'
    },
    '& > svg': {
      color: 'white',
      padding: 0,
      background: primaryColor,
      height: 'calc(100% + 2px)',
      top: '-1px',
      right: '-1px',
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4
    }
  })
};

export default makeStyles(styles);
