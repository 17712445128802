import { gql } from 'apollo-boost';
import { PROFILE_FRAGMENT } from '../../fragments';

export const GET_PROFILES = gql`
  query getProfiles($orderBy: String, $orderType: String, $page: Int, $pageSize: Int, $keyword: String) {
    getProfiles(orderBy: $orderBy, orderType: $orderType, page: $page, pageSize: $pageSize, keyword: $keyword) {
      totalCount
      data {
        ...profileFragment
      }
    }
  }
  ${PROFILE_FRAGMENT}
`;
