/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { TranslateTextComponent } from '../../../../translator';
import TableButton from '../../../shared/TableButton';
import { ReactComponent as BinIcon } from '../../../../assets/images/orders/bin.svg';
import { Dropdown, primaryColor } from '../../../../common-fe/src';

const textTranslate = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

const servicesColumns = ({ openModal, editSmartServiceStatus, deleteSmartService }) => {
  return [
    {
      id: 'name',
      // disablePadding: false,
      label: textTranslate('name'),
      isSortable: false,
      align: 'left',
      render: (item) => item.name
    },
    {
      id: 'quantity',
      disablePadding: false,
      label: textTranslate('quantity'),
      isSortable: false,
      align: 'left',
      render: (item) => item.dailyQuantity
    },
    {
      id: 'price',
      disablePadding: false,
      label: textTranslate('price'),
      isSortable: false,
      align: 'left',
      render: (item) => item.price
    },
    {
      id: 'description',
      disablePadding: false,
      label: textTranslate('description'),
      isSortable: false,
      align: 'left',
      render: (item) => item.description
    },
    {
      id: 'status',
      disablePadding: false,
      label: textTranslate('status'),
      isSortable: false,
      align: 'left',
      render: (item) => (
        <Dropdown
          dropdownStyle={{ backgroundColor: 'white', fontSize: 11, textTransform: 'uppercase' }}
          value={item.isActive ? 'active' : 'inactive'}
          items={[
            { value: 'active', label: textTranslate('active') },
            { value: 'inactive', label: textTranslate('inactive') }
          ]}
          onChange={({ target: { value } }) => editSmartServiceStatus({ service: item, isActive: value === 'active' })}
        />
      )
    },
    {
      id: 'edit',
      disablePadding: false,
      label: textTranslate('edit'),
      isSortable: false,
      align: 'left',
      render: (item) => <TableButton onClick={() => openModal(item)}>open</TableButton>
    },
    {
      id: 'delete',
      disablePadding: false,
      label: textTranslate('delete'),
      isSortable: false,
      align: 'left',
      render: (item) => (
        <BinIcon
          fill={primaryColor}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            deleteSmartService(item.uuid);
          }}
        />
      )
    }
  ];
};

export default servicesColumns;
