import { makeStyles } from '@material-ui/core/styles';
import { primaryColor, mainTextColor, borderTheme } from '../theme';

const styles = {
  iconPhysicalCategory: (props) => ({
    width: props.size === 'small' ? 10 : 16,
    height: props.size === 'small' ? 10 : 14
  }),
  typographyPhysicalCategory: () => ({
    fontSize: 9
  }),
  gridPhysicalCategory: () => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  }),
  boxHeaderReservation: () => ({
    display: 'flex',
    alignItems: 'center'
  }),
  filterIcon: () => ({
    width: 21,
    height: 21,
    marginRight: 6
  }),
  formControl: () => ({
    border: borderTheme,
    borderRadius: 4,
    minWidth: 120,
    marginLeft: 10,
    height: 40
  }),
  selectService: () => ({
    padding: '0 10px',
    '& svg': {
      color: 'white',
      background: primaryColor,
      padding: 0,
      height: 'calc(100% + 2px)',
      top: '-1px',
      right: '-1px',
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4
    },
    height: 40
  }),

  typographyNPaxBox: () => ({
    border: `2px solid${primaryColor}`,
    borderRadius: 4,
    fontSize: 10,
    marginLeft: 5,
    minWidth: 35,
    paddingLeft: 2
  }),
  gridKidsEnfantsNPax: () => ({
    boxShadow: ' 2px 3px 6px #00000029',
    position: 'absolute',
    padding: '9px 8px',
    background: 'white'
  }),
  gridKidsEnfantsDetails: () => ({
    display: 'flex'
  }),
  ageTypographyNPax: () => ({
    fontSize: 11,
    color: mainTextColor,
    fontWeight: 'bold'
  }),
  gridNPaxIcon: () => ({
    paddingBottom: 5
  }),
  footerButton: () => ({
    width: 325,
    height: 50,
    fontSize: 20,
    color: 'white',
    borderRadius: 9
  }),
  customButtonStatusChanged: (props) => ({
    backgroundColor: props.color
  }),
  disabledButton: (props) => ({
    '&.Mui-disabled': {
      backgroundColor: props.color,
      color: 'white'
    }
  })
};

export default makeStyles(styles);
