import { makeStyles } from '@material-ui/core/styles';

const styles = {
  container: {},
  doubleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      maxWidth: 108,
      fontSize: 18
    }
  },
  button: {
    color: 'white',
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1,
    borderRadius: 9,
    margin: '5px 0',
    width: '100%',
    minHeight: 50
  }
};

export default makeStyles(styles);
