import React, { useState, useContext, createContext } from 'react';
import { AuthContext } from './AuthContext';

import useCustomQuery from '../hooks/useCustomQuery';
import { GET_FB_OUTLET } from '../graphql/outlets/queries';

const appOutletStorage = localStorage.getItem('appOutlet') || null;

export const AppOutletContext = createContext();

export const AppOutletProvider = ({ children }) => {
  const [appOutlet, setAppOutlet] = useState(appOutletStorage);
  const { appOutletUUID } = useContext(AuthContext);

  const appOutletData = useCustomQuery(GET_FB_OUTLET, {
    skip: !appOutletUUID,
    requireAuth: true,
    variables: {
      fbOutletUUID: appOutletUUID
    },
    onCompleted: (data) => {
      const outlet = data?.getFbOutlet;
      setAppOutlet(outlet);
      localStorage.setItem('appOutlet', JSON.stringify(outlet));
    }
  });

  const reloadAppOutlet = () => {
    appOutletData
      .refetch()
      .then((res) => {
        const outlet = res?.data?.getFbOutlet;
        setAppOutlet(outlet);
        localStorage.setItem('appOutlet', JSON.stringify(outlet));
      })
      .catch((err) => console.log(err));
  };

  return (
    <AppOutletContext.Provider
      value={{
        appOutlet,
        reloadAppOutlet
      }}
    >
      {children}
    </AppOutletContext.Provider>
  );
};

export const AppOutletConsumer = AppOutletContext.Consumer;
