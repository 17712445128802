import { makeStyles } from '@material-ui/core/styles';

const styles = () => ({
  dashboardButton: () => ({
    fontFamily: 'Roboto',
    width: '100%',
    height: '100px',
    borderRadius: 4,
    color: 'white',
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#009fdd'
    }
  }),
  buttonDescription: () => ({
    textAlign: 'start',
    display: 'flex',
    flexDirection: 'row',
    textTransform: 'initial',
    lineHeight: 'normal',
    fontSize: 18,
    fontWeight: 'lighter'
  }),
  buttonTitle: () => ({
    display: 'flex',
    flexDirection: 'row',
    textTransform: 'initial',
    lineHeight: 'normal',
    marginBottom: 10
  })
});

export default makeStyles(styles);
