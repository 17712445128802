import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from '../theme';

const styles = {
  keyboardDatePicker: (props) => ({
    '& .MuiOutlinedInput-adornedEnd': { overflow: 'hidden' },
    borderRadius: 4,
    justifyContent: 'center',
    overflow: 'hidden',
    '& svg': {
      height: props.svgHeight,
      color: 'white',
      backgroundColor: primaryColor,
      borderRadius: '0 2px 2px 0'
    },
    '& button': {
      width: 40,
      padding: 0,
      right: '-0.5px',
      borderRadius: '0 2px 2px 0',
      backgroundColor: primaryColor,
      '&:hover': { backgroundColor: primaryColor },
      '&.MuiIconButton-root.Mui-disabled': { backgroundColor: primaryColor }
    },
    '& input': ({ inputTextAlign }) => ({
      textAlign: inputTextAlign || 'left'
    })
  })
};

export default makeStyles(styles);
