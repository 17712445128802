import React, { useContext } from 'react';
import { Box, Tab, Tabs, Typography } from '@material-ui/core/';
import orderBy from 'lodash/orderBy';
import { ReactComponent as AddAll } from '../../assets/images/order/play_course.svg';
import { borderTheme, CommonTable, CustomButton, MaterialIcon, primaryColor, secondaryColor } from '../../common-fe/src';
import courseSummaryColumns from '../../constants/cash/courseSummaryColumns';
import { groupedByMenuItem } from '../../constants/order/order';
import { LanguageContext } from '../../contexts/LanguageContext';
import styles from '../../styles/cash/cashStyle';
import { translate, TranslateTextComponent } from '../../translator/index';
import ChairsDetail from './ChairsDetail';
import TotalKeyboard from './TotalKeyboard';

const CourseSummary = ({
  isFastCash,
  activeCourseTabUUID,
  orderSummary,
  fastOrdersSummary,
  onCourseTabChange,
  onMoreTabButtonClick,
  onCloseFastOrderTabClick,
  showKeyboard,
  total,
  onShowKeyboardClick,
  closeKeyboard,
  addItemsToBill,
  addAllItemsToBill,
  onChairClick,
  deleteItem,
  deleteAllItems
}) => {
  const classes = styles({ isFastCash });
  const { language } = useContext(LanguageContext);
  const columns = courseSummaryColumns({ isFastCash, deleteItem, deleteAllItems, addItemsToBill });
  const groupedCourses = orderBy(groupedByMenuItem(orderSummary?.items ?? [])).sort((a, b) => {
    if (a[0].fbCourse.type > b[0].fbCourse.type) {
      return -1;
    }
    return 1;
  });

  const getTabColor = (orderBill) => {
    if (orderBill.status.toLowerCase() === 'complete' && orderBill.uuid === activeCourseTabUUID) {
      return 'rgb(127, 179, 127)';
    }

    if (orderBill.status.toLowerCase() === 'complete' && orderBill.uuid !== activeCourseTabUUID) {
      return 'rgb(187, 217, 187)';
    }

    if (orderBill.uuid === activeCourseTabUUID) {
      return primaryColor;
    }
    return 'rgb(163, 224, 255)';
  };

  return (
    <Box className={classes.courseSummaryContainer}>
      <Box className={classes.tabsContainer} style={{ borderBottom: `1px solid ${primaryColor}` }}>
        {!isFastCash && (
          <Tabs variant="scrollable" scrollButtons="auto" value="summary">
            <Tab className={classes.tab} label={translate('summary', language)} value="summary" style={{ backgroundColor: primaryColor }} />
          </Tabs>
        )}
        {isFastCash && activeCourseTabUUID && fastOrdersSummary && fastOrdersSummary.length > 0 && (
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={fastOrdersSummary.find((fastOrder) => fastOrder.uuid === activeCourseTabUUID) ? activeCourseTabUUID : fastOrdersSummary[0].uuid}
          >
            {fastOrdersSummary.map((orderBill, index) => (
              <Tab
                key={orderBill.uuid}
                className={classes.tab}
                label={
                  <Box className={classes.tabIcon}>
                    <span>{`${translate('order', language).toUpperCase()} ${index + 1}`}</span>
                    <MaterialIcon iconName="Cancel" onClick={() => onCloseFastOrderTabClick(orderBill.uuid)} />
                  </Box>
                }
                style={{ backgroundColor: getTabColor(orderBill) }}
                value={orderBill.uuid}
                onClick={() => onCourseTabChange(orderBill.uuid)}
              />
            ))}
            <Tab
              className={classes.tab}
              style={{ backgroundColor: '#EDFAFF', color: '#91C9E8' }}
              icon={<MaterialIcon iconName="Add" />}
              onClick={onMoreTabButtonClick}
            />
          </Tabs>
        )}
        <CustomButton style={{ backgroundColor: '#9DCA9D', height: 35, width: 'auto', padding: '2px 10px' }} onClick={addAllItemsToBill}>
          <Typography style={{ color: 'white', lineHeight: 1 }}>
            <TranslateTextComponent>add-all</TranslateTextComponent>
          </Typography>
          <AddAll style={{ marginLeft: 5 }} />
        </CustomButton>
      </Box>
      <Box style={{ flex: 1, boxShadow: '0px 3px 6px #00000029', overflowY: 'auto' }}>
        {(!isFastCash && (
          <CommonTable
            size="small"
            headerCellStyle={{ backgroundColor: secondaryColor, borderBottom: borderTheme }}
            items={groupedCourses}
            columns={columns}
            renderDetailsComponent={[
              {
                columnLabel: <TranslateTextComponent capitalize>chair</TranslateTextComponent>,
                buttonLabel: <TranslateTextComponent>add</TranslateTextComponent>,
                position: 3,
                renderFunction: (fbChair) => <ChairsDetail selectedItem={fbChair} onChairClick={onChairClick} onDeleteClick={deleteItem} />
              }
            ]}
            tableContainerStyle={{ flex: 1, overflowY: 'auto' }}
          />
        )) ||
          (isFastCash &&
            fastOrdersSummary &&
            fastOrdersSummary.map((fastOrder) => {
              if (fastOrder.uuid === activeCourseTabUUID) {
                return (
                  <CommonTable
                    key={fastOrder.uuid}
                    size="small"
                    fastOrder
                    headerCellStyle={{ backgroundColor: secondaryColor, borderBottom: borderTheme }}
                    items={groupedCourses}
                    columns={columns}
                    tableContainerStyle={{ flex: 1, overflowY: 'auto' }}
                  />
                );
              }
            }))}
      </Box>
      <TotalKeyboard showKeyboard={showKeyboard} total={total} onShowKeyboardClick={onShowKeyboardClick} closeKeyboard={closeKeyboard} />
    </Box>
  );
};

export default CourseSummary;
