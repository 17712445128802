import gql from 'graphql-tag';
import { FB_CHAIR_FRAGMENT, GUEST_FRAGMENT } from '../fragments';

export const FbBillFragment = gql`
  fragment FbBillFragment on FbBillsDto {
    id
    uuid
    documentNumber
    datetime
    paymentDatetime
    paymentDatetime
    discount
    discountTime
    discountPercentage
    discountPercentageTime
    discountNotes
    discountPercentageNotes
    discountValue
    subtotal
    total
    status
    fiscalPrintType
    creditCardType
    isPaid
  }
`;

export const FbBillRowFragment = gql`
  fragment FbBillRowFragment on FbBillsRowsDto {
    id
    uuid
    name
    quantity
    price
    total
    fbCoursesMenuItem {
      id
      uuid
      name
      quantity
      price
      with
      without
      fbCourse {
        id
        type
      }
    }
  }
`;

export const GET_ORDER_SUMMARY = gql`
  query getOrderSummary($fbOrderUUID: String!) {
    getOrderSummary(fbOrderUUID: $fbOrderUUID) {
      total
      items {
        id
        uuid
        name
        quantity
        price
        fbMenuItemId
        fbChairId
        centerpiece
        halfPortion
        sharedBy
        with
        without
        notes
        fbCourse {
          id
          fbCourseStatus
          type
        }
        fbMenuItem {
          id
          uuid
          fbMenuCategory {
            id
            uuid
            name
          }
        }
        fbChair {
          ...fbChairFragment
        }
      }
    }
  }
  ${FB_CHAIR_FRAGMENT}
`;

export const GET_FAST_ORDERS = gql`
  query getFastFbOrders($fbOutletUUID: String!, $status: FbOrdersStatuses) {
    getFastFbOrders(fbOutletUUID: $fbOutletUUID, status: $status) {
      data {
        id
        uuid
        status
        fbCourses {
          id
          uuid
        }
      }
    }
  }
`;

export const GET_FB_BILL = gql`
  query getFbBill($fbBillUUID: String!) {
    getFbBill(fbBillUUID: $fbBillUUID) {
      ...FbBillFragment
      fbPaymentMethod {
        id
        uuid
        code
      }
      fbBillsRows {
        ...FbBillRowFragment
      }
      fbOrder {
        id
        fbReservationTable {
          id
          fbTable {
            id
            number
          }
          fbReservation {
            id
            reservationTime
            nPax
            bookedBy {
              id
              firstname
              lastname
            }
          }
        }
      }
    }
  }
  ${FbBillFragment}
  ${FbBillRowFragment}
`;

export const GET_ORDER_BILLS = gql`
  query getFbOrderBills($fbOrderUUID: String!) {
    getFbOrderBills(fbOrderUUID: $fbOrderUUID) {
      data {
        ...FbBillFragment
        fbPaymentMethod {
          id
          uuid
          code
        }
        fbBillsRows {
          ...FbBillRowFragment
        }
        payMaster {
          id
          uuid
          name
          type
        }
        fbBillDocuments {
          id
          uuid
          type
          name
          path
          url
          description
        }
      }
    }
  }
  ${FbBillFragment}
  ${FbBillRowFragment}
`;

export const GET_PAY_MASTERS = gql`
  query getPayMasters($date: String, $month: String, $year: String, $keyword: String, $page: Int, $pageSize: Int) {
    getPayMasters(date: $date, month: $month, year: $year, keyword: $keyword, page: $page, pageSize: $pageSize) {
      totalCount
      data {
        id
        uuid
        name
        type
        expirationDate
        createdAt
        guestList {
          ...guestFragment
        }
        fbBills {
          ...FbBillFragment
          paidBy {
            ...guestFragment
          }
          fbBillDocuments {
            id
            uuid
            type
            images {
              uuid
              id
              path
            }
          }
        }
        guestList {
          id
          uuid
          firstname
          lastname
          company
          vatNumber
          fiscalCode
          gender
          dob
          nationality
          birthplace
          address
          city
          province
          zipCode
          country
          email
          certifiedEmail
          website
          phone
          mobilePhone
        }
        guest {
          ...guestFragment
        }
      }
    }
  }
  ${FbBillFragment}
  ${GUEST_FRAGMENT}
`;

export const GET_PAYMENT_METHODS = gql`
  query getFbPaymentMethods {
    getFbPaymentMethods {
      data {
        id
        uuid
        name
        description
      }
    }
  }
`;
