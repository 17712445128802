import React from 'react';
import { styled } from '@material-ui/core/styles';

const StyledCircle = styled('div')(({ color, size, margin }) => ({
  position: 'relative',
  backgroundColor: color,
  width: size || 30,
  height: size || 30,
  borderRadius: '50%',
  margin,
  '& div': {
    borderRadius: '50%',
    border: '1px solid white',
    width: size - (size * 10) / 100,
    height: size - (size * 10) / 100,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const ColoredCircle = ({ color, width, size, margin }) => {
  return (
    <StyledCircle color={color} width={width} size={size} margin={margin}>
      <div />
    </StyledCircle>
  );
};

export default ColoredCircle;
