import React from 'react';
import { Box, Button as MUIButton } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { TranslateTextComponent } from '../../translator';
import styles from '../../styles/menu/menuStyle';
import add from '../../assets/images/menu/add.svg';
import view1 from '../../assets/images/menu/view1.svg';
import view2 from '../../assets/images/menu/view2.svg';

const IconsContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));
const Button = styled(MUIButton)(() => ({ margin: 10, minWidth: 0 }));

const ItemsCounter = ({ itemsLength = 0, changeView, openAddItemModal }) => {
  const classes = styles();

  return (
    <Box className={classes.counter}>
      <Box>
        <TranslateTextComponent uppercase>listed-products</TranslateTextComponent>: {itemsLength}
      </Box>
      <IconsContainer>
        <Button onClick={openAddItemModal}>
          <img src={add} alt="Add" />
        </Button>
        <Button onClick={() => changeView('box')}>
          <img src={view1} alt="Box view" />
        </Button>
        <Button onClick={() => changeView('flat')}>
          <img src={view2} alt="Flat view" />
        </Button>
      </IconsContainer>
    </Box>
  );
};

export default ItemsCounter;
