import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import RemoveCircle from '@material-ui/icons/RemoveCircle';

const InputStepper = ({ onIncrement, onDecrement }) => {
  return (
    <Box style={{ display: 'flex', flexFlow: 'column', margin: '0 5px' }}>
      <IconButton style={{ padding: 0 }} onClick={onIncrement}>
        <AddCircle color="primary" />
      </IconButton>
      <IconButton style={{ padding: 0 }} onClick={onDecrement}>
        <RemoveCircle color="primary" />
      </IconButton>
    </Box>
  );
};

export default InputStepper;
