/* eslint-disable no-nested-ternary */
import { makeStyles } from '@material-ui/core/styles';
import { mainTextColor, borderTheme } from '../theme';

const styles = {
  buttonOrder: (props) => ({
    color: props.textColor,
    justifyContent: 'center',
    display: 'flex',
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: `2px solid ${props.color}`,
    borderLeft: `20px solid ${props.color}`,
    width: '100%',
    height: props.height || 50,
    backgroundColor: props.selectedCategory !== props.id ? null : props.color,
    '&:hover': {
      backgroundColor: 'white',
      color: props.textColor
    },
    '&:disabled': {
      color: mainTextColor
    }
  }),
  boldTypography: () => ({
    fontWeight: 'bold'
  }),
  nameTypography: () => ({
    fontWeight: 'bold',
    textTransform: 'uppercase'
  }),
  topDataGrid: () => ({
    borderBottom: borderTheme,
    marginBottom: 17,
    gridArea: 'top'
  }),
  imgButtonList: () => ({
    flex: 1,
    height: 32,
    width: 31
  }),
  buttonsPanelDetails: () => ({
    padding: '8px 8px'
  }),

  dishes: (props) => ({
    color: props.selectedDish !== props.dishKey ? mainTextColor : 'white',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: 15,
    border: `2px solid ${props.color}`,
    width: '100%',
    height: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    opacity: props.selectedDish !== props.dishKey ? (props.selectedDish === null ? 1 : 0.2) : null,
    backgroundColor: props.selectedDish !== props.dishKey ? null : props.color,
    '&:hover': {
      backgroundColor: props.selectedDish !== props.dishKey ? 'white' : props.color,
      color: props.selectedDish !== props.dishKey ? mainTextColor : 'white'
    },
    textTransform: 'none'
  }),
  circleButtonsGrid: () => ({
    marginTop: 43,
    padding: '0 35px'
  }),
  mainContainer: {
    height: '100%',
    display: 'grid',
    gridTemplateAreas: "'top' 'center' 'bottom'",
    gridTemplateRows: 'auto 1fr auto'
  }
};

export default makeStyles(styles);
