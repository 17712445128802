import { makeStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px 0'
  },
  filterInputContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    '& span': {
      margin: '0 10px',
      fontSize: 16,
      fontWeight: 'bold'
    }
  }
};

export default makeStyles(styles);
