import React, { useState, useEffect, useContext } from 'react';
import * as moment from 'moment';
import typy from 'typy';
import { Box, Grid, Typography, ClickAwayListener, Button, CircularProgress } from '@material-ui/core/';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ModalContext } from '../../contexts/ModalContext';
import { ReservationContext } from '../../contexts/ReservationContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import { TranslateTextComponent, translate, capitalizeFirstLetter } from '../../translator';
import { CustomButton, DataPicker, InputTextLabel, TimePicker, Dropdown } from '../../common-fe';
import PhysicalCategoriesBox from './PhysicalCategoriesBox';
import modalStyle from '../../common-fe/src/styles/shared/modalStyle';
import styles from '../../styles/saveReservation/newReservationStyle';
import ecStyles from '../../styles/saveReservation/AvailabilityStyle';
import { ROLES } from '../../constants/users/usersRole';
import { AuthContext } from '../../contexts/AuthContext';

const AvailabilityBox = ({
  physicalCategories,
  getAvailableSlots,
  availableSlotsData,
  isLoadingAvailableSlots,
  isLoadingPhysicalCategories,
  slotServiceId,
  userRoles,
  outlets
}) => {
  const classes = styles();
  const classesAvailability = ecStyles();
  const classesModal = modalStyle();

  const modalContext = useContext(ModalContext);
  const { appOutletUUID } = useContext(AuthContext);

  const [showPhysicalCategoriesBox, setShowPhysicalCategoriesBox] = useState(false);
  const [showCategoryInput, setShowCategoryInput] = useState(false);
  const [showSlotTime, setShowSlotTime] = useState(false);
  const [isShowOverBookingMessage, setIsShowOverBookingMessage] = useState(false);
  const [isShowAvailableMessage, setIsShowAvailableMessage] = useState(false);
  const [timeSlot, setTimeSlot] = useState(null);

  const languageContext = useContext(LanguageContext);
  const reservationContext = useContext(ReservationContext);
  const reservationActiveIndex = reservationContext?.isEditingReservationIndex;
  const activeReservation = reservationContext?.reservationsData[reservationActiveIndex];
  const handleChangeReservationData = reservationContext?.handleChangeReservationData;
  const handleChangeReservationDataFields = reservationContext?.handleChangeReservationDataFields;
  const initialFbGuestList = activeReservation?.fbGuestList;
  const errorResMap = reservationContext?.errorResMap;

  const [fbGuestList, setFbGuestList] = useState(initialFbGuestList);

  let availableSlots = [];
  if (availableSlotsData) availableSlots = typy(availableSlotsData.getAvailableSlots.availableSlots).safeArray;
  useEffect(() => {
    if (activeReservation?.fbGuestList) {
      setFbGuestList([...activeReservation.fbGuestList]);
      setIsShowAvailableMessage(false);
    }
  }, [activeReservation?.fbGuestList]);

  useEffect(() => {
    setFbGuestList(initialFbGuestList);
    setShowSlotTime(false);
    setIsShowOverBookingMessage(false);
    setIsShowAvailableMessage(false);
    setTimeSlot(null);
  }, [reservationActiveIndex]);

  const activeOutletUUID = reservationContext.reservationsData[reservationContext.isEditingReservationIndex]?.fbOutletUUID;
  useEffect(() => {
    if (activeOutletUUID !== null) {
      setShowSlotTime(false);
      setIsShowAvailableMessage(false);
      setIsShowOverBookingMessage(false);
      setTimeSlot(null);
    }
  }, [activeOutletUUID]);

  useEffect(() => {
    handleChangeReservationDataFields({
      fbOutletUUID: appOutletUUID,
      reservationDate: new Date()
    });
  }, []);

  const dataPickerStyle = { width: '100%', minHeight: 31 };
  const timePickerStyle = { width: '100%', minHeight: 31 };

  const addFbGuest = (category) => {
    setFbGuestList([
      ...fbGuestList,
      {
        guestPhysicalCategoryId: category.id
      }
    ]);
    setShowCategoryInput(category.hasAgeInput ? category : false);
  };

  const setShowCategoryAgeInput = (category) => {
    setShowCategoryInput(category);
  };

  const editFbGuestAge = (age, fbGuestIndex) => {
    fbGuestList[fbGuestIndex] = {
      ...fbGuestList[fbGuestIndex],
      age
    };
    setFbGuestList([...fbGuestList]);
  };

  const removeFbGuest = (category) => {
    const indexToRemove = fbGuestList.findIndex((cat) => cat.guestPhysicalCategoryId === category.id);
    const fbGuestListData = fbGuestList;
    if (indexToRemove > -1) {
      fbGuestListData.splice(indexToRemove, 1);
      setFbGuestList([...fbGuestListData]);
    }
  };

  const confirmFbGuestList = () => {
    if (activeReservation) {
      setIsShowAvailableMessage(false);
      setShowSlotTime(false);
      handleChangeReservationDataFields({ reservationTime: null, fbGuestList });
    } else {
      setTimeSlot(null);
      setIsShowAvailableMessage(false);
      setShowSlotTime(false);
      handleChangeReservationDataFields({
        reservationTime: null,
        fbServiceUUID: null,
        fbGuestList
      });
    }
    closePhysicalCategoriesBox();
  };

  const onDeleteClick = () => {
    setFbGuestList([]);
    closePhysicalCategoriesBox();
    handleChangeReservationData('fbGuestList', []);
  };

  const handleClick = () => {
    setShowPhysicalCategoriesBox((prev) => !prev);
  };
  const closePhysicalCategoriesBox = () => {
    setShowPhysicalCategoriesBox(false);
  };
  const handleClickAway = () => {
    setShowPhysicalCategoriesBox(false);
  };

  const noAvailabilityMsg = () => {
    if (!isLoadingAvailableSlots) {
      return (
        <Grid container>
          <Grid item className={classesAvailability.boxMsgNO}>
            <TranslateTextComponent capitalize>res-table-no</TranslateTextComponent>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  const availableMsg = () => {
    return (
      <Grid container style={{ marginTop: 25 }}>
        <Grid item className={classesAvailability.boxMsgOk}>
          <TranslateTextComponent capitalize>res-table-ok</TranslateTextComponent>
        </Grid>
      </Grid>
    );
  };

  const overbookingMsg = () => {
    return (
      <Grid container style={{ marginTop: 25 }}>
        <Grid item className={classesAvailability.boxMsgOb}>
          <TranslateTextComponent capitalize>res-table-ob-des</TranslateTextComponent>
        </Grid>
      </Grid>
    );
  };

  const isTimeSelected = () => {
    if (activeReservation?.reservationTime)
      return activeReservation.reservationTime !== null ? handleChangeReservationData('reservationTime', null) : null;
  };
  const handleChooseTimeRes = (availableSlot, buttonKey) => {
    if (!timeSlot || timeSlot !== buttonKey) {
      setTimeSlot(buttonKey);
      if (isShowOverBookingMessage && timeSlot !== buttonKey) setIsShowOverBookingMessage(false);
    } else {
      setTimeSlot(null);
    }
    if (availableSlot?.overbooking !== null) {
      if (isShowAvailableMessage) {
        setIsShowAvailableMessage(false);
        setTimeSlot(buttonKey);
      }
      setIsShowOverBookingMessage(true);
      modalContext.openModal({
        class: 'danger',
        title: <TranslateTextComponent capitalize>res-table-ob</TranslateTextComponent>,
        text: <TranslateTextComponent capitalize>res-table-ob-des</TranslateTextComponent>,
        actionButtons: [
          <Button
            key={0}
            variant="outlined"
            color="primary"
            className={classesModal.invertedButton}
            onClick={() => {
              modalContext.closeModal();
              setIsShowOverBookingMessage(false);
              setIsShowAvailableMessage(false);
              setTimeSlot(null);
            }}
          >
            <TranslateTextComponent capitalize>cancel</TranslateTextComponent>
          </Button>,
          <Button
            key={1}
            color="primary"
            variant="contained"
            className={classesModal.buttonStyle}
            onClick={() => {
              modalContext.closeModal();
              handleChangeReservationDataFields({
                reservationTime: availableSlot.time,
                fbServiceUUID: slotServiceId
              });
            }}
          >
            <TranslateTextComponent capitalize>confirm</TranslateTextComponent>
          </Button>
        ]
      });
    }
    if (availableSlot?.overbooking === null) {
      setIsShowAvailableMessage(true);
      handleChangeReservationDataFields({
        reservationTime: availableSlot.time,
        fbServiceUUID: slotServiceId
      });
    }
  };

  return (
    <Box>
      <Box style={{ marginTop: 15 }}>
        <Typography className={classes.checkTypography}>
          <TranslateTextComponent capitalize>n-pax</TranslateTextComponent>
        </Typography>
        {activeReservation?.fbGuestList?.length <= 0 ? (
          <span style={{ color: 'red', fontSize: 10 }}>{errorResMap ? errorResMap[reservationActiveIndex].get('fbGuestList') : null}</span>
        ) : null}
      </Box>
      {physicalCategories?.length > 0 && !isLoadingPhysicalCategories && (
        <Box className={classesAvailability.boxListener}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box className={classesAvailability.boxListenerLabel}>
              <Button onClick={handleClick} className={classesAvailability.buttonlistener}>
                {physicalCategories
                  ?.sort((a, b) => b.ageMin - a.ageMin)
                  .map((catName, index) => (
                    <Box style={{ marginRight: 5 }} key={index}>
                      {activeReservation?.fbGuestList.filter((fbGuest) => fbGuest.guestPhysicalCategoryId === catName.id).length}
                      {catName.description}
                    </Box>
                  ))}
              </Button>
              {showPhysicalCategoriesBox ? (
                <PhysicalCategoriesBox
                  fbGuestList={fbGuestList}
                  addFbGuest={addFbGuest}
                  removeFbGuest={removeFbGuest}
                  closePhysicalCategoriesBox={closePhysicalCategoriesBox}
                  onDeleteClick={onDeleteClick}
                  showCategoryInput={showCategoryInput}
                  setShowCategoryAgeInput={setShowCategoryAgeInput}
                  editFbGuestAge={editFbGuestAge}
                  confirmFbGuestList={confirmFbGuestList}
                  physicalCategories={physicalCategories}
                />
              ) : null}
            </Box>
          </ClickAwayListener>
        </Box>
      )}
      {isLoadingPhysicalCategories && (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {physicalCategories?.length <= 0 && reservationContext.reservationsData[reservationActiveIndex]?.fbOutletUUID && !isLoadingPhysicalCategories && (
        <TranslateTextComponent capitalize style={{ color: 'rgb(203, 45, 36)', fontWeight: 'bold' }}>
          profiles-not-activated-enable-them-in-settings
        </TranslateTextComponent>
      )}
      <Box style={{ marginTop: 60 }}>
        <Box>
          <Typography className={classes.checkTypography}>
            <TranslateTextComponent capitalize>outlet</TranslateTextComponent>
          </Typography>
          <Dropdown
            dropdownStyle={{ width: '100%' }}
            items={outlets.map((outlet) => ({ label: outlet.name, value: outlet.uuid }))}
            value={reservationContext.reservationsData?.[reservationActiveIndex]?.fbOutletUUID}
            error={errorResMap ? errorResMap[reservationActiveIndex].has('fbOutletUUID') : null}
            errorLabel={errorResMap ? errorResMap[reservationActiveIndex].get('fbOutletUUID') : null}
            onChange={({ target: { value } }) => {
              handleChangeReservationDataFields({
                fbOutletUUID: value,
                fbServiceUUID: null,
                fbGuestList: [],
                selectedTime: null,
                reservationTime: null
              });
            }}
          />
        </Box>
      </Box>
      <Box style={{ marginTop: 20 }}>
        <Typography className={classes.checkTypography}>
          <TranslateTextComponent capitalize>date</TranslateTextComponent>
        </Typography>
        <Box>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container style={{ display: 'contents', width: '100%' }}>
              <DataPicker
                onChange={(date) => {
                  setTimeSlot(null);
                  handleChangeReservationDataFields({
                    reservationTime: null,
                    reservationDate: date
                  });
                }}
                error={errorResMap ? errorResMap[reservationActiveIndex].has('reservationDate') : null}
                errorLabel={errorResMap ? errorResMap[reservationActiveIndex].get('reservationDate') : null}
                value={activeReservation && activeReservation.reservationDate}
                dataPickerStyle={dataPickerStyle}
              />
            </Grid>
            <Box style={{ width: '100%', marginTop: 20 }}>
              <Typography className={classes.checkTypography}>
                <TranslateTextComponent capitalize>time</TranslateTextComponent>
              </Typography>
              <TimePicker
                onChange={(time) => {
                  setTimeSlot(null);
                  handleChangeReservationDataFields({
                    selectedTime: time ? moment(time).format('HH:mm') : null,
                    reservationTime: null
                  });
                }}
                error={errorResMap ? errorResMap[reservationActiveIndex].has('selectedTime') : null}
                errorLabel={errorResMap ? errorResMap[reservationActiveIndex].get('selectedTime') : null}
                value={(activeReservation && activeReservation.selectedTime) || (activeReservation && activeReservation.reservationTime)}
                timePickerStyle={timePickerStyle}
              />
            </Box>
          </MuiPickersUtilsProvider>
        </Box>
        <Box style={{ marginTop: 20, marginBottom: 5 }}>
          <Typography className={classes.checkTypography}>
            <TranslateTextComponent capitalize>notes</TranslateTextComponent>
          </Typography>
          <InputTextLabel
            placeholder={capitalizeFirstLetter(translate('notes', languageContext.language))}
            className={classesAvailability.noteTextField}
            value={activeReservation?.notes ? activeReservation.notes : ''}
            onChange={(e) => handleChangeReservationData('notes', e.target.value)}
          />
        </Box>
        {activeReservation?.selectedTime !== null &&
        activeReservation?.reservationDate !== null &&
        activeReservation?.fbGuestList?.length > 0 &&
        activeReservation.fbOutletUUID ? (
          <Box style={{ marginTop: 20 }}>
            <CustomButton
              isLoading={isLoadingAvailableSlots}
              onClick={() => {
                setTimeSlot(null);
                isTimeSelected();
                getAvailableSlots({
                  variables: {
                    fbOutletUUID: activeReservation.fbOutletUUID,
                    date: moment(activeReservation.reservationDate).format('YYYY-MM-DD'),
                    reservationTime: activeReservation.selectedTime,
                    fbGuestList: activeReservation.fbGuestList
                  }
                });
                setShowSlotTime(true);
                setIsShowOverBookingMessage(false);
                setIsShowAvailableMessage(false);
              }}
            >
              <TranslateTextComponent capitalize>check-availability</TranslateTextComponent>
            </CustomButton>
          </Box>
        ) : null}
        {errorResMap[reservationActiveIndex].has('reservationTime') ? (
          <Typography
            style={{
              color: 'red',
              paddingTop: 10,
              fontSize: 12,
              textAlign: 'center'
            }}
          >
            <TranslateTextComponent capitalize>select-slot</TranslateTextComponent>
          </Typography>
        ) : null}
        {showSlotTime ? (
          <Box style={{ marginTop: 10 }}>
            <Grid container justify="space-between" style={{ textAlign: 'center', marginTop: 10 }}>
              {availableSlots.length !== 0
                ? availableSlots.map((availableSlot, key) => {
                    return (
                      <Grid item xs={4} key={key}>
                        <Button
                          disabled={
                            !availableSlot.isAvailable ||
                            (availableSlot.overbooking !== null &&
                              [ROLES.ROLE_LOCAL, ROLES.ROLE_FRONT_OFFICE, ROLES.ROLE_GUEST_SERVICE].some((r) => userRoles.includes(r)))
                          }
                          className={timeSlot === key ? classesAvailability.selectedTimeSlots : classesAvailability.timeSlots}
                          onClick={() => {
                            handleChooseTimeRes(availableSlot, key);
                          }}
                        >
                          {availableSlot.time}
                        </Button>
                      </Grid>
                    );
                  })
                : noAvailabilityMsg()}
            </Grid>
          </Box>
        ) : null}
        {isShowOverBookingMessage && overbookingMsg()}
        {isShowAvailableMessage && availableMsg()}
      </Box>
    </Box>
  );
};

export default AvailabilityBox;
