import React from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import ApolloProvider from './apollo/ApolloProvider';
import { ModalProvider, ModalConsumer } from './contexts/ModalContext';
import { Modal } from './common-fe';
import { appTheme } from './styles/theme';
import AppRouter from './router/router';
import { ReservationProvider } from './contexts/ReservationContext';
import { LanguageProvider } from './contexts/LanguageContext';
import { OrderProvider } from './contexts/OrderContext';
import { AppOutletProvider } from './contexts/AppOutletContext';
import { AuthProvider } from './contexts/AuthContext';
import { NotificationProvider, NotificationConsumer } from './contexts/NotificationContext';
import Notification from './components/shared/Notification';
import { OrdersNotificationProvider, OrdersNotificationConsumer } from './contexts/OrdersNotificationContext';
import Guard from './components/shared/Guard';
import { ROLES } from './constants/users/usersRole';
import OrderConfirmationModal from './components/Moh/orders/OrderConfirmationModal';

const StoreProviders = (props) => (
  <AppOutletProvider>
    <OrdersNotificationProvider>
      <ReservationProvider>
        <OrderProvider>{props.children}</OrderProvider>
      </ReservationProvider>
    </OrdersNotificationProvider>
  </AppOutletProvider>
);

const App = () => {
  return (
    <MuiThemeProvider theme={appTheme}>
      <CssBaseline />
      <LanguageProvider>
        <NotificationProvider>
          <ModalProvider>
            <AuthProvider>
              <ApolloProvider>
                <StoreProviders>
                  <AppRouter />
                  <ModalConsumer>
                    {(value) =>
                      value.isVisible && (
                        <Modal
                          isVisible={value.isVisible || false}
                          isLoading={value.isLoading || false}
                          icon={value.icon}
                          modalClass={value.class}
                          title={value.title}
                          text={value.text}
                          content={value.content}
                          actionButtons={value.actionButtons}
                          paperStyle={value.paperStyle}
                          onButtonClick={value.onButtonClick || value.closeModal}
                          onBackdropPress={value.onBackdropPress || value.closeModal}
                        />
                      )
                    }
                  </ModalConsumer>
                  <NotificationConsumer>
                    {(notificationValues) => (
                      <Guard roles={[ROLES.ROLE_SUPER_ADMIN]}>
                        <Notification notificationValues={notificationValues} />
                      </Guard>
                    )}
                  </NotificationConsumer>
                  <OrdersNotificationConsumer>
                    {({ showOrderConfirmationModal, currentOrder, onOrderConfirm, onOrderDelete }) => {
                      return (
                        showOrderConfirmationModal &&
                        currentOrder && (
                          <Guard ignoreSuperAdmin roles={[ROLES.FB_MANAGER, ROLES.FB_OPERATOR]}>
                            <OrderConfirmationModal order={currentOrder} confirmOrder={onOrderConfirm} deleteOrder={onOrderDelete} />
                          </Guard>
                        )
                      );
                    }}
                  </OrdersNotificationConsumer>
                </StoreProviders>
              </ApolloProvider>
            </AuthProvider>
          </ModalProvider>
        </NotificationProvider>
      </LanguageProvider>
    </MuiThemeProvider>
  );
};

export default App;
