import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import { TextField as MUITextField, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import moment, { weekdays } from 'moment';
import InputWithLabel from '../../shared/InputWithLabel';
import Switch from '../../shared/Switch';
import { TimePicker, invertedPrimaryCC80 } from '../../../common-fe/src';
import { TranslateTextComponent } from '../../../translator';
import ButtonsGroup from '../../shared/ButtonsGroup';
import Buttons from '../../shared/Buttons';
import MultiSelect from '../../shared/MultiSelect';
import { ModalContext } from '../../../contexts/ModalContext';
import { AuthContext } from '../../../contexts/AuthContext';
import useCustomQuery from '../../../hooks/useCustomQuery';
import { GET_FB_OUTLET } from '../../../graphql/outlets/queries';
import { getApolloErrors } from '../../../apollo/ApolloProvider';
import { EDIT_FB_OUTLET } from '../../../graphql/outlets/mutations';
import { GET_OUTLET_CATEGORIES } from '../../../graphql/moh/categories/queries';
import styles from '../../../styles/settings/tabs';

const Container = styled.div`
  .MuiGrid-root {
    width: 100%;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 60%;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > div:not(:last-child) {
    margin-bottom: 30px;
  }

  & > div:not(:last-child) {
    margin-right: 30px;
  }
`;

const TextField = styled(MUITextField).attrs({ variant: 'outlined', color: 'primary' })``;

const Label = styled(TranslateTextComponent)`
  margin-right: 20px;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  text-transform: capitalize;
`;

const chipParams = {
  variant: 'default',
  color: 'primary',
  style: { color: 'white' }
};

const validationSchema = Yup.object().shape({
  notificationEmail: Yup.array().of(
    Yup.object().shape({
      value: Yup.string().email('Invalid email')
    })
  ),
  price: Yup.number().typeError('Invalid number'),
  startTime: Yup.string().typeError('Invalid date').required('Required'),
  endTime: Yup.string().typeError('Invalid date').required('Required'),
  range: Yup.number().typeError('Invalid number'),
  ordersSlot: Yup.number().typeError('Invalid number'),
  slotLength: Yup.number().typeError('Invalid number'),
  categories: Yup.array().min(1, 'Required'),
  timeGap: Yup.number().typeError('Invalid number')
});

const TakeAway = () => {
  const classes = styles();

  const [takeawayDays, setTakeawayDays] = useState(new Array(weekdays().length).fill(false));
  const [initialTakeawayDays, setInitialTakeawayDays] = useState(null);
  const { appOutletUUID } = useContext(AuthContext);
  const modalContext = useContext(ModalContext);

  // Queries
  const getOutlet = useCustomQuery(GET_FB_OUTLET, {
    variables: { fbOutletUUID: appOutletUUID },
    onCompleted: (data) => {
      setTakeawayDays([...new Array(weekdays().length).fill(false).map((v, i) => !!data?.getFbOutlet?.smartSettings?.takeAwayDays?.includes(i))]);
      setInitialTakeawayDays([
        ...new Array(weekdays().length).fill(false).map((v, i) => !!data?.getFbOutlet?.smartSettings?.takeAwayDays?.includes(i))
      ]);
    }
  });

  const outletCategoriesResponse = useCustomQuery(GET_OUTLET_CATEGORIES);
  const outletCategories = outletCategoriesResponse?.data?.getOutletCategories?.data ?? [];

  // Mutations
  const [editOutlet] = useMutation(EDIT_FB_OUTLET);

  const { values, errors, touched, isSubmitting, setFieldValue, handleBlur, handleChange, handleReset, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      takeaway: getOutlet.data?.getFbOutlet?.isTakeAwayEnabled ?? false,
      notificationEmail:
        getOutlet.data?.getFbOutlet?.notificationEmail?.map((notificationEmail) => ({ value: notificationEmail, label: notificationEmail })) ?? [],
      startTime: getOutlet.data?.getFbOutlet?.smartSettings?.takeAwayStartTime ?? null,
      endTime: getOutlet.data?.getFbOutlet?.smartSettings?.takeAwayEndTime ?? null,
      range: getOutlet.data?.getFbOutlet?.smartSettings?.maxDeliveryDistance ?? 0,
      categories: getOutlet.data?.getFbOutlet?.outletCategories?.map((cat) => ({ value: cat.id, label: cat.name, ...cat })) ?? [],
      ordersSlot:
        getOutlet.data?.getFbOutlet?.smartSettings?.sharedMaxOrdersForSlot && getOutlet.data?.getFbOutlet?.smartSettings?.sharedSlotSize
          ? getOutlet.data?.getFbOutlet?.smartSettings?.sharedMaxOrdersForSlot
          : getOutlet.data?.getFbOutlet?.smartSettings?.takeAwayMaxOrdersForSlot ?? 0,
      slotLength:
        getOutlet.data?.getFbOutlet?.smartSettings?.sharedMaxOrdersForSlot && getOutlet.data?.getFbOutlet?.smartSettings?.sharedSlotSize
          ? getOutlet.data?.getFbOutlet?.smartSettings?.sharedSlotSize
          : getOutlet.data?.getFbOutlet?.smartSettings?.takeAwaySlotSize ?? 0,
      timeGap: getOutlet.data?.getFbOutlet?.smartSettings?.deliveryOrderTimeGap ?? 0,

      both: !!(getOutlet.data?.getFbOutlet?.smartSettings?.sharedMaxOrdersForSlot && getOutlet.data?.getFbOutlet?.smartSettings?.sharedSlotSize)
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      const sharedMaxOrdersForSlot = getOutlet.data?.getFbOutlet?.smartSettings?.sharedMaxOrdersForSlot;
      const sharedSlotSize = getOutlet.data?.getFbOutlet?.smartSettings?.sharedSlotSize;
      let slots = {};
      let categoriesIds = {};
      if (values.categories.length > 0) {
        categoriesIds = values.categories.map((category) => parseInt(category.id, 10)) || [];
      }
      if (values.both) {
        slots = { sharedMaxOrdersForSlot: parseFloat(values.ordersSlot, 10), sharedSlotSize: parseFloat(values.slotLength, 10) };
      } else if (sharedMaxOrdersForSlot !== null && sharedSlotSize !== null) {
        slots = {
          takeAwayMaxOrdersForSlot: parseFloat(values.ordersSlot, 10),
          takeAwaySlotSize: parseFloat(values.slotLength, 10),
          // Set delivery values to the shared ones
          deliveryMaxOrdersForSlot: parseFloat(sharedMaxOrdersForSlot, 10),
          deliverySlotSize: parseFloat(sharedSlotSize, 10),
          sharedMaxOrdersForSlot: null,
          sharedSlotSize: null
        };
      } else {
        slots = {
          takeAwayMaxOrdersForSlot: parseFloat(values.ordersSlot, 10),
          takeAwaySlotSize: parseFloat(values.slotLength, 10)
        };
      }

      editOutlet({
        variables: {
          fbOutletUUID: appOutletUUID,
          fbOutletData: {
            notificationEmail: values?.notificationEmail?.map((notificationEmail) => notificationEmail.value),
            isTakeAwayEnabled: values.takeaway,
            outletCategories: categoriesIds,
            smartSettings: {
              deliveryOrderTimeGap: parseFloat(values.timeGap),
              takeAwayStartTime: moment(values.startTime, 'HH:mm').format('HH:mm'),
              takeAwayEndTime: moment(values.endTime, 'HH:mm').format('HH:mm'),
              takeAwayDays: takeawayDays.reduce((out, bool, i) => (bool ? out.concat(i) : out), []),
              ...slots
            }
          }
        }
      })
        .then(() => {
          getOutlet.refetch();
          modalContext.openModal({
            class: 'success',
            title: <TranslateTextComponent capitalize>outlet-edit-success</TranslateTextComponent>,
            text: <TranslateTextComponent capitalize>outlet-edit-success-description</TranslateTextComponent>,
            actionButtons: [
              <Button
                key={0}
                variant="contained"
                color="primary"
                className="success"
                onClick={() => {
                  modalContext.closeModal();
                }}
              >
                <TranslateTextComponent uppercase>ok</TranslateTextComponent>
              </Button>
            ]
          });
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        });
      setSubmitting(false);
    }
  });

  return (
    <Container>
      <RowContainer>
        <InputWithLabel label="takeaway" containerStyle={{ width: 'auto' }}>
          <Switch
            leftLabel="no"
            rightLabel="yes"
            value={values.takeaway}
            onChange={(value) => {
              setFieldValue('takeaway', value);
            }}
          />
        </InputWithLabel>
        <RowContainer style={{ justifyContent: 'flex-end' }}>
          <InputWithLabel label="notificationEmail">
            <MultiSelect
              freeSolo
              error={touched.notificationEmail && !!errors.notificationEmail}
              options={[]}
              chipParams={chipParams}
              value={values.notificationEmail}
              getOptionSelected={(option, { value }) => option.value === value}
              onChange={(e, value) => {
                const newValues = [];
                value.forEach((v) => {
                  if (typeof v === 'string') {
                    newValues.push({ label: v, value: v });
                  } else newValues.push(v);
                });
                setFieldValue('notificationEmail', newValues);
              }}
            />
          </InputWithLabel>
          <InputWithLabel label="price" containerStyle={{ width: 'auto' }}>
            <TextField disabled value={0} />
          </InputWithLabel>
        </RowContainer>
      </RowContainer>
      <RowContainer>
        <InputWithLabel label="start-time">
          <TimePicker fullWidth value={values.startTime} onChange={(value) => setFieldValue('startTime', value)} />
        </InputWithLabel>
        <InputWithLabel label="end-time">
          <TimePicker fullWidth value={values.endTime} onChange={(value) => setFieldValue('endTime', value)} />
        </InputWithLabel>
      </RowContainer>
      <RowContainer>
        <InputWithLabel label="km-range">
          <TextField fullWidth disabled value={values.range} />
        </InputWithLabel>
        <InputWithLabel label="category" error={touched.categories && errors.categories} withMarginBottom>
          <MultiSelect
            options={outletCategories.map((category) => ({ ...category, label: category.name, value: category.uuid }))}
            value={values.categories}
            classes={classes.dropdown}
            onChange={(e, value) => setFieldValue('categories', value)}
          />
        </InputWithLabel>
      </RowContainer>
      <TranslateTextComponent capitalize style={{ fontWeight: 500, fontSize: 16, color: '#55595C', marginBottom: 5 }}>
        shared-settings
      </TranslateTextComponent>
      <RowContainer style={{ border: `1px solid ${invertedPrimaryCC80}`, borderRadius: 4, padding: '30px 22px', marginBottom: 40 }}>
        <ColumnContainer>
          <RowContainer>
            <InputWithLabel label="max-orders-per-slot" error={touched.ordersSlot && errors.ordersSlot}>
              <TextField
                name="ordersSlot"
                fullWidth
                value={values.ordersSlot}
                error={touched.ordersSlot && !!errors.ordersSlot}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputWithLabel>
            <InputWithLabel label="slot-length" error={touched.slotLength && errors.slotLength}>
              <TextField
                name="slotLength"
                fullWidth
                value={values.slotLength}
                error={touched.slotLength && !!errors.slotLength}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputWithLabel>
            <InputWithLabel label="time-gap" error={touched.timeGap && errors.timeGap}>
              <TextField
                name="timeGap"
                fullWidth
                value={values.timeGap}
                error={touched.timeGap && !!errors.timeGap}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputWithLabel>
          </RowContainer>
          <RowContainer>
            <FormControlLabel
              control={
                <Checkbox
                  name="both"
                  color="primary"
                  checked={values.both}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onChange={handleChange}
                />
              }
              label={
                <TranslateTextComponent capitalize style={{ marginLeft: 10, color: '#55595C' }}>
                  applies-to-takeaway-and-delivery
                </TranslateTextComponent>
              }
            />
          </RowContainer>
        </ColumnContainer>
      </RowContainer>
      <RowContainer style={{ justifyContent: 'flex-start', alignItems: 'center', marginBottom: 50 }}>
        <Label>enabled-days</Label>
        <ButtonsGroup
          containerStyle={{ width: '100%' }}
          buttons={[
            ...takeawayDays.slice(1).map((value, i) => ({ label: weekdays()[i + 1].toLowerCase(), checked: value, buttonStyle: { width: '100%' } })),
            { label: weekdays()[0].toLowerCase(), checked: takeawayDays[0], buttonStyle: { width: '100%' } }
          ]}
          onChange={(index) => {
            const newTakeawayDays = takeawayDays;
            if (index + 1 > takeawayDays.length - 1) {
              newTakeawayDays[0] = !newTakeawayDays[0];
            } else newTakeawayDays[index + 1] = !newTakeawayDays[index + 1];
            setTakeawayDays([...newTakeawayDays]);
          }}
        />
      </RowContainer>
      <Buttons
        containerStyle={{ justifyContent: 'space-between' }}
        buttons={[
          {
            buttonType: 'reset',
            style: { padding: '5px 10px', minWidth: 155, fontSize: 20, fontWeight: 600 },
            type: 'cancel',
            onClick: () => {
              setTakeawayDays([...initialTakeawayDays]);
              handleReset();
            }
          },
          {
            buttonType: 'submit',
            style: { padding: '5px 10px', minWidth: 155, fontSize: 20, fontWeight: 600 },
            label: 'save',
            type: 'confirm',
            disabled: isSubmitting,
            onClick: handleSubmit
          }
        ]}
      />
    </Container>
  );
};

export default TakeAway;
