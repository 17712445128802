/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { TranslateTextComponent } from '../../translator';

const getHistoryColumns = () => {
  return [
    {
      id: 'date',
      disablePadding: false,
      label: <TranslateTextComponent capitalize>reservation-date</TranslateTextComponent>,
      isSortable: false,
      render: (item) => item.fbReservation.date
    },
    {
      id: 'reservationTime',
      disablePadding: false,
      label: <TranslateTextComponent capitalize>time</TranslateTextComponent>,
      isSortable: false,
      render: (item) => item.fbReservation.reservationTime
    },
    {
      id: 'quantity',
      disablePadding: false,
      label: <TranslateTextComponent capitalize>amount</TranslateTextComponent>,
      isSortable: false,
      render: (item) => item.quantity
    },
    {
      id: 'name',
      disablePadding: false,
      label: <TranslateTextComponent capitalize>wine name</TranslateTextComponent>,
      isSortable: false,
      render: (item) => item.fbMenuItem.name
    },
    {
      id: 'outlet',
      disablePadding: false,
      width: '15%',
      label: <TranslateTextComponent capitalize>outlet</TranslateTextComponent>,
      isSortable: false,
      render: (item) => item.fbOutlet.name
    },

    {
      id: 'wineTypology',
      disablePadding: false,
      label: <TranslateTextComponent capitalize>wine typology</TranslateTextComponent>,
      isSortable: false,
      render: (item) => item.fbMenuItem.fbMenuCategory.name
    }
  ];
};

export default getHistoryColumns;
