import { makeStyles } from '@material-ui/core/styles';

const styles = {
  wrapper: ({ top, left }) => ({
    position: 'fixed',
    top,
    left,
    maxWidth: 212,
    maxHeight: 220,
    width: '100%',
    height: '100%',

    zIndex: 10
  }),
  container: {
    display: 'grid',
    gridTemplateAreas: '"title" "guests-list" "new-guest"',
    maxHeight: 220,
    backgroundColor: 'white',
    borderRadius: 4,
    overflow: 'auto',
    scrollbarColor: '#00B7FF #B4DCED',
    scrollbarWidth: 'thin',
    // Chrome only
    '& ::-webkit-scrollbar-track': {
      borderRadius: 10
    }
  },
  title: {
    gridArea: 'title',
    backgroundColor: '#00B7FF',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    color: 'white',
    padding: 5,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4
  },
  guestList: {
    gridArea: 'guests-list'
  },
  newGuest: {
    gridArea: 'new-guest',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    width: '100%',
    padding: 10,
    '& button': {
      color: 'white'
    },
    borderTop: '2px solid #B4DCED',
    boxShadow: '3px 3px 3px #DFDFDF'
  }
};

export default makeStyles(styles);
