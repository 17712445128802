import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-loader-spinner';
import {
  Box,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Table,
  TableBody,
  TablePagination,
  Typography,
  Button,
  Hidden,
} from '@material-ui/core';
import { primaryColor, secondaryColor } from '../../styles/theme';

const CommonTable = ({
  isLoading,
  size,
  items,
  columns,
  stickyHeader,
  hideHeader,
  tableHeader,
  orderBy,
  orderType,
  page,
  pageSize,
  rowsPerPageOptions,
  totalRows,
  isPaginationEnabled,
  headerTextStyle,
  headerCellStyle,
  rowCellStyle,
  rowStyle,
  tableContainerStyle,
  setTableSort,
  setTablePages,
  renderDetailsComponent,
}) => {
  const [openRowIndex, setOpenRowIndex] = useState(false);
  const [renderFunctionIndex, setRenderFunctionIndex] = useState(false);

  const handleSetOpenRowIndex = (index, rendFuncInd) => {
    if (renderFunctionIndex === rendFuncInd) {
      setOpenRowIndex(openRowIndex === index ? false : index);
    } else {
      setOpenRowIndex(index);
    }
    setRenderFunctionIndex(rendFuncInd);
  };

  const handleChangePage = (event, newPage) => {
    page = newPage + 1;
    setTablePages(page, pageSize);
  };

  const handleChangeRowsPerPage = (event) => {
    page = 1;
    pageSize = event.target.value;
    setTablePages(page, pageSize);
  };

  const defaultHeaderCellStyle = {
    fontWeight: 'bold',
    color: 'black',
    ...headerCellStyle,
  };

  const renderColumns = columns.map((col, indexCol) => {
    return {
      position: col.position || indexCol,
      renderColumn: () => (
        <Hidden {...col.hidden} key={indexCol}>
          <TableCell
            style={{
              width: col.width || 'auto',
              fontWeight: 'bold',
              ...defaultHeaderCellStyle,
              ...headerCellStyle,
            }}
            key={indexCol}
            align={col.align}
            padding={col.disablePadding ? 'none' : 'default'}
          >
            {col.isSortable ? (
              <TableSortLabel active={orderBy === col.id} direction={orderType} onClick={() => setTableSort(col.id, orderType)}>
                <Typography style={{ fontWeight: 'bold', ...headerTextStyle }}>{col.label}</Typography>
              </TableSortLabel>
            ) : (
              <Typography style={{ fontWeight: 'bold', ...headerTextStyle }}>{col.label}</Typography>
            )}
          </TableCell>
        </Hidden>
      ),
    };
  });

  if (renderDetailsComponent) {
    renderDetailsComponent.forEach((component, i) =>
      renderColumns.push({
        position: component.position || renderColumns.length + i,
        renderColumn: () => (
          <Hidden {...component.hidden} key={renderColumns.length + i}>
            <TableCell
              key={`${i}renderDetailsComponent`}
              style={{
                width: 'auto',
                ...defaultHeaderCellStyle,
                ...headerCellStyle,
              }}
              padding={component.disablePadding ? 'none' : 'default'}
            >
              {component.columnLabel && <Typography style={{ fontWeight: 'bold', ...headerTextStyle }}>{component.columnLabel}</Typography>}
            </TableCell>
          </Hidden>
        ),
      })
    );
  }

  const getTableHeader = () => {
    return (
      <TableHead>
        <TableRow>{renderColumns.sort((colA, colB) => colA.position - colB.position).map((col) => col.renderColumn())}</TableRow>
      </TableHead>
    );
  };

  const getTableItems = (row, index) => {
    const renderItems = columns.map((col, indexCol) => {
      return {
        position: col.position || indexCol,
        renderColumn: () => (
          <Hidden {...col.hidden} key={indexCol}>
            <TableCell align={col.align} style={{ width: col.width || 'auto', ...rowCellStyle }} key={indexCol}>
              {col.render ? col.render(row, col) : null}
            </TableCell>
          </Hidden>
        ),
      };
    });

    if (renderDetailsComponent) {
      renderDetailsComponent.forEach((btn, renderFuncIndex) =>
        renderItems.push({
          position: btn.position || renderItems.length + renderFuncIndex,
          renderColumn: () => (
            <Hidden {...btn.hidden} key={renderItems.length + renderFuncIndex}>
              <TableCell key={`${renderFuncIndex}renderFuncIndex`} style={{ width: 'auto', ...rowCellStyle }}>
                <Button
                  disabled={btn.showIf && !btn.showIf(row)}
                  key={renderFuncIndex}
                  color="primary"
                  variant="contained"
                  className="buttonTable"
                  onClick={() => handleSetOpenRowIndex(index, renderFuncIndex)}
                >
                  {openRowIndex === index && renderFunctionIndex === renderFuncIndex ? 'close' : btn.buttonLabel}
                </Button>
              </TableCell>
            </Hidden>
          ),
        })
      );
    }

    renderItems.sort((colA, colB) => colA.position - colB.position);
    return renderItems;
  };

  return (
    <Box style={{ overflow: 'auto', ...tableContainerStyle }}>
      {tableHeader}

      <Table size={size} stickyHeader={stickyHeader}>
        {!hideHeader && getTableHeader()}

        <TableBody>
          {isLoading && items.length === 0 && (
            <TableRow style={{ width: '100%' }}>
              <TableCell colSpan={columns.length + (renderDetailsComponent && renderDetailsComponent.length)}>
                <Spinner
                  type="TailSpin"
                  color="#00BFFF"
                  height={100}
                  width={100}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                />
              </TableCell>
            </TableRow>
          )}
          {items &&
            items.map((row, index) => {
              return (
                <React.Fragment key={`row${index}`}>
                  <TableRow
                    hover
                    style={{
                      borderTop: `1px solid ${primaryColor}`,
                      backgroundColor: index % 2 !== 0 ? secondaryColor : 'white',
                      ...rowStyle,
                    }}
                  >
                    {getTableItems(row, index).map((rowItem) => rowItem.renderColumn())}
                  </TableRow>

                  {openRowIndex === index && (
                    <TableRow style={{ borderBottom: `1px solid ${primaryColor}`, width: '100%' }}>
                      <TableCell colSpan={columns.length + renderDetailsComponent.length}>
                        {renderDetailsComponent[renderFunctionIndex].renderFunction(row)}
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })}
        </TableBody>
      </Table>
      {isPaginationEnabled && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalRows || 0}
          rowsPerPage={pageSize}
          page={page - 1}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
};

export default CommonTable;

CommonTable.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
  items: PropTypes.instanceOf(Array).isRequired,
  columns: PropTypes.instanceOf(Array).isRequired,
  stickyHeader: PropTypes.bool,
  hideHeader: PropTypes.bool,
  tableHeader: PropTypes.element,
  orderBy: PropTypes.string,
  orderType: PropTypes.string,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  totalRows: PropTypes.number,
  isPaginationEnabled: PropTypes.bool,
  headerTextStyle: PropTypes.instanceOf(Object),
  headerCellStyle: PropTypes.instanceOf(Object),
  rowCellStyle: PropTypes.instanceOf(Object),
  rowStyle: PropTypes.instanceOf(Object),
  tableContainerStyle: PropTypes.instanceOf(Object),
  setTableSort: PropTypes.func,
  setTablePages: PropTypes.func,
  renderDetailsComponent: PropTypes.instanceOf(Array),
};

CommonTable.defaultProps = {
  size: 'medium',
  stickyHeader: false,
  hideHeader: false,
  tableHeader: null,
  orderBy: null,
  orderType: 'asc',
  page: 1,
  pageSize: 10,
  rowsPerPageOptions: [5, 10, 25],
  totalRows: null,
  isPaginationEnabled: false,
  headerTextStyle: {},
  headerCellStyle: {},
  rowCellStyle: {},
  rowStyle: {},
  tableContainerStyle: {},
  setTableSort: PropTypes.func,
  setTablePages: PropTypes.func,
  renderDetailsComponent: null,
};
