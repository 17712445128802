import { makeStyles } from '@material-ui/core/styles';
import { borderTheme, mainTextColor } from '../theme';

const styles = {
  button: () => ({
    color: 'white',
    borderRadius: 9,
    boxShadow: 'none'
  }),
  switchTypography: () => ({
    marginRight: 20
  }),
  labelField: () => ({
    fontSize: 12,
    color: 'black'
  }),
  buttonModalForm: (props) => ({
    height: 28,
    letterSpacing: 0,
    fontSize: 10,
    color: 'white',
    borderRadius: 9,
    backgroundColor: props.color,
    '&:hover': {
      backgroundColor: props.hover
    }
  }),
  inputTextField: () => ({
    fontSize: 12,
    color: 'black',
    border: borderTheme,
    paddingLeft: 5,
    borderRadius: 4,
    marginBottom: 10
  }),
  textTypography: () => ({
    letterSpacing: 0,
    fontSize: 14,
    color: mainTextColor
  }),
  typographyContainer: () => ({
    height: 40,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 45
  }),
  textField: () => ({
    marginLeft: 10,
    width: 80,
    '& div': {
      height: 30
    },
    '& label': {
      fontSize: 12,
      transform: 'translate(8px, 10px) scale(1)'
    }
  })
};

export default makeStyles(styles);
