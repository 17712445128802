import React from 'react';
import { Box } from '@material-ui/core';

const OrderName = ({ name, withIngredient, withoutIngredient }) => {
  return (
    <Box style={{ paddingTop: 10, paddingBottom: 10 }}>
      <Box style={{ paddingLeft: 10 }}>{name}</Box>
      <Box style={{ paddingLeft: 10, color: 'green' }}>{withIngredient}</Box>
      <Box style={{ paddingLeft: 10, color: 'red' }}>{withoutIngredient}</Box>
    </Box>
  );
};

export default OrderName;
