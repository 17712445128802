import React from 'react';
import { Grid, Typography } from '@material-ui/core/';
import ItemsCarousel from 'react-items-carousel';
import { ReactComponent as ChairImg } from '../../assets/images/order/chair.svg';
import styles from '../../styles/order/chairsBox';
import { invertedPrimary } from '../../styles/theme';
import { MaterialIcon } from '../../common-fe';

const ChairCarousel = ({ fbChairs, setActiveItemIndex, activeItemIndex, handleSelectedChair }) => {
  const classes = styles();
  return (
    <ItemsCarousel
      infiniteLoop
      enablePlaceholder
      numberOfPlaceholderItems={5}
      minimumPlaceholderTime={1000}
      numberOfCards={4}
      activePosition="center"
      requestToChangeActive={(itemIndex) => setActiveItemIndex(itemIndex)}
      activeItemIndex={activeItemIndex}
      alwaysShowChevrons
      outsideChevron
      chevronWidth={20}
      leftChevron={<MaterialIcon iconName="chevron_left" style={{ color: invertedPrimary }} />}
      rightChevron={<MaterialIcon iconName="chevron_right" style={{ color: invertedPrimary }} />}
    >
      {fbChairs.map((chair) => (
        <Grid
          key={chair.id}
          container
          direction="column"
          alignItems="center"
          className={
            chair.isSelected ? [classes.chairBaseStyle, classes.selectedChair].join(' ') : [classes.chairBaseStyle, classes.chairStyle].join(' ')
          }
          onClick={() => handleSelectedChair(chair)}
        >
          <ChairImg className={classes.chairIcon} />
          <Typography className={classes.chairTypography}>{chair.number}</Typography>
        </Grid>
      ))}
    </ItemsCarousel>
  );
};

export default ChairCarousel;
