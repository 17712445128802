/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import typy from 'typy';
import { Box, Grid, Typography, Button } from '@material-ui/core/';
import { GET_MENU_CATEGORIES } from '../../graphql/menu/queries';
import { GET_TABLE_FB_ORDER } from '../../graphql/order/queries';
import { GET_FB_OUTLET } from '../../graphql/outlets/queries';
import ButtonMenu from './ButtonMenu';
import DishItem from './DishItem';
import { SearchBar, CustomButton, borderTheme } from '../../common-fe';
import { SET_FB_ORDER_MUTATION, PLAY_FB_COURSE, STOP_FB_COURSE, SET_FB_COURSE_STATUS, DELETE_FB_COURSE } from '../../graphql/order/mutations';
import { AuthContext } from '../../contexts/AuthContext';
import { OrderContext, initialState } from '../../contexts/OrderContext';
import { ModalContext } from '../../contexts/ModalContext';
import { TranslateTextComponent } from '../../translator';
import leftSidebarLayoutStyles from '../../styles/leftSidebarLayout/leftSidebarLayoutStyle';
import Circle from './Circle';
import FbCourseFood from './FbCourseFood';
import FbCourseDrink from './FbCourseDrink';
import { getApolloErrors } from '../../apollo/ApolloProvider';
import modalStyle from '../../common-fe/src/styles/shared/modalStyle';
import styles from '../../styles/order/orderStyle';
import FB_ORDER_STATUSES from '../../constants/order/orderStatuses';
import { ROUTE_TABLE_RESERVATION } from '../../router/routes';
import useCustomQuery from '../../hooks/useCustomQuery';

const Order = ({ fbTableUUID, history }) => {
  const classes = styles();
  const classesModal = modalStyle();

  const layoutClasses = leftSidebarLayoutStyles();

  const [changingFbCourseStatus, setChangingFbCourseStatus] = useState(null);
  const [filteredDishes, setFilteredDishes] = useState([]);

  const [showSummary, setShowSummary] = useState(false);
  const [editingIdFbMenuItem, setEditingIdFbMenuItem] = useState(null);
  const [oldIsWaiting, setOldIsWaiting] = useState(null);

  const modalContext = useContext(ModalContext);
  const { appOutletUUID } = useContext(AuthContext);

  const orderContext = useContext(OrderContext);
  const fbCourses = typy(orderContext, 'orderData.fbCourses').safeObject;
  const fbReservation = typy(orderContext, 'fbReservationTable.fbReservation').safeObject;
  const fbTableNumber = typy(orderContext, 'fbReservationTable.fbTable.number').safeObject;

  const [editFbOrder, { loading: mutationLoading }] = useMutation(SET_FB_ORDER_MUTATION);
  const [playFbCourse] = useMutation(PLAY_FB_COURSE);
  const [stopFbCourse] = useMutation(STOP_FB_COURSE);
  const [setFbCourseStatus] = useMutation(SET_FB_COURSE_STATUS);
  const [deleteFbCourse] = useMutation(DELETE_FB_COURSE);

  useEffect(() => {
    return () => orderContext.resetContext();
  }, []);
  const getTableFbOrder = useCustomQuery(GET_TABLE_FB_ORDER, {
    variables: { fbTableUUID },
    onCompleted: (data) => {
      if (data && data.getTableFbOrder) {
        const newFbCourses = [...data.getTableFbOrder.fbCourses];

        newFbCourses.map((fbCourse) => {
          const newMenuItems = fbCourse.fbCoursesMenuItems.filter((item) => item.id !== undefined);
          fbCourse.fbCoursesMenuItems = newMenuItems;
        });

        data.getTableFbOrder.fbCourses = newFbCourses;
        const initialResultFbOrder = JSON.parse(JSON.stringify(data.getTableFbOrder));
        orderContext.updateOrderContext({ ...data.getTableFbOrder });
        orderContext.updateInitialOrderContext(initialResultFbOrder);
      }
    },
    fetchPolicy: 'network-only'
  });

  const handleSetChangingFbCourseStatus = (expansionKey) => {
    setChangingFbCourseStatus(expansionKey === changingFbCourseStatus ? null : expansionKey);
  };

  const refetchFbOrder = () => {
    getTableFbOrder.refetch().then((res) => {
      orderContext.updateOrderContext({ ...res.data.getTableFbOrder });
    });
  };

  const getPhysicalCategories = useCustomQuery(GET_FB_OUTLET, {
    variables: { fbOutletUUID: appOutletUUID }
  });

  const getMenuCategories = useCustomQuery(GET_MENU_CATEGORIES, {
    variables: { fbOutletUUID: appOutletUUID },
    fetchPolicy: 'network-only'
  });

  const categories = typy(getMenuCategories, 'data.getFbMenuCategories.data').safeArray.sort((prev, next) => {
    if (prev.order < next.order) {
      return -1;
    }
    if (prev.order > next.order) {
      return 1;
    }
    return 0;
  });

  const expandableButtons = () => {
    const compare = (a, b) => {
      if (a.type === b.type) {
        return a.number - b.number;
      }
      return a.type < b.type ? 1 : -1;
    };

    let fbCoursesDrink = null;
    let fbCoursesFood = null;
    if (fbCourses) {
      fbCourses.sort(compare);
      fbCoursesFood = fbCourses.filter((fbCourse) => {
        return fbCourse.type === 'food';
      });
      fbCoursesDrink = fbCourses.filter((fbCourse) => {
        return fbCourse.type === 'drink';
      });
    }

    const onSetFbCourseStatus = (fbCourseUUID, fbCourseStatus) => {
      setFbCourseStatus({
        variables: {
          fbCourseUUID,
          fbCourseStatus
        }
      })
        .then((res) => {
          orderContext.updateOrderContext({ ...res.data.setFbCourseStatus });
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: 'Error',
            text: getApolloErrors(err).join(' - ')
          });
        });
    };

    return (
      <Box style={{ gridArea: 'center', maxHeight: '70vh', marginTop: 20, overflowY: 'scroll' }}>
        {fbCoursesFood &&
          fbCoursesFood.map((fbCourse, index) => {
            const expansionKey = `${fbCourse.type}${fbCourse.number}`;
            return (
              <FbCourseFood
                key={`food${index}`}
                fbCourse={fbCourse}
                expanded={
                  (showSummary && fbCourse.fbCourseStatus !== FB_ORDER_STATUSES.completed.label) || orderContext.activeFbCourseKey === expansionKey
                }
                expansionKey={expansionKey}
                changingFbCourseStatus={changingFbCourseStatus}
                handleSetChangingFbCourseStatus={handleSetChangingFbCourseStatus}
                notShowSummary={notShowSummary}
                onClick={() => setEditingIdFbMenuItem(false)}
                onEditFbMenuItem={(menuItem) => onEditFbMenuItem(menuItem)}
                onDeleteFbMenuItem={(menuItem) => onDeleteFbMenuItem(menuItem)}
                onSaveFbCourse={(fbCourse) => (fbCourse.fbCourseStatus === FB_ORDER_STATUSES.none.label ? sendFbOrder() : onSaveFbCourse(fbCourse))}
                onEditFbCourse={(fbCourse) => onEditFbCourse(fbCourse)}
                onRestoreFbCourse={(fbCourse) => onRestoreFbCourse(fbCourse)}
                onChangePanel={() => orderContext.updateActiveFbCourseKey(expansionKey)}
                onSetFbCourseStatus={(fbCourseUUID, status) => onSetFbCourseStatus(fbCourseUUID, status)}
                onDeleteCourse={() => onDeleteCourse(fbCourse.uuid)}
                onPlayFbCourse={() => onPlayFbCourse(fbCourse.uuid)}
                onStopFbCourse={() => onStopFbCourse(fbCourse.uuid)}
              />
            );
          })}
        {fbCoursesDrink && fbCoursesDrink.length > 0 && (
          <FbCourseDrink
            key="drink"
            fbCoursesDrink={fbCoursesDrink}
            expanded={showSummary || orderContext.activeFbCourseKey === 'drink'}
            expansionKey="drink"
            changingFbCourseStatus={changingFbCourseStatus}
            handleSetChangingFbCourseStatus={handleSetChangingFbCourseStatus}
            notShowSummary={notShowSummary}
            onClick={() => setEditingIdFbMenuItem(false)}
            onEditFbMenuItem={(menuItem) => onEditFbMenuItem(menuItem)}
            onDeleteFbMenuItem={(menuItem) => onDeleteFbMenuItem(menuItem)}
            onSaveFbCourse={(fbCourse) => onSaveFbCourse(fbCourse)}
            onEditFbCourse={(fbCourse) => onEditFbCourse(fbCourse)}
            onRestoreFbCourse={(fbCourse) => onRestoreFbCourse(fbCourse)}
            onChangePanel={() => orderContext.updateActiveFbCourseKey('drink')}
            onSetFbCourseStatus={(fbCourseUUID, status) => onSetFbCourseStatus(fbCourseUUID, status)}
            onDeleteCourse={(uuid) => onDeleteCourse(uuid)}
            onPlayFbCourse={(uuid) => onPlayFbCourse(uuid)}
            onStopFbCourse={(uuid) => onStopFbCourse(uuid)}
          />
        )}
      </Box>
    );
  };

  const onEditFbMenuItem = (menuItem) => {
    if (menuItem.fbChair) {
      menuItem.fbChairNumber = menuItem.fbChair.number;
      menuItem.fbChairUUID = menuItem.fbChair.uuid;
    }
    if (editingIdFbMenuItem === menuItem.fbMenuItemId) {
      setEditingIdFbMenuItem(null);
      orderContext.updateIsEditingFbCourse(false);
    } else {
      setEditingIdFbMenuItem(menuItem.fbMenuItemId);

      orderContext.updateSelectedCategory(menuItem.fbMenuItem.fbMenuCategoryId);
      orderContext.updateSelectedDish(menuItem.fbMenuItem.id);
      orderContext.saveMenuItemToEdit(menuItem);

      orderContext.updateChairOrder(menuItem);
      orderContext.updateIsEditingFbCourse(true);
    }
  };
  const onDeleteFbMenuItem = (menuItem) => {
    if (editingIdFbMenuItem === menuItem.fbMenuItemId) {
      setEditingIdFbMenuItem(null);
      orderContext.updateIsEditingFbCourse(false);
    } else {
      setEditingIdFbMenuItem(menuItem.fbMenuItemId);
      orderContext.deleteFbMenuItem(menuItem);
      orderContext.updateSelectedDish(null);
    }
  };

  const onEditFbCourse = (fbCourse) => {
    setOldIsWaiting(fbCourse.isWaiting);
    if (fbCourse.id && fbCourse.fbCourseStatus !== FB_ORDER_STATUSES.none.label && !fbCourse.isWaiting) {
      onStopFbCourse(fbCourse.uuid);
    }
    orderContext.updateOldCourse([...fbCourse.fbCoursesMenuItems]);
    orderContext.updateIsEditingFbCourse(!orderContext.isEditingCourse);
  };

  const isShowSummary = () => {
    setShowSummary(true);
    orderContext.updateActiveFbCourseKey(null);
    orderContext.updateIsEditingFbCourse(null);
  };
  const notShowSummary = () => {
    setShowSummary(false);
  };

  const openModalToSendFbOrder = () => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>send-fb-order-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>send-fb-order-question</TranslateTextComponent>,

      actionButtons: [
        <Button key={1} variant="outlined" color="primary" className={classesModal.invertedButton} onClick={modalContext.closeModal}>
          <TranslateTextComponent>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={2}
          color="primary"
          variant="contained"
          className={classesModal.buttonStyle}
          onClick={() => {
            modalContext.closeModal();
            sendFbOrder();
          }}
        >
          <TranslateTextComponent>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const onDeleteCourse = (uuid) => {
    deleteFbCourse({
      variables: {
        fbCourseUUID: uuid
      }
    })
      .then((response) => {
        orderContext.updateOrderContext(response.data.deleteFbCourse);
        orderContext.updateCompareOrderContext(true);
        orderContext.updateActiveFbCourseKey(null);
        orderContext.updateIsEditingFbCourse(false);
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const onStopFbCourse = (uuid) => {
    stopFbCourse({
      variables: {
        fbCourseUUID: uuid
      }
    })
      .then((res) => {
        // orderContext.closeIsEditing();
        // orderContext.updateActiveFbCourseKey(orderContext.findKeyOfFirstFoodEmptyCourse());
        orderContext.updateOrderContext({ ...res.data.stopFbCourse });
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const onPlayFbCourseMutation = (uuid) => {
    return playFbCourse({
      variables: {
        fbCourseUUID: uuid
      }
    });
  };

  const onPlayFbCourse = (uuid) => {
    onPlayFbCourseMutation(uuid)
      .then(() => {
        sendFbOrder();
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const onSaveFbCourse = (fbCourse) => {
    if (!oldIsWaiting && fbCourse.id) {
      onPlayFbCourseMutation(fbCourse.uuid)
        .then(() => {
          sendFbOrder();
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        });
    } else if (fbCourse.isWaiting) {
      sendFbOrder();
    }
    // orderContext.updateIsEditingFbCourse(!orderContext.isEditingCourse);
    // orderContext.updateActiveFbCourseKey(null);

    // if (orderContext.isEditingCourse === false) {
    //   orderContext.updateSelectedCategory(null);
    //   orderContext.updateSelectedDish(null);
    //   orderContext.updateChairOrder(null);
    // }
  };

  const onRestoreFbCourse = (fbCourse) => {
    if (!oldIsWaiting && fbCourse.id && fbCourse.fbCourseStatus !== FB_ORDER_STATUSES.none.label) {
      onPlayFbCourseMutation(fbCourse.uuid)
        .then((res) => {
          orderContext.updateOrderContext({ ...res.data.playFbCourse });
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        });
    }
    orderContext.restoreOldCourse();
  };

  const sendFbOrder = () => {
    const newFbCourses = orderContext.orderData.fbCourses.map((fbCourse) => {
      const newFbCourse = {};
      newFbCourse.fbCourseStatus = fbCourse.fbCourseStatus;
      newFbCourse.id = fbCourse.id;
      newFbCourse.number = fbCourse.number;
      newFbCourse.type = fbCourse.type;
      newFbCourse.fbCoursesMenuItems = fbCourse.fbCoursesMenuItems.map((fbMenuItem) => {
        const newFbMenuItem = {};
        newFbMenuItem.name = fbMenuItem.name;
        newFbMenuItem.uuid = fbMenuItem.uuid;
        newFbMenuItem.quantity = fbMenuItem.quantity;
        newFbMenuItem.fbMenuItemId = fbMenuItem.fbMenuItemId;
        newFbMenuItem.fbChairId = fbMenuItem.fbChairId;
        newFbMenuItem.centerpiece = fbMenuItem.centerpiece;
        newFbMenuItem.halfPortion = fbMenuItem.halfPortion;
        newFbMenuItem.notes = fbMenuItem.notes;
        newFbMenuItem.sharedBy = fbMenuItem.sharedBy;
        newFbMenuItem.with = fbMenuItem.with;
        newFbMenuItem.without = fbMenuItem.without;

        return newFbMenuItem;
      });
      return newFbCourse;
    });
    editFbOrder({
      variables: {
        fbTableUUID,
        fbOrderData: {
          fbCourses: newFbCourses
        }
      }
    })
      .then((response) => {
        orderContext.closeIsEditing();

        orderContext.updateOrderContext(response.data.setFbOrder);

        notShowSummary();
        modalContext.openModal({
          class: 'success',
          title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
          text: <TranslateTextComponent capitalize>send-fb-order-success</TranslateTextComponent>,
          actionButtons: [
            <Button
              key={0}
              color="primary"
              variant="contained"
              className={classesModal.buttonStyle}
              onClick={() => {
                modalContext.closeModal();
              }}
            >
              <TranslateTextComponent>order-dashboard</TranslateTextComponent>
            </Button>,
            <Button
              key={1}
              color="primary"
              variant="contained"
              className={classesModal.buttonStyle}
              onClick={() => {
                modalContext.closeModal();
                history.push(`${ROUTE_TABLE_RESERVATION}`);
              }}
            >
              <TranslateTextComponent>panoramic-tables</TranslateTextComponent>
            </Button>
          ]
        });
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  useEffect(() => {
    updateFilteredDishes();
  }, [orderContext.searchedDish, orderContext.fbSelectedCategoryId]);

  const updateFilteredDishes = () => {
    let newFilteredDish = [];
    const filteredByMacroCategory = categories.filter((category) => category.fbMenuMacrocategory.name === orderContext.fbMacroCategory);

    const newFbMenuItemsWithColor = filteredByMacroCategory.map((category) =>
      category.fbMenuItems
        .sort((prev, next) => {
          if (prev.name < next.name) {
            return -1;
          }
          if (prev.name > next.name) {
            return 1;
          }
          return 0;
        })
        .map((fbItem) => {
          const newFbItem = { ...fbItem };
          newFbItem.color = category.color;
          return newFbItem;
        })
    );

    newFbMenuItemsWithColor.forEach((category) => category.forEach((fbItem) => newFilteredDish.push(fbItem)));
    if (orderContext.fbSelectedCategoryId) {
      newFilteredDish = newFilteredDish.filter((item) => item.fbMenuCategoryId === orderContext.fbSelectedCategoryId);
    }

    if (orderContext.searchedDish) {
      newFilteredDish = newFilteredDish.filter((item) => item.name.toLowerCase().includes(orderContext.searchedDish.toLowerCase()));
    }
    setFilteredDishes(newFilteredDish);
  };

  const mainContent = () => {
    const selectedCategory = categories.find((category) => category.id === orderContext.fbSelectedCategoryId);
    let categoriesFiltered = [];
    if (orderContext.fbMacroCategory === 'food') {
      categoriesFiltered = categories.filter((macroCategory) => macroCategory.fbMenuMacrocategory.name === 'food');
    } else if (orderContext.fbMacroCategory === 'drink') {
      categoriesFiltered = categories.filter((macroCategory) => macroCategory.fbMenuMacrocategory.name === 'drink');
    } else categoriesFiltered = [];
    if (categoriesFiltered.length > 0) {
      return (
        <Box className={classes.mainContainer} style={{ position: 'relative' }}>
          <Box className={classes.topGridArea} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box style={{ width: '30%' }}>
              {orderContext.fbSelectedCategoryId !== null && (
                <ButtonMenu
                  selectedCategory={selectedCategory}
                  onClick={() => {
                    orderContext.updateSearchedDish('');
                    orderContext.updateSelectedDish(null);
                    orderContext.updateSelectedCategory(null);
                  }}
                />
              )}
            </Box>
            {orderContext.activeFbCourseKey && (
              <Box style={{ width: '30%' }}>
                <SearchBar
                  style={{ height: 40 }}
                  onClickIcon={() => orderContext.updateSearchedDish('')}
                  value={orderContext.searchedDish || ''}
                  onChange={(e) => orderContext.updateSearchedDish(e.target.value)}
                />
              </Box>
            )}
          </Box>
          {orderContext.fbSelectedCategoryId === null && orderContext.searchedDish === '' && orderContext.activeFbCourseKey ? (
            <Grid
              container
              spacing={5}
              style={{
                marginTop: 30,
                gridArea: 'center',
                maxHeight: '70vh',
                overflowY: 'auto'
              }}
            >
              {categoriesFiltered.map((category) => (
                <Grid key={category.id} item xs={6} md={6} lg={4}>
                  <ButtonMenu id={category.id} selectedCategory={category} onClick={() => orderContext.updateSelectedCategory(category.id)} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box
              style={{
                marginTop: 30,
                gridArea: 'center',
                maxHeight: '70vh',
                overflowY: orderContext.fbSelectedDish ? 'hidden' : 'scroll'
              }}
              onClick={() => {
                orderContext.updateSelectedDish(null);
                orderContext.updateChairOrder(initialState);
              }}
            >
              {orderContext.activeFbCourseKey &&
                orderContext.orderData.fbCourses[orderContext.getFbCourseActiveIndex()].fbCourseStatus !== FB_ORDER_STATUSES.completed.label && (
                  <Grid container spacing={5} style={{ width: '100%' }}>
                    {filteredDishes
                      .filter((hiddenDish) => hiddenDish.isHidden === false)
                      .map((dish, index) => (
                        <Grid key={`dish${index}`} item xs={6} md={2} lg={2}>
                          <DishItem
                            index={index}
                            dishKey={dish.id}
                            color={dish.color}
                            selectedDish={orderContext.fbSelectedDish}
                            dish={dish}
                            physicalCategories={typy(getPhysicalCategories, 'data.getFbOutlet.guestPhysicalCategories').safeArray}
                            refetchFbOrder={refetchFbOrder}
                            onClick={() => {
                              orderContext.updateIsEditingFbCourse(false);
                              if (dish.id === orderContext.fbSelectedDish) {
                                orderContext.updateSelectedDish(null);
                              } else {
                                orderContext.updateSelectedDish(dish.id);
                              }
                            }}
                          />
                        </Grid>
                      ))}
                  </Grid>
                )}
            </Box>
          )}
          {(orderContext.fbSelectedCategoryId !== null || orderContext.searchedDish !== '') && (
            <Grid container justify="space-around" alignItems="center" style={{ gridArea: 'bottom' }}>
              {categoriesFiltered.map((category) => (
                <Grid key={`${category.id}circle`}>
                  <Circle category={category} onClick={() => orderContext.updateSelectedCategory(category.id)} />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      );
    }
    return null;
  };

  return (
    <Box className={layoutClasses.layoutContainer}>
      <Box className={layoutClasses.leftSide}>
        <Box className={classes.mainContainer}>
          <Box className={classes.topGridArea} style={{ borderBottom: borderTheme }}>
            <Grid container>
              <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.nameTypography}>
                  {fbReservation && fbReservation.bookedBy.firstname}
                  &nbsp;
                  {fbReservation && fbReservation.bookedBy.lastname}
                </Typography>
              </Grid>
              <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Typography>
                  <TranslateTextComponent capitalize>table</TranslateTextComponent>:
                </Typography>
                <Typography className={classes.boldTypography}>
                  &nbsp;
                  {fbTableNumber}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                <Typography>
                  <TranslateTextComponent capitalize>room</TranslateTextComponent>:
                </Typography>
                <Typography className={classes.boldTypography}>
                  &nbsp;
                  {fbReservation && fbReservation.guestRoom ? fbReservation.guestRoom : 'ND'}
                </Typography>
              </Grid>
              <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Typography>
                  <TranslateTextComponent capitalize>amenities</TranslateTextComponent>:
                </Typography>
                <Typography className={classes.boldTypography}>&nbsp;ND</Typography>
              </Grid>
            </Grid>
          </Box>

          {expandableButtons()}

          {!orderContext.compareOrderContext && (
            <Box style={{ gridArea: 'bottom', display: 'flex' }}>
              <CustomButton isLoading={mutationLoading} onClick={openModalToSendFbOrder} translateText style={{ margin: 5, lineHeight: 1 }}>
                <TranslateTextComponent>send-order</TranslateTextComponent>
              </CustomButton>
              {!showSummary && (
                <CustomButton onClick={isShowSummary} translateText style={{ margin: 5 }}>
                  <TranslateTextComponent>show-summary</TranslateTextComponent>
                </CustomButton>
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Box className={layoutClasses.rightSide} style={{ padding: '0 50px' }}>
        {orderContext &&
          orderContext.orderData &&
          orderContext.orderData.fbCourses[orderContext.getFbCourseActiveIndex()] &&
          orderContext.orderData.fbCourses[orderContext.getFbCourseActiveIndex()].fbCourseStatus !== FB_ORDER_STATUSES.completed.label &&
          mainContent()}
      </Box>
    </Box>
  );
};

export default Order;
