import { Button, CircularProgress } from '@material-ui/core';
import React from 'react';
import styles from '../../styles/shared/customButton';

const CustomButton = ({ isLoading, children, img, disabled, style, onClick }) => {
  const classes = styles();

  return (
    <Button variant="contained" color="primary" className={classes.customButton} disabled={disabled} style={{ ...style }} onClick={onClick}>
      {img}
      {isLoading ? <CircularProgress style={{ color: 'white' }} /> : children}
    </Button>
  );
};

export default CustomButton;
