import React, { useState, useContext } from 'react';

import { Box, Paper, Grid, Modal, Fade } from '@material-ui/core/';

import { LanguageContext } from '../../../contexts/LanguageContext';

import getAssignChairColumns from '../../../constants/assignChair/assignChairColumns';
import { TranslateTextComponent, capitalizeFirstLetter, translate } from '../../../translator';

import PrimaryButton from '../../saveReservation/PrimaryButton';
import { SearchBar, CommonTable } from '../../../common-fe';
import GuestDetails from './GuestDetails';

import assignChairStyle from '../../../styles/table/tableDetail/assignChair';

const buttonClose = <TranslateTextComponent capitalize>close</TranslateTextComponent>;
const buttonAssign = <TranslateTextComponent capitalize>assign-chair</TranslateTextComponent>;

const SearchTable = ({ guestsData, openModalSearch, onClose, updateQueryFilters, queryFilters, handleAssignChair, setTablePages, totalCount }) => {
  const classes = assignChairStyle();

  const [guestChecked, setGuestChecked] = useState(null);

  const languageContext = useContext(LanguageContext);

  const handleGuestChecked = (uuid) => {
    if (guestChecked === uuid) {
      setGuestChecked(false);
    } else {
      setGuestChecked(uuid);
    }
  };

  const handleGuestClicked = (booleanValue, uuid) => {
    return uuid === guestChecked ? setGuestChecked(false) : null;
  };

  const getTableHeader = () => {
    return (
      <Grid container justify="center" className={classes.headerContainer}>
        <Box style={{ width: '75%' }}>
          <SearchBar
            style={{ height: 40, fontSize: 12 }}
            onChange={(e) => updateQueryFilters('keyword', e.target.value)}
            value={queryFilters.keyword}
            placeholder={capitalizeFirstLetter(translate('search', languageContext.language))}
          />
        </Box>
      </Grid>
    );
  };

  const columns = getAssignChairColumns({
    guestsData,
    handleGuestClicked,
    handleGuestChecked,
    setGuestChecked,
    guestChecked
  });

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Modal style={{ borderRadius: 20, width: '80%' }} open={openModalSearch} onClose={onClose} key="Modal">
        <Fade
          in
          style={{
            width: '90%',
            borderRadius: 20,
            marginLeft: '20%',
            marginRight: '20%',
            marginTop: 100
          }}
        >
          <Paper className={classes.paperStyle}>
            {getTableHeader()}
            <Grid item style={{ maxHeight: 350, overflow: 'auto', paddingRight: 5, paddingLeft: 5 }}>
              <CommonTable
                items={guestsData}
                columns={columns}
                page={queryFilters.page}
                pageSize={queryFilters.pageSize}
                setTablePages={setTablePages}
                totalRows={guestsData && totalCount}
                isPaginationEnabled
                rowCellStyle={{ padding: 0 }}
                renderDetailsComponent={[
                  {
                    buttonLabel: <TranslateTextComponent>details</TranslateTextComponent>,
                    renderFunction: (row) => <GuestDetails item={row} />
                  }
                ]}
              />
            </Grid>
            <Grid container justify="space-between" className={classes.gridButton}>
              <PrimaryButton name={buttonClose} onClick={onClose} />
              <PrimaryButton name={buttonAssign} onClick={() => handleAssignChair(guestChecked, null)} />
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </Box>
  );
};
export default SearchTable;
