import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Box, Tabs, Tab, Button } from '@material-ui/core';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { AppOutletContext } from '../../contexts/AppOutletContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import { ModalContext } from '../../contexts/ModalContext';
import BillsList from './BillsList/BillsList';
import BankCheck from './BankCheck/BankCheck';
import CloseOut from './CloseOut/CloseOut';
import DailyCloseOut from './DailyCloseOut/DailyCloseOut';
import Invoices from './Invoices/Invoices';
import PM from './PM/PM';
import FiscalPrinter from '../../common-fe/src/FiscalPrinter/index.ts';
import * as tabs from '../../constants/billsManagement/tabs';
import * as paymentMethods from '../../constants/billsManagement/paymentMethods';
import { translate, TranslateTextComponent } from '../../translator';
import modalStyle from '../../styles/shared/modalStyle';
import styles from '../../styles/billsManagement/billsManagement';

import {
  GET_FB_BILLS,
  GET_PAYMENT_METHODS,
  GET_FB_DAILY_CLOSURES,
  GET_FB_ACCOUNTING_CLOSURES,
  GET_FB_BILLS_TOTAL
} from '../../graphql/billsManagement/queries';
import { CONFIRM_FB_BILL_PAYMENT, REGISTER_FB_CLOSURE } from '../../graphql/billsManagement/mutations';
import { GET_PAY_MASTERS } from '../../graphql/cash/queries';
import { CANCEL_FB_BILL } from '../../graphql/cash/mutations';
import { getApolloErrors } from '../../apollo/ApolloProvider';
import { ROUTE_CASH } from '../../router/routes';
import useCustomQuery from '../../hooks/useCustomQuery';
import { AuthContext } from '../../contexts/AuthContext';

const DEFAULT_FILTERS_VALUES = {
  paymentMethodCodes: [],
  date: null,
  month: null,
  year: null,
  keyword: null,
  page: 1,
  pageSize: 10
};

const BillsManagement = () => {
  // States
  const [currentTab, setCurrentTab] = useState(tabs.BILLS_LIST);
  const [filters, setFilters] = useState(DEFAULT_FILTERS_VALUES);
  const [filterDate, setFilterDate] = useState(null);

  // Contexts
  const { language } = useContext(LanguageContext);
  const modalContext = useContext(ModalContext);
  const { appOutletUUID } = useContext(AuthContext);
  const {
    appOutlet: { fiscalPrinterIpAddress, useFiscalPrinter }
  } = useContext(AppOutletContext);
  const fiscalPrinterMode = process.env.REACT_APP_ENV === 'PROD' && useFiscalPrinter ? 'production' : 'test';

  const fiscalPrinter = new FiscalPrinter(fiscalPrinterIpAddress, fiscalPrinterMode);

  const history = useHistory();
  const classes = styles();

  const [confirmFbBillPayment] = useMutation(CONFIRM_FB_BILL_PAYMENT);
  const [cancelFbBill] = useMutation(CANCEL_FB_BILL);
  const [registerFbClosures] = useMutation(REGISTER_FB_CLOSURE);

  const modalClasses = modalStyle();

  // Queries
  const getPaymentMethods = useCustomQuery(GET_PAYMENT_METHODS, { fetchPolicy: 'network-only' });

  const getBills = useCustomQuery(GET_FB_BILLS, {
    variables: {
      fbOutletUUID: appOutletUUID,
      paymentMethodCodes: filters.paymentMethodCodes.length > 0 ? filters.paymentMethodCodes : null,
      documentNumber: filters.documentNumber,
      date: filters.date,
      month: filters.month,
      year: filters.year,
      status: filters.status,
      userKeyword: filters.userUUID,
      keyword: filters.keyword,
      page: filters.page,
      pageSize: filters.pageSize
    },
    fetchPolicy: 'network-only',
    skip: ![tabs.BILLS_LIST, tabs.BANK_CHECK_CREDIT_TRANSFERS].some((tab) => tab === currentTab)
  });

  const getFbBillsTotal = useCustomQuery(GET_FB_BILLS_TOTAL, {
    variables: {
      fbOutletUUID: appOutletUUID,
      paymentMethodCodes: filters.paymentMethodCodes.length > 0 ? filters.paymentMethodCodes : null,
      documentNumber: filters.documentNumber,
      date: filters.date,
      month: filters.month,
      year: filters.year,
      status: 'complete',
      userKeyword: filters.userUUID,
      keyword: filters.keyword
    },
    fetchPolicy: 'network-only',
    skip: ![tabs.BILLS_LIST, tabs.BANK_CHECK_CREDIT_TRANSFERS].some((tab) => tab === currentTab)
  });

  const getDailyClosures = useCustomQuery(GET_FB_DAILY_CLOSURES, {
    variables: {
      fbOutletUUID: appOutletUUID,
      date: filters.date,
      month: filters.month,
      year: filters.year,
      keyword: filters.keyword,
      page: filters.page,
      pageSize: filters.pageSize
    },
    fetchPolicy: 'network-only',
    skip: currentTab !== tabs.DAILY_CLOSE_OUT
  });

  const getFbAccountingClosures = useCustomQuery(GET_FB_ACCOUNTING_CLOSURES, {
    variables: {
      fbOutletUUID: appOutletUUID,
      date: filters.date,
      month: filters.month,
      year: filters.year,
      keyword: filters.keyword,
      page: filters.page,
      pageSize: filters.pageSize
    },
    fetchPolicy: 'network-only',
    skip: currentTab !== tabs.CLOSE_OUT_OF_CASH
  });

  const getPayMasters = useCustomQuery(GET_PAY_MASTERS, {
    variables: {
      date: filters.date,
      month: filters.month,
      year: filters.year,
      keyword: filters.keyword,
      page: filters.page,
      pageSize: filters.pageSize
    },
    fetchPolicy: 'network-only',
    skip: ![tabs.PM].some((tab) => tab === currentTab)
  });

  const refetchGetPayMaster = () => {
    getPayMasters.refetch();
  };

  const onTabChangeHandler = (value) => {
    if (value === tabs.BILLS_LIST)
      setFilters({
        ...DEFAULT_FILTERS_VALUES
      });
    if (value === tabs.BANK_CHECK_CREDIT_TRANSFERS)
      setFilters({
        ...DEFAULT_FILTERS_VALUES,
        paymentMethodCodes: [paymentMethods.BANK_CHECK, paymentMethods.BANK_TRANSFER],
        status: 'complete'
      });
    if (value === tabs.PM) {
      setFilters({ ...DEFAULT_FILTERS_VALUES });
    }
    setCurrentTab(value);
  };

  const onDateChangeHandler = (value, monthly) => {
    setFilterDate(null);
    if (monthly) {
      const date = value.split('-');
      setFilters((prev) => ({ ...prev, date: null, year: date[0], month: date[1] }));
    } else setFilters((prev) => ({ ...prev, date: value }));
  };

  const onFilterChangeHandler = (type, value) => {
    if (type === 'client') {
      setFilters((prev) => ({ ...prev, keyword: value }));
    } else if (type === 'date') {
      setFilterDate(moment(value).format('DD-MM-YYYY'));
      setFilters((prev) => ({ ...prev, date: moment(value).format('YYYY-MM-DD') }));
    } else if (type === 'document') setFilters((prev) => ({ ...prev, documentNumber: value }));
    else if (type === 'operator') setFilters((prev) => ({ ...prev, userUUID: value }));
    else if (type === 'status') setFilters((prev) => ({ ...prev, status: prev.status === 'complete' ? 'open' : 'complete' }));
  };

  const onCheckBoxFilterChangeHandler = ({ type, paymentMethodCode, pmDuration }) => {
    if (type === 'code' && paymentMethodCode !== 'all') {
      setFilters((prev) => ({ ...prev, paymentMethodCodes: [paymentMethodCode] }));
    } else {
      setFilters((prev) => ({ ...prev, paymentMethodCodes: [] }));
    }
    // TODO
    // if (type === 'duration' && paymentMethodCode !== 'all') {
    //   setFilters((prev) => ({ ...prev, pmDuration: [...prev.paymentMethodCodes, paymentMethodCode] }));
    // } else {
    //   setFilters((prev) => ({ ...prev, pmDuration: [] }));
    // }
  };

  const onConfirmFbBillPayment = (fbBillUUID, isConfirmed) => {
    confirmFbBillPayment({
      variables: {
        fbBillUUID,
        isConfirmed
      }
    })
      .then(() => {
        getBills.refetch();
        modalContext.openModal({
          class: 'success',
          title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
          text: <TranslateTextComponent capitalize>payment-confirmed</TranslateTextComponent>
        });
      })
      .catch((err) => {
        getBills.refetch();
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const onConfirmCancelBill = (fbBill) => {
    fiscalPrinter
      .cancelFiscalReceipt(fbBill.documentNumber, moment(fbBill.paymentDatetime).format('DD-MM-YYYY'))
      .then((res) => {
        if (res && res._attributes && res._attributes.success === 'true') {
          cancelFbBill({
            variables: {
              fbBillUUID: fbBill.uuid
            }
          })
            .then(() => {
              getBills.refetch();
              modalContext.openModal({
                class: 'success',
                title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
                text: <TranslateTextComponent capitalize>cancelled-confirmed</TranslateTextComponent>,
                actionButtons: [
                  <Button
                    key={0}
                    variant="outlined"
                    color="primary"
                    className={modalClasses.invertedButton}
                    onClick={() => {
                      modalContext.closeModal();
                    }}
                  >
                    OK
                  </Button>,
                  <Button
                    key={1}
                    variant="contained"
                    color="primary"
                    className={modalClasses.buttonStyle}
                    onClick={() => {
                      modalContext.closeModal();
                      history.push(ROUTE_CASH);
                    }}
                  >
                    <TranslateTextComponent uppercase>cash</TranslateTextComponent>
                  </Button>
                ]
              });
            })
            .catch((err) => {
              getBills.refetch();
              modalContext.openModal({
                class: 'danger',
                title: <TranslateTextComponent capitalize>Server error</TranslateTextComponent>,
                text: getApolloErrors(err).join(' - ')
              });
            });
        } else {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: `Error from fiscalPrinter (${res._attributes.code}: ${res._attributes.status})`
          });
        }
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: `Unable to send data to fiscal printer ${JSON.stringify(err)}`
        });
      });
  };

  const onRegisterFbDailyClosure = () => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>confirm</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>confirm-closure</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={1}
          variant="contained"
          color="primary"
          className={modalClasses.buttonStyle}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            fiscalPrinter
              .printZReport()
              .then((res) => {
                if (res && res._attributes && res._attributes.success === 'true') {
                  const { addInfo } = res;
                  registerFbClosures({
                    variables: {
                      fbOutletUUID: appOutletUUID,
                      date: moment().format('YYYY-MM-DD'),
                      zRepNumber: addInfo.zRepNumber._text,
                      fiscalPrinterAmount: parseFloat(addInfo.dailyAmount._text.replace(',', '.').replace(' ', ''))
                    }
                  })
                    .then(() => {
                      getBills.refetch();
                      modalContext.openModal({
                        class: 'success',
                        title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
                        text: <TranslateTextComponent capitalize>closure-confirmed</TranslateTextComponent>
                      });
                    })
                    .catch((err) => {
                      getBills.refetch();
                      modalContext.openModal({
                        class: 'danger',
                        title: <TranslateTextComponent capitalize>Server error</TranslateTextComponent>,
                        text: getApolloErrors(err).join(' - ')
                      });
                    });
                } else {
                  modalContext.openModal({
                    class: 'danger',
                    title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                    text: `Error from fiscalPrinter (${res._attributes.code}: ${res._attributes.status})`
                  });
                }
              })
              .catch((err) => {
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: `Unable to send data to fiscal printer ${JSON.stringify(err)}`
                });
              });
          }}
        >
          OK
        </Button>
      ]
    });
  };

  const onPageSizeChangeHandler = (value) => {
    setFilters((prev) => ({ ...prev, pageSize: value }));
  };

  const onPageChangeHandler = (value) => {
    setFilters((prev) => ({ ...prev, page: value + 1 }));
  };

  return (
    <Box className={classes.container}>
      <Tabs className={classes.tabsContainer} variant="fullWidth" value={currentTab} onChange={(e, value) => onTabChangeHandler(value)}>
        <Tab
          className={currentTab === tabs.BILLS_LIST ? classes.selectedTab : ''}
          label={translate(tabs.BILLS_LIST, language)}
          value={tabs.BILLS_LIST}
        />
        <Tab
          className={currentTab === tabs.BANK_CHECK_CREDIT_TRANSFERS ? classes.selectedTab : ''}
          label={translate(tabs.BANK_CHECK_CREDIT_TRANSFERS, language)}
          value={tabs.BANK_CHECK_CREDIT_TRANSFERS}
        />
        <Tab
          className={currentTab === tabs.CLOSE_OUT_OF_CASH ? classes.selectedTab : ''}
          label={translate(tabs.CLOSE_OUT_OF_CASH, language)}
          value={tabs.CLOSE_OUT_OF_CASH}
        />
        <Tab
          className={currentTab === tabs.DAILY_CLOSE_OUT ? classes.selectedTab : ''}
          label={translate(tabs.DAILY_CLOSE_OUT, language)}
          value={tabs.DAILY_CLOSE_OUT}
        />
        <Tab className={currentTab === tabs.INVOICES ? classes.selectedTab : ''} label={translate(tabs.INVOICES, language)} value={tabs.INVOICES} />
        <Tab className={currentTab === tabs.PM ? classes.selectedTab : ''} label={translate(tabs.PM, language)} value={tabs.PM} />
      </Tabs>
      <Box className={classes.content}>
        {currentTab === tabs.BILLS_LIST && (
          <BillsList
            isLoading={getBills.loading}
            data={getBills.data?.getFbBills?.data?.sort((a, b) => moment(a.datetime).isBefore(b.datetime)) ?? []}
            totalCount={getBills.data?.getFbBills?.totalCount ?? 0}
            totalAmount={getFbBillsTotal.data?.getFbBillsTotal?.sum ?? 0}
            page={filters.page}
            pageSize={filters.pageSize}
            filterDate={filterDate}
            paymentMethods={getPaymentMethods.data?.getFbPaymentMethods?.data ?? []}
            onDateChange={onDateChangeHandler}
            onFilterChange={onFilterChangeHandler}
            onCheckBoxFilterChange={onCheckBoxFilterChangeHandler}
            onPageSizeChange={onPageSizeChangeHandler}
            onPageChange={onPageChangeHandler}
            onRegisterFbDailyClosure={onRegisterFbDailyClosure}
            onConfirmCancelBill={onConfirmCancelBill}
          />
        )}
        {currentTab === tabs.BANK_CHECK_CREDIT_TRANSFERS && (
          <BankCheck
            isLoading={getBills.loading}
            data={getBills.data?.getFbBills?.data?.sort((a, b) => moment(a.datetime).isBefore(b.datetime)) ?? []}
            totalCount={getBills.data?.getFbBills?.totalCount ?? 0}
            totalAmount={getFbBillsTotal.data?.getFbBillsTotal?.sum ?? 0}
            page={filters.page}
            pageSize={filters.pageSize}
            filterDate={filterDate}
            paymentMethods={getPaymentMethods.data?.getFbPaymentMethods?.data ?? []}
            onDateChange={onDateChangeHandler}
            onFilterChange={onFilterChangeHandler}
            onCheckBoxFilterChange={onCheckBoxFilterChangeHandler}
            onPageSizeChange={onPageSizeChangeHandler}
            onConfirmFbBillPayment={onConfirmFbBillPayment}
            onPageChange={onPageChangeHandler}
          />
        )}
        {currentTab === tabs.CLOSE_OUT_OF_CASH && (
          <CloseOut
            isLoading={getFbAccountingClosures.loading}
            data={getFbAccountingClosures.data?.getFbAccountingClosures?.data?.sort((a, b) => moment(a.datetime).isBefore(b.datetime)) ?? []}
            totalCount={getFbAccountingClosures.data?.getFbAccountingClosures?.totalCount ?? 0}
            page={filters.page}
            pageSize={filters.pageSize}
            onDateChange={onDateChangeHandler}
            onFilterChange={onFilterChangeHandler}
            onPageSizeChange={onPageSizeChangeHandler}
            onPageChange={onPageChangeHandler}
          />
        )}
        {currentTab === tabs.DAILY_CLOSE_OUT && (
          <DailyCloseOut
            isLoading={getDailyClosures.loading}
            data={getDailyClosures.data?.getFbDailyClosures?.data?.sort((a, b) => moment(a.datetime).isBefore(b.datetime)) ?? []}
            totalCount={getDailyClosures.data?.getFbDailyClosures?.totalCount ?? 0}
            page={filters.page}
            pageSize={filters.pageSize}
            onDateChange={onDateChangeHandler}
            onFilterChange={onFilterChangeHandler}
            onPageSizeChange={onPageSizeChangeHandler}
            onPageChange={onPageChangeHandler}
          />
        )}
        {currentTab === tabs.INVOICES && (
          <Invoices
            isLoading={getBills.loading}
            paymentMethods={getPaymentMethods.data?.getFbPaymentMethods?.data.sort((a, b) => moment(a.datetime).isBefore(b.datetime)) ?? []}
            onDateChange={onDateChangeHandler}
            onFilterChange={onFilterChangeHandler}
          />
        )}
        {currentTab === tabs.PM && (
          <PM
            isLoading={getPayMasters.loading}
            refetchGetPayMaster={refetchGetPayMaster}
            data={getPayMasters.data?.getPayMasters?.data?.sort((a, b) => moment(a.datetime).isBefore(b.datetime)) ?? []}
            paymentMethods={getPayMasters.data?.getPayMasters?.data ?? []}
            totalCount={getPayMasters.data?.getPayMasters?.totalCount ?? 0}
            page={filters.page}
            pageSize={filters.pageSize}
            onDateChange={onDateChangeHandler}
            onFilterChange={onFilterChangeHandler}
            onCheckBoxFilterChange={onCheckBoxFilterChangeHandler}
            onPageSizeChange={onPageSizeChangeHandler}
            onPageChange={onPageChangeHandler}
          />
        )}
      </Box>
    </Box>
  );
};

export default BillsManagement;
