import React from 'react';
import { ROLES } from '../../constants/users/usersRole';
import {
  ROUTE_RESERVATIONS,
  ROUTE_TABLE_RESERVATION,
  ROUTE_SETTINGS,
  ROUTE_MENU,
  ROUTE_ROOM_EDITOR,
  ROUTE_RECORDS,
  ROUTE_CASH,
  ROUTE_BILLS_MANAGEMENT,
  ROUTE_MOH_KITCHEN,
  ROUTE_MOH_ORDERS,
  ROUTE_MOH_BOOKINGS,
  ROUTE_MOH_INVENTORY,
  ROUTE_GUEST_PROFILES
} from '../../router/routes';
import { TranslateTextComponent } from '../../translator';
import { textTranslateCapitalize } from '../../utils/functions';
import { ReactComponent as ReservationImg } from '../../assets/images/vertical_tab/reservation_white.svg';
import { ReactComponent as TablesImg } from '../../assets/images/vertical_tab/tables_white.svg';
import { ReactComponent as CashImg } from '../../assets/images/vertical_tab/cash_desk_white.svg';
import { ReactComponent as MenuImg } from '../../assets/images/vertical_tab/menu_white.svg';
import { ReactComponent as RoomEditorImg } from '../../assets/images/vertical_tab/room_editor_white.svg';
import { ReactComponent as HistoricalImg } from '../../assets/images/vertical_tab/historical_white.svg';
import { ReactComponent as BillsManagementImg } from '../../assets/images/billsManagement/bills_management.svg';
import { ReactComponent as SettingsImg } from '../../assets/images/vertical_tab/settings_white.svg';
import { ReactComponent as KitchenImg } from '../../assets/images/kitchen/food_filled.svg';
import { ReactComponent as OrdersIcon } from '../../assets/images/vertical_tab/icon_orders.svg';
import { ReactComponent as BookingsIcon } from '../../assets/images/vertical_tab/icon_bookings.svg';
import { ReactComponent as InventoryIcon } from '../../assets/images/vertical_tab/inventory_white.svg';
import { ReactComponent as ProfilesImg } from '../../assets/images/vertical_tab/profiles.svg';

const iconStyle = { width: 35, height: 35, color: 'white' };
const iconKitchenStyle = { width: 35, height: 35, fill: 'white' };
const buttons = [
  {
    id: 1,
    text: <TranslateTextComponent capitalize>view-reservations</TranslateTextComponent>,
    img: <ReservationImg style={iconStyle} />,
    route: ROUTE_RESERVATIONS,
    roles: [
      ROLES.ROLE_LOCAL,
      ROLES.ROLE_SUPERVISOR,
      ROLES.ROLE_FRONT_OFFICE,
      ROLES.ROLE_GUEST_SERVICE,
      ROLES.ROLE_FB_OPERATOR,
      ROLES.ROLE_MAITRE,
      ROLES.ROLE_HOSTESS,
      ROLES.ROLE_ROOM_SERVICE
    ],
    description: textTranslateCapitalize('show-reservations-description')
  },
  {
    id: 2,
    text: <TranslateTextComponent capitalize>view-tables</TranslateTextComponent>,
    img: <TablesImg style={iconStyle} />,
    route: ROUTE_TABLE_RESERVATION,
    roles: [ROLES.ROLE_SUPER_ADMIN],
    description: textTranslateCapitalize('show-tables-description')
  },
  {
    id: 3,
    text: <TranslateTextComponent capitalize>cash</TranslateTextComponent>,
    img: <CashImg style={iconStyle} />,
    route: ROUTE_CASH,
    roles: [ROLES.ROLE_SUPER_ADMIN],
    description: textTranslateCapitalize('cash-description')
  },
  {
    id: 4,
    text: <TranslateTextComponent capitalize>kitchen</TranslateTextComponent>,
    img: <KitchenImg style={iconKitchenStyle} />,
    route: ROUTE_MOH_KITCHEN,
    roles: [ROLES.ROLE_SUPER_ADMIN],
    description: textTranslateCapitalize('kitchen-description')
  },
  {
    id: 5,
    text: <TranslateTextComponent capitalize>menu</TranslateTextComponent>,
    img: <MenuImg style={iconStyle} />,
    route: ROUTE_MENU,
    roles: [ROLES.ROLE_MAITRE, ROLES.ROLE_HOSTESS, ROLES.ROLE_ROOM_SERVICE],
    description: textTranslateCapitalize('menu-description')
  },
  {
    id: 6,
    text: <TranslateTextComponent capitalize>room-editor</TranslateTextComponent>,
    img: <RoomEditorImg style={iconStyle} />,
    route: ROUTE_ROOM_EDITOR,
    roles: [ROLES.ROLE_SUPER_ADMIN],
    description: textTranslateCapitalize('room-description')
  },
  {
    id: 7,
    text: <TranslateTextComponent capitalize>app-orders</TranslateTextComponent>,
    img: <OrdersIcon style={iconStyle} />,
    route: ROUTE_MOH_ORDERS,
    roles: [ROLES.ROLE_ROOM_SERVICE],
    description: textTranslateCapitalize('moh-orders-description')
  },
  {
    id: 8,
    text: <TranslateTextComponent capitalize>app-services</TranslateTextComponent>,
    img: <BookingsIcon style={iconStyle} />,
    route: ROUTE_MOH_BOOKINGS,
    roles: [ROLES.ROLE_SUPERVISOR, ROLES.ROLE_FRONT_OFFICE, ROLES.ROLE_GUEST_SERVICE, ROLES.ROLE_MAITRE, ROLES.ROLE_HOSTESS, ROLES.ROLE_ROOM_SERVICE],
    description: textTranslateCapitalize('moh-bookings-description')
  },
  {
    id: 9,
    text: <TranslateTextComponent capitalize>inventory</TranslateTextComponent>,
    img: <InventoryIcon style={iconStyle} />,
    route: ROUTE_MOH_INVENTORY,
    roles: [ROLES.ROLE_SUPER_ADMIN],
    description: textTranslateCapitalize('moh-inventory-description')
  },
  {
    id: 10,
    text: <TranslateTextComponent capitalize>historical</TranslateTextComponent>,
    img: <HistoricalImg style={iconStyle} />,
    route: ROUTE_RECORDS,
    roles: [ROLES.ROLE_SUPER_ADMIN],
    description: textTranslateCapitalize('historical-description')
  },
  {
    id: 11,
    text: <TranslateTextComponent capitalize>bills-management</TranslateTextComponent>,
    img: <BillsManagementImg style={iconStyle} />,
    route: ROUTE_BILLS_MANAGEMENT,
    roles: [ROLES.ROLE_SUPER_ADMIN],
    description: textTranslateCapitalize('bills-description')
  },
  {
    id: 12,
    text: <TranslateTextComponent capitalize>profiles</TranslateTextComponent>,
    img: <ProfilesImg style={iconStyle} />,
    route: ROUTE_GUEST_PROFILES,
    roles: [
      ROLES.ROLE_LOCAL,
      ROLES.ROLE_SUPERVISOR,
      ROLES.ROLE_FRONT_OFFICE,
      ROLES.ROLE_GUEST_SERVICE,
      ROLES.ROLE_FB_OPERATOR,
      ROLES.ROLE_MAITRE,
      ROLES.ROLE_HOSTESS,
      ROLES.ROLE_ROOM_SERVICE
    ],
    description: textTranslateCapitalize('profiles-description')
  },
  {
    id: 13,
    text: <TranslateTextComponent capitalize>settings</TranslateTextComponent>,
    img: <SettingsImg style={iconStyle} />,
    route: ROUTE_SETTINGS,
    roles: [ROLES.ROLE_ROOM_SERVICE],
    description: textTranslateCapitalize('settings-description')
  }
];

export default buttons;
