/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState, useContext } from 'react';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import { useMutation } from '@apollo/react-hooks';
import { Box, Button, Typography } from '@material-ui/core';
import { getApolloErrors } from '../../apollo/ApolloProvider';
import { ModalContext } from '../../contexts/ModalContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import useCustomQuery from '../../hooks/useCustomQuery';
import { GET_FB_OUTLETS, GET_FB_OUTLET } from '../../graphql/outlets/queries';
import { ADD_FB_OUTLET, EDIT_FB_OUTLET } from '../../graphql/outlets/mutations';
import { CommonTable, SearchBar } from '../../common-fe';
import PageLayout from '../shared/PageLayout';
import { TranslateTextComponent, capitalizeFirstLetter, translate } from '../../translator';
import columns from './table/outletColumns';
import styles from '../../styles/reservation/reservation';
import OutletModal from './modal/OutletModal';

const initialFilters = {
  orderBy: 'id',
  orderType: 'DESC',
  page: 1,
  pageSize: 10,
  keyword: ''
};

const OutletList = () => {
  const classes = styles();

  const modalContext = useContext(ModalContext);
  const languageContext = useContext(LanguageContext);

  const [selectedOutletUUID, setSelectedOutletUUID] = useState(null);
  const [selectedDataOutlet, setSelectedDataOutlet] = useState(null);
  const [isEditingOutlet, setIsEditingOutlet] = useState(null);
  const [queryFilters, setQueryFilters] = useState(initialFilters);
  const [showModal, setShowModal] = useState(false);
  const [addOutlet] = useMutation(ADD_FB_OUTLET);
  const [editFbOutlet] = useMutation(EDIT_FB_OUTLET);

  const getFbOutlets = useCustomQuery(GET_FB_OUTLETS, {
    variables: queryFilters,
    onError: (err) => {
      modalContext.openModal({
        class: 'danger',
        title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
        text: getApolloErrors(err).join(' - ')
      });
    },
    fetchPolicy: 'network-only'
  });

  useCustomQuery(GET_FB_OUTLET, {
    variables: {
      fbOutletUUID: selectedOutletUUID
    },
    skip: !selectedOutletUUID,
    onCompleted: (data) => {
      if (data) {
        setSelectedDataOutlet(data?.getFbOutlet ?? {});
      }
    },
    onError: (err) => {
      modalContext.openModal({
        class: 'danger',
        title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
        text: getApolloErrors(err).join(' - ')
      });
    },
    fetchPolicy: 'no-cache'
  });

  const handleSearchChange = (value) => {
    setQueryFilters({
      ...queryFilters,
      keyword: value
    });
  };

  const totalRowsCount = getFbOutlets?.data?.getFbOutlets?.totalCount ?? 0;
  const allOutlets = getFbOutlets?.data?.getFbOutlets?.data ?? [];

  const setOrderType = (orderBy, orderType) => {
    const isDesc = queryFilters.orderBy === orderBy && orderType === 'desc';
    return isDesc ? 'ASC' : 'DESC';
  };

  const setTableSort = (orderBy, orderType) => {
    setQueryFilters({
      ...queryFilters,
      orderBy,
      orderType: setOrderType(orderBy, orderType)
    });
  };

  const setTablePages = (page, pageSize) => {
    setQueryFilters({
      ...queryFilters,
      page,
      pageSize
    });
  };

  const handleEnableEditing = (outletUUID) => {
    setSelectedOutletUUID(outletUUID);
    setShowModal(true);
    setIsEditingOutlet(true);
  };

  const resetAll = () => {
    setIsEditingOutlet(false);
    setSelectedOutletUUID(null);
    setSelectedDataOutlet(null);
    setShowModal(false);
  };

  const editOutlet = (outletValues) => {
    let geolocation = null;
    if (outletValues.longitude !== 0 && outletValues.latitude !== 0) {
      geolocation = { coordinates: [parseFloat(outletValues.longitude, 10), parseFloat(outletValues.latitude, 10)] };
    }

    editFbOutlet({
      variables: {
        fbOutletUUID: selectedOutletUUID,
        fbOutletData: {
          name: outletValues.name,
          address: outletValues.address,
          addressNumber: outletValues.addressNumber,
          city: outletValues.city,
          zipCode: outletValues.zipCode,
          country: outletValues.country,
          phoneNumber: outletValues.phone,
          email: outletValues.email,
          geolocation,
          notificationEmail: outletValues.notificationEmail,
          fiscalPrinterIpAddress: outletValues.fiscalPrinterIp
        }
      }
    })
      .then(() => {
        modalContext.openModal({
          class: 'success',
          title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
          text: <TranslateTextComponent capitalize>outlet-edited</TranslateTextComponent>
        });
        getFbOutlets.refetch();
        resetAll();
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const addNewOutlet = (outletValues) => {
    let geolocation = null;
    if (outletValues.longitude !== 0 && outletValues.latitude !== 0) {
      geolocation = { coordinates: [parseFloat(outletValues.longitude, 10), parseFloat(outletValues.latitude, 10)] };
    }

    addOutlet({
      variables: {
        fbOutletData: {
          name: outletValues.name,
          address: outletValues.address,
          addressNumber: outletValues.addressNumber,
          city: outletValues.city,
          zipCode: outletValues.zipCode,
          country: outletValues.country,
          phoneNumber: outletValues.phone,
          email: outletValues.email,
          geolocation,
          notificationEmail: outletValues.notificationEmail,
          fiscalPrinterIpAddress: outletValues.fiscalPrinterIp
        }
      }
    })
      .then(() => {
        modalContext.openModal({
          class: 'success',
          title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
          text: <TranslateTextComponent capitalize>outlet-added</TranslateTextComponent>
        });
        getFbOutlets.refetch();
        resetAll();
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  return (
    <PageLayout>
      <Box style={{ marginBottom: 25 }}>
        <SearchBar
          style={{ height: 35, padding: 0, fontSize: 10 }}
          className={classes.searchBar}
          placeholder={capitalizeFirstLetter(translate('search', languageContext.language))}
          iconStyle={{ height: 33 }}
          inputStyle={{ fontSize: 12, textIndent: 8 }}
          onChange={(event) => handleSearchChange(event.target.value)}
        />
      </Box>
      <CommonTable
        isLoading={getFbOutlets && getFbOutlets.loading}
        headerCellStyle={{ padding: '20px 0px' }}
        isPaginationEnabled
        items={allOutlets}
        orderBy={queryFilters.orderBy}
        orderType={queryFilters.orderType.toLocaleLowerCase()}
        page={queryFilters.page}
        pageSize={queryFilters.pageSize}
        setTableSort={setTableSort}
        setTablePages={setTablePages}
        columns={columns({ handleEnableEditing })}
        totalRows={totalRowsCount}
        rowCellStyle={{ paddingLeft: 0 }}
        tableHeader={
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#EDFAFF',
              minHeight: 28,
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
            }}
          >
            <TranslateTextComponent capitalize>outlets-tot</TranslateTextComponent>: {totalRowsCount}
          </Typography>
        }
      />
      <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '30px 10px 20px 0px', minWidth: 325, alignSelf: 'flex-end', fontSize: 20, fontWeight: 500, color: 'white' }}
          onClick={() => setShowModal(true)}
        >
          <TranslateTextComponent uppercase>add-new-outlet</TranslateTextComponent>
        </Button>
      </Box>
      <OutletModal
        showModal={showModal}
        closeModal={() => {
          resetAll();
        }}
        isEditingOutlet={isEditingOutlet}
        addNewOutlet={addNewOutlet}
        selectedDataOutlet={selectedDataOutlet}
        editOutlet={editOutlet}
      />
    </PageLayout>
  );
};

export default OutletList;
