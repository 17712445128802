import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import axios from 'axios';

import FbCourseStatusIcon from './FbCourseStatusIcon';
import { SharedBy, ChairOrHalfPortion, Centerpiece } from './OrderIconComponents';

import groupedByMenuItem from '../../../utils/menuGroupBy';
import FB_COURSE_STATUSES from '../../../constants/kitchen/courseStatus';

import styles from '../../../styles/kitchen/fbCourse';
import { secondaryColor, mainTextColor } from '../../../styles/theme';

const updateOrderGolfStatus = (status, externalOrderId) => {
  let status_order = '';
  switch (status) {
    case FB_COURSE_STATUSES.todo.label:
      status_order = 'Ordered';
      break;
    case FB_COURSE_STATUSES.preparing.label:
      status_order = 'Preparing';
      break;
    case FB_COURSE_STATUSES.ready.label:
      status_order = 'Ready Kitchen';
      break;
    default:
  }

  const number_order = externalOrderId;
  const mutation = `mutation{
          updateOrder(order_number:${number_order},status_order:"${status_order}") {
          status_order
           }
        }`;

  return axios({
    method: 'post',
    url: process.env.REACT_APP_GRAPHQL_URL_BACK_GPMS,
    data: {
      query: mutation
    }
  })
    .then((response) => {
      if (response.status === 200) {
        const statusOrder = response.data.data.updateOrder.status_order;
        console.log(`status_order ${statusOrder}`);
      }
    })
    .catch((error) => {
      console.log('createFbOrder error ', error);
      console.log('createFbOrder error message', error.response);
      // setErrorMessage(error.response.data);
    });
};

const FbCourse = ({ fbCourse, i, courseKey, fbOrder, changingFbCourseStatus, handleSetChangingFbCourseStatus, onSetFbCourseStatus }) => {
  const classes = styles();

  const groupedMenuItems = groupedByMenuItem(fbCourse.fbCoursesMenuItems);
  const getTotalQuantity = (items) => {
    return items.reduce((quantity, menuItem) => {
      quantity += menuItem.quantity;
      return quantity;
    }, 0);
  };

  return (
    <Grid container style={{ minHeight: 55, backgroundColor: i % 2 === 0 ? 'white' : secondaryColor }}>
      <Grid item xs={1} className={classes.courseNumberContainer}>
        <Typography style={{ fontWeight: 'bold', color: mainTextColor }}>{fbCourse.number}</Typography>
        <FbCourseStatusIcon
          fbCourse={fbCourse}
          showStatuses={courseKey === changingFbCourseStatus}
          onClickIcon={() => handleSetChangingFbCourseStatus(courseKey)}
          onSelectStatus={(status) => {
            if (fbOrder.externalOrderId != null || fbOrder.externalOrderId !== undefined) {
              updateOrderGolfStatus(FB_COURSE_STATUSES[status].label, fbOrder.externalOrderId);
            }
            onSetFbCourseStatus(fbCourse, status);
          }}
        />
      </Grid>
      <Grid item xs={11} style={{ borderLeft: `1.5px solid #B4DCED` }}>
        {groupedMenuItems.map((menuItem, j) => (
          <Grid key={j} container style={{ borderBottom: `1.5px solid #B4DCED` }}>
            <Grid item xs={1} className={classes.courseQuantityContainer}>
              <Grid style={{ fontSize: 14 }}>{getTotalQuantity(menuItem)}x</Grid>
            </Grid>
            <Grid item xs={11} className={classes.courseNameContainer}>
              <Grid style={{ fontSize: 13 }}>{menuItem[0].name}</Grid>
              <Grid style={{ color: '#449D44', fontSize: 10, fontWeight: 'bold' }}>
                {menuItem[0]?.with?.length > 0 ? `CON: ${menuItem[0].with.join(', ')}` : ''}
              </Grid>
              <Grid style={{ color: '#CB2D24', fontSize: 10, fontWeight: 'bold' }}>
                {menuItem[0].without?.length > 0 ? `SENZA: ${menuItem[0].without.join(', ')}` : ''}
              </Grid>
              <Grid style={{ fontSize: 10, fontStyle: 'italic' }}> {menuItem[0].notes} </Grid>
              <Grid container>
                {menuItem.map((item, k) => (
                  <Box key={k} className={classes.courseDetailedNumber}>
                    <Grid style={{ fontSize: 10, color: mainTextColor }}>
                      {item.halfPortion ? (
                        <span>
                          <sup>1</sup>/<sub>2</sub>
                        </span>
                      ) : (
                        item.quantity
                      )}
                    </Grid>
                    x
                    {item.sharedBy && item.sharedBy.length > 1 ? (
                      <SharedBy item={item} />
                    ) : (
                      <Box style={{ fontSize: 10 }}>
                        {item.fbChairId !== null && <ChairOrHalfPortion item={item} />}
                        {item.centerpiece && <Centerpiece key={k} />}
                      </Box>
                    )}
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default FbCourse;
