import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from '../theme';

const styles = {
  panelNewReservation: () => ({
    backgroundColor: '#449d44',
    color: 'white',
    width: '100%',
    justifyContent: 'space-between'
  }),
  savebutton: () => ({
    color: 'white',
    marginTop: 20,
    width: '100%',
    height: 50,
    fontSize: 20,
    fontFamily: 'Roboto'
  }),
  availabilityBox: () => ({
    margin: 20
  }),
  boxButtons: () => ({
    justifyContent: 'center',
    display: 'flex'
  }),
  boxContainer: () => ({
    margin: 20
  }),
  checkTypography: () => ({
    fontSize: 15,
    color: '#55595C',
    paddingBottom: 0
  }),
  textfieldSearch: () => ({
    height: 30,
    width: '100%',
    borderRadius: 5,
    paddingRight: 0
  }),
  gridSearch: () => ({
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    marginBottom: 45
  }),
  searchbutton: () => ({
    color: 'white',
    width: '100%',
    height: 45,
    fontSize: 20,
    fontFamily: 'Roboto'
  }),
  dateboxStyle: () => ({
    borderStyle: 'solid',
    paddingLeft: 10
  }),
  imgDate: () => ({
    width: 18,
    height: 25
  }),
  avatarimg: () => ({
    width: 78,
    height: 78
  }),
  flterbutton: () => ({
    color: 'white',
    height: 40,
    fontSize: 12,
    fontFamily: 'Roboto',
    marginRight: 15
  }),
  activeFilterbutton: () => ({
    color: primaryColor,
    backgroundColor: '#FFFFFF',
    height: 40,
    fontSize: 12,
    fontFamily: 'Roboto',
    marginRight: 15
  }),
  generalSearch: () => ({
    color: 'white',
    fontSize: 20,
    fontFamily: 'Roboto',
    border: '1.5px solid',
    borderRadius: 4,
    width: '100%',
    borderColor: primaryColor
  }),
  toolbarBox: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }),
  newClientButton: () => ({
    width: '100%',
    color: 'white',
    height: 40,
    fontSize: 15,
    fontFamily: 'Roboto',
    marginRight: 15
  }),
  listStyle: () => ({
    border: 'solid',
    borderRadius: 5,
    borderColor: primaryColor
  }),
  chip: () => ({
    paddingTop: 5,
    justifyContent: 'space-between',
    paddingRight: 5,
    paddingLeft: 95,
    paddingBottom: 5,
    width: '100%',
    height: 35,
    borderRadius: 0,
    backgroundColor: primaryColor,
    color: 'white'
  }),
  formControl: () => ({
    width: '100%'
  }),
  orderAndWinebutton: () => ({
    color: 'white',
    width: '100%',
    height: 45,
    fontSize: 15,
    lineHeight: 'initial',
    fontFamily: 'Roboto'
  })
};

export default makeStyles(styles);
