export const ROUTE_DASHBOARD = '/';
export const ROUTE_RESERVATIONS = '/reservations';
export const ROUTE_SAVE_RESERVATION = '/save_reservation';
export const ROUTE_TABLE_RESERVATION = '/table_reservation';
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_ORDER = '/order'; // :id
export const ROUTE_MENU = '/menu';
export const ROUTE_ROOM_EDITOR = '/room_editor';
export const ROUTE_RECORDS = '/records';
export const ROUTE_LOGIN = '/login';
export const ROUTE_ASSIGN_CHAIR = '/assign_chair'; // :id
export const ROUTE_GUEST_REGISTRY = '/new_guest';
export const ROUTE_CASH = '/cash';
export const ROUTE_PM_CASH = '/pm_cash';
export const ROUTE_BILLS_MANAGEMENT = '/bills_management';
export const ROUTE_EDIT_RESERVATION = '/edit_reservation'; // :id
export const ROUTE_ACCOUNT = '/account';
export const ROUTE_OUTLETS_LIST = '/outlets_list';
export const ROUTE_GUEST_USERS_LIST = '/guest_users_list';
export const ROUTE_STAFF_USERS_LIST = '/staff_users_list';
export const ROUTE_GUEST_PROFILES = '/guest_profiles';
export const ROUTE_CHECK_IN = '/check_in';
export const ROUTE_GUEST_PROFILE = '/guest_profile'; // :uuid
export const ROUTE_SAVE_DAILY_REPORT = '/save_daily_report';
export const ROUTE_EDIT_DAILY_REPORT = '/edit_daily_report'; // :uuid
export const ROUTE_DAILY_REPORTS = '/daily_reports';
// MOH
export const ROUTE_MOH_ORDERS = '/orders';
export const ROUTE_MOH_DRIVERS = '/drivers';
export const ROUTE_MOH_SERVICES = '/services';
export const ROUTE_MOH_BOOKINGS = '/bookings';
export const ROUTE_MOH_KITCHEN = '/kitchen';
export const ROUTE_MOH_INVENTORY = '/inventory';
export const ROUTE_KITCHEN_DETAILED_ORDER = '/detailed_order';
