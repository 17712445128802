/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import { FB_SERVICE_REVENUE_FRAGMENT, FB_OUTLETS_FRAGMENT } from '../fragments';

export const GET_FB_SERVICE_REVENUES = gql`
  query getFbServiceRevenues($fbOutletUUID: String, $date: String, $orderBy: String, $orderType: String, $page: Int, $pageSize: Int) {
    getFbServiceRevenues(fbOutletUUID: $fbOutletUUID, date: $date, orderBy: $orderBy, orderType: $orderType, page: $page, pageSize: $pageSize) {
      totalCount
      data {
        ...fbServiceRevenueFragment
        outlet {
          ...fbOutletsFragment
        }
      }
    }
  }
  ${FB_SERVICE_REVENUE_FRAGMENT}
  ${FB_OUTLETS_FRAGMENT}
`;

export const GET_FB_SERVICE_REVENUE = gql`
  query getFbServiceRevenue($fbServiceRevenueUUID: String) {
    getFbServiceRevenue(fbServiceRevenueUUID: $fbServiceRevenueUUID) {
      ...fbServiceRevenueFragment
    }
  }
  ${FB_SERVICE_REVENUE_FRAGMENT}
`;
