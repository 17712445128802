import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { Redirect } from 'react-router-dom';
import { Box, Grid, Typography, Checkbox, Button } from '@material-ui/core/';

import { LanguageContext } from '../contexts/LanguageContext';
import { AuthContext } from '../contexts/AuthContext';

import { TranslateTextComponent, translate, capitalizeFirstLetter } from '../translator';
import { ReferencesText } from '../common-fe';
import styles from '../styles/login/LoginStyle';
import { primaryColor } from '../styles/theme';
import { ROUTE_DASHBOARD } from '../router/routes';
import ForgotPasswordModal from '../components/login/ForgotPasswordModal';
import { ModalContext } from '../contexts/ModalContext';

const MAIL = require('../assets/images/login/mail.svg');
const KEY = require('../assets/images/login/pwdKey.svg');
const SHOW_PWD = require('../assets/images/login/pwdEye.svg');

const APP_NAME = 'Borgo Egnazia';

const ForgotPasswordText = styled(TranslateTextComponent).attrs({ capitalize: true })`
  text-align: center;
  font-size: 16px;
`;

const LoginPage = () => {
  const authContext = useContext(AuthContext);
  const modalContext = useContext(ModalContext);
  const languageContext = useContext(LanguageContext);
  const [checked, setChecked] = useState(false);
  const [textType, setTextType] = useState('password');
  const [user, setUser] = useState({
    user: '',
    password: ''
  });

  const error = authContext && authContext.errorMessage;

  const classes = styles();

  const handleChangeCheck = (e) => {
    setChecked(e.target.checked);
  };

  const showPwd = () => {
    if (textType === 'password') {
      setTextType('text');
    } else {
      setTextType('password');
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (user && user.user && user.password) {
      authContext.login(user.user, user.password);
    }
  };

  const handleLogin = (key, e) => {
    setUser((prev) => ({
      ...prev,
      [key]: e
    }));
  };

  const onHandleLogin = () => {
    if (user && user.user && user.password) {
      authContext.login(user.user, user.password);
    }
  };

  return authContext.isLoggedIn() ? (
    <Redirect to={{ pathname: ROUTE_DASHBOARD }} />
  ) : (
    <Box className={classes.pageContainer}>
      <Grid container className={classes.containerStyle}>
        <Grid item xs={12}>
          <Box className={classes.appName}>{APP_NAME}</Box>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.loginText}>Property Manager</Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 40, width: 400 }}>
          <ReferencesText
            img1={<img src={MAIL} alt="mail" style={{ paddingRight: 20, width: 65 }} />}
            placeholder={capitalizeFirstLetter(translate('email', languageContext.language))}
            name="email"
            onChange={(e) => handleLogin('user', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 0 }}>
          <form style={{ width: 400 }} onSubmit={(e) => handleOnSubmit(e)}>
            <ReferencesText
              img1={<img src={KEY} alt="mail" style={{ paddingRight: 20, width: 65 }} />}
              type={textType}
              placeholder={capitalizeFirstLetter(translate('login-password', languageContext.language))}
              img2={<img src={SHOW_PWD} alt="mail" style={{ paddingRight: 20, cursor: 'pointer', width: 65 }} role="button" onClick={showPwd} />}
              // value={loginData && loginData.password ? loginData.password : ''}
              onChange={(e) => handleLogin('password', e.target.value)}
            />
          </form>
        </Grid>
        <Grid item xs={12} style={{ textAlign: ' -webkit-center' }}>
          {error?.map((err, index) => (
            <Typography key={index} style={{ fontWeight: '600', marginRight: 5, color: '#55595C', fontSize: 14, marginTop: 25 }}>
              <span style={{ color: 'red', fontSize: 15 }}>{(err.message && err.message.message) || 'Login error'}</span>
            </Typography>
          ))}
        </Grid>
        <Grid item xs={12} style={{ marginTop: 35, display: 'flex', alignItems: 'center' }}>
          <Checkbox
            onChange={(e) => handleChangeCheck(e)}
            value={checked}
            checked={checked}
            style={{ color: `${primaryColor}` }}
            color="primary"
            className={classes.checkBox}
          />
          <Typography className={classes.checkTitle}>
            <TranslateTextComponent capitalize>remember-pwd</TranslateTextComponent>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ marginTop: 35, display: 'flex', alignItems: 'center' }}
          onClick={() =>
            modalContext.openModal({
              title: capitalizeFirstLetter(translate('forgot-password', languageContext.language)),
              content: <ForgotPasswordModal />,
              actionButtons: []
            })
          }
        >
          <ForgotPasswordText style={{ cursor: 'pointer', textDecoration: 'underline' }}>forgot-password-?</ForgotPasswordText>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 35, alignItems: 'center' }}>
          <Button className={classes.loginButton} variant="contained" color="primary" onClick={onHandleLogin}>
            {translate('login', languageContext.language)}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginPage;
