import { createMuiTheme } from '@material-ui/core/styles';

import {
  appTheme as commonTheme,
  primaryColor,
  secondaryColor,
  invertedPrimary,
  invertedPrimaryCC80,
  greenButton,
  redButton,
  hoverGreenButton,
  hoverRedButton,
  mainTextColor,
  borderTheme
} from '../common-fe';

export {
  primaryColor,
  secondaryColor,
  invertedPrimary,
  invertedPrimaryCC80,
  greenButton,
  redButton,
  hoverGreenButton,
  hoverRedButton,
  mainTextColor,
  borderTheme
};
export const appTheme = createMuiTheme({
  ...commonTheme,
  overrides: {
    ...commonTheme.overrides,
    MuiToolbar: {
      gutters: {
        paddingLeft: '21.5px !important'
      }
    },
    MuiTextField: {
      root: {
        '&.autocompleteTextField .MuiAutocomplete-clearIndicatorDirty': {
          display: 'none'
        }
      }
    },
    MuiAutocomplete: {
      root: {
        '& .MuiAutocomplete-inputRoot': {
          padding: '1px 4px !important'
        },
        '& .MuiTextField-root .MuiAutocomplete-input': {
          height: 31
        },
        '& .MuiAutocomplete-tag': {
          height: 31
        }
      }
    },
    MuiMenu: {
      list: {
        maxHeight: 400
      }
    }
  }
});
