import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { TextField as MUITextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import ImageUpload from '../../../shared/ImageUpload';
import InputWithLabel from '../../../shared/InputWithLabel';
import Buttons from '../../../shared/Buttons';
import { UPDATE_USER } from '../../../../graphql/settings/account/mutations';
import { ModalContext } from '../../../../contexts/ModalContext';
import { TranslateTextComponent } from '../../../../translator';
import { getApolloErrors } from '../../../../apollo/ApolloProvider';
import useCustomQuery from '../../../../hooks/useCustomQuery';
import { GET_USER_INFO } from '../../../../graphql/settings/account/queries';

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column;
  width: 100%;
  height: 100%;

  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;

  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const RowContainer = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 20px;
  }
`;

const TextField = styled(MUITextField).attrs({ variant: 'outlined', color: 'primary', fullWidth: true })``;

const validationSchema = Yup.object().shape({});

const ProfileForm = () => {
  const modalContext = useContext(ModalContext);

  // Queries
  const getUserInfo = useCustomQuery(GET_USER_INFO);
  // Mutations
  const [updateUser, { loading }] = useMutation(UPDATE_USER);

  const { values, errors, touched, isSubmitting, setFieldValue, handleChange, handleBlur, handleReset, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: getUserInfo.data?.getUserInfo?.username ?? '',
      firstName: getUserInfo.data?.getUserInfo?.firstName ?? '',
      lastName: getUserInfo.data?.getUserInfo?.lastName ?? '',
      avatar: getUserInfo.data?.getUserInfo?.avatar ?? null,
      phoneNumber: getUserInfo.data?.getUserInfo?.phoneNumber ?? ''
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      updateUser({ variables: { userInput: { ...values } } })
        .then(() => {
          getUserInfo.refetch();
          modalContext.openModal({
            class: 'success',
            title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
            text: <TranslateTextComponent capitalize>profile-updated</TranslateTextComponent>
          });
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        });
      setSubmitting(false);
    }
  });

  return (
    <Container>
      <ColumnContainer style={{ flex: 1 }}>
        <RowContainer>
          <ColumnContainer>
            <InputWithLabel label="username" error={touched.username && errors.username}>
              <TextField
                name="username"
                value={values.username}
                error={touched.username && !!errors.username}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputWithLabel>
          </ColumnContainer>
          <ColumnContainer>
            <ImageUpload
              currentImage={values.avatar}
              onImageDrop={(base64) => setFieldValue('avatar', base64)}
              onImageReset={() => setFieldValue('avatar', '')}
            />
          </ColumnContainer>
        </RowContainer>
        <RowContainer>
          <InputWithLabel label="email">
            <TextField disabled value={getUserInfo.data?.getUserInfo?.email ?? ''} />
          </InputWithLabel>
          <InputWithLabel label="phone-number">
            <TextField
              name="phoneNumber"
              value={values.phoneNumber}
              error={touched.phoneNumber && !!errors.phoneNumber}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
        </RowContainer>
        <RowContainer>
          <InputWithLabel label="name">
            <TextField
              name="firstName"
              value={values.firstName}
              error={touched.firstName && !!errors.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
          <InputWithLabel label="lastname">
            <TextField
              name="lastName"
              value={values.lastName}
              error={touched.lastName && !!errors.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
        </RowContainer>
      </ColumnContainer>
      <RowContainer>
        <Buttons
          containerStyle={{ justifyContent: 'space-between', marginBottom: 20 }}
          buttons={[
            { buttonType: 'reset', type: 'cancel', style: { fontSize: 20, fontWeight: 600, minWidth: 155 }, onClick: handleReset },
            {
              buttonType: 'submit',
              label: 'save',
              type: 'confirm',
              disabled: isSubmitting,
              isLoading: loading,
              style: { fontSize: 20, fontWeight: 600, minWidth: 155 },
              onClick: handleSubmit
            }
          ]}
        />
      </RowContainer>
    </Container>
  );
};

export default ProfileForm;
