import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSubscription, useMutation } from '@apollo/react-hooks';
import { Button } from '@material-ui/core';
import { ON_ORDER_CREATED } from '../graphql/moh/orders/subscriptions';
import { AuthContext } from './AuthContext';
import { NotificationContext } from './NotificationContext';
import { ModalContext } from './ModalContext';
import useCustomQuery from '../hooks/useCustomQuery';
import { GET_ORDERS } from '../graphql/moh/orders/queries';
import { SET_ORDER_STATUS } from '../graphql/moh/orders/mutations';
import { getApolloErrors } from '../apollo/ApolloProvider';
import modalStyle from '../styles/shared/modalStyle';
import { TranslateTextComponent } from '../translator';

export const OrdersNotificationContext = createContext();

export const OrdersNotificationProvider = ({ children }) => {
  const { appOutletUUID, user } = useContext(AuthContext);
  const { showNotification } = useContext(NotificationContext);
  const modalContext = useContext(ModalContext);
  const [showOrderConfirmationModal, setShowOrderConfirmationModal] = useState(false);
  const [ordersToConfirm, setOrdersToConfirm] = useState([]);
  const [currentOrder, setCurrentOrder] = useState(null);

  const modalClasses = modalStyle();

  // Queries
  const getOrders = useCustomQuery(GET_ORDERS, {
    variables: { fbOutletUUID: appOutletUUID, status: 'to_confirm', orderBy: 'date', orderType: 'asc' },
    fetchPolicy: 'no-cache',
    skip: !appOutletUUID || !user,
    onCompleted: (res) => {
      setOrdersToConfirm([...(res?.getOrders?.data ?? [])]);
    }
  });

  // Mutations
  const [setOrderStatus] = useMutation(SET_ORDER_STATUS);

  useSubscription(ON_ORDER_CREATED, {
    variables: {
      outletUUID: appOutletUUID
    },
    onSubscriptionData: ({ subscriptionData }) => {
      sendNotification({ orderType: subscriptionData.data?.orderCreated?.orderType });
      getOrders.refetch({ fbOutletUUID: appOutletUUID, status: 'to_confirm', orderBy: 'date', orderType: 'asc' }).then((res) => {
        setOrdersToConfirm([...(res.data.getOrders.data ?? [])]);
      });
    }
  });

  const updateOrdersToConfirmState = ({ orderUUID }) => {
    const filteredOrders = ordersToConfirm.filter((order) => order.uuid !== orderUUID);
    setOrdersToConfirm([...filteredOrders]);
  };

  const onOrderConfirm = ({ uuid, deliveryTime }) => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>confirm-order-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>confirm-order-description</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            modalContext.setModalLoading(true);
            setOrderStatus({ variables: { fbOrderUUID: uuid, deliveryTime, status: 'open' } })
              .then(() => {
                modalContext.setModalLoading(false);
                modalContext.openModal({
                  class: 'success',
                  title: <TranslateTextComponent capitalize>order-confirmed-success-title</TranslateTextComponent>,
                  text: <TranslateTextComponent capitalize>order-confirmed-success-description</TranslateTextComponent>,
                  actionButtons: [
                    <Button
                      key={0}
                      variant="outlined"
                      color="primary"
                      className={modalClasses.invertedButton}
                      onClick={() => {
                        if (ordersToConfirm.length - 1 > 0) {
                          updateOrdersToConfirmState({ orderUUID: uuid });
                          modalContext.closeModal();
                        } else {
                          modalContext.closeModal();
                          setShowOrderConfirmationModal(false);
                        }
                      }}
                    >
                      <TranslateTextComponent uppercase>ok</TranslateTextComponent>
                    </Button>
                  ]
                });
              })
              .catch((err) => {
                modalContext.setModalLoading(false);
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>an-error-occurred</TranslateTextComponent>,
                  text: getApolloErrors(err)
                });
              });
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const onOrderDelete = ({ uuid }) => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>delete-order-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>delete-order-description</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            modalContext.setModalLoading(true);
            setOrderStatus({ variables: { fbOrderUUID: uuid, status: 'deleted' } })
              .then(() => {
                modalContext.setModalLoading(false);
                modalContext.openModal({
                  class: 'success',
                  title: <TranslateTextComponent capitalize>order-deleted-success-title</TranslateTextComponent>,
                  text: <TranslateTextComponent capitalize>order-deleted-success-description</TranslateTextComponent>,
                  actionButtons: [
                    <Button
                      key={0}
                      variant="outlined"
                      color="primary"
                      className={modalClasses.invertedButton}
                      onClick={() => {
                        if (ordersToConfirm.length - 1 > 0) {
                          updateOrdersToConfirmState({ orderUUID: uuid });
                          modalContext.closeModal();
                        } else {
                          modalContext.closeModal();
                          setShowOrderConfirmationModal(false);
                        }
                      }}
                    >
                      <TranslateTextComponent uppercase>ok</TranslateTextComponent>
                    </Button>
                  ]
                });
              })
              .catch((err) => {
                modalContext.setModalLoading(false);
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>an-error-occurred</TranslateTextComponent>,
                  text: getApolloErrors(err)
                });
              });
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  useEffect(() => {
    if (ordersToConfirm.length > 0) {
      setCurrentOrder(ordersToConfirm[0]);
    } else {
      setCurrentOrder(null);
    }
  }, [ordersToConfirm]);

  useEffect(() => {
    if (currentOrder) {
      setShowOrderConfirmationModal(true);
    }
  }, [currentOrder]);

  const sendNotification = ({ orderType }) => {
    switch (orderType) {
      case 'DELIVERY':
        showNotification({ message: 'new-delivery-order' });
        break;
      case 'TAKE_AWAY':
        showNotification({ message: 'new-takeaway-order' });
        break;
      case 'SERVICE':
        showNotification({ message: 'new-service-order' });
        break;
      default: {
        showNotification({ message: 'new-delivery-order' });
      }
    }
  };

  return (
    <OrdersNotificationContext.Provider
      value={{
        showOrderConfirmationModal,
        currentOrder,
        onOrderConfirm,
        onOrderDelete
      }}
    >
      {children}
    </OrdersNotificationContext.Provider>
  );
};

export const OrdersNotificationConsumer = OrdersNotificationContext.Consumer;
