const customObjectValidator = (validationSchema, toValidate) => {
  const errorMap = new Map();
  let isValid = true;
  Object.keys(validationSchema).forEach((key) => {
    const validationKeyObj = validationSchema[key];
    if (validationKeyObj.isRequired && !toValidate[key]) {
      isValid = false;
      errorMap.set(key, 'This field is required');
    }
    if (toValidate[key] && validationKeyObj.validateFunctions) {
      validationKeyObj.validateFunctions.forEach((validateFunctionObj) => {
        if (!validateFunctionObj.fn(toValidate[key], toValidate)) {
          isValid = false;
          errorMap.set(key, validateFunctionObj.errorMessage);
        }
      });
    }
  });
  return {
    isValid,
    errorMap
  };
};

export default customObjectValidator;
