import React, { useState } from 'react';
import { Box, RadioGroup, FormControlLabel, Radio, Button, Switch } from '@material-ui/core';
import moment from 'moment';
import Wrapper from '../shared/Wrapper';
import LeftContainer from '../shared/LeftContainer';
import Input from '../shared/Input';
import { DataPicker } from '../../../common-fe/src';
import { TranslateTextComponent } from '../../../translator';
import MainContainer from '../shared/MainContainer';
import HeaderIncome from '../shared/HeaderIncome/HeaderIncome';
import { getPaymentTranslation } from '../../../utils/billsManagement';
import styles from '../../../styles/billsManagement/input';
import buttonsStyles from '../../../styles/billsManagement/buttons';

const Invoices = ({ filterDate, paymentMethods, onDateChange, onFilterChange, onCheckBoxFilterChange }) => {
  const [date, setDate] = useState(null);
  const classes = styles();
  const classesButtons = buttonsStyles();

  return (
    <Wrapper>
      <LeftContainer>
        <Box>
          <Input title="search" placeholder="search" onChange={(e) => onFilterChange('client', e.target.value)} />
          <Input
            title="date-filter"
            component={
              <DataPicker
                dataPickerStyle={{ overflow: 'hidden' }}
                value={date}
                onChange={(e) => {
                  if (moment(e).isValid()) {
                    setDate(e);
                    onFilterChange('date', e);
                  }
                }}
              />
            }
          />
          <Input
            title="search-document-number-shortened"
            customIcon="Receipt"
            placeholder="placeholder-search-check-ticket"
            onChange={(e) => onFilterChange('document', e.target.value)}
          />
          <Input
            title="display"
            type="radio"
            component={
              <RadioGroup style={{ maxHeight: 200 }} defaultValue="all" onChange={(e) => onCheckBoxFilterChange(e.target.value)}>
                <FormControlLabel
                  value="all"
                  control={<Radio className={classes.radio} size="small" />}
                  label={
                    <TranslateTextComponent className={classes.radioText} capitalize>
                      all
                    </TranslateTextComponent>
                  }
                />
                {paymentMethods.map((paymentMethod) => (
                  <FormControlLabel
                    key={paymentMethod.uuid}
                    value={paymentMethod.uuid}
                    control={<Radio className={classes.radio} size="small" />}
                    label={
                      <TranslateTextComponent className={classes.radioText} capitalize>
                        {getPaymentTranslation(paymentMethod.code)}
                      </TranslateTextComponent>
                    }
                  />
                ))}
              </RadioGroup>
            }
          />
          <Input
            title="display-paid-invoices"
            component={
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <TranslateTextComponent capitalize>yes</TranslateTextComponent>
                <Switch className={classes.switch} color="primary" />
                <TranslateTextComponent capitalize>no</TranslateTextComponent>
              </Box>
            }
          />
        </Box>
        <Box className={classesButtons.container}>
          <Button className={classesButtons.button} color="primary" variant="contained">
            <TranslateTextComponent>report</TranslateTextComponent>
          </Button>
        </Box>
      </LeftContainer>
      <MainContainer>
        <HeaderIncome date={filterDate} onDateChange={onDateChange} />
        {/* <WideCardContainer summaryComponent={<SummaryWithoutBorder data={null} summaryType={INVOICES} />} detailsComponent={<InvoicesDetails />} /> */}
      </MainContainer>
    </Wrapper>
  );
};

export default Invoices;
