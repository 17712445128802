import React from 'react';
import { Box, Button } from '@material-ui/core';
import styles from '../../styles/shared/keyboard';
import { ReactComponent as Backspace } from '../../assets/images/cash/backspace.svg';
import { ReactComponent as Return } from '../../assets/images/cash/return.svg';

const Keyboard = ({ onClick }) => {
  const classes = styles();

  return (
    <Box className={classes.container}>
      <Button className={classes.button} style={{ gridArea: 'zero' }} onClick={() => onClick(0)}>
        0
      </Button>
      <Button className={classes.button} style={{ gridArea: 'one' }} onClick={() => onClick(1)}>
        1
      </Button>
      <Button className={classes.button} style={{ gridArea: 'two' }} onClick={() => onClick(2)}>
        2
      </Button>
      <Button className={classes.button} style={{ gridArea: 'three' }} onClick={() => onClick(3)}>
        3
      </Button>
      <Button className={classes.button} style={{ gridArea: 'four' }} onClick={() => onClick(4)}>
        4
      </Button>
      <Button className={classes.button} style={{ gridArea: 'five' }} onClick={() => onClick(5)}>
        5
      </Button>
      <Button className={classes.button} style={{ gridArea: 'six' }} onClick={() => onClick(6)}>
        6
      </Button>
      <Button className={classes.button} style={{ gridArea: 'seven' }} onClick={() => onClick(7)}>
        7
      </Button>
      <Button className={classes.button} style={{ gridArea: 'eight' }} onClick={() => onClick(8)}>
        8
      </Button>
      <Button className={classes.button} style={{ gridArea: 'nine' }} onClick={() => onClick(9)}>
        9
      </Button>
      <Button className={classes.button} style={{ gridArea: 'dot' }} onClick={() => onClick('dot')}>
        .
      </Button>
      <Button className={classes.button} style={{ gridArea: 'cancel' }} onClick={() => onClick('cancel')}>
        C
      </Button>
      <Button className={classes.button} style={{ gridArea: 'tax-code' }} onClick={() => onClick('tax-code')}>
        CF
      </Button>
      <Button className={classes.button} style={{ gridArea: 'backspace' }} onClick={() => onClick('backspace')}>
        <Backspace />
      </Button>
      <Button className={classes.button} style={{ gridArea: 'return' }} onClick={() => onClick('return')}>
        <Return />
      </Button>
    </Box>
  );
};

export default Keyboard;
