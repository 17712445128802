import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { TextField as MUITextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import InputWithLabel from '../../../shared/InputWithLabel';
import Buttons from '../../../shared/Buttons';
import { CHANGE_PASSWORD } from '../../../../graphql/settings/account/mutations';
import { ModalContext } from '../../../../contexts/ModalContext';
import { TranslateTextComponent } from '../../../../translator';
import { getApolloErrors } from '../../../../apollo/ApolloProvider';

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column;
  height: 100%;

  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-flow: column;

  & > div:not(:last-child) {
    margin-bottom: 50px;
  }
`;

const RowContainer = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 20px;
  }
`;

const TextField = styled(MUITextField).attrs({ variant: 'outlined', color: 'primary', fullWidth: true })``;

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Required'),
  newPassword: Yup.string().required('Required'),
  confirmPassword: Yup.string('required')
    .required('Required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
});

const PasswordForm = () => {
  const modalContext = useContext(ModalContext);
  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD);

  const { values, errors, touched, isSubmitting, handleChange, handleBlur, handleReset, handleSubmit, resetForm } = useFormik({
    initialValues: { oldPassword: '', newPassword: '', confirmPassword: '' },
    validationSchema,
    onSubmit: ({ oldPassword, newPassword }, { setSubmitting }) => {
      changePassword({ variables: { oldPassword, newPassword } })
        .then(() => {
          modalContext.openModal({
            class: 'success',
            title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
            text: <TranslateTextComponent capitalize>password-changed</TranslateTextComponent>
          });
          resetForm();
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        });
      setSubmitting(false);
    }
  });

  return (
    <Container>
      <ColumnContainer style={{ flex: 1, alignItems: 'center', width: '70%', margin: 'auto' }}>
        <InputWithLabel label="old-password" error={touched.oldPassword && errors.oldPassword}>
          <TextField
            name="oldPassword"
            type="password"
            value={values.oldPassword}
            error={touched.oldPassword && !!errors.oldPassword}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
        <InputWithLabel label="new-password" error={touched.newPassword && errors.newPassword}>
          <TextField
            name="newPassword"
            type="password"
            value={values.newPassword}
            error={touched.newPassword && !!errors.newPassword}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
        <InputWithLabel label="confirm-password" error={touched.confirmPassword && errors.confirmPassword}>
          <TextField
            name="confirmPassword"
            type="password"
            value={values.confirmPassword}
            error={touched.confirmPassword && !!errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
      </ColumnContainer>
      <RowContainer>
        <Buttons
          containerStyle={{ justifyContent: 'space-between', marginBottom: 20 }}
          buttons={[
            { buttonType: 'reset', type: 'cancel', style: { fontSize: 20, fontWeight: 600, minWidth: 155 }, onClick: handleReset },
            {
              buttonType: 'submit',
              label: 'save',
              type: 'confirm',
              disabled: isSubmitting,
              isLoading: loading,
              style: { fontSize: 20, fontWeight: 600, minWidth: 155 },
              onClick: handleSubmit
            }
          ]}
        />
      </RowContainer>
    </Container>
  );
};

export default PasswordForm;
