/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { Box } from '@material-ui/core';
import { TranslateTextComponent, capitalizeFirstLetter } from '../../../../translator';

const textTranslate = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

const orderListColumns = () => {
  return [
    {
      id: 'name',
      label: textTranslate('name'),
      isSortable: false,
      align: 'left',
      render: (item) => item?.fbCoursesMenuItems?.map((course) => <Box key={course.id}>{`${capitalizeFirstLetter(course?.name ?? '-')}`}</Box>)
    },
    {
      id: 'quantity',
      label: textTranslate('quantity'),
      isSortable: false,
      align: 'left',
      render: (item) => item?.fbCoursesMenuItems?.map((course) => <Box key={course.id}>{`${course?.quantity ?? '-'}`}</Box>)
    },
    {
      id: 'price',
      label: textTranslate('unit-price'),
      isSortable: false,
      align: 'left',
      render: (item) =>
        item?.fbCoursesMenuItems?.map((course) => <Box key={course.id}>{course?.price ? `${Number(course?.price).toFixed(2)}` : '-'}</Box>)
    }
  ];
};
export default orderListColumns;
