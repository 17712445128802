/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import moment from 'moment';
import { TranslateTextComponent } from '../../../../translator';
import TableButton from '../../../shared/TableButton';
import { ReactComponent as BinIcon } from '../../../../assets/images/orders/bin.svg';
import { Dropdown } from '../../../../common-fe/src';
import getOrderBookingStatuses from '../../../../utils/getOrderBookingStatuses';

const textTranslate = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

const ordersColumns = ({ isDelivery, openModal, changeOrderStatus, language, drivers, onChangeDriver }) => {
  return [
    {
      id: 'id',
      // disablePadding: false,
      label: textTranslate('ID'),
      isSortable: false,
      align: 'left',
      render: (item) => item.id
    },
    {
      id: 'client',
      // disablePadding: false,
      label: textTranslate('client'),
      isSortable: false,
      align: 'left',
      render: (item) => `${item?.profile?.firstName} ${item?.profile?.lastName}`
    },
    {
      id: 'roomNumber',
      disablePadding: false,
      label: textTranslate('room-number'),
      isSortable: false,
      render: (item) => item.guestRoom
    },
    {
      id: 'requestTime',
      label: textTranslate('request-time'),
      isSortable: false,
      align: 'left',
      render: (item) => item.time
    },
    {
      id: isDelivery ? 'deliveryTime' : 'pickUpTime',
      disablePadding: false,
      label: isDelivery ? textTranslate('delivery-time') : textTranslate('pick-up-time'),
      isSortable: false,
      align: 'left',
      render: (item) => (item.deliveryTime ? moment(parseInt(item.deliveryTime, 10)).format('HH:mm') : null)
    },
    {
      id: 'notes',
      disablePadding: false,
      label: textTranslate('notes'),
      isSortable: false,
      align: 'left',
      render: (item) => item.notes
    },
    {
      id: 'status',
      disablePadding: false,
      label: textTranslate('status'),
      isSortable: false,
      width: '10%',
      align: 'left',
      render: (item) => (
        <Dropdown
          dropdownStyle={{ backgroundColor: 'white', fontSize: 11, textTransform: 'uppercase', minWidth: 140 }}
          value={item.status}
          items={getOrderBookingStatuses({ type: !isDelivery ? 'takeaway' : '', currentStatus: item.status, language, profile: item.profile })}
          onChange={({ target: { value } }) => {
            if (value !== item.status) changeOrderStatus({ selectedStatus: value, fbOrderUUID: item.uuid, order: item });
          }}
        />
      )
    },
    {
      id: 'driver',
      disablePadding: false,
      label: textTranslate('driver'),
      isSortable: false,
      width: '10%',
      align: 'left',
      render: (item) => (
        <Dropdown
          dropdownStyle={{ backgroundColor: 'white', fontSize: 11, textTransform: 'uppercase' }}
          value={item?.driver?.uuid}
          items={drivers?.map((driver) => ({ value: driver?.uuid, label: `${driver?.firstName ?? ''} ${driver?.lastName ?? ''}` } ?? []))}
          onChange={({ target: { value } }) => onChangeDriver(value, item.uuid)}
        />
      )
    },
    {
      id: 'details',
      disablePadding: false,
      label: textTranslate('details'),
      width: '8%',
      isSortable: false,
      align: 'left',
      render: (item) => <TableButton onClick={() => openModal(item)}>view</TableButton>
    },
    {
      id: 'delete',
      disablePadding: false,
      label: textTranslate('delete'),
      isSortable: false,
      width: '5%',
      align: 'left',
      render: (item) => <BinIcon fill="gray" style={{ cursor: 'default' }} />
    }
  ].filter((item) => isDelivery || (!isDelivery && item.id !== 'driver'));
};
export default ordersColumns;
