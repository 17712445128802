/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { TranslateTextComponent } from '../../translator';

const textTranslate = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

const getOrderColumns = () => {
  return [
    {
      id: 'category',
      disablePadding: true,
      width: '10%',
      label: textTranslate('category'),
      isSortable: true,
      render: (item) => null
    },
    {
      id: 'quantity',
      disablePadding: true,
      width: '15%',
      label: textTranslate('quantity'),
      isSortable: true,
      render: (item) => null
    },
    {
      id: 'description',
      disablePadding: true,
      width: '10%',
      label: textTranslate('description'),
      isSortable: false,
      render: (item) => null
    }
  ];
};

export default getOrderColumns;
