export const FbPaymentMethodsEnum = {
  CASH: 'CASH',
  CREDIT_CARD: 'CREDIT_CARD',
  BANK_TRANSFER: 'BANK_TRANSFER',
  BANK_CHECK: 'BANK_CHECK',
  ROOM_CHARGE: 'ROOM_CHARGE',
  PM: 'PM'
};

export const CreditCardTypes = {
  pagobancomat: 'pagobancomat',
  visa: 'visa',
  mastercard: 'mastercard',
  maestro: 'maestro',
  amex: 'amex'
};

export const getFiscalPrinterPaymentCode = (paymentMethod) => {
  switch (paymentMethod) {
    case FbPaymentMethodsEnum.CASH: {
      return '0';
    }
    case FbPaymentMethodsEnum.BANK_CHECK: {
      return '1';
    }
    case FbPaymentMethodsEnum.BANK_TRANSFER: {
      return '1';
    }
    case FbPaymentMethodsEnum.CREDIT_CARD: {
      return '2';
    }
    default: {
      return null;
    }
  }
};
