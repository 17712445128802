import { gql } from 'apollo-boost';
import { FB_ORDERS_FRAGMENT } from '../../fragments';

export const CREATE_SMART_SERVICE_ORDER = gql`
  mutation createSmartServiceOrder(
    $fbOutletUUID: String!
    $profileUUID: String
    $date: String!
    $time: String
    $clientName: String
    $guestRoom: String
    $notes: String
    $isSmartOrderServicePaid: Boolean
    $smartServiceItems: [SmartServiceInputDto!]!
  ) {
    createSmartServiceOrder(
      fbOutletUUID: $fbOutletUUID
      profileUUID: $profileUUID
      date: $date
      time: $time
      clientName: $clientName
      guestRoom: $guestRoom
      notes: $notes
      isSmartOrderServicePaid: $isSmartOrderServicePaid
      smartServiceItems: $smartServiceItems
    ) {
      ...fbOrdersFragment
    }
  }
  ${FB_ORDERS_FRAGMENT}
`;

export const SET_ORDER_STATUS = gql`
  mutation setOrderStatus($status: FbOrdersStatuses!, $fbOrderUUID: String!) {
    setOrderStatus(status: $status, fbOrderUUID: $fbOrderUUID) {
      ...fbOrdersFragment
    }
  }
  ${FB_ORDERS_FRAGMENT}
`;
