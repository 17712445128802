import React from 'react';
import { Tab } from '@material-ui/core';
import styles from '../../../styles/records/tabs/style';
import { TranslateTextComponent } from '../../../translator';

const TabItem = ({ label, selectedTab, value, onClick }) => {
  const classes = styles({ selected: selectedTab === label });

  return <Tab className={classes.tabItem} label={<TranslateTextComponent>{label}</TranslateTextComponent>} value={value} onClick={onClick} />;
};

export default TabItem;
