import React from 'react';
import { Tab } from '@material-ui/core';
import styled from 'styled-components/macro';
import styles from '../../../styles/records/tabs/style';
import { TranslateTextComponent } from '../../../translator';
import RoundedNotification from '../RoundedNotification';

const LabelContainer = styled.div`
  display: flex;
  align-items: center;

  & > *:first-child {
    margin-right: 10px;
  }
`;

const TabItem = ({ label, currentTab, value, notificationCounter, tabItemsStyle, style, onClick }) => {
  const classes = styles({ selected: currentTab === value });

  return (
    <Tab
      className={classes.tabItem}
      label={
        <LabelContainer>
          <TranslateTextComponent>{label?.toLowerCase()?.replace(/\s/g, '-')}</TranslateTextComponent>
          {notificationCounter > 0 && <RoundedNotification counter={notificationCounter} isActive={currentTab === value} />}
        </LabelContainer>
      }
      value={value}
      style={{ ...tabItemsStyle, ...style }}
      onClick={onClick}
    />
  );
};

export default TabItem;
