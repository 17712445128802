import React, { useContext } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import { Button, CircularProgress, InputAdornment, TextareaAutosize, TextField } from '@material-ui/core';
import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { LanguageContext } from '../../contexts/LanguageContext';
import { GET_FB_OUTLETS } from '../../graphql/outlets/queries';
import { GET_FB_SERVICES } from '../../graphql/settings/service/queries';
import { DataPicker, Dropdown, primaryColor } from '../../common-fe/src';
import PageLayout from '../shared/PageLayout';
import InputWithLabel from '../shared/InputWithLabel';
import Buttons from '../shared/Buttons';
import useCustomQuery from '../../hooks/useCustomQuery';
import { capitalizeFirstLetter, translate, TranslateTextComponent } from '../../translator';
import { SAVE_FB_SERVICE_REVENUE } from '../../graphql/revenues/mutations';
import { GET_FB_SERVICE_REVENUE } from '../../graphql/revenues/queries';
import { ROUTE_EDIT_DAILY_REPORT } from '../../router/routes';
import { ModalContext } from '../../contexts/ModalContext';
import modalStyle from '../../common-fe/src/styles/shared/modalStyle';
import { getApolloErrors } from '../../apollo/ApolloProvider';

const Container = styled.form`
  display: flex;
  flex-flow: column;
`;

const StyledInputWithLabel = styled(InputWithLabel).attrs({
  containerStyle: { display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20 },
  labelStyle: { marginRight: 10, flex: 0.3, textAlign: 'right' }
})``;

const DailyRevenueForm = ({ fbServiceRevenueUUID }) => {
  const history = useHistory();
  const { language } = useContext(LanguageContext);
  const modalContext = useContext(ModalContext);

  const classesModal = modalStyle();

  const getFbServiceRevenue = useCustomQuery(GET_FB_SERVICE_REVENUE, { variables: { fbServiceRevenueUUID }, skip: !fbServiceRevenueUUID });
  const [saveFbServiceRevenue] = useMutation(SAVE_FB_SERVICE_REVENUE);

  const validationSchema = Yup.object().shape({
    restaurant: Yup.string().required(capitalizeFirstLetter(translate('required', language))),
    date: Yup.string()
      .typeError(capitalizeFirstLetter(translate('invalid-date', language)))
      .required(capitalizeFirstLetter(translate('required', language))),
    fbServiceUUID: Yup.string().required(capitalizeFirstLetter(translate('required', language)))
  });

  const { values, errors, touched, isSubmitting, handleBlur, handleSubmit, handleReset, handleChange, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      restaurant: getFbServiceRevenue?.data?.getFbServiceRevenue?.fbService?.fbOutlet?.uuid || '',
      date: getFbServiceRevenue?.data?.getFbServiceRevenue?.date
        ? moment(getFbServiceRevenue?.data?.getFbServiceRevenue?.date).format('YYYY-MM-DD')
        : null,
      fbServiceUUID: getFbServiceRevenue?.data?.getFbServiceRevenue?.fbService?.uuid || '',
      totalRevenue: getFbServiceRevenue?.data?.getFbServiceRevenue?.totalRevenue || '',
      individualCoversNumber: getFbServiceRevenue?.data?.getFbServiceRevenue?.individualCoversNumber || '',
      groupCoversNumber: getFbServiceRevenue?.data?.getFbServiceRevenue?.groupCoversNumber || '',
      walkInCoversNumber: getFbServiceRevenue?.data?.getFbServiceRevenue?.walkInCoversNumber || '',
      complementaryCoversNumber: getFbServiceRevenue?.data?.getFbServiceRevenue?.complementaryCoversNumber || '',
      notes: getFbServiceRevenue?.data?.getFbServiceRevenue?.notes || ''
    },
    validationSchema,

    onSubmit: (values, { setSubmitting }) => {
      saveFbServiceRevenue({
        variables: {
          fbServiceRevenueData: {
            date: moment(values.date, 'YYYY-MM-DD'),
            totalRevenue: values?.totalRevenue ? Number(values?.totalRevenue) : null,
            individualCoversNumber: values?.individualCoversNumber ? Number(values?.individualCoversNumber) : null,
            groupCoversNumber: values?.groupCoversNumber ? Number(values?.groupCoversNumber) : null,
            walkInCoversNumber: values?.walkInCoversNumber ? Number(values?.walkInCoversNumber) : null,
            complementaryCoversNumber: values?.complementaryCoversNumber ? Number(values?.complementaryCoversNumber) : null,
            notes: values.notes
          },
          fbServiceUUID: values?.fbServiceUUID,
          fbServiceRevenueUUID
        }
      })
        .then((res) => {
          modalContext.openModal({
            class: 'success',
            title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
            text: (
              <TranslateTextComponent capitalize>
                {fbServiceRevenueUUID ? 'revenue-successfully-edited' : 'revenue-successfully-created'}
              </TranslateTextComponent>
            ),
            actionButtons: [
              <Button
                key={0}
                variant="outlined"
                color="primary"
                className={classesModal.invertedButton}
                onClick={() => {
                  modalContext.closeModal();
                  history.push(`${ROUTE_EDIT_DAILY_REPORT}/${res.data.saveFbServiceRevenue.uuid}`);
                }}
              >
                <TranslateTextComponent>ok</TranslateTextComponent>
              </Button>
            ]
          });
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        })
        .finally(() => setSubmitting(false));
    }
  });

  const getFbOutlets = useCustomQuery(GET_FB_OUTLETS);
  const getFbServices = useCustomQuery(GET_FB_SERVICES, {
    variables: { fbOutletUUID: values?.restaurant },
    skip: !values?.restaurant
  });

  return (
    <PageLayout>
      <Container>
        <StyledInputWithLabel label="restaurant">
          <div style={{ flex: 1 }}>
            <Dropdown
              name="restaurant"
              dropdownStyle={{ width: '100%', height: 35 }}
              items={getFbOutlets?.data?.getFbOutlets?.data.map((outlet) => ({ label: outlet.name, value: outlet.uuid }))}
              error={touched.restaurant && !!errors.restaurant}
              errorLabel={touched.restaurant && errors.restaurant}
              value={values?.restaurant}
              onChange={handleChange}
            />
          </div>
        </StyledInputWithLabel>

        <StyledInputWithLabel label="date">
          <div style={{ flex: 1 }}>
            <DataPicker
              fullWidth
              containerStyle={{ height: 35 }}
              error={touched.date && values.date === null}
              errorLabel={touched.date && errors.date}
              value={values.date}
              onChange={(value) => setFieldValue('date', value)}
            />
          </div>
        </StyledInputWithLabel>

        <StyledInputWithLabel label="service">
          <div style={{ flex: 1 }}>
            <Dropdown
              name="fbServiceUUID"
              dropdownStyle={{ width: '100%', height: 35 }}
              items={getFbServices?.data?.getFbServices?.data.map((service) => ({ label: service.name, value: service.uuid }))}
              error={touched.fbServiceUUID && !!errors.fbServiceUUID}
              errorLabel={touched.fbServiceUUID && errors.fbServiceUUID}
              value={values?.fbServiceUUID}
              onChange={handleChange}
            />
          </div>
        </StyledInputWithLabel>

        <StyledInputWithLabel label="total-report">
          <div style={{ flex: 1 }}>
            <TextField
              name="totalRevenue"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.totalRevenue}
              error={touched.totalRevenue && !!errors.totalRevenue}
              onBlur={handleBlur}
              onChange={handleChange}
              InputProps={{
                endAdornment: getFbServiceRevenue.loading && (
                  <InputAdornment position="start">
                    <CircularProgress color="primary" style={{ marginRight: 10, width: 25, height: 25 }} />
                  </InputAdornment>
                ),
                style: { height: 35 }
              }}
            />
          </div>
        </StyledInputWithLabel>

        <StyledInputWithLabel label="individual-covers-number">
          <div style={{ flex: 1 }}>
            <TextField
              name="individualCoversNumber"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.individualCoversNumber}
              error={touched.individualCoversNumber && !!errors.individualCoversNumber}
              onBlur={handleBlur}
              onChange={handleChange}
              InputProps={{
                endAdornment: getFbServiceRevenue.loading && (
                  <InputAdornment position="start">
                    <CircularProgress color="primary" style={{ marginRight: 10, width: 25, height: 25 }} />
                  </InputAdornment>
                ),
                style: { height: 35 }
              }}
            />
          </div>
        </StyledInputWithLabel>

        <StyledInputWithLabel label="group-covers-number">
          <div style={{ flex: 1 }}>
            <TextField
              name="groupCoversNumber"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.groupCoversNumber}
              error={touched.groupCoversNumber && !!errors.groupCoversNumber}
              onBlur={handleBlur}
              onChange={handleChange}
              InputProps={{
                endAdornment: getFbServiceRevenue.loading && (
                  <InputAdornment position="start">
                    <CircularProgress color="primary" style={{ marginRight: 10, width: 25, height: 25 }} />
                  </InputAdornment>
                ),
                style: { height: 35 }
              }}
            />
          </div>
        </StyledInputWithLabel>

        <StyledInputWithLabel label="walk-in-covers-number">
          <div style={{ flex: 1 }}>
            <TextField
              name="walkInCoversNumber"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.walkInCoversNumber}
              error={touched.walkInCoversNumber && !!errors.walkInCoversNumber}
              onBlur={handleBlur}
              onChange={handleChange}
              InputProps={{
                endAdornment: getFbServiceRevenue.loading && (
                  <InputAdornment position="start">
                    <CircularProgress color="primary" style={{ marginRight: 10, width: 25, height: 25 }} />
                  </InputAdornment>
                ),
                style: { height: 35 }
              }}
            />
          </div>
        </StyledInputWithLabel>

        <StyledInputWithLabel label="complementary-covers-number">
          <div style={{ flex: 1 }}>
            <TextField
              name="complementaryCoversNumber"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.complementaryCoversNumber}
              error={touched.complementaryCoversNumber && !!errors.complementaryCoversNumber}
              onBlur={handleBlur}
              onChange={handleChange}
              InputProps={{
                endAdornment: getFbServiceRevenue.loading && (
                  <InputAdornment position="start">
                    <CircularProgress color="primary" style={{ marginRight: 10, width: 25, height: 25 }} />
                  </InputAdornment>
                ),
                style: { height: 35 }
              }}
            />
          </div>
        </StyledInputWithLabel>

        <StyledInputWithLabel label="notes">
          <div style={{ flex: 1 }}>
            <TextareaAutosize
              placeholder={capitalizeFirstLetter(translate('notes', language))}
              rowsMax={4}
              style={{
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                height: 'auto',
                width: '100%',
                padding: 10,
                minHeight: 150,
                border: `1px solid ${primaryColor}`,
                borderRadius: 4
              }}
              value={values.notes}
              onChange={(e) => setFieldValue('notes', e.target.value)}
            />
          </div>
        </StyledInputWithLabel>

        <Buttons
          containerStyle={{ justifyContent: 'space-between' }}
          buttons={[
            {
              buttonType: 'reset',
              style: { padding: '5px 10px', minWidth: 155, fontSize: 20, fontWeight: 600 },
              type: 'cancel',
              onClick: handleReset
            },
            {
              buttonType: 'submit',
              style: { padding: '5px 10px', minWidth: 155, fontSize: 20, fontWeight: 600 },
              label: 'save',
              type: 'confirm',
              disabled: isSubmitting,
              onClick: handleSubmit
            }
          ]}
        />
      </Container>
    </PageLayout>
  );
};

export default DailyRevenueForm;
