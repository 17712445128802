import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';
import { Button as MUIButton } from '@material-ui/core';
import QRCodeSVG from 'qrcode.react';
import ReactToPrint from 'react-to-print';
import { TranslateTextComponent } from '../../../translator';
import { headerHeight, CustomModal } from '../../../common-fe/src';
import { ReactComponent as MohLogo } from '../../../assets/images/MohLogo.svg';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${() => `calc(100vh - ${headerHeight + 200}px)`};
`;

const Button = styled(MUIButton).attrs({ variant: 'contained', color: 'primary' })`
  color: white;
  font-size: ${({ modal }) => (modal ? '20px' : '30px')};
`;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
`;

const QRCode = () => {
  const [showModal, setShowModal] = useState(false);
  const modalBodyRef = useRef(null);

  return (
    <>
      <Container>
        <Button variant="contained" color="primary" onClick={() => setShowModal(true)}>
          <TranslateTextComponent uppercase>generate-menu-qr-code</TranslateTextComponent>
        </Button>
      </Container>
      <CustomModal
        showModal={showModal}
        width="auto"
        height="auto"
        styleBody={{ minWidth: 300, minHeight: 330 }}
        styleFooter={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}
        footer={
          <ReactToPrint
            trigger={() => (
              <Button modal>
                <TranslateTextComponent capitalize>print</TranslateTextComponent>
              </Button>
            )}
            content={() => modalBodyRef.current}
          />
        }
        onClose={() => setShowModal(false)}
      >
        <ModalBody ref={modalBodyRef}>
          <MohLogo />
          <QRCodeSVG value="38HwSwuuznoSUNt3xFSrZgPNbDxNuYhtW1" size={200} />
        </ModalBody>
      </CustomModal>
    </>
  );
};

export default QRCode;
