import React, { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import styles from '../../../styles/billsManagement/input';
import { SearchBar } from '../../../common-fe/src';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translate, capitalizeFirstLetter } from '../../../translator';

const Input = ({ component, title, type, customIcon, placeholder, style, onChange }) => {
  const { language } = useContext(LanguageContext);
  const classes = styles();

  const getInput = (type) => {
    if (component) return component;
    switch (type) {
      case 'switch':
        return 'switch';
      case 'radio':
        return 'checkbox';
      default:
        return (
          <SearchBar
            customIcon={customIcon}
            placeholder={placeholder ? capitalizeFirstLetter(translate(placeholder, language)) : ''}
            style={{ height: 35 }}
            onChange={onChange}
          />
        );
    }
  };

  return (
    <Box className={classes.container} style={style}>
      <Typography className={classes.title}>{capitalizeFirstLetter(translate(title, language))}</Typography>
      {getInput(type)}
    </Box>
  );
};

export default Input;
