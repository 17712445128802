import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { TranslateTextComponent } from '../../translator';
import { redButton } from '../../styles/theme';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  width: 100%;
  margin-bottom: ${({ withMarginBottom }) => (withMarginBottom ? '25px' : '0px')};

  & .MuiOutlinedInput-input {
    padding: 0;
    height: 40px;
  }

  & .MuiInputBase-input {
    padding: 0;
    text-indent: 10px;
  }
`;

const Label = styled(TranslateTextComponent)`
  margin-right: ${({ horizontal }) => (horizontal ? '10px' : '0px')};
  margin-bottom: ${({ horizontal }) => (horizontal ? '0px' : '5px')};
  color: #55595c;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
`;

const Error = styled(TranslateTextComponent).attrs({ capitalize: true })`
  color: ${() => redButton};
  font-size: 10px;
`;

const InputWithLabel = ({ containerStyle, label, labelStyle, withMarginBottom, error, horizontal, children }) => {
  return (
    <Container style={containerStyle} withMarginBottom={withMarginBottom}>
      {horizontal ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Label style={labelStyle} horizontal={horizontal}>
            {label}
          </Label>
          {children}
        </div>
      ) : (
        <>
          <Label style={labelStyle} horizontal={horizontal}>
            {label}
          </Label>
          {children}
        </>
      )}
      {error && typeof error === 'function' ? <Error>{error()}</Error> : null}
      {error && typeof error !== 'function' ? <Error>{error}</Error> : null}
    </Container>
  );
};

InputWithLabel.propTypes = {
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  horizontal: PropTypes.bool
};

InputWithLabel.defaultProps = {
  label: '',
  error: '',
  horizontal: false
};

export default InputWithLabel;
