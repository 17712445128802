import React from 'react';
import { Typography, Grid } from '@material-ui/core';

import styles from '../../styles/shared/tableListDetails';

const SingleTableDetail = ({ title, description }) => {
  const classes = styles();

  return (
    <Grid item xs={2} md={2} lg={2}>
      <Typography className={classes.titleTypography}>{title}</Typography>
      {Array.isArray(description) ? (
        description.map((value, index) => {
          return (
            <Typography key={index} className={classes.descriptionTypography}>
              {value || null}
            </Typography>
          );
        })
      ) : (
        <Typography className={classes.descriptionTypography}>{description || null}</Typography>
      )}
    </Grid>
  );
};
export default SingleTableDetail;
