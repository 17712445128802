import React, { useState, useContext } from 'react';
import { Typography, Box, Grid, Radio, RadioGroup, FormControlLabel } from '@material-ui/core/';

import { TranslateTextComponent, translate, capitalizeFirstLetter } from '../../../translator';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { InputTextLabel, MaterialIcon, CustomModal, SearchBar, TimePicker } from '../../../common-fe';
import Footer from '../Footer';
import styles from '../../../styles/settings/settingsStyle';
import { borderTheme, mainTextColor } from '../../../styles/theme';

const inputStyle = { display: 'flex', justifyContent: 'space-between', border: borderTheme, borderRadius: 4, width: 150, height: 30 };
const labelStyle = { fontWeight: '600', marginRight: 5, color: mainTextColor, fontSize: 14 };

const timePickerStyle = { width: '50%', minHeight: 30 };

const ServiceForm = ({ serviceSettings, updateServiceSettings, onClickSave, onClickDelete, errorFormMap }) => {
  const classes = styles();

  const [isIconModalOpen, setIsIconModalOpen] = useState(false);

  const languageContext = useContext(LanguageContext);

  const [searchedIcon, setSearchedIcon] = useState('');

  const openIconModal = () => {
    setIsIconModalOpen(true);
  };

  const closeIconModal = () => {
    setIsIconModalOpen(false);
  };

  return (
    <Box>
      <Grid container direction="row" justify="space-between" alignItems="center" className={classes.footerGridContainer}>
        <Grid className={classes.settingItem} item xs={3}>
          <InputTextLabel
            error={errorFormMap.has('name')}
            errorLabel={errorFormMap.get('name')}
            inputType="string"
            inputStyle={inputStyle}
            title={capitalizeFirstLetter(translate('name', languageContext.language))}
            placeholder={capitalizeFirstLetter(translate('breakfast', languageContext.language))}
            className={classes.noteTextField}
            classNameBox={classes.firstBox}
            onChange={(event) => updateServiceSettings('name', event.target.value)}
            value={serviceSettings.name}
          />
        </Grid>
        <Grid item xs={3} className={classes.settingItem}>
          <Typography className={classes.settingTypography}>
            <TranslateTextComponent capitalize>start-time</TranslateTextComponent>
          </Typography>
          <TimePicker
            onChange={(value) => updateServiceSettings('startTime', value)}
            error={errorFormMap.has('startTime')}
            errorLabel={errorFormMap.get('startTime')}
            value={serviceSettings.startTime}
            timePickerStyle={timePickerStyle}
          />
        </Grid>

        <Grid item xs={3} className={classes.settingItem}>
          <Typography className={classes.settingTypography}>
            <TranslateTextComponent capitalize>end-time</TranslateTextComponent>
          </Typography>
          <TimePicker
            onChange={(value) => updateServiceSettings('endTime', value)}
            error={errorFormMap.has('endTime')}
            errorLabel={errorFormMap.get('endTime')}
            value={serviceSettings.endTime}
            timePickerStyle={timePickerStyle}
          />
        </Grid>

        <Grid className={classes.settingItem} item xs={3}>
          <Typography style={labelStyle}>
            <TranslateTextComponent capitalize>state</TranslateTextComponent>
          </Typography>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <RadioGroup
              row
              value={serviceSettings.isActive}
              defaultValue={serviceSettings.isActive}
              onChange={(e) => updateServiceSettings('isActive', e.target.value)}
              className={classes.footerRadioGroup}
            >
              <FormControlLabel
                value="activated"
                control={
                  <Radio
                    checked={serviceSettings.isActive === 'activated'}
                    className={errorFormMap.get('isActive') && [classes.radio, 'error'].join(' ')}
                  />
                }
                label={
                  <Typography style={{ fontSize: 14 }}>
                    <TranslateTextComponent capitalize>activated</TranslateTextComponent>
                  </Typography>
                }
              />
              <FormControlLabel
                value="disabled"
                control={
                  <Radio
                    checked={serviceSettings.isActive === 'disabled'}
                    className={errorFormMap.get('isActive') && [classes.radio, 'error'].join(' ')}
                  />
                }
                label={
                  <Typography style={{ fontSize: 14 }}>
                    <TranslateTextComponent capitalize>disabled-state</TranslateTextComponent>
                  </Typography>
                }
              />
            </RadioGroup>

            <span style={{ color: 'red', fontSize: 10 }}>{errorFormMap.get('isActive') && errorFormMap.get('isActive')}</span>
          </Box>
        </Grid>
      </Grid>

      <Grid container direction="row" justify="space-between" alignItems="center" className={classes.footerGridContainer}>
        <Grid item xs={3} className={classes.settingItem}>
          <Typography className={classes.settingTypography}>
            <TranslateTextComponent capitalize>last-reservation</TranslateTextComponent>
          </Typography>
          <TimePicker
            onChange={(value) => updateServiceSettings('lastReservation', value)}
            error={errorFormMap.has('lastReservation')}
            errorLabel={errorFormMap.get('lastReservation')}
            value={serviceSettings.lastReservation}
            timePickerStyle={{ width: '60%', minHeight: 30 }}
          />
        </Grid>

        <Grid className={classes.settingItem} item xs={3}>
          <InputTextLabel
            error={errorFormMap.has('slotSizeMinutes')}
            errorLabel={errorFormMap.get('slotSizeMinutes')}
            inputStyle={{ width: 40 }}
            title={capitalizeFirstLetter(translate('time-slot-minutes', languageContext.language))}
            placeholder="0"
            className={classes.noteTextField}
            classNameBox={classes.footerBoxes}
            onChange={(event) => updateServiceSettings('slotSizeMinutes', event.target.value)}
            value={serviceSettings.slotSizeMinutes}
          />
        </Grid>

        <Grid className={classes.settingItem} item xs={3}>
          <InputTextLabel
            error={errorFormMap.has('maxGuestsForSlot')}
            errorLabel={errorFormMap.get('maxGuestsForSlot')}
            inputStyle={{ width: 40 }}
            title={capitalizeFirstLetter(translate('max-guests-per-slot', languageContext.language))}
            placeholder="0"
            className={classes.noteTextField}
            classNameBox={classes.footerBoxes}
            onChange={(event) => updateServiceSettings('maxGuestsForSlot', event.target.value)}
            value={serviceSettings.maxGuestsForSlot}
          />
        </Grid>

        <Grid className={classes.settingItem} item xs={3}>
          <Typography className={classes.iconTypography}>
            <TranslateTextComponent capitalize>icon</TranslateTextComponent>
          </Typography>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <Box className={errorFormMap.get('icon') ? [classes.iconBox, 'error'].join(' ') : classes.iconBox} onClick={() => openIconModal()}>
              <MaterialIcon iconName={serviceSettings.icon} />
            </Box>
            <span style={{ color: 'red', fontSize: 10 }}>{errorFormMap.get('icon') && errorFormMap.get('icon')}</span>
          </Box>
        </Grid>
      </Grid>
      <Footer onClickSave={onClickSave} onClickDelete={onClickDelete} footerStyle={{ paddingBottom: 100 }} />
      {isIconModalOpen && (
        <CustomModal
          showModal={isIconModalOpen}
          onClose={closeIconModal}
          styleHeader={{
            borderRadius: 10,
            padding: '40px 30px',
            height: 60,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            backgroundColor: '#EDFAFF'
          }}
          header={
            <SearchBar style={{ height: 40, fontSize: 12, width: '100%' }} value={searchedIcon} onChange={(e) => setSearchedIcon(e.target.value)} />
          }
        >
          <Grid
            container
            style={{
              padding: '10px 10px 10px 25px',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              overflowY: 'scroll',
              height: '100%'
            }}
          >
            {materialIconName.map((icon, index) => {
              if (icon.includes(searchedIcon)) {
                return (
                  <MaterialIcon
                    iconName={icon}
                    key={index}
                    style={{
                      cursor: 'pointer',
                      marginRight: 10,
                      marginBottom: 10,
                      border: borderTheme,
                      width: 40,
                      height: 40,
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: 10,
                      paddingLeft: 6
                    }}
                    onClick={() => {
                      updateServiceSettings('icon', icon);
                      closeIconModal();
                    }}
                  />
                );
              }
              return null;
            })}
          </Grid>
        </CustomModal>
      )}
    </Box>
  );
};
export default ServiceForm;

export const materialIconName = [
  'accessible',
  'child_care',
  'child_friendly',
  'person',
  'ac_unit',
  'access_alarm',
  'access_time',
  'accessibility',
  'accessible_forward',
  'account_balance',
  'account_box',
  'account_circle',
  'add',
  'delete',
  'add_alarm',
  'add_alert',
  'add_circle',
  'add_circle_outline',
  'add_location',
  'add_comment',
  'adjust',
  'airplanemode_active',
  'airplanemode_inactive',
  'alarm',
  'alarm_add',
  'alarm_off',
  'alarm_onn',
  'all_inclusive',
  'apps',
  'arrow_back',
  'arrow_downward',
  'arrow_forward',
  'arrow_left',
  'arrow_right',
  'attach_file',
  'attach_money',
  'block',
  'brightness_1',
  'brightness_2',
  'brightness_3',
  'brightness_4',
  'brightness_5',
  'brightness_6',
  'brightness_7',
  'brightness_auto',
  'brightness_high',
  'brightness_low',
  'brightness_medium',
  'cake',
  'calendar_today',
  'call',
  'call_end',
  'camera',
  'camera_alt',
  'cancel',
  'check',
  'check_circle',
  'close',
  'cloud',
  'cloud_off',
  'cloud_queue',
  'control_point',
  'create',
  'directions_run',
  'directions_walk',
  'done',
  'done_all',
  'done_outline',
  'drafts',
  'edit',
  'eco',
  'emoji_food_beverage',
  'emoji_nature',
  'emoji_people',
  'error',
  'error_outline',
  'euro',
  'event',
  'event_available',
  'event_busy',
  'event_note',
  'expand_less',
  'expand_more',
  'explore',
  'face',
  'fastfood',
  'favorite',
  'flare',
  'fitness_center',
  'flag',
  'free_breakfast',
  'group',
  'link',
  'local_bar',
  'local_cafe',
  'local_dining',
  'local_drink',
  'local_florist',
  'local_phone',
  'local_pizza',
  'local_hospital',
  'lock',
  'lock_open',
  'looks',
  'loop',
  'mail',
  'menu',
  'menu_book',
  'message',
  'mood',
  'mood_bad',
  'nature',
  'navigation',
  'nights_stay',
  'outdoor_grill',
  'pause',
  'payment',
  'people',
  'people_alt',
  'people_outline',
  'person_add',
  'person_outline',
  'person_add_disabled',
  'pets',
  'pregnant_woman',
  'radio',
  'remove_red_eye ',
  'report',
  'report_off',
  'restaurant',
  'restaurant_menu',
  'restore',
  'save',
  'sentiment_dissatisfied',
  'sentiment_satisfied',
  'sentiment_satisfied_alt',
  'sentiment_very_dissatisfied',
  'sentiment_very_satisfied',
  'single_bed',
  'spa',
  'tag_face',
  'search',
  'kitchen',
  'waves',
  'web_sunny',
  'cloudy',
  'wc',
  'visibility',
  'swap_vert',
  'subway'
];
