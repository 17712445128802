import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Button, Grid } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import debounce from 'lodash/debounce';
import PageLayout from '../shared/PageLayout';
import { CommonTable } from '../../common-fe/src';
import { TranslateTextComponent } from '../../translator';
import guestProfilesColumns from './table/guestProfilesColumns';
import useCustomQuery from '../../hooks/useCustomQuery';
import { GET_GUEST_PROFILES } from '../../graphql/guests/queries';
import { DELETE_GUEST } from '../../graphql/guests/mutations';
import { ModalContext } from '../../contexts/ModalContext';
import { AuthContext } from '../../contexts/AuthContext';
import modalStyle from '../../styles/shared/modalStyle';
import { getApolloErrors } from '../../apollo/ApolloProvider';
import { ROUTE_GUEST_PROFILE } from '../../router/routes';
import CheckInModal from '../users/modals/CheckInModal';
import CustomFilters from '../shared/CustomFilters/CustomFilters';
import profileCSVFields from './profileCsvFields';
import CSVCheckbox from './CSVCheckbox';

const initialFilters = {
  orderBy: 'id',
  orderType: 'DESC',
  page: 1,
  pageSize: 10,
  keyword: '',
  checkinDate: null,
  checkoutDate: null,
  roomNumber: null,
  isInHouse: false
};
const initialCsvFields = profileCSVFields.map(({ label }) => label);
const GuestProfiles = () => {
  const modalContext = useContext(ModalContext);
  const { user } = useContext(AuthContext);
  const authToken = user?.access_token;
  const [isLoading, setIsLoading] = useState(false);
  const [queryFilters, setQueryFilters] = useState(initialFilters);
  const [profileUUID, setProfileUUID] = useState(false);
  const [profileKeyword, setProfileKeyword] = useState('');
  const [profiles, setProfiles] = useState([]);
  const [csvFields, setCsvFields] = useState(initialCsvFields);
  const [checkInModalData, setCheckInModalData] = useState(null);
  const history = useHistory();

  const modalClasses = modalStyle();

  // Queries
  const getGuestProfiles = useCustomQuery(GET_GUEST_PROFILES, {
    variables: {
      ...queryFilters,
      checkinDate: moment(queryFilters?.checkinDate).isValid() ? moment(queryFilters.checkinDate).format('YYYY-MM-DD') : null,
      checkoutDate: moment(queryFilters?.checkoutDate).isValid() ? moment(queryFilters.checkoutDate).format('YYYY-MM-DD') : null
    },
    networkPolicy: 'no-cache',
    skip: true
  });

  useEffect(() => {
    if (queryFilters?.orderBy === 'room.number') {
      setQueryFilters((prev) => ({ ...prev, isInHouse: true }));
    }
  }, [queryFilters?.orderBy, queryFilters?.orderType]);

  const [deleteGuest] = useMutation(DELETE_GUEST);

  const exportGuests = () => {
    modalContext.openModal({
      class: 'primary',
      paperStyle: { maxWidth: '70%' },
      title: 'Select export fields',
      content: (
        <Grid container style={{ width: '60%' }}>
          {profileCSVFields.map(({ label }, i) => (
            <Grid key={i}>
              <CSVCheckbox
                label={label}
                isCheckedDefault={csvFields.indexOf(label) > -1}
                onChange={() => {
                  const fields = csvFields;
                  if (fields.indexOf(label) === -1) fields.push(label);
                  else fields.splice(fields.indexOf(label), 1);
                  setCsvFields([...fields]);
                }}
              />
            </Grid>
          ))}
        </Grid>
      ),
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            modalContext.setModalLoading(true);
            axios
              .post(
                `${process.env.REACT_APP_SERVER_URL}/profiles/download-csv`,
                {
                  ...queryFilters,
                  keyword: profileKeyword,
                  checkinDate: moment(queryFilters?.checkinDate).isValid() ? moment(queryFilters.checkinDate).format('YYYY-MM-DD') : null,
                  checkoutDate: moment(queryFilters?.checkoutDate).isValid() ? moment(queryFilters.checkoutDate).format('YYYY-MM-DD') : null,
                  page: null,
                  pageSize: null,
                  csvFields
                },
                { headers: { Authorization: `Bearer ${authToken}` } }
              )
              .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `profile_export${Date.now()}.csv`);
                document.body.appendChild(link);
                link.click();
                modalContext.closeModal();
                modalContext.setModalLoading(false);
                setCsvFields(initialCsvFields);
              })
              .catch((error) => {
                modalContext.setModalLoading(false);
                modalContext.closeModal();
                console.log(error);
                window.alert('exporting error');
              });
          }}
        >
          <TranslateTextComponent>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const onDeleteGuest = ({ guestUUID }) => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>delete-profile-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>delete-profile-description</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            modalContext.setModalLoading(true);
            deleteGuest({ variables: { guestUUID } })
              .then(() => {
                refetch();
                modalContext.setModalLoading(false);
                modalContext.openModal({
                  class: 'success',
                  title: <TranslateTextComponent capitalize>profile-deleted-success-title</TranslateTextComponent>,
                  text: <TranslateTextComponent capitalize>profile-deleted-success-description</TranslateTextComponent>
                });
              })
              .catch((err) => {
                modalContext.setModalLoading(false);
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>an-error-occurred</TranslateTextComponent>,
                  text: getApolloErrors(err)[0] ?? <TranslateTextComponent capitalize>an-error-occurred</TranslateTextComponent>
                });
              });
          }}
        >
          <TranslateTextComponent>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const setTableSort = (orderBy) => {
    const orderType = queryFilters.orderType === 'ASC' ? 'DESC' : 'ASC';
    setQueryFilters((prev) => ({
      ...prev,
      orderBy,
      orderType
    }));
  };

  const setTablePages = (page, pageSize) => {
    setQueryFilters((prev) => ({
      ...prev,
      page,
      pageSize
    }));
  };

  const updateQueryFilters = (key, value) => {
    setQueryFilters((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const refetchWithDebounce = useCallback(
    debounce((key, value) => {
      updateQueryFilters('keyword', value);
      refetch(key, value);
    }, 1000),
    [queryFilters]
  );

  useEffect(() => {
    refetch();
  }, [queryFilters]);

  const refetch = (key, value) => {
    setIsLoading(true);
    getGuestProfiles
      .refetch({ ...queryFilters, [key]: value })
      .then(({ data }) => {
        setProfiles(data?.getGuestProfiles);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <PageLayout>
      <CustomFilters
        filters={[
          {
            type: 'searchbar',
            onChange: ({ target: { value } }) => {
              setProfileKeyword(value);
              refetchWithDebounce('keyword', value);
            },
            onClear: () => {
              setProfileKeyword('');
              refetchWithDebounce('keyword', '');
            },
            style: { flex: 1 },
            value: profileKeyword
          },
          {
            type: 'date',
            label: 'check-in',
            value: queryFilters?.checkinDate,
            onChange: (value) => updateQueryFilters('checkinDate', value),
            style: { flex: 1 }
          },
          {
            type: 'date',
            label: 'check-out',
            value: queryFilters?.checkoutDate,
            onChange: (value) => updateQueryFilters('checkoutDate', value),
            style: { flex: 1 }
          },
          {
            type: 'guest-room-searchbar',
            label: 'guest-room',
            value: queryFilters?.roomNumber,
            onChange: (text) => updateQueryFilters('roomNumber', text),
            onItemClick: (room) => {
              updateQueryFilters('roomNumber', room?.number);
            },
            resetRoomKeyword: JSON.stringify(initialFilters) === JSON.stringify(queryFilters),
            style: { flex: 1 }
          },
          {
            type: 'checkbox',
            label: 'in-house',
            value: queryFilters?.isInHouse,
            onClick: () => updateQueryFilters('isInHouse', !queryFilters?.isInHouse),
            style: { flex: 0.4 }
          },
          {
            type: 'reset-filter',
            style: { flex: 0.3 },
            onClearFilter: () => {
              setQueryFilters(initialFilters);
              setProfileKeyword('');
              refetchWithDebounce('keyword', '');
            }
          }
        ]}
      />
      <CommonTable
        isLoading={isLoading}
        headerCellStyle={{ padding: '20px 0px' }}
        isPaginationEnabled
        items={profiles?.data ?? []}
        orderBy={queryFilters.orderBy}
        orderType={queryFilters.orderType.toLocaleLowerCase()}
        page={queryFilters.page}
        pageSize={queryFilters.pageSize}
        setTableSort={setTableSort}
        setTablePages={setTablePages}
        rowsPerPageOptions={[5, 10, 25, 200]}
        columns={guestProfilesColumns({
          editProfile: (guestProfileUUID) => history.push(`${ROUTE_GUEST_PROFILE}/${guestProfileUUID}`),
          deleteGuest: onDeleteGuest,
          openCheckInModal: (user) => {
            setCheckInModalData(user);
            setProfileUUID(user?.uuid);
          }
        })}
        totalRows={profiles?.totalCount}
        rowCellStyle={{ paddingLeft: 0 }}
        tableHeader={
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#EDFAFF',
              minHeight: 28,
              divShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
            }}
          >
            <TranslateTextComponent capitalize>users-tot</TranslateTextComponent>: {profiles?.totalCount}
          </span>
        }
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '30px 10px 20px 0px', minWidth: 325, alignSelf: 'flex-end', fontSize: 20, fontWeight: 500, color: 'white' }}
          onClick={() => history.push(ROUTE_GUEST_PROFILE)}
        >
          <TranslateTextComponent uppercase>new-profile</TranslateTextComponent>
        </Button>
        <Button
          disabled={!profiles?.totalCount}
          variant="contained"
          color="primary"
          style={{ margin: '30px 10px 20px 0px', minWidth: 325, alignSelf: 'flex-end', fontSize: 20, fontWeight: 500, color: 'white' }}
          onClick={exportGuests}
        >
          <TranslateTextComponent uppercase>export</TranslateTextComponent>
        </Button>
      </div>
      <CheckInModal
        profileUUID={profileUUID}
        data={checkInModalData}
        onClose={() => {
          setProfileUUID(null);
          setCheckInModalData(null);
          refetch();
        }}
      />
    </PageLayout>
  );
};

export default GuestProfiles;
