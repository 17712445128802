/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { Checkbox } from '@material-ui/core/';
import { TranslateTextComponent } from '../../translator';
import { primaryColor } from '../../styles/theme';

const getAssignChairColumns = ({ handleGuestChecked, handleGuestClicked, guestChecked }) => {
  return [
    {
      id: 'sel',
      disablePadding: false,
      width: '6%',
      label: <TranslateTextComponent capitalize>sel</TranslateTextComponent>,
      isSortable: false,
      render: (item) => (
        <Checkbox
          checked={item.uuid === guestChecked}
          color="primary"
          style={{ color: `${primaryColor}` }}
          onChange={() => handleGuestChecked(item.uuid, item)}
          value={item.uuid}
          onClick={(e) => handleGuestClicked(e.target.checked, item.uuid)}
        />
      )
    },
    {
      id: 'firstname',
      disablePadding: true,
      width: '18%',
      label: <TranslateTextComponent capitalize>name</TranslateTextComponent>,
      isSortable: false,
      render: (item) => item.firstname && `${item.firstname}`
    },
    {
      id: 'lastname',
      disablePadding: true,
      width: '18%',
      label: <TranslateTextComponent capitalize>surname</TranslateTextComponent>,
      isSortable: false,
      render: (item) => item.lastname && `${item.lastname}`
    },
    {
      id: 'room',
      disablePadding: true,
      width: '10%',
      label: <TranslateTextComponent capitalize>room</TranslateTextComponent>,
      isSortable: false,
      render: (item) => item.room && `${item.room}`
    },
    {
      id: 'gender',
      disablePadding: true,
      width: '10%',
      label: <TranslateTextComponent capitalize>gender</TranslateTextComponent>,
      isSortable: false,
      render: (item) => item.gender && `${item.gender}`
    },
    {
      id: 'nationality',
      disablePadding: true,
      width: '15%',
      label: <TranslateTextComponent capitalize>nationality</TranslateTextComponent>,
      isSortable: false,
      render: (item) => item.nationality && `${item.nationality}`
    },
    {
      id: 'language',
      disablePadding: true,
      width: '10%',
      label: <TranslateTextComponent capitalize>language</TranslateTextComponent>,
      isSortable: false,
      render: (item) => item.language && item.language.code && `${item.language.code}`
    }
  ];
};

export default getAssignChairColumns;
