import { makeStyles } from '@material-ui/core/styles';

const styles = {
  container: ({ top }) => ({
    position: 'fixed',
    top,
    display: 'grid',
    justifyItems: 'center',
    gridTemplateAreas: '"table-name shape capacity hidden tabletop buttons-area" "table-name-error . capacity-error hidden-error . ."',
    backgroundColor: 'white',
    borderTop: '3px solid #3399CC',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    boxShadow: '3px 3px 3px #707070',
    width: 'calc(100% - 100px)',
    minHeight: 90,
    padding: '10px 30px 0 30px',
    zIndex: 5,
    transition: 'top 0.3s'
  }),
  tableName: {
    display: 'flex',
    alignItems: 'center',
    gridArea: 'table-name',
    fontSize: 17
  },
  numberInput: {
    margin: '0 10px',
    maxWidth: 37.43,
    borderBottom: '2px solid #00B7FF',
    '& input': {
      color: '#00B7FF',
      textAlign: 'center'
    }
  },
  rotation: {
    display: 'flex',
    alignItems: 'center',
    gridArea: 'rotation'
  },
  shape: {
    display: 'flex',
    alignItems: 'center',
    gridArea: 'shape'
  },
  capacity: {
    display: 'flex',
    alignItems: 'center',
    gridArea: 'capacity',
    fontSize: 17
  },
  hidden: {
    display: 'flex',
    alignItems: 'center',
    gridArea: 'hidden'
  },
  vertical: {
    display: 'flex',
    alignItems: 'center',
    gridArea: 'vertical'
  },
  tabletop: {
    display: 'flex',
    alignItems: 'center',
    gridArea: 'tabletop'
  },
  buttonsArea: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    gridArea: 'buttons-area',
    '& button': {
      margin: '0 5px'
    }
  },
  saveButton: {
    backgroundColor: '#00B7FF',
    border: '2px solid #00B7FF'
  },
  deleteButton: {
    border: '2px solid #00B7FF'
  },
  formControlLabel: {
    width: '100%',
    '& .MuiFormControlLabel-label': {
      width: '100%',
      fontSize: 17
    }
  },
  fabIcon: {
    position: 'absolute',
    top: 1,
    right: 1,
    width: 35,
    height: 30
  },
  error: {
    fontSize: '0.7rem'
  }
};

export default makeStyles(styles);
