import React from 'react';
import Layout from '../../components/shared/Layout';
import GuestProfiles from '../../components/GuestProfiles/GuestProfiles';

const GuestProfilesPage = ({ history }) => {
  return (
    <Layout history={history} section="guest-profiles">
      <GuestProfiles />
    </Layout>
  );
};

export default GuestProfilesPage;
