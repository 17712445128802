import React, { useState, useContext } from 'react';
import { Formik } from 'formik';
import { isEmpty } from 'validator';
import { Box, Typography, Checkbox, FormControlLabel, Button } from '@material-ui/core';
import { isNumber } from 'util';
import { TranslateTextComponent, translate, capitalizeFirstLetter } from '../../../translator';
import { LanguageContext } from '../../../contexts/LanguageContext';
import roomHeaderStyle from '../../../styles/roomEditor/roomHeader/style';
import { Dropdown } from '../../../common-fe';
import Input from '../Input';
import Shape from '../Shape';

const RoomHeader = ({ roomUUID, rooms, pax, onRoomTablesChange, onRoomChange }) => {
  const [radiosPadding, setRadiosPadding] = useState(true);
  const classes = roomHeaderStyle({ radiosPadding });
  const { language } = useContext(LanguageContext);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        selectedRoom: roomUUID,
        selectedShape: 0,
        capacity: '',
        quantity: '',
        isActive: true,
        tabletop: false
      }}
      validate={(values) => {
        const errors = {};
        // Validate Room
        if (values.selectedRoom < 0) errors.selectedRoom = capitalizeFirstLetter(translate('selected-room-error', language));
        // Validate Shape
        if (values.selectedShape <= 0 || isEmpty(values.selectedShape, { ignore_whitespace: true })) {
          errors.selectedShape = capitalizeFirstLetter(translate('selected-shape-error', language));
        }
        // Validate Capacity
        if (isEmpty(values.capacity, { ignore_whitespace: true }) || parseInt(values.capacity, 10) <= 0) {
          errors.capacity = capitalizeFirstLetter(translate('capacity-error', language));
        } else if (isNaN(parseInt(values.capacity, 10)) || !isNumber(parseInt(values.capacity, 10))) {
          errors.capacity = 'Invalid number';
        }
        // Validate Quantity
        if (isEmpty(values.quantity, { ignore_whitespace: true }) || parseInt(values.quantity, 10) <= 0) {
          errors.quantity = capitalizeFirstLetter(translate('quantity-error', language));
        } else if (isNaN(parseInt(values.quantity, 10)) || !isNumber(parseInt(values.quantity, 10))) {
          errors.quantity = 'Invalid number';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting, resetForm }, initialValues) => {
        onRoomTablesChange(values);
        setSubmitting(false);
        resetForm(initialValues);
      }}
    >
      {({ values, errors, touched, setFieldValue, handleSubmit, handleChange, handleBlur, isSubmitting }) => (
        <form className={classes.container} onSubmit={handleSubmit}>
          <Box className={classes.roomSelector}>
            <Typography className={classes.selectorTitle}>
              <TranslateTextComponent capitalize>select-room</TranslateTextComponent>
            </Typography>
            <Dropdown
              name="selectedRoom"
              dropdownStyle={{ minWidth: 135, fontSize: 12 }}
              items={rooms.map((room) => ({ label: room.name, value: room.uuid }))}
              value={values.selectedRoom}
              onChange={(e) => {
                handleChange(e);
                onRoomChange(e.target.value);
              }}
            />
          </Box>
          <Typography className={classes.error} color="error" style={{ gridArea: 'selected-room-error' }}>
            {errors.selectedRoom}
          </Typography>
          <Box className={classes.roomMaxPax}>
            <Typography className={classes.number} style={{ color: '#00B7FF' }}>
              {pax.maxPax}
            </Typography>
            <Typography className={classes.numberDescription} style={{ color: '#00B7FF' }}>
              <TranslateTextComponent uppercase>max-pax</TranslateTextComponent>
            </Typography>
          </Box>
          <Box className={classes.roomSeatsTaken}>
            <Typography className={classes.number} style={{ color: '#449D44' }}>
              {pax.available}
            </Typography>
            <Typography className={classes.numberDescription} style={{ color: '#449D44' }}>
              <TranslateTextComponent uppercase>seats-available</TranslateTextComponent>
            </Typography>
          </Box>
          <Box className={classes.roomSeatsAvailable}>
            <Typography className={classes.number} style={{ color: '#CB2D24' }}>
              {pax.occupied}
            </Typography>
            <Typography className={classes.numberDescription} style={{ color: '#CB2D24' }}>
              <TranslateTextComponent uppercase>seats-taken</TranslateTextComponent>
            </Typography>
          </Box>
          <hr className={classes.separator} />
          <Box className={classes.tableShapeSelector}>
            <Typography className={classes.selectorTitle}>
              <TranslateTextComponent capitalize>shape-type</TranslateTextComponent>
            </Typography>
            <Dropdown
              name="selectedShape"
              value={values.selectedShape}
              renderValue={() => {
                if (values.selectedShape === 'round') return capitalizeFirstLetter(translate('round', language));
                if (values.selectedShape === 'square') return capitalizeFirstLetter(translate('square', language));
                if (values.selectedShape === 'rectangle') return capitalizeFirstLetter(translate('rectangle', language));
                return <TranslateTextComponent capitalize>shape-type</TranslateTextComponent>;
              }}
              items={[
                { label: <Shape shape="round" selected={values.selectedShape === 'round'} />, value: 'round' },
                { label: <Shape shape="square" selected={values.selectedShape === 'square'} />, value: 'square' },
                {
                  label: <Shape shape="rectangle" selected={values.selectedShape === 'rectangle'} />,
                  value: 'rectangle'
                }
              ]}
              dropdownStyle={{ minWidth: 135, fontSize: 12 }}
              onChange={(e) => {
                handleChange(e);
                setRadiosPadding(e.target.value !== 'rectangle');
              }}
            />
          </Box>
          <Typography className={classes.error} color="error" style={{ gridArea: 'selected-shape-error' }}>
            {errors.selectedShape && touched.selectedShape && errors.selectedShape}
          </Typography>
          <Box className={classes.tableCapacity}>
            <Typography className={classes.selectorTitle}>
              <TranslateTextComponent capitalize>capacity</TranslateTextComponent>
            </Typography>
            <Input
              name="capacity"
              error={errors.capacity && touched.capacity && errors.capacity}
              value={values.capacity}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>
          <Typography className={classes.error} color="error" style={{ gridArea: 'capacity-error' }}>
            {errors.capacity && touched.capacity && errors.capacity}
          </Typography>
          <Box className={classes.tableQuantity}>
            <Typography className={classes.selectorTitle}>
              <TranslateTextComponent capitalize>quantity-shortened</TranslateTextComponent>
            </Typography>
            <Input
              name="quantity"
              error={errors.quantity && touched.quantity && errors.quantity}
              value={values.quantity}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>
          <Typography className={classes.error} color="error" style={{ gridArea: 'quantity-error' }}>
            {errors.quantity && touched.quantity && errors.quantity}
          </Typography>
          <Box className={classes.tableSettings}>
            <Box className={classes.checkBoxContainer}>
              <FormControlLabel
                className={classes.formControlLabel}
                labelPlacement="start"
                control={
                  <Checkbox
                    name="isActive"
                    value={values.isActive}
                    color="primary"
                    checked={!values.isActive}
                    onChange={() => {
                      setFieldValue('isActive', !values.isActive);
                    }}
                    onBlur={handleBlur}
                  />
                }
                label={capitalizeFirstLetter(translate('hidden', language))}
              />
            </Box>
            <Box className={classes.checkBoxContainer}>
              <FormControlLabel
                className={classes.formControlLabel}
                labelPlacement="start"
                control={
                  <Checkbox
                    name="tabletop"
                    value={values.tabletop}
                    color="primary"
                    disabled={values.selectedShape === 'round'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                }
                label={capitalizeFirstLetter(translate('tabletop-seats', language))}
              />
            </Box>
          </Box>
          <Box className={classes.buttonArea}>
            <Button className={classes.button} variant="contained" type="submit" disabled={isSubmitting}>
              <TranslateTextComponent uppercase>create</TranslateTextComponent>
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default RoomHeader;
