import React, { useState, useContext } from 'react';
import orderBy from 'lodash/orderBy';
import { useMutation } from '@apollo/react-hooks';
import { Button } from '@material-ui/core';
import PageLayout from '../../shared/PageLayout';
import CustomFilters from '../../shared/CustomFilters/CustomFilters';
import Tabs from '../../shared/Tabs/Tabs';
import useCustomQuery from '../../../hooks/useCustomQuery';
import { AuthContext } from '../../../contexts/AuthContext';
import { GET_MENU_CATEGORIES } from '../../../graphql/menu/queries';
import { CommonTable } from '../../../common-fe/src';
import inventoryColumns from './table/inventoryColumns';
import InventoryItemModal from './form/InventoryItemModal';
import { GET_FB_MENU_ITEMS } from '../../../graphql/moh/menu/queries';
import { SET_MENU_ITEM_STOCK_ENABLED } from '../../../graphql/moh/inventory/mutations';
import { ModalContext } from '../../../contexts/ModalContext';
import { TranslateTextComponent, translate } from '../../../translator';
import { getApolloErrors } from '../../../apollo/ApolloProvider';
import modalStyle from '../../../styles/shared/modalStyle';
import { LanguageContext } from '../../../contexts/LanguageContext';

const Inventory = () => {
  const { appOutletUUID } = useContext(AuthContext);
  const modalContext = useContext(ModalContext);
  const { language } = useContext(LanguageContext);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [searchBarValue, setSearchBarValue] = useState('');
  const [tab, setTab] = useState('products');
  const [selectedCategoryUUID, setSelectedCategoryUUID] = useState(null);
  const [filtersPage, setFiltersPage] = useState({
    page: 1,
    pageSize: 10
  });

  const modalClasses = modalStyle();

  // Queries
  const getFbMenuCategories = useCustomQuery(GET_MENU_CATEGORIES, {
    variables: { fbOutletUUID: appOutletUUID },
    onCompleted: (data) => {
      const sortedCategories = orderBy(data?.getFbMenuCategories?.data ?? [], ['fbMenuMacrocategory.id', 'name']) ?? [];
      if (selectedCategoryUUID) {
        setSelectedCategoryUUID(data?.getFbMenuCategories?.data?.find((category) => category.uuid === selectedCategoryUUID));
      } else {
        setSelectedCategoryUUID(sortedCategories?.[0]?.uuid ?? '');
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });

  const getFbMenuItems = useCustomQuery(GET_FB_MENU_ITEMS, {
    variables: { fbOutletUUID: appOutletUUID, fbMenuCategoryUUID: selectedCategoryUUID, page: filtersPage.page, pageSize: filtersPage.pageSize },
    skip: !selectedCategoryUUID
  });

  // Mutations
  const [setMenuItemStockEnabled, { loading: setMenuItemStockEnabledLoading }] = useMutation(SET_MENU_ITEM_STOCK_ENABLED);

  const getSortedCategories = () => {
    return orderBy(getFbMenuCategories.data?.getFbMenuCategories?.data ?? [], ['fbMenuMacrocategory.id', 'name']) ?? [];
  };

  const getSortedFbMenuItems = () => {
    return (
      orderBy(getFbMenuItems.data?.getFbMenuItems?.data ?? [], ['name'])?.filter((item) =>
        item?.name?.toLowerCase()?.includes(searchBarValue?.toLowerCase())
      ) ?? []
    );
  };

  const onInventoryStatusChange = ({ menuItemUUID, isStockEnabled }) => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>change-status</TranslateTextComponent>,
      text: (
        <TranslateTextComponent
          capitalize
          vars={{ selectedStatus: isStockEnabled ? translate('active', language) : translate('inactive', language) }}
        >
          change-status-description
        </TranslateTextComponent>
      ),
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          variant="contained"
          color="primary"
          className={modalClasses.buttonStyle}
          onClick={() => {
            modalContext.setModalLoading(true);
            setMenuItemStockEnabled({ variables: { menuItemUUID, isStockEnabled } })
              .then(() => {
                modalContext.setModalLoading(false);
                getFbMenuItems.refetch();
                modalContext.closeModal();
              })
              .catch((err) => {
                modalContext.setModalLoading(false);
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const onShowItem = (item) => {
    setModalData(item);
    setShowModal(true);
  };

  const setTablePages = (page, pageSize) => {
    setFiltersPage({
      ...filtersPage,
      page,
      pageSize
    });
  };

  return (
    <PageLayout>
      <CustomFilters filters={[{ type: 'searchbar', onChange: ({ target: { value } }) => setSearchBarValue(value) }]} />
      <Tabs
        currentTab={tab}
        tabs={[{ label: 'products', style: { width: '100%' } }]}
        style={{ marginBottom: 20 }}
        changeTab={(value) => {
          setTab(value);
        }}
      />
      {selectedCategoryUUID && (
        <Tabs
          tabValueKey="uuid"
          variant="scrollable"
          currentTab={selectedCategoryUUID}
          tabs={
            getSortedCategories().map((category) => ({
              uuid: category.uuid,
              label: category.name,
              style: { width: 'auto', fontWeight: 400, textTransform: 'capitalize' }
            })) ?? []
          }
          changeTab={(value) => {
            setSelectedCategoryUUID(value);
          }}
        />
      )}
      <CommonTable
        isLoading={getFbMenuItems.loading}
        key={1}
        page={filtersPage.page}
        pageSize={filtersPage.pageSize}
        setTablePages={setTablePages}
        // orderType={tableFilters.orderType.toLocaleLowerCase()}
        // orderBy={tableFilters.orderBy}
        // setTableSort={setTableSort}
        totalRows={getFbMenuItems.data?.getFbMenuItems?.totalCount}
        isPaginationEnabled
        items={getSortedFbMenuItems()}
        columns={inventoryColumns({ onShowItem, onInventoryStatusChange })}
        tableContainerStyle={{ marginTop: 13 }}
        rowStyle={{ borderColor: '#B4DCED' }}
      />
      <InventoryItemModal
        data={modalData}
        showModal={showModal}
        fbOutletUUID={appOutletUUID}
        fbMenuCategoryUUID={selectedCategoryUUID}
        onClose={() => {
          setShowModal(false);
          setModalData(null);
        }}
      />
    </PageLayout>
  );
};

export default Inventory;
