import React, { useState, useContext } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core/';

import { TranslateTextComponent, translate } from '../../translator';
import { LanguageContext } from '../../contexts/LanguageContext';

import { primaryColor } from '../../styles/theme';
import styles from '../../styles/saveReservation/newReservationStyle';
import leftSidebarLayoutStyles from '../../styles/leftSidebarLayout/leftSidebarLayoutStyle';

const NewGuest = () => {
  const layoutClasses = leftSidebarLayoutStyles();
  const classes = styles();

  const languageContext = useContext(LanguageContext);

  const [changeFocusButton, setChangeFocusButton] = useState(null);

  const onSetInHouseGuest = () => {
    // set bookedBy.id null in context
    handleButton(`button${1}`);
  };

  const onSetOthersGuest = () => {
    handleButton(`button${0}`);
  };

  const handleButton = (id) => {
    if (changeFocusButton === id) {
      setChangeFocusButton(false);
    } else {
      setChangeFocusButton(id);
    }
  };

  return (
    <Box className={layoutClasses.layoutContainer}>
      <Box className={layoutClasses.leftSide}>
        <Box>
          <Grid container>
            <Typography>{translate('data', languageContext.language).toUpperCase()}</Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.savebutton}
              style={{ backgroundColor: changeFocusButton && changeFocusButton === `button${1}` ? 'rgba(0, 0, 0, 0.12)' : primaryColor }}
              onClick={onSetOthersGuest}
            >
              <TranslateTextComponent>in-house</TranslateTextComponent>
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.savebutton}
              style={{ backgroundColor: changeFocusButton && changeFocusButton === `button${0}` ? 'rgba(0, 0, 0, 0.12)' : primaryColor }}
              onClick={onSetInHouseGuest}
            >
              <TranslateTextComponent>others-guest</TranslateTextComponent>
            </Button>
          </Grid>
        </Box>
      </Box>
      <Box className={layoutClasses.rightSide}>RightSide</Box>
    </Box>
  );
};

export default NewGuest;
