import groupBy from 'lodash/groupBy';
export const ANTIPASTI_IMG = require('../../assets/images/order/circle-antipasti.svg');
export const PRIMI_IMG = require('../../assets/images/order/circle-primi.svg');
export const SECONDI_IMG = require('../../assets/images/order/circle-secondi.svg');
export const BEVANE_IMG = require('../../assets/images/order/circle-bevande.svg');
export const DISTILLATI_IMG = require('../../assets/images/order/circle-distillati.svg');
export const SNACKS_IMG = require('../../assets/images/order/circle-snacks.svg');
export const DESSERT_IMG = require('../../assets/images/order/circle-dessert.svg');
export const MENU_VARI_IMG = require('../../assets/images/order/circle-menu_vari.svg');
export const EXTRA_IMG = require('../../assets/images/order/circle-extra.svg');
export const CAFFETTERIA_IMG = require('../../assets/images/order/circle-caffetteria.svg');
export const VINI_IMG = require('../../assets/images/order/circle-vini.svg');
export const BIRRE_IMG = require('../../assets/images/order/circle-birre.svg');

export const groupedByMenuItem = (fbCoursesMenuItems) => {
  const groupedBy = groupBy(fbCoursesMenuItems, (menuItem) => {
    return menuItem.fbMenuItemId + menuItem.with + menuItem.without;
  });

  const keys = Object.keys(groupedBy).map((key) => {
    return key;
  });

  keys.sort();
  const dishes = keys.map((key) => {
    return groupedBy[key];
  });

  return dishes;
};

export const reduceFbFoodCourses = (fbCoursesMenuItems) => {
  const reducedFbFoodCourses =
    fbCoursesMenuItems &&
    fbCoursesMenuItems.reduce((objectsByKeyValue, menuItem) => {
      const { fbMenuItemId, with: withIngredients, without: withoutIngredients, name, fbMenuItem } = menuItem;
      const withIngredientsStringified = JSON.stringify(withIngredients.sort());
      const withoutIngredientsStringified = JSON.stringify(withoutIngredients.sort());

      const updateFbMenuItem = {
        fbMenuCategoryId: fbMenuItem && fbMenuItem.fbMenuCategoryId,
        id: fbMenuItem && fbMenuItem.id
      };

      objectsByKeyValue[withIngredientsStringified + withoutIngredientsStringified + fbMenuItemId] = (
        objectsByKeyValue[withIngredientsStringified + withoutIngredientsStringified + fbMenuItemId] || []
      ).concat(menuItem);
      objectsByKeyValue[withIngredientsStringified + withoutIngredientsStringified + fbMenuItemId].name = name;
      objectsByKeyValue[withIngredientsStringified + withoutIngredientsStringified + fbMenuItemId].fbMenuItem = updateFbMenuItem;
      objectsByKeyValue[withIngredientsStringified + withoutIngredientsStringified + fbMenuItemId].with = withIngredients;
      objectsByKeyValue[withIngredientsStringified + withoutIngredientsStringified + fbMenuItemId].without = withoutIngredients;

      return objectsByKeyValue;
    }, {});

  const compare = (a, b) => {
    if (reducedFbFoodCourses[a].name === reducedFbFoodCourses[b].name) {
      return reducedFbFoodCourses[a].with < reducedFbFoodCourses[b].with && reducedFbFoodCourses[a].without < reducedFbFoodCourses[b].without
        ? -1
        : 1;
    }
    return reducedFbFoodCourses[a].name > reducedFbFoodCourses[b].name ? 1 : -1;
  };
  const orderedReducedFbFoodCourses = {};
  if (reducedFbFoodCourses)
    Object.keys(reducedFbFoodCourses)
      .sort(compare)
      .forEach((key) => {
        orderedReducedFbFoodCourses[key] = reducedFbFoodCourses[key];
      });

  return orderedReducedFbFoodCourses;
};

export const getTotalQuantity = (menuItems) => {
  return menuItems.reduce((quantitySum, menuItem) => {
    quantitySum += menuItem.quantity;

    return quantitySum;
  }, 0);
};
