import React, { useState, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Typography, Button } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import ReactToPrint from 'react-to-print';
import { CommonTable } from '../../../common-fe/src';
import { TranslateTextComponent, translate } from '../../../translator';
import reservationsColumns from './table/reservationsColumns';
import useCustomQuery from '../../../hooks/useCustomQuery';
import { GET_CHANNELS } from '../../../graphql/channels/queries';
import { GET_PACKAGES } from '../../../graphql/reservations/queries';
import PrintableReservation from '../PrintableReservation';
import {
  DELETE_FB_RESERVATION_MUTATION,
  SET_FB_RESERVATION_GUEST_ARRIVED_MUTATION,
  EDIT_FB_RESERVATION_CHANNEL,
  EDIT_FB_RESERVATION_PACKAGE
} from '../../../graphql/reservations/mutations';
import { ModalContext } from '../../../contexts/ModalContext';
import modalStyle from '../../../styles/shared/modalStyle';
import { getApolloErrors } from '../../../apollo/ApolloProvider';
import Buttons from '../../shared/Buttons';
import { ROUTE_DAILY_REPORTS, ROUTE_SAVE_RESERVATION } from '../../../router/routes';
import ReservationDetails from './ReservationDetails/ReservationDetails';
import { LanguageContext } from '../../../contexts/LanguageContext';
import PrintableReservations from '../PrintableReservations';
import Guard from '../../shared/Guard';
import { ALL_ROLES, ROLES } from '../../../constants/users/usersRole';

const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

const ReservationsList = ({ reservations, maxPax, printableReservations, page, pageSize, onTablePageChanges, onTableSort }) => {
  const modalContext = useContext(ModalContext);
  const { language } = useContext(LanguageContext);
  const [reservationToPrint, setReservationToPrint] = useState(null);
  const printableReservationsRef = useRef(null);
  const printableReservationRef = useRef(null);
  const history = useHistory();

  const modalClasses = modalStyle();

  // Queries
  const getChannels = useCustomQuery(GET_CHANNELS);
  const getPackages = useCustomQuery(GET_PACKAGES);

  // Mutations
  const [setFbReservationGuestArrived] = useMutation(SET_FB_RESERVATION_GUEST_ARRIVED_MUTATION);
  const [editFbReservationChannel] = useMutation(EDIT_FB_RESERVATION_CHANNEL);
  const [editFbReservationPackage] = useMutation(EDIT_FB_RESERVATION_PACKAGE);
  const [deleteFbReservation] = useMutation(DELETE_FB_RESERVATION_MUTATION);

  const onChannelChange = ({ fbReservationUUID, channelUUID, channelName }) => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>change-channel-title</TranslateTextComponent>,
      text: (
        <TranslateTextComponent vars={{ channelName: translate(channelName?.toLowerCase() ?? '', language) }} capitalize>
          change-channel-text
        </TranslateTextComponent>
      ),
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            modalContext.setModalLoading(true);
            editFbReservationChannel({ variables: { fbReservationUUID, channelUUID } })
              .then(() => {
                modalContext.setModalLoading(false);
                reservations.refetch();
                modalContext.openModal({
                  class: 'success',
                  title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
                  text: <TranslateTextComponent capitalize>change-channel-success</TranslateTextComponent>
                });
              })
              .catch((err) => {
                modalContext.setModalLoading(false);
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const onPackageChange = ({ fbReservationUUID, packageUUID, packageName }) => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>change-package-title</TranslateTextComponent>,
      text: (
        <TranslateTextComponent vars={{ packageName: translate(packageName, language) }} capitalize>
          change-package-text
        </TranslateTextComponent>
      ),
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            modalContext.setModalLoading(true);
            editFbReservationPackage({ variables: { fbReservationUUID, packageUUID } })
              .then(() => {
                modalContext.setModalLoading(false);
                reservations.refetch();
                modalContext.openModal({
                  class: 'success',
                  title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
                  text: <TranslateTextComponent capitalize>change-package-success</TranslateTextComponent>
                });
              })
              .catch((err) => {
                modalContext.setModalLoading(false);
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const onSetFbReservationGuestArrived = (uuid) => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>guest-arrived-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>guest-arrived-text</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            modalContext.setModalLoading(true);
            setFbReservationGuestArrived({ variables: { fbReservationUUID: uuid } })
              .then(() => {
                modalContext.setModalLoading(false);
                reservations.refetch();
                modalContext.openModal({
                  class: 'success',
                  title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
                  text: <TranslateTextComponent capitalize>guest-arrived-confirmed-text</TranslateTextComponent>
                });
              })
              .catch((err) => {
                modalContext.setModalLoading(false);
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const onDeleteReservation = (uuid) => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>delete-reservation-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>delete-reservation-text</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            modalContext.setModalLoading(true);
            deleteFbReservation({ variables: { fbReservationUUID: uuid } })
              .then(() => {
                modalContext.setModalLoading(false);
                reservations.refetch();
                modalContext.openModal({
                  class: 'success',
                  title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
                  text: <TranslateTextComponent capitalize>success-modal-text</TranslateTextComponent>
                });
              })
              .catch((err) => {
                modalContext.setModalLoading(false);
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  return (
    <Container>
      <CommonTable
        isLoading={reservations.loading}
        key={1}
        page={page}
        pageSize={pageSize}
        setTablePages={(page, pageSize) => onTablePageChanges({ page, pageSize })}
        setTableSort={onTableSort}
        rowsPerPageOptions={
          reservations.data?.getFbReservations?.totalCount > 25 ? [5, 10, 25, reservations.data?.getFbReservations?.totalCount] : [5, 10, 25]
        }
        // orderType={tableFilters.orderType.toLocaleLowerCase()}
        // orderBy={tableFilters.orderBy}
        totalRows={reservations.data?.getFbReservations?.totalCount}
        isPaginationEnabled
        items={reservations.data?.getFbReservations?.data ?? []}
        columns={reservationsColumns({
          language,
          channels: getChannels.data?.getChannels?.data ?? [],
          packages: getPackages.data?.getPackages?.data ?? [],
          history,
          setCurrentReservation: (reservation) => setReservationToPrint(reservation),
          printableReservationRef: printableReservationRef.current,
          setFbReservationGuestArrived: onSetFbReservationGuestArrived,
          changeChannel: onChannelChange,
          changePackage: onPackageChange,
          deleteReservation: onDeleteReservation
        })}
        renderDetailsComponent={[
          {
            position: 10,
            disablePadding: true,
            columnLabel: <TranslateTextComponent capitalize>details</TranslateTextComponent>,
            buttonLabel: <TranslateTextComponent>details</TranslateTextComponent>,
            renderFunction: (row) => <ReservationDetails item={row} />
          }
        ]}
        tableContainerStyle={{ marginTop: 13 }}
        rowStyle={{ borderColor: '#B4DCED' }}
        tableHeader={
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#EDFAFF',
              minHeight: 28,
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
            }}
          >
            <TranslateTextComponent capitalize>total-pax</TranslateTextComponent>:{' '}
            {`${
              printableReservations
                ?.map((reservation) => reservation.fbGuestList)
                .reduce((a, s) => {
                  a += s.length;
                  return a;
                }, 0) ?? 0
            }/${maxPax}`}
          </Typography>
        }
      />
      <Buttons
        containerStyle={{ marginBottom: 20 }}
        buttons={[
          {
            custom: (
              <Guard key={-2} roles={[...ALL_ROLES]}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    color: 'white',
                    marginRight: 10,
                    borderRadius: 9,
                    minWidth: 325,
                    minHeight: 50,
                    fontSize: 20,
                    fontWeight: 'bold',
                    textTransform: 'uppercase'
                  }}
                  onClick={() => history.push(ROUTE_DAILY_REPORTS)}
                >
                  <TranslateTextComponent capitalize>daily-reports</TranslateTextComponent>
                </Button>
              </Guard>
            )
          },
          {
            custom: (
              <ReactToPrint
                key={-1}
                pageStyle={() => '@page {size: A4 landscape;; margin: 0mm;} @media print{@page {size: landscape}}'}
                trigger={() => (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      color: 'white',
                      marginRight: 10,
                      borderRadius: 9,
                      minWidth: 325,
                      minHeight: 50,
                      fontSize: 20,
                      fontWeight: 'bold',
                      textTransform: 'uppercase'
                    }}
                  >
                    <TranslateTextComponent capitalize>print</TranslateTextComponent>
                  </Button>
                )}
                content={() => printableReservationsRef.current}
              />
            )
          },
          {
            custom: (
              <Guard
                key={0}
                roles={[
                  ROLES.ROLE_LOCAL,
                  ROLES.ROLE_SUPERVISOR,
                  ROLES.ROLE_FRONT_OFFICE,
                  ROLES.ROLE_GUEST_SERVICE,
                  ROLES.ROLE_FB_OPERATOR,
                  ROLES.ROLE_MAITRE,
                  ROLES.ROLE_HOSTESS,
                  ROLES.ROLE_ROOM_SERVICE
                ]}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    color: 'white',
                    marginRight: 10,
                    borderRadius: 9,
                    minWidth: 325,
                    minHeight: 50,
                    fontSize: 20,
                    fontWeight: 'bold',
                    textTransform: 'uppercase'
                  }}
                  onClick={() => history.push(ROUTE_SAVE_RESERVATION)}
                >
                  <TranslateTextComponent capitalize>add-reservation</TranslateTextComponent>
                </Button>
              </Guard>
            )
          }
        ]}
      />
      <div style={{ display: 'none' }}>
        <PrintableReservations ref={printableReservationsRef} reservations={printableReservations} />
        <PrintableReservation ref={printableReservationRef} reservation={reservationToPrint} />
      </div>
    </Container>
  );
};

export default ReservationsList;
