import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Redirect, useHistory } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { ALL_ROLES, ROLES } from '../constants/users/usersRole';
import {
  ROUTE_DASHBOARD,
  ROUTE_RESERVATIONS,
  ROUTE_SAVE_RESERVATION,
  ROUTE_TABLE_RESERVATION,
  ROUTE_SETTINGS,
  ROUTE_ORDER,
  ROUTE_MENU,
  ROUTE_ROOM_EDITOR,
  ROUTE_RECORDS,
  ROUTE_LOGIN,
  ROUTE_ASSIGN_CHAIR,
  ROUTE_GUEST_REGISTRY,
  ROUTE_EDIT_RESERVATION,
  ROUTE_CASH,
  ROUTE_PM_CASH,
  ROUTE_BILLS_MANAGEMENT,
  ROUTE_MOH_ORDERS,
  ROUTE_MOH_DRIVERS,
  ROUTE_MOH_SERVICES,
  ROUTE_MOH_BOOKINGS,
  ROUTE_MOH_KITCHEN,
  ROUTE_KITCHEN_DETAILED_ORDER,
  ROUTE_ACCOUNT,
  ROUTE_OUTLETS_LIST,
  ROUTE_MOH_INVENTORY,
  ROUTE_GUEST_PROFILES,
  ROUTE_GUEST_PROFILE,
  ROUTE_CHECK_IN,
  ROUTE_STAFF_USERS_LIST,
  ROUTE_GUEST_USERS_LIST,
  ROUTE_SAVE_DAILY_REPORT,
  ROUTE_DAILY_REPORTS,
  ROUTE_EDIT_DAILY_REPORT
} from './routes';
import DashboardPage from '../pages/DashboardPage';
import ReservationsPage from '../pages/ReservationsPage';
import TableReservationPage from '../pages/TableReservationPage';
import SaveReservationPage from '../pages/SaveReservationPage';
import EditReservationPage from '../pages/EditReservationPage';
import SettingsPage from '../pages/Moh/Settings/MohSettingsPage';
import OrderPage from '../pages/OrderPage';
import MenuPage from '../pages/MenuPage';
import RoomEditorPage from '../pages/RoomEditorPage';
import RecordsPage from '../pages/RecordsPage';
import LoginPage from '../pages/LoginPage';
import AssignChairPage from '../pages/AssignChairPage';
import NewGuestPage from '../pages/NewGuestPage';
import CashPage from '../pages/CashPage';
import PMCashPage from '../pages/PMCashPage';
import BillsManagementPage from '../pages/BillsManagementPage';
import MohOrdersPage from '../pages/Moh/Orders/MohOrdersPage';
import MohDriversPage from '../pages/Moh/Drivers/MohDriversPage';
import MohServicesPage from '../pages/Moh/Services/MohServicesPage';
import MohBookingsPage from '../pages/Moh/Bookings/MohBookingsPage';
import MohKitchenPage from '../pages/Moh/Kitchen/MohKitchenPage';
import DetailedKitchenOrderPage from '../pages/Moh/Kitchen/DetailedKitchenOrderPage';
import OutletListPage from '../pages/OutletListPage';
import Account from '../pages/AccountPage';
import MohInventoryPage from '../pages/Moh/Inventory/InventoryPage';
import GuestProfilesPage from '../pages/GuestProfiles/GuestProfilesPage';
import GuestProfilePage from '../pages/GuestProfiles/GuestProfilePage';
import StaffUsersListPage from '../pages/StaffUsersListPage';
import GuestUsersListPage from '../pages/GuestUsersListPage';
import CheckInListPage from '../pages/CheckInListPage';
import DailyRevenuesPage from '../pages/Revenues/DailyRevenuesPage';
import SaveDailyRevenuePage from '../pages/Revenues/SaveDailyRevenuePage';
import EditDailyRevenuePage from '../pages/Revenues/EditDailyRevenuePage';

/*
  TO PROTECT A ROUTE BASED ON ROLES:
    <PrivateRoute roles={['ROLE_SUPER_ADMIN', 'ROLE_FB_ADMIN']} path={ROUTE_OUTLETS_LIST} exact component={OutletListPage} />
*/

const AppRouter = () => {
  return (
    <Router>
      <Route path={ROUTE_LOGIN} exact component={LoginPage} />

      <PrivateRoute roles={[ROLES.ROLE_SUPER_ADMIN]} path={ROUTE_OUTLETS_LIST} exact component={OutletListPage} />
      <PrivateRoute roles={[ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_SUPERVISOR]} path={ROUTE_STAFF_USERS_LIST} exact component={StaffUsersListPage} />
      <PrivateRoute
        roles={[ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_LOCAL, ROLES.ROLE_SUPERVISOR, ROLES.ROLE_FRONT_OFFICE, ROLES.ROLE_GUEST_SERVICE]}
        path={ROUTE_GUEST_USERS_LIST}
        exact
        component={GuestUsersListPage}
      />
      <PrivateRoute path={ROUTE_GUEST_PROFILES} exact component={GuestProfilesPage} />
      <PrivateRoute
        roles={[ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_LOCAL, ROLES.ROLE_SUPERVISOR, ROLES.ROLE_FRONT_OFFICE, ROLES.ROLE_GUEST_SERVICE]}
        path={ROUTE_CHECK_IN}
        exact
        component={CheckInListPage}
      />
      <PrivateRoute path={`${ROUTE_GUEST_PROFILE}/:uuid?`} exact component={GuestProfilePage} />
      <PrivateRoute path={ROUTE_DASHBOARD} exact component={DashboardPage} />
      <PrivateRoute
        roles={[
          ROLES.ROLE_LOCAL,
          ROLES.ROLE_SUPERVISOR,
          ROLES.ROLE_FRONT_OFFICE,
          ROLES.ROLE_GUEST_SERVICE,
          ROLES.ROLE_FB_OPERATOR,
          ROLES.ROLE_MAITRE,
          ROLES.ROLE_HOSTESS,
          ROLES.ROLE_ROOM_SERVICE
        ]}
        path={ROUTE_RESERVATIONS}
        exact
        component={ReservationsPage}
      />
      <PrivateRoute roles={[ROLES.ROLE_SUPER_ADMIN]} path={ROUTE_TABLE_RESERVATION} exact component={TableReservationPage} />
      <PrivateRoute
        roles={[
          ROLES.ROLE_LOCAL,
          ROLES.ROLE_SUPERVISOR,
          ROLES.ROLE_FRONT_OFFICE,
          ROLES.ROLE_GUEST_SERVICE,
          ROLES.ROLE_FB_OPERATOR,
          ROLES.ROLE_MAITRE,
          ROLES.ROLE_HOSTESS,
          ROLES.ROLE_ROOM_SERVICE
        ]}
        path={ROUTE_SAVE_RESERVATION}
        exact
        component={SaveReservationPage}
      />
      <PrivateRoute roles={[ROLES.ROLE_ROOM_SERVICE]} path={ROUTE_SETTINGS} exact component={SettingsPage} />
      <PrivateRoute roles={[ROLES.ROLE_SUPER_ADMIN]} path={`${ROUTE_ORDER}/:uuid`} exact component={OrderPage} />
      <PrivateRoute roles={[ROLES.ROLE_MAITRE, ROLES.ROLE_HOSTESS, ROLES.ROLE_ROOM_SERVICE]} path={ROUTE_MENU} exact component={MenuPage} />
      <PrivateRoute roles={[ROLES.ROLE_SUPER_ADMIN]} path={ROUTE_ROOM_EDITOR} exact component={RoomEditorPage} />
      <PrivateRoute roles={[ROLES.ROLE_SUPER_ADMIN]} path={ROUTE_RECORDS} exact component={RecordsPage} />
      <PrivateRoute roles={[ROLES.ROLE_SUPER_ADMIN]} path={`${ROUTE_ASSIGN_CHAIR}/:uuid`} exact component={AssignChairPage} />
      <PrivateRoute roles={[ROLES.ROLE_SUPER_ADMIN]} path={`${ROUTE_EDIT_RESERVATION}/:uuid`} exact component={EditReservationPage} />
      <PrivateRoute roles={[ROLES.ROLE_SUPER_ADMIN]} path={ROUTE_GUEST_REGISTRY} exact component={NewGuestPage} />
      <PrivateRoute roles={[ROLES.ROLE_SUPER_ADMIN]} path={ROUTE_CASH} exact component={CashPage} />
      <PrivateRoute roles={[ROLES.ROLE_SUPER_ADMIN]} path={`${ROUTE_CASH}/:uuid`} exact component={CashPage} />
      <PrivateRoute roles={[ROLES.ROLE_SUPER_ADMIN]} path={ROUTE_BILLS_MANAGEMENT} exact component={BillsManagementPage} />
      <PrivateRoute roles={[ROLES.ROLE_SUPER_ADMIN]} path={`${ROUTE_PM_CASH}/:uuid`} exact component={PMCashPage} />
      <PrivateRoute path={ROUTE_ACCOUNT} exact component={Account} />
      {/* MOH ROUTES */}
      <PrivateRoute roles={[ROLES.ROLE_ROOM_SERVICE]} path={`${ROUTE_MOH_ORDERS}`} exact component={MohOrdersPage} />
      <PrivateRoute roles={[ROLES.ROLE_SUPER_ADMIN]} path={`${ROUTE_MOH_DRIVERS}`} exact component={MohDriversPage} />
      <PrivateRoute roles={[]} path={`${ROUTE_MOH_SERVICES}`} exact component={MohServicesPage} />
      <PrivateRoute
        roles={[
          ROLES.ROLE_SUPERVISOR,
          ROLES.ROLE_FRONT_OFFICE,
          ROLES.ROLE_GUEST_SERVICE,
          ROLES.ROLE_MAITRE,
          ROLES.ROLE_HOSTESS,
          ROLES.ROLE_ROOM_SERVICE
        ]}
        path={`${ROUTE_MOH_BOOKINGS}`}
        exact
        component={MohBookingsPage}
      />
      <PrivateRoute roles={[ROLES.ROLE_SUPER_ADMIN]} path={`${ROUTE_MOH_KITCHEN}`} exact component={MohKitchenPage} />
      <PrivateRoute roles={[ROLES.ROLE_SUPER_ADMIN]} path={`${ROUTE_KITCHEN_DETAILED_ORDER}/:uuid`} exact component={DetailedKitchenOrderPage} />
      <PrivateRoute roles={[ROLES.ROLE_SUPER_ADMIN]} path={`${ROUTE_MOH_INVENTORY}`} exact component={MohInventoryPage} />
      <PrivateRoute roles={[...ALL_ROLES]} path={`${ROUTE_SAVE_DAILY_REPORT}`} exact component={SaveDailyRevenuePage} />
      <PrivateRoute roles={[...ALL_ROLES]} path={`${ROUTE_EDIT_DAILY_REPORT}/:uuid`} exact component={EditDailyRevenuePage} />
      <PrivateRoute roles={[...ALL_ROLES]} path={`${ROUTE_DAILY_REPORTS}`} exact component={DailyRevenuesPage} />
    </Router>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  if (authContext?.isLoggedIn()) {
    if (!rest.roles) {
      return <Route {...rest} render={(props) => <Component {...props} />} />;
    }
    if (
      authContext?.user?.roles?.some((role) => role.name === ROLES.ROLE_SUPER_ADMIN || ROLES.ROLE_MANAGER) ||
      (rest.roles && authContext?.user?.roles?.some((userRole) => rest.roles?.some((role) => userRole.name === role)))
    ) {
      return <Route {...rest} render={(props) => <Component {...props} />} />;
    }
    return (
      <Route
        {...rest}
        render={(props) => {
          history.replace(ROUTE_DASHBOARD);
          return <DashboardPage {...props} />;
        }}
      />
    );
  }
  return <Redirect to={ROUTE_LOGIN} />;
};
export default AppRouter;
