import React, { useState } from 'react';
import { Box } from '@material-ui/core/';
import Generals from './generals/Generals';
import RoomSettings from './room/RoomSettings';
import ServiceSettings from './service/ServiceSettings';
import SegmentsSettings from './segments/SegmentsSettings';
import Reservations from './reservations/Reservations';
import Advanced from './advanced/Advanced';
import { RenderTabs } from '../../common-fe';
import styles from '../../styles/settings/settingsStyle';
import tabElements from '../../fixtures/settings/settings';
import Delivery from './moh/Delivery';
import Takeaway from './moh/Takeaway';
import Services from './moh/Services';
import QRCode from './QRCode/QRCode';

const Settings = () => {
  const classes = styles();
  const [value, setValue] = useState('generals');

  function handleChange(event, newValue) {
    setValue(newValue);
  }
  const getBox = () => {
    let selectedTab = null;
    switch (value) {
      case 'generals': {
        selectedTab = <Generals />;
        break;
      }
      case 'reservations': {
        selectedTab = <Reservations />;
        break;
      }
      case 'room': {
        selectedTab = <RoomSettings />;
        break;
      }
      case 'service': {
        selectedTab = <ServiceSettings />;
        break;
      }
      case 'segments': {
        selectedTab = <SegmentsSettings />;
        break;
      }
      case 'moh-delivery': {
        selectedTab = <Delivery />;
        break;
      }
      case 'moh-takeaway': {
        selectedTab = <Takeaway />;
        break;
      }
      case 'moh-services': {
        selectedTab = <Services />;
        break;
      }
      case 'qr-code': {
        selectedTab = <QRCode />;
        break;
      }
      case 'advanced': {
        selectedTab = <Advanced />;
        break;
      }
      default: {
        break;
      }
    }
    return selectedTab;
  };

  return (
    <Box className={classes.boxContainer}>
      <RenderTabs variant="scrollable" scrollButtons="auto" tabElements={tabElements} value={value} onChange={handleChange} />
      <Box style={{ paddingTop: 20 }}>{getBox()}</Box>
    </Box>
  );
};
export default Settings;
