import React from 'react';
import styled from 'styled-components/macro';
import { TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputWithLabel from '../../../shared/InputWithLabel';
import Buttons from '../../../shared/Buttons';

const Container = styled.form`
  display: flex;
  flex-flow: column;
  width: 100%;

  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const RowContainer = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 30px;
  }
`;

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  phoneNumber: Yup.string().required('Required'),
  email: Yup.string().email('Email not valid').required('Required')
});

const NewDriver = ({ addDriver }) => {
  const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleReset, handleSubmit } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: ''
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      addDriver(values);
      setSubmitting(false);
    }
  });

  return (
    <Container>
      <InputWithLabel label="first-name" error={touched.firstName && errors.firstName}>
        <TextField
          name="firstName"
          fullWidth
          variant="outlined"
          color="primary"
          value={values.firstName}
          error={touched.firstName && !!errors.firstName}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </InputWithLabel>
      <InputWithLabel label="last-name" error={touched.lastName && errors.lastName}>
        <TextField
          name="lastName"
          fullWidth
          variant="outlined"
          color="primary"
          value={values.lastName}
          error={touched.lastName && !!errors.lastName}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </InputWithLabel>
      <RowContainer>
        <InputWithLabel label="phone-number" error={touched.phoneNumber && errors.phoneNumber}>
          <TextField
            name="phoneNumber"
            fullWidth
            variant="outlined"
            color="primary"
            value={values.phoneNumber}
            error={touched.phoneNumber && !!errors.phoneNumber}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
        <InputWithLabel label="e-mail" error={touched.email && errors.email}>
          <TextField
            name="email"
            fullWidth
            variant="outlined"
            color="primary"
            value={values.email}
            error={touched.email && !!errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </InputWithLabel>
      </RowContainer>
      <Buttons
        containerStyle={{ marginTop: 20 }}
        buttons={[
          { buttonType: 'reset', type: 'cancel', onClick: handleReset },
          { buttonType: 'submit', label: 'save', type: 'confirm', disabled: isSubmitting, onClick: handleSubmit }
        ]}
      />
    </Container>
  );
};

export default NewDriver;
