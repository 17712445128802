import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useSubscription } from '@apollo/react-hooks';
import { Typography, Button, RadioGroup, FormControlLabel, Radio, Icon } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import moment from 'moment';
import PageLayout from '../../shared/PageLayout';
import Tabs from '../../shared/Tabs/Tabs';
import CustomFilters from '../../shared/CustomFilters/CustomFilters';
import { CommonTable, CustomModal, primaryColor, CustomButton } from '../../../common-fe/src';
import ordersColumns from './table/ordersColumns';
import { TranslateTextComponent, translate } from '../../../translator';
import { ROUTE_MOH_DRIVERS } from '../../../router/routes';
import useCustomQuery from '../../../hooks/useCustomQuery';
import { GET_ORDERS } from '../../../graphql/moh/orders/queries';
import { GET_STAFF_USERS } from '../../../graphql/user/queries';
import { AuthContext } from '../../../contexts/AuthContext';
import ORDER_TYPE from '../../../constants/moh/orderType';
import OrderDetailModal from './OrderDetailModal';
import { SET_ORDER_STATUS } from '../../../graphql/moh/orders/mutations';
import { ModalContext } from '../../../contexts/ModalContext';
import { OrdersNotificationContext } from '../../../contexts/OrdersNotificationContext';
import { getApolloErrors } from '../../../apollo/ApolloProvider';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { MOH_ORDERS_STATUSES } from '../../../constants/moh/fbOrdersStatuses';
import { ROLES } from '../../../constants/users/usersRole';
import modalStyle from '../../../styles/shared/modalStyle';
import InputWithLabel from '../../shared/InputWithLabel';
import Buttons from '../../shared/Buttons';
import { GET_FB_OUTLET } from '../../../graphql/outlets/queries';
import { SET_SMART_ORDER_DRIVER } from '../../../graphql/user/mutations';
import { ON_ORDER_CREATED, ON_ORDER_STATUS_CHANGES } from '../../../graphql/moh/orders/subscriptions';

const TABS = { DELIVERY: { label: 'DELIVERY', code: ORDER_TYPE.DELIVERY }, TAKEAWAY: { label: 'TAKEAWAY', code: ORDER_TYPE.TAKE_AWAY } };
const DELIVERY_TAKEAWAY_TIME_OFFSET = 50;
const Orders = () => {
  const { showOrderConfirmationModal } = useContext(OrdersNotificationContext);
  const { appOutletUUID } = useContext(AuthContext);
  const { language } = useContext(LanguageContext);
  const modalContext = useContext(ModalContext);

  const [currentTab, setCurrentTab] = useState(TABS.DELIVERY);
  const [date, setDate] = useState(new Date());
  const [searchBarValue, setSearchBarValue] = useState('');
  const [filtersPage, setFiltersPage] = useState({
    page: 1,
    pageSize: 10
  });
  const [showModal, setShowModal] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const [currentOrderDeliveryDateAndTime, setCurrentOrderDeliveryDateAndTime] = useState('');
  const [selectedStatus, setSelectedStatus] = useState();
  const [deliveryDateAndTime, setDeliveryDateAndTime] = useState('');
  const [submitCount, setSubmitCount] = useState(0);
  const [preparingModal, setPreparingModal] = useState({ show: false, selectedStatus: null, fbOrderUUID: null });
  const [totalCount, setTotalCount] = useState({ delivery: 0, takeaway: 0 });

  const modalClasses = modalStyle();
  const history = useHistory();

  // Queries
  const getOrders = useCustomQuery(GET_ORDERS, {
    variables: {
      fbOutletUUID: appOutletUUID,
      type: [currentTab.code],
      page: filtersPage.page,
      pageSize: filtersPage.pageSize,
      keyword: searchBarValue,
      date: date ? moment(date).format('YYYY-MM-DD') : null
    },
    fetchPolicy: 'no-cache'
  });

  /** TEMP BADFIX */
  useEffect(() => {
    if (!showOrderConfirmationModal) {
      getOrders.refetch();
    }
  }, [showOrderConfirmationModal]);

  const getOutlet = useCustomQuery(GET_FB_OUTLET, {
    variables: {
      fbOutletUUID: appOutletUUID
    },
    fetchPolicy: 'no-cache'
  });

  const getStaffUsers = useCustomQuery(GET_STAFF_USERS, {
    variables: {
      fbOutletUUID: appOutletUUID,
      role: ROLES.FB_DRIVER
    }
  });

  const drivers = getStaffUsers?.data?.getStaffUsers?.data ?? null;

  // Mutations
  const [setOrderStatus, { loading: orderStatusLoading }] = useMutation(SET_ORDER_STATUS);
  const [setSmartOrderDriver] = useMutation(SET_SMART_ORDER_DRIVER);

  // Subscriptions
  useSubscription(ON_ORDER_CREATED, {
    variables: {
      outletUUID: appOutletUUID
    },
    onSubscriptionData: ({ subscriptionData }) => {
      getOrders
        .refetch({ fbOutletUUID: appOutletUUID, type: subscriptionData.data?.orderCreated?.orderType, date: moment().format('YYYY-MM-DD') })
        .then((res) => {
          if (subscriptionData.data?.orderCreated?.orderType === TABS.DELIVERY.code) {
            setTotalCount((prev) => ({ ...prev, delivery: res.data?.getOrders?.totalCount }));
          } else {
            setTotalCount((prev) => ({ ...prev, takeaway: res.data?.getOrders?.totalCount }));
          }
        })
        .catch((err) => console.log(err));
    }
  });

  useSubscription(ON_ORDER_STATUS_CHANGES, {
    variables: { outletUUID: appOutletUUID },
    onSubscriptionData: () => {
      getOrders
        .refetch({
          type: [currentTab.code],
          page: filtersPage.page,
          pageSize: filtersPage.pageSize,
          keyword: searchBarValue,
          date: date ? moment(date).format('YYYY-MM-DD') : null
        })
        .catch((err) => console.log(err));
    }
  });

  useEffect(() => {
    setFiltersPage((prev) => ({ ...prev, page: 1 }));
  }, [currentTab]);

  useEffect(() => {
    getOrders
      .refetch({ fbOutletUUID: appOutletUUID, type: TABS.DELIVERY.code, date: moment().format('YYYY-MM-DD') })
      .then((res) => {
        setTotalCount((prev) => ({ ...prev, delivery: res.data?.getOrders?.totalCount }));
      })
      .catch((err) => console.log(err));

    getOrders
      .refetch({ fbOutletUUID: appOutletUUID, type: TABS.TAKEAWAY.code, date: moment().format('YYYY-MM-DD') })
      .then((res) => {
        setTotalCount((prev) => ({ ...prev, takeaway: res.data?.getOrders?.totalCount }));
      })
      .catch((err) => console.log(err));
  }, []);

  const changeOrderStatus = ({ selectedStatus, fbOrderUUID, variables = {} }) => {
    modalContext.setModalLoading(true);
    setOrderStatus({
      variables: {
        fbOrderUUID,
        status: selectedStatus,
        ...variables
      }
    })
      .then(() => {
        resetPreparingModal();
        modalContext.setModalLoading(false);
        modalContext.openModal({
          class: 'success',
          title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
          text: <TranslateTextComponent capitalize>status-changed</TranslateTextComponent>
        });
        getOrders.refetch();
      })
      .catch((err) => {
        resetPreparingModal();
        modalContext.setModalLoading(false);
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const onChangeOrderStatus = ({ selectedStatus, fbOrderUUID, order }) => {
    const date = moment(order.date, 'YYYY-MM-DD').format('YYYY-MM-DD');
    const time = moment(order.time, 'HH:mm').format('HH:mm');

    const dateAndTime = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');

    setSelectedStatus(selectedStatus);
    setCurrentOrderDeliveryDateAndTime(dateAndTime);

    const outOfTime =
      moment(parseInt(order?.time, 10), 'HH:mm').format('HH:mm') <=
      moment(
        currentTab.label === TABS.DELIVERY.label
          ? getOutlet.data?.getFbOutlet?.smartSettings?.deliveryStartTime
          : getOutlet.data?.getFbOutlet?.smartSettings?.takeAwayStartTime,
        'HH:mm'
      )
        .add(DELIVERY_TAKEAWAY_TIME_OFFSET, 'minutes')
        .format('HH:mm');

    if (
      (!order?.deliveryTime && selectedStatus === MOH_ORDERS_STATUSES.preparing && outOfTime) ||
      (!order?.deliveryTime && selectedStatus === MOH_ORDERS_STATUSES.open && outOfTime)
    ) {
      setPreparingModal((prev) => ({ ...prev, show: true, selectedStatus, fbOrderUUID }));
    } else {
      modalContext.openModal({
        class: 'primary',
        title: <TranslateTextComponent capitalize>change-order-booking-status-title</TranslateTextComponent>,
        text: (
          <TranslateTextComponent
            vars={{ selectedStatus: translate(`orders-${selectedStatus?.toLowerCase()?.replace(/_/g, '-')}`, language) }}
            capitalize
          >
            change-order-booking-status-description
          </TranslateTextComponent>
        ),
        actionButtons: [
          <Button
            key={0}
            variant="outlined"
            color="primary"
            className={modalClasses.invertedButton}
            onClick={() => {
              modalContext.closeModal();
            }}
          >
            <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
          </Button>,
          <CustomButton
            key={1}
            variant="contained"
            color="primary"
            style={{ width: 'auto', fontSize: 14 }}
            onClick={() => {
              changeOrderStatus({ selectedStatus, fbOrderUUID });
            }}
          >
            <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
          </CustomButton>
        ]
      });
    }
  };

  const addDriver = (driverUUID, orderUUID) => {
    modalContext.setModalLoading(true);
    setSmartOrderDriver({
      variables: {
        driverUUID,
        orderUUID
      }
    })
      .then(() => {
        modalContext.setModalLoading(false);
        modalContext.openModal({
          class: 'success',
          title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
          text: <TranslateTextComponent capitalize>driver-added</TranslateTextComponent>
        });
        getOrders.refetch();
        getStaffUsers.refetch();
      })
      .catch((err) => {
        modalContext.setModalLoading(false);
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const onChangeDriver = (driverUUID, orderUUID) => {
    if (driverUUID && orderUUID) {
      modalContext.openModal({
        class: 'primary',
        title: <TranslateTextComponent capitalize>add-driver-to-order</TranslateTextComponent>,
        text: <TranslateTextComponent capitalize>add-driver-to-order-description</TranslateTextComponent>,
        actionButtons: [
          <Button
            key={0}
            variant="outlined"
            color="primary"
            className={modalClasses.invertedButton}
            onClick={() => {
              modalContext.closeModal();
            }}
          >
            <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
          </Button>,
          <Button
            key={1}
            variant="contained"
            color="primary"
            className={modalClasses.buttonStyle}
            onClick={() => {
              addDriver(driverUUID, orderUUID);
            }}
          >
            <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
          </Button>
        ]
      });
    }
  };

  const getDateAndTime = ({ offset = 0 }) => {
    if (!currentOrderDeliveryDateAndTime) return ' - ';
    return moment(currentOrderDeliveryDateAndTime).add(offset, 'minutes').format('DD-MM-YYYY HH:mm');
  };

  const resetPreparingModal = () => {
    setDeliveryDateAndTime('');
    setCurrentOrderDeliveryDateAndTime('');
    setSubmitCount(0);
    setPreparingModal((prev) => ({ ...prev, show: false, selectedStatus: null, fbOrderUUID: null }));
  };

  const setTablePages = (page, pageSize) => {
    setFiltersPage({
      ...filtersPage,
      page,
      pageSize
    });
  };

  return (
    <PageLayout>
      <CustomFilters
        filters={[
          {
            type: 'date',
            label: 'bookings-of',
            value: date,
            onChange: (value) => {
              setFiltersPage((prev) => ({ ...prev, page: 1 }));
              setDate(value);
              setFiltersPage({
                ...filtersPage,
                page: 1
              });
            }
          },
          {
            type: 'searchbar',
            onChange: ({ target: { value } }) => {
              setFiltersPage((prev) => ({ ...prev, page: 1 }));
              setSearchBarValue(value.target.value);
            }
          }
        ]}
      />
      <Tabs
        tabs={Object.keys(TABS).map((key) => ({
          label: TABS[key].label,
          notificationCounter: key === TABS.DELIVERY.label ? totalCount.delivery : totalCount.takeaway
        }))}
        currentTab={currentTab.label}
        changeTab={(tabName) => {
          const newTab = TABS[Object.keys(TABS).filter((key) => TABS[key].label === tabName)[0]];
          setCurrentTab(newTab);
        }}
      />
      <CommonTable
        isLoading={getOrders.loading}
        key={1}
        page={filtersPage.page}
        pageSize={filtersPage.pageSize}
        setTablePages={setTablePages}
        // orderType={tableFilters.orderType.toLocaleLowerCase()}
        // orderBy={tableFilters.orderBy}
        // setTableSort={setTableSort}
        totalRows={getOrders.data?.getOrders?.totalCount}
        isPaginationEnabled
        items={getOrders.data?.getOrders?.data ?? []}
        columns={ordersColumns({
          isDelivery: currentTab === TABS.DELIVERY,
          changeOrderStatus: onChangeOrderStatus,
          drivers,
          onChangeDriver,
          openModal: (item) => {
            setOrderData(item);
            setShowModal(true);
          },
          language
        })}
        tableContainerStyle={{ marginTop: 13 }}
        rowStyle={{ borderColor: '#B4DCED' }}
        tableHeader={
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#EDFAFF',
              minHeight: 28,
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
            }}
          >
            <TranslateTextComponent capitalize>orders-tot</TranslateTextComponent>: {getOrders.data?.getOrders?.totalCount}
          </Typography>
        }
      />
      {currentTab !== TABS.TAKEAWAY && (
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '30px 10px 20px 0px', minWidth: 325, alignSelf: 'flex-end', fontSize: 20, fontWeight: 500, color: 'white' }}
          onClick={() => history.push(ROUTE_MOH_DRIVERS)}
        >
          <TranslateTextComponent uppercase>drivers-list</TranslateTextComponent>
        </Button>
      )}
      <OrderDetailModal
        isDelivery={currentTab.label === TABS.DELIVERY.label}
        orderData={orderData}
        showModal={showModal}
        closeModal={() => {
          setShowModal(false);
          setOrderData(null);
        }}
      />
      <CustomModal
        header={translate('confirm-delivery-time', language)}
        styledHeader
        width="auto"
        height="auto"
        showModal={preparingModal.show}
        styleBody={{ justifyContent: 'flex-start' }}
        styleFooter={{ padding: '10px' }}
        footer={
          <Buttons
            containerStyle={{ justifyContent: 'space-between' }}
            buttons={[
              {
                type: 'cancel',
                label: 'cancel',
                style: { border: `1px solid ${primaryColor}`, color: primaryColor, backgroundColor: 'white' },
                onClick: () => resetPreparingModal()
              },
              {
                type: 'preview',
                label: 'confirm',
                spinnerColor: 'white',
                isLoading: orderStatusLoading,
                disabled: orderStatusLoading,
                onClick: () => {
                  if (deliveryDateAndTime) {
                    changeOrderStatus({
                      selectedStatus: preparingModal.selectedStatus,
                      fbOrderUUID: preparingModal.fbOrderUUID,
                      variables: { deliveryTime: deliveryDateAndTime }
                    });
                  } else {
                    setSubmitCount((prev) => prev + 1);
                  }
                }
              }
            ]}
          />
        }
        onClose={() => resetPreparingModal()}
      >
        <div style={{ display: 'flex', alignItems: 'center', flexFlow: 'column', width: '100%' }}>
          <Icon color="primary" style={{ width: 33, height: 33 }}>
            <HelpIcon style={{ width: 33, height: 33 }} />
          </Icon>
          <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>
            <TranslateTextComponent
              capitalize
              vars={{ selectedStatus: translate(`orders-${selectedStatus?.toLowerCase()?.replace(/_/g, '-')}`, language) }}
            >
              change-order-booking-status-title
            </TranslateTextComponent>
          </Typography>
          <Typography>
            <TranslateTextComponent
              capitalize
              vars={{ selectedStatus: translate(`orders-${selectedStatus?.toLowerCase()?.replace(/_/g, '-')}`, language) }}
            >
              change-order-booking-status-description
            </TranslateTextComponent>
          </Typography>
          <InputWithLabel error={(submitCount > 0 && !deliveryDateAndTime && 'required') || ''}>
            <RadioGroup
              aria-label="delivery date and time"
              value={deliveryDateAndTime}
              onChange={({ target: { value } }) => setDeliveryDateAndTime(value)}
            >
              <FormControlLabel
                value={getDateAndTime({})}
                disabled={!currentOrderDeliveryDateAndTime}
                control={<Radio />}
                label={getDateAndTime({})}
              />
              <FormControlLabel
                value={getDateAndTime({ offset: 15 })}
                disabled={!currentOrderDeliveryDateAndTime}
                control={<Radio />}
                label={getDateAndTime({ offset: 15 })}
              />
              <FormControlLabel
                value={getDateAndTime({ offset: 30 })}
                disabled={!currentOrderDeliveryDateAndTime}
                control={<Radio />}
                label={getDateAndTime({ offset: 30 })}
              />
              <FormControlLabel
                value={getDateAndTime({ offset: 45 })}
                disabled={!currentOrderDeliveryDateAndTime}
                control={<Radio />}
                label={getDateAndTime({ offset: 45 })}
              />
            </RadioGroup>
          </InputWithLabel>
        </div>
      </CustomModal>
    </PageLayout>
  );
};

export default Orders;
