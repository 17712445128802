/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core/';
import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import { TranslateTextComponent } from '../../../translator';
import TableButton from '../../shared/TableButton';
import { Dropdown } from '../../../common-fe/src';
import { CHECK_IN_STATUS } from '../../../constants/user/checkInStatus';
import { ROUTE_GUEST_PROFILE } from '../../../router/routes';

const textTranslate = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

const checkInColumns = ({ changeCheckInStatus, openCheckInModal, openDocument }) => {
  return [
    {
      id: 'first-name',
      label: textTranslate('first-name'),
      isSortable: false,
      align: 'left',
      render: (item) => item.profile?.firstName ?? '-'
    },
    {
      id: 'last-name',
      label: textTranslate('last-name'),
      isSortable: false,
      align: 'left',
      render: (item) => item.profile?.lastName ?? '-'
    },
    {
      id: 'email',
      disablePadding: false,
      label: textTranslate('email'),
      isSortable: false,
      align: 'left',
      render: (item) => item.profile?.email ?? '-'
    },
    {
      id: 'room',
      disablePadding: false,
      label: textTranslate('room'),
      isSortable: false,
      align: 'left',
      render: (item) => item.room?.number ?? '-'
    },
    {
      id: 'check-in',
      disablePadding: false,
      label: textTranslate('check-in'),
      isSortable: false,
      align: 'left',
      render: (item) => (item.checkinDate ? moment(item.checkinDate).format('DD-MM-YYYY') : '-')
    },
    {
      id: 'check-out',
      disablePadding: false,
      label: textTranslate('check-out'),
      isSortable: false,
      align: 'left',
      render: (item) => (item.checkoutDate ? moment(item.checkoutDate).format('DD-MM-YYYY') : '-')
    },
    {
      id: 'phone-number',
      disablePadding: false,
      label: textTranslate('phone-number'),
      isSortable: false,
      align: 'left',
      render: (item) => (item.profile?.staffPhoneNumber || item.staffPhoneNumber) ?? '-'
    },
    {
      id: 'status',
      disablePadding: false,
      label: textTranslate('status'),
      isSortable: false,
      align: 'left',
      render: (item) => (
        <Dropdown
          dropdownStyle={{ backgroundColor: 'white' }}
          value={item.status}
          items={Object.keys(CHECK_IN_STATUS).map((key) => ({
            label: textTranslate(CHECK_IN_STATUS[key].toLowerCase().replace(/_/g, '-')),
            value: CHECK_IN_STATUS[key]
          }))}
          onChange={({ target: { value } }) => {
            if (value !== item.status) {
              changeCheckInStatus({ user: item, checkInStatus: value });
            }
          }}
        />
      )
    },
    {
      id: 'document',
      disablePadding: false,
      label: textTranslate('document'),
      isSortable: false,
      align: 'left',
      render: (item) => (
        <TableButton fill="gray" disabled={!item.documentImages?.[0]?.path} style={{ cursor: 'pointer' }} onClick={() => openDocument(item)}>
          open
        </TableButton>
      )
    },
    {
      id: 'edit',
      disablePadding: false,
      label: textTranslate('actions'),
      isSortable: false,
      align: 'left',
      render: (item) => (
        <>
          <IconButton key="profileIcon" className="buttonCircle" style={{ marginRight: 5 }} onClick={() => openCheckInModal(item)}>
            <EditIcon />
          </IconButton>
          {item.profile?.uuid && (
            <Link key="editIcon" to={`${ROUTE_GUEST_PROFILE}/${item.profile?.uuid}`}>
              <IconButton className="buttonCircle" style={{ marginRight: 5 }}>
                <PersonIcon />
              </IconButton>
            </Link>
          )}
        </>
      )
    }
  ];
};

export default checkInColumns;
