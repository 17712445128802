import React, { useState, useContext } from 'react';
import { TextField, CircularProgress, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import moment from 'moment';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import { CustomModal, Dropdown, mainTextColor, redButton, DataPicker } from '../../../common-fe/src';
import { TranslateTextComponent, capitalizeFirstLetter, translate } from '../../../translator';
import Buttons from '../../shared/Buttons';
import InputWithLabel from '../../shared/InputWithLabel';
import ViewPhoto from '../../shared/ViewPhoto';
import useCustomQuery from '../../../hooks/useCustomQuery';
import { GET_ROOMS } from '../../../graphql/outlets/queries';
import { CHECK_IN_STATUS } from '../../../constants/user/checkInStatus';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { ModalContext } from '../../../contexts/ModalContext';
import { SAVE_CHECKIN } from '../../../graphql/user/mutations';
import { getApolloErrors } from '../../../apollo/ApolloProvider';
import { phoneNumberRegex } from '../../../utils/regex';
import MultiSelect from '../../shared/MultiSelect';
import { GET_TAGS, GET_PROFILE_DATA } from '../../../graphql/user/queries';
import { SAVE_PROFILE_DATA } from '../../../graphql/guests/mutations';
import modalStyle from '../../../styles/shared/modalStyle';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  min-width: 850px;
  padding: 20px 0;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  padding: 0 50px;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

const chipParams = {
  variant: 'default',
  color: 'primary',
  style: { color: 'white' }
};

const CheckInModal = ({ documentImages, profileUUID, onClose }) => {
  const { language } = useContext(LanguageContext);
  const modalContext = useContext(ModalContext);
  const [showDocument, setShowDocument] = useState(false);

  const classes = modalStyle();

  // Queries
  const getRooms = useCustomQuery(GET_ROOMS, { variables: { page: 1, pageSize: 500 } });
  const getTags = useCustomQuery(GET_TAGS);
  const getProfileData = useCustomQuery(GET_PROFILE_DATA, { variables: { profileUUID }, skip: !profileUUID });

  const profile = getProfileData?.data?.getProfileData;
  // Mutations
  const [saveCheckin, { loading: saveCheckinLoading }] = useMutation(SAVE_CHECKIN);
  const [saveProfileData, { loading: saveProfileDataLoading }] = useMutation(SAVE_PROFILE_DATA);

  const onSubmit = (values, { setSubmitting }) => {
    saveCheckin({
      variables: {
        profileUUID,
        roomUUID: values.roomNumber,
        checkinUUID: profile?.checkins?.[0]?.uuid,
        checkinInput: {
          checkinDate: moment(values.checkinDate).format('YYYY-MM-DD'),
          checkoutDate: moment(values.checkoutDate).format('YYYY-MM-DD'),
          status: values.status,
          arrangement: values?.arrangement,
          checkinPreferences: { localAdvisers: values?.localAdvisers?.map((local) => local.value) }
        }
      }
    })
      .then(() => {
        saveProfileData({
          variables: {
            profileUUID,
            profileData: {
              firstName: values.firstName,
              lastName: values.lastName,
              fullName: values.fullName,
              email: values.email,
              phone: values.phone,
              permissionContactAdvertisingViaSms: profile?.permissionContactAdvertisingViaSms ?? 'NOT_ASKED',
              permissionContactBookingTroublesViaMail: profile?.permissionContactBookingTroublesViaMail ?? 'NOT_ASKED',
              permissionContactBookingTroublesViaPhone: profile?.permissionContactBookingTroublesViaPhone ?? 'NOT_ASKED',
              permissionContactBookingTroublesViaEmail: profile?.permissionContactBookingTroublesViaEmail ?? 'NOT_ASKED',
              permissionContactAdvertisingViaMail: profile?.permissionContactAdvertisingViaMail ?? 'NOT_ASKED',
              permissionContactAdvertisingViaPhone: profile?.permissionContactAdvertisingViaPhone ?? 'NOT_ASKED',
              permissionContactAdvertisingViaEmail: profile?.permissionContactAdvertisingViaEmail ?? 'NOT_ASKED',
              permissionContactAdvertisingViaSms: profile?.permissionContactAdvertisingViaEmail ?? 'NOT_ASKED'
            }
          }
        }).then(() => {
          modalContext.openModal({
            class: 'success',
            title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
            text: <TranslateTextComponent capitalize>check-in-changed-successfully</TranslateTextComponent>,
            actionButtons: [
              <Button
                key={0}
                color="primary"
                variant="contained"
                className={classes.buttonStyle}
                onClick={() => {
                  onClose();
                  modalContext.closeModal();
                }}
              >
                OK
              </Button>
            ]
          });
        });
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
    setSubmitting(false);
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(translate('required', language)),
    lastName: Yup.string().required(translate('required', language)),
    status: Yup.string().required(translate('required', language)),
    checkinDate: Yup.string().typeError(translate('invalid-date', language)).required(translate('required', language)),
    checkoutDate: Yup.string().typeError(translate('invalid-date', language)).required(translate('required', language)),
    phone: Yup.string().matches(phoneNumberRegex, translate('invalid-number', language)),
    arrangement: Yup.string().required(translate('required', language)),
    roomNumber: Yup.string().required(translate('required', language))
  });

  const { values, errors, touched, handleSubmit, handleReset, handleChange, handleBlur, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: profile?.firstName ?? '',
      lastName: profile?.lastName ?? '',
      fullName: profile?.fullName ?? '',
      email: profile?.email ?? '',
      checkinDate: profile?.currentCheckin?.checkinDate ?? null,
      checkoutDate: profile?.currentCheckin?.checkoutDate ?? null,
      roomNumber: profile?.currentCheckin?.room?.uuid ?? '',
      phone: profile?.phone ?? '',
      status: profile?.currentCheckin?.status ?? '',
      arrangement: profile?.currentCheckin?.arrangement ?? '',
      localAdvisers: profile?.currentCheckin?.checkinPreferences?.localAdvisers?.map((local) => ({ label: local, value: local })) ?? []
    },
    validationSchema,
    onSubmit
  });

  const getTagsData = (fieldName) => {
    return (
      getTags.data?.getTags?.data.find((tag) => tag.fieldName === fieldName)?.tags?.map((tag) => ({ label: translate(tag, language), value: tag })) ??
      []
    );
  };

  return (
    <CustomModal
      width="auto"
      height="auto"
      showModal={!!profileUUID}
      header={`check-in: UUID#${profile?.currentCheckin?.uuid ?? ''}`}
      styledHeader
      styleModal={{ zIndex: 1000 }}
      onClose={onClose}
    >
      <Container>
        {values.fullName && (
          <RowContainer>
            <InputWithLabel label="full-name" error={touched.fullName && errors.fullName}>
              <TextField
                name="fullName"
                fullWidth
                variant="outlined"
                color="primary"
                value={values.fullName}
                error={touched.fullName && !!errors.fullName}
                disabled
                onBlur={handleBlur}
              />
            </InputWithLabel>
          </RowContainer>
        )}
        <RowContainer>
          <InputWithLabel label="first-name" error={touched.firstName && errors.firstName}>
            <TextField
              name="firstName"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.firstName}
              error={touched.firstName && !!errors.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
          <InputWithLabel label="last-name" error={touched.lastName && errors.lastName}>
            <TextField
              name="lastName"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.lastName}
              error={touched.lastName && !!errors.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
        </RowContainer>
        <RowContainer>
          <InputWithLabel label="email" error={touched.email && errors.email}>
            <TextField
              name="email"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.email}
              error={touched.email && !!errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
          <InputWithLabel label="stay-contact-number" error={touched.phone && errors.phone}>
            <TextField
              name="phone"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.phone}
              error={touched.phone && !!errors.phone}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
        </RowContainer>
        <RowContainer>
          <InputWithLabel label="arrangement" error={touched.arrangement && errors.arrangement}>
            <Dropdown
              dropdownStyle={{ height: 40, width: '100%' }}
              items={getTagsData('arrangement').map((a) => ({ label: a.label.toUpperCase(), value: a.value }))}
              value={values.arrangement}
              onChange={({ target: { value } }) => setFieldValue('arrangement', value)}
            />
          </InputWithLabel>

          <InputWithLabel label="assign-local-adviser-max-3">
            <MultiSelect
              freeSolo={getTags.data?.getTags?.data?.find((tag) => tag.fieldName === 'checkinPreferences.localAdvisers')?.isFreeTagAllowed}
              loading={saveCheckinLoading || saveProfileDataLoading}
              loadingText={<CircularProgress />}
              options={getTagsData('checkinPreferences.localAdvisers')}
              chipParams={chipParams}
              value={values?.localAdvisers ?? []}
              getOptionSelected={(option, { value }) => option.value === value}
              onChange={(e, value) => {
                if (value.length <= 3) {
                  const newValues = [];
                  value.forEach((v) => {
                    if (typeof v === 'string') {
                      newValues.push({ label: v, value: v });
                    } else newValues.push(v);
                  });
                  setFieldValue('localAdvisers', newValues);
                }
              }}
            />
          </InputWithLabel>
        </RowContainer>
        <RowContainer>
          <InputWithLabel label="check-in-date" error={touched.checkinDate && errors.checkinDate}>
            <DataPicker
              fullWidth
              value={values.checkinDate}
              error={touched.checkinDate && !!errors.checkinDate}
              onChange={(value) => setFieldValue('checkinDate', value)}
            />
          </InputWithLabel>
          <InputWithLabel label="check-out-date" error={touched.checkoutDate && errors.checkoutDate}>
            <DataPicker
              fullWidth
              value={values.checkoutDate}
              error={touched.checkoutDate && !!errors.checkoutDate}
              onChange={(value) => setFieldValue('checkoutDate', value)}
            />
          </InputWithLabel>
          <InputWithLabel label="room-number" error={touched.roomNumber && errors.roomNumber}>
            <Dropdown
              name="roomNumber"
              dropdownStyle={{ width: '100%', minHeight: 40 }}
              value={values.roomNumber}
              items={getRooms.data?.getRooms?.data?.sort((a, b) => a.number - b.number)?.map((room) => ({ label: room.number, value: room.uuid }))}
              onChange={({ target: { value } }) => setFieldValue('roomNumber', value)}
            />
          </InputWithLabel>
        </RowContainer>
        <RowContainer>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <TranslateTextComponent capitalize style={{ fontWeight: 500, color: mainTextColor }}>
              document
            </TranslateTextComponent>
            :
            <TranslateTextComponent capitalize style={{ cursor: 'pointer', marginLeft: 5, color: '#48B7FF' }} onClick={() => setShowDocument(true)}>
              view-file
            </TranslateTextComponent>
          </div>
          <div style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
            <InputWithLabel label="check-in-status" horizontal labelStyle={{ whiteSpace: 'nowrap' }} error={touched.status && errors.status}>
              <Dropdown
                dropdownStyle={{ width: '100%', minHeight: 40 }}
                value={values.status}
                items={Object.keys(CHECK_IN_STATUS).map((key) => ({
                  label: capitalizeFirstLetter(translate(CHECK_IN_STATUS[key]?.toLowerCase()?.replace(/_/g, '-'), language)),
                  value: CHECK_IN_STATUS[key]
                }))}
                onChange={({ target: { value } }) => setFieldValue('status', value)}
              />
            </InputWithLabel>
          </div>
        </RowContainer>
        <RowContainer>
          <Buttons
            buttons={[
              {
                buttonType: 'button',
                type: 'cancel',
                label: 'cancel',
                disabled: saveCheckinLoading || saveProfileDataLoading,
                style: {
                  padding: 0,
                  marginRight: 40,
                  backgroundColor: 'transparent',
                  border: 'none',
                  color: redButton,
                  textDecoration: 'underline',
                  minHeight: 50,
                  fontSize: 20,
                  fontWeight: 'medium',
                  textTransform: 'uppercase'
                },
                onClick: () => handleReset
              },
              {
                buttonType: 'button',
                type: 'confirm',
                label: 'confirm',
                disabled: saveCheckinLoading || saveProfileDataLoading,
                isLoading: saveCheckinLoading || saveProfileDataLoading,
                style: { borderRadius: 4, textTransform: 'uppercase', minWidth: 200, minHeight: 50, fontSize: 20 },
                onClick: handleSubmit
              }
            ]}
          />
        </RowContainer>
      </Container>
      <ViewPhoto showPhoto={showDocument} photos={documentImages ?? []} hide={() => setShowDocument(false)} />
    </CustomModal>
  );
};

export default CheckInModal;
