import { makeStyles } from '@material-ui/core/styles';

const styles = {
  dashboard: () => ({
    '& iconRed': {
      '& path': {
        fill: 'red'
      }
    }
  }),
  icon: (props) => ({
    height: 300,
    '& path': {
      fill: props.color || 'yellow'
    }
  }),
  gridButtonContainer: () => ({
    alignContent: 'center',
    justifyContent: 'center'
  })
};

export default makeStyles(styles);
