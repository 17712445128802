import React from 'react';
import { Box } from '@material-ui/core';
import styles from '../../../styles/billsManagement/mainContainer';

const MainContainer = ({ children }) => {
  const classes = styles();

  return <Box className={classes.container}>{children}</Box>;
};

export default MainContainer;
