import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button, Typography } from '@material-ui/core';
import { CommonTable, CustomModal } from '../../../common-fe/src';
import { TranslateTextComponent } from '../../../translator';
import useCustomQuery from '../../../hooks/useCustomQuery';
import { getApolloErrors } from '../../../apollo/ApolloProvider';
import { GET_STAFF_USERS } from '../../../graphql/user/queries';
import { SAVE_SMART_DRIVER } from '../../../graphql/user/mutations';
import { AuthContext } from '../../../contexts/AuthContext';
import { ModalContext } from '../../../contexts/ModalContext';
import CustomFilters from '../../shared/CustomFilters/CustomFilters';
import PageLayout from '../../shared/PageLayout';
import NewDriver from './forms/NewDriver';
import { ROLES } from '../../../constants/users/usersRole';
import driversColumns from './table/driversColumns';

const Drivers = () => {
  const [date, setDate] = useState(null);
  const [searchBarValue, setSearchBarValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [pageFilters, setPageFilters] = useState({
    page: 1,
    pageSize: 10
  });

  const { appOutletUUID } = useContext(AuthContext);
  const modalContext = useContext(ModalContext);

  // Queries
  const getStaffUsers = useCustomQuery(GET_STAFF_USERS, {
    variables: {
      fbOutletUUID: appOutletUUID,
      role: ROLES.FB_DRIVER,
      page: pageFilters.page,
      pageSize: pageFilters.pageSize,
      keyword: searchBarValue
    },
    fetchPolicy: 'network-only'
  });

  // Mutations
  const [addDriver] = useMutation(SAVE_SMART_DRIVER);

  const drivers = getStaffUsers?.data?.getStaffUsers?.data ?? [];
  const totalCount = getStaffUsers?.data?.getStaffUsers?.totalCount ?? null;

  const onAddDriver = (driverInput) => {
    addDriver({
      variables: {
        fbOutletUUID: appOutletUUID,
        driverInput: {
          firstName: driverInput.firstName,
          lastName: driverInput.lastName,
          email: driverInput.email,
          phoneNumber: driverInput.phoneNumber
        }
      }
    })
      .then(() => {
        modalContext.openModal({
          class: 'success',
          title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
          text: <TranslateTextComponent capitalize>driver-added</TranslateTextComponent>
        });
        getStaffUsers.refetch();
        setShowModal(false);
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  return (
    <PageLayout>
      <CustomFilters
        filters={[
          { type: 'date', label: 'drivers-list-of', value: date, onChange: (value) => setDate(value) },
          { type: 'searchbar', onChange: ({ target: { value } }) => setSearchBarValue(value) }
        ]}
      />
      <CommonTable
        isLoading={false}
        key={1}
        page={pageFilters.page}
        pageSize={pageFilters.pageSize}
        setTablePages={(page, pageSize) =>
          setPageFilters({
            ...pageFilters,
            page,
            pageSize
          })
        }
        // orderType={tableFilters.orderType.toLocaleLowerCase()}
        // orderBy={tableFilters.orderBy}
        // setTableSort={setTableSort}
        totalRows={totalCount}
        isPaginationEnabled
        items={drivers}
        columns={driversColumns()}
        tableContainerStyle={{ marginTop: 13 }}
        rowStyle={{ borderColor: '#B4DCED' }}
        tableHeader={
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#EDFAFF',
              minHeight: 28,
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
            }}
          >
            <TranslateTextComponent capitalize>orders-tot</TranslateTextComponent>: 10
          </Typography>
        }
      />
      <Button
        variant="contained"
        color="primary"
        style={{ margin: '30px 10px 20px 0px', minWidth: 325, alignSelf: 'flex-end', fontSize: 20, fontWeight: 500, color: 'white' }}
        onClick={() => setShowModal(true)}
      >
        <TranslateTextComponent uppercase>new-driver</TranslateTextComponent>
      </Button>
      <CustomModal
        showModal={showModal}
        hideCloseButton
        onClose={() => setShowModal(false)}
        width={430}
        height="auto"
        styledHeader
        styleBody={{
          borderTop: 'none',
          backgroundColor: 'white',
          borderRadius: 20,
          justifyContent: 'flex-start',
          padding: '25px 40px'
        }}
        header={<TranslateTextComponent uppercase>new-driver</TranslateTextComponent>}
      >
        <NewDriver addDriver={onAddDriver} />
      </CustomModal>
    </PageLayout>
  );
};

export default Drivers;
