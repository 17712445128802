/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_FB_SERVICES = gql`
  query getFbServices($fbOutletUUID: String!, $page: Int, $pageSize: Int) {
    getFbServices(fbOutletUUID: $fbOutletUUID, page: $page, pageSize: $pageSize) {
      totalCount
      data {
        id
        uuid
        name
        description
        icon
        startTime
        endTime
        isActive
        lastReservation
        slotSizeMinutes
        maxGuestsForSlot
      }
    }
  }
`;

export const GET_CURRENT_SERVICE = gql`
  query getCurrentService($fbOutletUUID: String!) {
    getCurrentService(fbOutletUUID: $fbOutletUUID) {
      id
      uuid
      name
      startTime
      endTime
      icon
      isActive
      description
      lastReservation
    }
  }
`;
