import React, { useState, useContext } from 'react';
import { Box, Grid, Button, TextField, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styles from '../../../styles/cash/modals/dishModalStyle';
import { CustomModal, primaryColor, greenButton, redButton } from '../../../common-fe/src';
import { TranslateTextComponent, translate, capitalizeFirstLetter } from '../../../translator';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { suggestionsIngredients } from '../../../fixtures/order/order';

const ID_BUTTON_WITHOUT = 'redButton';
const ID_BUTTON_WITH = 'greenButton';

const DishModal = ({ showDishModal, selectedDish: dish, onSave, closeModal }) => {
  const [quantity, setQuantity] = useState(1);
  const [withWithoutButton, setWithWithoutButton] = useState(false);
  const [withIngredients, setWithIngredients] = useState([]);
  const [withoutIngredients, setWithoutIngredients] = useState([]);
  const [notes, setNotes] = useState('');
  const { language } = useContext(LanguageContext);
  const classes = styles();

  const onAddIngredient = (ingredient) => {
    if (!withIngredients.find((ingr) => ingr.label === ingredient) && !withoutIngredients.find((ingr) => ingr.label === ingredient)) {
      if (withWithoutButton === ID_BUTTON_WITHOUT) {
        setWithIngredients([...withIngredients, { label: ingredient, color: greenButton, type: 'with' }]);
      }
      if (withWithoutButton === ID_BUTTON_WITH) {
        setWithoutIngredients([...withoutIngredients, { label: ingredient, color: redButton, type: 'without' }]);
      }
    }
  };

  const onRemoveIngredient = (ingredient) => {
    if (ingredient.type === 'with') {
      setWithIngredients(withIngredients.filter((ingr) => ingr.label !== ingredient.label));
    }
    if (ingredient.type === 'without') {
      setWithoutIngredients(withoutIngredients.filter((ingr) => ingr.label !== ingredient.label));
    }
  };

  return (
    <CustomModal
      hideCloseButton
      width="auto"
      height="auto"
      stylePaper={{ borderRadius: 0, minWidth: 280 }}
      styleBody={{ padding: 0 }}
      showModal={showDishModal}
      onClose={closeModal}
    >
      <Grid className={classes.container}>
        <Grid item className={classes.name}>
          {dish.name}
        </Grid>
        <Grid item className={classes.quantity}>
          <TranslateTextComponent capitalize>quantity</TranslateTextComponent>
          <Box>
            <Button
              className={classes.quantityButton}
              variant="outlined"
              color="primary"
              style={{ borderRadius: '4px 0 0 4px' }}
              onClick={() => setQuantity((prev) => (prev - 1 > 0 ? prev - 1 : prev))}
            >
              -
            </Button>
            <span style={{ border: `2px solid ${primaryColor}`, display: 'flex', alignItems: 'center', padding: '0 10px' }}>{quantity}</span>
            <Button
              className={classes.quantityButton}
              variant="outlined"
              color="primary"
              style={{ borderRadius: '0 4px 4px 0' }}
              onClick={() => setQuantity((prev) => prev + 1)}
            >
              +
            </Button>
          </Box>
        </Grid>
        <Grid item className={classes.withOrWithout}>
          <Box className={classes.withOrWithoutButtons}>
            <Button
              className={classes.withButton}
              variant="outlined"
              onClick={() => setWithWithoutButton((prev) => (prev === ID_BUTTON_WITH ? null : ID_BUTTON_WITH))}
            >
              <TranslateTextComponent uppercase style={{ marginRight: 5 }}>
                without
              </TranslateTextComponent>
              -
            </Button>
            <Button
              className={classes.withoutButton}
              variant="outlined"
              onClick={() => setWithWithoutButton((prev) => (prev === ID_BUTTON_WITHOUT ? null : ID_BUTTON_WITHOUT))}
            >
              <TranslateTextComponent uppercase style={{ marginRight: 5 }}>
                with
              </TranslateTextComponent>
              +
            </Button>
          </Box>
          {withWithoutButton && (
            <Autocomplete
              multiple
              freeSolo
              classes={{
                root: classes.autocompleteRoot,
                paper: classes.autocompletePaper,
                inputRoot: classes.autocompleteInputRoot
              }}
              id="tags-standard"
              options={suggestionsIngredients}
              getOptionLabel={(option) => option}
              value={[...withIngredients, ...withoutIngredients]}
              onChange={(e, v) => {
                if (v.length > [...withIngredients, ...withoutIngredients].length && v[v.length - 1]) {
                  onAddIngredient(v[v.length - 1]);
                }
              }}
              renderTags={(values) => {
                return values.map((tag, index) => (
                  <Chip
                    key={index}
                    className={classes.chip}
                    label={tag.label}
                    onDelete={() => onRemoveIngredient(tag)}
                    style={{ backgroundColor: tag.color, color: 'white', height: 26, fontSize: 12 }}
                  />
                ));
              }}
              renderInput={(params) => {
                return (
                  <TextField className="autocompleteTextField" {...params} variant="outlined" margin="normal" fullWidth style={{ minHeight: 50 }} />
                );
              }}
            />
          )}
        </Grid>
        <Grid item className={classes.notes}>
          <TextField
            className={classes.textField}
            style={{ marginTop: 5, marginBottom: 5 }}
            label={capitalizeFirstLetter(translate('notes', language))}
            variant="outlined"
            onChange={(e) => setNotes(e.target.value)}
          />
        </Grid>
        <Grid item className={classes.buttons}>
          <Button variant="contained" style={{ backgroundColor: redButton, color: 'white' }} onClick={closeModal}>
            <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: greenButton, color: 'white' }}
            onClick={() => onSave({ dish, quantity, withIngredients, withoutIngredients, notes })}
          >
            <TranslateTextComponent uppercase>save</TranslateTextComponent>
          </Button>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default DishModal;
