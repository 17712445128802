import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import { Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import PageLayout from '../../shared/PageLayout';
import CustomFilters from '../../shared/CustomFilters/CustomFilters';
import { CommonTable } from '../../../common-fe/src';
import bookingsColumns from './table/bookingsColumns';
import { TranslateTextComponent, translate } from '../../../translator';
import BookingsForm from './forms/BookingsForm';
import useCustomQuery from '../../../hooks/useCustomQuery';
import { GET_ORDERS } from '../../../graphql/moh/orders/queries';
import { AuthContext } from '../../../contexts/AuthContext';
import ORDER_TYPE from '../../../constants/moh/orderType';
import { ROUTE_MOH_SERVICES } from '../../../router/routes';
import { SET_ORDER_STATUS } from '../../../graphql/moh/orders/mutations';
import { getApolloErrors } from '../../../apollo/ApolloProvider';
import { ModalContext } from '../../../contexts/ModalContext';
import { MOH_ORDERS_STATUSES } from '../../../constants/moh/fbOrdersStatuses';
import modalStyle from '../../../styles/shared/modalStyle';
import { LanguageContext } from '../../../contexts/LanguageContext';
import Guard from '../../shared/Guard';
import { ROLES } from '../../../constants/users/usersRole';

const Container = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: flex-end;
`;

const Bookings = () => {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [searchBarValue, setSearchBarValue] = useState('');
  const [filtersPage, setFiltersPage] = useState({
    page: 1,
    pageSize: 10
  });
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [isShowingBookingModal, setIsShowingBookingModal] = useState(false);

  const { appOutletUUID } = useContext(AuthContext);
  const modalContext = useContext(ModalContext);
  const { language } = useContext(LanguageContext);

  const modalClasses = modalStyle();

  // Queries
  const getOrders = useCustomQuery(GET_ORDERS, {
    variables: {
      fbOutletUUID: appOutletUUID,
      date: date !== null ? moment(date).format('YYYY-MM-DD') : null,
      type: [ORDER_TYPE.SERVICE],
      page: filtersPage.page,
      pageSize: filtersPage.pageSize,
      keyword: searchBarValue
    },
    fetchPolicy: 'network-only'
  });

  // Mutations
  const [setOrderStatus] = useMutation(SET_ORDER_STATUS);

  const changeOrderStatus = ({ selectedStatus, fbOrderUUID, variables = {} }) => {
    if (selectedStatus?.toLowerCase() === MOH_ORDERS_STATUSES.completed) {
      modalContext.openModal({
        class: 'primary',
        title: <TranslateTextComponent capitalize>order-completed</TranslateTextComponent>,
        text: <TranslateTextComponent capitalize>order-completed-description</TranslateTextComponent>,
        actionButtons: [
          <Button
            key={0}
            variant="contained"
            color="primary"
            style={{ color: 'white' }}
            onClick={() => {
              modalContext.closeModal();
            }}
          >
            <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
          </Button>,
          <Button
            key={1}
            variant="contained"
            color="primary"
            style={{ color: 'white' }}
            onClick={() => {
              setOrderStatus({
                variables: {
                  fbOrderUUID,
                  status: selectedStatus
                }
              })
                .then(() => {
                  modalContext.openModal({
                    class: 'success',
                    title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
                    text: <TranslateTextComponent capitalize>status-changed</TranslateTextComponent>
                  });
                  getOrders.refetch();
                })
                .catch((err) => {
                  modalContext.openModal({
                    class: 'danger',
                    title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                    text: getApolloErrors(err).join(' - ')
                  });
                });
            }}
          >
            <TranslateTextComponent uppercase>ok</TranslateTextComponent>
          </Button>
        ]
      });
    } else {
      setOrderStatus({
        variables: {
          fbOrderUUID,
          status: selectedStatus,
          ...variables
        }
      })
        .then(() => {
          modalContext.openModal({
            class: 'success',
            title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
            text: <TranslateTextComponent capitalize>status-changed</TranslateTextComponent>
          });
          getOrders.refetch();
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        });
    }
  };

  const onChangeOrderStatus = ({ selectedStatus, fbOrderUUID }) => {
    if (selectedStatus === MOH_ORDERS_STATUSES.completed) {
      changeOrderStatus({ selectedStatus, fbOrderUUID });
    } else {
      modalContext.openModal({
        class: 'primary',
        title: <TranslateTextComponent capitalize>change-order-booking-status-title</TranslateTextComponent>,
        text: (
          <TranslateTextComponent
            vars={{
              selectedStatus: translate(
                `orders-${selectedStatus === 'preparing' ? 'working' : selectedStatus?.toLowerCase()?.replace(/_/g, '-')}`,
                language
              )
            }}
            capitalize
          >
            change-order-booking-status-description
          </TranslateTextComponent>
        ),
        actionButtons: [
          <Button
            key={0}
            variant="outlined"
            color="primary"
            className={modalClasses.invertedButton}
            onClick={() => {
              modalContext.closeModal();
            }}
          >
            <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
          </Button>,
          <Button
            key={1}
            variant="contained"
            color="primary"
            className={modalClasses.buttonStyle}
            onClick={() => {
              changeOrderStatus({ selectedStatus, fbOrderUUID });
            }}
          >
            <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
          </Button>
        ]
      });
    }
  };

  const setTablePages = (page, pageSize) => {
    setFiltersPage({
      ...filtersPage,
      page,
      pageSize
    });
  };

  return (
    <PageLayout>
      <CustomFilters
        filters={[
          {
            type: 'date',
            label: 'bookings-of',
            value: date,
            onChange: (value) => {
              setFiltersPage({
                ...filtersPage,
                page: 1
              });
              setDate(value);
            }
          },
          {
            type: 'searchbar',
            onChange: (value) => {
              setFiltersPage({
                ...filtersPage,
                page: 1
              });
              setSearchBarValue(value.target.value);
            }
          }
        ]}
      />
      <CommonTable
        isLoading={getOrders.loading}
        key={1}
        page={filtersPage.page}
        pageSize={filtersPage.pageSize}
        setTablePages={setTablePages}
        set
        // orderType={tableFilters.orderType.toLocaleLowerCase()}
        // orderBy={tableFilters.orderBy}
        // setTableSort={setTableSort}
        totalRows={getOrders.data?.getOrders?.totalCount}
        isPaginationEnabled
        items={getOrders.data?.getOrders?.data ?? []}
        columns={bookingsColumns({
          changeOrderStatus: onChangeOrderStatus,
          openModal: (data) => {
            setIsShowingBookingModal(true);
            setModalData(data);
            setShowModal(true);
          },
          language
        })}
        tableContainerStyle={{ marginTop: 13 }}
        rowStyle={{ borderColor: '#B4DCED' }}
        tableHeader={
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#EDFAFF',
              minHeight: 28,
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
            }}
          >
            <TranslateTextComponent capitalize>bookings-tot</TranslateTextComponent>: {getOrders.data?.getOrders?.totalCount}
          </Typography>
        }
      />
      <Container>
        <Button
          variant="contained"
          color="primary"
          style={{
            margin: '30px 10px 20px 0px',
            minWidth: 325,
            marginRight: 30,
            alignSelf: 'flex-end',
            fontSize: 20,
            fontWeight: 500,
            color: 'white'
          }}
          onClick={() => {
            setIsShowingBookingModal(false);
            setShowModal(true);
          }}
        >
          <TranslateTextComponent uppercase>new-booking</TranslateTextComponent>
        </Button>
        <Guard roles={[ROLES.ROLE_SUPER_ADMIN]}>
          <Link to={ROUTE_MOH_SERVICES}>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: '30px 10px 20px 0px', minWidth: 325, alignSelf: 'flex-end', fontSize: 20, fontWeight: 500, color: 'white' }}
            >
              <TranslateTextComponent uppercase>services-list</TranslateTextComponent>
            </Button>
          </Link>
        </Guard>
      </Container>
      {showModal && (
        <BookingsForm
          data={modalData}
          isShowingBookingModal={isShowingBookingModal}
          closeModal={() => {
            setShowModal(false);
            setModalData(null);
          }}
          refetchQuery={() => getOrders.refetch()}
        />
      )}
    </PageLayout>
  );
};

export default Bookings;
