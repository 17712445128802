import React from 'react';
import Loader from 'react-loader-spinner';
import { Grid } from '@material-ui/core/';

const CustomLoader = () => {
  return (
    <Grid key="loader" container justify="center">
      <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
    </Grid>
  );
};

export default CustomLoader;
