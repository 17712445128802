import { makeStyles } from '@material-ui/core/styles';

const ageStyle = {
  titleTypography: () => ({
    fontSize: 11,
    fontWeight: 'bold',
    color: '#5A5A5A'
  }),
  descriptionTypography: () => ({
    fontSize: 11,
    color: '#979797'
  }),
  buttonDetails: () => ({
    // background: primaryColor,
    color: 'white',
    fontSize: 10,
    width: 77,
    letterSpacing: 0,
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
  }),
  checkbox: () => ({
    cursor: 'unset',
    padding: 0,
    '& span': {
      width: 11,
      '& svg': {
        width: 15,
        height: 15
      }
    }
  })
};
export default makeStyles(ageStyle);
