import React from 'react';
import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Grid, TextField } from '@material-ui/core';
import { CustomModal, redButton, greenButton } from '../../../common-fe/src';
import { TranslateTextComponent } from '../../../translator';
import InputWithLabel from '../../shared/InputWithLabel';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;

  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const RowContainer = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 20px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  address: Yup.string(),
  addressNumber: Yup.number().typeError('Invalid address number'),
  city: Yup.string(),
  zipCode: Yup.number().typeError('Invalid zip code'),
  phone: Yup.number().typeError('Invalid phone number'),
  email: Yup.string().email('Invalid email'),
  country: Yup.string(),
  latitude: Yup.number()
    .typeError('Invalid latitude')
    .test('Latitude validation', 'Invalid latitude', (value) => value >= -90 && value <= 90),
  longitude: Yup.number()
    .typeError('Invalid longitude')
    .test('Longitude validation', 'Invalid longitude', (value) => value >= -180 && value <= 180),
  notificationEmail: Yup.string().email('Invalid email'),
  fiscalPrinterIp: Yup.string()
});

const OutletModal = ({ showModal, closeModal, addNewOutlet, selectedDataOutlet, isEditingOutlet, editOutlet }) => {
  const { values, errors, touched, handleBlur, handleChange, handleReset, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: selectedDataOutlet?.name ?? '',
      address: selectedDataOutlet?.address ?? '',
      addressNumber: selectedDataOutlet?.addressNumber ?? '',
      city: selectedDataOutlet?.city ?? '',
      zipCode: selectedDataOutlet?.zipCode ?? '',
      phone: selectedDataOutlet?.phoneNumber ?? '',
      email: selectedDataOutlet?.email ?? '',
      country: selectedDataOutlet?.country ?? '',
      latitude: selectedDataOutlet?.geolocation?.coordinates?.[1] ?? 0,
      longitude: selectedDataOutlet?.geolocation?.coordinates?.[0] ?? 0,
      notificationEmail: selectedDataOutlet?.notificationEmail ?? '',
      fiscalPrinterIp: selectedDataOutlet?.fiscalPrinterIpAddress ?? ''
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (!isEditingOutlet) addNewOutlet(values);
      else editOutlet(values);
      setSubmitting(false);
    }
  });

  return (
    <CustomModal
      styledHeader
      height="auto"
      width={857}
      stylePaper
      header={<TranslateTextComponent uppercase>{isEditingOutlet ? 'edit-outlet' : 'new-outlet'}</TranslateTextComponent>}
      styleBody={{ padding: '10px 60px' }}
      showModal={showModal}
      onClose={closeModal}
    >
      <Container>
        <RowContainer>
          <InputWithLabel label="outlet-name" error={touched.name && errors.name}>
            <TextField
              name="name"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.name}
              error={touched.name && !!errors.name}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
        </RowContainer>
        <Grid container>
          <Grid item xs={10} style={{ paddingRight: 10 }}>
            <InputWithLabel label="address" error={touched.address && errors.address}>
              <TextField
                name="address"
                fullWidth
                variant="outlined"
                color="primary"
                value={values.address}
                error={touched.address && !!errors.address}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputWithLabel>
          </Grid>
          <Grid item xs={2}>
            <InputWithLabel label="address-number" error={touched.addressNumber && errors.addressNumber}>
              <TextField
                name="addressNumber"
                fullWidth
                variant="outlined"
                color="primary"
                value={values.addressNumber}
                error={touched.addressNumber && !!errors.addressNumber}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputWithLabel>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={10} style={{ paddingRight: 10 }}>
            <InputWithLabel label="city" error={touched.city && errors.city}>
              <TextField
                name="city"
                fullWidth
                variant="outlined"
                color="primary"
                value={values.city}
                error={touched.city && !!errors.city}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputWithLabel>
          </Grid>
          <Grid item xs={2}>
            <InputWithLabel label="zip-code" error={touched.zipCode && errors.zipCode}>
              <TextField
                name="zipCode"
                fullWidth
                variant="outlined"
                color="primary"
                value={values.zipCode}
                error={touched.zipCode && !!errors.zipCode}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputWithLabel>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} style={{ paddingRight: 10 }}>
            <InputWithLabel label="phone" error={touched.phone && errors.phone}>
              <TextField
                name="phone"
                fullWidth
                variant="outlined"
                color="primary"
                value={values.phone}
                error={touched.phone && !!errors.phone}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputWithLabel>
          </Grid>
          <Grid item xs={6}>
            <InputWithLabel label="email" error={touched.email && errors.email}>
              <TextField
                name="email"
                fullWidth
                variant="outlined"
                color="primary"
                value={values.email}
                error={touched.email && !!errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputWithLabel>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} style={{ paddingRight: 10 }}>
            <InputWithLabel label="country" error={touched.country && errors.country}>
              <TextField
                name="country"
                fullWidth
                variant="outlined"
                color="primary"
                value={values.country}
                error={touched.country && !!errors.country}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputWithLabel>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} style={{ paddingRight: 10 }}>
            <InputWithLabel label="latitude" error={touched.latitude && errors.latitude}>
              <TextField
                name="latitude"
                fullWidth
                variant="outlined"
                color="primary"
                value={values.latitude}
                error={touched.latitude && !!errors.latitude}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputWithLabel>
          </Grid>
          <Grid item xs={6}>
            <InputWithLabel label="longitude" error={touched.longitude && errors.longitude}>
              <TextField
                name="longitude"
                fullWidth
                variant="outlined"
                color="primary"
                value={values.longitude}
                error={touched.longitude && !!errors.longitude}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputWithLabel>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} style={{ paddingRight: 10 }}>
            <InputWithLabel label="notification-email" error={touched.notificationEmail && errors.notificationEmail}>
              <TextField
                name="notificationEmail"
                fullWidth
                variant="outlined"
                color="primary"
                value={values.notificationEmail}
                error={touched.notificationEmail && !!errors.notificationEmail}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputWithLabel>
          </Grid>
          <Grid item xs={6}>
            <InputWithLabel label="ip-fiscal-printer" error={touched.fiscalPrinterIp && errors.fiscalPrinterIp}>
              <TextField
                name="fiscalPrinterIp"
                fullWidth
                variant="outlined"
                color="primary"
                value={values.fiscalPrinterIp}
                error={touched.fiscalPrinterIp && !!errors.fiscalPrinterIp}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </InputWithLabel>
          </Grid>
        </Grid>
        <ButtonContainer>
          <Button
            variant="contained"
            style={{
              margin: '30px 10px 20px 0px',
              minWidth: 125,
              alignSelf: 'flex-end',
              fontSize: 18,
              fontWeight: 500,
              backgroundColor: redButton,
              color: 'white'
            }}
            onClick={() => {
              handleReset();
              closeModal();
            }}
          >
            <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
          </Button>
          <Button
            variant="contained"
            style={{
              margin: '30px 10px 20px 0px',
              backgroundColor: greenButton,
              minWidth: 125,
              alignSelf: 'flex-end',
              fontSize: 18,
              fontWeight: 500,
              color: 'white'
            }}
            onClick={handleSubmit}
          >
            <TranslateTextComponent uppercase>save</TranslateTextComponent>
          </Button>
        </ButtonContainer>
      </Container>
    </CustomModal>
  );
};

export default OutletModal;
