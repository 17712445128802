import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Radio, RadioGroup } from '@material-ui/core';
import styles from '../../../../styles/billsManagement/wideCard';

const WideCardContainer = ({ withCheckBox, checked, withoutPaddingDetails, summaryComponent, detailsComponent, onCheckBoxChange, onClick }) => {
  const [expanded, setExpanded] = useState(false);
  const classes = styles({ expanded, withoutPaddingDetails });

  return (
    <Box className={classes.container} onClick={onClick}>
      {withCheckBox && (
        <RadioGroup style={{ justifyContent: 'center' }} aria-label="bill" name="bill">
          <Radio className={classes.radio} size="small" checked={checked} onChange={onCheckBoxChange} />
        </RadioGroup>
      )}
      <Accordion className={classes.expansionContainer} expanded={expanded} onChange={() => setExpanded((prev) => !prev)}>
        <AccordionSummary className={classes.expansionSummaryContainer}>{summaryComponent}</AccordionSummary>
        <AccordionDetails className={classes.expansionDetailsContainer}>{detailsComponent}</AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default WideCardContainer;
