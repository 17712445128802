import React, { forwardRef, useContext } from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import { LanguageContext } from '../../contexts/LanguageContext';
import { capitalizeFirstLetter, translate } from '../../translator';

const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

const ReservationHeader = styled.h1`
  text-transform: uppercase;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LabelText = styled.span`
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
`;

const ValueText = styled.span`
  font-size: 1.2rem;
`;

const Divider = styled.hr`
  width: 100%;
  margin: 20px 0px;
`;

const PrintableReservation = forwardRef(({ reservation }, ref) => {
  const { language } = useContext(LanguageContext);

  const getInfos = (items) => {
    return items.map((item, index) =>
      item.value ? (
        <React.Fragment key={index}>
          <InfoContainer>
            <LabelText style={{ marginRight: 5 }}>{capitalizeFirstLetter(translate(item.label, language))}:</LabelText>
            <ValueText>{item?.label.includes('date') ? moment(item.value).format('DD-MM-YYYY') : item.value}</ValueText>
          </InfoContainer>
          {item.divider ? <Divider /> : null}
        </React.Fragment>
      ) : null
    );
  };

  return (
    <Container ref={ref}>
      <ReservationHeader>reservation #{reservation?.uuid}</ReservationHeader>
      {getInfos([
        { label: 'first-name', value: reservation?.bookedBy?.firstname },
        { label: 'last-name', value: reservation?.bookedBy?.lastname },
        { label: 'date-of-birth', value: reservation?.bookedBy?.dob },
        { label: 'birthplace', value: reservation?.bookedBy?.birthplace },
        { label: 'telephone', value: reservation?.bookedBy?.phone },
        { label: 'mobile-phone', value: reservation?.bookedBy?.mobilePhone },
        { label: 'email', value: reservation?.bookedBy?.email, divider: true },
        { label: 'reservation-date', value: reservation?.date },
        { label: 'reservation-time', value: reservation?.reservationTime },
        { label: 'reservation-status', value: reservation?.status },
        { label: 'room-number', value: reservation?.guestRoom },
        { label: 'n-pax', value: reservation?.nPax },
        { label: 'notes', value: reservation?.notes }
      ])}
    </Container>
  );
});

export default PrintableReservation;
