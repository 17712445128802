import React, { useState, useContext } from 'react';
import { Box, ClickAwayListener, Typography, Grid, Button } from '@material-ui/core/';
import typy from 'typy';
import { GET_GUESTS } from '../../../graphql/guests/queries';
import { GET_FB_OUTLET } from '../../../graphql/outlets/queries';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { SearchBar } from '../../../common-fe';
import PhysicalCategoriesBoxGender from '../../shared/PhysicalCategoriesBoxGender';
import SearchTable from './SearchTable';
import { TranslateTextComponent, capitalizeFirstLetter, translate } from '../../../translator';
import assignChairStyle from '../../../styles/table/tableDetail/assignChair';
import { primaryColor } from '../../../styles/theme';
import useCustomQuery from '../../../hooks/useCustomQuery';
import { AuthContext } from '../../../contexts/AuthContext';

const buttonSearchByName = <TranslateTextComponent capitalize>search-by-name</TranslateTextComponent>;
const buttonExternal = <TranslateTextComponent capitalize>external</TranslateTextComponent>;

const initialFilters = {
  pageSize: 5,
  page: 1,
  keyword: ''
};

const RoomBox = ({ onClickListener, reservationData, roomNbr, handleAssignChair, setIsFreeChair, freeChair }) => {
  const classes = assignChairStyle();

  const [queryFilters, setQueryFilters] = useState(initialFilters);
  const [clickedExternalGuest, setClickedExternalGuest] = useState(false);
  const [openModalSearch, setOpenModalSearch] = useState(false);
  const { appOutletUUID } = useContext(AuthContext);

  const guestsResponse = useCustomQuery(GET_GUESTS, {
    variables: {
      page: queryFilters.page,
      pageSize: queryFilters.pageSize,
      fbOutletUUID: appOutletUUID,
      keyword: queryFilters.keyword
    },
    fetchPolicy: 'network-only'
  });

  const outletResponse = useCustomQuery(GET_FB_OUTLET, {
    variables: {
      fbOutletUUID: appOutletUUID,
      fetchPolicy: 'network-only'
    }
  });

  const updateQueryFilters = (key, value) => {
    setQueryFilters({
      ...queryFilters,
      [key]: value || null
    });
  };

  const setTablePages = (page, pageSize) => {
    setQueryFilters({
      ...queryFilters,
      page,
      pageSize
    });
  };

  const reservation = typy(reservationData, 'bookedBy').safeObject;
  const guestsData = typy(guestsResponse, 'data.getGuests.data').safeObject;
  const totalCount = typy(guestsResponse, 'data.getGuests.totalCount').safeObject;
  const outletPhysicalCategory = typy(outletResponse, 'data.getFbOutlet.guestPhysicalCategories').safeObject;

  const languageContext = useContext(LanguageContext);

  const openModalOnSearch = () => {
    setOpenModalSearch(true);
  };

  const handleClose = () => {
    setOpenModalSearch(false);
  };

  const hanldeClickExternalGuest = () => {
    if (clickedExternalGuest === true) {
      setClickedExternalGuest(false);
    } else {
      setClickedExternalGuest(true);
    }
  };

  const handleSearchChange = (e) => {
    console.log('event on change ', e);
  };

  const handleClickListener = () => {
    onClickListener();
  };

  return (
    <Box
      style={{
        marginBottom: 10,
        boxShadow: '0 8px 21px rgba(0, 0, 0, 0.27)'
      }}
    >
      <ClickAwayListener touchEvent={openModalSearch ? false : 'onTouchEnd'} onClickAway={handleClickListener}>
        <Grid container style={{ backgroundColor: 'white' }} justify="center">
          <Grid item xs className={classes.gridList} style={{ minHeight: 300 }}>
            <Typography className={classes.header}>
              {roomNbr}
              {reservation && reservation.guestRoom}
            </Typography>
            <Grid
              item
              xs
              style={{ marginBottom: 7, borderBottomStyle: 'solid', borderColor: '#B4DCED', height: 135, overflow: 'auto' }}
              className={classes.tableGuestContainer}
            >
              {guestsData &&
                guestsData.map((guestInRoom, index) => {
                  return index % 2 ? (
                    <Typography className={classes.detailOdd} key={index} onClick={() => handleAssignChair(guestInRoom.uuid, guestInRoom.gender)}>
                      {guestInRoom.firstname}
                      {'  '} {guestInRoom.lastname}
                    </Typography>
                  ) : (
                    <Typography
                      className={classes.detailPair}
                      key={guestInRoom.id}
                      onClick={() => handleAssignChair(guestInRoom.uuid, guestInRoom.gender)}
                    >
                      {guestInRoom.firstname}
                      {'  '} {guestInRoom.lastname}
                    </Typography>
                  );
                })}
            </Grid>
            <Grid item xs style={{ marginTop: 10, paddingLeft: 8, paddingRight: 8 }}>
              <SearchBar
                style={{ height: 25, padding: 0, fontSize: 8 }}
                className={classes.searchBar}
                placeholder={capitalizeFirstLetter(translate('search-by-room', languageContext.language))}
                iconStyle={{ height: 25, padding: 2 }}
                inputStyle={{ fontSize: 12, textIndent: 8 }}
                onChange={(event) => handleSearchChange(event.target.value)}
              />
            </Grid>
            <Grid item xs style={{ marginTop: 5, marginLeft: 8, paddingRight: 8 }}>
              <Button
                className={classes.buttons}
                onClick={() => {
                  setIsFreeChair(true);
                  freeChair();
                }}
              >
                <TranslateTextComponent uppercase>free-chair</TranslateTextComponent>
              </Button>

              <Button className={classes.buttons} onClick={() => openModalOnSearch()}>
                {buttonSearchByName}
              </Button>
              {
                // TODO: delete on guestGuestByRoomNumber is available!
                reservation && !reservation.guestRoom ? (
                  <Button
                    className={classes.buttons}
                    style={{
                      backgroundColor: clickedExternalGuest ? 'white' : primaryColor,
                      color: clickedExternalGuest ? primaryColor : 'white',
                      border: clickedExternalGuest ? `1px solid ${primaryColor}` : 'none'
                    }}
                    onClick={hanldeClickExternalGuest}
                  >
                    {buttonExternal}
                  </Button>
                ) : null
              }
            </Grid>
            <Box key="physicalCatBoxGender">
              <PhysicalCategoriesBoxGender
                isClickable
                physicalCategories={outletPhysicalCategory || []}
                handleClick={(category, gender) => handleAssignChair(null, gender, category.uuid)}
              />
            </Box>
          </Grid>
        </Grid>
      </ClickAwayListener>
      {openModalSearch && (
        <SearchTable
          guestsData={guestsData}
          openModalSearch={openModalSearch}
          onClose={handleClose}
          updateQueryFilters={updateQueryFilters}
          queryFilters={queryFilters}
          handleAssignChair={handleAssignChair}
          setTablePages={setTablePages}
          totalCount={totalCount}
        />
      )}
    </Box>
  );
};

export default RoomBox;
