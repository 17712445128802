/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import { FB_OUTLETS_FRAGMENT } from '../fragments';

export const EDIT_FB_OUTLET = gql`
  mutation editFbOutlet($fbOutletUUID: String!, $fbOutletData: FbOutletEditInput!) {
    editFbOutlet(fbOutletUUID: $fbOutletUUID, fbOutletData: $fbOutletData) {
      ...fbOutletsFragment
    }
  }
  ${FB_OUTLETS_FRAGMENT}
`;

export const EDIT_FB_OUTLET_RESERVATION_SETTINGS = gql`
  mutation editFbOutletReservationSettings($fbOutletUUID: String!, $fbReservationSettings: FbOutletReservationSettingsInput!) {
    editFbOutletReservationSettings(fbOutletUUID: $fbOutletUUID, fbReservationSettings: $fbReservationSettings) {
      id
      name
      description
      address
      addressNumber
      city
      zipCode
      country
      phoneNumber
      maxPax
      maxDaysBeforeReservation
      isOnlineReservationAccepted
      minPaxForReservation
      maxPaxForReservation
      isReservationEditableByGuest
      avoidEditingOrDeleteBeforeMinutes
    }
  }
`;

export const ADD_FB_OUTLET = gql`
  mutation addFbOutlet($fbOutletData: NewFbOutletDto!) {
    addFbOutlet(fbOutletData: $fbOutletData) {
      ...fbOutletsFragment
    }
  }
  ${FB_OUTLETS_FRAGMENT}
`;
