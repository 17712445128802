import { makeStyles } from '@material-ui/core/styles';
import FB_ORDER_STATUSES from '../../../constants/order/orderStatuses';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 5,
    '& > div': {
      margin: '0 2px'
    }
  },
  statusIcon: (props) => ({
    width: 15,
    height: 15,
    fill: props && props.status && FB_ORDER_STATUSES[props.status].color
  })
};

export default makeStyles(styles);
