import { makeStyles } from '@material-ui/core/styles';

const styles = {
  container: ({ active }) => ({
    margin: '0 5px',
    backgroundColor: active ? '#00B7FF' : '#EDFAFF',
    fontSize: 16,
    color: active ? 'white' : '#3399CC',
    textTransform: 'capitalize',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    minWidth: 0,
    padding: '0 30px',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: 'rgb(0, 128, 178)'
    }
  })
};

export default makeStyles(styles);
