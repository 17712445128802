export const decodeFpStatus = (fbStatus) => {
  let printer = '';
  let ej = '';
  let cashDrawer = '';
  let receipt = '';
  let mode = '';

  switch (fbStatus.substring(0, 1)) {
    case '0':
      printer = 'OK';
      break;
    case '2':
      printer = 'Carta in esaurimento';
      break;
    case '3':
      printer = 'Offline (fine carta o coperchio aperto)';
      break;
    default:
      printer = 'Risposta errata';
  }

  switch (fbStatus.substring(1, 2)) {
    case '0':
      ej = 'OK';
      break;
    case '1':
      ej = 'Prossimo ad Esaurimento';
      break;
    case '2':
      ej = 'Da formattare';
      break;
    case '3':
      ej = 'Precedente';
      break;
    case '4':
      ej = 'Di altro misuratore';
      break;
    case '5':
      ej = 'Esaurito';
      break;
    default:
      ej = 'Risposta errata';
  }

  switch (fbStatus.substring(2, 3)) {
    case '0':
      cashDrawer = 'Aperto';
      break;
    case '1':
      cashDrawer = 'Chiuso';
      break;
    default:
      cashDrawer = 'Risposta errata';
  }

  switch (fbStatus.substring(3, 4)) {
    case '0':
      receipt = 'Fiscale aperto';
      break;
    case '1':
      receipt = 'Fiscale/Non fiscale chiuso';
      break;
    case '2':
      receipt = 'Non fiscale aperto';
      break;
    case '3':
      receipt = 'Pagamento in corso';
      break;
    case '4':
      receipt = 'Errore ultimo comando ESC/POS con Fiscale/Non fiscale chiuso';
      break;
    case '5':
      receipt = 'Scontrino in negativo';
      break;
    case '6':
      receipt = 'Errore ultimo comando ESC/POS con Non fiscale aperto';
      break;
    case '7':
      receipt = 'Attesa chiusura scontrino modalit&agrave; JAVAPOS';
      break;
    case '8':
      receipt = 'Documento fiscale aperto';
      break;
    case 'A':
      receipt = 'Titolo aperto';
      break;
    case '2':
      receipt = 'Titolo chiuso';
      break;
    default:
      receipt = 'Risposta errata';
  }

  switch (fbStatus.substring(4, 5)) {
    case '0':
      mode = 'Stato registrazione';
      break;
    case '1':
      mode = 'Stato X';
      break;
    case '2':
      mode = 'Stato Z';
      break;
    case '3':
      mode = 'Stato Set';
      break;
    default:
      mode = 'Risposta errata';
  }
  return { printer, ej, cashDrawer, receipt, mode };
};

export const test = 'test';
