import React from 'react';
import Layout from '../components/shared/Layout';
import Reservations from '../components/reservations/Reservations';

const ReservationsPage = ({ history }) => {
  return (
    <Layout history={history} section="reservations">
      <Reservations history={history} />
    </Layout>
  );
};

export default ReservationsPage;
