import React, { useContext } from 'react';
import { Box, Button } from '@material-ui/core/';
import { CONTENT_RESERVATION_FORM, CONTENT_EXTERNAL_GUEST_LIST } from '../../constants/saveReservation/internalRoutes';
import { ReservationContext } from '../../contexts/ReservationContext';
import styles from '../../styles/saveReservation/newReservationStyle';
import { TranslateTextComponent } from '../../translator';

const InternalCustomer = ({ handleSetInternalRoute }) => {
  const reservationContext = useContext(ReservationContext);
  const classes = styles();

  return (
    <Box>
      <Box className={classes.boxButtons}>
        <Button
          variant="contained"
          color="primary"
          className={classes.savebutton}
          style={{ marginTop: 0 }}
          onClick={() => {
            reservationContext.restoreReservationsData();
            reservationContext.restoreNewGuestData(handleSetInternalRoute(CONTENT_RESERVATION_FORM));
          }}
        >
          <TranslateTextComponent>new-client</TranslateTextComponent>
        </Button>
      </Box>
      <Box className={classes.boxButtons}>
        <Button
          variant="contained"
          color="primary"
          className={classes.savebutton}
          style={{ marginTop: 15 }}
          onClick={() => reservationContext.restoreNewGuestData(handleSetInternalRoute(CONTENT_EXTERNAL_GUEST_LIST))}
        >
          <TranslateTextComponent>registry</TranslateTextComponent>
        </Button>
      </Box>
    </Box>
  );
};

export default InternalCustomer;
