import React from 'react';
import { Box } from '@material-ui/core';
import groupBy from 'lodash/groupBy';
import typy from 'typy';
import { ReactComponent as Chair } from '../../../assets/images/cash/chair.svg';
import styles from '../../../styles/cash/splitChairsStyle';
import { CustomButton } from '../../../common-fe/src';
import { TranslateTextComponent } from '../../../translator';

const SplitChairs = ({ chairs, selectedChairs, showSplitChairs, onSelectChair, onSelectChairSaveButton, onSelectChairCancelButton }) => {
  const classes = styles();

  let groupedChairs = typy(groupBy(chairs, 'id')).safeObject;
  groupedChairs = typy(
    Object.keys(groupedChairs).map((item) => {
      if (item.length > 0 && item[0] !== null) return groupedChairs[item];
    })
  ).safeArray;

  groupedChairs = groupedChairs
    .map((gc) => gc[0] && { number: gc[0].number, uuid: gc[0].uuid })
    .filter((fgc) => fgc !== null)
    .sort((prev, next) => prev.number - next.number);

  return (
    showSplitChairs && (
      <Box className={classes.container}>
        <Box className={classes.chairsContainer}>
          {groupedChairs.map((chair) => (
            <Box
              key={chair.uuid}
              className={[classes.groupedChairsContainer, selectedChairs.includes(chair.uuid) ? classes.selectedChair : ''].join(' ')}
              onClick={() => onSelectChair(chair.uuid)}
            >
              <Chair />
              <span className={classes.chairNumber}>{chair.number}</span>
            </Box>
          ))}
        </Box>
        <Box className={classes.buttonsContainer}>
          <CustomButton
            style={{ maxHeight: 30, maxWidth: 110, backgroundColor: '#CB2D24' }}
            onClick={(e) => {
              e.stopPropagation();
              onSelectChairCancelButton();
            }}
          >
            <TranslateTextComponent>cancel</TranslateTextComponent>
          </CustomButton>
          <CustomButton
            style={{ maxHeight: 30, maxWidth: 110, backgroundColor: '#449D44' }}
            onClick={(e) => {
              e.stopPropagation();
              onSelectChairSaveButton();
            }}
          >
            <TranslateTextComponent>save</TranslateTextComponent>
          </CustomButton>
        </Box>
      </Box>
    )
  );
};

export default SplitChairs;
