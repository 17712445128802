import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from '../../theme';

const styles = {
  wrapper: {
    border: `1px solid ${primaryColor}`,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    color: '#55595C',
    margin: 'auto',
    maxWidth: 969,
    width: '100%',
    marginBottom: 20
  },
  wrapperHeader: {
    backgroundColor: primaryColor,
    paddingLeft: 15,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    fontSize: 45,
    fontWeight: 'bold',
    color: 'white',
    textTransform: 'uppercase'
  },
  container: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%'
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    marginTop: 15
  },
  dataContainer: {
    display: 'grid',
    width: '100%',
    minHeight: 80,
    gridTemplateAreas: '"id-area data-area"',
    gridTemplateColumns: 'auto 1fr',
    marginTop: 15,
    borderTop: `1px solid ${primaryColor}`,
    borderBottom: `1px solid ${primaryColor}`,
    overflow: 'hidden'
  },
  dataRow: {
    display: 'flex',
    width: '100%',
    minHeight: 80,
    borderBottom: `1px solid ${primaryColor}`
  },
  itemContainer: {
    margin: '10px 0',
    border: `1px solid ${primaryColor}`,
    borderRadius: 10,
    overflow: 'hidden'
  },
  itemTitleContainer: {
    margin: '20px 10px',
    display: 'grid',
    gridTemplateAreas: '"id quantity dish price chair client"',
    gridColumnGap: 20,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  itemTitle: {
    color: '#55595C',
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    paddingLeft: 10
  },
  idHeader: {
    width: '100%',
    maxWidth: 56
  },
  quantityHeader: {
    width: '100%',
    maxWidth: 56
  },
  dishHeader: {
    width: '100%',
    maxWidth: 517
  },
  priceHeader: {
    width: '100%',
    maxWidth: 111
  },
  chairHeader: {
    width: '100%',
    maxWidth: 80
  },
  clientHeader: {
    width: '100%',
    maxWidth: 176
  },
  idArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: primaryColor,
    fontSize: 45,
    color: 'white',
    textAlign: 'center',
    width: '100%',
    maxWidth: 56
  },
  quantityArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: `1px solid ${primaryColor}`,
    width: '100%',
    maxWidth: 56
  },
  dishArea: {
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'column',
    borderRight: `1px solid ${primaryColor}`,
    width: '100%',
    maxWidth: 485
  },
  priceArea: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: `1px solid ${primaryColor}`,
    width: '100%',
    maxWidth: 111
  },
  chairArea: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: `1px solid ${primaryColor}`,
    width: '100%',
    maxWidth: 80, // 57
    overflow: 'auto'
  },
  clientArea: { display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 10, width: '100%', maxWidth: 176 }
};

export default makeStyles(styles);
