/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { Grid, IconButton } from '@material-ui/core/';
import EditIcon from '@material-ui/icons/Edit';
import { TranslateTextComponent, capitalizeFirstLetter } from '../../../translator';
import { greenButton, redButton } from '../../../common-fe/src';
import { textTranslateCapitalize } from '../../../utils/functions';

const textTranslate = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

const getServiceColor = (enabled) => {
  if (enabled) return greenButton;
  return redButton;
};

const outletColumns = ({ handleEnableEditing }) => {
  return [
    {
      id: 'name',
      label: textTranslate('name'),
      isSortable: false,
      align: 'left',
      render: (item) => item?.name ?? ''
    },
    {
      id: 'address',
      label: textTranslate('address'),
      isSortable: false,
      align: 'left',
      render: (item) => item?.address && `${capitalizeFirstLetter(item?.address ?? '')}, ${item?.addressNumber ?? ''}`
    },
    {
      id: 'city',
      disablePadding: false,
      label: textTranslate('city'),
      isSortable: false,
      align: 'left',
      render: (item) => item?.city ?? ''
    },
    {
      id: 'email',
      disablePadding: false,
      label: textTranslate('email'),
      isSortable: false,
      align: 'left',
      render: (item) => item?.email ?? ''
    },
    {
      id: 'phoneNumber',
      disablePadding: false,
      label: textTranslate('phoneNumber'),
      isSortable: false,
      align: 'left',
      render: (item) => item.phoneNumber ?? ''
    },
    {
      id: 'activeServices',
      disablePadding: false,
      label: textTranslate('outlet-active-services'),
      isSortable: false,
      align: 'left',
      render: (item) => (
        <Grid container>
          <Grid item xs={12} style={{ fontWeight: 'bold', color: getServiceColor(item && item.isOnlineReservationAccepted) }}>
            {textTranslateCapitalize('on-line-accepted')}
          </Grid>
          <Grid item xs={12} style={{ fontWeight: 'bold', color: getServiceColor(item && item.isDeliveryEnabled) }}>
            {textTranslateCapitalize('delivery-enabled')}
          </Grid>
          <Grid item xs={12} style={{ fontWeight: 'bold', color: getServiceColor(item && item.isTakeAwayEnabled) }}>
            {textTranslateCapitalize('takeaway-enabled')}
          </Grid>
          <Grid item xs={12} style={{ fontWeight: 'bold', color: getServiceColor(item && item.isSmartServiceEnabled) }}>
            {textTranslateCapitalize('moh-service-enabled')}
          </Grid>
        </Grid>
      )
    },
    {
      id: 'edit',
      disablePadding: false,
      label: textTranslate('edit'),
      isSortable: false,
      align: 'left',
      render: (item) => (
        <IconButton className="buttonCircle" disabled={!!item.arrivalTime} onClick={() => handleEnableEditing(item?.uuid)}>
          <EditIcon />
        </IconButton>
      )
    }
    // {
    //   id: 'delete',
    //   disablePadding: false,
    //   label: textTranslate('delete'),
    //   isSortable: false,
    //   align: 'left',
    //   render: () => <BinIcon fill="gray" style={{ cursor: 'pointer' }} />
    // }
  ];
};

export default outletColumns;
