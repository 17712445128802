const checkinsCSVFields = [
  { label: 'FIRST_NAME' },
  { label: 'LAST_NAME' },
  { label: 'EMAIL' },
  { label: 'CHECK-IN DATE' },
  { label: 'CHECK-OUT DATE' },
  { label: 'ROOM' },
  { label: 'COUNTRY_CODE' },
  { label: 'PHONE' },
  { label: 'STATUS' },
  { label: 'TRANS_INFO' },
  { label: 'CHECKOUT_FEED' },
  { label: 'HOUSEWIFE' },
  { label: 'RES_LOCAL' },
  { label: 'NOTES' },
  { label: 'TRIP_REASON' },
  { label: 'COMPLAINTS' },
  { label: 'CHECKIN_MOOD' },
  { label: 'CHECKOUT_MOOD' }
];

export default checkinsCSVFields;
