import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ModalContext } from '../contexts/ModalContext';
import { AuthContext } from '../contexts/AuthContext';
import { TranslateTextComponent, translate } from '../translator';
import { LanguageContext } from '../contexts/LanguageContext';

const useCustomQuery = (query, params) => {
  const { isLoggedIn } = useContext(AuthContext);
  const modalContext = useContext(ModalContext);
  const { language } = useContext(LanguageContext);
  // pass params.requireAuth = true for require authentication
  let skip = params?.skip ?? false;
  if (params && params.requireAuth && !skip) {
    skip = !isLoggedIn();
  }

  const queryResponse = useQuery(query, {
    fetchPolicy: 'network-only',
    ...params,
    skip,
    onError: (err) => {
      const textError = JSON.stringify(err);
      const queryName = query.definitions[0]?.name?.value ?? '';
      console.log(`Error ${queryName}:`, JSON.parse(textError));
      if (err?.graphQLErrors?.[0]?.message?.statusCode === 403) {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: `${queryName}: ${translate('no-privileges', language)}`
        });
      } else {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: `${queryName}: ${err}`
        });
      }
    }
  });
  return queryResponse;
};

export default useCustomQuery;
