import React from 'react';
import { Button, Grid } from '@material-ui/core';
import styles from '../../styles/dashboard/dashboardButtonStyle';

const DashboardButton = ({ button }) => {
  const classes = styles();
  return (
    <Button variant="contained" color="primary" className={classes.dashboardButton}>
      <Grid container direction="column" alignItems="center" style={{ width: '15%' }}>
        {button.img}
      </Grid>
      <Grid container direction="column" alignItems="flex-start" style={{ width: '85%', paddingLeft: 15 }}>
        <Grid className={classes.buttonTitle}>{button.text}</Grid>
        <Grid className={classes.buttonDescription}>{button.description}</Grid>
      </Grid>
    </Button>
  );
};

export default DashboardButton;
