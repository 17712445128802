import React from 'react';
import { Box } from '@material-ui/core';
import typy from 'typy';
import moment from 'moment';
import styles from '../../../../../styles/billsManagement/wideCard';
import { getIcon, getSVGIconURI } from '../../../../../utils/billsManagement';
import SummaryRowDetails from './SummaryRowDetails';
import { TranslateTextComponent } from '../../../../../translator';

const SummaryWithoutBorder = ({ data, summaryType, leftComponent, rightComponent, withBackgroundImage }) => {
  const classes = styles({ backgroundImage: withBackgroundImage ? getSVGIconURI(typy(data, 'fbPaymentMethod.code').safeString) : 'none' });

  return (
    <Box className={classes.summaryWithoutBorderContainer}>
      {withBackgroundImage && <Box className={classes.backgroundIcon}>{getIcon(typy(data, 'fbPaymentMethod.code').safeString)}</Box>}
      <Box className={classes.summaryWithoutBorderRow}>
        {leftComponent || (
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            {getIcon(typy(data, 'fbPaymentMethod.code').safeString)}
            <Box style={{ marginLeft: 10 }}>
              <span>{moment(data && data.datetime).format('DD/MM/YYYY') || ''}</span>
              {data && data.status === 'cancelled' && (
                <b style={{ color: 'red' }}>
                  &nbsp;(<TranslateTextComponent>deleted</TranslateTextComponent>)
                </b>
              )}
            </Box>
          </Box>
        )}
        <Box>{rightComponent || <span>€ {typy(data, 'total').safeNumber.toFixed(2).replace('.', ',')}</span>}</Box>
      </Box>
      <SummaryRowDetails data={data} type={summaryType} />
    </Box>
  );
};

export default SummaryWithoutBorder;
