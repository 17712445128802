import React from 'react';
import { Box } from '@material-ui/core';
import { CommonTable, secondaryColor } from '../../../common-fe';
import { TranslateTextComponent } from '../../../translator';
import OrderDetails from './OrderDetails';
import getRoomServiceColumns from '../../../constants/roomService/roomServiceColumns';

const RoomService = () => {
  const columns = getRoomServiceColumns();
  const items = [
    {
      roomNumber: 1,
      name: 'ciccio',
      orderTime: '12:00',
      requestTime: '11:00',
      state: 'active',
      notes: 'lasdsadsa'
    }
  ];
  return (
    <Box style={{ paddingLeft: 40 }}>
      <CommonTable
        size="small"
        items={items}
        columns={columns}
        tableContainerStyle={{ backgroundColor: secondaryColor }}
        renderDetailsComponent={[
          {
            columnLabel: <TranslateTextComponent capitalize>details</TranslateTextComponent>,
            buttonLabel: <TranslateTextComponent>details</TranslateTextComponent>,
            renderFunction: (row) => <OrderDetails item={row} />
          }
        ]}
      />
    </Box>
  );
};
export default RoomService;
