import React from 'react';
import { Box } from '@material-ui/core';
import style from '../../styles/roomEditor/roomHeader/style';

const Shape = ({ shape, selected }) => {
  const classes = style({ size: 30, selected });

  switch (shape) {
    case 'round':
      return <Box className={classes.roundShape} />;
    case 'square':
      return <Box className={classes.squareShape} />;
    case 'rectangle':
      return <Box className={classes.rectangleShape} />;
    default:
      return <Box className={classes.rectangleShape} />;
  }
};

export default Shape;
