import React from 'react';
import ReplayIcon from '@material-ui/icons/Replay';
import { CustomButton } from '../../common-fe/src';
import { TranslateTextComponent } from '../../translator';

const ResetFilterButton = ({ onClick }) => {
  return (
    <CustomButton onClick={onClick} style={{ height: 45, borderRadius: 4, display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
      <ReplayIcon style={{ marginRight: 5 }} />
      <TranslateTextComponent>reset</TranslateTextComponent>
    </CustomButton>
  );
};

export default ResetFilterButton;
