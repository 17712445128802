import React from 'react';
import { Box } from '@material-ui/core';
import moment from 'moment';
import typy from 'typy';
import styles from '../../../../../styles/billsManagement/wideCard';
import { getIcon, getPaymentTranslationFromCode } from '../../../../../utils/billsManagement';
import * as sections from '../../../../../constants/billsManagement/tabs';
import { TranslateTextComponent } from '../../../../../translator';

import { FbPaymentMethodsEnum } from '../../../../../constants/cash/paymentMethods';

const SummaryRowDetails = ({ data, type, rowNumber }) => {
  const classes = styles();

  const getSummaryDetail = () => {
    if (type === sections.BILLS_LIST) {
      return (
        <>
          <Box className={classes.summaryWithoutBorderSecondRowDetail}>
            <span>#ID:</span>
            <span>{data && data.id}</span>
          </Box>
          <Box className={classes.summaryWithoutBorderSecondRowDetail}>
            <span>
              {getIcon('clock')}
              <span>
                <TranslateTextComponent capitalize>time</TranslateTextComponent>:
              </span>
            </span>
            <span>{moment(data && data.datetime).format('HH:mm')}</span>
          </Box>
          <Box className={classes.summaryWithoutBorderSecondRowDetail}>
            <span>
              {getIcon('hand')}
              <span>
                <TranslateTextComponent capitalize>mode</TranslateTextComponent>:
              </span>
            </span>

            {typy(data, 'fbPaymentMethod.code').safeString === FbPaymentMethodsEnum.CREDIT_CARD ? (
              <span>{data.creditCardType}</span>
            ) : (
              <TranslateTextComponent capitalize>
                {getPaymentTranslationFromCode(typy(data, 'fbPaymentMethod.code').safeString)}
              </TranslateTextComponent>
            )}
          </Box>
          <Box className={classes.summaryWithoutBorderSecondRowDetail}>
            <span>
              {getIcon('man')}
              <span>
                <TranslateTextComponent capitalize>operator</TranslateTextComponent>:
              </span>
            </span>
            <span>{typy(data, 'createdBy.firstName').safeString + ' ' + typy(data, 'createdBy.lastName').safeString}</span>
          </Box>
          <Box className={classes.summaryWithoutBorderSecondRowDetail}>
            <span>
              {getIcon('document')}
              <span>
                <TranslateTextComponent capitalize>document-n</TranslateTextComponent>:
              </span>
            </span>
            <span>{typy(data, 'documentNumber').safeString || '-'}</span>
          </Box>
        </>
      );
    } else if (type === sections.BANK_CHECK_CREDIT_TRANSFERS) {
      return (
        <>
          <Box className={classes.summaryWithoutBorderSecondRowDetail}>
            <span>
              {getIcon('clock')}
              <span>
                <TranslateTextComponent capitalize>time</TranslateTextComponent>:
              </span>
            </span>
            <span>{moment(data && data.datetime).format('HH:mm')}</span>
          </Box>
          <Box className={classes.summaryWithoutBorderSecondRowDetail}>
            <span>
              {getIcon('hand')}
              <span>
                <TranslateTextComponent capitalize>mode</TranslateTextComponent>:
              </span>
            </span>
            <TranslateTextComponent capitalize>{getPaymentTranslationFromCode(typy(data, 'fbPaymentMethod.code').safeString)}</TranslateTextComponent>
          </Box>
          <Box className={classes.summaryWithoutBorderSecondRowDetail}>
            <span>
              {getIcon('man')}
              <span>
                <TranslateTextComponent capitalize>operator</TranslateTextComponent>:
              </span>
            </span>
            <span>{typy(data, 'createdBy.firstName').safeString + ' ' + typy(data, 'createdBy.lastName').safeString}</span>
          </Box>
          <Box className={classes.summaryWithoutBorderSecondRowDetail}>
            <span>
              {getIcon('clients')}
              <span>
                <TranslateTextComponent capitalize>client-name</TranslateTextComponent>:
              </span>
            </span>
            <span>{(data && data.documentNumber) || '-'}</span>
          </Box>
          <Box className={classes.summaryWithoutBorderSecondRowDetail}>
            <span>
              {getIcon('document')}
              <span>
                <TranslateTextComponent capitalize>document-n</TranslateTextComponent>:
              </span>
            </span>
            <span>{(data && data.documentNumber) || '-'}</span>
          </Box>
        </>
      );
    } else if (type === sections.CLOSE_OUT_OF_CASH) {
      if (rowNumber === 1) {
        return (
          <>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('clock')}
                <span>
                  <TranslateTextComponent capitalize>time</TranslateTextComponent>:
                </span>
              </span>
              <span>{moment(data && data.datetime).format('DD/MM/YYYY HH:mm')}</span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('hand')}
                <span>
                  <TranslateTextComponent capitalize>operator</TranslateTextComponent>:
                </span>
              </span>
              <TranslateTextComponent capitalize>{data.createdBy && `${data.createdBy.firstName} ${data.createdBy.lastName}`}</TranslateTextComponent>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('ticket')}
                <span>
                  <TranslateTextComponent capitalize>tickets</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {data && data.totalTicketAmount.toFixed(2)} ({data && data.totalTicketNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('invoice-paper')}
                <span>
                  <TranslateTextComponent capitalize>invoices</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {data && data.totalInvoiceAmount.toFixed(2)} ({data && data.totalInvoiceNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('hotel-blue')}
                <span>
                  <TranslateTextComponent capitalize>room-charge-shortened</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {data && data.totalRoomChargeAmount.toFixed(2)} ({data && data.totalRoomChargeNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('pm-blue')}
                <span>
                  <TranslateTextComponent capitalize>pm</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {data && data.totalPMAmount.toFixed(2)} ({data && data.totalPMNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('client-badge')}
                <span>
                  <TranslateTextComponent capitalize>client-type</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {data && data.totalCustomerTypeAmount.toFixed(2)} ({data && data.totalCustomerTypeNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('document')}
                <span>
                  <TranslateTextComponent capitalize>document-n</TranslateTextComponent>:
                </span>
              </span>
              <span>{(data && data.documentNumber) || '-'}</span>
            </Box>
          </>
        );
      } else {
        return (
          <>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('cash-blue')}
                <span>
                  <TranslateTextComponent capitalize>cash-payment</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {data && data.totalCashAmount.toFixed(2)} ({data && data.totalCashNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('bank-check')}
                <span>
                  <TranslateTextComponent capitalize>payment-bank-check</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {data && data.totalBankCheckAmount.toFixed(2)} ({data && data.totalBankCheckNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('bank-transfer')}
                <span>
                  <TranslateTextComponent capitalize>bank-transfer</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {data && data.totalBankTransferAmount.toFixed(2)} ({data && data.totalBankTransferNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('mastercard')}
                <span>
                  <TranslateTextComponent capitalize>mastercard</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {(data.totalCardMastercardAmount || 0).toFixed(2)} ({data && data.totalCardMastercardNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('mastercard')}
                <span>
                  <TranslateTextComponent capitalize>pagobancomat</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {(data.totalCardPagobancomatAmount || 0).toFixed(2)} ({data && data.totalCardPagobancomatNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('maestro')}
                <span>
                  <TranslateTextComponent capitalize>maestro</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {(data.totalCardMaestroAmount || 0).toFixed(2)} ({data && data.totalCardMaestroNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('amex')}
                <span>
                  <TranslateTextComponent capitalize>amex</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {(data.totalCardAmexAmount || 0).toFixed(2)} ({data && data.totalCardAmexAmount})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('visa')}
                <span>
                  <TranslateTextComponent capitalize>visa</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {(data.totalCardVisaAmount || 0).toFixed(2)} ({data && data.totalCardVisaNumber})
              </span>
            </Box>
          </>
        );
      }
    } else if (type === sections.DAILY_CLOSE_OUT) {
      if (rowNumber === 1) {
        return (
          <>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('clock')}
                <span>
                  <TranslateTextComponent capitalize>time</TranslateTextComponent>:
                </span>
              </span>
              <span>{moment(data && data.datetime).format('DD/MM/YYYY HH:mm')}</span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('hand')}
                <span>
                  <TranslateTextComponent capitalize>operator</TranslateTextComponent>:
                </span>
              </span>
              <TranslateTextComponent capitalize>{data.createdBy && `${data.createdBy.firstName} ${data.createdBy.lastName}`}</TranslateTextComponent>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('ticket')}
                <span>
                  <TranslateTextComponent capitalize>tickets</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {data && data.totalTicketAmount.toFixed(2)} ({data && data.totalTicketNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('invoice-paper')}
                <span>
                  <TranslateTextComponent capitalize>invoices</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {data && data.totalInvoiceAmount.toFixed(2)} ({data && data.totalInvoiceNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('hotel-blue')}
                <span>
                  <TranslateTextComponent capitalize>room-charge-shortened</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {data && data.totalRoomChargeAmount.toFixed(2)} ({data && data.totalRoomChargeNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('pm-blue')}
                <span>
                  <TranslateTextComponent capitalize>pm</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {data && data.totalPMAmount.toFixed(2)} ({data && data.totalPMNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('client-badge')}
                <span>
                  <TranslateTextComponent capitalize>client-type</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {data && data.totalCustomerTypeAmount.toFixed(2)} ({data && data.totalCustomerTypeNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('document')}
                <span>
                  <TranslateTextComponent capitalize>document-n</TranslateTextComponent>:
                </span>
              </span>
              <span>{(data && data.documentNumber) || '-'}</span>
            </Box>
          </>
        );
      } else {
        return (
          <>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('cash-blue')}
                <span>
                  <TranslateTextComponent capitalize>cash-payment</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {data && data.totalCashAmount.toFixed(2)} ({data && data.totalCashNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('bank-check')}
                <span>
                  <TranslateTextComponent capitalize>payment-bank-check</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {data && data.totalBankCheckAmount.toFixed(2)} ({data && data.totalBankCheckNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('bank-transfer')}
                <span>
                  <TranslateTextComponent capitalize>bank-transfer</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {data && data.totalBankTransferAmount.toFixed(2)} ({data && data.totalBankTransferNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('mastercard')}
                <span>
                  <TranslateTextComponent capitalize>mastercard</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {(data.totalCardMastercardAmount || 0).toFixed(2)} ({data && data.totalCardMastercardNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('mastercard')}
                <span>
                  <TranslateTextComponent capitalize>pagobancomat</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {(data.totalCardPagobancomatAmount || 0).toFixed(2)} ({data && data.totalCardPagobancomatNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('maestro')}
                <span>
                  <TranslateTextComponent capitalize>maestro</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {(data.totalCardMaestroAmount || 0).toFixed(2)} ({data && data.totalCardMaestroNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('amex')}
                <span>
                  <TranslateTextComponent capitalize>amex</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {(data.totalCardAmexAmount || 0).toFixed(2)} ({data && data.totalCardAmexNumber})
              </span>
            </Box>
            <Box className={classes.summaryWithoutBorderSecondRowDetail}>
              <span>
                {getIcon('visa')}
                <span>
                  <TranslateTextComponent capitalize>visa</TranslateTextComponent>:
                </span>
              </span>
              <span>
                € {(data.totalCardVisaAmount || 0).toFixed(2)} ({data && data.totalCardVisaNumber})
              </span>
            </Box>
          </>
        );
      }
    } else if (type === sections.INVOICES) {
      return (
        <>
          <Box className={classes.summaryWithoutBorderSecondRowDetail}>
            <span>
              {getIcon('clock')}
              <span>
                <TranslateTextComponent capitalize>time</TranslateTextComponent>:
              </span>
            </span>
            <span>{moment(data && data.datetime).format('HH:mm')}</span>
          </Box>
          <Box className={classes.summaryWithoutBorderSecondRowDetail}>
            <span>
              {getIcon('hand')}
              <span>
                <TranslateTextComponent capitalize>mode</TranslateTextComponent>:
              </span>
            </span>
            <TranslateTextComponent capitalize>{getPaymentTranslationFromCode(typy(data, 'fbPaymentMethod.code').safeString)}</TranslateTextComponent>
          </Box>
          <Box className={classes.summaryWithoutBorderSecondRowDetail}>
            <span>
              {getIcon('man')}
              <span>
                <TranslateTextComponent capitalize>operator</TranslateTextComponent>:
              </span>
            </span>
            <span>{typy(data, 'createdBy.firstName').safeString + ' ' + typy(data, 'createdBy.lastName').safeString}</span>
          </Box>
          <Box className={classes.summaryWithoutBorderSecondRowDetail}>
            <span>
              {getIcon('clients')}
              <span>
                <TranslateTextComponent capitalize>client-name</TranslateTextComponent>:
              </span>
            </span>
            <span>{(data && data.documentNumber) || '-'}</span>
          </Box>
          <Box className={classes.summaryWithoutBorderSecondRowDetail}>
            <span>
              {getIcon('vat')}
              <span>
                <TranslateTextComponent capitalize>vat-number-shortened</TranslateTextComponent>:
              </span>
            </span>
            <span>{(data && data.documentNumber) || '-'}</span>
          </Box>
          <Box className={classes.summaryWithoutBorderSecondRowDetail}>
            <span>
              {getIcon('document')}
              <span>
                <TranslateTextComponent capitalize>document-n</TranslateTextComponent>:
              </span>
            </span>
            <span>{(data && data.documentNumber) || '-'}</span>
          </Box>
        </>
      );
    } else if (type === sections.PM) {
      return (
        <>
          <Box className={classes.summaryWithoutBorderSecondRowDetail}>
            <span>
              {getIcon('clients')}
              <span>
                <TranslateTextComponent capitalize>client-name</TranslateTextComponent>:
              </span>
            </span>
            <span>{typy(data, 'guest.firstname').safeString + ' ' + typy(data, 'guest.lastname').safeString}</span>
          </Box>
          <Box className={classes.summaryWithoutBorderSecondRowDetail}>
            <span>
              {getIcon('pm-blue')}
              <span>
                <TranslateTextComponent capitalize>pm-type</TranslateTextComponent>:
              </span>
            </span>
            <TranslateTextComponent capitalize>{data && data.type}</TranslateTextComponent>
          </Box>
          <Box className={classes.summaryWithoutBorderSecondRowDetail}>
            <span>
              {getIcon('pm-duration')}
              <span>
                <TranslateTextComponent capitalize>pm-duration</TranslateTextComponent>:
              </span>
            </span>
            <span>{data && !data.expirationDate ? <TranslateTextComponent capitalize>undefined</TranslateTextComponent> : data.expirationDate}</span>
          </Box>
          <Box className={classes.summaryWithoutBorderSecondRowDetail}>
            <span>
              {getIcon('open-list')}
              <span>
                <TranslateTextComponent capitalize>operations</TranslateTextComponent>:
              </span>
            </span>
            <span>{(data && data.documentNumber) || '-'}</span>
          </Box>
        </>
      );
    }
    return null;
  };

  return <Box className={classes.summaryWithoutBorderRow}>{getSummaryDetail()}</Box>;
};

export default SummaryRowDetails;
