import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from '../theme';

const styles = {
  fadeContainer: () => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 450,
    minWidth: 400,
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    // Change the color of Chrome scrollBar
    '&::-webkit-scrollbar-thumb': {
      '-webkit-box-shadow': `inset 0 0 10px 10px #e0e0e0`
    },
    // Change the color of Mozilla scrollBar
    scrollbarColor: '#e0e0e0 #C3BEB8',
    paddingTop: 20,
    maxHeight: '90%',
    overflowY: 'auto'
  }),
  iconClose: () => ({
    color: primaryColor,
    height: 30,
    width: 30,
    top: -10,
    position: 'absolute',
    right: -10
  }),
  typographyClientData: () => ({
    fontSize: 13,
    fontWeight: 500,
    color: '#5A5A5A',
    marginLeft: 25,
    fontFamily: 'Roboto'
  }),
  categoryTypography: () => ({
    marginTop: 15,
    color: '#5A5A5A',
    fontSize: 15,
    fontWeight: 600,
    marginLeft: 23
  }),
  dishes: () => ({
    fontSize: 14,
    marginTop: 5,
    color: '#55595C'
  }),
  gridQuantity: () => ({
    marginLeft: 23,
    fontSize: 10,
    color: '#55595C',
    display: 'flex',
    flexDirection: 'column'
  }),
  gridPrice: () => ({
    color: '#55595C',
    fontSize: 14,
    fontWeight: 'Bold ',
    textAlign: 'right',
    marginRight: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 12
  }),
  gridTotal: (props) => ({
    paddingLeft: 23,
    paddingTop: 0,
    paddingBottom: 15,
    backgroundColor: props.backgroundColor
  }),
  gridSubTotal: (props) => ({
    paddingLeft: 23,
    paddingTop: 20,
    backgroundColor: props.backgroundColor
  }),
  gridDiscount: (props) => ({
    paddingLeft: 23,
    backgroundColor: props.backgroundColor
  }),
  bookedByContainer: (props) => ({
    backgroundColor: props.backgroundColor
  }),
  signatureGrid: (props) => ({
    color: props.backgroundColor,
    marginTop: 15,
    fontSize: 15,
    fontStyle: 'italic',
    marginLeft: 23,
    marginRight: 20,
    pointer: 'cursor'
  }),
  canvasSign: (props) => ({
    borderBottom: `2px dashed ${props.backgroundColor}`
  }),
  payButton: (props) => ({
    backgroundColor: props.backgroundColor,
    color: props.color,
    '&.MuiButton-root': {
      '&:hover': {
        background: '#a6a6a6'
      }
    }
  }),
  total: () => ({
    fontSize: 20,
    color: '#55595C',
    fontWeight: 500,
    marginRight: 20
  }),
  foodAndDrinks: () => ({
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'auto',
    miHeight: '55%',
    // Change the color of Chrome scrollBar
    '&::-webkit-scrollbar-thumb': {
      '-webkit-box-shadow': `inset 0 0 10px 10px #e0e0e0`
    },
    // Change the color of Mozzilla scrollBar
    scrollbarColor: '#e0e0e0 #C3BEB8'
  }),
  withAndWithout: () => ({
    fontSize: 10,
    fontStyle: 'italic',
    fontWeight: 'bold',
    paddingRight: 5
  }),
  boxSignature: () => ({
    width: '100%',
    bottom: 2
  }),
  iconDeleteSignature: () => ({
    color: '#C3BEB8',
    position: 'absolute',
    top: 0,
    right: 0
  })
};

export default makeStyles(styles);
