import React from 'react';

export default React.createContext({
  selectedCategoryId: 1,
  view: 'box',
  showAddItemModal: false,
  showEditItemModal: false,
  showAddCategoryModal: false,
  showEditCategoryModal: false
});
