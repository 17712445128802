/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';
import { OUTLET_CATEGORIES_FRAGMENT } from '../../fragments';

export const GET_OUTLET_CATEGORIES = gql`
  query getOutletCategories($orderBy: String, $orderType: String, $page: Int, $pageSize: Int) {
    getOutletCategories(orderBy: $orderBy, orderType: $orderType, page: $page, pageSize: $pageSize) {
      totalCount
      data {
        ...outletCategoriesFragment
      }
    }
  }
  ${OUTLET_CATEGORIES_FRAGMENT}
`;
