import { makeStyles } from '@material-ui/core/styles';
import { primaryColor, invertedPrimary, mainTextColor, borderTheme } from '../theme';

const styles = {
  chairsBox: () => ({
    position: 'absolute',
    width: 280,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: 1,
    boxShadow: '0px 3px 10px #0000004B',
    borderRadius: 4,
    background: '#FFFFFF 0% 0% no-repeat padding-box'
  }),
  chairsRow: () => ({
    borderBottom: borderTheme,
    padding: 15
  }),
  buttonRow: () => ({
    borderBottom: borderTheme,
    padding: 15,
    width: '100%',
    margin: 0
  }),
  notSelectedButton: () => ({
    borderBottom: borderTheme,
    padding: 10
  }),
  chairTypography: () => ({
    color: primaryColor,
    textAlign: 'center'
  }),
  button: (props) => ({
    boxShadow: 'none',
    backgroundColor: props.mainColor,
    textAlign: 'center',
    color: props.defaultTextColor,
    fontSize: 15,
    borderRadius: 9,
    width: '100%',
    height: '30px',
    padding: 0,
    '&.selected': {
      background: 'white',
      color: props.mainColor,
      border: `1.5px solid ${props.mainColor}`,
      '&:hover': {
        background: 'white',
        color: props.mainColor,
        border: `1.5px solid ${props.mainColor}`
      }
    },
    '&:hover': {
      backgroundColor: props.hoverColor,
      color: 'white'
    }
  }),
  withOrWithoutButton: (props) => ({
    boxShadow: 'none',
    backgroundColor: 'white',
    textAlign: 'center',
    color: props.mainColor,
    fontSize: 15,
    borderRadius: 9,
    width: '100%',
    height: '30px',
    padding: 0,
    '&.selected': {
      background: 'white',
      border: `1.5px solid ${props.mainColor}`,
      '&:hover': {
        background: 'white',
        border: `1.5px solid ${props.mainColor}`
      }
    },
    '&:hover': {
      backgroundColor: 'white',
      border: `1.5px solid ${props.mainColor}`
    }
  }),
  counterButton: () => ({
    backgroundColor: 'white',
    color: primaryColor,
    height: 30,
    width: 35,
    border: borderTheme
  }),
  counterButtonSub: () => ({
    borderRadius: '4px 0px 0px 4px'
  }),
  counterButtonAdd: () => ({
    borderRadius: '0px 4px 4px 0px'
  }),
  counterTypography: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    color: mainTextColor,
    fontSize: 14,
    height: 30,
    width: 35,
    fontWeight: 'bold',
    borderRadius: 0,
    borderTop: borderTheme,
    borderBottom: borderTheme
  }),

  chairBase: () => ({
    cursor: 'pointer',
    padding: '10px 0',
    borderRadius: 6
  }),
  chairBaseStyle: () => ({
    cursor: 'pointer',
    padding: '5px 0',
    borderRadius: 6
  }),
  chairStyle: () => ({
    '&:hover': { backgroundColor: invertedPrimary },
    '&:hover svg path': { fill: 'white' },
    '&:hover p': { color: 'white' }
  }),
  selectedChair: () => ({
    backgroundColor: invertedPrimary,
    '& svg path': { fill: 'white' },
    '& p': { color: 'white' }
  }),
  chairIcon: () => ({
    width: 20,
    height: 25
  }),
  searchIngredient: () => ({
    border: borderTheme,
    borderRadius: 4,
    paddingLeft: 4
  }),
  withIngredientContainer: () => ({
    marginTop: 10
  }),
  withoutIngredientContainer: () => ({
    marginTop: 10
  }),
  maleIcon: () => ({
    cursor: 'pointer',
    borderRadius: 6,

    '&:hover svg .filled': { fill: invertedPrimary }
  }),
  maleIconSelected: () => ({
    cursor: 'pointer',
    borderRadius: 6,

    '& svg .filled': { fill: invertedPrimary }
  }),
  femaleIcon: () => ({
    cursor: 'pointer',
    borderRadius: 6,

    '&:hover svg .filled': { fill: '#b93dc9' }
  }),
  femaleIconSelected: () => ({
    cursor: 'pointer',
    borderRadius: 6,

    '& svg .filled': { fill: '#b93dc9' }
  }),
  textFieldNote: () => ({
    '& fieldset': {
      height: 40
    }
  }),
  errorMessage: () => ({
    color: 'red',
    fontSize: 10,
    textAlign: 'center',
    fontWeight: 'bold'
  }),
  notesTextField: () => ({
    '& div': {
      height: 30
    },
    '& label': {
      fontSize: 12,
      transform: 'translate(8px, 10px) scale(1)'
    }
  }),
  addRemoveIngredientButton: () => ({
    color: 'white',
    fontWeight: 'bold',
    width: 30
  }),
  dishName: () => ({
    textAlign: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  }),
  counterButtonAddTypography: () => ({
    fontSize: 28
  }),
  counterButtonSubTypography: () => ({
    fontSize: 38
  }),
  counterButtonTypography: () => ({
    display: 'flex',
    alignItems: 'center',
    height: 0
  }),
  autocompletePaper: {
    '&.MuiAutocomplete-paper .MuiAutocomplete-listbox': { maxHeight: 120 }
  },
  autocompleteInputRoot: {
    '&.MuiAutocomplete-inputRoot': { padding: 4 }
  },
  chip: {
    '& svg': { fill: 'white' }
  }
};

export default makeStyles(styles);
