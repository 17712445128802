import React, { createContext, useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import typy from 'typy';
import FB_ORDER_STATUSES from '../constants/order/orderStatuses';

export const OrderContext = createContext();
export const initialState = {
  quantity: 1,
  name: '',
  fbMenuItemId: null,
  fbMenuItem: {
    fbMenuCategoryId: null,
    id: null
  },
  fbChairNumber: null,
  fbChairId: null,
  fbChairUUID: null,
  centerpiece: true,
  halfPortion: false,
  sharedBy: [],
  with: [],
  without: [],
  notes: ''
};

export const OrderProvider = (props) => {
  const [orderData, setOrderData] = useState(null);
  const [initialOrderData, setInitialOrderData] = useState(null);
  const [compareOrderContext, setCompareOrderContext] = useState(null);
  const [fbReservationTable, setFbReservationTable] = useState(null);

  const [searchedDish, setSearchedDish] = useState('');

  const [chairOrder, setChairOrder] = useState(initialState);
  const [isEditingCourse, setIsEditingCourse] = useState(false);
  const [menuItemToEdit, setMenuItemToEdit] = useState(null);
  const [oldCourse, setOldCourse] = useState(null);

  const [activeFbCourseKey, setActiveFbCourseKey] = useState(null);
  const [activeFbCourseDrinkKey, setActiveFbCourseDrinkKey] = useState('drink1');

  const [fbMacroCategory, setFbMacroCategory] = useState(null);
  const [fbSelectedCategoryId, setFbSelectedCategoryId] = useState(null);
  const [fbSelectedDish, setFbSelectedDish] = useState(null);

  const resetContext = () => {
    setOrderData(null);
    setInitialOrderData(null);
    setCompareOrderContext(null);
    setFbReservationTable(null);
    setSearchedDish('');
    setChairOrder(initialState);
    setIsEditingCourse(null);
    setMenuItemToEdit(null);
    setOldCourse(null);
    setActiveFbCourseKey(null);
    setActiveFbCourseDrinkKey('drink1');
    setFbMacroCategory(null);
    setFbSelectedCategoryId(null);
    setFbSelectedDish(null);
  };

  useEffect(() => {
    setFbSelectedDish(null);
    setFbSelectedCategoryId(null);
    if (orderData && orderData.fbCourses) {
      if (getFbCourseActiveIndex() !== null) {
        setFbMacroCategory(orderData.fbCourses[getFbCourseActiveIndex()].type);
      }
    }
  }, [activeFbCourseKey]);

  useEffect(() => {
    if (orderData) {
      const thereIsAnEmptyFoodCourse = checkIfThereIsAnEmptyFoodCourse();
      if (thereIsAnEmptyFoodCourse) {
        addNewEmptyFoodCourse();
      }
      if (!activeFbCourseKey) setActiveFbCourseKey(findKeyOfFirstFoodEmptyCourse());

      const thereIsAnEmptyDrinkCourse = checkIfThereIsAnEmptyDrinkCourse();

      if (thereIsAnEmptyDrinkCourse > -1) {
        addNewEmptyDrinkCourse();
      }
    }
  }, [orderData]);

  useEffect(() => {
    setActiveFbCourseKey(findKeyOfFirstFoodEmptyCourse());
  }, []);

  const updateSearchedDish = (value) => {
    setSearchedDish(value);
  };

  const getChairFromId = (id) => {
    return fbReservationTable && fbReservationTable.fbTable.fbChairs.find((chair) => chair.id === id);
  };
  const checkIfThereIsAnEmptyFoodCourse = () => {
    const filteredFoodCourses = typy(orderData, 'fbCourses').safeArray.filter((fbCourse) => fbCourse.type === 'food');
    const orderedDesc = orderBy(filteredFoodCourses, ['number'], ['desc']);
    return (orderedDesc[0] && orderedDesc[0].fbCoursesMenuItems.length > 0) || !orderedDesc[0];
  };

  const checkIfThereIsAnEmptyDrinkCourse = () => {
    return orderData.fbCourses && orderData.fbCourses.filter((fbCourse) => fbCourse.type === 'drink');
  };

  const updateActiveFbCourseDrinkKey = (drinkKey) => {
    const activeDrink = orderData.fbCourses.find((course) => {
      return `${course.type}${course.number}`.includes(drinkKey);
    });

    if (activeDrink) {
      setActiveFbCourseDrinkKey(`${activeDrink.type}${activeDrink.number}`);
    }
  };

  const getFbCourseDrinkActiveIndex = () => {
    if (activeFbCourseDrinkKey) {
      return orderData.fbCourses.findIndex((course) => {
        return `${course.type}${course.number}` === activeFbCourseDrinkKey;
      });
    }
    return `drink1`;
  };

  const updateSelectedCategory = (categoryId) => {
    setFbSelectedCategoryId(categoryId);
  };

  const updateSelectedDish = (dish) => {
    setFbSelectedDish(dish);
  };

  const updateActiveFbCourseKey = (key) => {
    if (key === null) {
      setFbMacroCategory(null);
    }
    if (!isEditingCourse) {
      if (key === activeFbCourseKey) {
        setFbMacroCategory(null);
        setActiveFbCourseKey(null);
      } else {
        setActiveFbCourseKey(key);
      }
    }
  };

  const getFbCourseActiveIndex = () => {
    if (activeFbCourseKey) {
      return orderData.fbCourses.findIndex((course) => {
        return `${course.type}${course.number}`.includes(activeFbCourseKey);
      });
    }
    return null;
  };

  const findKeyOfFirstFoodEmptyCourse = () => {
    return (
      orderData &&
      orderData.fbCourses &&
      orderData.fbCourses.reduce((key, fbCourse) => {
        if (fbCourse.type === 'food' && fbCourse.fbCoursesMenuItems.length === 0) {
          key = `${fbCourse.type}${fbCourse.number}`;
        }
        return key;
      }, null)
    );
  };

  const addNewEmptyDrinkCourse = () => {
    const newFbOrder = { ...orderData };
    const number = orderData && orderData.fbCourses && orderData.fbCourses.filter((fbCourse) => fbCourse.type === 'drink').length;
    const newEmptyCourse = {
      type: 'drink',
      number: number + 1,
      fbCourseStatus: FB_ORDER_STATUSES.none.label,
      fbCoursesMenuItems: [],
      centerpiece: true,
      halfPortion: true,
      sharedBy: [],
      with: [],
      without: [],
      notes: ''
    };

    newFbOrder.fbCourses.push(newEmptyCourse);
    setOrderData(newFbOrder);

    setActiveFbCourseDrinkKey(`drink${number + 1}`);
  };

  const addNewEmptyFoodCourse = () => {
    const newFbOrder = { ...orderData };
    const newEmptyCourse = {
      type: 'food',
      number: orderData.fbCourses.filter((fbCourse) => fbCourse.type === 'food').length + 1,
      fbCourseStatus: FB_ORDER_STATUSES.none.label,
      guestFbReservationId: null,
      fbCoursesMenuItems: [],
      centerpiece: true,
      halfPortion: true,
      sharedBy: [],
      with: [],
      without: [],
      notes: ''
    };

    newFbOrder.fbCourses.push(newEmptyCourse);
    setOrderData(newFbOrder);
  };

  const updateChairOrder = (newChairOrder) => {
    setChairOrder(newChairOrder);
  };

  const updateIsEditingFbCourse = (value) => {
    if (!value) {
      setChairOrder({ ...initialState });
    }
    setIsEditingCourse(value);
  };

  const closeIsEditing = () => {
    setIsEditingCourse(false);
    setActiveFbCourseKey(null);
  };

  const updateOldCourse = (oldC) => {
    setOldCourse(oldC);
  };

  const restoreOldCourse = () => {
    const newFbOrder = { ...orderData };
    let activeIndex = null;
    if (fbMacroCategory === 'food') {
      activeIndex = getFbCourseActiveIndex();
    }

    if (fbMacroCategory === 'drink') {
      activeIndex = getFbCourseDrinkActiveIndex();
    }
    newFbOrder.fbCourses[activeIndex].fbCoursesMenuItems = [...oldCourse];
    setOrderData(newFbOrder);
    setChairOrder(initialState);
    setFbSelectedDish(null);
    setIsEditingCourse(false);
  };

  const deleteFbMenuItem = (item) => {
    const newFbOrder = { ...orderData };
    let activeIndex = null;
    if (fbMacroCategory === 'food') {
      activeIndex = getFbCourseActiveIndex();
    }

    if (fbMacroCategory === 'drink') {
      activeIndex = getFbCourseDrinkActiveIndex();
    }

    const itemFound = newFbOrder.fbCourses[activeIndex].fbCoursesMenuItems.findIndex((fbItem) => {
      return (
        fbItem.fbMenuItemId === item.fbMenuItemId &&
        fbItem.guestFbReservationId === item.guestFbReservationId &&
        fbItem.centerpiece === item.centerpiece &&
        fbItem.halfPortion === item.halfPortion &&
        (isEqual(fbItem.sharedBy, item.sharedBy) ||
          ((fbItem.sharedBy === null || fbItem.sharedBy.length === 0) && (item.sharedBy === null || item.sharedBy.length === 0))) &&
        (fbMacroCategory === 'food' ? isEqual(fbItem.with.sort(), item.with.sort()) && isEqual(fbItem.without.sort(), item.without.sort()) : true)
      );
    });

    newFbOrder.fbCourses[activeIndex].fbCoursesMenuItems.splice(itemFound, 1);

    setOrderData(newFbOrder);
  };

  const saveMenuItemToEdit = (menuItem) => {
    setMenuItemToEdit(menuItem);
  };

  const editFbCourse = (item) => {
    const newFbOrder = { ...orderData };
    let activeIndex = null;
    if (fbMacroCategory === 'food') {
      activeIndex = getFbCourseActiveIndex();
    }

    if (fbMacroCategory === 'drink') {
      activeIndex = getFbCourseDrinkActiveIndex();
    }
    if (isEditingCourse) {
      const itemFoundIndex = newFbOrder.fbCourses[activeIndex].fbCoursesMenuItems.findIndex((fbItem) => {
        return (
          fbItem.fbMenuItemId === item.fbMenuItemId &&
          fbItem.fbChairId === menuItemToEdit.fbChairId &&
          fbItem.centerpiece === menuItemToEdit.centerpiece &&
          fbItem.halfPortion === menuItemToEdit.halfPortion &&
          (isEqual(fbItem.sharedBy, menuItemToEdit.sharedBy) ||
            ((fbItem.sharedBy === null || fbItem.sharedBy.length === 0) &&
              (menuItemToEdit.sharedBy === null || menuItemToEdit.sharedBy.length === 0))) &&
          (fbMacroCategory === 'food'
            ? isEqual(fbItem.with.sort(), menuItemToEdit.with.sort()) && isEqual(fbItem.without.sort(), menuItemToEdit.without.sort())
            : true)
        );
      });
      newFbOrder.fbCourses[activeIndex].fbCoursesMenuItems.splice(itemFoundIndex, 1);

      addFbMenuItem(item);
    }
  };

  const addFbMenuItem = (item) => {
    const newFbOrder = { ...orderData };

    let activeIndex = null;
    if (fbMacroCategory === 'food') {
      activeIndex = getFbCourseActiveIndex();
    }

    if (fbMacroCategory === 'drink') {
      activeIndex = getFbCourseDrinkActiveIndex();
    }

    const itemFound = newFbOrder.fbCourses[activeIndex].fbCoursesMenuItems.find((fbItem) => {
      return (
        fbItem.fbMenuItemId === item.fbMenuItemId &&
        fbItem.fbChairId === item.fbChairId &&
        fbItem.centerpiece === item.centerpiece &&
        fbItem.halfPortion === item.halfPortion &&
        (isEqual(fbItem.sharedBy, item.sharedBy) ||
          ((fbItem.sharedBy === null || fbItem.sharedBy.length === 0) && (item.sharedBy === null || item.sharedBy.length === 0))) &&
        (fbMacroCategory === 'food' ? isEqual(fbItem.with.sort(), item.with.sort()) && isEqual(fbItem.without.sort(), item.without.sort()) : true)
      );
    });

    if (itemFound) {
      itemFound.fbChairNumber = item.fbChairNumber;
      itemFound.quantity += item.quantity;
      if (itemFound.notes) {
        itemFound.notes = itemFound.notes.concat(`\n\r${item.notes}`);
      } else {
        itemFound.notes = item.notes;
      }
    } else {
      newFbOrder.fbCourses[activeIndex].fbCoursesMenuItems.push(item);
    }

    setOrderData(newFbOrder);
    setCompareOrderContext(false);
  };

  const updateOrderContext = (fbOrder) => {
    setOrderData({ ...fbOrder });
    setFbReservationTable(fbOrder.fbReservationTable);
  };
  const updateInitialOrderContext = (fbOrder) => {
    setInitialOrderData({ ...fbOrder });
  };

  const compareOrderContexts = () => {
    const orderContextStringify = JSON.stringify(orderData);

    const initialOrderContextStringify = JSON.stringify(initialOrderData);

    setCompareOrderContext(orderContextStringify === initialOrderContextStringify);
  };

  const updateCompareOrderContext = (value) => {
    setCompareOrderContext(value);
  };

  return (
    <OrderContext.Provider
      value={{
        updateOrderContext,
        orderData,
        fbReservationTable,
        addFbMenuItem,
        addNewEmptyDrinkCourse,
        fbMacroCategory,
        fbSelectedCategoryId,
        updateSelectedCategory,
        fbSelectedDish,
        updateSelectedDish,
        updateActiveFbCourseKey,
        activeFbCourseKey,
        updateActiveFbCourseDrinkKey,
        activeFbCourseDrinkKey,
        chairOrder,
        updateChairOrder,
        updateIsEditingFbCourse,
        isEditingCourse,
        updateOldCourse,
        restoreOldCourse,
        deleteFbMenuItem,
        saveMenuItemToEdit,
        editFbCourse,
        updateInitialOrderContext,
        compareOrderContexts,
        compareOrderContext,
        updateCompareOrderContext,
        getFbCourseActiveIndex,
        findKeyOfFirstFoodEmptyCourse,
        getChairFromId,
        updateSearchedDish,
        searchedDish,
        resetContext,

        closeIsEditing
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};

export const OrderConsumer = OrderContext.Consumer;
