const FB_COURSE_STATUSES = {
  todo: { color: '#C80000', label: 'todo' },
  ready: { color: '#009664', label: 'ready' },
  preparing: { color: '#FFBA00', label: 'preparing' },
  waiting: { color: '#E8E800', label: 'waiting' },
  completed: { color: '#979797', label: 'completed' },
  none: { color: '#55595C', label: 'none' }
};

export default FB_COURSE_STATUSES;
