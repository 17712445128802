import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { CustomModal, MaterialIcon, primaryColor, CustomButton } from '../../../common-fe/src';
import styles from '../../../styles/cash/modals/selectPersonsNumberStyle';
import { TranslateTextComponent } from '../../../translator';

const AddChairModal = ({ showAddChairModal, closeModals, addChair }) => {
  const classes = styles();
  const [value, setValue] = useState(1);

  return (
    <CustomModal
      width={450}
      height={286}
      styleBody={{ display: 'grid', gridTemplateAreas: '"icon" "label" "stepper" "buttons"', gridTemplateColumns: '1fr' }}
      showModal={showAddChairModal}
      onClose={closeModals}
    >
      <Box className={classes.area} style={{ gridArea: 'icon' }}>
        <MaterialIcon iconName="Help" style={{ color: primaryColor, fontSize: '4rem' }} />
      </Box>
      <Box className={classes.area} style={{ gridArea: 'label', fontSize: 18 }}>
        <TranslateTextComponent capitalize>add-chair-modal-text</TranslateTextComponent>
      </Box>
      <Box className={[classes.area, classes.stepper].join(' ')} style={{ gridArea: 'stepper' }}>
        <MaterialIcon
          iconName="RemoveCircle"
          style={{ cursor: 'pointer', fontSize: '2.5rem' }}
          onClick={() => {
            if (value - 1 > 0) {
              setValue((prev) => prev - 1);
            }
          }}
        />
        <span>{value}</span>
        <MaterialIcon
          iconName="AddCircle"
          style={{ cursor: 'pointer', fontSize: '2.5rem' }}
          onClick={() => {
            setValue((prev) => prev + 1);
          }}
        />
      </Box>
      <Box className={[classes.area, classes.buttons].join(' ')} style={{ gridArea: 'buttons' }}>
        <CustomButton
          style={{ width: 'auto', backgroundColor: 'white', color: primaryColor, border: `1px solid ${primaryColor}` }}
          onClick={closeModals}
        >
          <TranslateTextComponent>cancel</TranslateTextComponent>
        </CustomButton>
        <CustomButton style={{ width: 'auto' }} onClick={() => addChair(value)}>
          <TranslateTextComponent>confirm</TranslateTextComponent>
        </CustomButton>
      </Box>
    </CustomModal>
  );
};

export default AddChairModal;
