import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid, ClickAwayListener } from '@material-ui/core';
import CategoryButton from './CategoryButton';
import { GET_MENU_CATEGORIES } from '../../graphql/menu/queries';
import cashStyles from '../../styles/cash/cashStyle';
import useCustomQuery from '../../hooks/useCustomQuery';
import { AuthContext } from '../../contexts/AuthContext';

const MenuList = ({ standalone, data, selectedCategoryUUID, categoryButtonClick, dishClick }) => {
  const [categoryUUID, setCategoryUUID] = useState(null);
  const [categoryColor, setCategoryColor] = useState(null);
  const [dishes, setDishes] = useState([]);
  const [showDishes, setShowDishes] = useState(false);
  const { appOutletUUID } = useContext(AuthContext);

  const classes = cashStyles({ categoryColor });

  useEffect(() => {
    const selectedCategoryObject = categories?.data?.getFbMenuCategories?.data?.filter((category) => {
      return category.uuid === categoryUUID;
    });

    setDishes([...(selectedCategoryObject?.[0]?.fbMenuItems?.sort((prev, next) => prev.name > next.name) ?? [])]);
    setCategoryColor(categories.data?.getFbMenuCategories?.data?.find((category) => category.uuid === categoryUUID)?.color ?? '');
  }, [categoryUUID]);

  // Query
  const categories = useCustomQuery(GET_MENU_CATEGORIES, {
    variables: { fbOutletUUID: appOutletUUID },
    skip: !standalone,
    fetchPolicy: 'network-only'
  });

  return (
    <Box
      className={standalone ? classes.menuArea : ''}
      style={
        standalone
          ? { position: 'relative', gridArea: 'menu-area', display: 'flex', height: '100%' }
          : { position: 'relative', gridArea: 'categories' }
      }
    >
      <Grid container style={{ height: standalone ? '100%' : '60vh', overflowY: 'auto' }}>
        {!standalone &&
          data
            ?.sort((prev, next) => prev.order - next.order)
            .map((category) => {
              return (
                <Grid key={category.id} item style={{ margin: '5px 0' }} xs={12}>
                  <CategoryButton
                    onClick={() => {
                      categoryButtonClick(category.uuid);
                    }}
                    size="small"
                    key={category.name}
                    category={category}
                    isSelected={selectedCategoryUUID === category.uuid}
                  />
                </Grid>
              );
            })}
        {standalone &&
          categories.data?.getFbMenuCategories?.data
            ?.sort((prev, next) => prev.order - next.order)
            .map((category) => {
              return (
                <Grid key={category.id} item style={{ margin: '5px 0' }} xs={12}>
                  <CategoryButton
                    onClick={() => {
                      setCategoryUUID(category.uuid);
                      setShowDishes(true);
                    }}
                    size="small"
                    key={category.name}
                    category={category}
                    isSelected={categoryUUID === category.uuid}
                    standalone
                  />
                </Grid>
              );
            })}
      </Grid>
      {standalone && categoryUUID && showDishes && (
        <ClickAwayListener
          onClickAway={() => {
            setShowDishes(false);
            setCategoryUUID(false);
          }}
        >
          <Box
            style={{
              position: 'absolute',
              display: 'flex',
              flexWrap: 'wrap',
              padding: 20,
              top: 0,
              left: 200,
              backgroundColor: 'white',
              border: `2px solid ${categoryColor}`,
              borderRadius: '0 10px 10px 0',
              maxHeight: '80vh',
              minWidth: 650,
              width: '100%',
              overflow: 'auto',
              boxShadow: '0px 3px 6px #00000029',
              zIndex: 5
            }}
          >
            {dishes.map((dish) => (
              <Box className={classes.menuItem} key={dish.uuid} onClick={() => dishClick(dish)}>
                {dish.name?.length > 11 ? `${dish.name.substring(0, 11)}...` : dish.name}
              </Box>
            ))}
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default MenuList;
