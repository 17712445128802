/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { IconButton, Box } from '@material-ui/core/';
import PrintIcon from '@material-ui/icons/Print';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactToPrint from 'react-to-print';
import styled from 'styled-components/macro';
import moment from 'moment';
import { TranslateTextComponent, capitalizeFirstLetter, translate } from '../../../../translator';
import FB_RESERVATION_STATUSES from '../../../../constants/showReservations/reservationsStatuses';
import ColoredCircle from '../../../shared/ColoredCircle';
import { ReactComponent as AdultIcon } from '../../../../assets/images/adult.svg';
import { ReactComponent as KidIcon } from '../../../../assets/images/kid.svg';
import { ReactComponent as EnfantIcon } from '../../../../assets/images/enfant.svg';
import { mainTextColor } from '../../../../styles/theme';
import { Dropdown } from '../../../../common-fe/src';
import TableButton from '../../../shared/TableButton';
import { ROUTE_EDIT_RESERVATION } from '../../../../router/routes';

const textTranslate = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

const getNPax = (guestsList) => {
  if (!guestsList) return '-';

  const PhysicalCategoryBox = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-right: 10px;
  `;

  const nPax = {
    adults: { number: 0, icon: <AdultIcon style={{ width: 10, height: 10 }} /> },
    kids: { number: 0, icon: <KidIcon style={{ width: 10, height: 10 }} /> },
    enfants: { number: 0, icon: <EnfantIcon style={{ width: 10, height: 10 }} /> }
  };
  guestsList.forEach((guest) => {
    nPax[guest.guestPhysicalCategory.name].number += 1;
  });

  return (
    <div style={{ color: mainTextColor }}>
      <span style={{ fontSize: 16 }}>{guestsList.length}</span>
      <br />
      <div style={{ display: 'flex' }}>
        {Object.keys(nPax).map((key) => {
          if (nPax[key].number > 0)
            return (
              <PhysicalCategoryBox key={key}>
                {nPax[key].icon}
                <span style={{ fontSize: 11 }}>{nPax[key].number}</span>
              </PhysicalCategoryBox>
            );

          return null;
        })}
      </div>
    </div>
  );
};

const reservationsColumns = ({
  language,
  channels,
  packages,
  history,
  printableReservationRef,
  setCurrentReservation,
  changeChannel,
  changePackage,
  setFbReservationGuestArrived,
  deleteReservation
}) => {
  return [
    {
      id: 'bookedBy.lastName',
      disablePadding: true,
      label: textTranslate('client-name'),
      isSortable: true,
      render: (item) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ColoredCircle color={FB_RESERVATION_STATUSES[item.status?.toLowerCase()].color} style={{ marginRight: 10 }} />
          <span>{item.reservationName ?? `${item.bookedBy?.lastname ?? '--'} ${item.bookedBy?.firstname ?? '-'}`}</span>
        </div>
      )
    },
    {
      id: 'nPax',
      disablePadding: false,
      label: textTranslate('pax'),
      isSortable: true,
      render: (item) => getNPax(item.fbGuestList)
    },
    {
      id: 'fbReservation.guestRoom',
      disablePadding: false,
      label: textTranslate('room'),
      isSortable: true,
      render: (item) => item.guestRoom
    },
    {
      id: 'fbReservation.date',
      disablePadding: false,
      label: textTranslate('reservation-date'),
      isSortable: true,
      render: (item) => moment(item.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    {
      id: 'fbReservation.reservationTime',
      disablePadding: false,
      label: textTranslate('time'),
      isSortable: true,
      render: (item) => item.reservationTime ?? '-'
    },
    {
      id: 'outlet',
      disablePadding: false,
      label: textTranslate('outlet'),
      isSortable: false,
      render: (item) => item.fbService?.fbOutlet?.name ?? '-'
    },
    {
      id: 'arrangement',
      disablePadding: true,
      label: textTranslate('arrangement'),
      isSortable: false,
      render: (item) => item.arrangement ?? '-'
    },
    {
      id: 'channel',
      disablePadding: false,
      label: textTranslate('channel'),
      isSortable: false,
      render: (item) => {
        return (
          <Dropdown
            dropdownStyle={{ backgroundColor: 'white' }}
            items={
              channels.map((channel) => ({
                label: textTranslate(channel.name ? `channel_${channel.name?.toLowerCase()}` : ''),
                value: channel.uuid
              })) ?? []
            }
            value={item.channel?.uuid}
            onChange={({ target: { value } }) => {
              if (item.channel?.uuid !== value)
                changeChannel({
                  fbReservationUUID: item.uuid,
                  channelUUID: value,
                  channelName: channels.find((channel) => channel.uuid === value)?.name ?? 'none'
                });
            }}
          />
        );
      }
    },
    {
      id: 'package',
      disablePadding: false,
      label: textTranslate('package'),
      isSortable: false,
      render: (item) => (
        <Dropdown
          dropdownStyle={{ backgroundColor: 'white' }}
          items={
            packages
              .map((packageItem) => ({ label: textTranslate(packageItem.name?.toLowerCase() ?? ''), value: packageItem.uuid }))
              ?.concat({ label: textTranslate('none'), value: null }) ?? []
          }
          value={item.package?.uuid}
          onChange={({ target: { value } }) => {
            if (item.package?.uuid !== value)
              changePackage({
                fbReservationUUID: item.uuid,
                packageUUID: value,
                packageName: packages.find((pkg) => pkg.uuid === value)?.name ?? 'none'
              });
          }}
        />
      )
    },
    {
      id: 'guestArrived',
      disablePadding: false,
      label: textTranslate('guest-arrived'),
      isSortable: false,
      render: (item) => (
        <TableButton
          disabled={item.status !== FB_RESERVATION_STATUSES.open.label}
          style={{ maxHeight: 28, fontSize: 10 }}
          onClick={() => setFbReservationGuestArrived(item.uuid)}
        >
          confirm
        </TableButton>
      )
    },
    {
      id: 'actions',
      disablePadding: true,
      label: textTranslate('actions'),
      isSortable: false,
      render: (item) => (
        <Box style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <ReactToPrint
            trigger={() => (
              <IconButton className="buttonCircle" style={{ marginRight: 5 }}>
                <PrintIcon />
              </IconButton>
            )}
            content={() => printableReservationRef}
            onBeforeGetContent={() => setCurrentReservation(item)}
          />
          <IconButton className="buttonCircle" style={{ marginRight: 5 }} onClick={() => history.push(`${ROUTE_EDIT_RESERVATION}/${item.uuid}`)}>
            <EditIcon />
          </IconButton>
          <IconButton
            className="buttonCircle"
            disabled={item.status !== FB_RESERVATION_STATUSES.open.label}
            onClick={() => deleteReservation(item.uuid)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      )
    }
  ];
};

export default reservationsColumns;
