import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import typy from 'typy';
import SingleTableDetail from '../../shared/SingleTableDetail';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { translate, capitalizeFirstLetter } from '../../../translator';

const getMood = (unicode) => {
  const cleanedUnicode = unicode && unicode.replace(/<\/?[^>]+(>|$)/g, '');
  return <span className="emoji" role="img" aria-label="good" dangerouslySetInnerHTML={{ __html: cleanedUnicode }} />;
};

const ReservationDetail = ({ fbReservation }) => {
  const { language } = useContext(LanguageContext);

  return (
    <Grid container justify="center" style={{ textAlign: 'center' }}>
      <Grid item md={4} xs={4}>
        <SingleTableDetail
          title={capitalizeFirstLetter(translate('arrival-time', language))}
          description={typy(fbReservation, 'arrivalTime').safeString || '-'}
        />
        <SingleTableDetail
          title={capitalizeFirstLetter(translate('covers-number', language))}
          description={typy(fbReservation, 'fbGuestList.length').safeNumber || '-'}
        />
        <SingleTableDetail
          title={capitalizeFirstLetter(translate('table-number', language))}
          description={typy(fbReservation, 'tableNumber').safeString || '-'}
        />
      </Grid>
      <Grid item md={4} xs={4}>
        <SingleTableDetail
          title={capitalizeFirstLetter(translate('client-type', language))}
          description={typy(fbReservation, 'bookedBy.guestType.name').safeString || '-'}
        />
        <SingleTableDetail
          title={capitalizeFirstLetter(translate('mood', language))}
          description={
            typy(fbReservation, 'bookedBy.guestMoods').safeArray.length !== 0
              ? getMood(typy(fbReservation, 'bookedBy.guestMoods[0].mood.unicode').safeString)
              : '-'
          }
        />
        <SingleTableDetail title={capitalizeFirstLetter(translate('notes', language))} description={typy(fbReservation, 'notes').safeString || '-'} />
      </Grid>
      <Grid item md={4} xs={4}>
        <SingleTableDetail
          title={capitalizeFirstLetter(translate('arrangement', language))}
          description={typy(fbReservation, 'arrangement').safeString ? typy(fbReservation, 'arrangement').safeString : '-'}
        />
        <SingleTableDetail
          title={capitalizeFirstLetter(translate('res-channel', language))}
          description={typy(fbReservation, 'channel').safeString || '-'}
        />
        <SingleTableDetail
          title={capitalizeFirstLetter(translate('package', language))}
          description={typy(fbReservation, 'package').safeString || '-'}
        />
      </Grid>
    </Grid>
  );
};

export default ReservationDetail;
