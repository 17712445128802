import React from 'react';
import Layout from '../components/shared/Layout';
import BillsManagement from '../components/BillsManagement/BillsManagement';

const BillsManagementPage = ({ history }) => {
  return (
    <Layout history={history} section="bills-management">
      <BillsManagement />
    </Layout>
  );
};

export default BillsManagementPage;
