import React, { useState } from 'react';
import Spinner from 'react-loader-spinner';
import { Box, TablePagination } from '@material-ui/core';
import moment from 'moment';
import MainContainer from '../shared/MainContainer';
import { DataPicker, CustomButton } from '../../../common-fe/src';
import HeaderIncome from '../shared/HeaderIncome/HeaderIncome';
import Input from '../shared/Input';
import WideCardContainer from '../shared/WideCard/WideCardContainer';
import SummaryWithBorder from '../shared/WideCard/Summaries/SummaryWithBorder';
import { CLOSE_OUT_OF_CASH } from '../../../constants/billsManagement/tabs';
import CloseOutDetails from '../shared/WideCard/Details/CloseOutDetails';

const CloseOut = ({ isLoading, data, totalCount, page, pageSize, onFilterChange, onDateChange, onPageSizeChange, onPageChange }) => {
  const [date, setDate] = useState(null);

  return (
    <MainContainer>
      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Input
          title=""
          component={
            <DataPicker
              dataPickerStyle={{ overflow: 'hidden', maxWidth: 200 }}
              value={date}
              onChange={(e) => {
                if (moment(e).isValid()) {
                  setDate(e);
                  onFilterChange('date', e);
                }
              }}
            />
          }
        />
        <HeaderIncome monthly onDateChange={onDateChange} />
        <CustomButton style={{ maxWidth: 200 }} disabled>
          REPORT
        </CustomButton>
      </Box>
      <Box style={{ overflow: 'auto' }}>
        {isLoading ? (
          <Box style={{ display: 'flex', justifyContent: 'center', border: 'none', margin: 'auto', paddingTop: 50 }}>
            <Spinner type="TailSpin" color="#00BFFF" height={100} width={100} />
          </Box>
        ) : (
          data.map((d) => (
            <WideCardContainer
              key={d.uuid}
              summaryComponent={<SummaryWithBorder data={d} summaryType={CLOSE_OUT_OF_CASH} />}
              detailsComponent={<CloseOutDetails data={d} />}
            />
          ))
        )}
      </Box>
      <TablePagination
        style={{ display: 'flex', justifyContent: 'center' }}
        component="div"
        count={totalCount}
        rowsPerPageOptions={[5, 10, 25]}
        rowsPerPage={pageSize}
        page={page - 1}
        onChangePage={(e, page) => onPageChange(page)}
        onChangeRowsPerPage={(e) => onPageSizeChange(e.target.value)}
      />
    </MainContainer>
  );
};

export default CloseOut;
