import React, { useState, useRef, useEffect } from 'react';
import { Grid, Typography, Box } from '@material-ui/core/';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { invertedPrimary, primaryColor } from '../../styles/theme';
import MaterialIcon from './MaterialIcon';
import styles from '../../styles/shared/timePicker';

const hours = Array(24)
  .fill(0)
  .map((e, i) => `${i}`);

const minutes = Array(60)
  .fill(0)
  .map((e, i) => `${i}`);

const Arrow = ({ iconName, rotate, onClick }) => {
  return <MaterialIcon iconName={iconName} style={{ color: invertedPrimary, transform: `rotate(${rotate}deg)`, width: 20 }} onClick={onClick} />;
};

const useOutsideAlerter = (containerRef, iconRef, openToggleHandler) => {
  /**
   * Alert if clicked on outside of element
   */
  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target) && iconRef.current && !iconRef.current.contains(event.target)) {
      openToggleHandler();
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

const commonSettings = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 5,
  vertical: true,
  verticalSwiping: true,
  swipeToSlide: true,
  nextArrow: <Arrow iconName="ChevronLeft" rotate={-90} />,
  prevArrow: <Arrow iconName="ChevronRight" rotate={-90} />,
  centerMode: true
};
const CustomTimePicker = ({ hoursPlaceholder, minsPlaceholder, label, labelStyle, onValueChange, error, errorLabel, value = '00:00' }) => {
  const classes = styles();
  const hourRef = useRef(null);
  const minRef = useRef(null);
  const containerRef = useRef(null);
  const iconRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [hasInitialized, setHasInitialized] = useState(false);

  const hoursSettings = {
    ...commonSettings,
    initialSlide: value.split(':')[0] && value.split(':')[0] !== hoursPlaceholder ? Number(value.split(':')[0]) : 0
  };
  const minsSettings = {
    ...commonSettings,
    initialSlide: value.split(':')[1] && value.split(':')[1] !== minsPlaceholder ? Number(value.split(':')[1]) : 0
  };

  useEffect(() => {
    if (value) {
      const formattedHour = getFormattedTime(value.split(':')[0]);
      const formattedMins = getFormattedTime(value.split(':')[1]);
      onValueChange(`${formattedHour}:${formattedMins}`);
    }
  }, [value]);

  const openToggleHandler = () => {
    if (!hasInitialized) {
      onValueChange('00:00');
      setHasInitialized(true);
    }

    setIsOpen((prevProp) => !prevProp);
  };
  const updateCurrentHourIndex = (i) => {
    hourRef.current.slickGoTo(i);

    const m = value.split(':')[1];
    onValueChange(`${hours[i]}:${m}`);
  };
  const updateCurrentMinIndex = (i) => {
    minRef.current.slickGoTo(i);
    const h = value.split(':')[0];
    onValueChange(`${h}:${minutes[i]}`);
  };

  useOutsideAlerter(containerRef, iconRef, openToggleHandler);

  const getFormattedTime = (currentValue) => {
    return currentValue.length === 1 ? `0${currentValue}` : currentValue;
  };
  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      <Typography style={{ ...labelStyle }}>{label}</Typography>
      <Box style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
        <Box className={error ? [classes.timePickerBox, 'error'].join(' ') : classes.timePickerBox}>
          <Box style={{ width: '100%' }}>
            <Typography style={{ textAlign: 'center' }}>
              {value.split(':')[0] === null ? hoursPlaceholder : `${getFormattedTime(value.split(':')[0])}`}:
              {value.split(':')[1] === null ? minsPlaceholder : `${getFormattedTime(value.split(':')[1])}`}
            </Typography>
          </Box>
          <Box
            style={{ width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', backgroundColor: primaryColor }}
            ref={iconRef}
          >
            <MaterialIcon iconName="AccessTime" onClick={() => openToggleHandler()} style={{ color: 'white' }} />
          </Box>
        </Box>
        {error ? <span style={{ color: 'red', fontSize: 10 }}>{errorLabel}</span> : null}

        {isOpen && (
          <Grid
            container
            className="timePickerSliderBox"
            justify="space-evenly"
            style={{
              backgroundColor: 'white',
              bottom: -193,
              position: 'absolute',
              width: '100%',
              boxShadow: '0px 3px 10px #0000004B',
              zIndex: 1
            }}
            ref={containerRef}
          >
            <Slider
              ref={hourRef}
              {...hoursSettings}
              style={{ width: 20, textAlign: 'center', height: 15 }}
              afterChange={(currentSlide) => updateCurrentHourIndex(currentSlide.toString())}
            >
              {hours.map((h, i) => (
                <Box key={i} onClick={() => updateCurrentHourIndex(i)}>
                  {getFormattedTime(h)}
                </Box>
              ))}
            </Slider>
            <Slider
              ref={minRef}
              {...minsSettings}
              style={{ width: 20, textAlign: 'center' }}
              afterChange={(currentSlide) => updateCurrentMinIndex(currentSlide.toString())}
            >
              {minutes.map((m, i) => (
                <Box key={i} onClick={() => updateCurrentMinIndex(i)}>
                  {getFormattedTime(m)}
                </Box>
              ))}
            </Slider>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default CustomTimePicker;
