import React, { useEffect } from 'react';
import Layout from '../components/shared/Layout';
import RoomContainer from '../components/roomEditor/RoomContainer';

const RoomEditorPage = ({ history }) => {
  useEffect(() => {
    document.body.style.backgroundColor = 'white';
  }, []);

  return (
    <Layout history={history} section="room-editor">
      <RoomContainer />
    </Layout>
  );
};

export default RoomEditorPage;
