import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from '../../common-fe/src';

const styles = {
  container: {
    cursor: 'default',
    position: 'absolute',
    top: 60,
    right: 0,
    display: 'grid',
    gridTemplateAreas: '"chairs-area" "buttons-area"',
    gridTemplateRows: '1fr auto',
    width: 270,
    height: 150,
    overflowX: 'auto',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '5px 5px 20px #0000004B',
    border: '1px solid #91C9E8',
    borderRadius: 4,
    padding: 10,
    zIndex: 5
  },
  chairsContainer: {
    gridArea: 'chairs-area',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  buttonsContainer: {
    gridArea: 'buttons-area',
    display: 'flex',
    justifyContent: 'space-around'
  },
  chairNumber: {
    color: primaryColor
  },
  groupedChairsContainer: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
    borderRadius: 5,
    padding: 10,
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: '#3399CC',
      '& span, path': {
        color: 'white',
        fill: 'white'
      }
    }
  },
  selectedChair: {
    backgroundColor: '#3399CC',
    '& span, path': {
      color: 'white',
      fill: 'white'
    }
  }
};

export default makeStyles(styles);
