/* eslint-disable import/prefer-default-export */

export const MOH_ORDERS_STATUSES = {
  to_confirm: 'to_confirm',
  open: 'open',
  payment_pending: 'payment_pending',
  preparing: 'preparing',
  delivering: 'delivering',
  completed: 'completed',
  deleted: 'deleted',
  /**
   * "working" STATUS IS USED FOR TRANSLATIONS PURPOSES
   * IT DOESN'T EXIST ON SCHEMA.
   * USE "preparing" AS MUTATION PARAMETER
   */
  working: 'working'
};
