import { gql } from 'apollo-boost';
import { SMART_SERVICES_FRAGMENT } from '../../fragments';

export const ADD_SMART_SERVICE = gql`
  mutation addSmartService($fbOutletUUID: String!, $smartServiceData: SmartServicesInputDto!) {
    addSmartService(fbOutletUUID: $fbOutletUUID, smartServiceData: $smartServiceData) {
      ...smartServicesFragment
    }
  }
  ${SMART_SERVICES_FRAGMENT}
`;

export const EDIT_SMART_SERVICE = gql`
  mutation editSmartService($smartServiceUUID: String!, $smartServiceData: SmartServicesInputDto!) {
    editSmartService(smartServiceUUID: $smartServiceUUID, smartServiceData: $smartServiceData) {
      ...smartServicesFragment
    }
  }
  ${SMART_SERVICES_FRAGMENT}
`;

export const DELETE_SMART_SERVICE = gql`
  mutation deleteSmartService($smartServiceUUID: String!) {
    deleteSmartService(smartServiceUUID: $smartServiceUUID)
  }
`;
