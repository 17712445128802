/* eslint-disable no-nested-ternary */
import { makeStyles } from '@material-ui/core/styles';
import { borderTheme } from '../theme';

const styles = {
  timePickerBox: () => ({
    width: 150,
    minHeight: 30,
    border: borderTheme,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    alignItems: 'center',
    '&.error': {
      border: '1.5px solid red'
    }
  })
};

export default makeStyles(styles);
