import React from 'react';
import * as moment from 'moment';
import { Box } from '@material-ui/core';
import styles from '../../../../../styles/billsManagement/wideCard';
import { TranslateTextComponent } from '../../../../../translator';
import { CustomButton } from '../../../../../common-fe/src';

const CloseOutDetails = ({ data }) => {
  const classes = styles();
  return (
    <Box className={classes.closeOutContainer}>
      <Box className={classes.closeOutDetailRow}>
        <span>{moment(data.datetime).format('DD/MM/YYYY HH:mm') || ''}</span>
        <span>{data.documentNumber}</span>
      </Box>
      <hr />
      <Box className={classes.closeOutDetailRow}>
        <Box>
          <TranslateTextComponent>days-total</TranslateTextComponent>
          {/* <TranslateTextComponent>grand-total</TranslateTextComponent> */}
        </Box>
        <Box>
          <span>€ {data.totalAmount && data.totalAmount.toFixed(2)}</span>
          {/* <span>267.555</span> */}
        </Box>
      </Box>
      <Box className={classes.closeOutDetailRow}>
        <Box>
          <TranslateTextComponent>discounts-total</TranslateTextComponent>
          <TranslateTextComponent>corrections-total</TranslateTextComponent>
          <TranslateTextComponent>charges-not-collected</TranslateTextComponent>
        </Box>
        <Box>
          <span>€ {data.totalDiscountAmount && data.totalDiscountAmount.toFixed(2)}</span>
          <span>0</span>
          <span>0</span>
        </Box>
      </Box>
      <hr />
      <Box className={classes.closeOutDetailRow}>
        <Box>
          <TranslateTextComponent>daily-goods-refund</TranslateTextComponent>
          <TranslateTextComponent>refund-grand-total</TranslateTextComponent>
        </Box>
        <Box>
          <span>0</span>
          <span>0</span>
        </Box>
      </Box>
      <hr />
      <Box className={classes.closeOutDetailRow}>
        <Box>
          <TranslateTextComponent>daily-total-cancellations</TranslateTextComponent>
          <TranslateTextComponent>grand-total-cancellations</TranslateTextComponent>
        </Box>
        <Box>
          <span>{data.totalCancellationNumber && data.totalCancellationNumber}</span>
          <span>{data.totalCancellationAmount && data.totalCancellationAmount.toFixed(2)}</span>
        </Box>
      </Box>
      <hr />
      <Box className={classes.closeOutDetailRow}>
        <Box>
          <TranslateTextComponent>business-documents-number</TranslateTextComponent>
        </Box>
        <Box>
          <span>{data && data.totalTicketNumber + data.totalInvoiceNumber}</span>
        </Box>
      </Box>
      <hr />
      <Box className={classes.closeOutDetailRow}>
        <Box>
          <TranslateTextComponent>wipes-number</TranslateTextComponent>
        </Box>
        <Box>
          <span>0</span>
        </Box>
      </Box>
      <hr />
      <Box className={classes.closeOutDetailRow}>
        <Box>
          <TranslateTextComponent>documents-to-send</TranslateTextComponent>
          <TranslateTextComponent>documents-3-days</TranslateTextComponent>
        </Box>
        <Box>
          <span>0</span>
          <span>0</span>
        </Box>
      </Box>
      <hr />
      <Box className={classes.closeOutDetailRow}>
        <Box>
          <TranslateTextComponent>invoices-number</TranslateTextComponent>
          <TranslateTextComponent>total-day-invoices</TranslateTextComponent>
        </Box>
        <Box>
          <span>{data && data.totalInvoiceNumber}</span>
          <span>{data && data.totalInvoiceAmount}</span>
        </Box>
      </Box>
      <hr />
      <Box className={classes.closeOutDetailRow}>
        <Box>
          <TranslateTextComponent>restores-number</TranslateTextComponent>
        </Box>
        <Box>
          <span>0</span>
        </Box>
      </Box>
      <CustomButton style={{ marginTop: 10 }}>
        <TranslateTextComponent>print</TranslateTextComponent>
      </CustomButton>
    </Box>
  );
};

export default CloseOutDetails;
