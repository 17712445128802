import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@material-ui/core';
import typy from 'typy';
import { CustomButton, CustomModal, redButton, greenButton } from '../../../common-fe/src';
import { TranslateTextComponent } from '../../../translator';
import PMForm from '../../cash/PM/PMForm/PMForm';
import PMUsers from '../../cash/PM/PMUsers/PMUsers';

const NewPmModal = ({ showNewPmModal, closeModal, pmToEdit, refetchGetPayMaster }) => {
  const [isPMGroup, setIsPMGroup] = useState(false);
  const [guestsList, setGuestsList] = useState([]);
  const formRef = useRef(null);

  useEffect(() => {
    if (typy(pmToEdit, 'guestList').safeArray && typy(pmToEdit, 'guestList').safeArray.length > 0) {
      setGuestsList(pmToEdit.guestList);
      setIsPMGroup(true);
    }
  }, [pmToEdit]);
  const onRemoveGuestClickHandler = (guestUUID) => {
    setGuestsList((prev) => prev.filter((guest) => guest.uuid !== guestUUID));
  };

  const onAddGuestClickHandler = (guest) => {
    if (!guestsList.some((g) => g.uuid === guest.uuid)) setGuestsList((prev) => [...prev, guest]);
  };

  const onPMFormSubmit = () => {
    formRef.current.handleSubmit();
  };

  const resetGuestsList = () => {
    setGuestsList([]);
  };

  return (
    <CustomModal
      showModal={showNewPmModal}
      hideCloseButton
      onClose={closeModal}
      width="auto"
      height="auto"
      stylePaper={{ maxWidth: !showNewPmModal ? 900 : 'unset' }}
      styleBody={{
        backgroundColor: 'white',
        borderRadius: 20,
        minHeight: 306,
        maxHeight: 461,
        minWidth: 665,
        overflow: 'auto'
      }}
    >
      <Box>
        <PMForm
          ref={formRef}
          refetchGetPayMaster={refetchGetPayMaster}
          guestsList={guestsList}
          onPMGroupChange={() => setIsPMGroup((prev) => !prev)}
          resetGuestsList={resetGuestsList}
          closePMForm={closeModal}
          pmToEdit={pmToEdit}
        />
        {isPMGroup && <PMUsers guestsList={guestsList} onAddGuestClick={onAddGuestClickHandler} onRemoveGuestClick={onRemoveGuestClickHandler} />}
        <Box style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
          <CustomButton
            style={{ width: 'auto', minWidth: 155, backgroundColor: redButton }}
            onClick={() => {
              closeModal();
              setIsPMGroup((prev) => (!prev ? prev : !prev));
            }}
          >
            <TranslateTextComponent>cancel</TranslateTextComponent>
          </CustomButton>
          <Box>
            <CustomButton style={{ width: 'auto', minWidth: 155, marginLeft: 5, backgroundColor: greenButton }} onClick={onPMFormSubmit}>
              <TranslateTextComponent>save</TranslateTextComponent>
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default NewPmModal;
