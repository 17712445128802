import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import { ReactComponent as Clock } from '../../assets/images/clock.svg';

import styles from '../../styles/shared/dataPicker';

const TimePicker = ({ value, error, errorLabel, onChange, containerStyle, timePickerStyle, inputPropsStyle, inputTextAlign, fullWidth }) => {
  const svgHeight = timePickerStyle && timePickerStyle.height ? timePickerStyle.height : 40;
  const classes = styles({ svgHeight, inputTextAlign });

  let timeValue = value;

  if (typeof value === 'string') {
    const splittedTime = value.split(':');
    timeValue = new Date();
    timeValue.setHours(splittedTime[0], splittedTime[1]);
  }

  let inputStyleObj = {
    ...timePickerStyle
  };
  if (error) {
    inputStyleObj = {
      ...inputStyleObj,
      borderColor: 'red'
    };
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div style={{ display: 'flex', flexFlow: 'column', width: '100%', ...containerStyle }}>
        <KeyboardTimePicker
          className={[classes.keyboardDatePicker, 'dataPicker'].join(' ')}
          fullWidth={fullWidth}
          autoOk
          autoComplete="off"
          ampm={false}
          disableToolbar
          variant="inline"
          placeholder="HH/MM"
          style={inputStyleObj}
          value={timeValue}
          onChange={onChange}
          inputVariant="outlined"
          inputProps={{
            style: { padding: 0, minHeight: 30, textIndent: 10, ...inputPropsStyle }
          }}
          keyboardIcon={<Clock />}
        />
        {error ? <span style={{ color: 'red', fontSize: 10 }}>{errorLabel}</span> : null}
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default TimePicker;
