import React, { useState, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import styles from '../../styles/shared/verticalTab';
import { ROLES } from '../../constants/users/usersRole';

export default function VerticalTabs({ history, buttons, userRoles }) {
  const classes = styles();
  const [value, setValue] = useState(0);

  const filteredButtons = buttons.filter((button) =>
    button.roles.some((r) => userRoles?.includes(r) || userRoles?.includes(ROLES.ROLE_SUPER_ADMIN) || userRoles?.includes(ROLES.ROLE_MANAGER))
  );

  useEffect(() => {
    const getRouteIndex = (path) => filteredButtons.findIndex((btn) => path.substring(0, btn.route.length) === btn.route);
    setValue(getRouteIndex(history.location.pathname));
  }, [history]);

  return (
    <Tabs
      className={classes.tabs}
      classes={{
        indicator: value < 0 ? null : classes.indicator
      }}
      orientation="vertical"
      variant="scrollable"
      scrollButtons="off"
      aria-label="Vertical tab"
      value={value < 0 ? false : value}
    >
      {filteredButtons.map((button, index) => (
        <Tab
          key={`verticalTab${index}`}
          className="sidebar"
          style={{ color: 'white' }}
          label={button.text}
          icon={button.img}
          value={index}
          onClick={() => {
            history.push(button.route);
            setValue(index);
          }}
        />
      ))}
    </Tabs>
  );
}

VerticalTabs.propTypes = {
  buttons: PropTypes.instanceOf(Array)
};

VerticalTabs.defaultProps = {
  buttons: []
};
