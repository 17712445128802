import { makeStyles } from '@material-ui/core/styles';
import { primaryColor, mainTextColor, borderTheme } from '../theme';
import FB_ORDER_STATUSES from '../../constants/order/orderStatuses';
import { SEX_CODES } from '../../components/shared/ChairItem';

const getChairColor = (gender) => {
  switch (gender) {
    case SEX_CODES.male:
      return primaryColor;
    case SEX_CODES.female:
      return '#e349d8';
    case 'sharedBy':
      return 'white';
    default:
      return mainTextColor;
  }
};
const styles = {
  courseNumberTypography: () => ({
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: 35,
    marginRight: 5,
    '&.selected': {
      color: primaryColor,
      '& p': { color: primaryColor },
      '&:hover': {
        color: mainTextColor,
        '& p': { color: mainTextColor }
      }
    }
  }),
  drinkCourse: () => ({
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: 35,
    marginRight: 5
  }),
  expansionPanel: () => ({
    borderRadius: '10px !important',
    flex: 2,
    border: borderTheme
  }),
  expansionPanelDetails: () => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px'
  }),
  expansionPanelSummary: () => ({
    height: 50,
    marginTop: 0,
    marginBottom: 0,
    padding: 10,
    '&.MuiExpansionPanelSummary-root.Mui-expanded': {
      minHeight: 0
    }
  }),

  expandArrow: () => ({
    color: primaryColor,
    fontSize: '-webkit-xxx-large'
  }),
  chairImg: (props) => ({
    height: 25,
    width: 15,
    '& path': { fill: getChairColor(props.gender) }
  }),
  sharedChairsContainer: () => ({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    textAlign: 'center',
    backgroundColor: primaryColor,
    borderRadius: 10,
    padding: '2px 10px 0px 10px',
    position: 'relative',
    marginBottom: 2
  }),
  sharedChairsGrid: () => ({
    marginRight: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:last-child': {
      marginRight: 0
    },
    '& svg': { height: 25, width: 15 },
    '& svg path': { fill: 'white' },
    '& p': { color: 'white' }
  }),
  centerpieceImg: () => ({
    height: 47,
    width: 35
  }),
  fbChairNumber: (props) => ({
    color: getChairColor(props.gender),
    fontWeight: 'bold',
    fontSize: 12
  }),
  fbMenuItemName: () => ({
    color: mainTextColor,
    fontSize: 16
  }),
  quantityValue: () => ({
    color: mainTextColor,
    fontSize: 9,
    textAlign: 'center',
    marginTop: '-3px',
    marginBottom: '3px'
  }),
  withOrWithoutContainer: () => ({
    paddingBottom: 5
  }),
  withOrWithoutItem: (props) => ({
    color: props.color,
    fontSize: 12,
    fontWeight: 'bold'
  }),
  editFbMenuItemButton: () => ({
    backgroundColor: primaryColor,
    color: 'white',
    borderRadius: 4,
    padding: 5,
    fontWeight: 'bold',
    fontSize: 10,
    height: 25
  }),
  optionsFbMenuItemButton: () => ({
    backgroundColor: primaryColor,
    color: 'white',
    borderRadius: 4,
    padding: 5,
    fontWeight: 'bold',
    fontSize: 10,
    height: 40,
    width: 80
  }),
  notesTypography: () => ({
    color: mainTextColor,
    borderRadius: 4,
    fontSize: 9
  }),
  itemName: () => ({
    fontSize: 12,
    color: mainTextColor,
    fontWeight: 'bold',
    paddingBottom: 5
  }),
  notesHeaderTitle: () => ({
    color: mainTextColor,
    fontSize: 9,
    fontWeight: 600,
    lineHeight: 1.2
  }),
  newCourseButton: () => ({
    borderRadius: 9,
    color: 'white',
    height: 30,
    margin: '11px 0'
  }),
  sendArrowImg: () => ({
    marginRight: 10
  }),
  fbCourseDrink: () => ({
    alignItems: 'baseline',
    fontSize: 47,
    fontWeight: 'bold',
    color: mainTextColor,
    height: 55,
    padding: '0 5px',
    '& p': { fontSize: 13, fontWeight: 'bold', color: '#55595C' },
    '&.selected': {
      color: primaryColor,
      '& p': { color: primaryColor },
      '&:hover': {
        color: mainTextColor,
        '& p': { color: mainTextColor }
      }
    },
    '&:hover': {
      color: primaryColor,
      '& p': { color: primaryColor }
    }
  }),
  statusIconActionsContainer: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      cursor: 'pointer'
    }
  }),
  statusIcon: (props) => ({
    cursor: 'pointer',
    fill: props && props.status && FB_ORDER_STATUSES[props.status].color,
    '& .filled': { fill: props && props.status && FB_ORDER_STATUSES[props.status].color }
  }),
  sharedChairsBox: () => ({ display: 'flex', flexDirection: 'column' })
};
export default makeStyles(styles);
