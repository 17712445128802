import React, { useContext } from 'react';
import Dashboard from '../components/dashboard/Dashboard';
import DashboardHeader from '../components/shared/Header';
import LanguageSwitcher from '../components/shared/LanguageSwitcher';
import HeaderMenuContent from '../components/shared/HeaderMenuContent';
import { LanguageContext, languageConfig } from '../contexts/LanguageContext';

const DashboardPage = () => {
  const languageContext = useContext(LanguageContext);

  return (
    <>
      <DashboardHeader
        menuContent={<HeaderMenuContent />}
        iconLanguageSelected={languageConfig[languageContext.language]?.icon}
        languageSelected={languageConfig[languageContext.language]?.languageLabel}
        languageSwitcher={<LanguageSwitcher />}
      />
      <Dashboard />
    </>
  );
};

export default DashboardPage;
