import gql from 'graphql-tag';
import {
  FB_CHAIR_FRAGMENT,
  FB_RESERVATION_FRAGMENT,
  GUEST_FRAGMENT,
  PACKAGES_FRAGMENT,
  FB_OUTLETS_FRAGMENT,
  PROFILE_PHYSICAL_CATEGORIES_FRAGMENT
} from '../fragments';

export const GET_GUEST_PHYSICAL_CATEGORIES = gql`
  query getGuestPhysicalCategories {
    getGuestPhysicalCategories {
      totalCount
      data {
        id
        uuid
        name
        description
        ageRange
        hasAgeInput
        icon
      }
    }
  }
`;

export const GET_FB_RESERVATIONS = gql`
  query getFbReservations(
    $fbOutletUUID: String
    $page: Int
    $pageSize: Int
    $orderBy: String
    $orderType: String
    $date: String
    $toDate: String
    $keyword: String
    $fbServiceUUID: String
    $status: FbReservationStatuses
  ) {
    getFbReservations(
      fbOutletUUID: $fbOutletUUID
      page: $page
      pageSize: $pageSize
      orderBy: $orderBy
      orderType: $orderType
      date: $date
      toDate: $toDate
      keyword: $keyword
      fbServiceUUID: $fbServiceUUID
      status: $status
    ) {
      totalCount
      data {
        ...fbReservationFragment
      }
    }
  }
  ${FB_RESERVATION_FRAGMENT}
`;

export const GET_FB_RESERVATION = gql`
  query getFbReservation($fbReservationUUID: String!) {
    getFbReservation(fbReservationUUID: $fbReservationUUID) {
      ...fbReservationFragment
    }
  }
  ${FB_RESERVATION_FRAGMENT}
`;

export const GET_HISTORICAL_RESERVATIONS = gql`
  query getFbHistoricalReservations(
    $fbOutletUUID: String!
    $page: Int
    $pageSize: Int
    $orderBy: String
    $orderType: String
    $date: String
    $keyword: String
    $fbServiceUUID: String
    $status: FbReservationStatuses
  ) {
    getFbHistoricalReservations(
      fbOutletUUID: $fbOutletUUID
      page: $page
      pageSize: $pageSize
      orderBy: $orderBy
      orderType: $orderType
      date: $date
      keyword: $keyword
      fbServiceUUID: $fbServiceUUID
      status: $status
    ) {
      totalCount
      data {
        ...guestFragment
      }
    }
  }
  ${GUEST_FRAGMENT}
`;

export const GET_HISTORICAL_TABLES = gql`
  query getFbHistoricalTables(
    $fbOutletUUID: String!
    $page: Int
    $pageSize: Int
    $orderBy: String
    $orderType: String
    $date: String
    $time: String
    $keyword: String
  ) {
    getFbHistoricalTables(
      fbOutletUUID: $fbOutletUUID
      page: $page
      pageSize: $pageSize
      orderBy: $orderBy
      orderType: $orderType
      date: $date
      time: $time
      keyword: $keyword
    ) {
      totalCount
      data {
        ...guestFragment
      }
    }
  }
  ${FB_CHAIR_FRAGMENT}
  ${GUEST_FRAGMENT}
`;
export const GET_AVAILABLE_SLOTS = gql`
  query getAvailableSlots($fbOutletUUID: String!, $date: String!, $reservationTime: String!, $fbGuestList: [FbGuestListDtoInput!]!) {
    getAvailableSlots(fbOutletUUID: $fbOutletUUID, date: $date, reservationTime: $reservationTime, fbGuestList: $fbGuestList) {
      fbServiceUUID
      availableSlots {
        time
        isAvailable
        overbooking
      }
    }
  }
`;

export const GET_PACKAGES = gql`
  query getPackages($page: Int, $pageSize: Int) {
    getPackages(page: $page, pageSize: $pageSize) {
      totalCount
      data {
        ...packagesFragment
      }
    }
  }
  ${PACKAGES_FRAGMENT}
`;

export const GET_FB_RESERVATIONS_SUMMARY = gql`
  query getFbReservationsSummary($date: CustomDate!, $toDate: CustomDate!) {
    getFbReservationsSummary(date: $date, toDate: $toDate) {
      outlet {
        ...fbOutletsFragment
      }
      fbServices {
        totalPax
        fbService {
          ...fbServicesFragment
        }
        profilePhysicalCategories {
          totalPax
          profilePhysicalCategory {
            ...profilePhysicalCategoriesFragment
          }
        }
      }
    }
  }
  ${FB_OUTLETS_FRAGMENT}
  ${PROFILE_PHYSICAL_CATEGORIES_FRAGMENT}
`;

export const GET_ROOMS = gql`
  query getRooms($orderBy: String, $orderType: String, $page: Int, $pageSize: Int) {
    getRooms(orderBy: $orderBy, orderType: $orderType, page: $page, pageSize: $pageSize) {
      totalCount
      data {
        id
        uuid
        name
        number
      }
    }
  }
`;
