import React from 'react';

import { Input } from '@material-ui/core/';
import ecStyles from '../../styles/saveReservation/AvailabilityStyle';

const CustomInput = (props) => {
  const classesAvailability = ecStyles();

  return (
    <Input
      label={props.label}
      disableUnderline
      onClick={props.onClick}
      onChange={props.onChange}
      value={props.value || ''}
      id={props.id}
      className={classesAvailability.textFieldAge}
      placeholder={props.placeholder}
    />
  );
};

export default CustomInput;
