import { makeStyles } from '@material-ui/core/styles';

const styles = {
  container: ({ top, left }) => ({
    position: 'fixed',
    top,
    left,
    backgroundColor: 'white',
    borderRadius: 4,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: 10,
    overflow: 'auto',
    boxShadow: '3px 3px 3px #DFDFDF'
  }),
  item: {
    paddingLeft: 0,
    paddingRight: 0,
    '& a': {
      textDecoration: 'none',
      color: '#55595C',
      width: '100%',
      height: '100%',
      padding: 5
    },
    '& span': {
      textDecoration: 'none',
      color: '#55595C',
      width: '100%',
      height: '100%',
      padding: 5
    },
    fontSize: 12,
    '&:hover': {
      backgroundColor: '#EDFAFF'
    },
    transition: 'background-color 0.3s'
  }
};

export default makeStyles(styles);
