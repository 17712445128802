import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { CircularProgress } from '@material-ui/core';
import MultiSelect from '../../../shared/MultiSelect';
import Buttons from '../../../shared/Buttons';
import { redButton } from '../../../../styles/theme';
import InputWithLabel from '../../../shared/InputWithLabel';
import styles from '../../../../styles/settings/tabs';
import { capitalizeFirstLetter, translate } from '../../../../translator';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { Dropdown } from '../../../../common-fe/src';
import useCustomQuery from '../../../../hooks/useCustomQuery';
import { GET_LANGUAGES } from '../../../../graphql/languages/queries';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  width: 100%;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

const chipParams = {
  variant: 'default',
  color: 'primary',
  style: { color: 'white' }
};

const PersonalTraitsForm = ({ data, formValues, onFormValuesChange, tags, loading, saveProfileData }) => {
  const { language } = useContext(LanguageContext);

  const classes = styles();

  // Queries
  const getLanguages = useCustomQuery(GET_LANGUAGES);

  const getLabelsAndValues = (value, translation = true) => {
    if (value && value.length > 0) {
      if (translation) {
        return typeof value === 'string'
          ? { label: capitalizeFirstLetter(translate(value, language)), value }
          : value.map((item) => ({ label: capitalizeFirstLetter(translate(item, language)), value: item }));
      }
      return typeof value === 'string' ? { label: value } : value.map((item) => ({ label: item, value: item }));
    }
    return null;
  };

  const { values, isSubmitting, handleSubmit, handleReset, handleChange, setFieldValue, getFieldProps } = useFormik({
    enableReinitialize: true,
    initialValues: {
      employment: formValues?.employment || data?.profileInfo?.employment || null,
      languageCode: formValues?.languageCode || data?.language?.ISO639_1 || null,
      spokenLanguageCodes:
        formValues?.spokenLanguages ||
        data?.spokenLanguages?.map((sl) => ({
          label: getLanguages.data?.getLanguages?.data?.find((l) => l.ISO639_1 === sl.ISO639_1)?.name ?? '',
          value: sl.ISO639_1
        })) ||
        [],
      characterInfo: formValues?.characterInfo || getLabelsAndValues(data?.profileInfo?.characterInfo) || [],
      foodRestrictions: formValues?.foodRestrictions || getLabelsAndValues(data?.fbProfilePreferences?.foodRestrictions) || [],
      allergens: formValues?.allergens || getLabelsAndValues(data?.fbProfilePreferences?.allergens) || [],
      intolerances: formValues?.intolerances || getLabelsAndValues(data?.fbProfilePreferences?.intolerances) || [],
      otherPersonalInfo: formValues?.otherPersonalInfo || getLabelsAndValues(data?.profileInfo?.otherPersonalInfo) || [],
      physicalLimitations: formValues?.physicalLimitations || getLabelsAndValues(data?.profileInfo?.physicalLimitations) || []
    },
    onSubmit: (_, { setSubmitting }) => {
      saveProfileData(formValues);
      setSubmitting(false);
    }
  });

  useEffect(() => {
    onFormValuesChange({ ...getFieldProps().value });
  }, [values]);

  const getTags = (fieldName) => {
    return (
      tags
        .find((tag) => tag.fieldName === fieldName)
        ?.tags?.map((tag) => ({ label: capitalizeFirstLetter(translate(tag?.toLowerCase(), language)), value: tag })) ?? []
    );
  };

  return (
    <Container>
      <RowContainer>
        <InputWithLabel label="employment">
          <Dropdown
            name="employment"
            items={getTags('profileInfo.employment')}
            value={values.employment}
            dropdownStyle={{ width: '100%', height: 40 }}
            onChange={handleChange}
          />
        </InputWithLabel>
        <InputWithLabel label="mother-tongue">
          <Dropdown
            name="languageCode"
            items={
              getLanguages.data?.getLanguages?.data?.map((language) => ({
                label: language.name,
                value: language.ISO639_1
              })) ?? []
            }
            value={values.languageCode}
            dropdownStyle={{ width: '100%', height: 40 }}
            onChange={handleChange}
          />
        </InputWithLabel>
        <InputWithLabel label="spoken-language">
          <MultiSelect
            className={classes.dropdown}
            freeSolo
            loading={loading}
            loadingText={<CircularProgress />}
            options={getLanguages.data?.getLanguages?.data
              ?.filter((lang) => lang.ISO639_1 !== values.languageCode)
              ?.map((language) => ({ label: language.name, value: language.ISO639_1 }))}
            chipParams={chipParams}
            value={values.spokenLanguageCodes}
            getOptionSelected={(option, { value }) => option.value === value}
            onChange={(e, value) => {
              const languages = [];
              value.forEach((l) => {
                if (typeof l === 'string') {
                  const label = getLanguages.data?.getLanguages?.data?.find((lang) => lang.ISO639_1 === l)?.name ?? '';
                  languages.push({ label, value });
                } else {
                  languages.push(l);
                }
              });
              setFieldValue('spokenLanguageCodes', languages);
            }}
          />
        </InputWithLabel>
      </RowContainer>
      <RowContainer>
        <InputWithLabel label="character">
          <MultiSelect
            className={classes.dropdown}
            loading={loading}
            loadingText={<CircularProgress />}
            options={getTags('profileInfo.characterInfo')}
            chipParams={chipParams}
            value={values.characterInfo}
            getOptionSelected={(option, { value }) => option.value === value}
            onChange={(e, value) => setFieldValue('characterInfo', value)}
          />
        </InputWithLabel>
        <InputWithLabel label="food-restrictions">
          <MultiSelect
            className={classes.dropdown}
            loading={loading}
            loadingText={<CircularProgress />}
            options={getTags('fbProfilePreferences.foodRestrictions')}
            chipParams={chipParams}
            value={values.foodRestrictions}
            getOptionSelected={(option, { value }) => option.value === value}
            onChange={(e, value) => setFieldValue('foodRestrictions', value)}
          />
        </InputWithLabel>
      </RowContainer>
      <RowContainer>
        <InputWithLabel label="allergens">
          <MultiSelect
            className={classes.dropdown}
            loading={loading}
            loadingText={<CircularProgress />}
            freeSolo={tags?.find((tag) => tag.fieldName === 'fbProfilePreferences.allergens')?.isFreeTagAllowed}
            options={getTags('fbProfilePreferences.allergens')}
            chipParams={chipParams}
            value={values.allergens}
            getOptionSelected={(option, { value }) => option.value === value}
            onChange={(e, value) => {
              const newValues = [];
              value.forEach((v) => {
                if (typeof v === 'string') {
                  newValues.push({ label: v, value: v });
                } else newValues.push(v);
              });
              setFieldValue('allergens', newValues);
            }}
          />
        </InputWithLabel>
        <InputWithLabel label="intolerances">
          <MultiSelect
            className={classes.dropdown}
            loading={loading}
            loadingText={<CircularProgress />}
            freeSolo={tags?.find((tag) => tag.fieldName === 'fbProfilePreferences.intolerances')?.isFreeTagAllowed}
            options={getTags('fbProfilePreferences.intolerances')}
            chipParams={chipParams}
            value={values.intolerances}
            getOptionSelected={(option, { value }) => option.value === value}
            onChange={(e, value) => {
              const newValues = [];
              value.forEach((v) => {
                if (typeof v === 'string') {
                  newValues.push({ label: v, value: v });
                } else newValues.push(v);
              });
              setFieldValue('intolerances', newValues);
            }}
          />
        </InputWithLabel>
      </RowContainer>
      <RowContainer>
        <InputWithLabel label="other-personal-characteristics">
          <MultiSelect
            className={classes.dropdown}
            loading={loading}
            loadingText={<CircularProgress />}
            options={getTags('profileInfo.otherPersonalInfo')}
            chipParams={chipParams}
            value={values.otherPersonalInfo}
            getOptionSelected={(option, { value }) => option.value === value}
            onChange={(e, value) => setFieldValue('otherPersonalInfo', value)}
          />
        </InputWithLabel>
        <InputWithLabel label="physical-impediment-disabilities">
          <MultiSelect
            className={classes.dropdown}
            loading={loading}
            loadingText={<CircularProgress />}
            options={getTags('profileInfo.physicalLimitations')}
            chipParams={chipParams}
            value={values.physicalLimitations}
            getOptionSelected={(option, { value }) => option.value === value}
            onChange={(e, value) => setFieldValue('physicalLimitations', value)}
          />
        </InputWithLabel>
      </RowContainer>
      <Buttons
        buttons={[
          {
            buttonType: 'button',
            type: 'cancel',
            label: 'delete',
            style: {
              padding: 0,
              marginLeft: 80,
              backgroundColor: 'transparent',
              border: 'none',
              color: redButton,
              textDecoration: 'underline',
              minHeight: 50,
              fontSize: 20,
              fontWeight: 'medium',
              textTransform: 'uppercase'
            },
            onClick: handleReset
          },
          {
            buttonType: 'button',
            type: 'confirm',
            label: 'confirm',
            disabled: isSubmitting,
            style: { minHeight: 50, minWidth: 227, fontSize: 20, fontWeight: 'medium', textTransform: 'uppercase' },
            onClick: handleSubmit
          }
        ]}
      />
    </Container>
  );
};

export default PersonalTraitsForm;
