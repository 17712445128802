import React from 'react';
import style from '../../styles/roomEditor/roomHeader/style';

const Input = ({ name, placeholder, value, error, onChange, onBlur, width }) => {
  const classes = style({ error, width });

  return <input name={name} value={value} onChange={onChange} onBlur={onBlur} className={classes.input} type="text" placeholder={placeholder} />;
};

export default Input;
