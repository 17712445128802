/* eslint-disable prefer-destructuring */
import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core/';
import styles from '../../../styles/kitchen/fbCourse';

export const SharedBy = ({ item }) => {
  const classes = styles();

  return (
    <Grid container style={{ paddingLeft: 3, paddingRight: 7 }}>
      {item.sharedBy &&
        item.sharedBy.map((num, i) => (
          <Box
            key={i}
            className={classes.detailedCourseIcon}
            style={{
              marginRight: 5
            }}
          >
            <Typography className={classes.detailedCourseText}>{num}</Typography>
          </Box>
        ))}
    </Grid>
  );
};

export const ChairOrHalfPortion = ({ item }) => {
  const classes = styles();
  return (
    <Grid style={{ paddingLeft: 3, paddingRight: 7 }}>
      <Box className={classes.detailedCourseIcon}>
        <Typography className={classes.detailedCourseText}>{item?.fbChair?.number}</Typography>
      </Box>
    </Grid>
  );
};

export const Centerpiece = () => {
  const classes = styles();

  return (
    <Grid style={{ paddingLeft: 3, paddingRight: 7 }}>
      <Box className={classes.detailedCourseIcon}>
        <Typography className={classes.detailedCourseText}>CT</Typography>
      </Box>
    </Grid>
  );
};
