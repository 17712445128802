import React, { useState, createContext } from 'react';
import axios from 'axios';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const AuthContext = createContext();

const userData = localStorage.getItem('user');
const storedAppOutletUUID = localStorage.getItem('appOutletUUID') || null;

export const AuthProvider = (props) => {
  const [user, setUser] = useState(userData ? JSON.parse(userData) : null);
  const [appOutletUUID, setAppOutletUUID] = useState(storedAppOutletUUID);
  const [errorMessage, setErrorMessage] = useState(null);
  const isLoggedIn = () => user !== null && user.access_token !== null;

  const handleSetAppOutletUUID = (outletUUID) => {
    setAppOutletUUID(outletUUID);
    localStorage.setItem('appOutletUUID', outletUUID);
  };

  const login = (username, password) => {
    axios
      .post(`${SERVER_URL}/graphql`, {
        operationName: null,
        variable: {},
        query: `{
          login(loginInput: {
            email: "${username}"
            password: "${password}"
          }) {
            access_token
            username
            id
            email
            outlets {
              id
              uuid
              name
              fiscalPrinterIpAddress
            }
            roles {
              uuid
              name
            }
          }
        }`
      })
      .then((res) => {
        const response = res && res.data;
        if (response.data?.login?.access_token) {
          setErrorMessage(null);
          const firstOutletUUID = response.data.login?.outlets?.[0]?.uuid ?? null;
          localStorage.setItem('user', JSON.stringify(response.data.login));
          localStorage.setItem('appOutletUUID', firstOutletUUID);
          setUser(response.data.login);
          handleSetAppOutletUUID(firstOutletUUID);
        } else if (response?.errors) {
          setErrorMessage(response.errors);
        }
      })
      .catch((error) => {
        console.log('auth error message', error.response.data);
        console.log('auth error message', error.response.data.error);
        setErrorMessage(error.response.data);
      });
  };

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('appOutletUUID');
    localStorage.removeItem('appOutlet');
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        appOutletUUID,
        login,
        logout,
        handleSetAppOutletUUID,
        isLoggedIn,
        errorMessage
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;

export const withAuthContext = (Component) => (props) => (
  <AuthConsumer>{(providerProps) => <Component {...props} sessionContextProps={providerProps} />}</AuthConsumer>
);
