import React, { useRef } from 'react';
import { Box, Button, Fade, Grid, Modal, Paper, Typography } from '@material-ui/core/';
import _ from 'lodash';
import Spinner from 'react-loader-spinner';
import ReactToPrint from 'react-to-print';
import typy from 'typy';
import { groupedByMenuItem } from '../../constants/order/order';
import { GET_FB_ORDER } from '../../graphql/order/queries';
import receiptStyle from '../../styles/shared/receiptStyle';
import { capitalizeFirstLetter, translate, TranslateTextComponent } from '../../translator';
import useCustomQuery from '../../hooks/useCustomQuery';

const Receipt = ({ isOpen, handleClose, fbOrderUUID }) => {
  const classes = receiptStyle();
  const customBackground = receiptStyle({ backgroundColor: 'white' });
  const customButton = receiptStyle({ backgroundColor: '#C3BEB8', color: 'white' });
  const componentRef = useRef();

  const getFbOrderResponse = useCustomQuery(GET_FB_ORDER, {
    variables: {
      fbOrderUUID
    },
    skip: !fbOrderUUID,
    fetchPolicy: 'network-only'
  });

  const getFbOrder = typy(getFbOrderResponse, 'data.getFbOrder').safeObject;
  const fbCourses = typy(getFbOrder, 'fbCourses').safeObject;
  const fbReservation = typy(getFbOrder, 'fbReservationTable.fbReservation').safeObject;
  const bookedBy = typy(fbReservation, 'bookedBy').safeObject;
  const fbTable = typy(getFbOrder, 'fbReservationTable.fbTable').safeObject;

  let allFoodItems = [];
  let allDrinkItems = [];
  const partialReceipt = [];

  const fbCoursesDrink = fbCourses && fbCourses.filter((fbCourse) => fbCourse.type === 'drink');
  const fbCoursesFood = fbCourses && fbCourses.filter((fbCourse) => fbCourse.type === 'food');

  if (fbOrderUUID && fbCoursesFood && fbCoursesFood.length) {
    fbCoursesFood.forEach((fbCourse) => {
      allFoodItems = [...allFoodItems, ...fbCourse.fbCoursesMenuItems];
    });
  }

  if (fbCoursesDrink && fbCoursesDrink.length) {
    fbCoursesDrink.forEach((fbDrink) => {
      allDrinkItems = [...allDrinkItems, ...fbDrink.fbCoursesMenuItems];
    });
  }

  const courseGroupedByType = _.groupBy(fbCourses && fbCourses, (item) => item.type);
  const allGroupedFoodItems = _.groupBy(allFoodItems, (item) => item.fbMenuItemId);
  const groupedFbDrinkItems = groupedByMenuItem(allDrinkItems);

  return (
    <Box>
      <Modal open={isOpen} onClose={handleClose} style={{ opacity: 1, backdropFilter: 'blur(10px)', height: 'auto' }}>
        <Fade in className={classes.fadeContainer}>
          <Paper style={{ paddingTop: 0 }}>
            <Box ref={componentRef}>
              <Grid container className={customBackground.bookedByContainer} style={{ borderBottom: '1px solid #55595C' }}>
                <Grid item xs={4} style={{ paddingTop: 7, paddingBottom: 3 }}>
                  <Typography className={classes.typographyClientData}>
                    {bookedBy ? capitalizeFirstLetter(`${bookedBy.firstname}`) : null}{' '}
                    {' ' && bookedBy ? capitalizeFirstLetter(`${bookedBy.lastname}`) : null}
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{ paddingTop: 7, paddingBottom: 3, paddingRight: 23 }}>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <Typography className={classes.typographyClientData}>
                        <TranslateTextComponent capitalize>n-pax</TranslateTextComponent>:{' '}
                        {fbReservation ? capitalizeFirstLetter(`${fbReservation.nPax}`) : null}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.typographyClientData}>
                        <TranslateTextComponent capitalize>t-numb</TranslateTextComponent>:{' '}
                        {fbTable ? capitalizeFirstLetter(`${fbTable.number}`) : null}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item style={{ paddingBottom: 3, textAlign: 'end' }}>
                    <Typography className={classes.typographyClientData}>
                      <TranslateTextComponent capitalize>reservation-time</TranslateTextComponent>:{' '}
                      {fbReservation ? capitalizeFirstLetter(`${fbReservation.reservationTime}`) : null}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {getFbOrderResponse.loading ? (
                <Box style={{ display: 'flex', justifyContent: 'center', border: 'none', margin: 'auto', padding: 50 }}>
                  <Spinner type="TailSpin" color="white" height={100} width={100} />
                </Box>
              ) : (
                <Box className={classes.foodAndDrinks}>
                  <Grid container>
                    <Grid item xs={12} className={classes.categoryTypography}>
                      {translate(capitalizeFirstLetter('food'))}
                    </Grid>
                    {Object.keys(allGroupedFoodItems).map((key, index) => {
                      const groupedFbMenuItems = groupedByMenuItem(allGroupedFoodItems[key]);
                      return (
                        <Grid container key={index}>
                          <Grid item xs={12} className={classes.dishes}>
                            {Object.keys(groupedFbMenuItems).map((groupedItemKey, keyGrid) => {
                              const singlePrice = groupedFbMenuItems[groupedItemKey][0].price;
                              const sum = _.sumBy(groupedFbMenuItems[groupedItemKey], (item) => item.quantity);
                              const dishPrice = sum * groupedFbMenuItems[groupedItemKey][0].price;
                              partialReceipt.push(dishPrice);

                              return (
                                <Grid container justify="space-between" key={keyGrid}>
                                  <Grid item className={classes.gridQuantity}>
                                    {sum} x € {singlePrice.toFixed(2)}
                                    <Grid item style={{ marginLeft: 15, fontSize: 14 }}>
                                      {groupedFbMenuItems?.[groupedItemKey]?.[0]?.name.length > 44
                                        ? translate(capitalizeFirstLetter(groupedFbMenuItems[groupedItemKey][0].name.substring(0, 44) ?? ''))
                                        : translate(capitalizeFirstLetter(groupedFbMenuItems[groupedItemKey][0].name ?? ''))}{' '}
                                      {groupedFbMenuItems &&
                                      groupedFbMenuItems[groupedItemKey][0] &&
                                      groupedFbMenuItems[groupedItemKey][0].with.length !== 0 ? (
                                        <Grid container>
                                          <Grid item>
                                            <Typography className={classes.withAndWithout}>
                                              <TranslateTextComponent uppercase>with</TranslateTextComponent>:
                                            </Typography>
                                          </Grid>
                                          <Grid item>
                                            <Typography className={classes.withAndWithout}>
                                              {translate(capitalizeFirstLetter(groupedFbMenuItems[groupedItemKey][0].with.join(', ')))}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ) : null}
                                      {groupedFbMenuItems &&
                                      groupedFbMenuItems[groupedItemKey][0] &&
                                      groupedFbMenuItems[groupedItemKey][0].without.length !== 0 ? (
                                        <Grid container>
                                          <Grid item>
                                            <Typography className={classes.withAndWithout}>
                                              <TranslateTextComponent uppercase>without</TranslateTextComponent>:
                                            </Typography>
                                          </Grid>
                                          <Grid item>
                                            <Typography className={classes.withAndWithout}>
                                              {translate(capitalizeFirstLetter(groupedFbMenuItems[groupedItemKey][0].without.join(', ')))}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ) : null}
                                    </Grid>
                                  </Grid>

                                  <Grid item xs={3}>
                                    <Typography className={classes.gridPrice}>€ {dishPrice ? dishPrice.toFixed(2) : null}</Typography>
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>

                  <Grid container style={{ paddingBottom: 20 }}>
                    <Grid item xs={12}>
                      {Object.keys(courseGroupedByType).map((key) => {
                        return key && key === 'drink' ? (
                          <Typography className={classes.categoryTypography} key="drink">
                            <TranslateTextComponent capitalize>{key}</TranslateTextComponent>
                          </Typography>
                        ) : null;
                      })}
                    </Grid>
                    <Grid container>
                      {Object.keys(groupedFbDrinkItems).map((groupedDrinkKey, keyDrinkGrid) => {
                        const singleDrink = groupedFbDrinkItems[groupedDrinkKey][0].price;
                        const sumDrink = _.sumBy(groupedFbDrinkItems[groupedDrinkKey], (item) => item.quantity);
                        const drinkPrice = sumDrink * groupedFbDrinkItems[groupedDrinkKey][0].price;

                        partialReceipt.push(drinkPrice);
                        return (
                          <Grid container justify="space-between" key={keyDrinkGrid}>
                            <Grid item className={classes.gridQuantity}>
                              {sumDrink} x € {singleDrink.toFixed(2)}
                              <Grid item style={{ marginLeft: 15, fontSize: 14 }}>
                                {groupedFbDrinkItems && groupedFbDrinkItems[groupedDrinkKey]
                                  ? translate(capitalizeFirstLetter(groupedFbDrinkItems[groupedDrinkKey][0].name))
                                  : null}{' '}
                                {groupedFbDrinkItems && groupedFbDrinkItems[groupedDrinkKey] ? groupedFbDrinkItems[groupedDrinkKey][0].with : null}
                                {groupedFbDrinkItems && groupedFbDrinkItems[groupedDrinkKey] ? groupedFbDrinkItems[groupedDrinkKey][0].without : null}
                              </Grid>
                            </Grid>

                            <Grid item xs={3}>
                              <Typography className={classes.gridPrice}>€ {drinkPrice ? drinkPrice.toFixed(2) : null} </Typography>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Box>
              )}
              <Box className={classes.boxSignature}>
                <Grid
                  container
                  className={customBackground.gridTotal}
                  style={{ borderTop: '1px solid #55595C', borderBottom: '1px solid #55595C' }}
                  justify="space-between"
                >
                  <Grid item className={classes.total}>
                    <TranslateTextComponent uppercase>total</TranslateTextComponent>:
                  </Grid>
                  <Grid item className={classes.total}>
                    <Typography style={{ fontSize: 20, color: '#55595C', fontWeight: 500 }}>
                      € {partialReceipt ? _.sum(partialReceipt).toFixed(2) : null}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Grid container justify="space-around" style={{ padding: 10 }}>
              <ReactToPrint
                trigger={() => (
                  <Button className={customButton.payButton}>
                    <TranslateTextComponent capitalize>print</TranslateTextComponent>
                  </Button>
                )}
                content={() => componentRef.current}
              />
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </Box>
  );
};

export default Receipt;
