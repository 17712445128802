import React from 'react';
import Layout from '../../../components/shared/Layout';
import Inventory from '../../../components/Moh/inventory/Inventory';

const MohInventoryPage = ({ history }) => {
  return (
    <Layout history={history} section="inventory">
      <Inventory />
    </Layout>
  );
};

export default MohInventoryPage;
