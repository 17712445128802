import React from 'react';
import { MenuItem, Select, Grid } from '@material-ui/core/';
import styles from '../../styles/shared/dropdown';

const Dropdown = ({
  name,
  value,
  error,
  errorLabel,
  displayEmpty,
  placeholder,
  renderValue,
  onChange,
  onBlur,
  items,
  disabled = false,
  dropdownStyle
}) => {
  const [open, setOpen] = React.useState(false);
  const classes = styles();

  function handleClose() {
    setOpen(false);
  }
  function handleOpen() {
    setOpen(true);
  }
  let dropdownStyleObj = {
    ...dropdownStyle
  };
  if (error) {
    dropdownStyleObj = {
      ...dropdownStyleObj,
      borderColor: 'red'
    };
  }

  return (
    <>
      <Select
        name={name}
        disabled={disabled}
        displayEmpty={displayEmpty}
        disableUnderline
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        onBlur={onBlur}
        value={value || ''}
        onChange={onChange}
        renderValue={renderValue}
        className={classes.selectValue}
        style={dropdownStyleObj}
        placeholder={placeholder}
      >
        {placeholder && <MenuItem value="">{placeholder}</MenuItem>}
        {items &&
          items.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <Grid container>
                {item.icon || null}
                {item.label ?? ''}
              </Grid>
            </MenuItem>
          ))}
      </Select>
      {error ? <span style={{ color: 'red', fontSize: 10 }}>{errorLabel}</span> : null}
    </>
  );
};

export default Dropdown;
