import { gql } from 'apollo-boost';
import { FB_MENU_ITEMS_FRAGMENT, MENU_ITEM_STOCK_FRAGMENT } from '../../fragments';

export const ADD_MENU_ITEM_STOCK = gql`
  mutation addMenuItemStock($quantity: Float!, $menuItemUUID: String!) {
    addMenuItemStock(quantity: $quantity, menuItemUUID: $menuItemUUID) {
      ...menuItemStockFragment
    }
  }
  ${MENU_ITEM_STOCK_FRAGMENT}
`;

export const SET_MENU_ITEM_STOCK_ENABLED = gql`
  mutation setMenuItemStockEnabled($isStockEnabled: Boolean!, $menuItemUUID: String!) {
    setMenuItemStockEnabled(isStockEnabled: $isStockEnabled, menuItemUUID: $menuItemUUID) {
      ...fbMenuItemsFragment
    }
  }
  ${FB_MENU_ITEMS_FRAGMENT}
`;
