import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: white;
  padding: 15px 15px 0px 15px;
  border-top-left-radius: 15px;
`;

const PageLayout = ({ children, style }) => {
  return <Container style={style}>{children}</Container>;
};

export default PageLayout;
