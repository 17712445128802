import { useContext } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '../../contexts/AuthContext';
import { ROLES } from '../../constants/users/usersRole';

const Guard = ({ roles, ignoreSuperAdmin, children }) => {
  const authContext = useContext(AuthContext);

  const isAllowed = () => {
    if (
      authContext?.user?.roles?.some(
        (userRole) =>
          roles.some((role) => role === userRole.name) ||
          (!ignoreSuperAdmin && userRole.name === ROLES.ROLE_SUPER_ADMIN) ||
          userRole.name === ROLES.ROLE_MANAGER
      )
    ) {
      return children;
    }
    /* YOU SHALL NOT PASS! 🧙 */
    return null;
  };

  return isAllowed();
};

Guard.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Guard;
