import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from '../../common-fe/src';

const styles = {
  leftContainer: {
    display: 'grid',
    gridTemplateAreas: '"services-dropdown" "guests-container" "options-container"',
    gridTemplateRows: 'auto auto auto',
    gridRowGap: 10,
    width: '100%'
  },
  rightContainer: {
    display: 'grid',
    gridTemplateAreas: '"tabs-container" "tables-container"',
    gridTemplateRows: 'auto 1fr',
    gridRowGap: 10,
    height: '100%'
  },
  buttonsContainer: {
    position: 'fixed',
    bottom: 25,
    right: 25,
    display: 'flex'
  },
  button: {
    margin: '0 10px'
  },
  buttonIcon: {
    marginRight: 10
  },
  // MessageBox
  gridMsgBox: {
    marginLeft: 10,
    marginTop: 10,
    position: 'absolute',
    top: 130,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 276,
    borderBottom: '4px solid orange',
    boxShadow: '0px 3px 6px #00000029',
    backgroundColor: 'white',
    zIndex: 10
  },
  secondGridMsgBox: {
    marginLeft: 10,
    marginTop: 10,
    position: 'absolute',
    top: 130,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: 277,
    borderBottom: `4px solid ${primaryColor}`,
    boxShadow: `0px 3px 6px  #00000029`
  },
  exclamationPoint: {
    paddingLeft: 12,
    fontSize: 30,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    color: '#F7A445'
  },
  SecExclamationPoint: {
    paddingLeft: 12,
    fontSize: 30,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    color: primaryColor
  },
  selectFirstTxt: {
    paddingLeft: 12,
    fontSize: 15,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    color: '#777777'
  },
  secondChair: {
    paddingLeft: 5,
    fontSize: 15,
    fontFamily: 'Roboto',
    color: '#777777',
    paddingRight: 12
  },
  panZoomButton: {
    backgroundColor: '#e0e0e0',
    color: 'black',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
  }
};

export default makeStyles(styles);
