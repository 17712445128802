import React from 'react';
import { Grid } from '@material-ui/core/';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import DashboardButton from './DashboardButton';
import styles from '../../styles/dashboard/dashboardStyle';
import buttons from '../../fixtures/dashboard/dashboard';
import { headerHeight } from '../../common-fe/src';
import Guard from '../shared/Guard';

const Container = styled.div`
  display: flex;
  height: ${() => `calc(100% - ${headerHeight}px)`};
  align-items: flex-start;
  margin: 0px 50px;
`;

const Home = () => {
  const classes = styles();

  return (
    <Container>
      <Grid container spacing={5} alignItems="center" className={classes.gridButtonContainer} style={{ marginTop: 20 }}>
        {buttons.map((button, index) => (
          <Guard key={index} roles={button.roles}>
            <Grid item md={6} xs={12} lg={4} style={{ marginBottom: 30, width: '100%' }}>
              <Link to={button.route}>
                <DashboardButton button={button} />
              </Link>
            </Grid>
          </Guard>
        ))}
      </Grid>
    </Container>
  );
};
export default Home;
