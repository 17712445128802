import React from 'react';
import { Box, Modal, Fade, Paper, Grid } from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Cancel';
import modalStyle from '../../styles/shared/customModalStyle';
import { primaryColor } from '../../styles/theme';

const CustomModal = ({
  showModal,
  header,
  children,
  footer,
  styledHeader,
  width = '50%',
  height,
  borderRadius = 10,
  hideCloseButton = false,
  styleModal,
  stylePaper,
  styleHeader,
  styleBody,
  styleFooter,
  onClose
}) => {
  const classes = modalStyle({ height, header });

  return (
    showModal && (
      <Modal className={classes.modalContainer} style={styleModal} open={showModal} onClose={onClose}>
        <Fade in style={{ width, borderRadius }}>
          <Paper className={classes.paperContainer} style={stylePaper}>
            {!hideCloseButton && (
              <Box className={classes.closeModalButton} onClick={onClose}>
                <CloseIcon style={{ fontSize: 40, color: primaryColor }} />
              </Box>
            )}
            {header && (
              <Grid className={[classes.headerContainer, styledHeader ? classes.styledHeader : ''].join(' ')} item style={styleHeader}>
                {header}
              </Grid>
            )}
            <Grid className={classes.childrenContainer} container style={styleBody}>
              {children}
            </Grid>
            {footer && (
              <Grid className={classes.footerContainer} item style={styleFooter}>
                {footer}
              </Grid>
            )}
          </Paper>
        </Fade>
      </Modal>
    )
  );
};

export default CustomModal;
