import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Box, Button, Grid, Typography } from '@material-ui/core/';
import typy from 'typy';
import { getApolloErrors } from '../../../apollo/ApolloProvider';
import leftSidebarLayoutStyles from '../../../common-fe/src/styles/shared/leftSidebarLayoutStyle';
import { ModalContext } from '../../../contexts/ModalContext';
import { ASSIGN_CHAIR } from '../../../graphql/tables/mutations';
import { GET_FB_TABLE } from '../../../graphql/tables/queries';
import { ROUTE_DASHBOARD } from '../../../router/routes';
import assignChairStyle from '../../../styles/table/tableDetail/assignChair';
import styleTable from '../../../styles/viewTables/tables/style';
import { TranslateTextComponent } from '../../../translator';
import TableItem from '../../ViewTables/Tables/TableItem';
import GuestReservationSidebar from './GuestReservationSidebar';
import RoomBox from './RoomBox';
import useCustomQuery from '../../../hooks/useCustomQuery';

const AssignChair = ({ fbTableUUID, history }) => {
  const layoutClasses = leftSidebarLayoutStyles();
  const classes = assignChairStyle();
  const classMsg = styleTable();

  const modalContext = useContext(ModalContext);

  const [showListenerRoom, setShowListenerRoom] = useState(false);
  const [chairClicked, setChairClicked] = useState(false);
  const [isFreeChair, setIsFreeChair] = useState(false);

  const [fbReservationTable, setFbReservationTable] = useState([]);

  const tableResponse = useCustomQuery(GET_FB_TABLE, {
    variables: {
      fbTableUUID
    },
    fetchPolicy: 'network-only',
    onCompleted: (tableRes) => {
      const fbReservationTables = typy(tableRes, 'getFbTable.fbReservationTables').safeArray;
      if (tableRes && fbReservationTables.length === 0) {
        modalContext.openModal({
          class: 'danger',
          title: 'Error',
          text: 'Table or reservation not found.',
          actionButtons: [
            <Button
              key={1}
              color="primary"
              variant="contained"
              style={{ color: 'white' }}
              onClick={() => {
                modalContext.closeModal();
                history.push(ROUTE_DASHBOARD);
              }}
            >
              <TranslateTextComponent>ok</TranslateTextComponent>
            </Button>
          ]
        });
      } else {
        setFbReservationTable(fbReservationTables[0]);
      }
    },
    notifyOnNetworkStatusChange: true
  });

  const [assignChair] = useMutation(ASSIGN_CHAIR);

  const tableData = typy(tableResponse, 'data.getFbTable').safeObject;
  const reservationData = typy(tableData, 'fbReservationTables[0].fbReservation').safeObject;

  const handleChairClick = (chair) => {
    if (showListenerRoom === true) {
      setShowListenerRoom(false);
    } else if (isFreeChair) {
      setShowListenerRoom(false);
      setChairClicked(chair);
    } else {
      setShowListenerRoom(true);
      setChairClicked(chair);
    }
  };

  const onClickListener = () => {
    if (showListenerRoom === true) {
      setShowListenerRoom(false);
    } else {
      setShowListenerRoom(true);
    }
  };

  const handleAssignChair = (guestFbId, guestGender, guestPhysicalCategoryUUID) => {
    if (guestFbId != null) {
      assignChair({
        variables: { guestPhysicalCategoryUUID: null, fbChairUUID: chairClicked.uuid, gender: guestGender, guestUUID: guestFbId }
      })
        .then(() => {
          refetchTable();
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        });
      setShowListenerRoom(false);
      setChairClicked(false);
    } else {
      assignChair({
        variables: { guestPhysicalCategoryUUID, fbChairUUID: chairClicked.uuid, gender: guestGender, guestUUID: null }
      })
        .then(() => {
          refetchTable();
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        });
      setShowListenerRoom(false);
      setChairClicked(false);
    }
  };

  const refetchTable = () => {
    tableResponse.refetch();
  };

  const getTableScale = () => {
    if (tableData.shape === 'round' || tableData.shape === 'square') {
      if (tableData.capacity >= 6) {
        return 1.4;
      }
      return 1.7;
    }

    if (tableData.shape === 'rectangle') {
      if (tableData.capacity <= 5) {
        return 1.9;
      }
      if (tableData.capacity <= 10) {
        return 1.6;
      }
      if (tableData.capacity > 10) {
        return 1.4;
      }
    }
    return null;
  };
  const chairUuid = chairClicked && chairClicked.uuid;

  const freeChair = () => {
    if ((chairUuid !== false && chairClicked.guestFbReservation !== null) || (chairUuid !== false && chairClicked.guest !== null)) {
      assignChair({
        variables: { guestPhysicalCategoryUUID: null, fbChairUUID: chairUuid, gender: null, guestUUID: null }
      })
        .then(() => {
          refetchTable();
          modalContext.openModal({
            class: 'success',
            title: <TranslateTextComponent capitalize>free-chair</TranslateTextComponent>,
            text: <TranslateTextComponent capitalize>free-chairOk</TranslateTextComponent>
          });
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        });
      setIsFreeChair(false);
      setChairClicked(false);
      setShowListenerRoom(false);
    }
  };

  return (
    <Box className={layoutClasses.layoutContainer}>
      <Box className={layoutClasses.leftSide}>
        <GuestReservationSidebar
          reservationTable={fbReservationTable}
          history={history}
          fbTableUUID={fbTableUUID}
          tableData={tableData}
          chairClicked={chairClicked}
          assignChair={assignChair}
          refetchTable={refetchTable}
          setIsFreeChair={setIsFreeChair}
          isFreeChair={isFreeChair}
          setChairClicked={setChairClicked}
          freeChair={freeChair}
        />
      </Box>
      <Box className={layoutClasses.rightSide} style={{ display: 'flex', justifyContent: 'center', marginTop: 10, overflow: 'auto' }}>
        <Box className={classes.boxContainer}>
          {tableData && (
            <TableItem
              isGuestNameShowed
              table={tableData}
              selectedTableId={tableData && tableData.id}
              scaleSize={getTableScale()}
              onChairClick={(e) => handleChairClick(e)}
              containerStyle={{ position: 'absolute', left: 20, right: 0, top: '20%', margin: 'auto' }}
            />
          )}
        </Box>
        {isFreeChair && (
          <Grid container className={classMsg.secondGridMsgBox}>
            <Typography className={classMsg.SecExclamationPoint}>!</Typography>
            <Typography className={classMsg.secondChair}>
              <TranslateTextComponent capitalize>free-chair-msg</TranslateTextComponent>
            </Typography>
          </Grid>
        )}
        {showListenerRoom && (
          <Grid
            container
            style={{
              left: 0,
              right: 0,
              top: '30%',
              margin: 'auto',
              zIndex: 100,
              position: 'absolute',
              width: 150
            }}
          >
            <Grid item xs>
              <RoomBox
                key="roomBox"
                reservationData={reservationData}
                handleChairClick={handleChairClick}
                setShowListenerRoom={setShowListenerRoom}
                refetchTable={refetchTable}
                onClickListener={onClickListener}
                handleAssignChair={handleAssignChair}
                chairClicked={chairClicked}
                setIsFreeChair={setIsFreeChair}
                freeChair={freeChair}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default AssignChair;
