import React from 'react';
import Layout from '../components/shared/Layout';
import OutletList from '../components/outlets/OutletList';

const OutletListPage = ({ history }) => {
  return (
    <Layout history={history} section="outlets">
      <OutletList />
    </Layout>
  );
};

export default OutletListPage;
