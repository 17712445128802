import React from 'react';
import { Box } from '@material-ui/core';
import moment from 'moment';
import typy from 'typy';
import styles from '../../../../../styles/billsManagement/wideCard';
import SummaryRowDetails from './SummaryRowDetails';

const SummaryWithBorder = ({ data, summaryType }) => {
  const classes = styles();

  return (
    <Box className={classes.summaryWithoutBorderContainer}>
      <Box className={classes.summaryWithoutBorderRow}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box style={{ marginLeft: 10 }}>
            <span>{moment(data.date).format('DD/MM/YYYY') || ''}</span>
          </Box>
        </Box>
        <Box>
          <span>
            €{' '}
            {(data.total &&
              typy(data, 'total')
                .safeNumber.toFixed(2)
                .replace('.', ',')) ||
              typy(data, 'totalAmount')
                .safeNumber.toFixed(2)
                .replace('.', ',')}
          </span>
        </Box>
      </Box>
      <SummaryRowDetails data={data} type={summaryType} rowNumber={1} />
      <hr style={{ width: '100%', border: `1px solid #91C9E8` }} />
      <SummaryRowDetails data={data} type={summaryType} rowNumber={2} />
    </Box>
  );
};

export default SummaryWithBorder;
