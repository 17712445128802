import React from 'react';
import styled from 'styled-components/macro';
import { TranslateTextComponent } from '../../../translator';
import { mainTextColor } from '../../../styles/theme';

const Wrapper = styled.div`
  display: flex;
  flex-flow: ${({ labelsOnTop }) => (labelsOnTop ? 'column' : 'row')};
  align-items: center;
  height: 100%;
  flex: ${({ labelsOnTop }) => (labelsOnTop ? '1' : 'auto')};
`;

const Title = styled(TranslateTextComponent).attrs({ capitalize: true })`
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: bold;
  color: ${() => mainTextColor};
  margin: ${({ labelsOnTop }) => (labelsOnTop ? '0px' : '0px 10px')};
`;

const FilterOption = ({ title, filterComponent, disabled, labelsOnTop, labelsStyles, wrapperStyle }) => {
  return (
    <Wrapper title={title} labelsOnTop={labelsOnTop} style={wrapperStyle}>
      {title && (
        <Title disabled={disabled} labelsOnTop={labelsOnTop} style={labelsStyles}>
          {title}
        </Title>
      )}
      {filterComponent}
    </Wrapper>
  );
};

export default FilterOption;
