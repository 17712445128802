import React from 'react';
import Layout from '../components/shared/Layout';
import StaffUsersList from '../components/users/StaffUsersList';

const StaffUsersListPage = ({ history }) => {
  return (
    <Layout history={history} section="staff-users">
      <StaffUsersList />
    </Layout>
  );
};

export default StaffUsersListPage;
