import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from '../theme';

const styles = {
  leftContainer: {
    display: 'grid',
    gridTemplateAreas: '"searchbar" "categories" "buttons"',
    height: '90vh'
  },
  rightContainer: {
    position: 'relative',
    height: '90vh',
    overflow: 'auto'
  },
  // MenuItem
  containerBox: ({ showAllergens }) => ({
    display: 'grid',
    gridTemplateAreas: showAllergens ? "'image name buttons' 'image allergens buttons'" : "'image name buttons' 'image name buttons'",
    color: '#55595C',
    margin: 30,
    gridTemplateColumns: 'auto 1fr auto',
    gridColumnGap: '10px',
    borderRadius: '15px',
    border: '1px solid #B4DCED',
    boxShadow: '0px 3px 6px #00000029',
    maxHeight: '300px',
    minHeight: '100px',
    width: 'auto',
    padding: '15px 20px'
  }),
  imageAreaBox: ({ image }) => ({
    gridArea: 'image',
    border: `1px solid ${primaryColor}`,
    borderRadius: '10px',
    // backgroundImage: image ? `url(${image})` : 'url(https://via.placeholder.com/70x70?text=No+Image)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '70px',
    minWidth: '70px',
    margin: 'auto',
    paddingRight: 5
  }),
  nameAreaBox: {
    gridArea: 'name',
    display: 'grid',
    gridTemplateAreas: "'title price' 'title category'",
    paddingBottom: 10,
    '& > div': {
      fontSize: 14,
      fontWeight: 500,
      textTransform: 'uppercase',
      textOverflow: 'ellipsis'
    }
  },
  priceAreaBox: {
    gridArea: 'price',
    marginLeft: 'auto',
    '& > p': {
      fontWeight: 500
    }
  },
  categoryAreaBox: ({ showAllergens }) => ({
    gridArea: 'category',
    marginLeft: 'auto',
    marginTop: 'auto',
    textTransform: 'capitalize !important',
    fontWeight: 'normal !important'
  }),
  allergensAreaBox: {
    gridArea: 'allergens',
    display: 'grid',
    gridTemplateAreas: "'title .' 'allergens-list allergens-list'",
    borderTop: `1px solid ${primaryColor}`,
    paddingTop: 10
  },
  buttonsAreaBox: {
    gridArea: 'buttons',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-around',
    borderLeft: `1px solid ${primaryColor}`,
    paddingLeft: 10
  },
  containerFlat: {
    display: 'grid',
    gridTemplateAreas: "'image name info allergens category buttons'",
    gridTemplateColumns: 'auto 1fr 1fr 1fr auto auto',
    // gridColumnGap: '20px',
    color: '#55595C',
    margin: 30,
    borderRadius: '15px',
    border: '1px solid #B4DCED',
    boxShadow: '0px 3px 6px #00000029',
    maxHeight: '300px',
    minHeight: '100px',
    width: 'auto',
    minWidth: '400px',
    padding: '15px 20px'
  },
  imageAreaFlat: ({ image }) => ({
    gridArea: 'image',
    border: `1px solid ${primaryColor}`,
    borderRadius: '10px',
    // backgroundImage: image ? `url(${image})` : 'url(https://via.placeholder.com/70x70?text=No+Image)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '70px',
    minWidth: '70px',
    margin: 'auto 0'
  }),
  nameAreaFlat: {
    gridArea: 'name',
    borderRight: `1px solid ${primaryColor}`,
    padding: '0 10px',
    textTransform: 'uppercase',
    fontWeight: 500
  },
  infoAreaFlat: {
    gridArea: 'info',
    display: 'grid',
    gridTemplateAreas: '"price" "notes"',
    borderRight: `1px solid ${primaryColor}`,
    padding: '0 10px'
  },
  allergensAreaFlat: {
    gridArea: 'allergens',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    borderRight: `1px solid ${primaryColor}`,
    padding: '0 10px'
  },
  categoryAreaFlat: {
    gridArea: 'category',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    borderRight: `1px solid ${primaryColor}`,
    padding: '0 10px'
  },
  buttonsAreaFlat: {
    gridArea: 'buttons',
    display: 'flex',
    flexFlow: 'column',
    margin: 'auto'
  },
  // ItemsCounter
  counter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
    color: '#55595c',
    background: 'linear-gradient(90deg, rgba(217,237,246,1) 0%, rgba(255,255,255,1) 100%)',
    padding: '0 30px',
    minHeight: 51
  },
  // Modals
  modalContainer: {
    display: 'grid',
    gridTemplateAreas: '"image name" "image category" "description description" "price allergens" "status notes" "buttons buttons"',
    gridTemplateRows: 'auto auto auto auto auto',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    minHeight: 470,
    borderRadius: 30,
    boxShadow: '2px 2px 2px 2px #9d9d9d',
    padding: 20,
    backgroundColor: 'white'
  },
  modalCategoryContainer: {
    display: 'grid',
    gridTemplateAreas: '"name color" "order order" "status category" "buttons buttons"',
    gridTemplateRows: 'auto auto auto',
    position: 'fixed',
    top: '50%',
    right: '50%',
    bottom: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 530,
    height: 400,
    borderRadius: 30,
    boxShadow: '2px 2px 2px 2px #9d9d9d',
    padding: 20,
    backgroundColor: 'white'
  },
  modalCategoryColorContainer: {
    gridArea: 'color',
    display: 'flex',
    alignItems: 'center',
    marginTop: 9
  },
  colorPicker: ({ size }) => ({
    cursor: 'pointer',
    border: 'none',
    height: size - 6,
    width: size - 6
  }),
  modalButton: {
    height: 50,
    fontSize: 15,
    fontWeight: 'bold',
    color: 'white',
    textTransform: 'uppercase'
  },
  floatingButton: {
    position: 'absolute',
    backgroundColor: `${primaryColor}`,
    width: 35,
    height: 27,
    top: -10,
    right: -10,

    '& span': {
      color: 'white',
      fontSize: '1.2rem'
    }
  },
  allergensContainer: {
    gridArea: 'allergens'
  },
  addButtonsContainer: {
    gridArea: 'buttons',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  dishesContainer: {
    display: 'flex',
    width: 'auto',
    paddingRight: 30
  }
};

export default makeStyles(styles);
