/* eslint-disable import/prefer-default-export */
/* eslint-disable no-undef */

import gql from 'graphql-tag';
import { FB_ORDERS_FRAGMENT } from '../../fragments';

export const SET_ORDER_STATUS = gql`
  mutation setOrderStatus($fbOrderUUID: String!, $status: FbOrdersStatuses!, $deliveryTime: CustomDate) {
    setOrderStatus(fbOrderUUID: $fbOrderUUID, status: $status, deliveryTime: $deliveryTime) {
      ...fbOrdersFragment
    }
  }
  ${FB_ORDERS_FRAGMENT}
`;
