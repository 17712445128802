import React from 'react';
import { Box, Button } from '@material-ui/core';
import styles from '../../../styles/viewTables/floatingGuests/style';
import FloatingGuestItem from './FloatingGuestItem';
import { TranslateTextComponent } from '../../../translator';

const FloatingGuests = ({ guests, coords, onNewReservationButtonClick, onGuestItemClick, onAssignFastFbTable }) => {
  const classes = styles({ top: coords.top, left: coords.left });

  return (
    <Box id="floating-guests-menu" tabIndex="0" className={classes.wrapper}>
      <Box className={classes.container}>
        <Box className={classes.title}>
          <TranslateTextComponent capitalize>assign-table-to</TranslateTextComponent>
        </Box>
        <Box className={classes.guestList}>
          {guests.map((guest, index) => (
            <FloatingGuestItem key={guest.id} index={index} guest={guest} onGuestItemClick={onGuestItemClick} />
          ))}
        </Box>
      </Box>
      <Box className={classes.newGuest}>
        <Button id="fast-order-button" color="primary" variant="contained" onClick={onAssignFastFbTable} style={{ width: '100%' }}>
          <TranslateTextComponent>fast-order</TranslateTextComponent>
        </Button>
      </Box>
      <Box className={classes.newGuest}>
        <Button id="new-reservation-button" color="primary" variant="contained" onClick={onNewReservationButtonClick} style={{ width: '100%' }}>
          <TranslateTextComponent>new-reservation</TranslateTextComponent>
        </Button>
      </Box>
    </Box>
  );
};

export default FloatingGuests;
