import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from '../theme';

const ecStyles = {
  listStyle: () => ({
    border: 'solid',
    borderRadius: 5,
    borderColor: primaryColor
  }),

  placeholderChip: () => ({
    fontSize: 14,
    fontWeight: 'bold',
    color: '#55595C'
  }),

  noteTextField: () => ({
    fontSize: 14,
    height: 40,
    color: '#55595C',
    border: '1.5px solid',
    borderColor: primaryColor,
    paddingLeft: 10,
    borderRadius: 4
  }),

  tagTextField: () => ({
    fontSize: 14,
    height: 40,
    color: '#55595C',
    border: '1.5px solid',
    borderColor: primaryColor,
    paddingLeft: 10,
    borderRadius: 4,
    '&:first-child>div:before': {
      height: 0,
      backgroundColor: 'none !important'
    },
    '&:first-child>div:hover:before': {
      height: 0,
      backgroundColor: 'none !important'
    }
  }),

  placeholdersStyle: () => ({
    fontWeight: 'bold',
    color: '#55595C',
    fontSize: 14
  }),

  txtNote: () => ({
    width: '100%'
  }),

  saveButton: () => ({
    paddingRight: 5,
    height: 50,
    width: 195,
    borderRadius: 9,
    color: 'white',
    textAlign: 'center',
    fontSize: 19
  }),

  boxListener: () => ({
    position: 'relative',
    width: '100%'
  }),
  awayListener: () => ({
    width: '100%'
  }),
  boxListenerLabel: () => ({
    width: '100%',
    position: 'absolute',
    zIndex: 100
  }),
  buttonlistener: () => ({
    width: '100%',
    justifyContent: 'space-between',
    color: primaryColor,
    textDecoration: 'underline',
    '&:hover': {
      background: 'white',
      textDecoration: 'underline'
    }
  }),
  iconButton: () => ({
    '&:hover': {
      background: 'transparent'
    }
  }),
  icons: () => ({
    backgroundColor: primaryColor,
    color: 'white',
    borderRadius: 30,
    opacity: 1
  }),
  categorytext: () => ({
    color: '#565A5D',
    textAlign: 'left',
    fontSize: 15,
    paddingTop: 5
  }),
  categoryRangeAge: () => ({
    color: '#939596',
    textAlign: 'left',
    fontSize: 10
  }),
  categoryCounter: () => ({
    paddingLeft: 8,
    color: '#565A5D',
    textSize: 26
  }),
  textFieldAge: () => ({
    fontSize: 12,
    color: '#939596',
    border: 'solid',
    cursor: 'pointer',
    marginLeft: 5,
    borderColor: primaryColor,
    paddingLeft: 5,
    borderRadius: 10
  }),
  deleteButton: () => ({
    color: 'white',
    backgroundColor: '#CB2D24',
    textSize: 10,
    width: '100%',
    height: 25,
    paddingTop: 2
  }),
  confirmButton: () => ({
    color: 'white',
    backgroundColor: '#449D44',
    textSize: 10,
    height: 25,
    width: '100%',
    paddingTop: 2
  }),
  buttonContainer: () => ({
    paddingLeft: 10,
    paddingRight: 10
  }),
  gridInput: () => ({
    paddingRight: 5,
    paddingTop: 5
  }),
  headerForm: () => ({
    color: '#00B7FF',
    borderBottomStyle: 'solid',
    borderBottomColor: '#B4DCED',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 20
  }),
  reservationPanel: () => ({
    borderStyle: 'solid',
    borderWidth: '1.5px',
    borderColor: '#00B7FF',
    borderRadius: 10
  }),
  reservationPanelError: () => ({
    borderStyle: 'solid',
    borderWidth: '1.5px',
    borderColor: 'red',
    borderRadius: 10
  }),
  outletName: () => ({
    fontSize: 12,
    color: '#55595C'
  }),
  styleBorderRight: () => ({
    borderRightStyle: 'solid',
    borderRightColor: primaryColor,
    alignSelf: 'center'
  }),

  imgStyle: () => ({
    '& path': {
      fill: primaryColor
    }
  }),
  imgTable: () => ({
    '& path': {
      fill: 'black'
    },
    '& a': {
      fill: 'black'
    },
    '& rect': {
      fill: 'black'
    },
    // display: 'flex',
    paddingBottom: 6
  }),
  placeholderTypography: () => ({
    fontSize: 12,
    color: '#55595C',
    fontWeight: 'bold'
  }),
  panelDetail: () => ({
    borderTopStyle: 'solid',
    borderWidth: '1.5px',
    borderColor: '#00B7FF',
    display: 'block'
  }),
  panelDetailError: () => ({
    borderTopStyle: 'solid',
    borderWidth: '1.5px',
    borderColor: 'red',
    display: 'block'
  }),
  fabAddButton: () => ({
    color: 'white',
    fontSize: 21,
    textAlign: 'center',
    width: 35,
    height: 35
  }),
  delButton: () => ({
    backgroundColor: '#00B7FF ',
    // opacity: 0.2,
    width: 40,
    height: 40,
    position: 'relative'
  }),
  delIcon: () => ({
    color: 'white',
    position: 'absolute',
    width: 20,
    height: 23
  }),
  timeSlots: () => ({
    backgroundColor: primaryColor,
    color: 'white',
    fontSize: 11,
    height: 21,
    borderRadius: 4,
    paddingTop: 2
  }),
  selectedTimeSlots: () => ({
    backgroundColor: 'white',
    color: primaryColor,
    border: '1px solid',
    borderColor: primaryColor,
    fontSize: 11,
    height: 21,
    borderRadius: 4,
    paddingTop: 2
  }),
  boxMsgNO: () => ({
    width: '100%',
    height: 40,
    fontSize: 12,
    textAlign: 'left',
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: 4,
    color: 'white',
    backgroundColor: '#CB2D24'
  }),
  boxMsgOk: () => ({
    width: '100%',
    height: 40,
    fontSize: 12,
    textAlign: 'center',
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: 2,
    color: 'white',
    backgroundColor: '#449D44'
  }),
  boxMsgOb: () => ({
    width: '100%',
    height: 40,
    fontSize: 12,
    textAlign: 'left',
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: 2,
    color: 'white',
    backgroundColor: '#CB2D24'
  })
};

export default makeStyles(ecStyles);
