import React from 'react';
import { Box } from '@material-ui/core';
import { CommonTable, secondaryColor } from '../../../common-fe';
import getOrderColumns from '../../../constants/roomService/orderColumns';

const OrderDetails = ({ item }) => {
  const columns = getOrderColumns();
  return (
    <Box style={{ display: 'flex', justifyContent: 'center', backgroundColor: secondaryColor }}>
      <CommonTable size="small" items={[]} columns={columns} tableContainerStyle={{ width: '50%' }} />
    </Box>
  );
};
export default OrderDetails;
