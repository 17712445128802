import React from 'react';
import { Box } from '@material-ui/core';
import typy from 'typy';
import groupBy from 'lodash/groupBy';
import Spinner from 'react-loader-spinner';
import styles from '../../../styles/records/orders/style';
import OrderItem from './OrderItem';
import { ReactComponent as FoodIcon } from '../../../assets/images/order/food_filled.svg';
import { ReactComponent as DrinkIcon } from '../../../assets/images/order/drink_filled.svg';
import { TranslateTextComponent } from '../../../translator';
import { GET_FB_ORDER } from '../../../graphql/order/queries';
import useCustomQuery from '../../../hooks/useCustomQuery';

const Orders = ({ fbTable }) => {
  const classes = styles();

  const { fbCourses, uuid: fbOrderUUID } = fbTable.fbReservationTables[0].fbOrder;
  const fbOrder = useCustomQuery(GET_FB_ORDER, {
    variables: {
      fbOrderUUID,
    },
    fetchPolicy: 'network-only',
  });

  const fetchedFbCourses = typy(fbOrder, 'data.getFbOrder.fbCourses').safeArray;
  const fbTableRes = typy(fbOrder, 'data.getFbOrder.fbReservationTable.fbTable').safeObject;
  const groupedCourses = groupBy(fetchedFbCourses || fbCourses, (course) => {
    return course.type;
  });

  return (
    // FOOD
    fbOrder && fbOrder.loading ? (
      <Box className={classes.wrapper} style={{ display: 'flex', justifyContent: 'center', border: 'none', margin: 'auto' }}>
        <Spinner type="TailSpin" color="#00BFFF" height={100} width={100} />
      </Box>
    ) : (
      <>
        <Box className={classes.wrapper}>
          <Box className={classes.wrapperHeader}>
            <FoodIcon fill="white" style={{ marginRight: 10 }} /> <TranslateTextComponent capitalize>foods</TranslateTextComponent>
          </Box>
          <Box className={classes.container}>
            <Box className={classes.headerContainer}>
              <Box className={[classes.idHeader, classes.itemTitle].join(' ')} style={{ textAlign: 'center' }}>
                {/* <FoodIcon fill="#00b7ff" style={{ width: 22.75, height: 'auto' }} /> */}
              </Box>
              <Box className={[classes.quantityHeader, classes.itemTitle].join(' ')}>
                <TranslateTextComponent uppercase>quantity-shortened</TranslateTextComponent>
              </Box>
              <Box className={[classes.dishHeader, classes.itemTitle].join(' ')}>
                <TranslateTextComponent uppercase>dish</TranslateTextComponent>
              </Box>
              <Box className={[classes.priceHeader, classes.itemTitle].join(' ')}>
                <TranslateTextComponent uppercase>price</TranslateTextComponent>
              </Box>
              <Box className={[classes.chairHeader, classes.itemTitle].join(' ')}>
                <TranslateTextComponent uppercase>chair</TranslateTextComponent>
              </Box>
              <Box className={[classes.clientHeader, classes.itemTitle].join(' ')}>
                <TranslateTextComponent uppercase>client-name</TranslateTextComponent>
              </Box>
            </Box>
            {typy(groupedCourses, 'food')
              .safeArray.sort((prev, next) => prev.number - next.number)
              .map((course) => (
                <OrderItem key={course.id} course={course} table={fbTableRes} />
              ))}
          </Box>
        </Box>
        {/* DRINK */}
        {typy(groupedCourses, 'drink').safeArray.length > 0 && (
          <Box className={classes.wrapper}>
            <Box className={classes.wrapperHeader}>
              <DrinkIcon fill="white" style={{ marginRight: 10 }} />
              <TranslateTextComponent capitalize>beverages</TranslateTextComponent>
            </Box>
            <Box className={classes.container}>
              <Box className={classes.headerContainer}>
                <Box className={[classes.idHeader, classes.itemTitle].join(' ')} style={{ textAlign: 'center' }}>
                  {/* <FoodIcon fill="#00b7ff" style={{ width: 22.75, height: 'auto' }} /> */}
                </Box>
                <Box className={[classes.quantityHeader, classes.itemTitle].join(' ')}>
                  <TranslateTextComponent uppercase>quantity-shortened</TranslateTextComponent>
                </Box>
                <Box className={[classes.dishHeader, classes.itemTitle].join(' ')}>
                  <TranslateTextComponent uppercase>dish</TranslateTextComponent>
                </Box>
                <Box className={[classes.priceHeader, classes.itemTitle].join(' ')}>
                  <TranslateTextComponent uppercase>price</TranslateTextComponent>
                </Box>
                <Box className={[classes.chairHeader, classes.itemTitle].join(' ')}>
                  <TranslateTextComponent uppercase>chair</TranslateTextComponent>
                </Box>
                <Box className={[classes.clientHeader, classes.itemTitle].join(' ')}>
                  <TranslateTextComponent uppercase>client-name</TranslateTextComponent>
                </Box>
              </Box>
              {typy(groupedCourses, 'drink')
                .safeArray.sort((prev, next) => prev.number - next.number)
                .map((course) => (
                  <OrderItem key={course.id} course={course} table={fbTableRes} />
                ))}
            </Box>
          </Box>
        )}
        {/* TOTAL AMOUNT */}
        {/* <Amount /> */}
      </>
    )
  );
};

export default Orders;
