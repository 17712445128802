import groupBy from 'lodash/groupBy';

const groupedByMenuItem = (fbCoursesMenuItems) => {
  const groupedBy = groupBy(fbCoursesMenuItems, (menuItem) => {
    return menuItem.fbMenuItemId + menuItem.with + menuItem.without;
  });

  const keys = Object.keys(groupedBy).map((key) => {
    return key;
  });

  keys.sort();
  const dishes = keys.map((key) => {
    return groupedBy[key];
  });

  return dishes;
};

export default groupedByMenuItem;
