import { MOH_ORDERS_STATUSES } from '../constants/moh/fbOrdersStatuses';
import { translate, capitalizeFirstLetter } from '../translator';

const getLabel = ({ key, language, type }) => {
  if (MOH_ORDERS_STATUSES[key] === MOH_ORDERS_STATUSES.preparing && type === 'bookings')
    return capitalizeFirstLetter(translate(`orders-${MOH_ORDERS_STATUSES.working?.toLowerCase()?.replace(/_/g, '-')}`, language));
  return capitalizeFirstLetter(translate(`orders-${MOH_ORDERS_STATUSES[key]?.toLowerCase()?.replace(/_/g, '-')}`, language));
};

export default ({ type, currentStatus, language = 'en', profile }) => {
  if (!profile && type === 'bookings') {
    return Object.keys(MOH_ORDERS_STATUSES)
      .map((key) => ({
        label: getLabel({ key, language, type }),
        value: MOH_ORDERS_STATUSES[key]
      }))
      .filter(
        (status) =>
          status.value === MOH_ORDERS_STATUSES.open ||
          status.value === MOH_ORDERS_STATUSES.preparing ||
          status.value === MOH_ORDERS_STATUSES.completed
      );
  }

  switch (currentStatus) {
    case MOH_ORDERS_STATUSES.to_confirm:
      return Object.keys(MOH_ORDERS_STATUSES)
        .map((key) => ({
          label: getLabel({ key, language, type }),
          value: MOH_ORDERS_STATUSES[key]
        }))
        .filter(
          (status) =>
            status.value === MOH_ORDERS_STATUSES.to_confirm ||
            status.value === MOH_ORDERS_STATUSES.open ||
            status.value === MOH_ORDERS_STATUSES.preparing ||
            status.value === MOH_ORDERS_STATUSES.deleted
        );

    case MOH_ORDERS_STATUSES.completed:
      return Object.keys(MOH_ORDERS_STATUSES)
        .map((key) => ({
          label: getLabel({ key, language, type }),
          value: MOH_ORDERS_STATUSES[key]
        }))
        .filter((status) => status.value === MOH_ORDERS_STATUSES.completed);

    case MOH_ORDERS_STATUSES.deleted:
      return Object.keys(MOH_ORDERS_STATUSES)
        .map((key) => ({
          label: getLabel({ key, language, type }),
          value: MOH_ORDERS_STATUSES[key]
        }))
        .filter((status) => status.value === MOH_ORDERS_STATUSES.deleted);

    case MOH_ORDERS_STATUSES.open:
      return Object.keys(MOH_ORDERS_STATUSES)
        .map((key) => ({
          label: getLabel({ key, language, type }),
          value: MOH_ORDERS_STATUSES[key]
        }))
        .filter((status) => status.value === MOH_ORDERS_STATUSES.open || status.value === MOH_ORDERS_STATUSES.preparing);

    case MOH_ORDERS_STATUSES.preparing:
      return Object.keys(MOH_ORDERS_STATUSES)
        .map((key) => ({
          label: getLabel({ key, language, type }),
          value: MOH_ORDERS_STATUSES[key]
        }))
        .filter(
          (status) =>
            status.value === MOH_ORDERS_STATUSES.preparing ||
            (type !== 'bookings' && type !== 'takeaway' && status.value === MOH_ORDERS_STATUSES.delivering) ||
            ((type === 'bookings' || type === 'takeaway') && status.value === MOH_ORDERS_STATUSES.completed)
        );

    case MOH_ORDERS_STATUSES.delivering:
      return Object.keys(MOH_ORDERS_STATUSES)
        .map((key) => ({
          label: getLabel({ key, language, type }),
          value: MOH_ORDERS_STATUSES[key]
        }))
        .filter((status) => status.value === MOH_ORDERS_STATUSES.delivering || status.value === MOH_ORDERS_STATUSES.completed);

    default:
      return Object.keys(MOH_ORDERS_STATUSES).map((key) => ({
        label: getLabel({ key, language, type }),
        value: MOH_ORDERS_STATUSES[key]
      }));
  }
};
