/* eslint-disable no-nested-ternary */
import { makeStyles } from '@material-ui/core/styles';

const styles = {
  wrapper: ({ rotate }) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    transform: `rotate(${rotate}deg)`,
    transition: 'transform 0.3s'
  }),
  chairHorizontal: () => ({
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'space-evenly',
    width: '100%',
    height: '100%'
  }),
  chairVertical: () => ({
    position: 'absolute',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-evenly',
    width: '100%',
    height: '100%'
  }),
  rowReverse: {
    flexFlow: 'row-reverse!important'
  },
  columnReverse: {
    flexFlow: 'column-reverse!important'
  }
};

export default makeStyles(styles);
