/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import style from '../../styles/shared/chairItem';
import { primaryColor } from '../../styles/theme';

import maleIcon from '../../assets/images/table_item/male.svg';
import femaleIcon from '../../assets/images/table_item/female.svg';
import othersIcon from '../../assets/images/table_item/others.svg';

export const SEX_CODES = {
  male: 'MALE',
  female: 'FEMALE',
  other: 'NOT_KNOWN'
};

const getChairBackgroundColor = (tableBusy, isInEditor, chair) => {
  if (!tableBusy) {
    return 'linear-gradient(233deg, rgba(255,255,255,1) 0%, rgba(192,193,194,1) 100%)';
  }
  if ((!isInEditor && chair.gender) || (!isInEditor && chair.guest) || (!isInEditor && chair.guestPhysicalCategory)) {
    return 'rgba(0, 0, 0, 0) linear-gradient(233deg, rgb(254, 0, 0) 0%, rgb(64, 47, 47) 100%) repeat scroll 0% 0%';
  }
  return 'rgba(0, 0, 0, 0) linear-gradient(233deg, rgb(68, 157, 68) 0%, rgb(47, 64, 53) 100%) repeat scroll 0% 0%';
};

const Chair = ({ isInEditor = false, tableBusy, chair, chairSize, rotate, borderPosition, margin, onChairClick, isGuestNameShowed }) => {
  const classes = style({
    chairSize,
    rotate,
    borderPosition,
    background: getChairBackgroundColor(tableBusy, isInEditor, chair)
  });

  const chairGender = chair && chair.guest ? chair.guest.gender : chair.gender;

  const getChairColor = () => {
    switch (chairGender) {
      case SEX_CODES.male:
        return primaryColor;
      case SEX_CODES.female:
        return '#e349d8';
      case SEX_CODES.other:
        return '#939393';
      default:
        return null;
    }
  };

  const labelTop = style({ marginTop: '-40px', left: '-12px', borderLeft: `4px solid ${getChairColor(chairGender)}` });
  const labelBottom = style({ marginTop: 60, left: '-10px', borderLeft: `4px solid ${getChairColor(chairGender)}` });
  const labelLeft = style({ marginTop: 10, left: '-81px', borderLeft: `4px solid ${getChairColor(chairGender)}` });
  const labelRight = style({ marginTop: 10, left: 60, borderLeft: `4px solid ${getChairColor(chairGender)}` });

  const showNameGuest = () => {
    let classes = null;
    if (margin === '-35px 0' && borderPosition === 'top') {
      classes = labelTop.nameLabelGuest;
    } else if (margin === '-35px 0' && borderPosition === 'bottom') {
      classes = labelBottom.nameLabelGuest;
    } else if (margin === '0 -35px' && borderPosition === 'right') {
      classes = labelRight.nameLabelGuest;
    } else {
      classes = labelLeft.nameLabelGuest;
    }
    return (
      <Typography className={classes}>
        {chair.guest && chair.guest.firstname} {'\n'}
        {chair.guest && chair.guest.lastname}
      </Typography>
    );
  };

  const showSexImg = () => {
    if (chairGender) {
      return (
        <Box className={classes.genderBadge}>
          <img src={chairGender === SEX_CODES.male ? maleIcon : chairGender === SEX_CODES.female ? femaleIcon : othersIcon} alt="Gender badge" />
        </Box>
      );
    }
    return null;
  };

  return (
    <Box
      className={classes.container}
      style={{ margin }}
      onClick={(e) => {
        if (!isInEditor) {
          e.stopPropagation();
          onChairClick(chair);
        }
      }}
    >
      {!isInEditor && showSexImg()}
      {isGuestNameShowed && chair && chair.guest ? showNameGuest() : null}
    </Box>
  );
};

export default Chair;
