import { makeStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    gridArea: 'buttons-area',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexFlow: 'column',
    paddingLeft: 5,
    paddingRight: 5,
    height: '100%',
    maxWidth: 250,
    paddingBottom: 10
  }
};

export default makeStyles(styles);
