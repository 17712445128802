import React, { useState, useContext } from 'react';
import { IconButton, Box, Button } from '@material-ui/core/';
import typy from 'typy';
import moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import { useMutation } from '@apollo/react-hooks';
import { TranslateTextComponent } from '../../../translator';
import customObjectValidator from '../../../utils/validator';
import { ModalContext } from '../../../contexts/ModalContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { GET_FB_SERVICES } from '../../../graphql/settings/service/queries';
import { DELETE_FB_SERVICE_MUTATION, EDIT_FB_SERVICE_MUTATION, ADD_FB_SERVICE_MUTATION } from '../../../graphql/settings/service/mutations';
import ServiceForm from './ServiceForm';
import { CommonTable } from '../../../common-fe';
import getServiceColumns from '../../../constants/settings/serviceColumns';
import { getApolloErrors } from '../../../apollo/ApolloProvider';
import modalStyle from '../../../common-fe/src/styles/shared/modalStyle';
import useCustomQuery from '../../../hooks/useCustomQuery';

const FORM_VALIDATION = {
  name: {
    isRequired: true
  },
  startTime: {
    isRequired: true
  },
  endTime: {
    isRequired: true
  },
  isActive: {
    isRequired: true
  },
  lastReservation: {
    isRequired: true
  },
  slotSizeMinutes: {
    isRequired: true,
    validateFunctions: [
      {
        fn: (value) => typy(Number(value)).safeNumber > 0,
        errorMessage: 'Insert positive number'
      }
    ]
  },
  maxGuestsForSlot: {
    isRequired: true,
    validateFunctions: [{ fn: (value) => typy(Number(value)).safeNumber > 0, errorMessage: 'Insert positive number' }]
  }
};

const initialState = {
  isActive: '',
  name: '',
  startTime: null,
  endTime: null,
  lastReservation: null,
  slotSizeMinutes: 1,
  maxGuestsForSlot: 1,
  icon: ''
};

const ServiceSettings = () => {
  const classesModal = modalStyle();

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [serviceSettings, setServiceSettings] = useState(initialState);
  const [editItemUUID, setEditItemUUID] = useState(null);
  const [errorFormMap, setErrorFormMap] = useState(new Map());
  const [errorResMap, setErrorResMap] = useState([new Map()]);
  const modalContext = useContext(ModalContext);
  const { appOutletUUID } = useContext(AuthContext);

  const [addFbService] = useMutation(ADD_FB_SERVICE_MUTATION);
  const [deleteFbService] = useMutation(DELETE_FB_SERVICE_MUTATION);
  const [editFbService] = useMutation(EDIT_FB_SERVICE_MUTATION);

  const getFbServices = useCustomQuery(GET_FB_SERVICES, {
    variables: { fbOutletUUID: appOutletUUID },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const textTranslate = (text) => {
    return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
  };

  const handleAddButton = () => {
    setIsFormVisible(!isFormVisible);
    setFormEmpty();
  };

  const setFormEmpty = () => {
    setServiceSettings({ ...initialState });
    setEditItemUUID(null);
    setErrorFormMap(new Map());

    setErrorResMap([new Map()]);
  };

  const updateServiceSettings = (key, value) => {
    setServiceSettings({ ...serviceSettings, [key]: value });
  };

  const isValidForm = () => {
    const serviceToValidate = serviceSettings;
    const validation = customObjectValidator(FORM_VALIDATION, serviceToValidate);
    setErrorFormMap(validation.errorMap);
    return validation.isValid;
  };

  const deleteService = (item) => {
    modalContext.openModal({
      class: 'warning',
      title: textTranslate('delete-service-title'),
      text: textTranslate('delete-service-text'),
      actionButtons: [
        <Button key={1} variant="outlined" color="primary" className={classesModal.invertedButton} onClick={modalContext.closeModal}>
          {textTranslate('cancel')}
        </Button>,
        <Button
          key={2}
          color="primary"
          variant="contained"
          className={classesModal.buttonStyle}
          onClick={() => {
            modalContext.closeModal();
            deleteFbService({ variables: { fbServiceUUID: item.uuid } })
              .then(() => getFbServices.refetch())
              .catch((err) => {
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
          }}
        >
          {textTranslate('confirm')}
        </Button>
      ]
    });
  };

  const editService = (item) => {
    const startTime = moment(new Date(`${moment().format('YYYY-MM-DD')} ${item.startTime}`));
    const endTime = moment(new Date(`${moment().format('YYYY-MM-DD')} ${item.endTime}`));
    const lastReservation = moment(new Date(`${moment().format('YYYY-MM-DD')} ${item.lastReservation}`));

    setEditItemUUID(item.uuid);
    const updatedServiceSettings = {
      name: item.name,
      isActive: item.isActive ? 'activated' : 'disabled',
      startTime,
      endTime,
      lastReservation,
      slotSizeMinutes: item.slotSizeMinutes,
      maxGuestsForSlot: item.maxGuestsForSlot,
      icon: item.icon
    };

    setServiceSettings(updatedServiceSettings);
    setIsFormVisible(true);
  };

  const onClickSave = () => {
    setErrorResMap([...errorResMap, new Map()]);

    if (!isValidForm()) {
      modalContext.openModal({
        class: 'danger',
        title: textTranslate('form-not-valid-title'),
        text: textTranslate('form-not-valid-text'),
        actionButtons: null
      });
    } else {
      modalContext.openModal({
        class: 'primary',
        title: textTranslate('form-valid-title'),
        text: textTranslate(editItemUUID ? 'form-edit-text' : 'form-valid-text'),
        actionButtons: [
          <Button key={1} variant="outlined" color="primary" className={classesModal.invertedButton} onClick={modalContext.closeModal}>
            {textTranslate('cancel')}
          </Button>,
          <Button
            key={2}
            color="primary"
            variant="contained"
            className={classesModal.buttonStyle}
            onClick={() => {
              modalContext.closeModal();
              fetchForm();
            }}
          >
            {textTranslate('confirm')}
          </Button>
        ]
      });
    }
  };

  const fetchForm = () => {
    const newFbService = {
      ...serviceSettings,
      maxGuestsForSlot: Number(serviceSettings.maxGuestsForSlot),
      slotSizeMinutes: Number(serviceSettings.slotSizeMinutes),
      startTime: moment(new Date(serviceSettings.startTime)).format('HH:mm'),
      endTime: moment(new Date(serviceSettings.endTime)).format('HH:mm'),
      lastReservation: moment(new Date(serviceSettings.lastReservation)).format('HH:mm'),
      isActive: serviceSettings.isActive === 'activated'
    };
    if (editItemUUID) {
      editFbService({
        variables: {
          fbServiceUUID: editItemUUID,
          fbServiceData: newFbService
        }
      })
        .then(() => {
          modalContext.closeModal();
          modalContext.openModal({
            class: 'success',
            title: textTranslate('success-modal-title'),
            text: textTranslate('success-modal-text'),
            actionButtons: null
          });
          setFormEmpty();

          getFbServices.refetch();
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        });
    } else {
      addFbService({
        variables: {
          fbOutletUUID: appOutletUUID,
          fbServiceData: newFbService
        }
      })
        .then(() => {
          modalContext.closeModal();
          modalContext.openModal({
            class: 'success',
            title: textTranslate('success-modal-title'),
            text: textTranslate('success-modal-text'),
            actionButtons: null
          });
          setFormEmpty();

          getFbServices.refetch();
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        });
    }
    setIsFormVisible(false);
  };

  const onClickDelete = () => {
    modalContext.openModal({
      class: 'warning',
      title: textTranslate('delete-form-title'),
      text: textTranslate('delete-form-text'),
      actionButtons: [
        <Button key={1} variant="outlined" color="primary" className={classesModal.invertedButton} onClick={modalContext.closeModal}>
          {textTranslate('cancel')}
        </Button>,
        <Button
          key={2}
          color="primary"
          variant="contained"
          className={classesModal.buttonStyle}
          onClick={() => {
            modalContext.closeModal();
            setFormEmpty();
          }}
        >
          {textTranslate('confirm')}
        </Button>
      ]
    });
  };

  const columns = getServiceColumns({ deleteService, editService });
  return (
    <Box>
      <CommonTable
        size="small"
        items={typy(getFbServices, 'data.getFbServices.data').safeArray.sort((prev, next) => prev.id - next.id)}
        columns={columns}
      />
      <Box style={{ padding: 10 }}>
        <IconButton className="buttonCircle" onClick={handleAddButton}>
          <AddIcon />
        </IconButton>
      </Box>
      {isFormVisible && (
        <ServiceForm
          serviceSettings={serviceSettings}
          updateServiceSettings={updateServiceSettings}
          onClickSave={onClickSave}
          onClickDelete={onClickDelete}
          errorFormMap={errorFormMap}
        />
      )}
    </Box>
  );
};

export default ServiceSettings;
