/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { Box, Button } from '@material-ui/core';
import typy from 'typy';
import { TranslateTextComponent } from '../../translator';
import { MaterialIcon } from '../../common-fe/src';
import FB_ORDER_STATUSES from '../order/orderStatuses';

const textTranslate = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

const courseSummaryColumns = ({ isFastCash, deleteAllItems, addItemsToBill }) => {
  return [
    {
      id: 'quantity',
      disablePadding: false,
      label: textTranslate('quantity'),
      isSortable: false,
      align: 'center',
      position: 0,
      render: (item) =>
        Math.round(
          typy(item)
            .safeArray.map((it) => it.quantity)
            .reduce((acc, cv) => acc + cv) * 100
        ) / 100,
    },
    {
      id: 'description',
      disablePadding: false,
      label: textTranslate('description'),
      isSortable: false,
      position: 1,
      render: (item) => (
        <>
          <Box>{typy(item[0], 'name').safeString}</Box>
          {typy(item[0], 'with').safeArray.length > 0 && (
            <Box style={{ fontStyle: 'italic' }}>
              <TranslateTextComponent capitalize style={{ color: '#55595C', fontWeight: 'bold' }}>
                with
              </TranslateTextComponent>
              : {typy(item[0], 'with').safeArray.join(', ')}
            </Box>
          )}
          {typy(item[0], 'without').safeArray.length > 0 && (
            <Box style={{ fontStyle: 'italic' }}>
              <TranslateTextComponent capitalize style={{ color: '#55595C', fontWeight: 'bold' }}>
                without
              </TranslateTextComponent>
              : {typy(item[0], 'without').safeArray.join(', ')}
            </Box>
          )}
        </>
      ),
    },
    {
      id: 'amount',
      disablePadding: false,
      label: textTranslate('price'),
      isSortable: false,
      align: 'center',
      position: 2,
      render: (item) => (
        <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>€ {typy(item[0], 'price').safeNumber.toFixed(2).toString().replace('.', ',')}</span>
      ),
    },
    {
      id: 'addRow',
      disablePadding: false,
      position: 4,
      label: <TranslateTextComponent capitalize>chairs</TranslateTextComponent>,
      isSortable: false,
      align: 'center',
      render: (item) => {
        return (
          <Button
            style={{ lineHeight: 1, width: 66, height: 28, fontSize: 10, color: 'white' }}
            variant="contained"
            color="primary"
            onClick={() => addItemsToBill(item)}
          >
            {!isFastCash ? <TranslateTextComponent>add-all</TranslateTextComponent> : <TranslateTextComponent>add</TranslateTextComponent>}
          </Button>
        );
      },
    },
    {
      id: 'delete',
      disablePadding: false,
      position: 5,
      label: '',
      isSortable: false,
      align: 'center',
      render: (items) => {
        let areInTodo = true;
        const itemsUUIDs = [];
        items.forEach((item) => {
          itemsUUIDs.push(item.uuid);
          if (
            [FB_ORDER_STATUSES.completed.label, FB_ORDER_STATUSES.preparing.label, FB_ORDER_STATUSES.ready.label].some(
              (status) => status === item.fbCourse.fbCourseStatus
            )
          )
            areInTodo = false;
        });
        if (areInTodo) {
          return (
            <MaterialIcon
              iconName="Clear"
              style={{ display: 'flex', cursor: 'pointer', color: '#cb2d24' }}
              onClick={() => {
                deleteAllItems(itemsUUIDs);
              }}
            />
          );
        }
        return null;
      },
    },
  ];
};

export default courseSummaryColumns;
