import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { primaryColor, mainTextColor } from '../../styles/theme';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 85px;
  border: ${() => `1px solid ${primaryColor}`};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const Tile = styled.div`
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 3rem;

  ${({ left, right }) => {
    if (left) {
      return css`
        user-select: none;
        font-weight: normal;
        border-right: ${() => `1px solid ${primaryColor}`};
      `;
    }

    if (right) {
      return css`
        user-select: none;
        font-weight: normal;
        border-left: ${() => `1px solid ${primaryColor}`};
      `;
    }
  }}
`;

const TileText = styled.span`
  color: ${({ primary }) => (primary ? primaryColor : mainTextColor)};
  font-weight: bold;
`;

const TilesStepper = ({ value, containerStyle, leftTileStyle, centerTileStyle, rightTileStyle, onIncrease, onDecrease }) => {
  return (
    <Container style={containerStyle}>
      <Tile left pointer style={leftTileStyle} onClick={onDecrease}>
        <TileText primary>-</TileText>
      </Tile>
      <Tile style={centerTileStyle}>
        <TileText>{value}</TileText>
      </Tile>
      <Tile right pointer style={rightTileStyle} onClick={onIncrease}>
        <TileText primary>+</TileText>
      </Tile>
    </Container>
  );
};

TilesStepper.propTypes = {
  value: PropTypes.number.isRequired,
  onIncrease: PropTypes.func.isRequired,
  onDecrease: PropTypes.func.isRequired
};

export default TilesStepper;
