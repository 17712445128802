import { makeStyles } from '@material-ui/core/styles';
import { headerHeight, primaryColor } from '../../common-fe/src';

const paddingTop = 20;

const styles = {
  container: {
    display: 'grid',
    gridTemplateAreas: "'guest-info-area guest-info-area guest-info-area guest-info-area' 'menu-area summary-area bill-area buttons-area'",
    gridTemplateRows: 'auto 1fr',
    gridTemplateColumns: 'auto 1fr 1fr auto',
    gridRowGap: 10,
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    borderTopLeftRadius: 15,
  },
  guestInfoArea: {
    gridArea: 'guest-info-area',
    borderTopLeftRadius: 15,
    display: 'grid',
    gridTemplateAreas: '"avatar info searchbar button"',
    gridTemplateColumns: 'auto 1fr 1fr auto',
    gridColumnGap: 10,
    backgroundColor: '#EDFAFF',
    padding: '10px 13px',
    boxShadow: '0px 3px 6px #00000029',
  },
  tab: ({ activeTab }) => ({
    position: 'relative',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    minWidth: 0,
    fontSize: 16,
    color: 'white',
    marginRight: 5,
  }),
  tabIcon: ({ activeTab }) => ({
    position: 'relative',
    paddingRight: 15,
    '& span:last-child': {
      position: 'absolute',
      top: -10,
      right: -12,
      transition: 'color 0.3s',
      '&:hover': {
        color: 'red',
      },
    },
  }),
  guestNameTypography: {
    color: '#55595C',
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  titleTypography: {
    color: '#55595C',
    fontSize: 14,
    fontWeight: 'bold',
  },
  dataTypography: { color: '#55595C', fontWeight: 'normal' },
  menuArea: {
    gridArea: 'menu-area',
    paddingLeft: 10,
    paddingRight: 10,
    width: 200,
  },
  courseSummaryContainer: ({ isFastCash }) => ({
    gridArea: 'summary-area',
    display: 'flex',
    flexFlow: 'column',
    height: !isFastCash ? `calc(100vh - ${headerHeight + 60 + 10}px)` : `calc(100vh - ${headerHeight + paddingTop - 10}px)`,
    borderLeft: `2px solid ${primaryColor}`,
    borderRight: `2px solid ${primaryColor}`,
    paddingLeft: 10,
    paddingRight: 10,
    '& .MuiTableCell-root.MuiTableCell-sizeSmall': {
      padding: 10,
    },
  }),
  tabsContainer: {
    gridArea: 'tabs-area',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  billSummaryContainer: ({ isFastCash }) => ({
    gridArea: 'bill-area',
    display: 'flex',
    flexFlow: 'column',
    height: !isFastCash ? `calc(100vh - ${headerHeight + 60 + 10}px)` : `calc(100vh - ${headerHeight + paddingTop - 10}px)`,
    '& .MuiTableCell-root.MuiTableCell-sizeSmall': {
      padding: 10,
    },
  }),
  menuItem: ({ categoryColor }) => ({
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    width: 110,
    margin: 5,
    color: '#55595C',
    fontWeight: '500',
    border: `1px solid ${categoryColor}`,
    borderRadius: 10,
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover': {
      backgroundColor: categoryColor,
      color: 'white',
    },
  }),
};

export default makeStyles(styles);
