import { gql } from 'apollo-boost';

export const ON_CHECKIN_BY_GUEST_CREATED = gql`
  subscription onCheckinByGuestCreated {
    checkinByGuestCreated {
      checkinUUID
      checkinDate
      checkoutDate
      guestProfileFullName
    }
  }
`;

export const ON_CHECKIN_SAVED = gql`
  subscription onCheckinSaved {
    checkinSaved {
      checkinUUID
      checkinDate
      checkoutDate
      guestProfileFullName
    }
  }
`;
