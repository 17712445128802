export const rootXML = {
  _declaration: {
    _attributes: {
      version: '1.0',
      encoding: 'utf-8'
    }
  },
  's:Envelope': {
    _attributes: {
      'xmlns:s': 'http://schemas.xmlsoap.org/soap/envelope'
    },
    's:Body': {}
  }
};

export const setXmlBody = (body) => ({
  _declaration: {
    _attributes: {
      version: '1.0',
      encoding: 'utf-8'
    }
  },
  's:Envelope': {
    _attributes: {
      'xmlns:s': 'http://schemas.xmlsoap.org/soap/envelope'
    },
    's:Body': {
      ...body
    }
  }
});
