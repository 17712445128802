import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@material-ui/core/';
import typy from 'typy';
import * as moment from 'moment';
import { primaryColor } from '../../common-fe/src';
import FiscalPrinter from '../../common-fe/src/FiscalPrinter/index.ts';
import { GET_ORDER_SUMMARY, GET_ORDER_BILLS, GET_FAST_ORDERS, GET_FB_BILL } from '../../graphql/cash/queries';
import {
  ADD_ITEM_TO_BILL,
  REMOVE_ITEM_FROM_BILL,
  ADD_ALL_ITEMS_TO_BILL,
  REMOVE_ALL_ITEMS_FROM_BILL,
  ADD_ITEMS_TO_BILL_BY_CHAIR,
  SPLIT_ORDER_EQUALLY,
  CREATE_ORDER_BILL,
  CREATE_FAST_ORDER_BILL,
  SET_BILL_ITEM_QUANTITY,
  SET_BILL_ITEM_PRICE,
  ADD_ITEMS_TO_BILL,
  PAY_BILL,
  ADD_ORDER_ITEM_FROM_CASH,
  CLOSE_FAST_ORDER,
  DELETE_ORDER_BILL,
  SET_BILL_DISCOUNT,
  SET_BILL_DISCOUNT_PERCENTAGE,
  DELETE_ORDER_ITEMS_FROM_CASH,
  DELETE_ORDER_ITEM_FROM_CASH,
  ADD_BILL_TO_PAY_MASTER,
  UPLOAD_BILL_DOCUMENT,
  CANCEL_FB_BILL,
  TRANSFER_FB_ORDER
} from '../../graphql/cash/mutations';
import styles from '../../styles/cash/cashStyle';
import BillSummary from './BillSummary';
import ButtonsList from './ButtonsList/ButtonsList';
import CourseSummary from './CourseSummary';
import GuestReservation from './GuestReservation';
import TablesModal from './Modals/TablesModal';
import ChargeModal from './Modals/ChargeModal';
import SelectPersonNumberModal from './Modals/SelectPersonsNumberModal';
import PaymentModal from './Modals/PaymentModal';
import orderBy from '../../utils/orderBy';
import MenuList from '../menu/MenuList';
import DishModal from './Modals/DishModal';
import DiscountModal from './Modals/DiscountModal';
import modalStyle from '../../styles/shared/modalStyle';
import { ModalContext } from '../../contexts/ModalContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import { AuthContext } from '../../contexts/AuthContext';
import { AppOutletContext } from '../../contexts/AppOutletContext';
import { getApolloErrors } from '../../apollo/ApolloProvider';
import { TranslateTextComponent, translate } from '../../translator';
import * as documents from '../../constants/cash/documentTypes';
import Check from './Modals/Check';
import { getFiscalPrinterPaymentCode } from '../../constants/cash/paymentMethods';
import { ROUTE_CASH } from '../../router/routes';
import useCustomQuery from '../../hooks/useCustomQuery';

const Cash = ({ fbOrderUUID, fbBillUUID, isPayingPM }) => {
  const [isFastCash, setIsFastCash] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [orderSummary, setOrderSummary] = useState({});
  const [fastOrdersSummary, setFastOrdersSummary] = useState([]);
  const [orderBills, setOrderBills] = useState([]);
  const [currentOrderBill, setCurrentOrderBill] = useState({});
  const [activeBillTabUUID, setActiveBillTabUUID] = useState(null);
  const [activeCourseTabUUID, setActiveCourseTabUUID] = useState(null);
  const [selectedChairs, setSelectedChairs] = useState([]);
  const [selectedRowUUID, setSelectedRowUUID] = useState(null);
  const [keyboardLabel, setKeyboardLabel] = useState('quantity');
  const [discountModalType, setDiscountModalType] = useState('');
  const [selectedPaymentMethodCode, setSelectedPaymentMethodCode] = useState('');
  const [selectedCreditCardType, setSelectedCreditCardType] = useState(null);
  const [selectedDish, setSelectedDish] = useState({});
  const [showDishModal, setShowDishModal] = useState(false);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [showCheckModal, setShowCheckModal] = useState(false);
  const [selectedPayMasterUUID, setSelectedPayMasterUUID] = useState(null);
  const [selectedGuestUUID, setSelectedGuestUUID] = useState(null);
  // Buttons
  const [showSplitOptions, setShowSplitOptions] = useState(false);
  const [showPayOptions, setShowPayOptions] = useState(false);
  const [showBillKeyboard, setShowBillKeyboard] = useState(false);
  const [showSummaryKeyboard, setShowSummaryKeyboard] = useState(false);
  const [showSplitChairs, setShowSplitChairs] = useState(false);
  const [showTablesModal, setShowTablesModal] = useState(false);
  const [showTransferBillModal, setShowTransferBillModal] = useState(false);
  const [showChargeModal, setShowChargeModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showSelectPersonsNumberModal, setShowSelectPersonsNumberModal] = useState(false);

  const classes = styles({ isFastCash });
  const history = useHistory();

  // Modal Context
  const modalClasses = modalStyle();
  const modalContext = useContext(ModalContext);
  const languageContext = useContext(LanguageContext);
  const { appOutletUUID } = useContext(AuthContext);
  const {
    appOutlet: { fiscalPrinterIpAddress, useFiscalPrinter }
  } = useContext(AppOutletContext);
  const fiscalPrinterMode = process.env.REACT_APP_ENV === 'PROD' && useFiscalPrinter ? 'production' : 'test';

  const fiscalPrinter = new FiscalPrinter(fiscalPrinterIpAddress, fiscalPrinterMode);

  // Check if an UUID is passed via url params
  useEffect(() => {
    if (fbOrderUUID) setIsFastCash(false);
  }, []);

  useEffect(() => {
    if (orderBills.length > 0 && !initialized && !isFastCash) {
      setActiveBillTabUUID(typy(orderBills[0], 'uuid').safeString);
      setInitialized(true);
    }
  }, [orderBills, initialized]);

  useEffect(() => {
    if (!isPayingPM) {
      setCurrentOrderBill({ ...orderBills.find((orderBill) => orderBill.uuid === activeBillTabUUID) });
    }
  }, [orderBills, activeBillTabUUID]);

  useEffect(() => {
    if (isFastCash && activeCourseTabUUID) {
      getOrderSummary.refetch({ fbOrderUUID: activeCourseTabUUID }).then((res) => {
        setOrderSummary({ ...typy(res, 'data.getOrderSummary').safeObject });
      });
      getOrderBills.refetch({ fbOrderUUID: activeCourseTabUUID }).then((res) => {
        const sortedOrderBill = orderBy(typy(res, 'data.getFbOrderBills.data').safeArray, ['status', 'id'], ['desc', 'asc']);
        setActiveBillTabUUID(sortedOrderBill[0] && sortedOrderBill[0].uuid);
        setOrderBills([...sortedOrderBill]);
      });
    }
  }, [activeCourseTabUUID]);

  // Queries
  const getOrderSummary = useCustomQuery(GET_ORDER_SUMMARY, {
    variables: { fbOrderUUID },
    onCompleted: (data) => {
      setOrderSummary({ ...typy(data, 'getOrderSummary').safeObject });
    },
    skip: isFastCash || isPayingPM,
    fetchPolicy: 'network-only'
  });

  useCustomQuery(GET_FB_BILL, {
    variables: { fbBillUUID },
    onCompleted: (data) => {
      setOrderBills([data?.getFbBill]);
      setCurrentOrderBill(data?.getFbBill);
    },
    skip: !isPayingPM,
    fetchPolicy: 'network-only'
  });

  const getFastOrders = useCustomQuery(GET_FAST_ORDERS, {
    variables: { fbOutletUUID: appOutletUUID, status: 'open' },
    onCompleted: (data) => {
      setFastOrdersSummary([...typy(data, 'getFastFbOrders.data').safeArray]);
      setActiveCourseTabUUID(typy(data, 'getFastFbOrders.data').safeArray[0].uuid);
      if (typy(data, 'getFastFbOrders.data').safeArray[0].uuid) {
        getOrderSummary.refetch({ fbOrderUUID: typy(data, 'getFastFbOrders.data').safeArray[0].uuid }).then((res) => {
          setOrderSummary({ ...typy(res, 'data.getOrderSummary').safeObject });
        });
      }
    },
    skip: !isFastCash || isPayingPM,
    fetchPolicy: 'network-only'
  });

  const getOrderBills = useCustomQuery(GET_ORDER_BILLS, {
    variables: { fbOrderUUID },
    skip: isFastCash,
    onCompleted: (data) => {
      setOrderBills([...orderBy(typy(data, 'getFbOrderBills.data').safeArray, ['status', 'id'], ['desc', 'asc'])]);
    },
    fetchPolicy: 'network-only'
  });

  const updateOrderSummary = () => {
    getOrderSummary.refetch().then((res) => setOrderSummary({ ...typy(res, 'data.getOrderSummary').safeObject }));
  };

  // Mutations
  const [createFastOrderBill] = useMutation(CREATE_FAST_ORDER_BILL); // fbOutletUUID
  const [createOrderBill] = useMutation(CREATE_ORDER_BILL); // fbBillUUID
  const [closeFastOrder] = useMutation(CLOSE_FAST_ORDER); // fbOrderUUID
  const [deleteOrderBill] = useMutation(DELETE_ORDER_BILL); // fbBillUUID
  const [addItemToBill] = useMutation(ADD_ITEM_TO_BILL); // quantity, fbBillUUID, fbCoursesMenuItemUUID
  const [addItemsToBill] = useMutation(ADD_ITEMS_TO_BILL); // fbBillUUID, fbCoursesMenuItemUUIDs
  const [addAllItemsToBill] = useMutation(ADD_ALL_ITEMS_TO_BILL); // fbBillUUID
  const [addOrderItemFromCash] = useMutation(ADD_ORDER_ITEM_FROM_CASH); // fbOrderUUID, fbCourseMenuItem
  const [removeItemFromBill] = useMutation(REMOVE_ITEM_FROM_BILL); // fbBillRowUUID
  const [removeAllItemsFromBill] = useMutation(REMOVE_ALL_ITEMS_FROM_BILL); // fbBillUUID
  const [deleteOrderItemFromCash] = useMutation(DELETE_ORDER_ITEM_FROM_CASH); // fbCourseMenuItemUUID
  const [deleteOrderItemsFromCash] = useMutation(DELETE_ORDER_ITEMS_FROM_CASH); // fbCourseMenuItemUUIDs
  const [addItemsToBillByChair] = useMutation(ADD_ITEMS_TO_BILL_BY_CHAIR); // fbBillUUID, fbChairUUIDs
  const [addBillToPayMaster] = useMutation(ADD_BILL_TO_PAY_MASTER); // fbBillUUID, payMasterUUID,
  const [payBill] = useMutation(PAY_BILL); // fbPaymentMethodUUID, fbBillUUID
  const [uploadBillDocument] = useMutation(UPLOAD_BILL_DOCUMENT); // fbBillUUID, base64image, documentType, name
  const [splitOrderEqually] = useMutation(SPLIT_ORDER_EQUALLY); // fbBillsNumber, fbOrderUUID
  const [setBillDiscount] = useMutation(SET_BILL_DISCOUNT); // discountPercentage, discountPercentageNotes, fbBillUUID
  const [setBillDiscountPercentage] = useMutation(SET_BILL_DISCOUNT_PERCENTAGE); // discount, discountNotes, fbBillUUID
  const [setBillItemQuantity] = useMutation(SET_BILL_ITEM_QUANTITY); // quantity, fbBillsRowUUID
  const [setBillItemPrice] = useMutation(SET_BILL_ITEM_PRICE); // quantity, fbBillsRowUUID
  const [cancelFbBill] = useMutation(CANCEL_FB_BILL);
  const [transferFbOrder] = useMutation(TRANSFER_FB_ORDER);

  // Menu actions
  const onDishClickHandler = (dish) => {
    setSelectedDish(dish);
    setShowDishModal(true);
  };

  const onModalDishSaveButtonClickHandler = ({ dish, quantity, withIngredients, withoutIngredients, notes }) => {
    const onlyWithIngredients = [];
    const onlyWithoutIngredients = [];

    withIngredients.forEach((ingredient) => {
      onlyWithIngredients.push(ingredient.label);
    });

    withoutIngredients.forEach((ingredient) => {
      onlyWithoutIngredients.push(ingredient.label);
    });

    addOrderItemFromCash({
      variables: {
        fbOrderUUID: !isFastCash ? fbOrderUUID : activeCourseTabUUID,
        fbCourseMenuItem: {
          fbMenuItemId: dish.id,
          quantity,
          with: onlyWithIngredients.join(', '),
          without: onlyWithoutIngredients.join(', '),
          notes
        }
      }
    })
      .then((res) => {
        closeModals();
        setOrderSummary({ ...typy(res, 'data.addOrderItemFromCash').safeObject });
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const onPayMasterChargeHandler = ({ documentType, base64image }) => {
    addBillToPayMaster({ variables: { fbBillUUID: activeBillTabUUID, payMasterUUID: selectedPayMasterUUID, guestUUID: selectedGuestUUID } })
      .then(() => {
        if (base64image) {
          uploadBillDocument({
            variables: {
              fbBillUUID: activeBillTabUUID,
              image: {
                base64image
              },
              documentType
            }
          }).catch((err) => {
            modalContext.openModal({
              class: 'danger',
              title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
              text: getApolloErrors(err).join(' - ')
            });
          });
        }

        getOrderBills
          .refetch()
          .then((data) => {
            setActiveBillTabUUID(orderBy(typy(data, 'data.getFbOrderBills.data').safeArray, ['status', 'id'], ['desc', 'desc'])[0].uuid);
            setOrderBills([...orderBy(typy(data, 'data.getFbOrderBills.data').safeArray, ['status', 'id'], ['desc', 'asc'])]);
          })
          .catch((err) => {
            modalContext.openModal({
              class: 'danger',
              title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
              text: getApolloErrors(err).join(' - ')
            });
          });

        getFastOrders.refetch().then((resPay) => {
          const sortedFastOrders = orderBy(typy(resPay, 'data.getFastFbOrders.data').safeArray, ['status', 'id'], ['desc', 'asc']);
          setActiveCourseTabUUID(sortedFastOrders[0].uuid);
          setFastOrdersSummary(sortedFastOrders);
          updateOrderSummary();
        });

        modalContext.openModal({
          class: 'success',
          title: <TranslateTextComponent capitalize>success-charge-title</TranslateTextComponent>,
          text: <TranslateTextComponent capitalize>success-charge-description</TranslateTextComponent>,
          actionButtons: [
            <Button
              key={0}
              variant="contained"
              color="primary"
              className={modalClasses.invertedButton}
              onClick={() => {
                modalContext.closeModal();
                closeModals();
              }}
            >
              <TranslateTextComponent uppercase>ok</TranslateTextComponent>
            </Button>
          ]
        });
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  // Tables actions
  const onCourseTabChangeHandler = (tabUUID) => {
    setActiveCourseTabUUID(tabUUID);
  };

  const onBillTabChangeHandler = (tabUUID) => {
    setActiveBillTabUUID(tabUUID);
  };

  const onMoreCourseTabButtonClickHandler = () => {
    createFastOrderBill({ variables: { fbOutletUUID: appOutletUUID } })
      .then((data) => {
        setActiveCourseTabUUID(orderBy(typy(data, 'data.createFastFbOrder.data').safeArray, ['status', 'id'], ['desc', 'desc'])[0].uuid);
        setFastOrdersSummary([...typy(data, 'data.createFastFbOrder.data').safeArray]);
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const onMoreBillTabButtonClickHandler = () => {
    createOrderBill({ variables: { fbOrderUUID: fbOrderUUID || activeCourseTabUUID } })
      .then((data) => {
        setActiveBillTabUUID(orderBy(typy(data, 'data.createOrderBill.data').safeArray, ['status', 'id'], ['desc', 'desc'])[0].uuid);
        setOrderBills([...orderBy(typy(data, 'data.createOrderBill.data').safeArray, ['status', 'id'], ['desc', 'asc'])]);
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const onCloseFastOrderTabClickHandler = (fbOrderUUID) => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>close-cash-tab-modal-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>close-cash-tab-modal-text</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            closeFastOrder({ variables: { fbOrderUUID } })
              .then((res) => {
                const sortedFastOrders = orderBy(typy(res, 'data.closeFastFbOrder.data').safeArray, ['status', 'id'], ['desc', 'asc']);
                setActiveCourseTabUUID(sortedFastOrders[0].uuid);
                setFastOrdersSummary(sortedFastOrders);
                updateOrderSummary();
                modalContext.closeModal();
              })
              .catch((err) => {
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const onCloseOrderBillTabClickHandler = (fbBillUUID) => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>close-cash-tab-modal-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>close-cash-tab-modal-text</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            deleteOrderBill({ variables: { fbBillUUID } })
              .then((res) => {
                const sortedOrderBill = orderBy(typy(res, 'data.deleteOrderBill.data').safeArray, ['status', 'id'], ['desc', 'asc']);
                setActiveBillTabUUID(sortedOrderBill[0].uuid);
                setOrderBills([...sortedOrderBill]);
                updateOrderSummary();
                modalContext.closeModal();
              })
              .catch((err) => {
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const onChairClickHandler = (fbChair) => {
    addItemToBill({ variables: { quantity: fbChair.quantity, fbBillUUID: activeBillTabUUID, fbCoursesMenuItemUUID: fbChair.uuid } })
      .then((data) => {
        setOrderBills([...orderBy(typy(data, 'data.addItemToBill.data').safeArray, ['status', 'id'], ['desc', 'asc'])]);
        updateOrderSummary();
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const onSetQuantityHandler = (billsRowUUID) => {
    setKeyboardLabel('quantity');
    setSelectedRowUUID(billsRowUUID);
    setShowBillKeyboard(true);
  };

  const onSetPriceHandler = (billsRowUUID) => {
    setKeyboardLabel('price');
    setSelectedRowUUID(billsRowUUID);
    setShowBillKeyboard(true);
  };

  const onKeyboardSubmitHandler = (value) => {
    if (keyboardLabel === 'quantity') {
      setBillItemQuantity({ variables: { quantity: parseFloat(value, 10), fbBillsRowUUID: selectedRowUUID } })
        .then((data) => {
          setOrderBills([...orderBy(typy(data, 'data.setFbBillItemQuantity.data').safeArray, ['status', 'id'], ['desc', 'asc'])]);
          setShowBillKeyboard(false);
          setSelectedRowUUID(null);
          updateOrderSummary();
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        });
    } else {
      setBillItemPrice({ variables: { price: parseFloat(value, 10), fbBillsRowUUID: selectedRowUUID } })
        .then((data) => {
          setOrderBills([...orderBy(typy(data, 'data.setFbBillItemPrice.data').safeArray, ['status', 'id'], ['desc', 'asc'])]);
          setShowBillKeyboard(false);
          setSelectedRowUUID(null);
          updateOrderSummary();
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        });
    }
  };

  const onDiscountSubmitHandler = ({ value, notes }) => {
    setBillDiscount({
      variables: { fbBillUUID: activeBillTabUUID || fbBillUUID, discount: value, discountNotes: notes }
    })
      .then((data) => {
        setOrderBills([...orderBy(typy(data, 'data.setFbBillIDiscount.data').safeArray, ['status', 'id'], ['desc', 'asc'])]);
        updateOrderSummary();
        closeModals();
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const onDiscountPercentageSubmitHandler = ({ value, notes }) => {
    setBillDiscountPercentage({
      variables: { fbBillUUID: activeBillTabUUID || fbBillUUID, discountPercentage: value, discountPercentageNotes: notes }
    })
      .then((data) => {
        setOrderBills([...orderBy(typy(data, 'data.setFbBillIDiscountPercentage.data').safeArray, ['status', 'id'], ['desc', 'asc'])]);
        updateOrderSummary();
        closeModals();
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const onDeleteDiscountHandler = () => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>remove-discount-modal-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>remove-discount-modal-text</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            setBillDiscount({
              variables: { fbBillUUID: activeBillTabUUID, discount: null, discountNotes: null }
            })
              .then((data) => {
                setOrderBills([...orderBy(typy(data, 'data.setFbBillIDiscount.data').safeArray, ['status', 'id'], ['desc', 'asc'])]);
                updateOrderSummary();
                closeModals();
                modalContext.closeModal();
              })
              .catch((err) => {
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const onDeleteDiscountPercentageHandler = () => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>remove-discount-modal-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>remove-discount-modal-text</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            setBillDiscountPercentage({
              variables: { fbBillUUID: activeBillTabUUID, discountPercentage: null, discountPercentageNotes: null }
            })
              .then((data) => {
                setOrderBills([...orderBy(typy(data, 'data.setFbBillIDiscountPercentage.data').safeArray, ['status', 'id'], ['desc', 'asc'])]);
                updateOrderSummary();
                closeModals();
                modalContext.closeModal();
              })
              .catch((err) => {
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const onAddItemsToBillHandler = (fbCoursesMenuItemUUIDs) => {
    addItemsToBill({
      variables: { fbBillUUID: activeBillTabUUID, fbCoursesMenuItemUUIDs: fbCoursesMenuItemUUIDs.map((menuItem) => menuItem.uuid) }
    })
      .then((data) => {
        setOrderBills([...orderBy(typy(data, 'data.addItemsToBill.data').safeArray, ['status', 'id'], ['desc', 'asc'])]);
        updateOrderSummary();
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const onAddAllItemsToBillHandler = () => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>add-all-items-modal-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>add-all-items-modal-text</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            addAllItemsToBill({ variables: { fbBillUUID: activeBillTabUUID } })
              .then((data) => {
                setOrderBills([...orderBy(typy(data, 'data.addAllItemsToBill.data').safeArray, ['status', 'id'], ['desc', 'asc'])]);
                updateOrderSummary();
                modalContext.closeModal();
              })
              .catch((err) => {
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const onDeleteCourseItemHandler = (fbCourseMenuItemUUID) => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>delete-all-item-from-cash-modal-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>delete-all-item-from-cash-modal-text</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            deleteOrderItemFromCash({ variables: { fbCourseMenuItemUUID } })
              .then(() => {
                getOrderBills.refetch().then((data) => {
                  setOrderBills([...orderBy(typy(data, 'data.getFbOrderBills.data').safeArray, ['status', 'id'], ['desc', 'asc'])]);
                });
                updateOrderSummary();
                modalContext.closeModal();
              })
              .catch((err) => {
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const onDeleteCourseItemsHandler = (fbCourseMenuItemUUIDs) => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>delete-all-items-from-cash-modal-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>delete-all-items-from-cash-modal-text</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            deleteOrderItemsFromCash({ variables: { fbCourseMenuItemUUIDs } })
              .then(() => {
                getOrderBills.refetch().then((data) => {
                  setOrderBills([...orderBy(typy(data, 'data.getFbOrderBills.data').safeArray, ['status', 'id'], ['desc', 'asc'])]);
                });
                updateOrderSummary();
                modalContext.closeModal();
              })
              .catch((err) => {
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const onDeleteBillItemHandler = (fbBillRowUUID) => {
    removeItemFromBill({ variables: { fbBillRowUUID } })
      .then((data) => {
        setOrderBills([...orderBy(typy(data, 'data.removeItemFromBill.data').safeArray, ['status', 'id'], ['desc', 'asc'])]);
        updateOrderSummary();
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const onDeleteAllBillItemsHandler = () => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>remove-all-items-modal-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>remove-all-items-modal-text</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            removeAllItemsFromBill({ variables: { fbBillUUID: activeBillTabUUID } })
              .then((data) => {
                setOrderBills([...orderBy(typy(data, 'data.removeAllItemsFromBill.data').safeArray, ['status', 'id'], ['desc', 'asc'])]);
                setActiveBillTabUUID(typy(data, 'data.removeAllItemsFromBill.data[0].uuid').safeString);
                updateOrderSummary();
                modalContext.closeModal();
              })
              .catch((err) => {
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const onShowBillKeyboardClickHandler = () => {
    setShowBillKeyboard(true);
  };

  const onShowSummaryKeyboardClickHandler = () => {
    setShowSummaryKeyboard(true);
  };

  const closeBillKeyboardHandler = () => {
    setSelectedRowUUID(null);
    setShowBillKeyboard(false);
  };

  const closeSummaryKeyboardHandler = () => {
    setShowSummaryKeyboard(false);
  };

  // Buttons actions handlers
  const onSplitClickHandler = () => {
    setShowSplitOptions(true);
  };

  const onSplitChairClickHandler = () => {
    setShowSplitChairs(true);
  };

  const onSplitEqually = (quantity) => {
    splitOrderEqually({ variables: { fbBillsNumber: quantity, fbOrderUUID: fbOrderUUID || activeCourseTabUUID } })
      .then((data) => {
        setOrderBills([...orderBy(typy(data, 'data.splitFbOrderEqually.data').safeArray, ['status', 'id'], ['desc', 'asc'])]);
        updateOrderSummary();
        closeModals();
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const onBackClickHandler = () => {
    setShowSplitOptions(false);
    setShowPayOptions(false);
  };

  const onTablesMapClickHandler = () => {
    setShowTablesModal(true);
  };

  const onTransferBillClickHandler = () => {
    setShowTransferBillModal(true);
  };

  const onChargeClickHandler = () => {
    setShowChargeModal(true);
  };

  const onDiscountClickHandler = (type) => {
    setDiscountModalType(type);
    setShowDiscountModal(true);
  };

  const onCancelFiscalReceipt = () => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>cancel-fiscal-receipt-modal-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>cancel-fiscal-receipt-modal-text</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            fiscalPrinter
              .cancelFiscalReceipt(currentOrderBill.documentNumber, moment(currentOrderBill.paymentDatetime).format('DD-MM-YYYY'))
              .then((res) => {
                if (res && res._attributes && res._attributes.success === 'true') {
                  cancelFbBill({
                    variables: {
                      fbBillUUID: activeBillTabUUID
                    }
                  })
                    .then((data) => {
                      setOrderBills([...orderBy(typy(data, 'data.cancelFbBill.data').safeArray, ['status', 'id'], ['desc', 'asc'])]);
                      updateOrderSummary();
                      modalContext.openModal({
                        class: 'success',
                        title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
                        text: <TranslateTextComponent capitalize>success-cancel-fiscal-receipt</TranslateTextComponent>
                      });
                    })
                    .catch((err) => {
                      modalContext.openModal({
                        class: 'danger',
                        title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                        text: getApolloErrors(err).join(' - ')
                      });
                    });
                } else {
                  modalContext.openModal({
                    class: 'danger',
                    title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                    text: `Error from fiscalPrinter (${res._attributes.code}: ${res._attributes.status})`
                  });
                }
              })
              .catch((err) => {
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: `Unable to send data to fiscal printer ${JSON.stringify(err)}`
                });
              });
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const onConfirmPayFbBill = (fiscalPrintType) => {
    if (fiscalPrintType === 'ticket') {
      const items = currentOrderBill.fbBillsRows.map((billRow) => ({
        description: billRow.name,
        quantity: billRow.quantity,
        unitPrice: billRow.price,
        department: '2' // iva
      }));
      const paymentTypeCode = getFiscalPrinterPaymentCode(selectedPaymentMethodCode);
      const paymentDescription = translate(selectedPaymentMethodCode, languageContext.language);
      fiscalPrinter
        .printFiscalReceipt(items, paymentTypeCode, '0', paymentDescription.toUpperCase(), currentOrderBill.discountValue)
        .then((res) => {
          if (res && res._attributes && res._attributes.success === 'true') {
            const { addInfo } = res;
            const fiscalReceiptDate = (date) => {
              const splitted = date.split('/');
              const joined = `${splitted[2]}-${splitted[1]}-${splitted[0]}`;
              return moment(joined).format('YYYY-MM-DD');
            };
            payBill({
              variables: {
                paymentMethodCode: selectedPaymentMethodCode,
                fbBillUUID: isPayingPM ? fbBillUUID : activeBillTabUUID,
                creditCardType: selectedCreditCardType,
                fiscalPrintType,
                zRepNumber: addInfo.zRepNumber._text,
                fiscalReceiptNumber: addInfo.fiscalReceiptNumber._text,
                fiscalReceiptAmount: addInfo.fiscalReceiptAmount._text,
                fiscalReceiptDate: fiscalReceiptDate(addInfo.fiscalReceiptDate._text),
                fiscalReceiptTime: addInfo.fiscalReceiptTime._text
              }
            })
              .then((data) => {
                setOrderBills([...orderBy(typy(data, 'data.payFbBill.data').safeArray, ['status', 'id'], ['desc', 'asc'])]);
                closeModals();
                setShowSplitOptions(null);
                setShowPayOptions(null);
                getFastOrders.refetch().then((resPay) => {
                  const sortedFastOrders = orderBy(typy(resPay, 'data.getFastFbOrders.data').safeArray, ['status', 'id'], ['desc', 'asc']);
                  setActiveCourseTabUUID(sortedFastOrders[0].uuid);
                  setFastOrdersSummary(sortedFastOrders);
                  updateOrderSummary();
                });
                modalContext.openModal({
                  class: 'success',
                  title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
                  text: <TranslateTextComponent capitalize>payment-success</TranslateTextComponent>,
                  actionButtons: [
                    <Button
                      key={1}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        modalContext.closeModal();
                        if (isPayingPM) {
                          history.push(ROUTE_CASH);
                        }
                      }}
                    >
                      <TranslateTextComponent uppercase>ok</TranslateTextComponent>
                    </Button>
                  ]
                });
              })
              .catch((err) => {
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - ')
                });
              });
          } else {
            modalContext.openModal({
              class: 'danger',
              title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
              text: `Error from fiscalPrinter: ${JSON.stringify(res)}`
            });
          }
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: `Unable to send data to fiscal printer ${JSON.stringify(err)}`
          });
        });
    }
  };

  const onPayMethodClickHandler = (paymentMethodCode, creditCardType = null) => {
    setSelectedPaymentMethodCode(paymentMethodCode);
    setSelectedCreditCardType(creditCardType);
    setShowPaymentModal(true);
  };

  const onXPersonsClickHandler = () => {
    setShowSelectPersonsNumberModal(true);
  };

  const onPayClickHandler = () => {
    setShowPayOptions(true);
  };

  const onSelectChairHandler = (chairUUID) => {
    if (selectedChairs.includes(chairUUID)) {
      const chairs = selectedChairs.filter((uuid) => uuid !== chairUUID);
      setSelectedChairs([...chairs]);
    } else setSelectedChairs([...selectedChairs, chairUUID]);
  };

  const onSelectChairSaveButtonHandler = () => {
    addItemsToBillByChair({ variables: { fbBillUUID: activeBillTabUUID, fbChairUUIDs: selectedChairs } })
      .then((data) => {
        setOrderBills([...orderBy(typy(data, 'data.addItemsToBillByChair.data').safeArray, ['status', 'id'], ['desc', 'asc'])]);
        updateOrderSummary();
        setShowSplitChairs(false);
        setSelectedChairs([]);
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const onSelectChairCancelButtonHandler = () => {
    setSelectedChairs([]);
    setShowSplitChairs(false);
  };

  const onAcquireCheckHandler = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      uploadBillDocument({
        variables: {
          fbBillUUID: activeBillTabUUID,
          image: {
            base64image: reader.result
          },
          documentType: documents.PAPER_CHECK
        }
      })
        .then(() =>
          getOrderBills.refetch().then((res) => {
            const sortedOrderBill = orderBy(typy(res, 'data.getFbOrderBills.data').safeArray, ['status', 'id'], ['desc', 'asc']);
            setActiveBillTabUUID(sortedOrderBill[0] && sortedOrderBill[0].uuid);
            setOrderBills([...sortedOrderBill]);
          })
        )
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        });
    };
  };

  const onDisplayCheckHandler = () => {
    setShowCheckModal(true);
  };

  // Modals
  const closeModals = () => {
    setShowTablesModal(false);
    setShowTransferBillModal(false);
    setShowChargeModal(false);
    setShowSelectPersonsNumberModal(false);
    setShowPaymentModal(false);
    setShowDishModal(false);
    setShowDiscountModal(false);
    setSelectedPayMasterUUID(null);
    setSelectedGuestUUID(null);
    setShowCheckModal(false);
    setSelectedCreditCardType(null);
    setSelectedPaymentMethodCode(null);
  };

  const onTableClickHandler = (table) => {
    if (
      table &&
      table.fbReservationTables &&
      table.fbReservationTables.length > 0 &&
      table &&
      table.fbReservationTables &&
      table.fbReservationTables[0] &&
      table.fbReservationTables[0].fbOrder !== null
    ) {
      const orderUUID = typy(table, 'fbReservationTables[0].fbOrder.uuid').safeString;
      window.location.href = `/cash/${orderUUID}`;
    }
  };

  const onTransferBillTableClickHandler = (fbTable) => {
    transferFbOrder({
      variables: {
        fbOrderUUID: activeCourseTabUUID,
        fbTableUUID: fbTable.uuid
      }
    })
      .then(() => {
        modalContext.openModal({
          class: 'success',
          title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
          text: <TranslateTextComponent capitalize>success-cancel-fiscal-receipt</TranslateTextComponent>,
          actionButtons: [
            <Button
              key={0}
              variant="contained"
              color="primary"
              className={modalClasses.invertedButton}
              onClick={() => {
                modalContext.closeModal();
                closeModals();
                window.location.href = `/cash/${activeCourseTabUUID}`;
              }}
            >
              <TranslateTextComponent uppercase>ok</TranslateTextComponent>
            </Button>
          ]
        });
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  const onPayMasterCheckHandler = (payMasterUUID) => {
    setSelectedPayMasterUUID((prev) => (prev === payMasterUUID ? null : payMasterUUID));
    const nodes = document.querySelectorAll('.pm-checkbox');
    nodes.forEach((node) => {
      if (node.id !== payMasterUUID) {
        node.checked = false;
      }
    });
  };

  const onPayMasterDetailCheckHandler = ({ payMasterUUID, guestUUID }) => {
    setSelectedPayMasterUUID(payMasterUUID);
    setSelectedGuestUUID((prev) => (prev === guestUUID ? null : guestUUID));
    const guestNodes = document.querySelectorAll('.pm-detail-checkbox');
    const pmNodes = document.querySelectorAll('.pm-checkbox');
    guestNodes.forEach((node) => {
      if (node.id !== guestUUID) {
        node.checked = false;
      }
    });
    pmNodes.forEach((node) => {
      if (node.id !== payMasterUUID) {
        node.checked = false;
      }
    });
  };

  return (
    <Box className={classes.container}>
      {!isFastCash && <GuestReservation fbOrderUUID={fbOrderUUID} />}
      <Box style={{ gridArea: 'menu-area' }}>{!isPayingPM && <MenuList dishClick={onDishClickHandler} standalone />}</Box>
      <Box style={{ gridArea: 'summary-area' }}>
        {!isPayingPM && (
          <CourseSummary
            isFastCash={isFastCash}
            activeCourseTabUUID={activeCourseTabUUID}
            orderSummary={orderSummary}
            fastOrdersSummary={fastOrdersSummary}
            showKeyboard={showSummaryKeyboard}
            total={typy(orderSummary, 'total').safeNumber}
            onCourseTabChange={onCourseTabChangeHandler}
            onMoreTabButtonClick={onMoreCourseTabButtonClickHandler}
            onCloseFastOrderTabClick={onCloseFastOrderTabClickHandler}
            addItemsToBill={onAddItemsToBillHandler}
            addAllItemsToBill={onAddAllItemsToBillHandler}
            deleteItem={onDeleteCourseItemHandler}
            deleteAllItems={onDeleteCourseItemsHandler}
            onChairClick={onChairClickHandler}
            onShowKeyboardClick={onShowSummaryKeyboardClickHandler}
            closeKeyboard={closeSummaryKeyboardHandler}
          />
        )}
      </Box>
      <Box style={{ gridArea: 'bill-area', paddingLeft: 10, paddingRight: 10, borderRight: `2px solid ${primaryColor}` }}>
        <BillSummary
          isPayingPM={isPayingPM}
          isFastCash={isFastCash}
          activeBillTabUUID={fbBillUUID || activeBillTabUUID}
          orderBills={orderBills}
          showKeyboard={showBillKeyboard}
          keyboardLabel={keyboardLabel}
          selectedRowUUID={selectedRowUUID}
          onBillTabChange={onBillTabChangeHandler}
          onMoreTabButtonClick={onMoreBillTabButtonClickHandler}
          onCloseOrderBillTabClick={onCloseOrderBillTabClickHandler}
          openDiscountModal={onDiscountClickHandler}
          onDeleteDiscount={onDeleteDiscountHandler}
          onDeleteDiscountPercentage={onDeleteDiscountPercentageHandler}
          onSetQuantity={onSetQuantityHandler}
          onSetPrice={onSetPriceHandler}
          onKeyboardSubmit={onKeyboardSubmitHandler}
          deleteBillItem={onDeleteBillItemHandler}
          deleteAllBillItems={onDeleteAllBillItemsHandler}
          onShowKeyboardClick={onShowBillKeyboardClickHandler}
          closeKeyboard={closeBillKeyboardHandler}
        />
      </Box>
      <Box style={{ gridArea: 'buttons-area', padding: 5, display: 'flex', justifyContent: 'center' }}>
        <ButtonsList
          isPayingPM={isPayingPM}
          fbOrderUUID={isFastCash ? activeCourseTabUUID : fbOrderUUID}
          showSplitOptions={showSplitOptions}
          showPayOptions={showPayOptions}
          showTablesModal={showTablesModal}
          showChargeModal={showChargeModal}
          chairs={typy(orderSummary, 'items').safeArray.map((order) => order.fbChair)}
          activeBillTabUUID={activeBillTabUUID}
          orderBills={orderBills}
          selectedChairs={selectedChairs}
          showSplitChairs={showSplitChairs}
          currentOrderBill={currentOrderBill}
          onSplitChairClick={onSplitChairClickHandler}
          onSelectChair={onSelectChairHandler}
          onSplitClick={onSplitClickHandler}
          onSelectChairSaveButton={onSelectChairSaveButtonHandler}
          onSelectChairCancelButton={onSelectChairCancelButtonHandler}
          onBackClick={onBackClickHandler}
          onTransferBillClick={onTransferBillClickHandler}
          onTablesMapClick={onTablesMapClickHandler}
          onChargeClick={onChargeClickHandler}
          onDiscountClick={onDiscountClickHandler}
          onPayMethodClick={onPayMethodClickHandler}
          onXPersonsClick={onXPersonsClickHandler}
          onPayClick={onPayClickHandler}
          onCancelFiscalReceipt={onCancelFiscalReceipt}
          onAcquireCheck={onAcquireCheckHandler}
          onDisplayCheck={onDisplayCheckHandler}
        />
      </Box>
      {showDishModal && (
        <DishModal showDishModal={showDishModal} selectedDish={selectedDish} closeModal={closeModals} onSave={onModalDishSaveButtonClickHandler} />
      )}
      <TablesModal
        showTablesModal={showTablesModal || showTransferBillModal}
        onTableClick={showTransferBillModal ? onTransferBillTableClickHandler : onTableClickHandler}
        closeModal={closeModals}
      />
      {showChargeModal && (
        <ChargeModal
          activeBillTabUUID={activeBillTabUUID}
          showChargeModal={showChargeModal}
          selectedPayMasterUUID={selectedPayMasterUUID}
          selectedGuestUUID={selectedGuestUUID}
          currentOrderBill={currentOrderBill}
          closeModal={closeModals}
          onPayMasterCheck={onPayMasterCheckHandler}
          onPayMasterDetailCheck={onPayMasterDetailCheckHandler}
          onPayMasterCharge={onPayMasterChargeHandler}
        />
      )}
      {showSelectPersonsNumberModal && (
        <SelectPersonNumberModal
          showSelectPersonsNumberModal={showSelectPersonsNumberModal}
          closeModal={closeModals}
          onSplitEqually={onSplitEqually}
        />
      )}
      {showPaymentModal && (
        <PaymentModal
          showPaymentModal={showPaymentModal}
          closeModal={closeModals}
          selectedPaymentMethodCode={selectedPaymentMethodCode}
          selectedCreditCardType={selectedCreditCardType}
          onConfirmPayFbBill={onConfirmPayFbBill}
        />
      )}
      {showDiscountModal && (
        <DiscountModal
          showDiscountModal={showDiscountModal}
          closeModal={closeModals}
          discountModalType={discountModalType}
          currentDiscount={
            discountModalType === 'percentage'
              ? currentOrderBill && currentOrderBill.discountPercentage && currentOrderBill.discountPercentage.toString()
              : currentOrderBill && currentOrderBill.discount && currentOrderBill.discount.toString()
          }
          currentNotes={
            discountModalType === 'percentage'
              ? currentOrderBill && currentOrderBill.discountPercentageNotes
              : currentOrderBill && currentOrderBill.discountNotes
          }
          total={currentOrderBill.total}
          onDiscountSubmit={onDiscountSubmitHandler}
          onDiscountPercentageSubmit={onDiscountPercentageSubmitHandler}
        />
      )}
      {showCheckModal && <Check showCheckModal={showCheckModal} currentOrderBill={currentOrderBill} closeModal={closeModals} />}
    </Box>
  );
};

export default Cash;
