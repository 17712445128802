import React from 'react';
import { Box } from '@material-ui/core';
import { CustomModal, MaterialIcon, primaryColor, CustomButton } from '../../../common-fe/src';
import styles from '../../../styles/cash/modals/paymentModalStyle';
import { TranslateTextComponent } from '../../../translator';

const PaymentModal = ({ selectedPaymentMethodCode, showPaymentModal, closeModal, onConfirmPayFbBill }) => {
  const classes = styles();

  return (
    <CustomModal
      height={290}
      showModal={showPaymentModal}
      styleBody={{
        display: 'grid',
        gridTemplateAreas: '"icon" "label" "payment" "choice" "buttons"',
        gridTemplateColumns: '1fr',
        color: '#4D4D4D',
        fontSize: 18,
        padding: 5
      }}
      onClose={closeModal}
    >
      <Box className={classes.area} style={{ gridArea: 'icon' }}>
        <MaterialIcon iconName="Help" style={{ color: primaryColor, fontSize: '4rem' }} />
      </Box>
      <Box className={classes.area} style={{ gridArea: 'label' }}>
        <TranslateTextComponent capitalize>payment-method-modal-title</TranslateTextComponent>
      </Box>
      <Box className={classes.area} style={{ gridArea: 'payment', fontWeight: 'bold', fontSize: 24 }}>
        <TranslateTextComponent uppercase style={{ fontWeight: 'bold' }}>
          {selectedPaymentMethodCode}
        </TranslateTextComponent>
      </Box>
      <Box className={classes.area} style={{ gridArea: 'choice' }}>
        <TranslateTextComponent capitalize style={{ fontWeight: 'bold' }}>
          payment-method-modal-choice
        </TranslateTextComponent>
      </Box>
      <Box
        className={classes.area}
        style={{ gridArea: 'buttons', display: 'flex', justifyContent: 'space-between', paddingLeft: 20, paddingRight: 20 }}
      >
        <CustomButton style={{ width: 'auto', color: 'white', backgroundColor: '#CB2D24' }} onClick={closeModal}>
          <TranslateTextComponent>cancel</TranslateTextComponent>
        </CustomButton>

        <CustomButton style={{ width: 'auto' }} onClick={() => onConfirmPayFbBill('ticket')}>
          <TranslateTextComponent>ticket</TranslateTextComponent>
        </CustomButton>
        {/* <CustomButton style={{ width: 'auto' }}>
          <TranslateTextComponent>pro-forma</TranslateTextComponent>
        </CustomButton> */}
      </Box>
    </CustomModal>
  );
};

export default PaymentModal;
