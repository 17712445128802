import React, { useContext } from 'react';
import { Box, Grid, Typography, Chip, Paper, Button } from '@material-ui/core/';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { LanguageContext } from '../../contexts/LanguageContext';
import { TranslateTextComponent, translate, capitalizeFirstLetter } from '../../translator';
import KidsAgeBox from './KidsAgeBox';
import CustomInput from '../shared/CustomInput';
import styles from '../../styles/saveReservation/newReservationStyle';
import ecStyles from '../../styles/saveReservation/AvailabilityStyle';

const PhysicalCategoriesBox = ({
  closePhysicalCategoriesBox,
  addFbGuest,
  removeFbGuest,
  fbGuestList,
  onDeleteClick,
  showCategoryInput,
  editFbGuestAge,
  confirmFbGuestList,
  physicalCategories,
  setShowCategoryAgeInput
}) => {
  const classes = styles();
  const classesAvailability = ecStyles();

  const languageContext = useContext(LanguageContext);

  return (
    <Paper className={classes.paper}>
      <Box>
        <Chip
          variant="outlined"
          size="small"
          label={translate('nbr-persons', languageContext.language).toUpperCase()}
          onDelete={closePhysicalCategoriesBox}
          className={classes.chip}
        />
      </Box>
      <Box>
        <Grid container style={{ paddingBottom: 10 }}>
          {physicalCategories
            .sort((a, b) => b.ageMin - a.ageMin)
            .map((category, index) => (
              <Grid container style={{ marginTop: 15 }} key={index}>
                <Grid item xs={3}>
                  <Typography variant="h3" className={classesAvailability.categoryCounter}>
                    {fbGuestList.filter((fbGuest) => fbGuest.guestPhysicalCategoryId === Number(category.id)).length}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="body1" className={classesAvailability.categorytext}>
                    {category.description}
                  </Typography>
                  <Typography variant="caption" className={classesAvailability.categoryRangeAge}>
                    {category.ageRange}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Button className={classesAvailability.iconButton} onClick={() => removeFbGuest(category)}>
                    <RemoveIcon className={classesAvailability.icons} />
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button className={classesAvailability.iconButton} style={{ justifyContent: 'left' }} onClick={() => addFbGuest(category)}>
                    <AddIcon className={classesAvailability.icons} />
                  </Button>
                </Grid>
                <Grid item xs className={classesAvailability.gridInput}>
                  {category.hasAgeInput && (
                    <CustomInput
                      disableUnderline
                      id={`${category.id}`}
                      placeholder={capitalizeFirstLetter(translate('ages', languageContext.language))}
                      onClick={() => setShowCategoryAgeInput(category)}
                    />
                  )}
                </Grid>
                {category.name === showCategoryInput.name && (
                  <KidsAgeBox category={category} fbGuestList={fbGuestList} editFbGuestAge={editFbGuestAge} />
                )}
              </Grid>
            ))}
        </Grid>
      </Box>
      <Box>
        <Grid container style={{ paddingBottom: 10 }}>
          <Grid item xs={6} className={classesAvailability.buttonContainer}>
            <Button className={classesAvailability.deleteButton} onClick={onDeleteClick}>
              <TranslateTextComponent capitalize>delete</TranslateTextComponent>
            </Button>
          </Grid>
          <Grid item xs={6} className={classesAvailability.buttonContainer}>
            <Button onClick={confirmFbGuestList} className={classesAvailability.confirmButton}>
              <TranslateTextComponent capitalize>confirm</TranslateTextComponent>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default PhysicalCategoriesBox;
