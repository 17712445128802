import React from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { Box, Grid, List, ListItem } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import VerticalTab from './components/shared/VerticalTab';
import Header from './components/shared/Header';
import { ReactComponent as Img } from './assets/images/home.svg';
import { appTheme, invertedPrimary } from './styles/theme';
import leftSidebarLayoutStyles from './styles/shared/leftSidebarLayoutStyle';
import FiscalPrinterTestPage from './pages/FiscalPrinterTestPage';

const MenuContent = () => {
  return (
    <List>
      <ListItem>item1</ListItem>
      <ListItem>item2</ListItem>
    </List>
  );
};
const TestPage = (props) => {
  const layoutClasses = leftSidebarLayoutStyles();
  // user, menuContent
  const user = { username: 'NameTest' };
  return (
    <Box>
      <Header section="settings" user={user} menuContent={<MenuContent />} />
      <Box className={layoutClasses.verticalTabContainer}>
        <VerticalTab
          history={props.history}
          buttons={[{ id: 1, text: 'title', route: '/', img: <Img /> }, { id: 2, text: 'title', route: '/', img: <Img /> }]}
        />
        <Box className={layoutClasses.layoutContainer} />
      </Box>
    </Box>
  );
};
const App = () => {
  return (
    <MuiThemeProvider theme={appTheme}>
      <CssBaseline />
      <Router>
        <Route path="/" exact component={TestPage} />
        <Route path="/fiscal-printer" exact component={FiscalPrinterTestPage} />
      </Router>
    </MuiThemeProvider>
  );
};

export default App;
