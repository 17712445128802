import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import { translate, capitalizeFirstLetter } from '../../translator';
import { LanguageContext, languageConfig } from '../../contexts/LanguageContext';
import VerticalTabs from './VerticalTabs';
import Header from './Header';
import leftSidebarLayoutStyles from '../../common-fe/src/styles/shared/leftSidebarLayoutStyle';
import buttons from '../../fixtures/shared/verticalTab';
import HeaderMenuContent from './HeaderMenuContent';
import LanguageSwitcher from './LanguageSwitcher';
import { AuthContext } from '../../contexts/AuthContext';

const Layout = ({ children, history, section, fullScreenContainer, fixed }) => {
  const { user } = useContext(AuthContext);
  const { language } = useContext(LanguageContext);
  const layoutClasses = leftSidebarLayoutStyles();

  return (
    <Box style={fixed ? { position: 'fixed', width: '100%' } : {}}>
      <Header
        section={capitalizeFirstLetter(translate(section, language))}
        menuContent={<HeaderMenuContent />}
        iconLanguageSelected={languageConfig[language]?.icon}
        languageSelected={languageConfig[language]?.languageLabel}
        languageSwitcher={<LanguageSwitcher />}
      />
      <Box className={fullScreenContainer ? layoutClasses.fullScreenMainContainer : layoutClasses.mainContainer}>
        <VerticalTabs history={history} buttons={buttons} userRoles={user?.roles?.map((r) => r.name)} />
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
