import React from 'react';
import { Box } from '@material-ui/core';
import styles from '../../styles/shared/coloredCircle';

const ColoredCircle = ({ title = '', size = 15, color = 'gray', highlighted, style }) => {
  const classes = styles({ size, color, highlighted });

  return (
    <Box className={classes.container} title={title.charAt(0).toUpperCase() + title.substring(1)} style={style}>
      <Box className={classes.filled} />
    </Box>
  );
};

export default ColoredCircle;
