import React, { useState, useContext, useEffect } from 'react';
import { Formik } from 'formik';
import { isNumeric } from 'validator';
import { Box, Typography, Input as MUIInput, Fab, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TranslateTextComponent, translate, capitalizeFirstLetter } from '../../../translator';
import { LanguageContext } from '../../../contexts/LanguageContext';
import style from '../../../styles/roomEditor/tableEditHeader/style';
import { Dropdown } from '../../../common-fe';
import modalStyle from '../../../common-fe/src/styles/shared/modalStyle';
import Shape from '../Shape';
import { ModalContext } from '../../../contexts/ModalContext';

const TableEditHeader = ({ showTableEditor, closeTableEditor, table, updateTable, deleteTable }) => {
  const [top, setTop] = useState(190);
  const classes = style({ top });
  const modalClasses = modalStyle();
  const { language } = useContext(LanguageContext);
  const modalContext = useContext(ModalContext);

  useEffect(() => {
    document.addEventListener('scroll', onScrollHandler);
  });

  const onScrollHandler = () => {
    if (window.scrollY > 40) setTop(0);
    else setTop(190);
  };

  const onUpdate = (id, values) => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent uppercase>save-table-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>save-table</TranslateTextComponent>,
      actionButtons: [
        <Button key={0} variant="outlined" color="primary" className={modalClasses.invertedButton} onClick={modalContext.closeModal}>
          <TranslateTextComponent>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            updateTable(id, values);
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const onDeleteTable = () => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent uppercase>delete-table-title</TranslateTextComponent>,
      text: `${capitalizeFirstLetter(translate('delete-table', language))} ${table.number}?`,
      actionButtons: [
        <Button key={0} variant="outlined" color="primary" className={modalClasses.invertedButton} onClick={modalContext.closeModal}>
          <TranslateTextComponent>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            deleteTable(table.id);
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  return (
    showTableEditor && (
      <Formik
        enableReinitialize
        initialValues={{
          number: table.name || table.number || '',
          shape: table.shape,
          capacity: table.capacity,
          isActive: table.isActive || false,
          tabletop: table.tabletop || false
        }}
        validate={(values) => {
          const errors = {};
          // Validate Capacity
          if (!values.capacity) errors.capacity = capitalizeFirstLetter(translate('capacity-error', language));
          else if (typeof values.capacity === 'number' || (typeof values.capacity === 'string' && isNumeric(values.capacity))) {
            if (values.capacity <= 0) errors.capacity = capitalizeFirstLetter(translate('invalid-number', language));
          } else if (typeof values.capacity === 'string' && !isNumeric(values.capacity))
            errors.capacity = capitalizeFirstLetter(translate('invalid-number', language));

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          onUpdate({ ...table, ...values });
          setSubmitting(false);
        }}
      >
        {({ values, handleSubmit, handleChange, setFieldValue, handleBlur, isSubmitting, errors }) => (
          <form className={classes.container} onSubmit={handleSubmit}>
            <Box className={classes.tableName}>
              <Fab color="primary" aria-label="add" className={classes.fabIcon} onClick={closeTableEditor}>
                <CloseIcon />
              </Fab>
              <Typography>
                <TranslateTextComponent capitalize>table-n</TranslateTextComponent>
              </Typography>
              <MUIInput
                name="number"
                disableUnderline
                className={classes.numberInput}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.number}
              />
            </Box>
            <Box className={classes.shape}>
              <Dropdown
                name="shape"
                value={values.shape}
                renderValue={() => {
                  if (values.shape === 'round') return capitalizeFirstLetter(translate('round', language));
                  if (values.shape === 'square') return capitalizeFirstLetter(translate('square', language));
                  if (values.shape === 'rectangle') return capitalizeFirstLetter(translate('rectangle', language));
                  return <TranslateTextComponent capitalize>shape-type</TranslateTextComponent>;
                }}
                items={[
                  { label: <Shape shape="round" selected={values.shape === 'round'} />, value: 'round' },
                  { label: <Shape shape="square" selected={values.shape === 'square'} />, value: 'square' },
                  {
                    label: <Shape shape="rectangle" selected={values.shape === 'rectangle'} />,
                    value: 'rectangle'
                  }
                ]}
                dropdownStyle={{ minWidth: 135, fontSize: 12 }}
                onChange={handleChange}
              />
            </Box>
            <Box className={classes.capacity}>
              <Typography>
                <TranslateTextComponent capitalize>capacity</TranslateTextComponent>
              </Typography>
              <MUIInput
                className={classes.numberInput}
                name="capacity"
                value={values.capacity}
                onChange={handleChange}
                onBlur={handleBlur}
                disableUnderline
              />
            </Box>
            <Typography className={classes.error} color="error" style={{ gridArea: 'capacity-error' }}>
              {errors.capacity}
            </Typography>
            <Box className={classes.hidden}>
              <FormControlLabel
                className={classes.formControlLabel}
                labelPlacement="start"
                control={
                  <Checkbox
                    name="isActive"
                    value={values.isActive}
                    color="primary"
                    checked={!values.isActive}
                    onChange={() => {
                      setFieldValue('isActive', !values.isActive);
                    }}
                    onBlur={handleBlur}
                  />
                }
                label={capitalizeFirstLetter(translate('hidden', language))}
              />
            </Box>
            <Box className={classes.tabletop}>
              <FormControlLabel
                className={classes.formControlLabel}
                labelPlacement="start"
                control={
                  <Checkbox
                    name="tabletop"
                    value={values.tabletop}
                    color="primary"
                    disabled={values.shape === 'round'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                }
                label={capitalizeFirstLetter(translate('tabletop-seats', language))}
              />
            </Box>
            <Box className={classes.buttonsArea}>
              <Button className={classes.saveButton} type="submit" variant="outlined" disabled={isSubmitting}>
                <TranslateTextComponent uppercase>save</TranslateTextComponent>
              </Button>
              <Button className={classes.deleteButton} type="button" variant="outlined" onClick={onDeleteTable}>
                <TranslateTextComponent uppercase>delete</TranslateTextComponent>
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    )
  );
};

export default TableEditHeader;
