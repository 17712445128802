import gql from 'graphql-tag';
import { FB_SERVICE_REVENUE_FRAGMENT } from '../fragments';

export const SAVE_FB_SERVICE_REVENUE = gql`
  mutation saveFbServiceRevenue($fbServiceRevenueData: FbServiceRevenueInputDto!, $fbServiceUUID: String!, $fbServiceRevenueUUID: String) {
    saveFbServiceRevenue(fbServiceRevenueData: $fbServiceRevenueData, fbServiceUUID: $fbServiceUUID, fbServiceRevenueUUID: $fbServiceRevenueUUID) {
      ...fbServiceRevenueFragment
    }
  }
  ${FB_SERVICE_REVENUE_FRAGMENT}
`;

export const DELETE_FB_SERVICE_REVENUE = gql`
  mutation deleteFbServiceRevenue($fbServiceRevenueUUID: String!) {
    deleteFbServiceRevenue(fbServiceRevenueUUID: $fbServiceRevenueUUID)
  }
`;
