import React from 'react';
import { Box, Tabs, Tab } from '@material-ui/core/';
import styles from '../../styles/shared/settingsStyle';
import { invertedPrimaryCC80 } from '../../styles/theme';

const RenderTabs = ({ tabElements, value, variant, scrollButtons, onChange, containerStyle }) => {
  const renderTab = () => {
    return tabElements.map((tab, index) => (
      <Tab
        key={`tab${index}`}
        value={`${tab.key}`}
        label={tab.label}
        className={value === tab.key ? classes.tabTextSelected : classes.tabText}
        style={{ ...tab.tabStyle }}
        index={index}
      />
    ));
  };
  const classes = styles();
  return (
    <Box style={{ borderBottom: `1px solid ${invertedPrimaryCC80}`, ...containerStyle }}>
      <Tabs classes={{ indicator: classes.indicator }} value={value} variant={variant} scrollButtons={scrollButtons} onChange={onChange}>
        {renderTab()}
      </Tabs>
    </Box>
  );
};
export default RenderTabs;
