import React, { useState, useContext } from 'react';
import { Button, IconButton, Box } from '@material-ui/core/';
import typy from 'typy';
import { useMutation } from '@apollo/react-hooks';
import AddIcon from '@material-ui/icons/Add';
import { CommonTable } from '../../../common-fe';
import customObjectValidator from '../../../utils/validator';
import { GET_FB_ROOMS } from '../../../graphql/settings/room/queries';
import { GET_FB_SERVICES } from '../../../graphql/settings/service/queries';
import { DELETE_FB_ROOM_MUTATION, EDIT_FB_ROOM_MUTATION, ADD_FB_ROOM_MUTATION } from '../../../graphql/settings/room/mutations';
import { ModalContext } from '../../../contexts/ModalContext';
import { AuthContext } from '../../../contexts/AuthContext';
import RoomForm from './RoomForm';
import { TranslateTextComponent } from '../../../translator';
import { getApolloErrors } from '../../../apollo/ApolloProvider';
import getRoomColumns from '../../../constants/settings/roomColumns';
import modalStyle from '../../../common-fe/src/styles/shared/modalStyle';
import useCustomQuery from '../../../hooks/useCustomQuery';

const FORM_VALIDATION = {
  name: {
    isRequired: true,
  },
  maxPax: {
    isRequired: true,
    validateFunctions: [
      {
        fn: (value) => typy(Number(value)).safeNumber > 0,
        errorMessage: 'Insert positive number',
      },
    ],
  },
  isActive: {
    isRequired: true,
  },
  hasOnlineReservations: {
    isRequired: true,
  },
  fbServices: {
    isRequired: true,
    validateFunctions: [
      {
        fn: (value) => value.length > 0,
        errorMessage: 'Select at least one service',
      },
    ],
  },
};

const initialState = {
  name: '',
  maxPax: null,
  hasOnlineReservations: '',
  isActive: '',
  fbServices: [],
};

const RoomSettings = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [roomSettings, setRoomSettings] = useState(initialState);
  const classesModal = modalStyle();
  const modalContext = useContext(ModalContext);
  const { appOutletUUID } = useContext(AuthContext);

  const [addFbRoom] = useMutation(ADD_FB_ROOM_MUTATION);

  const [deleteFbRoom] = useMutation(DELETE_FB_ROOM_MUTATION);
  const [editFbRoom] = useMutation(EDIT_FB_ROOM_MUTATION);
  const [editItemUUID, setEditItemUUID] = useState(null);

  const [errorFormMap, setErrorFormMap] = useState(new Map());
  const [errorResMap, setErrorResMap] = useState([new Map()]);

  const getFbServices = useCustomQuery(GET_FB_SERVICES, {
    variables: { fbOutletUUID: appOutletUUID },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const getFbRooms = useCustomQuery(GET_FB_ROOMS, {
    variables: { fbOutletUUID: appOutletUUID },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const textTranslate = (text) => {
    return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
  };
  const handleAddButton = () => {
    setIsFormVisible(!isFormVisible);
    setFormEmpty();
  };

  const updateRoomSettings = (key, value) => {
    setRoomSettings({
      ...roomSettings,
      [key]: value,
    });
  };

  const isValidForm = () => {
    const guestToValidate = roomSettings;
    const validation = customObjectValidator(FORM_VALIDATION, guestToValidate);
    setErrorFormMap(validation.errorMap);
    return validation.isValid;
  };

  const editRoom = (item) => {
    setEditItemUUID(item.uuid);
    const updatedRoomSettings = {
      name: item.name,
      hasOnlineReservations: item.hasOnlineReservations ? 'yes' : 'no',
      isActive: item.isActive ? 'activated' : 'disabled',
      fbServices: item.fbServices.map((fbService) => fbService.id),
      maxPax: item.maxPax,
    };

    setRoomSettings(updatedRoomSettings);
    setIsFormVisible(true);
  };
  const deleteRoom = (item) => {
    modalContext.openModal({
      class: 'warning',
      title: textTranslate('delete-room-title'),
      text: textTranslate('delete-room-text'),
      actionButtons: [
        <Button key={1} variant="outlined" color="primary" className={classesModal.invertedButton} onClick={modalContext.closeModal}>
          {textTranslate('cancel')}
        </Button>,
        <Button
          key={2}
          color="primary"
          variant="contained"
          className={classesModal.buttonStyle}
          onClick={() => {
            modalContext.closeModal();
            deleteFbRoom({ variables: { fbRoomUUID: item.uuid } })
              .then(() => getFbRooms.refetch())
              .catch((err) => {
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - '),
                });
              });
          }}
        >
          {textTranslate('confirm')}
        </Button>,
      ],
    });
  };

  const onClickSave = () => {
    setErrorResMap([...errorResMap, new Map()]);

    if (!isValidForm()) {
      modalContext.openModal({
        class: 'danger',
        title: textTranslate('form-not-valid-title'),
        text: textTranslate('form-not-valid-text'),
        actionButtons: null,
      });
    } else {
      modalContext.openModal({
        class: 'primary',
        title: textTranslate('form-valid-title'),
        text: textTranslate(editItemUUID ? 'form-edit-text' : 'form-valid-text'),
        actionButtons: [
          <Button key={1} variant="outlined" color="primary" className={classesModal.invertedButton} onClick={modalContext.closeModal}>
            {textTranslate('cancel')}
          </Button>,
          <Button
            key={2}
            color="primary"
            variant="contained"
            className={classesModal.buttonStyle}
            onClick={() => {
              modalContext.closeModal();
              fetchForm();
            }}
          >
            {textTranslate('confirm')}
          </Button>,
        ],
      });
    }
  };

  const fetchForm = () => {
    const newFbRoom = {
      ...roomSettings,
      hasOnlineReservations: roomSettings.hasOnlineReservations === 'yes',
      isActive: roomSettings.isActive === 'activated',
      maxPax: Number(roomSettings.maxPax),
    };

    if (editItemUUID) {
      editFbRoom({
        variables: {
          fbRoomUUID: editItemUUID,
          fbRoomData: newFbRoom,
        },
      })
        .then(() => {
          modalContext.closeModal();
          modalContext.openModal({
            class: 'success',
            title: textTranslate('success-modal-title'),
            text: textTranslate('success-modal-text'),
            actionButtons: null,
          });
          setFormEmpty();

          getFbRooms.refetch();
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - '),
          });
        });
    } else {
      addFbRoom({
        variables: {
          fbOutletUUID: appOutletUUID,
          fbRoomData: newFbRoom,
        },
      })
        .then(() => {
          modalContext.closeModal();
          modalContext.openModal({
            class: 'success',
            title: textTranslate('success-modal-title'),
            text: textTranslate('success-modal-text'),
            actionButtons: null,
          });
          setFormEmpty();

          getFbRooms.refetch();
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - '),
          });
        });
    }
    setIsFormVisible(false);
  };

  const setFormEmpty = () => {
    setRoomSettings({ ...initialState });
    setErrorFormMap(new Map());
    setErrorResMap([new Map()]);
    setEditItemUUID(null);
  };

  const onClickDelete = () => {
    modalContext.openModal({
      class: 'warning',
      title: textTranslate('delete-form-title'),
      text: textTranslate('delete-form-text'),
      actionButtons: [
        <Button key={1} variant="outlined" color="primary" className={classesModal.invertedButton} onClick={modalContext.closeModal}>
          {textTranslate('cancel')}
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={classesModal.buttonStyle}
          onClick={() => {
            modalContext.closeModal();
            setFormEmpty();
          }}
        >
          {textTranslate('confirm')}
        </Button>,
      ],
    });
  };

  const handleCheckboxChange = (event, serviceId) => {
    updateRoomSettings('fbServices', serviceId);
  };
  const columns = getRoomColumns({ deleteRoom, editRoom });

  return (
    <Box>
      <CommonTable
        size="small"
        items={typy(getFbRooms, 'data.getFbRooms.data').safeArray.sort((prev, next) => prev.id - next.id)}
        columns={columns}
      />
      <Box style={{ padding: 10 }}>
        <IconButton className="buttonCircle" onClick={handleAddButton}>
          <AddIcon />
        </IconButton>
      </Box>
      {isFormVisible && (
        <RoomForm
          roomSettings={roomSettings}
          updateRoomSettings={updateRoomSettings}
          onClickSave={onClickSave}
          onClickDelete={onClickDelete}
          errorFormMap={errorFormMap}
          fbServices={typy(getFbServices, 'data.getFbServices.data').safeArray}
          handleCheckboxChange={handleCheckboxChange}
        />
      )}
    </Box>
  );
};

export default RoomSettings;
