/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const GET_FB_SEGMENTS = gql`
  query getFbSegments($fbOutletUUID: String!, $page: Int, $pageSize: Int) {
    getFbSegments(fbOutletUUID: $fbOutletUUID, page: $page, pageSize: $pageSize) {
      totalCount
      data {
        id
        uuid
        name
        description
        startDate
        endDate
        isActive
        fbSegmentsServices {
          days
          fbService {
            id
            uuid
            name
            startTime
            endTime
            icon
          }
        }
      }
    }
  }
`;
