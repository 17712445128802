import React from 'react';
import Layout from '../components/shared/Layout';
import SaveReservation from '../components/saveReservation/SaveReservation';

const EditReservationPage = ({ match, history }) => {
  return (
    <Layout history={history} section="edit-reservation">
      <SaveReservation fbReservationUUID={match.params.uuid} />
    </Layout>
  );
};

export default EditReservationPage;
