import React from 'react';
import { Box } from '@material-ui/core';
import GridLayout from 'react-grid-layout';
import style from '../../../styles/viewTables/tables/style';
import TableItem from './TableItem';

const Tables = ({
  isGuestNameShowed,
  roomTables,
  selectedTableUUID,
  selectedOptionButton,
  selectedGuestUUID,
  isRoomEditable,
  layout,
  onLayoutChangeHandler,
  onTableClickHandler,
  onChairClickHandler,
  onTableShift
}) => {
  const classes = style();

  return (
    <Box className={classes.container}>
      <GridLayout
        isDraggable={isRoomEditable}
        onLayoutChange={(layout) => {
          onLayoutChangeHandler(layout);
          onTableShift(layout);
        }}
        layout={layout}
        measureBeforeMount
        isResizable={false}
        margin={[50, 50]}
        rowHeight={40}
        className="layout"
        compactType={null}
        cols={20}
        width={4000}
      >
        {roomTables.map((table) => {
          return (
            <Box key={table.id} data-grid={{ x: table.positionX, y: table.positionY, w: 1, h: 1 }}>
              <TableItem
                isGuestNameShowed={isGuestNameShowed}
                table={table}
                isBusy={table.fbReservationTables.length > 0}
                selectedTableUUID={selectedTableUUID}
                selectedOptionButton={selectedOptionButton}
                selectedGuestUUID={selectedGuestUUID}
                isRoomEditable={isRoomEditable}
                onTableClick={onTableClickHandler}
                onChairClick={onChairClickHandler}
              />
            </Box>
          );
        })}
      </GridLayout>
    </Box>
  );
};

export default Tables;
