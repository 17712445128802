import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import typy from 'typy';
import * as moment from 'moment';
import { Box, Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Button, debounce } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { GET_FB_RESERVATION, GET_AVAILABLE_SLOTS } from '../../graphql/reservations/queries';
import { GET_FB_OUTLET, GET_FB_OUTLETS } from '../../graphql/outlets/queries';
import { GET_GUEST_PROFILES } from '../../graphql/guests/queries';
import { getApolloErrors } from '../../apollo/ApolloProvider';
import { ReservationContext } from '../../contexts/ReservationContext';
import { TranslateTextComponent } from '../../translator';
import { ModalContext } from '../../contexts/ModalContext';
import { RenderTabs, CustomButton } from '../../common-fe';
import { ROUTE_EDIT_RESERVATION } from '../../router/routes';
import InternalCustomer from './InternalCustomer';
import InternalCustomerMenu from './InternalCustomerMenu';
import AvailabilityBox from './AvailabilityBox';
import ReservationForm from './ReservationForm';
import ExternalUserGrid from './ExternalUserGrid';
import leftSidebarLayoutStyles from '../../common-fe/src/styles/shared/leftSidebarLayoutStyle';
import styles from '../../styles/saveReservation/newReservationStyle';
import modalStyle from '../../styles/shared/modalStyle';
import { CONTENT_RESERVATION_FORM, CONTENT_EXTERNAL_GUEST_LIST } from '../../constants/saveReservation/internalRoutes';
import useCustomQuery from '../../hooks/useCustomQuery';
import { AuthContext } from '../../contexts/AuthContext';
import { GET_TAGS } from '../../graphql/user/queries';

const TAB_CLIENT = 'client';
const TAB_AVAILABILITY = 'availability';

const SaveReservation = ({ fbReservationUUID, history }) => {
  const classes = styles();
  const classesModal = modalStyle();
  const layoutClasses = leftSidebarLayoutStyles();
  const { appOutletUUID, user } = useContext(AuthContext);

  const [activeTab, setActiveTab] = useState(TAB_CLIENT);
  const [internalRoute, setInternalRoute] = useState(fbReservationUUID ? CONTENT_RESERVATION_FORM : null);
  const [guests, setGuests] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [physicalCategories, setPhysicalCategories] = useState([]);

  const [totalGuestsCount, setTotalGuestsCount] = useState(false);
  const [guestsQueryFilters, setGuestsQueryFilters] = useState({
    page: 1,
    pageSize: 10,
    keyword: '',
    isFbRepeater: false,
    fbOutletUUID: '',
    roomNumber: null
  });
  const [guestsInHouseQueryFilters, setGuestsInHouseQueryFilters] = useState({
    pageSize: 10,
    page: 1,
    keyword: '',
    isFbRepeater: false,
    checkInDate: '',
    checkOutDate: '',
    fbOutletUUID: '',
    roomNumber: null
  });
  const [isLoadingGuests, setIsLoadingGuests] = useState(false);

  const reservationContext = useContext(ReservationContext);
  const modalContext = useContext(ModalContext);

  // Queries
  const getFbOutlet = useCustomQuery(GET_FB_OUTLET, {
    variables: { fbOutletUUID: reservationContext.reservationsData[reservationContext?.isEditingReservationIndex]?.fbOutletUUID },
    skip: !reservationContext.reservationsData[reservationContext?.isEditingReservationIndex]?.fbOutletUUID,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setPhysicalCategories(data?.getFbOutlet?.guestPhysicalCategories ?? []);
    },
    onError: () => {
      reservationContext.handleChangeReservationData('fbOutletUUID', null, 0);
    }
  });

  const getFbOutlets = useCustomQuery(GET_FB_OUTLETS);

  const getGuestProfiles = useCustomQuery(GET_GUEST_PROFILES, {
    variables: {
      page: guestsQueryFilters.page,
      pageSize: guestsQueryFilters.pageSize,
      fbOutletUUID: appOutletUUID,
      keyword: guestsQueryFilters.keyword,
      roomNumber: guestsQueryFilters?.roomNumber
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.getGuestProfiles?.data?.length > 0) {
        setGuests([...guests, ...res?.getGuestProfiles?.data]);
        setTotalGuestsCount(res?.getGuestProfiles?.totalCount);
      }
    },
    skip: true
  });

  useEffect(() => {
    refetch();
  }, [guestsQueryFilters]);

  const refetch = useCallback(
    debounce(() => {
      setTotalGuestsCount(false);
      setGuests([]);
      setIsLoadingGuests(true);
      getGuestProfiles
        .refetch()
        .then((res) => {
          setGuests([...guests, ...(res?.data?.getGuestProfiles?.data ?? [])]);
          setTotalGuestsCount(res?.data?.getGuestProfiles?.totalCount);
        })
        .finally(() => setIsLoadingGuests(false));
    }, 1000),
    []
  );

  const getFbReservation = useCustomQuery(GET_FB_RESERVATION, {
    variables: {
      fbReservationUUID
    },
    skip: !fbReservationUUID,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const allergens = typy(data, 'getFbReservation.bookedBy.fbProfilePreferences.allergens').safeArray;
      const intolerances = typy(data, 'getFbReservation.bookedBy.fbProfilePreferences.intolerances').safeArray;
      const bookedBy = typy(data, 'getFbReservation.bookedBy').safeObject;

      const editGuestData = {
        id: typy(bookedBy, 'id').safeNumber,
        uuid: typy(bookedBy, 'uuid').safeString,
        firstName: typy(bookedBy, 'firstname').safeString,
        lastName: typy(bookedBy, 'lastname').safeString,
        languageCode: typy(bookedBy, 'language').safeString,
        dob: typy(bookedBy, 'dob').safeString,
        nationality: typy(bookedBy, 'nationality').safeString,
        address: typy(bookedBy, 'address').safeString,
        city: typy(bookedBy, 'city').safeString,
        zipCode: typy(bookedBy, 'zipCode').safeString,
        country: typy(bookedBy, 'country').safeString,
        email: typy(bookedBy, 'email').safeString,
        phone: typy(bookedBy, 'phone').safeString,
        favouriteWater: typy(bookedBy, 'favouriteWater').safeString,
        favouriteWine: typy(bookedBy, 'favouriteWine').safeString,
        allergens,
        intolerances
      };
      reservationContext.handleSetGuestData(editGuestData);
      const fbGuestList = typy(data, 'getFbReservation.fbGuestList').safeArray.map((guest) => {
        return { guestPhysicalCategoryId: Number(guest.guestPhysicalCategoryId), age: Number(guest.age) || null };
      });

      const editReservation = {
        id: typy(data, 'getFbReservation.id').safeNumber,
        uuid: typy(data, 'getFbReservation.uuid').safeString,
        fbOutletUUID: typy(data, 'getFbReservation.fbService.fbOutlet.uuid').safeString,
        fbServiceUUID: typy(data, 'getFbReservation.fbService.uuid').safeString,
        packageUUID: typy(data, 'getFbReservation.package.uuid').safeString,
        channelUUID: typy(data, 'getFbReservation.channel.uuid').safeString,
        channelId: typy(data, 'getFbReservation.id').safeNumber,
        arrangement: typy(data, 'getFbReservation.arrangement').safeString,
        fbRoom: typy(data, 'getFbReservation.fbService.fbOutlet.fbRooms').safeString,
        reservationDate: moment(typy(data, 'getFbReservation.date').safeString),
        reservationTime: typy(data, 'getFbReservation.reservationTime').safeString,
        selectedTime: typy(data, 'getFbReservation.reservationTime').safeString,
        requests: typy(data, 'getFbReservation.requests').safeString,
        notes: typy(data, 'getFbReservation.notes').safeString,
        tableNumber: typy(data, 'getFbReservation.tableNumber').safeString,
        guestRoom: typy(data, 'getFbReservation.guestRoom').safeString,
        fbGuestList
      };

      reservationContext.handleSetFirstReservationData({ ...editReservation, guestRoom: data?.getFbReservation?.guestRoom });
    }
  });

  const getTags = useCustomQuery(GET_TAGS);

  const [getAvailableSlots, { loading: isLoadingAvailableSlots, data: availableSlotsData }] = useLazyQuery(GET_AVAILABLE_SLOTS, {
    fetchPolicy: 'network-only'
  });
  const slotServiceId = availableSlotsData && availableSlotsData.getAvailableSlots.fbServiceUUID;
  const loadMoreGuests = () => {
    if (!isLoadingGuests && totalGuestsCount && guests.length < totalGuestsCount) {
      setIsLoadingGuests(true);
      getGuestProfiles
        .refetch({ ...guestsQueryFilters, page: guestsQueryFilters.page + 1 })
        .then((res) => {
          setGuests([...guests, ...res?.data?.getGuestProfiles?.data]);
          setTotalGuestsCount(res?.data?.getGuestProfiles?.totalCount);
        })
        .finally(() => setIsLoadingGuests(false));
    }
  };

  const tabElements = [
    {
      id: 1,
      key: TAB_CLIENT,
      tabStyle: { width: '43%', minWidth: 0 },
      label: <TranslateTextComponent uppercase>client-name</TranslateTextComponent>
    },
    {
      id: 2,
      key: TAB_AVAILABILITY,
      tabStyle: { width: '55%', minWidth: 0 },
      label: <TranslateTextComponent uppercase>availability</TranslateTextComponent>,
      onClick: () => {
        setInternalRoute(CONTENT_RESERVATION_FORM);
      }
    }
  ];

  useEffect(() => {
    reservationContext.reservationResetData();
    reservationContext.resetValidationErrors();
  }, []);

  useEffect(() => {
    if (internalRoute === CONTENT_RESERVATION_FORM) {
      setActiveTab(TAB_AVAILABILITY);
    }
  }, [internalRoute]);

  const textTranslate = (text) => {
    return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
  };

  const guestData = reservationContext?.guestData;

  const isNewReservation = () => {
    if (!fbReservationUUID) {
      openModalOnSave();
    } else {
      openModalOnEdit();
    }
  };

  const openModalOnEdit = () => {
    modalContext.openModal({
      class: 'primary',
      title: textTranslate('edit-reservation'),
      text: textTranslate('edit-reservation-text'),
      actionButtons: [
        <Button key={0} variant="outlined" color="primary" className={classesModal.invertedButton} onClick={modalContext.closeModal}>
          {textTranslate('cancel')}
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={classesModal.buttonStyle}
          onClick={() => {
            modalContext.closeModal();
            onSaveReservation();
          }}
        >
          {textTranslate('confirm')}
        </Button>
      ]
    });
  };

  const openModalOnSave = () => {
    modalContext.openModal({
      class: 'primary',
      title: textTranslate('save-reservation'),
      text: textTranslate('save-reservation-text'),
      actionButtons: [
        <Button key={0} variant="outlined" color="primary" className={classesModal.invertedButton} onClick={modalContext.closeModal}>
          {textTranslate('cancel')}
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={classesModal.buttonStyle}
          onClick={() => {
            modalContext.closeModal();
            onSaveReservation();
          }}
        >
          {textTranslate('confirm')}
        </Button>
      ]
    });
  };

  const reservationSaved = () => {
    modalContext.openModal({
      class: 'success',
      title: textTranslate('res-confirm'),
      text: textTranslate('reservation-saved'),
      actionButtons: [
        <Button
          key={0}
          color="primary"
          variant="contained"
          style={{ color: 'white' }}
          className={classesModal.buttonStyle}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          {textTranslate('confirm')}
        </Button>
      ]
    });
  };

  const onSaveReservation = () => {
    const isValidReservation = reservationContext.isValidReservation();
    const isValidGuest = reservationContext.isValidGuest();

    if (isValidReservation && isValidGuest) {
      setIsSaving(true);
      reservationContext
        .saveGuest()
        .then((response) => {
          if (fbReservationUUID) getFbReservation.refetch();
          if (response?.data) {
            const guest = response.data.addGuest || response.data.editGuest;
            reservationContext.handleSetGuestData(guest);
            reservationContext.saveReservations(guest.uuid).then((res) => {
              const savedReservationUUID =
                typy(res[0], 'data.editFbReservation.uuid').safeString || typy(res[0], 'data.addFbReservation.uuid').safeString;
              const selectedTime =
                typy(res[0], 'data.editFbReservation.reservationTime').safeString || typy(res[0], 'data.addFbReservation.reservationTime').safeString;
              reservationSaved();
              reservationContext.handleChangeReservationDataFields({ selectedTime, guestRoom: res?.[0]?.data?.addFbReservation?.guestRoom });
              if (savedReservationUUID) {
                reservationContext.handleSetIsEditingReservationIndex(0);
                if (history) history.push(`${ROUTE_EDIT_RESERVATION}/${savedReservationUUID}`);
              }
            });
          }
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  const setNewFilterAndRefetchInHouseGuests = (objectValues) => {
    setGuestsInHouseQueryFilters({
      ...guestsInHouseQueryFilters,
      page: 1,
      ...objectValues
    });
  };

  const setNewFilterAndRefetchGuests = (objectValues) => {
    setGuestsQueryFilters({
      ...guestsQueryFilters,
      page: 1,
      ...objectValues
    });
  };

  const handleSetInternalRoute = (route) => {
    setInternalRoute(route);
    setActiveTab(TAB_AVAILABILITY);
  };

  const handleSwitchTab = (event, tab) => {
    setActiveTab(tab);
  };

  const showInternal = () => {
    return (
      <Box style={{ marginTop: 30 }}>
        <CustomButton isLoading={isSaving} style={{ marginTop: 10, marginBottom: 10, width: '100%' }} onClick={() => isNewReservation()}>
          <TranslateTextComponent>save</TranslateTextComponent>
        </CustomButton>
      </Box>
    );
  };

  const showExternal = () => {
    return (
      <Box style={{ marginTop: 30 }}>
        <Grid item xs style={{ textAlign: 'end' }}>
          <CustomButton isLoading={isSaving} style={{ marginTop: 10, marginBottom: 10 }} onClick={() => isNewReservation()}>
            <TranslateTextComponent>save</TranslateTextComponent>
          </CustomButton>
        </Grid>
      </Box>
    );
  };

  const getMainContent = () => {
    switch (internalRoute) {
      case CONTENT_RESERVATION_FORM: {
        return (
          <ReservationForm
            isLoadingPhysicalCategories={getFbOutlet.loading}
            physicalCategories={physicalCategories}
            fbReservationUUID={fbReservationUUID}
            outlets={getFbOutlets.data?.getFbOutlets?.data ?? []}
            tags={getTags.data?.getTags?.data ?? []}
          />
        );
      }
      case CONTENT_EXTERNAL_GUEST_LIST: {
        return (
          <Box style={{ marginLeft: 10 }}>
            <InternalCustomerMenu
              handleSetInternalRoute={handleSetInternalRoute}
              guestsQueryFilters={guestsQueryFilters}
              setNewFilterAndRefetch={setNewFilterAndRefetchGuests}
            />
            <ExternalUserGrid
              isLoadingGuests={isLoadingGuests}
              handleSetInternalRoute={handleSetInternalRoute}
              guests={guests}
              totalGuestsCount={totalGuestsCount}
              page={guestsQueryFilters.page}
              loadMoreGuests={loadMoreGuests}
              guestsQueryFilters={guestsQueryFilters}
            />
          </Box>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Box className={layoutClasses.layoutContainer}>
      <Box className={layoutClasses.leftSide}>
        <Grid>
          <Accordion style={{ marginBottom: 10 }} defaultExpanded>
            <AccordionSummary
              style={{ height: 45, minHeight: 50 }}
              expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.panelNewReservation}
            >
              <Typography style={{ fontSize: 18 }}>
                <TranslateTextComponent uppercase>reservation</TranslateTextComponent>
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: '10px 9px' }}>
              <Box style={{ width: ' 100% ' }}>
                <RenderTabs tabElements={tabElements} value={activeTab} onChange={handleSwitchTab} containerStyle={{ width: '100%' }} />
                <Box style={{ paddingTop: 10 }}>
                  {activeTab === TAB_AVAILABILITY ? (
                    <AvailabilityBox
                      physicalCategories={physicalCategories}
                      getAvailableSlots={getAvailableSlots}
                      availableSlotsData={availableSlotsData}
                      isLoadingAvailableSlots={isLoadingAvailableSlots}
                      isLoadingPhysicalCategories={getFbOutlet.loading}
                      slotServiceId={slotServiceId}
                      userRoles={user?.roles?.map((r) => r.name) ?? []}
                      outlets={getFbOutlets.data?.getFbOutlets?.data ?? []}
                    />
                  ) : (
                    <InternalCustomer
                      guestsInHouseQueryFilters={guestsInHouseQueryFilters}
                      handleSetInternalRoute={handleSetInternalRoute}
                      setNewFilterAndRefetchInHouseGuests={setNewFilterAndRefetchInHouseGuests}
                    />
                  )}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
        {activeTab === TAB_AVAILABILITY && guestData.id != null ? showInternal() : null}
        {guestData.id === null && activeTab === TAB_AVAILABILITY ? showExternal() : null}
      </Box>
      <Box className={layoutClasses.rightSide}>
        <Box>{getMainContent()}</Box>
      </Box>
    </Box>
  );
};
export default SaveReservation;
