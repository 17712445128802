import React from 'react';
import { Box, Button } from '@material-ui/core/';

import styles from '../../styles/saveReservation/newReservationStyle';

const PrimaryButton = (props) => {
  const classes = styles();
  return (
    <Box style={{ marginBottom: 10 }}>
      <Button variant="contained" color="primary" className={classes.searchbutton} onClick={props.onClick}>
        {props.name}
      </Button>
    </Box>
  );
};

export default PrimaryButton;
