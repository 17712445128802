import { makeStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    display: 'grid',
    gridTemplateAreas:
      '"room-selector room-max-pax room-seats-taken room-seats-available separator table-shape-selector table-capacity table-quantity table-settings button-area" "selected-room-error . . . . selected-shape-error capacity-error quantity-error . ."',
    gridColumnGap: 10,
    minHeight: 62,
    paddingRight: 15
  },
  roomSelector: {
    gridArea: 'room-selector',
    gridTemplateAreas: '"title" "selector"',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center'
  },
  roomMaxPax: {
    gridArea: 'room-max-pax',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center'
  },
  roomSeatsTaken: {
    gridArea: 'room-seats-taken',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center'
  },
  roomSeatsAvailable: {
    gridArea: 'room-seats-available',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center'
  },
  separator: {
    border: '1px solid #00B7FF',
    minHeight: 62,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center'
  },
  tableShapeSelector: {
    gridArea: 'table-shape-selector',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center'
  },
  tableCapacity: {
    gridArea: 'table-capacity',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center'
  },
  tableQuantity: {
    gridArea: 'table-quantity',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center'
  },
  tableSettings: ({ radiosPadding }) => ({
    gridArea: 'table-settings',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center'
    // '& span': {
    //   paddingTop: !radiosPadding ? 0 : 3,
    //   paddingBottom: !radiosPadding ? 0 : 3
    // }
  }),
  buttonArea: {
    gridArea: 'button-area',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center'
  },
  selectorTitle: {
    fontSize: 14,
    marginBottom: 5
  },
  number: {
    fontSize: 29,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  numberDescription: {
    fontSize: 11,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  input: ({ error, width }) => ({
    border: `1px solid ${error ? 'red' : '#00B7FF'}`,
    borderRadius: 4,
    width: width || 90,
    minHeight: 30,
    margin: 0,
    textIndent: 5,
    '&::placeholder': {
      fontSize: 7
    }
  }),
  checkBoxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 200
  },
  formControlLabel: {
    width: '100%',
    '& .MuiFormControlLabel-label': {
      width: '100%',
      fontSize: 12
    }
  },
  checkBox: {
    padding: 0
  },
  button: {
    backgroundColor: '#00B7FF',
    minHeight: 50,
    width: 120,
    '&:hover': {
      backgroundColor: '#01A2E2'
    }
  },
  error: {
    fontSize: '0.7rem'
  },
  roundShape: ({ size, selected }) => ({
    backgroundColor: selected ? '#00B7FF' : '#55595C',
    height: size || 30,
    width: size || 30,
    borderRadius: '50%',
    margin: 'auto'
  }),
  squareShape: ({ size, selected }) => ({
    backgroundColor: selected ? '#00B7FF' : '#55595C',
    height: size || 30,
    width: size || 30,
    borderRadius: 4,
    margin: 'auto'
  }),
  rectangleShape: ({ size, selected }) => ({
    backgroundColor: selected ? '#00B7FF' : '#55595C',
    height: size || 30,
    width: size * 2 || 60,
    borderRadius: 4,
    margin: 'auto'
  })
};

export default makeStyles(styles);
