import React, { useState, useEffect, useRef } from 'react';
import { Typography, Box, Fab } from '@material-ui/core';
import styled from 'styled-components/macro';
import RootRef from '@material-ui/core/RootRef';
import { useDropzone } from 'react-dropzone';
import uploadIcon from '../../assets/images/menu/icon_upload.svg';
import FetchImage from './FetchImage';
import { TranslateTextComponent } from '../../translator';
import { primaryColor } from '../../styles/theme';

const acceptedExtension = ['image/jpg', 'image/jpeg', 'image/png'];
const MAX_SIZE = 4000000;

const StyledImageUpload = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => (height ? `${height}px` : '157px')};
  width: ${({ width }) => (width ? `${width}px` : '157px')};
  border: ${({ sizeError }) => `1px solid ${sizeError ? 'red' : '#00b7ff'}`};
  border-radius: 5px;
  margin-bottom: 5px;
`;

const ImageUpload = ({ gridarea, height, width, containerStyle, uploadContainerStyle, currentImage, onImageDrop, onImageReset }) => {
  const [files, setFiles] = useState([]);
  const [imageURI, setImageURI] = useState(null);
  const [sizeError, setSizeError] = useState(null);

  const imageRef = useRef(null);
  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedExtension,
    maxSize: MAX_SIZE,
    multiple: false,
    onDropRejected: (acceptedFiles) => {
      if (acceptedFiles[0].size > MAX_SIZE) {
        setSizeError('size-error');
      }
    },
    onDropAccepted: (acceptedFiles) => {
      setSizeError(null);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });
  const { ref, ...rootProps } = getRootProps();

  useEffect(() => {
    if (files[0]) {
      setImageURI(getBase64(files[0]));
    }
  }, [files]);

  useEffect(() => {
    if (imageURI) {
      onImageDrop(imageURI);
    }
  }, [imageURI]);

  useEffect(() => {
    if (!currentImage && !files[0]) {
      setFiles([]);
      setImageURI(null);
    }
  }, [currentImage]);

  const getBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageURI(reader.result);
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  };

  return (
    <RootRef rootRef={ref}>
      <div style={{ gridArea: gridarea, position: 'relative', ...containerStyle }}>
        <StyledImageUpload sizeError={sizeError} height={height} width={width} style={{ ...uploadContainerStyle }} {...rootProps}>
          <input {...getInputProps()} />
          {!files.length > 0 ? (
            <>
              {currentImage ? (
                <>
                  <FetchImage
                    imgRef={imageRef}
                    imagePath={currentImage}
                    style={{ height: height || '100%', width: width || '100%', borderRadius: 4 }}
                  />
                  <Fab
                    style={{ backgroundColor: primaryColor, width: 35, height: 35, position: 'absolute', top: -15, right: -15, index: 100 }}
                    color="primary"
                    aria-label="close"
                    onClick={(e) => {
                      e.stopPropagation();
                      onImageReset();
                      onImageDrop(null);
                      setFiles([]);
                    }}
                  >
                    <Typography style={{ color: 'white', fontSize: '1.2rem' }}>x</Typography>
                  </Fab>
                </>
              ) : (
                <>
                  <img src={uploadIcon} alt="Upload" />
                  <Typography style={{ fontSize: '1.2rem', color: '#55595C' }}>Drag & Drop</Typography>
                  <Typography style={{ fontSize: '0.7rem' }}>your file or browse</Typography>
                </>
              )}
            </>
          ) : (
            files.map((file) => (
              <React.Fragment key={file.name}>
                <div style={{ height: '100%', width: '100%' }}>
                  <div style={{ height: '100%', width: '100%' }}>
                    <img style={{ objectFit: 'cover', objectPosition: 'center', width: '100%', height: '100%' }} src={file.preview} alt="preview" />
                  </div>
                </div>
                <Fab
                  style={{ backgroundColor: primaryColor, width: 35, height: 35, position: 'absolute', top: -15, right: -15, index: 100 }}
                  color="primary"
                  aria-label="close"
                  onClick={(e) => {
                    e.stopPropagation();
                    onImageReset();
                    onImageDrop(null);
                    setFiles([]);
                  }}
                >
                  <Typography style={{ color: 'white', fontSize: '1.2rem' }}>x</Typography>
                </Fab>
              </React.Fragment>
            ))
          )}
        </StyledImageUpload>

        {sizeError && (
          <Box style={{ height: 20 }}>
            <TranslateTextComponent vars={{ maxSize: `${MAX_SIZE / 10 ** 6} MB` }} style={{ fontSize: 10, color: 'red', marginTop: 10 }}>
              {sizeError}
            </TranslateTextComponent>
          </Box>
        )}
      </div>
    </RootRef>
  );
};

export default ImageUpload;
