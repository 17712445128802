/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const ADD_FB_ROOM_MUTATION = gql`
  mutation addFbRoom($fbOutletUUID: String!, $fbRoomData: FbRoomsInput!) {
    addFbRoom(fbOutletUUID: $fbOutletUUID, fbRoomData: $fbRoomData) {
      id
      uuid
      name
      isActive
      maxPax
      description
      hasOnlineReservations
      fbServices {
        id
        uuid
        name
        startTime
        endTime
        icon
      }
    }
  }
`;

export const DELETE_FB_ROOM_MUTATION = gql`
  mutation deleteFbRoom($fbRoomUUID: String!) {
    deleteFbRoom(fbRoomUUID: $fbRoomUUID)
  }
`;

export const EDIT_FB_ROOM_MUTATION = gql`
  mutation editFbRoom($fbRoomUUID: String!, $fbRoomData: FbRoomsInput!) {
    editFbRoom(fbRoomUUID: $fbRoomUUID, fbRoomData: $fbRoomData) {
      id
      uuid
      name
      maxPax
      description
    }
  }
`;
