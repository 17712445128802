/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import typy from 'typy';
import { TranslateTextComponent } from '../../translator';

const textTranslate = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

const getReservationsColumns = () => {
  return [
    {
      id: 'bookedBy.lastname',
      disablePadding: false,
      width: '25%',
      label: textTranslate('client-fullName'),
      isSortable: true,
      render: (item) => `${typy(item, 'bookedBy.firstname').safeString} ${typy(item, 'bookedBy.lastname').safeString}`
    },
    {
      id: 'fbReservation.date',
      disablePadding: false,
      label: textTranslate('reservation-date'),
      isSortable: true,
      render: (item) => typy(item, 'date').safeString
    },
    {
      id: 'fbReservation.reservationTime',
      disablePadding: false,
      label: textTranslate('reservation-time'),
      isSortable: true,
      render: (item) => typy(item, 'reservationTime').safeString
    },
    {
      id: 'nPax',
      disablePadding: false,
      label: textTranslate('n-pax'),
      isSortable: false,
      render: (item) => typy(item, 'nPax').safeNumber
    },
    {
      id: 'status',
      disablePadding: false,
      label: textTranslate('reservation-status'),
      isSortable: true,
      render: (item) => typy(item, 'status').safeString
    },
    {
      id: 'channel.name',
      disablePadding: false,
      label: textTranslate('reservation-channel'),
      isSortable: true,
      hidden: {
        mdDown: true
      },
      render: (item) => typy(item, 'channel.name').safeString
    }
  ];
};

export default getReservationsColumns;
