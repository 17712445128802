import React from 'react';
import DailyRevenues from '../../components/revenues/DailyRevenues';
import Layout from '../../components/shared/Layout';

const DailyRevenuesPage = ({ history }) => {
  return (
    <Layout history={history} section="daily-reports">
      <DailyRevenues />
    </Layout>
  );
};

export default DailyRevenuesPage;
