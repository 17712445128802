/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';
import { ROLE_FRAGMENT, USER_FRAGMENT, FB_ORDERS_FRAGMENT, GUEST_USER_FRAGMENT, CHECKIN_FRAGMENT } from '../fragments';

export const SAVE_SMART_DRIVER = gql`
  mutation saveSmartDriver($fbOutletUUID: String!, $driverInput: SaveUserDto!) {
    saveSmartDriver(fbOutletUUID: $fbOutletUUID, driverInput: $driverInput) {
      ...userFragment
      hasTempPassword
      roles {
        ...roleFragment
      }
    }
  }
  ${USER_FRAGMENT}
  ${ROLE_FRAGMENT}
`;

export const SET_SMART_ORDER_DRIVER = gql`
  mutation setSmartOrderDriver($driverUUID: String!, $orderUUID: String!) {
    setSmartOrderDriver(driverUUID: $driverUUID, orderUUID: $orderUUID) {
      ...fbOrdersFragment
    }
  }
  ${FB_ORDERS_FRAGMENT}
`;

export const CREATE_STAFF_USER = gql`
  mutation createStaffUser($fbOutletUUIDs: [String!]!, $userInput: SaveUserDto!, $role: RolesEnum!) {
    createStaffUser(fbOutletUUIDs: $fbOutletUUIDs, userInput: $userInput, role: $role) {
      ...userFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const DELETE_STAFF_USER = gql`
  mutation deleteStaffUser($userUUID: String!) {
    deleteStaffUser(userUUID: $userUUID)
  }
`;

export const UPDATE_STAFF_USER = gql`
  mutation updateStaffUser($uuid: String!, $fbOutletUUIDs: [String!]!, $userInput: SaveUserDto!, $roles: [RolesEnum!]) {
    updateStaffUser(uuid: $uuid, fbOutletUUIDs: $fbOutletUUIDs, userInput: $userInput, roles: $roles) {
      ...userFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const CREATE_GUEST_USER = gql`
  mutation createGuestUser($profileUUID: String, $userInput: SaveUserDto!) {
    createGuestUser(profileUUID: $profileUUID, userInput: $userInput) {
      ...userFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const UPDATE_GUEST_USER_EMAIL_CONFIRMED = gql`
  mutation updateGuestUserEmailConfirmed($emailConfirmedValue: Boolean!, $userUUID: String!) {
    updateGuestUserEmailConfirmed(emailConfirmedValue: $emailConfirmedValue, userUUID: $userUUID) {
      ...guestUserFragment
    }
  }
  ${GUEST_USER_FRAGMENT}
`;

export const SAVE_CHECKIN = gql`
  mutation saveCheckin($roomUUID: String, $profileUUID: String!, $checkinUUID: String, $checkinInput: SaveCheckinDto!) {
    saveCheckin(roomUUID: $roomUUID, profileUUID: $profileUUID, checkinUUID: $checkinUUID, checkinInput: $checkinInput) {
      uuid
    }
  }
`;

export const SAVE_CHECKIN_PREFERENCES = gql`
  mutation saveCheckinPreferences($checkinUUID: String!, $checkinInput: SaveCheckinDto!) {
    saveCheckinPreferences(checkinUUID: $checkinUUID, checkinInput: $checkinInput) {
      ...checkinFragment
    }
  }
  ${CHECKIN_FRAGMENT}
`;
