import React from 'react';
import { t } from 'typy'; // this will also work
import { Grid, Typography } from '@material-ui/core/';
import { ReactComponent as CenterpieceImg } from '../../assets/images/order/centerpiece.svg';
import { TranslateTextComponent } from '../../translator';
import styles from '../../styles/order/fbCourse';
import { primaryColor } from '../../styles/theme';

const OrderNotesComponent = ({ items }) => {
  const classes = styles();

  return (
    <Grid container direction="column" style={{ marginLeft: 20, marginTop: 5 }}>
      <Typography className={classes.notesHeaderTitle}>
        <TranslateTextComponent capitalize>notes</TranslateTextComponent>*
      </Typography>
      {items.map((item, index) => {
        if (item.notes !== '' && item.notes !== null) {
          return (
            <Grid key={index} container direction="column" alignItems="flex-start" style={{ paddingBottom: 5 }}>
              {(t(item, 'fbChairNumber').safeNumber || item.halfPortion) && (
                <Typography style={{ paddingRight: 9, fontWeight: 'bold', fontSize: 12, color: primaryColor }}>
                  {t(item, 'fbChairNumber').safeNumber}
                </Typography>
              )}
              {item.centerpiece && <CenterpieceImg style={{ height: 16, width: 16 }} />}
              {item.sharedBy && item.sharedBy.length > 1 && (
                <Typography style={{ fontWeight: 'bold', fontSize: 12, color: primaryColor }}>{item.sharedBy.join('-')}</Typography>
              )}

              <Typography className={classes.notesTypography}>{item.notes}</Typography>
            </Grid>
          );
        }
        return null;
      })}
    </Grid>
  );
};

export default OrderNotesComponent;
