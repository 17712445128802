import { makeStyles } from '@material-ui/core/styles';

const modalStyle = {
  modalContainer: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }),
  paperContainer: () => ({
    position: 'relative',
    outlineColor: 'transparent'
  }),
  headerContainer: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    overflow: 'hidden'
  },
  childrenContainer: ({ height = '70vh', header }) => ({
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    borderTop: header ? '1.5px solid #B4DCED' : 'none',
    height
  }),
  footerContainer: { borderBottomLeftRadius: 10, borderBottomRightRadius: 10, overflow: 'hidden' },
  styledHeader: {
    padding: '40px 30px',
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'rgb(0, 183, 255)',
    // Text
    fontWeight: 'bold',
    fontSize: 30,
    color: 'white',
    textTransform: 'uppercase'
  },
  closeModalButton: () => ({
    cursor: 'pointer',
    height: 40,
    width: 40,
    position: 'absolute',
    top: '-15px',
    right: '-13px',
    borderRadius: '50%',
    backgroundColor: 'white'
  })
};

export default makeStyles(modalStyle);
