import { makeStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    display: 'grid',
    gridTemplateAreas: '"photo name" "photo reservation-time" "photo room" "photo persons-number"',
    cursor: 'pointer',
    color: '#55595C',
    padding: 5,
    borderBottom: '2px solid #B4DCED',
    '&:last-of-type': {
      borderBottom: 'none'
    },
    '&:hover': {
      backgroundColor: '#B4DCED'
    },
    '& div': {
      maxWidth: 85
    },
    transition: 'background-color 0.3s'
  },
  photoArea: {
    gridArea: 'photo',
    '& img': {
      borderRadius: 10,
      objectFit: 'contain',
      objectPosition: 'initial',
      minHeight: 77,
      minWidth: 77
    }
  },
  nameArea: {
    gridArea: 'name',
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  reservationTimeArea: {
    gridArea: 'reservation-time',
    fontSize: 12
  },
  roomArea: {
    gridArea: 'room',
    fontSize: 12
  },
  personsNumberArea: {
    gridArea: 'persons-number',
    fontSize: 12
  }
};

export default makeStyles(styles);
