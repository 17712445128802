import React, { useContext } from 'react';
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, Observable, split } from 'apollo-boost';
import { WebSocketLink } from 'apollo-link-ws';
import { onError } from 'apollo-link-error';
import { getMainDefinition } from 'apollo-utilities';
import { ApolloProvider } from '@apollo/react-hooks';
import { AuthContext } from '../contexts/AuthContext';

export const getApolloErrors = ({ graphQLErrors, networkErrors }) => {
  const errors = [];
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => errors.push(error.message.message || error.message));
  }
  if (networkErrors) {
    networkErrors.forEach((error) => errors.push(error.message));
  }
  return errors;
};

export default (props) => {
  const { user, appOutletUUID, logout } = useContext(AuthContext);
  const authToken = user?.access_token;
  const request = async (operation) => {
    operation.setContext({
      headers: {
        authorization: `Bearer ${authToken}`,
        'outlet-uuid': appOutletUUID
      }
    });
  };

  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        let handle;
        Promise.resolve(operation)
          .then((oper) => request(oper))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer)
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) handle.unsubscribe();
        };
      })
  );

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL
  });

  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_GRAPHQL_WS_URL,
    options: {
      reconnect: authToken,
      connectionParams: {
        authToken
      }
    }
  });

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
  );

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) => {
            // eslint-disable-next-line no-console
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            if (message?.statusCode === 401) {
              logout();
              return false;
            }
          });
        // eslint-disable-next-line no-console
        if (networkError) console.log(`[Network error]: ${JSON.stringify(networkError)}`);
      }),
      requestLink,
      link
    ]),
    cache: new InMemoryCache()
  });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
