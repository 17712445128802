import { makeStyles } from '@material-ui/core/styles';
import { primaryColor, invertedPrimary, headerHeight } from '../theme';

const leftSidebarLayoutStyles = {
  layoutContainer: () => ({
    display: 'flex',
    padding: '15px 0px 15px 15px',
    background: 'white',
    borderTopLeftRadius: 15,
    overflow: 'hidden',
    flex: 1
  }),
  leftSide: () => ({
    paddingRight: 10,
    width: 320,
    borderRight: `2px solid ${primaryColor}`
  }),
  rightSide: ({ position }) => ({
    position,
    flex: 2
  }),
  mainContainer: () => ({
    display: 'flex',
    background: invertedPrimary,
    width: '100%',
    minHeight: `calc(100vh - ${headerHeight}px)`
  }),
  fullScreenMainContainer: () => ({
    display: 'flex',
    background: invertedPrimary,
    width: '100%',
    height: `calc(100vh - ${headerHeight}px)`
  })
};

export default makeStyles(leftSidebarLayoutStyles);
