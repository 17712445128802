import React, { useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import { Avatar, Box, Grid, Hidden, Typography, CircularProgress } from '@material-ui/core/';
import { ReservationContext } from '../../contexts/ReservationContext';
import { CONTENT_RESERVATION_FORM } from '../../constants/saveReservation/internalRoutes';
import { CustomLoader } from '../../common-fe';
import gridStyles from '../../styles/saveReservation/showUserGrid';
import { FLAG, PHONE, MAIL, LANGUAGE, ROOM, GREEN_DOOR, RED_DOOR } from '../../constants/images_root';
import { TranslateTextComponent } from '../../translator';

const ExternalUserGrid = ({ handleSetInternalRoute, guests, totalGuestsCount, loadMoreGuests, isLoadingGuests }) => {
  const classes = gridStyles();

  const reservationContext = useContext(ReservationContext);
  const onSetGuest = (bookedBy) => {
    const fbProfilePreferences = bookedBy?.fbProfilePreferences ?? {};
    const allergens = fbProfilePreferences?.allergens?.map((allergen) => allergen) ?? [];
    const intolerances = fbProfilePreferences?.intolerances?.map((intolerance) => intolerance) ?? [];
    const editGuestData = {
      id: bookedBy.id,
      uuid: bookedBy.uuid,
      firstName: bookedBy.firstName,
      lastName: bookedBy.lastName,
      languageCode: bookedBy.language,
      dob: bookedBy.dob,
      nationality: bookedBy.nationality,
      address: bookedBy.address,
      city: bookedBy.city,
      zipCode: bookedBy.zipCode,
      country: bookedBy.country,
      email: bookedBy.email,
      phone: bookedBy.phone,
      favouriteWater: bookedBy.favouriteWater,
      favouriteWine: bookedBy.favouriteWine,
      allergens,
      intolerances
    };
    reservationContext.handleSetGuestData(editGuestData);
    reservationContext.handleChangeReservationDataFields({
      guestRoom: bookedBy?.currentCheckin?.room?.number,
      arrangement: bookedBy?.currentCheckin?.arrangement
    });

    handleSetInternalRoute(CONTENT_RESERVATION_FORM);
  };

  return (
    <Box className={classes.gridContainerStyle}>
      <InfiniteScroll
        pageStart={1}
        loadMore={(page) => loadMoreGuests(page)}
        hasMore={totalGuestsCount !== false && guests.length < totalGuestsCount}
        loader={<CustomLoader key="customKeyLoader" />}
      >
        {guests?.length > 0 ? (
          guests.map((guest, index) => (
            <Grid key={index} container direction="row" className={classes.paperStyle} elevation={0} onClick={() => onSetGuest(guest)}>
              <Grid item xs className={classes.borderBox}>
                <Avatar className={classes.avatarImg} />
                <Grid className={classes.boxGuestName}>
                  <Typography className={classes.txtStyle}>{guest?.firstName ?? '-'}</Typography>
                  <Typography className={classes.txtStyle}>{guest?.lastName ?? '-'}</Typography>
                </Grid>
              </Grid>

              <Grid container className={classes.borderBox}>
                <Grid item xs style={{ paddingLeft: 18, paddingRight: 18, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <img src={ROOM} alt="room" className={classes.roomImg} />
                  <Typography className={classes.txtStyle} style={{ textAlign: 'center' }}>
                    {guest?.currentCheckin?.room
                      ? `${guest?.currentCheckin?.room?.number}
                        ${guest?.currentCheckin?.room?.name && ' - '}
                        ${guest?.currentCheckin?.room?.name}`
                      : '-'}
                  </Typography>
                </Grid>
                <Grid item xs style={{ display: 'flex', flexDirection: 'column' }}>
                  <Grid className={classes.gridCheckIn}>
                    <img src={GREEN_DOOR} alt="green door" className={classes.tableContentImg} />
                    <Typography className={classes.txtStyle}>
                      {guest?.currentCheckin?.checkinDate ? moment(guest?.currentCheckin?.checkinDate).format('DD/MM/YYYY') : '-'}{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs style={{ display: 'flex', flex: 2, alignSelf: 'center', alignItems: 'center', alignContent: 'flex-start' }}>
                    <img src={RED_DOOR} alt="red door" className={classes.tableContentImg} />
                    <Typography className={classes.txtStyle}>
                      {guest?.currentCheckin?.checkoutDate ? moment(guest?.currentCheckin?.checkoutDate).format('DD/MM/YYYY') : '-'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Hidden mdDown xsDown>
                <Grid container className={classes.phoneSection} alignContent="flex-start" alignItems="flex-start">
                  <Grid item xs style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={PHONE} alt="phone" className={classes.tableContentImg} />
                    {guest.phone ?? '-'}
                  </Grid>
                  <Grid item xs style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={MAIL} alt="mail" className={classes.tableContentImg} />
                    {guest?.email ?? '-'}
                  </Grid>
                </Grid>
                <Grid container className={classes.languageSection}>
                  <Grid item xs style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={LANGUAGE} alt="language" className={classes.tableContentImg} />
                    {guest?.spokenLanguages?.length > 0 ? guest?.spokenLanguages?.map((language) => language?.name).join(', ') : '-'}
                  </Grid>
                  <Grid item xs style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={FLAG} alt="flag" className={classes.tableContentImg} />
                    {guest?.nationality ?? '-'}
                  </Grid>
                </Grid>
              </Hidden>

              <Hidden xlUp lgUp>
                <Grid container item xs={12} lg={12} md={12} className={classes.extReservationSectionTablet}>
                  <Grid
                    container
                    item
                    xs={12}
                    lg={12}
                    md={12}
                    direction="column"
                    alignContent="center"
                    style={{ textAlign: 'center', marginTop: 25, marginLeft: '15%' }}
                  >
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                      <img src={PHONE} alt="phone" className={classes.tableContentImg} />
                      <Typography className={classes.txtStyle}>{guest.phone ? guest.phone : '-'}</Typography>
                    </Grid>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                      <img src={MAIL} alt="mail" className={classes.tableContentImg} />
                      <Typography className={classes.txtStyle}>{guest.email ? guest.email : '-'}</Typography>
                    </Grid>

                    <Grid item style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
                      <img src={LANGUAGE} alt="language" className={classes.tableContentImg} />
                      <Typography className={classes.txtStyle}>{guest.language ? guest.language.code : '-'}</Typography>
                    </Grid>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                      <img src={FLAG} alt="flag" className={classes.tableContentImg} />
                      <Typography className={classes.txtStyle}>{guest.nationality ? guest.nationality : '-'}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
          ))
        ) : (
          <Box style={{ display: 'flex', justifyContent: 'center', marginTop: 150 }}>
            {isLoadingGuests ? (
              <CircularProgress />
            ) : (
              <TranslateTextComponent style={{ fontSize: 24 }} capitalize>
                no-results-found
              </TranslateTextComponent>
            )}
          </Box>
        )}
      </InfiniteScroll>
    </Box>
  );
};

export default ExternalUserGrid;
