import React from 'react';
import Layout from '../components/shared/Layout';
import GuestUsersList from '../components/users/GuestUsersList';

const GuestUsersListPage = ({ history }) => {
  return (
    <Layout history={history} section="guest-users">
      <GuestUsersList />
    </Layout>
  );
};

export default GuestUsersListPage;
