import React, { useState, useEffect } from 'react';
import typy from 'typy';

import Avatar from '@material-ui/core/Avatar';
import { Grid, Box, Typography } from '@material-ui/core/';

import { TranslateTextComponent } from '../../../translator';
import { ROUTE_ORDER } from '../../../router/routes';
import WinesHistoryModal from '../../shared/WinesHistoryModal';

import { CustomButton } from '../../../common-fe';
import styles from '../../../styles/table/tableDetail/guestReservationSidebarStyle';

const GuestReservationSidebar = ({
  reservationTable,
  reservationData,
  containerXs,
  history,
  fbTableUUID,
  tableData,
  chairClicked,
  freeChair,
  setIsFreeChair,
  isFreeChair
}) => {
  const classes = styles();

  const allergens = reservationTable?.fbProfilePreferences?.allergens ?? [];
  const reservation = typy(reservationTable, 'fbReservation').safeObject;

  const [openWineHistory, setOpenWineHistory] = useState(false);

  const handleOpenWineHistory = () => {
    setOpenWineHistory(true);
  };
  const handleCloseWineHistory = () => {
    setOpenWineHistory(false);
  };
  useEffect(() => {
    if (isFreeChair) freeChair();
  }, [isFreeChair, chairClicked]);

  const goToOrder = () => {
    if (history) history.push(`${ROUTE_ORDER}/${fbTableUUID}`);
  };

  return (
    <Grid item xs={containerXs} className={classes.leftContentStyle}>
      <Box style={{ flex: 1 }}>
        <Box className={classes.avatarContainer}>
          <Avatar className={classes.avatarImage} />
        </Box>
        <Grid container direction="column">
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography className={classes.guestNameTypography}>
              {typy(reservationTable, 'fbReservation.bookedBy.firstname').safeString}{' '}
              {typy(reservationTable, 'fbReservation.bookedBy.lastname').safeString}
            </Typography>
          </Grid>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} className={classes.boxTypography} style={{ justifyContent: 'space-between' }}>
              <Box className={classes.boxTypography}>
                <Typography className={classes.typographyStyle} style={{ fontWeight: 'bold' }}>
                  <TranslateTextComponent capitalize>room</TranslateTextComponent>:{'  '}
                </Typography>
                <Typography>{reservation && reservation.guestRoom ? reservation.guestRoom : ' -'}</Typography>
                <Typography className={[classes.typographyStyle, classes.spaceContent].join(' ')}>
                  {typy(reservationTable, 'fbReservation.bookedBy.guestRoom').safeString}
                </Typography>
              </Box>
              <Box className={[classes.boxTypography, classes.boxAlignedRight].join(' ')}>
                <Typography className={classes.typographyStyle} style={{ fontWeight: 'bold' }}>
                  <TranslateTextComponent capitalize>time</TranslateTextComponent>:{'  '}
                </Typography>
                <Typography className={[classes.typographyStyle, classes.spaceContent].join(' ')}>
                  {typy(reservationTable, 'fbReservation.reservationTime').safeString}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} className={classes.boxTypography} style={{ justifyContent: 'space-between' }}>
              <Box className={classes.boxTypography}>
                <Typography className={classes.typographyStyle} style={{ fontWeight: 'bold' }}>
                  N pax:
                </Typography>
                <Typography className={[classes.typographyStyle, classes.spaceContent].join(' ')}>
                  {typy(reservationTable, 'fbReservation.nPax').safeNumber}
                </Typography>
              </Box>
              <Box className={[classes.boxTypography, classes.boxAlignedRight].join(' ')}>
                <Typography className={classes.typographyStyle} style={{ fontWeight: 'bold' }}>
                  <TranslateTextComponent capitalize>t-numb</TranslateTextComponent> :
                </Typography>
                <Typography className={[classes.typographyStyle, classes.spaceContent].join(' ')}>
                  {tableData && tableData.number ? tableData.number : ' -'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container style={{ paddingTop: 6.5, paddingBottom: 6.5 }} className={classes.gridContainer}>
            <Grid item xs={6} className={classes.gridAllergies}>
              <Typography className={classes.secondaryTypoStyle}>
                <TranslateTextComponent capitalize>allergens</TranslateTextComponent>:
              </Typography>
              <Typography className={classes.intoleranceTypo}>
                {allergens && allergens.length !== 0
                  ? allergens
                      .filter((allergen) => allergen.type === 'allergen')
                      .map((res) => res.fbAllergen.name)
                      .join(',\r\n')
                  : '-'}
              </Typography>
            </Grid>
            <Box className={classes.intoleranceBoxContainer}>
              <Grid item xs={6} className={classes.gridAllergies}>
                <Typography className={classes.secondaryTypoStyle}>
                  <TranslateTextComponent capitalize>intolerances</TranslateTextComponent>:
                </Typography>
                <Typography className={classes.intoleranceTypo}>
                  {allergens && allergens.length !== 0
                    ? allergens
                        .filter((allergen) => allergen.type === 'intolerance')
                        .map((res) => res.fbAllergen.name)
                        .join(',\r\n')
                    : '-'}
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <Grid container style={{ marginTop: 6.5 }} className={classes.gridContainer}>
            <Grid item xs={12} className={classes.gridAllergies}>
              <Typography className={classes.secondaryTypoStyle}>
                <TranslateTextComponent capitalize>preferences</TranslateTextComponent>:
              </Typography>
              <Typography className={classes.preferencesTypo}>
                {typy(reservationTable, 'fbReservation.bookedBy.preferences').safeString || '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 6.5 }}>
            <Grid item xs={4}>
              <Typography className={classes.secondaryTypoStyle} style={{ display: 'flex' }}>
                <TranslateTextComponent capitalize>favourite-wine</TranslateTextComponent>:
              </Typography>
              <Typography className={classes.secondaryTypoStyle} style={{ display: 'flex' }}>
                <TranslateTextComponent capitalize>favourite-water</TranslateTextComponent>:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography className={classes.fbPreferencesTypo}>
                {typy(reservationTable, 'fbReservation.bookedBy.favouriteWine').safeString || '-'}
              </Typography>
              <Typography className={classes.fbPreferencesTypo}>
                {typy(reservationTable, 'fbReservation.bookedBy.favouriteWater').safeString || '-'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.smallButtonContainer}>
        <CustomButton onClick={() => goToOrder()}>
          <TranslateTextComponent>visit-order</TranslateTextComponent>
        </CustomButton>
        <CustomButton
          style={{ backgroundColor: isFreeChair ? 'rgb(0, 128, 178)' : null }}
          onClick={() => {
            setIsFreeChair(true);
            freeChair();
          }}
        >
          <TranslateTextComponent>free-chair</TranslateTextComponent>
        </CustomButton>
        <CustomButton>
          <TranslateTextComponent>order-history</TranslateTextComponent>
        </CustomButton>
        <CustomButton onClick={handleOpenWineHistory}>
          <TranslateTextComponent>wine-history</TranslateTextComponent>
        </CustomButton>
        {/* <CustomButton>
          <TranslateTextComponent>registry</TranslateTextComponent>
        </CustomButton> */}
        {openWineHistory && <WinesHistoryModal item={reservationData} open={openWineHistory} onClose={handleCloseWineHistory} />}
      </Box>
    </Grid>
  );
};

export default GuestReservationSidebar;
