/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { Button, IconButton, Box, Grid } from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import { TranslateTextComponent } from '../../translator';
import { MaterialIcon } from '../../common-fe';

const getRoomColumns = ({ deleteRoom, editRoom }) => {
  return [
    {
      id: 'isActive',
      disablePadding: false,
      width: 50,
      label: <TranslateTextComponent capitalize>state</TranslateTextComponent>,
      isSortable: false,
      render: (item) =>
        item.isActive ? (
          <TranslateTextComponent capitalize>opened</TranslateTextComponent>
        ) : (
          <TranslateTextComponent capitalize>closed</TranslateTextComponent>
        )
    },
    {
      id: 'name',
      disablePadding: false,
      width: 70,
      label: <TranslateTextComponent capitalize>name</TranslateTextComponent>,
      isSortable: false,
      render: (item) => item.name
    },
    {
      id: 'maxPax',
      disablePadding: false,
      width: 40,
      label: <TranslateTextComponent capitalize>max-covers</TranslateTextComponent>,
      isSortable: false,
      render: (item) => item.maxPax
    },
    {
      id: 'hasOnlineReservations',
      disablePadding: false,
      width: 40,
      label: <TranslateTextComponent capitalize>online-reservations</TranslateTextComponent>,
      isSortable: false,
      render: (item) =>
        item.hasOnlineReservations ? (
          <TranslateTextComponent capitalize>yes</TranslateTextComponent>
        ) : (
          <TranslateTextComponent capitalize>no</TranslateTextComponent>
        )
    },
    {
      id: 'service',
      disablePadding: false,
      width: 100,
      label: <TranslateTextComponent capitalize>service</TranslateTextComponent>,
      isSortable: false,
      render: (item) => (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          {item.fbServices &&
            item.fbServices.map((service, index) => (
              <Grid key={`service${index}`} container direction="row" alignItems="center" item xs={4}>
                <MaterialIcon iconName={service.icon} /> &nbsp;
                <TranslateTextComponent capitalize>{service.name}</TranslateTextComponent>
              </Grid>
            ))}
        </Box>
      )
    },
    {
      id: 'edit',
      disablePadding: false,
      width: 60,
      label: <TranslateTextComponent capitalize>edit</TranslateTextComponent>,
      isSortable: false,

      render: (item) => (
        <Button color="primary" variant="contained" className="buttonTable" onClick={() => editRoom(item)}>
          <TranslateTextComponent capitalize>open</TranslateTextComponent>
        </Button>
      )
    },
    {
      id: 'delete',
      disablePadding: false,
      width: 40,
      label: <TranslateTextComponent capitalize>delete-icon</TranslateTextComponent>,
      isSortable: false,

      render: (item) => (
        <IconButton className="buttonCircle" onClick={() => deleteRoom(item)}>
          <DeleteIcon />
        </IconButton>
      )
    }
  ];
};

export default getRoomColumns;
