import gql from 'graphql-tag';
import { PROFILE_FRAGMENT, GUEST_FRAGMENT } from '../fragments';

export const ADD_GUEST = gql`
  mutation addGuest($languageCode: String, $guestTypeUUID: String, $guestData: GuestInput!) {
    addGuest(languageCode: $languageCode, guestTypeUUID: $guestTypeUUID, guestData: $guestData) {
      id
      uuid
      firstname
      lastname
      gender
      dob
      nationality
      address
      city
      zipCode
      country
      email
      phone
      mobilePhone
      favouriteWine
      favouriteWater
      preferences
      fiscalCode
      vatNumber
      language {
        ISO639_1
        ISO639_3
        name
      }
      guestType {
        id
        uuid
        name
        description
      }
      guestsFbAllergens {
        type
        fbAllergen {
          id
          name
          description
        }
      }
    }
  }
`;

export const GUEST_SIGN_UP = gql`
  mutation guestSignUp($signUpInput: SignUpInputDto!) {
    guestSignUp(signUpInput: $signUpInput) {
      id
    }
  }
`;

export const EDIT_GUEST = gql`
  mutation editGuest($guestUUID: String!, $languageCode: String, $guestTypeUUID: String, $guestData: GuestInput!) {
    editGuest(guestUUID: $guestUUID, languageCode: $languageCode, guestTypeUUID: $guestTypeUUID, guestData: $guestData) {
      ...guestFragment
    }
  }
  ${GUEST_FRAGMENT}
`;

export const SAVE_PROFILE_DATA = gql`
  mutation saveProfileData($profileData: ProfileFullDataInputDto!, $profileUUID: String) {
    saveProfileData(profileData: $profileData, profileUUID: $profileUUID) {
      ...profileFragment
    }
  }
  ${PROFILE_FRAGMENT}
`;

export const DELETE_GUEST = gql`
  mutation deleteGuest($guestUUID: String!) {
    deleteGuest(guestUUID: $guestUUID)
  }
`;
