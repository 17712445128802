/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import HelpIcon from '@material-ui/icons/Help';
import DoneIcon from '@material-ui/icons/Done';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import { Button, Typography, Box, Modal as MaterialModal, Fade, Backdrop, Paper, CircularProgress } from '@material-ui/core/';
import modalStyle from '../../styles/shared/modalStyle';

import { primaryColor, appTheme } from '../../styles/theme';

const modalClasses = {
  success: {
    primaryColor: '#449D44',
    defaultIcon: <DoneIcon />
  },
  primary: {
    primaryColor,
    defaultIcon: <HelpIcon />
  },
  warning: {
    primaryColor: '#ee9800',
    defaultIcon: <WarningIcon />
  },
  danger: {
    primaryColor: '#cb2d24',
    defaultIcon: <ErrorIcon />
  }
};

const Modal = ({
  modalClass,
  icon,
  iconStyle,
  title,
  text,
  content,
  isVisible,
  isLoading,
  titleStyle,
  textStyle,
  paperStyle,
  onBackdropPress,
  onButtonClick,
  actionButtons
}) => {
  const classes = modalStyle();
  const screenWidth = window.screen.width;
  const showMobile = screenWidth < appTheme.breakpoints.values.md;

  let iconComponent = null;
  if (typeof icon === 'string' && modalClass) {
    iconComponent = (
      <Icon color="primary" style={{ fontSize: 40, color: modalClasses[modalClass].primaryColor }}>
        {icon}
      </Icon>
    );
  } else if (typeof icon === 'object' && modalClass) {
    iconComponent = React.cloneElement(icon, { style: { fontSize: 40, color: modalClasses[modalClass].primaryColor, ...iconStyle } });
  } else if (modalClass) {
    iconComponent = React.cloneElement(modalClasses[modalClass].defaultIcon, {
      style: { fontSize: 40, color: modalClasses[modalClass].primaryColor, ...iconStyle }
    });
  }
  return (
    <MaterialModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isVisible}
      onClose={onBackdropPress}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in style={showMobile ? { minWidth: 'unset', borderRadius: 10 } : { minWidth: 430, borderRadius: 10 }}>
        <Paper className={classes.paper} style={paperStyle}>
          <Box className={classes.modalDescriptionInfo}>
            <Box>{iconComponent}</Box>
            {title && (
              <Typography
                style={{
                  fontWeight: 'bold',
                  marginVertical: 8,
                  fontSize: 20,
                  ...titleStyle
                }}
              >
                {title}
              </Typography>
            )}
            {text && <Typography style={{ marginTop: 15, ...textStyle }}>{text}</Typography>}
            {content}
          </Box>
          <Box style={{ marginTop: 20, flexDirection: 'row' }}>
            <Box
              style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: !isLoading && actionButtons && actionButtons.length > 1 ? 'space-between' : 'center',
                display: 'flex'
              }}
            >
              {isLoading ? (
                <CircularProgress style={{ color: '#00b7ff' }} />
              ) : (
                actionButtons || (
                  <Button color="primary" variant="contained" className={classes.buttonStyle} onClick={onButtonClick}>
                    OK
                  </Button>
                )
              )}
            </Box>
          </Box>
        </Paper>
      </Fade>
    </MaterialModal>
  );
};

export default Modal;

Modal.propTypes = {
  isLoading: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.any,
  modalClass: PropTypes.oneOf(Object.keys(modalClasses), null),
  iconSize: PropTypes.number,
  titleStyle: PropTypes.instanceOf(Object),
  textStyle: PropTypes.instanceOf(Object),
  actionButtons: PropTypes.array
};
