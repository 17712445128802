/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { TranslateTextComponent } from '../../../translator';
import { ReactComponent as BinIcon } from '../../../assets/images/orders/bin.svg';
import TableButton from '../../shared/TableButton';
import { mainTextColor } from '../../../styles/theme';
import { Dropdown } from '../../../common-fe/src';

const textTranslate = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

const guestUsersColumns = ({ openCheckInModal, updateGuestUserEmailHandler }) => {
  return [
    {
      id: 'first-name',
      label: textTranslate('first-name'),
      isSortable: false,
      align: 'left',
      render: (item) => item.firstName
    },
    {
      id: 'last-name',
      label: textTranslate('last-name'),
      isSortable: false,
      align: 'left',
      render: (item) => item.lastName
    },
    {
      id: 'email',
      disablePadding: false,
      label: textTranslate('email'),
      isSortable: false,
      align: 'left',
      render: (item) => item.email
    },
    {
      id: 'confirmed-email',
      disablePadding: false,
      label: textTranslate('confirmed-email'),
      isSortable: false,
      align: 'left',
      render: (item) => (
        <Dropdown
          dropdownStyle={{ backgroundColor: 'white' }}
          items={[
            { label: textTranslate('yes'), value: 'true' },
            { label: textTranslate('no'), value: 'false' }
          ]}
          value={item.emailConfirmed?.toString()}
          onChange={({ target: { value } }) => updateGuestUserEmailHandler({ emailConfirmedValue: value === 'true', userUUID: item.uuid })}
        />
      )
    },
    {
      id: 'check-in-status',
      disablePadding: false,
      label: textTranslate('check-in-status'),
      isSortable: false,
      align: 'left',
      render: (item) => (
        <TranslateTextComponent style={{ fontSize: 16, fontWeight: 500, color: mainTextColor }} capitalize>
          {item.profile?.checkins?.[0]?.status?.toLowerCase()?.replace(/_/, '-') ?? '-'}
        </TranslateTextComponent>
      )
    },
    {
      id: 'check-in',
      disablePadding: false,
      label: textTranslate('check-in'),
      isSortable: false,
      align: 'left',
      render: (item) => (
        <TableButton fill="gray" style={{ cursor: 'pointer' }} onClick={() => openCheckInModal(item)}>
          check-in
        </TableButton>
      )
    },
    {
      id: 'delete',
      disablePadding: false,
      label: textTranslate('delete'),
      isSortable: false,
      align: 'left',
      render: () => <BinIcon fill="gray" style={{ cursor: 'pointer' }} />
    }
  ];
};

export default guestUsersColumns;
