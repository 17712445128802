import React from 'react';
import { Box, Grid, ClickAwayListener, Paper } from '@material-ui/core/';
import CustomInput from '../shared/CustomInput';
import ageStyle from '../../styles/saveReservation/kidsAge';
import { MaterialIcon } from '../../common-fe';
import { TranslateTextComponent } from '../../translator';

const KidsAgeBox = ({ category, fbGuestList, editFbGuestAge }) => {
  const kidsClass = ageStyle();
  const filteredFbGuestList = fbGuestList.filter((fbG) => fbG.guestPhysicalCategoryId === category.id);

  return (
    filteredFbGuestList.length > 0 && (
      <Box className={kidsClass.parentBox}>
        <ClickAwayListener onClickAway={() => true}>
          <Paper className={kidsClass.paperParent}>
            <Grid container style={{ justifyContent: 'center' }}>
              <Grid>
                <Box className={kidsClass.ageBoxHeader}>
                  <TranslateTextComponent capitalize>enter-age</TranslateTextComponent>
                </Box>
              </Grid>
              {fbGuestList.map((fbGuest, index) => {
                return (
                  fbGuest.guestPhysicalCategoryId === category.id && (
                    <Grid key={index} container className={kidsClass.contentContainer}>
                      <Grid item xs={4} className={kidsClass.img}>
                        {category.icon && <MaterialIcon iconName={category.icon} />}
                      </Grid>
                      <Grid item xs={8} style={{ paddingRight: 3 }}>
                        <CustomInput value={fbGuest.age || ''} onChange={(e) => editFbGuestAge(Number(e.target.value), index)} />
                      </Grid>
                    </Grid>
                  )
                );
              })}
            </Grid>
          </Paper>
        </ClickAwayListener>
      </Box>
    )
  );
};

export default KidsAgeBox;
