import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography, Input } from '@material-ui/core/';
import { primaryColor } from '../../styles/theme';

const useStyles = makeStyles(() => ({
  imgBox: () => ({
    width: '100%',
    height: '100%',
    alignSelf: 'center',
    textAlign: 'end'
  }),
  typographyDescr: () => ({
    fontSize: 12,
    color: '#55595C',
    width: '100%'
  }),
  titleCatStyle: () => ({
    fontWeight: 'bold',
    color: '#55595C',
    fontSize: 12
  }),
  noteTextField: () => ({
    fontSize: 14,
    height: 70,
    width: 400,
    color: '#55595C',
    border: '1.5px solid',
    borderColor: primaryColor,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 4,
    display: 'flex'
  })
}));

const ReferencesText = ({ title, error, img1, img2, errorLabel, inputStyle, placeholder, onChange, value, onClick, ...others }) => {
  let inputStyleObj = {
    ...inputStyle
  };
  if (error) {
    inputStyleObj = {
      ...inputStyleObj,
      borderColor: 'red'
    };
  }

  const classes = useStyles();
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} className={classes.noteTextField}>
          {img1}
          <Input
            disableUnderline
            fullWidth
            placeholder={placeholder}
            variant="outlined"
            value={value}
            style={inputStyleObj}
            onChange={onChange}
            error={error}
            {...others}
          />

          {img2}
        </Grid>
        <Grid item xs={12} style={{ textAlign: ' -webkit-center' }}>
          <Typography style={{ fontWeight: '600', marginRight: 5, color: '#55595C', fontSize: 14, marginTop: 25 }}>
            {title} {error ? <span style={{ color: 'red', fontSize: 15 }}>{errorLabel}</span> : null}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReferencesText;
