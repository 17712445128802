import React, { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { LanguageContext, AVAILABLE_LANGUAGES, languageConfig } from '../../contexts/LanguageContext';
import { invertedPrimary } from '../../styles/theme';
import styles from '../../styles/shared/headerOptions';

const LanguageSwitcher = () => {
  const languageContext = useContext(LanguageContext);
  const classes = styles();

  const onChangeLanguage = (language) => {
    languageContext.setLanguage(language);
  };

  return (
    <Box>
      {AVAILABLE_LANGUAGES.map((language) => (
        <Box key={language} className={classes.languageItem} onClick={() => onChangeLanguage(language)}>
          <Box className={classes.flagContainer}>{languageConfig[language].icon}</Box>
          <Box className={classes.textContainer}>
            <Typography style={{ color: invertedPrimary, fontSize: 14 }}>{languageConfig[language].languageLabel}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default LanguageSwitcher;
