import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core/';
import { primaryColor } from '../../styles/theme';

const useStyles = makeStyles(() => ({
  boxInput: () => ({
    borderRadius: 5,
    border: '1.5px solid',
    borderColor: primaryColor,
    width: 75,
    height: 40,
    alignItems: 'center'
  }),
  imgBox: () => ({
    marginLeft: 10,
    width: 22,
    height: 22
  }),
  typographyCounter: () => ({
    marginLeft: 5,
    textAlign: 'right',
    paddingRight: 10,
    fontSize: 14,
    color: '#55595C',
    display: 'contents'
  }),
  titleCatStyle: () => ({
    fontWeight: 'bold',
    color: '#55595C',
    fontSize: 14
  })
}));

const BoxImgCategories = (props) => {
  const classes = useStyles();
  return (
    <Box style={{ display: 'flex', alignItems: 'flex-end', width: '100%', marginLeft: 10, marginRight: 10 }}>
      <Box>
        <Typography className={classes.titleCatStyle}>{props.title}</Typography>
        <Grid container className={classes.boxInput}>
          <Grid item xs className={classes.imgBox}>
            {props.img}
          </Grid>
          <Grid item xs style={{ textAlign: 'center' }}>
            <Typography className={classes.typographyCounter}>{props.count}</Typography>
          </Grid>
        </Grid>
      </Box>
      {props.children}
    </Box>
  );
};

export default BoxImgCategories;
