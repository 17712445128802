import React, { useContext } from 'react';
import { Box, Grid, Typography, Accordion, AccordionDetails, AccordionSummary, ButtonBase, Button } from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TranslateTextComponent } from '../../translator';
import { ReactComponent as PlayCourseImg } from '../../assets/images/order/play_course.svg';
import { ReactComponent as StopCourseImg } from '../../assets/images/order/stop_course.svg';
import { SharedBy, ChairOrHalfPortion, Centerpiece } from './OrderIconComponents';
import { OrderContext } from '../../contexts/OrderContext';
import { ModalContext } from '../../contexts/ModalContext';
import OrderNotesComponent from './OrderNotesComponent';
import FB_ORDER_STATUSES from '../../constants/order/orderStatuses';
import { MaterialIcon } from '../../common-fe';
import { greenButton, redButton } from '../../styles/theme';
import FbCourseStatusIcon from './FbCourseStatusIcon';
import modalStyle from '../../common-fe/src/styles/shared/modalStyle';
import styles from '../../styles/order/fbCourse';
import { getTotalQuantity, groupedByMenuItem } from '../../constants/order/order';

const FbCourseFood = ({
  fbCourse,
  expanded,
  expansionKey,
  changingFbCourseStatus,
  handleSetChangingFbCourseStatus,
  notShowSummary,
  onClick,
  onEditFbMenuItem,
  onDeleteFbMenuItem,
  onEditFbCourse,
  onSaveFbCourse,
  onRestoreFbCourse,
  onChangePanel,
  onSetFbCourseStatus,
  onDeleteCourse,
  onPlayFbCourse,
  onStopFbCourse
}) => {
  const withoutLabel = styles({ color: redButton });
  const withLabel = styles({ color: greenButton });
  const classes = styles();
  const classesModal = modalStyle();

  const modalContext = useContext(ModalContext);

  const orderContext = useContext(OrderContext);

  const openModalToDeleteCourse = (uuid) => {
    modalContext.openModal({
      class: 'primary',
      text: <TranslateTextComponent capitalize>delete-fb-course-question</TranslateTextComponent>,

      actionButtons: [
        <Button key={1} variant="outlined" color="primary" className={classesModal.invertedButton} onClick={modalContext.closeModal}>
          <TranslateTextComponent>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={2}
          color="primary"
          variant="contained"
          className={classesModal.buttonStyle}
          onClick={() => {
            modalContext.closeModal();
            onDeleteCourse(uuid);
          }}
        >
          <TranslateTextComponent>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };
  const fbCoursesMenuItems = fbCourse && fbCourse.fbCoursesMenuItems;
  const orderedReducedFbFoodCourses = groupedByMenuItem(fbCoursesMenuItems);

  return (
    <Grid container style={{ marginBottom: 15, paddingRight: 10 }} onClick={onClick}>
      <Box style={{ flex: 1 }}>
        <Accordion className={classes.expansionPanel} expanded={expanded} onChange={onChangePanel} onClick={notShowSummary}>
          <AccordionSummary
            className={classes.expansionPanelSummary}
            expandIcon={<ExpandMoreIcon className={classes.expandArrow} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container justify="flex-start" alignItems="center">
              <Typography
                className={
                  orderContext.activeFbCourseKey === `${orderContext.fbMacroCategory}${fbCourse.number}`
                    ? [classes.courseNumberTypography, 'selected'].join(' ')
                    : classes.courseNumberTypography
                }
              >
                {fbCourse.number}
              </Typography>
              <FbCourseStatusIcon
                fbCourse={fbCourse}
                macroCategory="food"
                showStatuses={expansionKey === changingFbCourseStatus}
                onClickIcon={() => handleSetChangingFbCourseStatus(expansionKey)}
                onSelectStatus={(status) => {
                  onSetFbCourseStatus(fbCourse.uuid, status);
                }}
              />

              {fbCourse.fbCoursesMenuItems.length === 0 && (
                <Typography style={{ marginLeft: 10, fontSize: 14 }}>
                  <TranslateTextComponent uppercase>empty-course</TranslateTextComponent>
                </Typography>
              )}
            </Grid>
          </AccordionSummary>

          <AccordionDetails className={classes.expansionPanelDetails}>
            {expanded &&
              orderedReducedFbFoodCourses.map((menuItems, index) => {
                return (
                  <Grid key={index} container direction="column" style={{ paddingBottom: 10 }}>
                    <Grid container alignItems="center">
                      {getTotalQuantity(menuItems)}x&nbsp;
                      <Typography className={classes.fbMenuItemName}>{menuItems[0].name}</Typography>
                    </Grid>
                    <Grid style={{ paddingBottom: 5 }}>
                      {menuItems[0].with.length > 0 && (
                        <Grid container>
                          <Typography className={withLabel.withOrWithoutItem}>{menuItems[0].with.join(', ')}</Typography>
                        </Grid>
                      )}
                      {menuItems[0].without.length > 0 && (
                        <Grid container>
                          <Typography className={withoutLabel.withOrWithoutItem}>{menuItems[0].without.join(', ')}</Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Grid container>
                      {menuItems
                        .sort((fbMa, fbMb) => fbMa.id - fbMb.id)
                        .map((menuItem, i) => {
                          return (
                            <Grid key={i} style={{ marginRight: 5, display: 'flex', flexDirection: 'column' }}>
                              {menuItem.sharedBy && menuItem.sharedBy.length > 1 ? (
                                <SharedBy
                                  item={menuItem}
                                  sharedBy={menuItem.sharedBy}
                                  fbChairs={orderContext.fbReservationTable.fbTable.fbChairs.sort((prev, next) => prev.number - next.number)}
                                />
                              ) : (
                                <Grid style={{ paddingTop: menuItem.centerpiece ? 0 : 5 }}>
                                  {menuItem.fbChairId !== null && (
                                    <ChairOrHalfPortion
                                      item={menuItem}
                                      isHalfPortion={menuItem.halfPortion}
                                      chair={orderContext.getChairFromId(menuItem.fbChairId)}
                                    />
                                  )}

                                  {menuItem.centerpiece && <Centerpiece item={menuItem} />}
                                </Grid>
                              )}
                              {menuItem.quantity && <Typography className={classes.quantityValue}>x{menuItem.quantity}</Typography>}
                              {orderContext.isEditingCourse && (
                                <Grid container direction="column">
                                  <ButtonBase style={{ color: 'red' }} onClick={() => onDeleteFbMenuItem(menuItem)}>
                                    <MaterialIcon iconName="delete" style={{ color: redButton, fontSize: 'large' }} />
                                  </ButtonBase>
                                  <ButtonBase onClick={() => onEditFbMenuItem(menuItem)}>
                                    <MaterialIcon iconName="edit" style={{ color: '#707070', fontSize: 'large' }} />
                                  </ButtonBase>
                                </Grid>
                              )}
                            </Grid>
                          );
                        })}
                    </Grid>
                    {menuItems.some((item) => item.notes !== '' && item.notes !== null) && (
                      <OrderNotesComponent items={menuItems} classes={classes} />
                    )}
                  </Grid>
                );
              })}
            {(fbCourse.fbCoursesMenuItems.length > 0 || orderContext.isEditingCourse) && (
              <Grid container justify="space-between" alignItems="center" style={{ paddingBottom: 10 }}>
                <Grid container justify="space-between" alignItems="center" style={{ flex: 1, marginBottom: 10 }}>
                  <Grid item>
                    {!orderContext.isEditingCourse &&
                      orderContext.activeFbCourseKey === `food${fbCourse.number}` &&
                      (fbCourse.fbCourseStatus === FB_ORDER_STATUSES.none.label ||
                        fbCourse.fbCourseStatus === FB_ORDER_STATUSES.todo.label ||
                        fbCourse.fbCourseStatus === FB_ORDER_STATUSES.waiting.label) && (
                        <ButtonBase className={classes.editFbMenuItemButton} onClick={() => onEditFbCourse(fbCourse)}>
                          <TranslateTextComponent uppercase>edit</TranslateTextComponent>
                        </ButtonBase>
                      )}
                  </Grid>

                  {fbCourse.id && (
                    <Grid item className={classes.statusIconActionsContainer}>
                      <Grid>
                        {(fbCourse.fbCourseStatus === FB_ORDER_STATUSES.none.label || fbCourse.isWaiting) && !orderContext.isEditingCourse && (
                          <PlayCourseImg
                            onClick={(e) => {
                              e.stopPropagation();
                              onPlayFbCourse();
                            }}
                          />
                        )}
                      </Grid>
                      {fbCourse.fbCourseStatus !== FB_ORDER_STATUSES.completed.label &&
                        fbCourse.fbCourseStatus !== FB_ORDER_STATUSES.none.label &&
                        !fbCourse.isWaiting &&
                        !orderContext.isEditingCourse && (
                          <StopCourseImg
                            onClick={(e) => {
                              e.stopPropagation();
                              onStopFbCourse();
                            }}
                          />
                        )}
                    </Grid>
                  )}
                </Grid>

                <Grid container justify="space-between">
                  {orderContext.isEditingCourse && (
                    <>
                      <ButtonBase className={classes.optionsFbMenuItemButton} onClick={() => onSaveFbCourse(fbCourse)}>
                        <TranslateTextComponent uppercase>save-and-close</TranslateTextComponent>
                      </ButtonBase>
                      <ButtonBase className={classes.optionsFbMenuItemButton} onClick={() => onRestoreFbCourse(fbCourse)}>
                        <TranslateTextComponent uppercase>undo-changes</TranslateTextComponent>
                      </ButtonBase>
                      {fbCourse.id && (
                        <ButtonBase className={classes.optionsFbMenuItemButton} onClick={() => openModalToDeleteCourse(fbCourse.uuid)}>
                          <TranslateTextComponent uppercase>delete-course</TranslateTextComponent>
                        </ButtonBase>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
      {!expanded && fbCourse.id && fbCourse.fbCoursesMenuItems.length > 0 && (
        <Box className={classes.statusIconActionsContainer}>
          {(fbCourse.fbCourseStatus === FB_ORDER_STATUSES.none.label || fbCourse.isWaiting) && (
            <Box style={{ marginLeft: 10 }}>
              <PlayCourseImg
                onClick={(e) => {
                  e.stopPropagation();
                  onPlayFbCourse();
                }}
              />
            </Box>
          )}
          {fbCourse.fbCourseStatus !== FB_ORDER_STATUSES.completed.label &&
            fbCourse.fbCourseStatus !== FB_ORDER_STATUSES.none.label &&
            !fbCourse.isWaiting && (
              <Box style={{ marginLeft: 10 }}>
                <StopCourseImg
                  onClick={(e) => {
                    e.stopPropagation();
                    onStopFbCourse();
                  }}
                />
              </Box>
            )}
        </Box>
      )}
    </Grid>
  );
};

export default FbCourseFood;
