import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';

const useBase64Image = (imagePath) => {
  const authContext = useContext(AuthContext);

  const [image, setImage] = useState(null);
  useEffect(() => {
    if (imagePath) {
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}${imagePath}`, {
          responseType: 'arraybuffer',
          headers: { Authorization: `Bearer ${authContext.user.access_token}` }
        })
        .then((response) => {
          const base64 = Buffer.from(response.data, 'binary').toString('base64');
          const ext = imagePath.split('.').pop();
          const newImage = `data:image/${ext};base64,${base64}`;
          setImage(newImage);
        })
        .catch((err) => console.log('err', err));
    }
  }, [imagePath]);

  return image;
};

export default useBase64Image;
