import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { TextareaAutosize, Accordion, AccordionSummary, AccordionDetails, Icon, CircularProgress } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import moment from 'moment';
import MultiSelect from '../../../shared/MultiSelect';
import Buttons from '../../../shared/Buttons';
import { redButton, primaryColor, mainTextColor } from '../../../../styles/theme';
import InputWithLabel from '../../../shared/InputWithLabel';
import styles from '../../../../styles/settings/tabs';
import useCustomQuery from '../../../../hooks/useCustomQuery';
import { GET_MOODS } from '../../../../graphql/user/queries';
import { capitalizeFirstLetter, translate } from '../../../../translator';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import accordionStyles from '../../../../styles/billsManagement/wideCard';
import { Dropdown } from '../../../../common-fe/src';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  width: 100%;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

const StayTitle = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${() => mainTextColor};
`;

const chipParams = {
  variant: 'default',
  color: 'primary',
  style: { color: 'white' }
};

const ExperientialForm = ({ data, tags, formValues, onFormValuesChange, loading, saveProfileData }) => {
  const { language } = useContext(LanguageContext);
  const [formData, setFormData] = useState({
    vip: null,
    bestAttention: null,
    favoriteExperiences: null,
    favoriteInternalRestaurants: null,
    favoriteExternalRestaurants: null,
    checkins: []
  });

  const [expanded, setExpanded] = useState(() => {
    const obj = {};
    for (let i = 0; i < data?.checkins.length; i++) {
      if (i === 0) obj[i] = true;
      else obj[i] = false;
    }
    return obj;
  });
  let initialFormData = {};

  const classes = styles();
  const accordionClasses = accordionStyles();

  // Queries
  const getMoods = useCustomQuery(GET_MOODS);

  const getLabelsAndValues = (value) => {
    if (value) {
      return typeof value === 'string'
        ? { label: capitalizeFirstLetter(translate(value, language)), value }
        : value.map((item) => ({ label: capitalizeFirstLetter(translate(item, language)), value: item }));
    }
    return null;
  };

  useEffect(() => {
    const checkins =
      data?.checkins?.map((checkin) => ({
        uuid: checkin.uuid,
        reasonOfTrip: checkin?.checkinPreferences?.reasonOfTrip ?? null,
        checkinMoodIDs:
          checkin.checkinPreferences?.checkinMood?.map((mood) => ({
            label: capitalizeFirstLetter(translate(mood.name, language)),
            value: mood.id
          })) ?? [],
        checkoutMoodIDs:
          checkin.checkinPreferences?.checkoutMood?.map((mood) => ({
            label: capitalizeFirstLetter(translate(mood.name, language)),
            value: mood.id
          })) ?? [],
        arrangement: checkin?.arrangement ?? null,
        requestedServices: getLabelsAndValues(checkin?.checkinPreferences?.requestedServices) ?? [],
        transportationInfo: checkin?.checkinPreferences?.transportationInfo ?? null,
        reservedLocalAdviser: checkin?.checkinPreferences?.reservedLocalAdviser ?? null,
        localAdvisers: getLabelsAndValues(checkin?.checkinPreferences?.localAdvisers, false) ?? [], // MAX 3,
        complaints: getLabelsAndValues(checkin?.checkinPreferences?.complaints) ?? [],
        checkoutFeedback: checkin?.checkinPreferences?.checkoutFeedback ?? null,
        housewife: checkin?.checkinPreferences?.housewife ?? '',
        checkinNotes: checkin?.checkinPreferences?.checkinNotes ?? ''
      })) ?? [];
    setFormData({
      vip: formValues?.vip || data?.profileInfo?.vip,
      bestAttention: formValues?.bestAttention || data?.bestAttention,
      favoriteExperiences: formValues?.favoriteExperiences || getLabelsAndValues(data?.profileInfo?.favoriteExperiences) || [],
      favoriteInternalRestaurants:
        formValues?.favoriteInternalRestaurants || getLabelsAndValues(data?.fbProfilePreferences?.favoriteInternalRestaurants) || [],
      favoriteExternalRestaurants: formValues?.favoriteExternalRestaurants || data?.fbProfilePreferences?.favoriteExternalRestaurants || '',
      checkins: formValues?.checkins || [...checkins]
    });
  }, [data]);

  useEffect(() => {
    if (Object.keys(initialFormData).length <= 0 && formData)
      initialFormData = {
        vip: data?.profileInfo?.vip,
        bestAttention: data?.bestAttention,
        favoriteExperiences: getLabelsAndValues(data?.profileInfo?.favoriteExperiences) ?? [],
        favoriteInternalRestaurants: getLabelsAndValues(data?.fbProfilePreferences?.favoriteInternalRestaurants) ?? [],
        favoriteExternalRestaurants: data?.fbProfilePreferences?.favoriteExternalRestaurants ?? '',
        checkins: [...formData.checkins]
      };
  }, [formData]);

  useEffect(() => {
    onFormValuesChange({ ...initialFormData, ...formData });
  }, [formData]);

  const getTags = (fieldName) => {
    return (
      tags
        .find((tag) => tag.fieldName === fieldName)
        ?.tags?.map((tag) => ({ label: capitalizeFirstLetter(translate(tag?.toLowerCase(), language)), value: tag })) ?? []
    );
  };

  const updateCheckinValue = ({ i, name, value }) => {
    if (formData?.checkins?.length > 0) {
      const checkins = [...formData?.checkins];
      checkins[i][name] = value;
      setFormData((prev) => ({ ...prev, checkins: [...checkins] }));
    }
  };

  const onSubmit = () => {
    saveProfileData(formData);
  };

  const onReset = () => {
    const newCheckins = [];
    formData.checkins.forEach((checkin, i) => {
      checkin = { ...initialFormData[i] };
      newCheckins.push(checkin);
    });
    setFormData({ ...initialFormData, checkins: newCheckins });
  };

  return (
    <Container>
      <RowContainer>
        <InputWithLabel label="vip-guest">
          <Dropdown
            name="vip"
            items={getTags('profileInfo.vip')}
            value={formData?.vip ?? null}
            dropdownStyle={{ width: '100%', height: 40 }}
            onChange={({ target: { name, value } }) => setFormData((prev) => ({ ...prev, [name]: value }))}
          />
        </InputWithLabel>
        <InputWithLabel label="guest-best-attention">
          <Dropdown
            name="bestAttention"
            items={getTags('bestAttention')}
            value={formData?.bestAttention ?? null}
            dropdownStyle={{ width: '100%', height: 40 }}
            onChange={({ target: { name, value } }) => setFormData((prev) => ({ ...prev, [name]: value }))}
          />
        </InputWithLabel>
        <InputWithLabel label="favorite-experiences-activities">
          <MultiSelect
            className={classes.dropdown}
            loading={loading}
            loadingText={<CircularProgress />}
            options={getTags('profileInfo.favoriteExperiences')}
            chipParams={chipParams}
            value={formData?.favoriteExperiences ?? []}
            getOptionSelected={(option, { value }) => option.value === value}
            onChange={(e, value) => setFormData((prev) => ({ ...prev, favoriteExperiences: value }))}
          />
        </InputWithLabel>
      </RowContainer>
      <RowContainer>
        <InputWithLabel label="favorite-local-restaurants">
          <MultiSelect
            className={classes.dropdown}
            loading={loading}
            loadingText={<CircularProgress />}
            options={getTags('fbProfilePreferences.favoriteInternalRestaurants')}
            chipParams={chipParams}
            value={formData?.favoriteInternalRestaurants ?? []}
            getOptionSelected={(option, { value }) => option.value === value}
            onChange={(e, value) => setFormData((prev) => ({ ...prev, favoriteInternalRestaurants: value }))}
          />
        </InputWithLabel>
        <InputWithLabel label="favorite-external-restaurants">
          <TextareaAutosize
            name="favoriteExternalRestaurants"
            color="primary"
            value={formData?.favoriteExternalRestaurants ?? []}
            style={{
              fontFamily: 'roboto',
              width: '100%',
              minHeight: 40,
              padding: 10,
              resize: 'vertical',
              border: `1px solid ${primaryColor}`,
              borderRadius: 4
            }}
            onChange={({ target: { name, value } }) => setFormData((prev) => ({ ...prev, [name]: value }))}
          />
        </InputWithLabel>
      </RowContainer>
      {data?.checkins?.length > 0 &&
        data.checkins
          .sort((a, b) => (a.checkinDate > b.checkinDate ? 1 : -1))
          .map((checkin, i) => (
            <RowContainer key={checkin.uuid}>
              <Accordion
                className={accordionClasses.expansionContainer}
                expanded={expanded[i] ?? false}
                onChange={() => setExpanded((prev) => ({ ...prev, [i]: !prev[i] }))}
              >
                <AccordionSummary
                  className={accordionClasses.expansionSummaryContainer}
                  expandIcon={
                    <Icon color="primary" style={{ width: 50, height: 50 }}>
                      <ExpandMore style={{ width: 50, height: 50 }} />
                    </Icon>
                  }
                >
                  <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
                    <StayTitle>
                      {`Stay ${moment(checkin?.checkinDate).format('DD/MM/YYYY')} - ${moment(checkin?.checkoutDate).format('DD/MM/YYYY')}`}
                    </StayTitle>
                    <StayTitle>
                      {capitalizeFirstLetter(translate('room', language))} {checkin?.room?.number}
                    </StayTitle>
                  </div>
                </AccordionSummary>
                <AccordionDetails className={accordionClasses.expansionDetailsContainer} style={{ flexFlow: 'column' }}>
                  <RowContainer>
                    <InputWithLabel label="travel-purpose">
                      <Dropdown
                        name="reasonOfTrip"
                        items={getTags('checkinPreferences.reasonOfTrip')}
                        value={formData?.checkins?.[i]?.reasonOfTrip}
                        dropdownStyle={{ width: '100%', height: 40 }}
                        onChange={({ target: { name, value } }) => updateCheckinValue({ i, name, value })}
                      />
                    </InputWithLabel>
                    <InputWithLabel label="arrangement">
                      <Dropdown
                        name="arrangement"
                        dropdownStyle={{ height: 40, width: '100%' }}
                        items={getTags('arrangement').map((a) => ({ label: a.label.toUpperCase(), value: a.value }))}
                        value={formData?.checkins?.[i]?.arrangement}
                        onChange={({ target: { name, value } }) => updateCheckinValue({ i, name, value })}
                      />
                    </InputWithLabel>
                  </RowContainer>
                  <RowContainer>
                    <InputWithLabel label="check-in-mood">
                      <MultiSelect
                        className={classes.dropdown}
                        loading={loading}
                        loadingText={<CircularProgress />}
                        options={getMoods.data?.getMoods?.data?.map((mood) => ({
                          label: capitalizeFirstLetter(translate(mood.name, language)),
                          value: mood.id
                        }))}
                        chipParams={chipParams}
                        value={formData?.checkins?.[i]?.checkinMoodIDs ?? []}
                        getOptionSelected={(option, { value }) => option.value === value}
                        onChange={(e, value) => updateCheckinValue({ i, name: 'checkinMoodIDs', value })}
                      />
                    </InputWithLabel>
                    <InputWithLabel label="check-out-mood">
                      <MultiSelect
                        className={classes.dropdown}
                        loading={loading}
                        loadingText={<CircularProgress />}
                        options={getMoods.data?.getMoods?.data?.map((mood) => ({
                          label: capitalizeFirstLetter(translate(mood.name, language)),
                          value: mood.id
                        }))}
                        chipParams={chipParams}
                        value={formData?.checkins?.[i]?.checkoutMoodIDs ?? []}
                        getOptionSelected={(option, { value }) => option.value === value}
                        onChange={(e, value) => updateCheckinValue({ i, name: 'checkoutMoodIDs', value })}
                      />
                    </InputWithLabel>
                  </RowContainer>
                  <RowContainer>
                    <InputWithLabel label="requested-provided-services">
                      <MultiSelect
                        className={classes.dropdown}
                        freeSolo
                        loading={loading}
                        loadingText={<CircularProgress />}
                        options={getTags('checkinPreferences.requestedServices')}
                        chipParams={chipParams}
                        value={formData?.checkins?.[i]?.requestedServices ?? []}
                        getOptionSelected={(option, { value }) => option.value === value}
                        onChange={(e, value) => updateCheckinValue({ i, name: 'requestedServices', value })}
                      />
                    </InputWithLabel>
                    <InputWithLabel label="transport">
                      <Dropdown
                        name="transportationInfo"
                        items={getTags('checkinPreferences.transportationInfo')}
                        value={formData?.checkins?.[i]?.transportationInfo}
                        dropdownStyle={{ width: '100%', height: 40 }}
                        onChange={({ target: { name, value } }) => updateCheckinValue({ i, name, value })}
                      />
                    </InputWithLabel>
                  </RowContainer>
                  <RowContainer>
                    <InputWithLabel label="local-adviser">
                      <Dropdown
                        name="reservedLocalAdviser"
                        items={getTags('checkinPreferences.reservedLocalAdviser')}
                        value={formData?.checkins?.[i]?.reservedLocalAdviser}
                        dropdownStyle={{ width: '100%', height: 40 }}
                        onChange={({ target: { name, value } }) => updateCheckinValue({ i, name, value })}
                      />
                    </InputWithLabel>
                    <InputWithLabel label="assign-local-adviser-max-3">
                      <MultiSelect
                        className={classes.dropdown}
                        freeSolo={tags?.find((tag) => tag.fieldName === 'checkinPreferences.localAdvisers')?.isFreeTagAllowed}
                        loading={loading}
                        loadingText={<CircularProgress />}
                        options={getTags('checkinPreferences.localAdvisers')}
                        chipParams={chipParams}
                        value={formData?.checkins?.[i]?.localAdvisers ?? []}
                        getOptionSelected={(option, { value }) => option.value === value}
                        onChange={(e, value) => {
                          if (value.length <= 3) {
                            const newValues = [];
                            value.forEach((v) => {
                              if (typeof v === 'string') {
                                newValues.push({ label: v, value: v });
                              } else newValues.push(v);
                            });
                            updateCheckinValue({ i, name: 'localAdvisers', value: newValues });
                          }
                        }}
                      />
                    </InputWithLabel>
                  </RowContainer>
                  <RowContainer>
                    <InputWithLabel label="complaints">
                      <MultiSelect
                        className={classes.dropdown}
                        loading={loading}
                        loadingText={<CircularProgress />}
                        options={getTags('checkinPreferences.complaints')}
                        chipParams={chipParams}
                        value={formData?.checkins?.[i]?.complaints ?? []}
                        getOptionSelected={(option, { value }) => option.value === value}
                        onChange={(e, value) => updateCheckinValue({ i, name: 'complaints', value })}
                      />
                    </InputWithLabel>
                    <InputWithLabel label="end-of-stay-response-feedback">
                      <Dropdown
                        name="checkoutFeedback"
                        items={getTags('checkinPreferences.checkoutFeedback')}
                        value={formData?.checkins?.[i]?.checkoutFeedback}
                        dropdownStyle={{ width: '100%', height: 40 }}
                        onChange={({ target: { name, value } }) => updateCheckinValue({ i, name, value })}
                      />
                    </InputWithLabel>
                  </RowContainer>
                  <RowContainer>
                    <InputWithLabel label="housekeeper">
                      <TextareaAutosize
                        name="housewife"
                        color="primary"
                        value={formData?.checkins?.[i]?.housewife ?? ''}
                        style={{
                          fontFamily: 'roboto',
                          width: '100%',
                          minHeight: 110,
                          padding: 10,
                          resize: 'vertical',
                          border: `1px solid ${primaryColor}`,
                          borderRadius: 4
                        }}
                        onChange={({ target: { name, value } }) => updateCheckinValue({ i, name, value })}
                      />
                    </InputWithLabel>
                  </RowContainer>
                  <RowContainer>
                    <InputWithLabel label="notes">
                      <TextareaAutosize
                        name="checkinNotes"
                        color="primary"
                        value={formData?.checkins?.[i]?.checkinNotes ?? ''}
                        style={{
                          fontFamily: 'roboto',
                          width: '100%',
                          minHeight: 110,
                          padding: 10,
                          resize: 'vertical',
                          border: `1px solid ${primaryColor}`,
                          borderRadius: 4
                        }}
                        onChange={({ target: { name, value } }) => updateCheckinValue({ i, name, value })}
                      />
                    </InputWithLabel>
                  </RowContainer>
                </AccordionDetails>
              </Accordion>
            </RowContainer>
          ))}
      <Buttons
        buttons={[
          {
            buttonType: 'button',
            type: 'cancel',
            label: 'delete',
            style: {
              padding: 0,
              marginLeft: 80,
              backgroundColor: 'transparent',
              border: 'none',
              color: redButton,
              textDecoration: 'underline',
              minHeight: 50,
              fontSize: 20,
              fontWeight: 'medium',
              textTransform: 'uppercase'
            },
            onClick: onReset
          },
          {
            buttonType: 'button',
            type: 'confirm',
            label: 'confirm',
            style: { minHeight: 50, minWidth: 227, fontSize: 20, fontWeight: 'medium', textTransform: 'uppercase' },
            onClick: onSubmit
          }
        ]}
      />
    </Container>
  );
};

export default ExperientialForm;
