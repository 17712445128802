import React, { useState } from 'react';
import styled from 'styled-components/macro';
import orderBy from 'lodash/orderBy';
import { ReactComponent as KnifeAndForkGrayIcon } from '../../../assets/images/knifeAndForkGray.svg';
import { ReactComponent as PlayIcon } from '../../../assets/images/play-icon.svg';
import { mainTextColor } from '../../../styles/theme';
import { TranslateTextComponent } from '../../../translator';
import { ReactComponent as AdultIcon } from '../../../assets/images/adult.svg';
import { ReactComponent as KidIcon } from '../../../assets/images/kid.svg';
import { ReactComponent as EnfantIcon } from '../../../assets/images/enfant.svg';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  min-width: 230px;
  min-height: 227px;
  margin: 10px 0;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-bottom: ${({ color }) => `6px solid ${color}`};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 18px;
  font-size: 18px;
  color: ${() => mainTextColor};
  font-weight: medium;
  text-transform: capitalize;
`;

const Divider = styled.div`
  height: 100%;
  width: 3px;
  border-radius: 3px;
  background-color: ${({ color }) => color};
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  height: 100%;
  padding: 11px 0px;
  margin: 0 12px;
  border-top: ${({ color }) => `2px solid ${color}`};
  border-radius: 2px;
`;

const ColumnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  height: 100%;
  padding: 0px 40px;

  div:not(:first-child):not(:last-child) {
    margin-bottom: 6px;
  }
`;

const Title = styled.div`
  display: flex;
  margin-bottom: 10px;
  color: ${() => mainTextColor};
  font-size: 16px;
  text-transform: capitalize;

  span {
    margin-right: 5px;
  }

  span:last-child {
    font-weight: bold;
  }
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  opacity: ${({ grayed }) => (grayed ? 0.5 : 1)};

  svg {
    width: 13px;
    height: 13px;
    margin-right: 5px;
  }

  span {
    color: ${() => mainTextColor};
    font-size: 15px;
    text-transform: capitalize;
  }

  span:first-of-type {
    font-weight: 300;
    margin-right: 5px;
  }

  span:first-of-type:after {
    content: ':';
  }
`;

const getPhysicalCategoryPax = ({ physicalCategories, categoryName }) => {
  return physicalCategories?.find(({ profilePhysicalCategory }) => profilePhysicalCategory?.name === categoryName)?.totalPax ?? 0;
};

const OutletCard = ({ outlet, fbServices, onOutletClick }) => {
  const [colors, setColors] = useState({});

  const getRandomColor = (outletUUID) => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    setColors((prev) => ({ ...prev, [outletUUID]: color }));
  };

  return (
    <Container color={colors[outlet?.uuid] ?? getRandomColor(outlet?.uuid)}>
      <Header>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <KnifeAndForkGrayIcon fill="red" style={{ marginRight: 12 }} />
          {outlet.name}
        </div>
        <PlayIcon style={{ cursor: 'pointer' }} onClick={() => onOutletClick(outlet.uuid)} />
      </Header>
      <RowContainer color={colors[outlet?.uuid] ?? getRandomColor(outlet?.uuid)} justifyContent={fbServices?.length > 1 ? 'space-between' : 'center'}>
        {orderBy(fbServices, ['fbService.startTime'], ['ASC'])?.map((fbService, index) => (
          <React.Fragment key={index}>
            <ColumnContainer>
              <Title>
                <span>{fbService?.fbService?.name}</span>
                <span>({fbService?.totalPax})</span>
              </Title>
              <Text>
                <AdultIcon />
                <TranslateTextComponent>adults</TranslateTextComponent>
                <span>{getPhysicalCategoryPax({ physicalCategories: fbService?.profilePhysicalCategories, categoryName: 'adults' })}</span>
              </Text>
              <Text>
                <KidIcon />
                <TranslateTextComponent>kids</TranslateTextComponent>
                <span>{getPhysicalCategoryPax({ physicalCategories: fbService?.profilePhysicalCategories, categoryName: 'kids' })}</span>
              </Text>
              <Text grayed>
                <EnfantIcon />
                <TranslateTextComponent>enfants</TranslateTextComponent>
                <span>{getPhysicalCategoryPax({ physicalCategories: fbService?.profilePhysicalCategories, categoryName: 'enfants' })}</span>
              </Text>
            </ColumnContainer>
            {index < fbServices.length - 1 ? <Divider color={colors[outlet?.uuid] ?? getRandomColor(outlet?.uuid)} /> : null}
          </React.Fragment>
        ))}
      </RowContainer>
    </Container>
  );
};

export default OutletCard;
