import React, { useState, useContext } from 'react';
import typy from 'typy';
import * as _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Box, Grid, Typography } from '@material-ui/core';

import { AuthContext } from '../../../contexts/AuthContext';
import { ModalContext } from '../../../contexts/ModalContext';
import { getApolloErrors } from '../../../apollo/ApolloProvider';
import { TranslateTextComponent } from '../../../translator';
import { GET_FB_ORDER } from '../../../graphql/order/queries';
import { GET_ORDERS } from '../../../graphql/moh/orders/queries';

import { SET_FB_COURSE_STATUS } from '../../../graphql/order/mutations';
import FbCourseStatusIcon from './FbCourseStatusIcon';
import DetailedSummaryOrder from './DetailedSummaryOrder';
import styles from '../../../styles/kitchen/fbCourse';
import leftSidebarLayoutStyles from '../../../styles/leftSidebarLayout/leftSidebarLayoutStyle';
import ORDER_TYPE from '../../../constants/moh/orderType';
import { MOH_ORDERS_STATUSES } from '../../../constants/moh/fbOrdersStatuses';
import { ROUTE_MOH_KITCHEN, ROUTE_KITCHEN_DETAILED_ORDER } from '../../../router/routes';
import { CustomButton, mainTextColor } from '../../../common-fe/src';

const DetailedOrder = ({ fbOrderUUID }) => {
  const classes = styles();
  const layoutClasses = leftSidebarLayoutStyles();

  const [changingFbCourseStatus, setChangingFbCourseStatus] = useState(null);
  const [setFbCourseStatus] = useMutation(SET_FB_COURSE_STATUS);

  const modalContext = useContext(ModalContext);
  const { appOutletUUID } = useContext(AuthContext);

  const getFbOrdersRes = useQuery(GET_ORDERS, {
    variables: {
      fbOutletUUID: appOutletUUID,
      date: moment(new Date()).format('YYYY-MM-DD'),
      status: MOH_ORDERS_STATUSES.open,
      type: [ORDER_TYPE.DELIVERY, ORDER_TYPE.FB, ORDER_TYPE.TAKE_AWAY]
    },
    onError: (err) => {
      modalContext.openModal({
        class: 'danger',
        title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
        text: getApolloErrors(err).join(' - ')
      });
    },
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const fbOrders = getFbOrdersRes?.data?.getOrders?.data ?? [];

  const filteredOrders = fbOrders
    .filter((fbOrder) => fbOrder.fbCourses.length > 0)
    .filter((fbOrder) => fbOrder.fbCourses.some((fbC) => fbC.type === 'food') > 0);

  const getFbOrderRes = useQuery(GET_FB_ORDER, {
    variables: { fbOrderUUID },
    onError: (err) => {
      modalContext.openModal({
        class: 'danger',
        title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
        text: getApolloErrors(err).join(' - ')
      });
    },
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const tableFbOrders = typy(getFbOrderRes, 'data.getFbOrder').safeObject;

  const orderedFoodCourses = (fbOrder) => {
    const filteredFoodCourses = fbOrder.fbCourses.filter(
      (fbCourse) => fbCourse.type === 'food' && fbCourse?.fbCoursesMenuItems?.map((item) => item.fbMenuItemId !== null)
    );

    const orderedCourses = _.orderBy(filteredFoodCourses, ['number'], ['asc']);
    return orderedCourses;
  };

  const handleSetChangingFbCourseStatus = (courseKey) => {
    setChangingFbCourseStatus(courseKey === changingFbCourseStatus ? null : courseKey);
  };

  const onSetFbCourseStatus = (fbCourse, fbCourseStatus) => {
    setFbCourseStatus({
      variables: {
        fbCourseUUID: fbCourse.uuid,
        fbCourseStatus
      }
    })
      .then(() => {
        getFbOrdersRes.refetch();
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: 'Error',
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  return (
    <Box className={layoutClasses.layoutContainer}>
      <Box className={layoutClasses.leftSide}>
        <Grid className={classes.detailedTableContainer} style={{ height: '70vh' }}>
          {filteredOrders.map((fbOrder, index) => (
            <Grid key={index} item className={classes.courseTableContainer}>
              <Link to={`${ROUTE_KITCHEN_DETAILED_ORDER}/${fbOrder.uuid}`}>
                <Box className={classes.detailedHeaderContainer}>
                  {fbOrder?.type !== ORDER_TYPE.FB ? (
                    <>
                      <Typography className={classes.detailedHeaderInfo}>
                        <TranslateTextComponent capitalize>type</TranslateTextComponent>:{' '}
                        <span>{(fbOrder?.type !== ORDER_TYPE.TAKE_AWAY ? fbOrder?.type : 'TAKE AWAY') || '-'}</span>
                      </Typography>
                      <Typography className={classes.detailedHeaderInfo}>
                        <TranslateTextComponent capitalize>time</TranslateTextComponent>: <span> {fbOrder.time}</span>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography className={classes.detailedHeaderInfo}>
                        <TranslateTextComponent uppercase>table</TranslateTextComponent>:{' '}
                        {typy(fbOrder, 'fbReservationTable.fbTable.number').safeString || '-'}/
                        {typy(fbOrder, 'fbReservationTable.fbReservation.bookedBy.lastname').safeString}
                      </Typography>
                      <Typography className={classes.detailedHeaderInfo}>
                        <TranslateTextComponent uppercase>pax</TranslateTextComponent>:{' '}
                        {typy(fbOrder, 'fbReservationTable.fbReservation.nPax').safeNumber || '-'}
                      </Typography>
                      <Typography className={classes.detailedHeaderInfo}>
                        <TranslateTextComponent uppercase>time</TranslateTextComponent>:{' '}
                        {fbOrder?.fbReservationTable?.fbReservation?.reservationTime ?? '-'}
                      </Typography>
                    </>
                  )}
                </Box>
              </Link>

              <Box className={classes.smallCourseImageContainer}>
                {orderedFoodCourses(fbOrder).map((fbCourse, i) => {
                  const courseKey = `${typy(fbOrder, 'fbReservationTable.fbTable.id').safeNumber}-${fbCourse.number}`;
                  return (
                    <Box key={i} style={{ display: 'inline-block' }}>
                      <Grid container>
                        <Typography style={{ fontWeight: 'bold', color: mainTextColor }}>{fbCourse.number}</Typography>
                        <FbCourseStatusIcon
                          hideStatusBox
                          iconSize={{ width: 37, height: 30, marginRight: 15 }}
                          fbCourse={fbCourse}
                          showStatuses={courseKey === changingFbCourseStatus}
                          onClickIcon={() => {}}
                        />
                      </Grid>
                    </Box>
                  );
                })}
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid container className={classes.showOrderButton}>
          <Link to={`${ROUTE_MOH_KITCHEN}`}>
            <CustomButton style={{ fontSize: 19 }}>
              <TranslateTextComponent>overview</TranslateTextComponent>
            </CustomButton>
          </Link>
        </Grid>
      </Box>
      <Grid className={layoutClasses.rightSide}>
        <DetailedSummaryOrder
          tableFbOrders={tableFbOrders}
          changingFbCourseStatus={changingFbCourseStatus}
          handleSetChangingFbCourseStatus={handleSetChangingFbCourseStatus}
          onSetFbCourseStatus={(fbCourse, status) => onSetFbCourseStatus(fbCourse, status)}
        />
      </Grid>
    </Box>
  );
};

export default DetailedOrder;
