import React, { useState } from 'react';
import Spinner from 'react-loader-spinner';
import { Switch, Button, Box, TablePagination } from '@material-ui/core';
import moment from 'moment';
import Wrapper from '../shared/Wrapper';
import LeftContainer from '../shared/LeftContainer';
import MainContainer from '../shared/MainContainer';
import HeaderIncome from '../shared/HeaderIncome/HeaderIncome';
import WideCardContainer from '../shared/WideCard/WideCardContainer';
import SummaryWithoutBorder from '../shared/WideCard/Summaries/SummaryWithoutBorder';
import BillsListDetails from '../shared/WideCard/Details/BillsListDetails';
import Input from '../shared/Input';
import { DataPicker } from '../../../common-fe/src';
import styles from '../../../styles/billsManagement/input';
import buttonsStyles from '../../../styles/billsManagement/buttons';
import { TranslateTextComponent } from '../../../translator';
import { BANK_CHECK_CREDIT_TRANSFERS } from '../../../constants/billsManagement/tabs';

const BankCheck = ({
  isLoading,
  data,
  totalCount,
  totalAmount,
  page,
  pageSize,
  filterDate,
  onDateChange,
  onFilterChange,
  onConfirmFbBillPayment,
  onPageSizeChange,
  onPageChange
}) => {
  const [date, setDate] = useState(null);
  const classes = styles();
  const classesButtons = buttonsStyles();

  return (
    <Wrapper>
      <LeftContainer>
        <Box>
          <Input title="search" placeholder="search" onChange={(e) => onFilterChange('client', e.target.value)} />
          <Input
            title="date-filter"
            component={
              <DataPicker
                dataPickerStyle={{ overflow: 'hidden' }}
                value={date}
                onChange={(e) => {
                  if (moment(e).isValid()) {
                    setDate(e);
                    onFilterChange('date', e);
                  }
                }}
              />
            }
          />
          <Input
            title="search-document-number-shortened"
            customIcon="Receipt"
            placeholder="placeholder-search-check-ticket"
            onChange={(e) => onFilterChange('document', e.target.value)}
          />
          {/* <Input
            title="display"
            type="radio"
            component={
              <RadioGroup defaultValue="all" onChange={(e) => onCheckBoxFilterChange({ type: 'code', paymentMethodCode: e.target.value })}>
                <FormControlLabel
                  value="all"
                  control={<Radio className={classes.radio} size="small" />}
                  label={
                    <TranslateTextComponent className={classes.radioText} style={{ fontSize: 'red' }} capitalize>
                      all
                    </TranslateTextComponent>
                  }
                />
                {paymentMethods.map((paymentMethod) => (
                  <FormControlLabel
                    key={paymentMethod.uuid}
                    value={paymentMethod.uuid}
                    control={<Radio className={classes.radio} size="small" />}
                    label={
                      <TranslateTextComponent className={classes.radioText} capitalize>
                        {getPaymentTranslation(paymentMethod.code)}
                      </TranslateTextComponent>
                    }
                  />
                ))}
              </RadioGroup>
            }
          /> */}
          <Input
            title="display-paid-invoices"
            component={
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <TranslateTextComponent capitalize>yes</TranslateTextComponent>
                <Switch className={classes.switch} color="primary" onChange={() => onFilterChange('status')} />
                <TranslateTextComponent capitalize>no</TranslateTextComponent>
              </Box>
            }
          />
        </Box>
        <Box className={classesButtons.container}>
          <Button className={classesButtons.button} color="primary" variant="contained">
            <TranslateTextComponent>report</TranslateTextComponent>
          </Button>
        </Box>
      </LeftContainer>
      <MainContainer>
        <HeaderIncome monthly date={filterDate} totalAmount={totalAmount} onDateChange={onDateChange} />
        <Box style={{ overflow: 'auto' }}>
          {isLoading ? (
            <Box style={{ display: 'flex', justifyContent: 'center', border: 'none', margin: 'auto', paddingTop: 50 }}>
              <Spinner type="TailSpin" color="#00BFFF" height={100} width={100} />
            </Box>
          ) : (
            data.map((d, index) => (
              <WideCardContainer
                key={index}
                summaryComponent={<SummaryWithoutBorder data={d} summaryType={BANK_CHECK_CREDIT_TRANSFERS} />}
                detailsComponent={<BillsListDetails fbBill={d} withPaymentStatus onConfirmFbBillPayment={onConfirmFbBillPayment} />}
              />
            ))
          )}
        </Box>
        <TablePagination
          style={{ display: 'flex', justifyContent: 'center' }}
          component="div"
          count={totalCount}
          rowsPerPageOptions={[5, 10, 25]}
          rowsPerPage={pageSize}
          page={page - 1}
          onChangePage={(e, page) => onPageChange(page)}
          onChangeRowsPerPage={(e) => onPageSizeChange(e.target.value)}
        />
      </MainContainer>
    </Wrapper>
  );
};

export default BankCheck;
