import React, { useState, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import styles from '../../styles/shared/verticalTab';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

export default function VerticalTabs({ history, buttons }) {
  const classes = styles();
  const [value, setValue] = useState(0);

  useEffect(() => {
    const getRouteIndex = (path) => buttons.findIndex((btn) => path.substring(0, btn.route.length) === btn.route);
    setValue(getRouteIndex(history.location.pathname));
  }, [history]);

  const renderTab = () => {
    return buttons.map((button, index) => (
      <Tab
        key={`verticalTab${index}`}
        className="sidebar"
        style={{ color: 'white' }}
        label={button.text}
        icon={button.img}
        {...a11yProps(index)}
        onClick={history ? () => history.push(button.route) : null}
      />
    ));
  };
  return (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      scrollButtons="off"
      value={value < 0 ? false : value}
      aria-label="Vertical tabs example"
      className={classes.tabs}
      classes={{
        indicator: value < 0 ? null : classes.indicator
      }}
    >
      {renderTab()}
    </Tabs>
  );
}

VerticalTabs.propTypes = {
  buttons: PropTypes.instanceOf(Array)
};
VerticalTabs.defaultProps = {
  buttons: []
};
