import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Button as MUIButton } from '@material-ui/core';
import { TranslateTextComponent } from '../../translator';
import { primaryColor } from '../../styles/theme';

const Button = styled(MUIButton).attrs({ variant: 'contained', color: 'primary' })`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${() => primaryColor};
  border-radius: 4px;
  padding: 8px 5px;
  font-family: 'Roboto';
  font-weight: 500;
  color: white;
  text-transform: uppercase;
`;

const TableButton = ({ children, disabled, style, onClick }) => {
  return (
    <Button style={style} disabled={disabled} onClick={onClick}>
      <TranslateTextComponent>{children}</TranslateTextComponent>
    </Button>
  );
};

TableButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

TableButton.defaultProps = {
  disabled: false
};

export default TableButton;
