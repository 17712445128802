import React, { useState, useContext } from 'react';
import * as moment from 'moment';
import { RadioGroup, Radio, FormControlLabel, Box, Button, TablePagination } from '@material-ui/core';
import Spinner from 'react-loader-spinner';

import { ModalContext } from '../../../contexts/ModalContext';
import Wrapper from '../shared/Wrapper';
import LeftContainer from '../shared/LeftContainer';
import MainContainer from '../shared/MainContainer';
import Input from '../shared/Input';
import { TranslateTextComponent } from '../../../translator';
import HeaderIncome from '../shared/HeaderIncome/HeaderIncome';
import WideCardContainer from '../shared/WideCard/WideCardContainer';
import SummaryWithoutBorder from '../shared/WideCard/Summaries/SummaryWithoutBorder';
import BillsListDetails from '../shared/WideCard/Details/BillsListDetails';
import { getPaymentTranslation } from '../../../utils/billsManagement';
import { BILLS_LIST } from '../../../constants/billsManagement/tabs';

import modalStyle from '../../../styles/shared/modalStyle';
import { DataPicker } from '../../../common-fe/src';
import styles from '../../../styles/billsManagement/input';
import buttonsStyles from '../../../styles/billsManagement/buttons';

const BillsList = ({
  isLoading,
  data,
  totalCount,
  totalAmount,
  page,
  pageSize,
  filterDate,
  paymentMethods,
  onDateChange,
  onFilterChange,
  onCheckBoxFilterChange,
  onPageSizeChange,
  onPageChange,
  onConfirmCancelBill,
  onRegisterFbDailyClosure
}) => {
  const [selectedBill, setSelectedBill] = useState(null);
  const [date, setDate] = useState(null);
  const classes = styles();
  const classesButtons = buttonsStyles();

  const modalContext = useContext(ModalContext);
  const modalClasses = modalStyle();

  const onSelectBill = (bill) => {
    setSelectedBill(selectedBill === bill ? null : bill);
  };

  const onCancelBill = () => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>confirm</TranslateTextComponent>,
      text: (
        <TranslateTextComponent
          vars={{
            date: moment(selectedBill.paymentDatetime).format('DD-MM-YYYY'),
            documentNumber: selectedBill.documentNumber,
            total: selectedBill.total
          }}
        >
          cancel-bill-description
        </TranslateTextComponent>
      ),
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            setSelectedBill(null);
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          variant="contained"
          color="primary"
          className={modalClasses.buttonStyle}
          onClick={() => {
            onConfirmCancelBill(selectedBill);
            setSelectedBill(null);
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>ok</TranslateTextComponent>
        </Button>
      ]
    });
  };

  return (
    <Wrapper>
      <LeftContainer>
        <Box>
          <Input title="search" placeholder="search" onChange={(e) => onFilterChange('client', e.target.value)} />
          <Input
            title="date-filter"
            component={
              <DataPicker
                dataPickerStyle={{ overflow: 'hidden' }}
                value={date}
                onChange={(e) => {
                  if (moment(e).isValid()) {
                    setDate(e);
                    onFilterChange('date', e);
                  }
                }}
              />
            }
          />
          <Input
            title="search-document-number-shortened"
            customIcon="Receipt"
            placeholder="placeholder-search-check-ticket"
            onChange={(e) => onFilterChange('document', e.target.value)}
          />
          <Input
            title="search-operator"
            customIcon="Person"
            placeholder="search-operator"
            onChange={(e) => onFilterChange('operator', e.target.value)}
          />
          <Input
            title="display"
            type="radio"
            component={
              <RadioGroup
                style={{ maxHeight: 200 }}
                defaultValue="all"
                onChange={(e) => onCheckBoxFilterChange({ type: 'code', paymentMethodCode: e.target.value })}
              >
                <FormControlLabel
                  value="all"
                  control={<Radio className={classes.radio} size="small" />}
                  label={
                    <TranslateTextComponent className={classes.radioText} capitalize>
                      all
                    </TranslateTextComponent>
                  }
                />
                {paymentMethods.map(
                  (paymentMethod) =>
                    paymentMethod.code !== null && (
                      <FormControlLabel
                        key={paymentMethod.code}
                        value={paymentMethod.code}
                        control={<Radio className={classes.radio} size="small" />}
                        label={
                          <TranslateTextComponent className={classes.radioText} capitalize>
                            {getPaymentTranslation(paymentMethod.code)}
                          </TranslateTextComponent>
                        }
                      />
                    )
                )}
              </RadioGroup>
            }
          />
        </Box>
        <Box className={classesButtons.container}>
          <Button
            className={classesButtons.button}
            color="primary"
            variant="contained"
            disabled={selectedBill === null || (selectedBill && selectedBill.status !== 'complete')}
            onClick={onCancelBill}
          >
            <TranslateTextComponent>cancel-bill</TranslateTextComponent>
          </Button>
          <Button className={classesButtons.button} color="primary" variant="contained" onClick={onRegisterFbDailyClosure}>
            <TranslateTextComponent>closing-case</TranslateTextComponent>
          </Button>
        </Box>
      </LeftContainer>
      <MainContainer>
        <HeaderIncome totalAmount={totalAmount} date={filterDate} onDateChange={onDateChange} />
        <Box style={{ overflow: 'auto' }}>
          {isLoading ? (
            <Box style={{ display: 'flex', justifyContent: 'center', border: 'none', margin: 'auto', paddingTop: 50 }}>
              <Spinner type="TailSpin" color="#00BFFF" height={100} width={100} />
            </Box>
          ) : (
            data.map((d, index) => (
              <WideCardContainer
                key={index}
                withCheckBox
                onClick={() => onSelectBill(null)}
                onCheckBoxChange={() => onSelectBill(d)}
                checked={(selectedBill && selectedBill.uuid === d.uuid) || false}
                summaryComponent={<SummaryWithoutBorder data={d} summaryType={BILLS_LIST} />}
                detailsComponent={<BillsListDetails fbBill={d} />}
              />
            ))
          )}
        </Box>
        <TablePagination
          style={{ display: 'flex', justifyContent: 'center' }}
          component="div"
          count={totalCount}
          rowsPerPageOptions={[5, 10, 25]}
          rowsPerPage={pageSize}
          page={page - 1}
          onChangePage={(e, page) => onPageChange(page)}
          onChangeRowsPerPage={(e) => onPageSizeChange(e.target.value)}
        />
      </MainContainer>
    </Wrapper>
  );
};

export default BillsList;
