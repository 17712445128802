import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import moment from 'moment';
import styles from '../../../../../styles/billsManagement/wideCard';
import { TranslateTextComponent, capitalizeFirstLetter, translate } from '../../../../../translator';
import { CustomButton } from '../../../../../common-fe/src';
import { LanguageContext } from '../../../../../contexts/LanguageContext';

const DailyCloseOutDetails = ({ data }) => {
  const { language } = useContext(LanguageContext);
  const classes = styles();

  return (
    <Box className={classes.dailyCloseOutDetailContainer}>
      <Box className={classes.dailyCloseOutDetailTitle}>
        <span>{data.fbOutlet && data.fbOutlet.name}</span>
        <span>{moment(data.datetime).format('DD/MM/YYYY')}</span>
      </Box>
      <Box className={classes.dailyCloseOutDetailRowsContainer}>
        <Box className={classes.dailyCloseOutDetailBoxVerticalLeft}>
          <Box>
            <TranslateTextComponent className={classes.dailyCloseOutDetailLabelColor} capitalize>
              operator
            </TranslateTextComponent>
            : {data.createdBy && `${data.createdBy.firstName} ${data.createdBy.lastName}`}
          </Box>
          <Box>
            <TranslateTextComponent className={classes.dailyCloseOutDetailLabelColor} capitalize>
              covers
            </TranslateTextComponent>
            : {data.totalPaxAmount}
          </Box>
          <Box>
            {capitalizeFirstLetter(translate('opened-pm', language)) + ' (): '}
            <span className={classes.dailyCloseOutDetailLabelRed}>ND</span>
          </Box>
        </Box>
        <Box className={classes.dailyCloseOutDetailBoxVerticalRight}>
          <Box style={{ textAlign: 'right' }}>{moment(data.datetime).format('HH:mm')}</Box>
          <Box>
            {capitalizeFirstLetter(translate('closed-pm', language)) + ' (8): '}
            <span className={classes.dailyCloseOutDetailLabelGreen}>20.212121</span>
          </Box>
        </Box>
      </Box>
      <hr />
      <Box className={classes.dailyCloseOutDetailRowsContainer}>
        <Box className={classes.dailyCloseOutDetailBoxVerticalLeft}>
          <TranslateTextComponent className={[classes.dailyCloseOutDetailLabelColor, classes.dailyCloseOutDetailBold].join(' ')} capitalize>
            total-income
          </TranslateTextComponent>
          <Box style={{ justifyContent: 'space-between', display: 'flex' }}>
            <TranslateTextComponent className={classes.dailyCloseOutDetailLabelColor} capitalize>
              total-net-shortened
            </TranslateTextComponent>
            <span>€ {data && data.totalAmount.toFixed(2)}</span>
          </Box>
          <Box style={{ justifyContent: 'space-between', display: 'flex' }}>
            <TranslateTextComponent className={classes.dailyCloseOutDetailLabelColor} capitalize>
              collected
            </TranslateTextComponent>
            <span>€ {data && data.totalTicketAmount.toFixed(2)}</span>
          </Box>
          <Box style={{ justifyContent: 'space-between', display: 'flex' }}>
            <TranslateTextComponent className={classes.dailyCloseOutDetailLabelColor} capitalize>
              not-collected
            </TranslateTextComponent>
            <span>€ {((data && data.totalAmount) - (data && data.totalTicketAmount)).toFixed(2)}</span>
          </Box>
          <TranslateTextComponent className={[classes.dailyCloseOutDetailLabelColor, classes.dailyCloseOutDetailBold].join(' ')} capitalize>
            total-discounts-shortened
          </TranslateTextComponent>
          <Box style={{ justifyContent: 'space-between', display: 'flex' }}>
            <TranslateTextComponent className={classes.dailyCloseOutDetailLabelColor} capitalize>
              discount-on-total
            </TranslateTextComponent>
            <span>€ {data && data.totalDiscountAmount.toFixed(2)}</span>
          </Box>
        </Box>
      </Box>
      <hr />
      <Box className={classes.dailyCloseOutDetailRowsContainer}>
        <Box className={classes.dailyCloseOutDetailBoxVerticalLeft}>
          <TranslateTextComponent className={[classes.dailyCloseOutDetailLabelColor, classes.dailyCloseOutDetailBold].join(' ')} capitalize>
            payment-mode-detail
          </TranslateTextComponent>
          <Box style={{ justifyContent: 'space-between', display: 'flex' }}>
            <TranslateTextComponent className={classes.dailyCloseOutDetailLabelColor} capitalize>
              payment-cash
            </TranslateTextComponent>
            <span>
              € {data && data.totalCashAmount.toFixed(2)} ({data && data.totalCashNumber})
            </span>
          </Box>
          <Box style={{ justifyContent: 'space-between', display: 'flex' }}>
            <TranslateTextComponent className={classes.dailyCloseOutDetailLabelColor} capitalize>
              bank-check
            </TranslateTextComponent>
            <span>
              € {data && data.totalBankCheckAmount.toFixed(2)} ({data && data.totalBankCheckNumber})
            </span>
          </Box>
          <Box style={{ justifyContent: 'space-between', display: 'flex' }}>
            <TranslateTextComponent className={classes.dailyCloseOutDetailLabelColor} capitalize>
              bank-transfer
            </TranslateTextComponent>
            <span>
              € {data && data.totalBankTransferAmount.toFixed(2)} ({data && data.totalBankTransferNumber})
            </span>
          </Box>
          <Box style={{ justifyContent: 'space-between', display: 'flex' }}>
            <TranslateTextComponent className={classes.dailyCloseOutDetailLabelColor} capitalize>
              pagoBancomat
            </TranslateTextComponent>
            <span>
              € {data && data.totalCardPagobancomatAmount && data.totalCardPagobancomatAmount.toFixed(2)} ({data && data.totalCardPagobancomatNumber})
            </span>
          </Box>
          <Box style={{ justifyContent: 'space-between', display: 'flex' }}>
            <TranslateTextComponent className={classes.dailyCloseOutDetailLabelColor} capitalize>
              mastercard
            </TranslateTextComponent>
            <span>
              € {data && data.totalCardMastercardAmount.toFixed(2)} ({data && data.totalCardMastercardNumber})
            </span>
          </Box>
          <Box style={{ justifyContent: 'space-between', display: 'flex' }}>
            <TranslateTextComponent className={classes.dailyCloseOutDetailLabelColor} capitalize>
              maestro
            </TranslateTextComponent>
            <span>
              € {data && data.totalCardMaestroAmount.toFixed(2)} ({data && data.totalCardMaestroNumber})
            </span>
          </Box>
          <Box style={{ justifyContent: 'space-between', display: 'flex' }}>
            <TranslateTextComponent className={classes.dailyCloseOutDetailLabelColor} capitalize>
              amex
            </TranslateTextComponent>
            <span>
              € {data && data.totalCardAmexAmount.toFixed(2)} ({data && data.totalCardAmexNumber})
            </span>
          </Box>
          <Box style={{ justifyContent: 'space-between', display: 'flex' }}>
            <TranslateTextComponent className={classes.dailyCloseOutDetailLabelColor} capitalize>
              visa
            </TranslateTextComponent>
            <span>
              € {data && data.totalCardVisaAmount.toFixed(2)} ({data && data.totalCardVisaNumber})
            </span>
          </Box>
          <TranslateTextComponent className={[classes.dailyCloseOutDetailLabelColor, classes.dailyCloseOutDetailBold].join(' ')} capitalize>
            total
          </TranslateTextComponent>
          <span className={classes.dailyCloseOutDetailLabelColor}>{translate('vat', language).toUpperCase() + ' 10%'}</span>
          <span className={classes.dailyCloseOutDetailLabelColor}>{translate('vat', language).toUpperCase() + ' 22%'}</span>
          <TranslateTextComponent className={classes.dailyCloseOutDetailLabelColor} capitalize>
            taxable
          </TranslateTextComponent>
        </Box>
      </Box>
      <hr />
      <Box className={classes.dailyCloseOutDetailRowsContainer}>
        <Box className={classes.dailyCloseOutDetailBoxVerticalLeft}>
          <TranslateTextComponent className={[classes.dailyCloseOutDetailLabelColor, classes.dailyCloseOutDetailBold].join(' ')} capitalize>
            charges
          </TranslateTextComponent>
          <Box style={{ justifyContent: 'space-between', display: 'flex' }}>
            <TranslateTextComponent className={classes.dailyCloseOutDetailLabelColor} capitalize>
              room-charge-shortened
            </TranslateTextComponent>
            <span>
              € {data && data.totalRoomChargeAmount.toFixed(2)} ({data && data.totalRoomChargeNumber})
            </span>
          </Box>
          <Box style={{ justifyContent: 'space-between', display: 'flex' }}>
            <TranslateTextComponent className={classes.dailyCloseOutDetailLabelColor} capitalize>
              PM
            </TranslateTextComponent>
            <span>
              € {data && data.totalPMAmount.toFixed(2)} ({data && data.totalPMNumber})
            </span>
          </Box>
          <Box style={{ justifyContent: 'space-between', display: 'flex' }}>
            <TranslateTextComponent className={classes.dailyCloseOutDetailLabelColor} capitalize>
              client-type
            </TranslateTextComponent>
            <span>
              € {data && data.totalCustomerTypeAmount.toFixed(2)} ({data && data.totalCustomerTypeNumber})
            </span>
          </Box>
        </Box>
      </Box>
      <hr />
      <Box className={classes.dailyCloseOutDetailRowsContainer}>
        <Box className={classes.dailyCloseOutDetailBoxVerticalLeft}>
          <TranslateTextComponent className={[classes.dailyCloseOutDetailLabelColor, classes.dailyCloseOutDetailBold].join(' ')}>
            documents
          </TranslateTextComponent>
          <Box className={classes.dailyCloseOutDetailLabelColor} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box style={{ display: 'flex' }}>
              {`${capitalizeFirstLetter(translate('ticket', language))} (${data && data.totalTicketNumber}) `}
              <Box>
                {/* <Button className={classes.dailyCloseOutDetailButton} color="primary" variant="contained">
                <TranslateTextComponent>open</TranslateTextComponent>
              </Button> */}
              </Box>
            </Box>
            <span>€ {data && data.totalTicketAmount.toFixed(2)}</span>
          </Box>
        </Box>
      </Box>
      <CustomButton style={{ marginTop: 10 }}>
        <TranslateTextComponent>print</TranslateTextComponent>
      </CustomButton>
    </Box>
  );
};

export default DailyCloseOutDetails;
