const profileCSVFields = [
  { label: 'ID' },
  { label: 'FULL_NAME' },
  { label: 'FIRST_NAME' },
  { label: 'LAST_NAME' },
  { label: 'DOB' },
  { label: 'PLACE_OF_BIRTH' },
  { label: 'GENDER' },
  { label: 'PHONE' },
  { label: 'LANGUAGE' },
  { label: 'SPOKEN_LANGUAGE' },
  { label: 'ADDRESS' },
  { label: 'ADDRESS_NUMBER' },
  { label: 'CITY' },
  { label: 'PROVINCE' },
  { label: 'POSTAL_CODE' },
  { label: 'COUNTRY_CODE' },
  { label: 'NATIONALITY_CODE' },
  { label: 'MARITAL_STATUS' },
  { label: 'EMAIL' },
  { label: 'BEST_ATTENTION' },
  { label: 'FAV_WINE' },
  { label: 'FAV_WATER' },
  { label: 'FB_PREF' },
  { label: 'FOOD_RESTR' },
  { label: 'FAV_INT_REST' },
  { label: 'FAV_EXT_REST' },
  { label: 'VIP' },
  { label: 'PARTNER' },
  { label: 'CHILDREN' },
  { label: 'CHARACTER' },
  { label: 'EMPLOYMENT' },
  { label: 'PHYSICAL_LIMIT' },
  { label: 'FAV_EXPERIENCES' }
];

export default profileCSVFields;
