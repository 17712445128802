import React from 'react';
import Bookings from '../../../components/Moh/bookings/Bookings';
import Layout from '../../../components/shared/Layout';

const MohBookingsPage = ({ history }) => {
  return (
    <Layout history={history} section="app-services">
      <Bookings />
    </Layout>
  );
};

export default MohBookingsPage;
