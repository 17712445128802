import gql from 'graphql-tag';
import { VAT_CODES_FRAGMENT, FB_MENU_CATEGORIES_FRAGMENT } from '../fragments';

export const GET_MENU_MACRO_CATEGORIES = gql`
  query {
    getFbMenuMacroCategories {
      totalCount
      data {
        id
        uuid
        name
        fbMenuCategories {
          ...fbMenuCategoriesFragment
        }
      }
    }
  }
  ${VAT_CODES_FRAGMENT}
  ${FB_MENU_CATEGORIES_FRAGMENT}
`;

export const GET_MENU_CATEGORIES = gql`
  query getFbMenuCategories($fbOutletUUID: String!, $page: Int, $pageSize: Int, $orderBy: String, $orderType: String) {
    getFbMenuCategories(fbOutletUUID: $fbOutletUUID, page: $page, pageSize: $pageSize, orderBy: $orderBy, orderType: $orderType) {
      totalCount
      data {
        ...fbMenuCategoriesFragment
      }
    }
  }
  ${FB_MENU_CATEGORIES_FRAGMENT}
`;
