import { gql } from 'apollo-boost';
import { LANGUAGE_FRAGMENT } from '../fragments';

export const GET_LANGUAGES = gql`
  query getLanguages {
    getLanguages {
      totalCount
      data {
        ...languageFragment
      }
    }
  }
  ${LANGUAGE_FRAGMENT}
`;
