import React from 'react';
import { Tab } from '@material-ui/core';
import style from '../../../../styles/viewTables/tabs/tabItem/style';

const TabItem = ({ id, label, active, changeTab, key, isActive }) => {
  const classes = style({ active: id === active });

  return <Tab className={classes.container} label={label} value={key} disabled={!isActive} onClick={() => changeTab(id)} />;
};

export default TabItem;
