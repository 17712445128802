import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Box, Button, FormControlLabel, Grid, Switch, TextField, Typography } from '@material-ui/core/';
import typy from 'typy';
import { getApolloErrors } from '../../../apollo/ApolloProvider';
import modalStyle from '../../../common-fe/src/styles/shared/modalStyle';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { ModalContext } from '../../../contexts/ModalContext';
import { EDIT_FB_OUTLET_RESERVATION_SETTINGS } from '../../../graphql/outlets/mutations';
import { GET_FB_OUTLET } from '../../../graphql/outlets/queries';
import useCustomQuery from '../../../hooks/useCustomQuery';
import styles from '../../../styles/settings/reservations';
import { capitalizeFirstLetter, translate, TranslateTextComponent } from '../../../translator';
import customObjectValidator from '../../../utils/validator';
import Footer from '../Footer';

const FORM_VALIDATION = {
  maxDaysBeforeReservation: {
    isRequired: false,
    validateFunctions: [
      {
        fn: (value) => typy(Number(value)).safeNumber > 0,
        errorMessage: 'Insert positive number'
      }
    ]
  },
  minPaxForReservation: {
    isRequired: false,
    validateFunctions: [
      {
        fn: (value, form) => Number(value) <= Number(form.maxPaxForReservation) && typy(Number(value)).safeNumber > 0,
        errorMessage: 'Error min pax for reservation'
      }
    ]
  },
  maxPaxForReservation: {
    isRequired: false,
    validateFunctions: [
      {
        fn: (value, form) => Number(value) >= Number(form.minPaxForReservation) && typy(Number(value)).safeNumber > 0,
        errorMessage: 'Error max pax for reservation'
      }
    ]
  },
  avoidEditingOrDeleteBeforeMinutes: {
    isRequired: false,
    validateFunctions: [
      {
        fn: (value) => typy(Number(value)).safeNumber > 0,
        errorMessage: 'Insert positive number'
      }
    ]
  },
  maxPax: {
    isRequired: false,
    validateFunctions: [
      {
        fn: (value) => typy(Number(value)).safeNumber > 0,
        errorMessage: 'Insert positive number'
      }
    ]
  }
};

const ReservationsSetting = () => {
  const classes = styles();
  const classesModal = modalStyle();

  const [fbOutlet, setFbOutlet] = useState({});
  const [initialSettings, setInitialSettings] = useState();
  const [errorFormMap, setErrorFormMap] = useState(new Map());
  const [errorResMap, setErrorResMap] = useState([new Map()]);
  const languageContext = useContext(LanguageContext);
  const modalContext = useContext(ModalContext);
  const { appOutletUUID } = useContext(AuthContext);

  const [editFbOutletReservationSettings] = useMutation(EDIT_FB_OUTLET_RESERVATION_SETTINGS);

  useCustomQuery(GET_FB_OUTLET, {
    variables: { fbOutletUUID: appOutletUUID },
    onCompleted: (data) => {
      if (data && data.getFbOutlet) {
        setFbOutlet({ ...data.getFbOutlet });
        setInitialSettings({ ...data.getFbOutlet });
      }
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const translateText = (text) => {
    return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
  };

  const updateReservationSettings = (key, value) => {
    setFbOutlet({ ...fbOutlet, [key]: value });
  };

  const textTranslate = (text) => {
    return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
  };

  const isValidForm = () => {
    const fbOutletToValidate = fbOutlet;
    const validation = customObjectValidator(FORM_VALIDATION, fbOutletToValidate);
    setErrorFormMap(validation.errorMap);
    return validation.isValid;
  };

  const onClickSave = () => {
    setErrorResMap([...errorResMap, new Map()]);

    if (!isValidForm()) {
      modalContext.openModal({
        class: 'danger',
        title: textTranslate('form-not-valid-title'),
        text: textTranslate('form-not-valid-text'),
        actionButtons: null
      });
    } else {
      modalContext.openModal({
        class: 'primary',
        title: textTranslate('form-valid-title'),
        text: textTranslate('form-valid-text'),
        actionButtons: [
          <Button key={1} variant="outlined" color="primary" className={classesModal.invertedButton} onClick={modalContext.closeModal}>
            {textTranslate('cancel')}
          </Button>,
          <Button
            key={2}
            color="primary"
            variant="contained"
            className={classesModal.buttonStyle}
            onClick={() => {
              modalContext.closeModal();
              fetchForm();
            }}
          >
            {textTranslate('confirm')}
          </Button>
        ]
      });
    }
  };

  const restoreData = () => {
    modalContext.openModal({
      class: 'warning',
      title: textTranslate('restore-data-title'),
      text: textTranslate('restore-data-text'),
      actionButtons: [
        <Button key={1} variant="outlined" color="primary" className={classesModal.invertedButton} onClick={modalContext.closeModal}>
          {textTranslate('cancel')}
        </Button>,
        <Button
          key={2}
          color="primary"
          variant="contained"
          className={classesModal.buttonStyle}
          onClick={() => {
            modalContext.closeModal();
            setFbOutlet({ ...initialSettings });
          }}
        >
          {textTranslate('confirm')}
        </Button>
      ]
    });
  };

  const fetchForm = () => {
    const newFbOutletSettings = {
      maxDaysBeforeReservation: Number(fbOutlet.maxDaysBeforeReservation),
      isOnlineReservationAccepted: fbOutlet.isOnlineReservationAccepted,
      minPaxForReservation: Number(fbOutlet.minPaxForReservation),
      maxPaxForReservation: Number(fbOutlet.maxPaxForReservation),
      isReservationEditableByGuest: fbOutlet.isReservationEditableByGuest,
      avoidEditingOrDeleteBeforeMinutes: Number(fbOutlet.avoidEditingOrDeleteBeforeMinutes),
      maxPax: Number(fbOutlet.maxPax)
    };

    editFbOutletReservationSettings({
      variables: {
        fbOutletUUID: appOutletUUID,
        fbReservationSettings: newFbOutletSettings
      }
    })
      .then((res) => {
        setFbOutlet({ ...res.data.editFbOutletReservationSettings });
        setInitialSettings({ ...res.data.editFbOutletReservationSettings });
        setErrorFormMap(new Map());
        setErrorResMap([new Map()]);
        modalContext.openModal({
          class: 'success',
          title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
          text: <TranslateTextComponent capitalize>success-modal-text</TranslateTextComponent>
        });
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <Box className={classes.typographyContainer}>
          <Typography className={classes.textTypography}>
            <TranslateTextComponent
              capitalize
              isHtml
              vars={{
                maxDaysBeforeReservation: fbOutlet.maxDaysBeforeReservation
                  ? `<span style="font-weight: bold; color: red">${fbOutlet.maxDaysBeforeReservation}</span>`
                  : 'N'
              }}
            >
              max-days-before-reservation
            </TranslateTextComponent>
          </Typography>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              autoComplete="off"
              label={capitalizeFirstLetter(translate('num', languageContext.language))}
              value={fbOutlet.maxDaysBeforeReservation || ''}
              onChange={(e) => updateReservationSettings('maxDaysBeforeReservation', e.target.value)}
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                maxLength: 3
              }}
              margin="dense"
              variant="outlined"
            />
            <span style={{ color: 'red', fontSize: 10 }}>
              {errorFormMap.get('maxDaysBeforeReservation') && errorFormMap.get('maxDaysBeforeReservation')}
            </span>
          </Box>
        </Box>

        <Box className={classes.typographyContainer} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Typography className={[classes.switchTypography, classes.textTypography].join(' ')}>
            {translateText('is-online-reservation-accepted')}
          </Typography>
          <FormControlLabel
            control={
              <Grid component="label" container alignItems="center" justify="space-around" spacing={1}>
                <Grid item>
                  <Typography className={classes.textTypography}>{translateText('no')}</Typography>
                </Grid>
                <Switch
                  color="primary"
                  checked={fbOutlet.isOnlineReservationAccepted === true}
                  onChange={(e) => updateReservationSettings('isOnlineReservationAccepted', e.target.checked)}
                />
                <Grid item>
                  <Typography className={classes.textTypography}>{translateText('yes')}</Typography>
                </Grid>
              </Grid>
            }
          />
        </Box>

        <Box className={classes.typographyContainer}>
          <Typography className={classes.textTypography}>
            <TranslateTextComponent
              capitalize
              isHtml
              vars={{
                minPaxForReservation: fbOutlet.minPaxForReservation
                  ? `<span style="font-weight: bold; color: red">${fbOutlet.minPaxForReservation}</span>`
                  : 'N',
                maxPaxForReservation: fbOutlet.maxPaxForReservation
                  ? `<span style="font-weight: bold; color: red">${fbOutlet.maxPaxForReservation}</span>`
                  : 'N'
              }}
            >
              accept-min-max-reservations
            </TranslateTextComponent>
          </Typography>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              autoComplete="off"
              label={capitalizeFirstLetter(translate('min', languageContext.language))}
              value={fbOutlet.minPaxForReservation || ''}
              onChange={(e) => updateReservationSettings('minPaxForReservation', e.target.value)}
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                maxLength: 3
              }}
              margin="dense"
              variant="outlined"
            />
            <span style={{ color: 'red', fontSize: 10 }}>{errorFormMap.get('minPaxForReservation') && errorFormMap.get('minPaxForReservation')}</span>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              autoComplete="off"
              label={capitalizeFirstLetter(translate('max', languageContext.language))}
              value={fbOutlet.maxPaxForReservation || ''}
              onChange={(e) => updateReservationSettings('maxPaxForReservation', e.target.value)}
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                maxLength: 3
              }}
              margin="dense"
              variant="outlined"
            />
            <span style={{ color: 'red', fontSize: 10 }}>{errorFormMap.get('maxPaxForReservation') && errorFormMap.get('maxPaxForReservation')}</span>
          </Box>
        </Box>

        <Box className={classes.typographyContainer} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Typography className={[classes.switchTypography, classes.textTypography].join(' ')}>
            {translateText('is-reservation-editable-by-guest')}
          </Typography>
          <FormControlLabel
            control={
              <Grid component="label" container alignItems="center" justify="space-around" spacing={1}>
                <Grid item>
                  <Typography className={classes.textTypography}>{translateText('no')}</Typography>
                </Grid>
                <Switch
                  color="primary"
                  checked={fbOutlet.isReservationEditableByGuest === true}
                  onChange={(e) => updateReservationSettings('isReservationEditableByGuest', e.target.checked)}
                />
                <Grid item>
                  <Typography className={classes.textTypography}>{translateText('yes')}</Typography>
                </Grid>
              </Grid>
            }
          />
        </Box>

        <Box className={classes.typographyContainer}>
          <Typography className={classes.textTypography}>
            <TranslateTextComponent
              capitalize
              isHtml
              vars={{
                avoidEditingOrDeleteBeforeMinutes: fbOutlet.avoidEditingOrDeleteBeforeMinutes
                  ? `<span style="font-weight: bold; color: red">${fbOutlet.avoidEditingOrDeleteBeforeMinutes}</span>`
                  : 'N'
              }}
            >
              avoid-editing-or-delete-before-minutes
            </TranslateTextComponent>
          </Typography>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              autoComplete="off"
              label={capitalizeFirstLetter(translate('mins', languageContext.language))}
              value={fbOutlet.avoidEditingOrDeleteBeforeMinutes || ''}
              onChange={(e) => updateReservationSettings('avoidEditingOrDeleteBeforeMinutes', e.target.value)}
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                maxLength: 4
              }}
              margin="dense"
              variant="outlined"
            />
            <span style={{ color: 'red', fontSize: 10 }}>
              {errorFormMap.get('avoidEditingOrDeleteBeforeMinutes') && errorFormMap.get('avoidEditingOrDeleteBeforeMinutes')}
            </span>
          </Box>
        </Box>

        <Box className={classes.typographyContainer}>
          <Typography className={classes.textTypography}>
            <TranslateTextComponent
              capitalize
              isHtml
              vars={{
                maxPax: fbOutlet.maxPax ? `<span style="font-weight: bold; color: red">${fbOutlet.maxPax}</span>` : 'N'
              }}
            >
              max-n-pax
            </TranslateTextComponent>
          </Typography>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              label={capitalizeFirstLetter(translate('covers', languageContext.language))}
              value={fbOutlet.maxPax || ''}
              onChange={(e) => updateReservationSettings('maxPax', e.target.value)}
              className={classes.textField}
              InputLabelProps={{ shrink: true }}
              inputProps={{
                maxLength: 4
              }}
              margin="dense"
              variant="outlined"
            />
            <span style={{ color: 'red', fontSize: 10 }}>{errorFormMap.get('maxPax') && errorFormMap.get('maxPax')}</span>
          </Box>
        </Box>
      </Grid>
      <Footer onClickSave={onClickSave} onClickDelete={restoreData} />
    </Grid>
  );
};

export default ReservationsSetting;
