import React from 'react';
import Drivers from '../../../components/Moh/drivers/Drivers';
import Layout from '../../../components/shared/Layout';

const MohDriversPage = ({ history }) => {
  return (
    <Layout history={history} section="drivers-list">
      <Drivers />
    </Layout>
  );
};

export default MohDriversPage;
