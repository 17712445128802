/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';
import { FB_ORDERS_FRAGMENT } from '../../fragments';

export const GET_ORDERS = gql`
  query getOrders(
    $fbOutletUUID: String!
    $date: String
    $type: [OrderTypes!]
    $status: FbOrdersStatuses
    $statuses: [FbOrdersStatuses!]
    $orderBy: String
    $orderType: String
    $keyword: String
    $page: Int
    $pageSize: Int
  ) {
    getOrders(
      fbOutletUUID: $fbOutletUUID
      date: $date
      type: $type
      status: $status
      statuses: $statuses
      orderBy: $orderBy
      orderType: $orderType
      keyword: $keyword
      page: $page
      pageSize: $pageSize
    ) {
      totalCount
      data {
        ...fbOrdersFragment
      }
    }
  }
  ${FB_ORDERS_FRAGMENT}
`;
