import React from 'react';
import { Box, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import styles from '../../../../../styles/billsManagement/wideCard';
import { TranslateTextComponent } from '../../../../../translator';
import { Dropdown, greenButton, redButton } from '../../../../../common-fe/src';
import ColoredCircle from '../../../../shared/ColoredCircle';

const BillsListDetails = ({ withPaymentStatus, fbBill, onConfirmFbBillPayment }) => {
  const classes = styles();
  return (
    <Box className={classes.coursesListContainer}>
      {withPaymentStatus && (
        <Box className={classes.paymentStatus}>
          <TranslateTextComponent capitalize style={{ marginRight: 10 }}>
            status
          </TranslateTextComponent>
          <Dropdown
            items={[
              {
                label: (
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <ColoredCircle color={greenButton} style={{ marginRight: 5 }} />
                    <TranslateTextComponent capitalize>paid</TranslateTextComponent>
                  </Box>
                ),
                value: 'paid'
              },
              {
                label: (
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <ColoredCircle color={redButton} style={{ marginRight: 5 }} />
                    <TranslateTextComponent capitalize>not-paid</TranslateTextComponent>
                  </Box>
                ),
                value: 'not-paid'
              }
            ]}
            onChange={(e) => onConfirmFbBillPayment(fbBill.uuid, e.target.value === 'paid')}
            value={fbBill.isPaid ? 'paid' : 'not-paid'}
          />
        </Box>
      )}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <TranslateTextComponent capitalize>quantity-shortened</TranslateTextComponent>
            </TableCell>
            <TableCell style={{ width: '100%' }}>
              <TranslateTextComponent capitalize>description</TranslateTextComponent>
            </TableCell>
            <TableCell>
              <TranslateTextComponent capitalize>amount</TranslateTextComponent>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fbBill &&
            fbBill.fbBillsRows &&
            fbBill.fbBillsRows.map((d) => (
              <TableRow key={d.uuid}>
                <TableCell align="right">{d.quantity}</TableCell>
                <TableCell>{d.name}</TableCell>
                <TableCell align="right">{d.price.toFixed(2).replace('.', ',')}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default BillsListDetails;
