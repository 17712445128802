import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button, Fab, FormControlLabel, Radio, RadioGroup, TextField, InputAdornment, ClickAwayListener, CircularProgress } from '@material-ui/core';
import { Add, Delete, Cancel } from '@material-ui/icons';
import { useFormik } from 'formik';
import moment from 'moment';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import debounce from 'lodash/debounce';
import { getApolloErrors } from '../../../../apollo/ApolloProvider';
import { CommonTable, CustomModal, DataPicker, Dropdown, mainTextColor, primaryColor } from '../../../../common-fe/src';
import { AuthContext } from '../../../../contexts/AuthContext';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import { ModalContext } from '../../../../contexts/ModalContext';
import { CREATE_SMART_SERVICE_ORDER } from '../../../../graphql/moh/bookings/mutations';
import { GET_SMART_SERVICES } from '../../../../graphql/moh/services/queries';
import useCustomQuery from '../../../../hooks/useCustomQuery';
import modalStyle from '../../../../styles/shared/modalStyle';
import { capitalizeFirstLetter, translate, TranslateTextComponent } from '../../../../translator';
import Buttons from '../../../shared/Buttons';
import InputWithLabel from '../../../shared/InputWithLabel';
import bookingServicesListColumns from '../table/bookingServicesListColumns';
import { GET_PROFILES } from '../../../../graphql/moh/bookings/queries';
import { getLatestCheckin } from '../../../../utils/getLatestCheckin';

const Container = styled.form`
  display: flex;
  flex-flow: column;
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;

  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  width: 100%;

  & > div:not(:last-child) {
    margin-right: 30px;
  }
`;

const ClientSearchContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  flex: 0.8;
`;

const ResultsContainer = styled.ul`
  position: absolute;
  top: 65px;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 170px;
  background-color: white;
  border: 1px solid #d9d9d9;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: auto;
  z-index: 1;

  li {
    cursor: pointer;
    padding: 10px 5px;

    transition: background-color 0.3s;

    &:hover {
      background-color: ${() => primaryColor};
    }
  }
`;

const BookingsForm = ({ data, isShowingBookingModal, closeModal, refetchQuery }) => {
  const { appOutletUUID } = useContext(AuthContext);
  const { language } = useContext(LanguageContext);
  const modalContext = useContext(ModalContext);
  const [profilesFilters, setProfilesFilters] = useState({ keyword: '', pageSize: 10, page: 1 });
  const [showResults, setShowResults] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const modalClasses = modalStyle();

  const validationSchema = Yup.object().shape({
    date: Yup.string()
      .typeError(capitalizeFirstLetter(translate('invalid-date', language)))
      .required(capitalizeFirstLetter(translate('required', language))),
    clientName: Yup.string().required(capitalizeFirstLetter(translate('required', language))),
    guestRoom: Yup.number().typeError(capitalizeFirstLetter(translate('invalid-number', language))),
    services: Yup.array().of(
      Yup.object().shape({
        smartServiceUUID: Yup.string().required(capitalizeFirstLetter(translate('required', language))),
        quantity: Yup.number()
          .required(capitalizeFirstLetter(translate('required', language)))
          .min(1)
      })
    ),
    newService: Yup.object().shape({
      smartServiceUUID: Yup.string().required(capitalizeFirstLetter(translate('required', language))),
      quantity: Yup.number()
        .required(capitalizeFirstLetter(translate('required', language)))
        .min(1)
    })
  });
  const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleReset, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      date: data?.date ?? null,
      clientName: `${data?.profile?.firstName ?? ''} ${data?.profile?.lastName ?? ''}`.trim() || data?.clientName || '',
      profileUUID: data?.profile?.uuid ?? '',
      guestRoom: getLatestCheckin(data?.profile?.checkins)?.room?.number || data?.guestRoom || '',
      services: data?.orderSmartServices ?? [],
      newService: { smartServiceUUID: '', quantity: 1 },
      notes: data?.notes ?? '',
      isPaid: data?.isSmartOrderServicePaid ?? false
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (isShowingBookingModal) console.log(values);
      else createServiceOrder({ values });
      setSubmitting(false);
    }
  });

  // Queries
  const getSmartServices = useCustomQuery(GET_SMART_SERVICES, { variables: { fbOutletUUID: appOutletUUID } });
  const getProfiles = useCustomQuery(GET_PROFILES, { variables: profilesFilters, skip: values.clientName.length < 3 });

  // Mutations
  const [createSmartServiceOrder, { loading }] = useMutation(CREATE_SMART_SERVICE_ORDER);

  const createServiceOrder = ({ values }) => {
    let smartServiceItems = [...(values.services ?? [])];
    if (values.newService.smartServiceUUID && values.newService.quantity > 0) {
      smartServiceItems = smartServiceItems.concat(values.newService);
    }

    smartServiceItems = smartServiceItems.map((s) => ({ ...s, quantity: Number(s.quantity) }));

    let variables;

    if (values.profileUUID) {
      variables = {
        fbOutletUUID: appOutletUUID,
        smartServiceItems,
        date: moment(values.date).format('YYYY-MM-DD'),
        profileUUID: values.profileUUID,
        guestRoom: values.guestRoom,
        notes: values.notes,
        isSmartOrderServicePaid: values.isPaid
      };
    } else {
      variables = {
        fbOutletUUID: appOutletUUID,
        smartServiceItems,
        date: moment(values.date).format('YYYY-MM-DD'),
        clientName: values.clientName,
        guestRoom: values.guestRoom,
        notes: values.notes,
        isSmartOrderServicePaid: values.isPaid
      };
    }

    createSmartServiceOrder({
      variables
    })
      .then(() => {
        refetchQuery();
        closeModal();
        modalContext.openModal({
          class: 'success',
          title: <TranslateTextComponent capitalize>new-moh-service-order-success</TranslateTextComponent>,
          text: <TranslateTextComponent capitalize>new-moh-service-order-description-success</TranslateTextComponent>,
          actionButtons: [
            <Button
              key={0}
              variant="contained"
              color="primary"
              className={modalClasses.invertedButton}
              onClick={() => {
                modalContext.closeModal();
              }}
            >
              <TranslateTextComponent uppercase>ok</TranslateTextComponent>
            </Button>
          ]
        });
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - ')
        });
      });
  };

  // const editServiceOrder = ({ values }) => {
  //   editSmartServiceOrder({
  //     refetchQueries: [{ query: GET_SMART_SERVICES, variables: { fbOutletUUID: appOutletUUID } }],
  //     variables: {
  //       smartServiceUUID: data.uuid,
  //       smartServiceData: {
  //         ...values,
  //         price: parseFloat(values.price, 10),
  //         dailyQuantity: parseFloat(values.dailyQuantity, 10),
  //         days: weekdays.filter((weekday) => weekday.checked).map((w) => Object.keys(WEEKDAYS).indexOf(w.value.toUpperCase()))
  //       }
  //     }
  //   })
  //     .then(() => {
  //       closeModal();
  //       modalContext.openModal({
  //         class: 'success',
  //         title: <TranslateTextComponent capitalize>edit-moh-service-success</TranslateTextComponent>,
  //         text: <TranslateTextComponent capitalize>edit-moh-service-description-success</TranslateTextComponent>,
  //         actionButtons: [
  //           <Button
  //             key={0}
  //             variant="contained"
  //             color="primary"
  //             className={modalClasses.invertedButton}
  //             onClick={() => {
  //               modalContext.closeModal();
  //             }}
  //           >
  //             <TranslateTextComponent uppercase>ok</TranslateTextComponent>
  //           </Button>
  //         ]
  //       });
  //     })
  //     .catch((err) => {
  //       modalContext.openModal({
  //         class: 'danger',
  //         title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
  //         text: getApolloErrors(err).join(' - ')
  //       });
  //     });
  // };

  const getAvailableServices = (currentServiceUUID) => {
    const servicesUUIDs = values.services.map((s) => s.smartServiceUUID);

    if (values.date) {
      if (currentServiceUUID) {
        const currentServiceLabel = getSmartServices.data?.getSmartServices?.data.find((s) => s.uuid === currentServiceUUID)?.name;
        return getSmartServices.data?.getSmartServices?.data
          ?.filter((s) => !servicesUUIDs.includes(s.uuid) && s.days.includes(values.date.getDay()))
          .map((service) => ({ label: service.name, value: service.uuid }))
          .concat({ label: currentServiceLabel, value: currentServiceUUID });
      }

      return getSmartServices.data?.getSmartServices?.data
        ?.filter((s) => !servicesUUIDs.includes(s.uuid) && s.days.includes(values.date.getDay()))
        .map((service) => ({ label: service.name, value: service.uuid }));
    }
    return [];
  };

  const onClientNameChangeHandler = debounce(({ target: { value } }) => {
    if (value?.trim()?.length > 0) {
      setProfilesFilters((prev) => ({ ...prev, keyword: value }));
    } else {
      setProfilesFilters((prev) => ({ ...prev, keyword: '' }));
    }
  }, 1000);

  return (
    <CustomModal
      hideCloseButton
      showModal
      onClose={closeModal}
      width={857}
      height="auto"
      styledHeader
      styleBody={{
        borderTop: 'none',
        backgroundColor: 'white',
        borderRadius: 20,
        justifyContent: 'flex-start',
        padding: '25px 60px'
      }}
      header={
        <TranslateTextComponent uppercase vars={{ id: data?.id }}>
          {isShowingBookingModal ? 'edit-booking-service' : 'new-booking-service'}
        </TranslateTextComponent>
      }
    >
      <Container>
        <RowContainer>
          <InputWithLabel label="date" error={touched.date && errors.date}>
            <DataPicker fullWidth value={values.date} onChange={(value) => setFieldValue('date', value)} disabled={data !== null} />
          </InputWithLabel>
          {isShowingBookingModal && (
            <InputWithLabel label="price">
              <TextField
                name="price"
                fullWidth
                variant="outlined"
                color="primary"
                value={data?.orderSmartServices?.map((service) => service.price * service.quantity)?.reduce((p, v) => p + v, 0)}
                disabled
              />
            </InputWithLabel>
          )}
        </RowContainer>
        <RowContainer>
          <ClientSearchContainer>
            <InputWithLabel label="client" error={touched.clientName && errors.clientName}>
              <TextField
                name="clientName"
                fullWidth
                variant="outlined"
                color="primary"
                value={values.clientName}
                error={touched.clientName && !!errors.clientName}
                disabled={data !== null}
                InputProps={{
                  // eslint-disable-next-line no-nested-ternary
                  endAdornment: getProfiles.loading ? (
                    <InputAdornment position="end">
                      <CircularProgress color="primary" style={{ marginRight: 10, width: 25, height: 25 }} />
                    </InputAdornment>
                  ) : values.profileUUID && data === null ? (
                    <InputAdornment position="end">
                      <Cancel
                        color="primary"
                        style={{ cursor: 'pointer', marginRight: 5 }}
                        onClick={() => {
                          setSelectedProfile(null);
                          setFieldValue('clientName', '');
                          setFieldValue('profileUUID', '');
                          setFieldValue('guestRoom', '');
                        }}
                      />
                    </InputAdornment>
                  ) : null
                }}
                onClick={() => {
                  if (values.clientName.length >= 3 && !values.profileUUID) {
                    setShowResults(true);
                  }
                }}
                onBlur={handleBlur}
                onChange={(e) => {
                  e.persist();
                  onClientNameChangeHandler(e);
                  if (values.profileUUID) {
                    setSelectedProfile(null);
                    setFieldValue('profileUUID', '');
                  }
                  setShowResults(true);
                  setFieldValue('clientName', e.target.value);
                }}
              />
            </InputWithLabel>
            {values.clientName.length >= 3 && !values.profileUUID && getProfiles.data?.getProfiles?.data?.length > 0 && showResults && (
              <ClickAwayListener onClickAway={() => setShowResults(false)}>
                <ResultsContainer>
                  {getProfiles.data?.getProfiles?.data?.map((p) => (
                    <li
                      key={p.uuid}
                      onClick={() => {
                        setSelectedProfile(p);
                        setFieldValue('profileUUID', p.uuid);
                        setFieldValue('clientName', `${p.firstName} ${p.lastName}`);
                        setFieldValue('guestRoom', getLatestCheckin(p.checkins)?.room?.number ?? '');
                        setShowResults(false);
                      }}
                      role="presentation"
                    >
                      {p.firstName} {p.lastName}
                      {moment(p.dateOfBirth).isValid()
                        ? ` - ${capitalizeFirstLetter(translate('born-on', language))} ${moment(p.dateOfBirth).format('DD/MM/YYYY')}`
                        : null}
                      {p.placeOfBirth ? ` - ${capitalizeFirstLetter(translate('born-in', language))} ${p.placeOfBirth}` : null}
                    </li>
                  ))}
                </ResultsContainer>
              </ClickAwayListener>
            )}
          </ClientSearchContainer>
          <InputWithLabel label="room-number" error={touched.guestRoom && errors.guestRoom} containerStyle={{ flex: 0.2 }}>
            <TextField
              name="guestRoom"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.guestRoom}
              error={touched.guestRoom && !!errors.guestRoom}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={data !== null || (!!values.profileUUID && !!values.guestRoom && getLatestCheckin(selectedProfile?.checkins)?.room?.number)}
            />
          </InputWithLabel>
        </RowContainer>
        {!isShowingBookingModal ? (
          <>
            {values.services.map((service, index) => (
              <RowContainer key={index}>
                <InputWithLabel
                  label="service"
                  containerStyle={{ maxWidth: 'calc(100% - 160px)' }}
                  error={touched.services && !values.services?.[index]?.smartServiceUUID ? translate('select-a-service', language) : null}
                >
                  <Dropdown
                    dropdownStyle={{ minHeight: 40, width: '100%' }}
                    items={getAvailableServices(values.services[index].smartServiceUUID)}
                    value={values.services?.[index]?.smartServiceUUID}
                    onChange={({ target: { value } }) => {
                      const newServices = [...values.services];
                      newServices[index] = { smartServiceUUID: value, quantity: newServices[index].quantity };
                      setFieldValue('services', newServices);
                    }}
                    disabled={data !== null || !values.date}
                  />
                </InputWithLabel>
                <InputWithLabel
                  label="quantity"
                  containerStyle={{ width: 60 }}
                  error={
                    touched.quantity &&
                    (Number.isNaN(Number(values.services?.[index]?.quantity)) || Number(values.services?.[index]?.quantity) <= 0
                      ? translate('invalid-number', language)
                      : null)
                  }
                >
                  <TextField
                    name="quantity"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    value={values.services?.[index]?.quantity}
                    error={
                      touched.quantity &&
                      (Number.isNaN(Number(values.services?.[index]?.quantity)) || Number(values.services?.[index]?.quantity) <= 0)
                    }
                    onBlur={handleBlur}
                    onChange={({ target: { value } }) => {
                      const newServices = [...values.services];
                      newServices[index] = { smartServiceUUID: newServices[index].smartServiceUUID, quantity: value };
                      setFieldValue('services', newServices);
                    }}
                    disabled={data !== null}
                  />
                </InputWithLabel>
                <Fab
                  color="primary"
                  style={{ color: 'white', width: 40, height: 'auto', alignSelf: 'flex-end', boxShadow: 'none' }}
                  onClick={() => {
                    const newServices = [...values.services].filter((service, serviceIndex) => index !== serviceIndex);
                    setFieldValue('services', newServices);
                  }}
                >
                  <Delete />
                </Fab>
              </RowContainer>
            ))}
            <RowContainer>
              <InputWithLabel
                label="service"
                containerStyle={{ maxWidth: 'calc(100% - 160px)' }}
                error={touched.services && !values.newService.smartServiceUUID ? translate('select-a-service', language) : null}
              >
                <Dropdown
                  dropdownStyle={{ minHeight: 40, width: '100%' }}
                  items={getAvailableServices()}
                  value={values.newService.smartServiceUUID}
                  onChange={({ target: { value } }) => setFieldValue('newService', { smartServiceUUID: value, quantity: values.newService.quantity })}
                  disabled={data !== null || !values.date}
                />
              </InputWithLabel>
              <InputWithLabel
                containerStyle={{ width: 60 }}
                label="quantity"
                error={
                  touched.quantity &&
                  (Number.isNaN(Number(values.newService?.quantity)) || Number(values.newService?.quantity) <= 0
                    ? translate('invalid-number', language)
                    : null)
                }
              >
                <TextField
                  name="quantity"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  value={values.newService.quantity}
                  error={touched.quantity && (Number.isNaN(Number(values.newService?.quantity)) || Number(values.newService?.quantity) <= 0)}
                  onBlur={handleBlur}
                  onChange={({ target: { value } }) =>
                    setFieldValue('newService', { smartServiceUUID: values.newService.smartServiceUUID, quantity: value })
                  }
                  disabled={data !== null}
                />
              </InputWithLabel>
              <Fab
                aria-label="add"
                color="primary"
                style={{ color: 'white', width: 40, height: 'auto', alignSelf: 'flex-end', boxShadow: 'none' }}
                disabled={getAvailableServices().length - 1 <= values.services.length}
                onClick={() => {
                  const newServices = [...values.services].concat({
                    smartServiceUUID: values.newService.smartServiceUUID,
                    quantity: values.newService.quantity
                  });
                  setFieldValue('newService', { smartServiceUUID: '', quantity: 1 });
                  setFieldValue('services', newServices);
                }}
              >
                <Add />
              </Fab>
            </RowContainer>
          </>
        ) : (
          <RowContainer>
            <InputWithLabel label="order-list">
              <CommonTable
                tableContainerStyle={{ width: '100%', fontSize: 14, border: `1px solid ${mainTextColor}`, opacity: 0.7, borderRadius: 5 }}
                headerCellStyle={{ color: mainTextColor, fontSize: 14 }}
                headerTextStyle={{ color: mainTextColor, fontSize: 14 }}
                rowCellStyle={{ borderTop: `1px solid ${mainTextColor}` }}
                rowStyle={{ borderBottom: `1px solid ${mainTextColor}` }}
                items={data?.orderSmartServices ?? []}
                columns={bookingServicesListColumns()}
              />
            </InputWithLabel>
          </RowContainer>
        )}
        <RowContainer>
          <InputWithLabel label="notes" error={touched.notes && errors.notes}>
            <TextField
              name="notes"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.notes}
              error={touched.notes && !!errors.notes}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={data !== null}
            />
          </InputWithLabel>
        </RowContainer>
        <RowContainer>
          <InputWithLabel label="status:" labelStyle={{ fontSize: 16 }} horizontal>
            <RadioGroup
              style={{ display: 'flex', flexDirection: 'row' }}
              value={values.isPaid}
              onChange={({ target: { value } }) => setFieldValue('isPaid', value === 'paid')}
            >
              <FormControlLabel
                checked={values.isPaid}
                value="paid"
                control={<Radio color="primary" />}
                label={<TranslateTextComponent capitalize>paid</TranslateTextComponent>}
                labelPlacement="end"
                disabled={data !== null}
              />
              <FormControlLabel
                checked={!values.isPaid}
                value="unpaid"
                control={<Radio color="primary" />}
                label={<TranslateTextComponent capitalize>to-be-paid</TranslateTextComponent>}
                labelPlacement="end"
                disabled={data !== null}
              />
            </RadioGroup>
          </InputWithLabel>
          {!isShowingBookingModal && (
            <Buttons
              buttons={[
                {
                  buttonType: 'reset',
                  type: 'cancel',
                  onClick: () => {
                    setFieldValue('newService', { smartServiceUUID: '', quantity: 1 });
                    handleReset();
                  }
                },
                { buttonType: 'submit', type: 'confirm', disabled: isSubmitting, isLoading: loading, onClick: handleSubmit }
              ]}
            />
          )}
        </RowContainer>
      </Container>
    </CustomModal>
  );
};

export default BookingsForm;
