import { createMuiTheme } from '@material-ui/core/styles';

export const primaryColor = '#00b7ff';
export const secondaryColor = '#edfaff';
export const invertedPrimary = '#3399cc';
export const invertedPrimaryCC80 = '#3399CC80';

export const greenButton = '#449D44';
export const redButton = '#CB2D24';
export const hoverRedButton = '#9B1E19';
export const hoverGreenButton = '#146919';

export const mainTextColor = '#55595c';
export const borderTheme = `1.5px solid ${primaryColor}`;

export const headerHeight = 64;
export const leftSidebarWidth = 82;

export const commonTheme = {
  palette: {
    background: {
      // default: secondaryColor
    },
    primary: {
      main: primaryColor
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        'body, html, #root': { height: '100%' },
        '*': { scrollbarColor: '#00B7FF #B4DCED' },
        '*::-webkit-scrollbar': {
          width: '20px'
        },
        '*::-webkit-scrollbar-track': {
          boxShadow: `inset 0 0 10px 10px ${secondaryColor}`,
          border: 'solid 3px transparent',
          borderRadius: 10
        },
        '*::-webkit-scrollbar-thumb': {
          boxShadow: `inset 0 0 10px 10px ${primaryColor}`,
          border: 'solid 3px transparent',
          borderRadius: '10px'
        },
        'a:-webkit-any-link': {
          textDecoration: 'none'
        }
      }
    },
    MuiGrid: {
      root: {
        '&.timePickerSliderBox .slick-slide.slick-current': {
          backgroundColor: primaryColor,
          color: 'white',
          fontSize: 14
        },
        '&.timePickerSliderBox .slick-slide.slick-active:not(.slick-current)': {
          fontSize: 11
        },
        '&.timePickerSliderBox .slick-slide': {
          fontSize: 10,
          height: '22px !important'
        },
        '&.timePickerSliderBox .slick-list': {
          display: 'flex !important',
          flexFlow: 'column !important',
          height: '135px !important'
        }
      }
    },

    MuiTextField: {
      root: {
        '&.blackInput .MuiFormLabel-root': {
          color: 'black'
        },
        '&.blackInput .MuiInputBase-root input': {
          color: 'black'
        },
        '&.blackInput .MuiInput-underline:before': {
          borderBottomColor: 'black'
        },
        '&.blackInput .MuiInput-underline:after': {
          borderBottomColor: 'black'
        }
      }
    },

    MuiOutlinedInput: {
      root: {
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: borderTheme
        }
      },
      notchedOutline: {
        borderColor: primaryColor,
        border: borderTheme
      },
      adornedEnd: {
        paddingRight: 0
      },
      input: {
        '&.textFieldAge': {
          padding: 0
        }
      }
    },
    MuiChip: {
      deleteIconSmall: {
        color: 'white'
      }
    },
    MuiButtonBase: {
      root: {
        '&.buttonLeftMenu': {
          width: '50%',

          '&.MuiButton-text': {
            borderRadius: 10,
            background: primaryColor,
            margin: '10px 10px'
          },

          '& .MuiButton-label': {
            padding: '0 10px',
            color: 'white'
          }
        },
        '&.buttonTable': {
          width: 66,
          height: 28,
          fontSize: 10,
          color: 'white'
        },
        '&.buttonCircle': {
          width: 28,
          height: 28,
          background: primaryColor,
          borderRadius: '50%',
          padding: 2,
          color: 'white',
          boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
          '&:hover': {
            backgroundColor: invertedPrimary
          },
          '&.Mui-disabled': {
            boxShadow: 'none',
            backgroundColor: 'grey',
            color: 'white'
          }
        }
      }
    },
    MuiButton: {
      root: {
        '&:hover': {
          backgroundColor: invertedPrimary
        }
      }
    },
    PrivateRadioButtonIcon: {
      root: {
        color: primaryColor,
        borderRadius: '50%'
      },
      checked: {
        background: 'transparent',
        borderRadius: '50%',
        color: 'white',
        border: `6px solid ${primaryColor}`,
        width: 22,
        height: 22,

        '& svg': {
          display: 'none'
        }
      }
    },

    MuiTabs: {
      indicator: {
        display: 'none'
      }
    },
    MuiTab: {
      root: {
        '&.sidebar .MuiTab-wrapper': {
          fontWeight: 'normal',
          fontSize: 10
        },
        '&.sidebar': {
          minWidth: 'unset'
        },
        '&.MuiTab-textColorInherit': {
          opacity: 1
        }
      }
    },
    MuiTable: {
      root: {
        '&.reservationTable .MuiTableCell-root': {
          padding: '15px 0'
        },
        '&.settingsTable .MuiTableCell-root': {
          padding: '15px 15px'
        },
        '&.cashTable .MuiTableCell-root': {
          borderTop: `1px solid ${primaryColor}`,
          padding: '5px 0'
        },
        '&.historyModalTable .tableBody .MuiTableRow-root': {
          '&:last-child .MuiTableCell-root': {
            borderRadius: 20
          }
        }
      }
    },
    MuiInput: {
      root: {
        '&.inputTable': {
          border: `2px solid${primaryColor}`,
          borderRadius: 4,
          height: 25,
          fontSize: 10,
          background: 'white'
        }
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: invertedPrimary
      },
      positionStatic: {
        transform: 'unset'
      }
    },
    MuiSelect: {
      select: {
        paddingLeft: 10,
        '&:focus': {
          backgroundColor: 'unset'
        }
      }
    },
    // td.MuiTableCell-root.MuiTableCell-body
    MuiTableCell: {
      root: {
        borderBottom: 'unset'
      }
    } /*
    MuiIconButton: {
      root: {
        '&.dataPicker': {
          padding: 0,
          backgroundColor: primaryColor,
          color: 'white'
        }
      }
    } */
  }
};

export const appTheme = createMuiTheme({
  ...commonTheme
});
