import { makeStyles } from '@material-ui/core/styles';
import { borderTheme, mainTextColor } from '../../theme';

const styles = {
  leftContentStyle: () => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  }),
  avatarContainer: () => ({
    display: 'flex',
    justifyContent: 'center'
  }),
  avatarImage: () => ({
    margin: 10,
    width: 111,
    height: 111
  }),
  gridContainer: () => ({
    borderBottom: borderTheme
  }),
  boxTypography: () => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5
  }),
  typographyStyle: () => ({
    fontSize: 14,
    color: mainTextColor
  }),
  secondaryTypoStyle: () => ({
    fontWeight: 'bold',
    fontSize: 12,
    color: mainTextColor
  }),
  spaceContent: () => ({
    marginLeft: 5
  }),
  gridAllergies: () => ({
    display: 'flex',
    flexDirection: 'column'
  }),
  guestNameTypography: () => ({
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: 20,
    marginBottom: 6.5,
    color: mainTextColor
  }),
  intoleranceBoxContainer: () => ({
    display: 'flex',
    justifyContent: 'flex-end'
  }),
  preferencesTypo: () => ({
    fontSize: 12,
    marginBottom: 6.5
  }),
  fbPreferencesTypo: () => ({
    fontSize: 12,
    marginLeft: 5,
    textTransform: 'capitalize'
  }),
  smallButtonContainer: () => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: '0.537 0 10%'
  }),
  intoleranceTypo: () => ({
    fontSize: 12,
    fontFamily: 'Roboto',
    textTransform: 'capitalize'
  })
};

export default makeStyles(styles);
