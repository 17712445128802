import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Box, Button, Grid } from '@material-ui/core';
import typy from 'typy';
import SaveIcon from '@material-ui/icons/Save';
import RestoreIcon from '@material-ui/icons/Restore';
import orderBy from 'lodash/orderBy';
import { ModalContext } from '../../contexts/ModalContext';
import { AuthContext } from '../../contexts/AuthContext';
import { TranslateTextComponent } from '../../translator';
import { GET_ROOMS, GET_DEFAULT_TABLES } from '../../graphql/roomEditor/queries';
import { SET_DEFAULT_ROOM_TABLES } from '../../graphql/roomEditor/mutations';
import RoomHeader from './RoomHeader/RoomHeader';
import RoomEditor from './RoomEditor';
import TableEditHeader from './TableEditHeader/TableEditHeader';
import { getApolloErrors } from '../../apollo/ApolloProvider';
import modalStyle from '../../common-fe/src/styles/shared/modalStyle';
import layoutStyle from '../../styles/leftSidebarLayout/leftSidebarLayoutStyle';
import style from '../../styles/roomEditor/style';
import useCustomQuery from '../../hooks/useCustomQuery';

const RoomContainer = () => {
  const layoutClasses = layoutStyle({ position: 'relative' });
  const classes = style();
  const modalClasses = modalStyle();
  const [initialRoomTables, setInitialRoomTables] = useState([]);
  const [roomUUID, setRoomUUID] = useState(-1);
  const [rooms, setRooms] = useState([]);
  const [roomTables, setRoomTables] = useState(initialRoomTables);
  const [pax, setPax] = useState({ maxPax: 0, available: 0, occupied: 0 });
  const [showButtons, setShowButtons] = useState(false);
  const [showTableEditor, setShowTableEditor] = useState(false);
  const [tableToEdit, setTableToEdit] = useState(null);
  const [initialLayout, setInitialLayout] = useState({});
  const [layout, setLayout] = useState({});
  const [layoutInitialized, setLayoutInitialized] = useState(false);
  const [saveRoomTables] = useMutation(SET_DEFAULT_ROOM_TABLES);
  const modalContext = useContext(ModalContext);
  const { appOutletUUID } = useContext(AuthContext);

  useEffect(() => {
    if (roomUUID >= 0) getTablesRes.refetch();
  }, [roomUUID]);

  useEffect(() => {
    if (!layoutInitialized && Object.keys(layout).length > 0) {
      setLayoutInitialized(true);
      setInitialLayout(layout);
    }
  }, [layout]);

  useEffect(() => {
    if (rooms.length > 0) {
      setRoomUUID(rooms[0].uuid);
    }
  }, [rooms]);

  const updateMaxPax = (tables) => {
    let occupied = 0;
    tables.forEach((table) => {
      occupied += table.capacity;
    });

    let maxPax = 0;
    rooms.forEach((room) => {
      if (room.uuid === roomUUID) {
        maxPax = room.maxPax;
      }
    });
    setPax({ ...pax, maxPax, available: maxPax - occupied, occupied });
  };

  // Get Tables query
  const getTablesRes = useCustomQuery(GET_DEFAULT_TABLES, {
    variables: { fbRoomUUID: roomUUID },
    onCompleted: (data) => {
      setShowButtons(false);
      setLayoutInitialized(false);
      setLayout(layout);
      setInitialRoomTables(typy(data, 'getFbDefaultTables.data').safeArray);
      setRoomTables(typy(data, 'getFbDefaultTables.data').safeArray);
      updateMaxPax(typy(data, 'getFbDefaultTables.data').safeArray);
    },
    fetchPolicy: 'network-only',
    skip: roomUUID === -1,
  });

  // Get Rooms query
  const getRoomsRes = useCustomQuery(GET_ROOMS, {
    variables: { fbOutletUUID: appOutletUUID },
    onCompleted: () => {
      setShowButtons(false);
      setRooms(typy(getRoomsRes, 'data.getFbRooms.data').safeArray);
    },
    fetchPolicy: 'network-only',
  });

  const onRoomChange = (uuid) => {
    setRoomUUID(uuid);
  };

  const onRoomTablesChange = (newTable) => {
    const newTables = [...roomTables];
    let nextNumber = 1;
    const filteredTables = roomTables.filter((table) => table.number.match(/\d/));
    const sortedTables = orderBy(filteredTables, ['number'], ['desc']);
    if (sortedTables && sortedTables[0]) {
      nextNumber = Number(sortedTables[0].number) + 1;
    }

    for (let i = 0; i < newTable.quantity; i++) {
      newTables.push({
        id: new Date().getTime() + i,
        number: nextNumber.toString(),
        size: 70,
        shape: newTable.selectedShape,
        isActive: newTable.isActive,
        capacity: parseInt(newTable.capacity, 10),
        positionX: 2,
        positionY: 0,
        rotate: 0,
        tabletop: newTable.tabletop,
        w: 1,
        h: 1,
      });
      nextNumber++;
    }
    updateMaxPax(newTables);
    setRoomTables(newTables);
    setShowButtons(true);
  };

  const onTableShift = (layout) => {
    setShowButtons(true);
    const newRoomTables = roomTables;
    for (let i = 0; i < roomTables.length; i++) {
      for (let k = 0; k < layout.length; k++) {
        if (newRoomTables[i].id === parseInt(layout[k].i, 10)) {
          newRoomTables[i] = { ...newRoomTables[i], positionX: layout[k].x, positionY: layout[k].y };
        }
      }
    }
    setRoomTables([...newRoomTables]);
  };

  const onTableUpdate = (table) => {
    const newRoomTables = [];
    for (let i = 0; i < roomTables.length; i++) {
      if (table.id === roomTables[i].id) {
        newRoomTables.push({
          ...roomTables[i],
          ...table,
          capacity: parseInt(table.capacity, 10),
          w: 1,
          h: 1,
        });
      } else newRoomTables.push(roomTables[i]);
      setRoomTables([...newRoomTables]);
    }
    updateMaxPax(newRoomTables);
    setShowButtons(true);
    setLayout({});
  };

  const onTableDelete = (id) => {
    const newRoomTables = roomTables.filter((table) => table.id !== id);
    updateMaxPax(newRoomTables);
    setRoomTables([...newRoomTables]);
    setShowTableEditor(false);
  };

  const onTableClickHandler = (table) => {
    setShowTableEditor(true);
    setTableToEdit(table);
  };

  const textTranslate = (text) => {
    return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
  };

  const tableRoomSaved = () => {
    modalContext.openModal({
      class: 'success',
      title: textTranslate('mod-confirm'),
      text: textTranslate('mod-saved'),
      actionButtons: (
        <Grid container justify="space-around">
          <Button
            color="primary"
            variant="contained"
            style={{ color: 'white' }}
            className={modalClasses.buttonStyle}
            onClick={() => {
              modalContext.closeModal();
            }}
          >
            {textTranslate('confirm')}
          </Button>
        </Grid>
      ),
    });
  };

  const onSaveHandler = () => {
    modalContext.openModal({
      class: 'success',
      title: <TranslateTextComponent uppercase>room-save-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>room-save</TranslateTextComponent>,
      actionButtons: [
        <Button key={0} variant="outlined" color="primary" className={modalClasses.invertedButton} onClick={modalContext.closeModal}>
          <TranslateTextComponent>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            saveRoomTables({
              variables: {
                fbRoomUUID: roomUUID,
                fbTables: roomTables.map((table) => ({
                  number: table.number,
                  shape: table.shape,
                  isActive: table.isActive,
                  capacity: parseInt(table.capacity, 10),
                  positionX: table.positionX,
                  positionY: table.positionY,
                  rotate: table.rotate,
                  tabletop: table.tabletop,
                })),
              },
            })
              .then(() => {
                setInitialLayout(layout);
                setInitialRoomTables(roomTables);
                setShowButtons(false);
                setShowTableEditor(false);
                modalContext.closeModal();
                tableRoomSaved();
              })
              .catch((err) => {
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - '),
                });
              });
          }}
        >
          <TranslateTextComponent>confirm</TranslateTextComponent>
        </Button>,
      ],
    });
  };

  const onResetClickHandler = () => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent uppercase>room-reset-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>room-reset</TranslateTextComponent>,
      actionButtons: [
        <Button key={0} variant="outlined" color="primary" className={modalClasses.invertedButton} onClick={modalContext.closeModal}>
          <TranslateTextComponent>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            setRoomTables([...initialRoomTables]);
            setLayout({ ...initialLayout });
            updateMaxPax(initialRoomTables);
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent>confirm</TranslateTextComponent>
        </Button>,
      ],
    });
  };

  const closeTableEditor = () => {
    setShowTableEditor(false);
  };

  const onLayoutChangeHandler = (layout, layouts) => {
    setLayout({ ...layouts });
  };

  return (
    <Box className={layoutClasses.layoutContainer}>
      <Box className={layoutClasses.rightSide}>
        <RoomHeader roomUUID={roomUUID} rooms={rooms} pax={pax} onRoomTablesChange={onRoomTablesChange} onRoomChange={onRoomChange} />
        {roomTables.length > 0 && (
          <TableEditHeader
            showTableEditor={showTableEditor}
            closeTableEditor={closeTableEditor}
            table={tableToEdit}
            updateTable={onTableUpdate}
            deleteTable={onTableDelete}
          />
        )}
        <RoomEditor
          isDraggable
          layout={layout}
          roomTables={roomTables}
          onLayoutChangeHandler={onLayoutChangeHandler}
          marginTop={showTableEditor ? 100 : 0}
          onTableClick={onTableClickHandler}
          onTableShift={onTableShift}
          updateTable={onTableUpdate}
          closeTableEditor={closeTableEditor}
        />
        {showButtons && (
          <Box className={classes.buttonsContainer}>
            <Button className={classes.button} variant="contained" size="medium" onClick={onResetClickHandler}>
              <RestoreIcon className={classes.buttonIcon} />
              <TranslateTextComponent uppercase>reset</TranslateTextComponent>
            </Button>
            <Button className={classes.button} variant="contained" size="medium" color="primary" onClick={onSaveHandler}>
              <SaveIcon className={classes.buttonIcon} />
              <TranslateTextComponent uppercase>save</TranslateTextComponent>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RoomContainer;
