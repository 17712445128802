import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import typy from 'typy';
import styles from '../../../styles/viewTables/table/style';
import Chairs from '../../shared/Chairs';
import Courses from './Courses';

const TABLE_UNIT_PIXELS = 60;

const getTableSizes = (table) => {
  const sizes = {
    width: 1,
    height: 1
  };

  const capacity = table.capacity < 4 ? 4 : table.capacity;

  if (table && table.shape === 'rectangle') {
    sizes.width = TABLE_UNIT_PIXELS * capacity * 0.6;
    sizes.height = TABLE_UNIT_PIXELS * capacity * 0.35;
  } else {
    sizes.width = TABLE_UNIT_PIXELS * capacity * 0.5;
    sizes.height = TABLE_UNIT_PIXELS * capacity * 0.5;
  }
  return sizes;
};

const TableItem = ({
  table,
  isBusy,
  selectedTableUUID,
  selectedOptionButton,
  selectedGuestUUID,
  scaleSize,
  onTableClick,
  onChairClick,
  isRoomEditable,
  isGuestNameShowed
}) => {
  const [hover, setHover] = useState(false);
  const classes = styles({
    capacity: table ? table.capacity : null,
    hover,
    rotation: table ? table.rotate : null,
    background: table ? table.fbReservationTables.length <= 0 : false,
    reserved: typy(table, 'fbReservationTables[0]').safeArray.length > 0
  });

  // const reservationTime = typy(table, 'fbReservationTables[0].fbReservation.reservationTime').safeString;
  // const firstName = typy(table, 'fbReservationTables[0].fbReservation.bookedBy.firstname').safeString;
  const lastName = typy(table, 'fbReservationTables[0].fbReservation.bookedBy.lastname').safeString;
  const roomNumber = typy(table, 'fbReservationTables[0].fbReservation.guestRoom').safeString;

  const setTableFilter = () => {
    if (isRoomEditable) {
      return 'none';
    }

    if (
      selectedTableUUID &&
      selectedTableUUID !== table.uuid &&
      isBusy &&
      selectedOptionButton !== 'transfer-table' &&
      selectedOptionButton !== 'merge-table'
    ) {
      return 'blur(5px)';
    }

    if (
      selectedTableUUID &&
      selectedTableUUID !== table.uuid &&
      selectedOptionButton !== 'transfer-table' &&
      selectedOptionButton !== 'merge-table'
    ) {
      return 'blur(5px)';
    }

    if (isBusy && selectedTableUUID && selectedOptionButton === 'transfer-table') {
      return 'blur(5px)';
    }

    if (!isBusy && selectedOptionButton === 'free-table') {
      return 'blur(5px)';
    }

    if (isBusy && selectedGuestUUID) {
      return 'blur(5px)';
    }

    return 'none';
  };

  return (
    <Box
      className="table-item"
      tabIndex="0"
      style={{
        ...getTableSizes(table),
        transform: `scale(${scaleSize})`,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
        filter: setTableFilter()
      }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onFocus={() => {}}
      onClick={(e) => {
        e.stopPropagation();
        if (onTableClick) onTableClick(e, table);
      }}
    >
      <Chairs table={table} rotate={table.rotate} isGuestNameShowed={isGuestNameShowed} onChairClick={onChairClick} />
      <Box className={classes.badge}>{table.number || ''}</Box>
      <Box className={classes.tableDescription}>
        <Box className={classes.roomArea}>{roomNumber ? `Stanza ${roomNumber}` : null}</Box>
        <Box className={classes.courseStatusArea}>
          {typy(table, 'fbReservationTables[0].fbOrder.fbCourses').safeArray.length > 0 && (
            <Courses
              courses={typy(table, 'fbReservationTables[0].fbOrder.fbCourses')
                .safeArray.filter((course) => course.type === 'food')
                .sort((prev, next) => prev.number - next.number)}
            />
          )}
        </Box>
        <Box className={classes.roomArea}>{roomNumber ? `Stanza ${roomNumber}` : null}</Box>
        <Box className={classes.nameArea}>{lastName ? `${lastName}` : null}</Box>
        {/* <Typography style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>{table.capacity} pax</Typography> */}
      </Box>
      <Box className={classes.container}>
        <Box className={[classes.table, classes[table.shape]].join(' ')} />
      </Box>
    </Box>
  );
};

export default TableItem;
