/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import typy from 'typy';
import { TranslateTextComponent } from '../../../../translator';

const textTranslate = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

const getChargeModalColumns = ({ onPayMasterCheck, selectedPayMasterUUID }) => {
  return [
    {
      id: 'checkbox',
      disablePadding: false,
      width: '5%',
      label: '',
      isSortable: false,
      align: 'center',
      render: (item) => {
        return (
          <input
            id={item.uuid}
            className="pm-checkbox"
            type="checkbox"
            value={item.uuid === selectedPayMasterUUID ? 'on' : 'off'}
            disabled={item.type === 'group'}
            onChange={() => onPayMasterCheck(item.uuid)}
          />
        );
      }
    },
    {
      id: 'pm-name',
      disablePadding: false,
      width: '25%',
      label: textTranslate('pm-name'),
      isSortable: false,
      align: 'center',
      render: (item) => typy(item, 'name').safeString
    },
    {
      id: 'holder',
      disablePadding: false,
      width: '25%',
      label: textTranslate('holder'),
      isSortable: false,
      render: (item) =>
        typy(item, 'guest.firstname').safeString
          ? typy(item, 'guest.firstname').safeString + ' ' + typy(item, 'guest.lastname').safeString
          : typy(item, 'guest.company').safeString
    },
    {
      id: 'pm-type',
      disablePadding: false,
      width: '25%',
      label: textTranslate('pm-type'),
      isSortable: false,
      align: 'center',
      render: (item) => (typy(item, 'type').safeString ? item.type.charAt(0).toUpperCase() + item.type.substring(1) : '')
    },
    {
      id: 'duration',
      disablePadding: false,
      width: '25%',
      label: textTranslate('expiry'),
      isSortable: false,
      align: 'center',
      render: (item) => (!item.expirationDate ? <TranslateTextComponent capitalize>undefined</TranslateTextComponent> : item.expirationDate)
    }
  ];
};

export default getChargeModalColumns;
