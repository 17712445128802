/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const ADD_FB_SEGMENT_MUTATION = gql`
  mutation addFbSegment($fbOutletUUID: String!, $fbSegmentData: FbSegmentsInput!) {
    addFbSegment(fbOutletUUID: $fbOutletUUID, fbSegmentData: $fbSegmentData) {
      id
      uuid
      name
      description
      startDate
      endDate
      isActive
    }
  }
`;

export const DELETE_FB_SEGMENT_MUTATION = gql`
  mutation deleteFbSegment($fbSegmentUUID: String!) {
    deleteFbSegment(fbSegmentUUID: $fbSegmentUUID)
  }
`;

export const EDIT_FB_SEGMENT_MUTATION = gql`
  mutation editFbSegment($fbSegmentUUID: String!, $fbSegmentData: FbSegmentsEditInput!) {
    editFbSegment(fbSegmentUUID: $fbSegmentUUID, fbSegmentData: $fbSegmentData) {
      id
      uuid
      name
      description
      startDate
      endDate
      isActive
      fbSegmentsServices {
        days
        fbService {
          id
          uuid
          name
          startTime
          endTime
        }
      }
    }
  }
`;
