import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from '../theme';

const leftSidebarLayoutStyles = {
  layoutContainer: () => ({
    display: 'flex',
    padding: '15px 0px 0px 15px',
    background: 'white',
    borderTopLeftRadius: 15,
    width: '100%',
  }),
  leftSide: () => ({
    paddingRight: 10,
    width: 320,
    borderRight: `2px solid ${primaryColor}`,
  }),
  rightSide: ({ position }) => ({
    position,
    flex: 2,
  }),
};

export default makeStyles(leftSidebarLayoutStyles);
