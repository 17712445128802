import React from 'react';
import Layout from '../components/shared/Layout';
import CheckInList from '../components/users/CheckInList';

const CheckInListPage = ({ history }) => {
  return (
    <Layout history={history} section="check-in-list">
      <CheckInList />
    </Layout>
  );
};

export default CheckInListPage;
