import { makeStyles } from '@material-ui/core/styles';
import { primaryColor, mainTextColor, borderTheme, secondaryColor } from '../theme';
import FB_ORDER_STATUSES from '../../constants/order/orderStatuses';

const styles = {
  // FbCourseStatusIcon.js style

  courseIconContainer: () => ({
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 10,
    boxShadow: '0px 3px 10px #0000004B'
  }),

  iconSize: (props) => ({
    width: props.width || 32,
    height: props.height || 24,
    marginRight: props.marginRight || 0
  }),

  statusFilledIcon: (props) => ({
    fill: props && props.status && FB_ORDER_STATUSES[props.status].color,
    '& .filled': { fill: props && props.status && FB_ORDER_STATUSES[props.status].color }
  }),

  changeStatusIcon: () => ({
    display: 'flex',
    alignItems: 'center'
  }),

  // Order.js style

  mainContentContainer: () => ({
    // overflowY: 'auto',
    backgroundColor: 'white',
    paddingTop: 25,
    paddingLeft: 30,
    borderTopLeftRadius: 15,
    width: '100%',
    height: '91vh'
  }),

  tableContainer: () => ({
    position: 'relative',
    border: borderTheme,
    height: 280,
    borderRadius: 10,
    marginRight: 30,
    marginBottom: 25,
    width: '31%',
    display: 'flex',
    flexDirection: 'column',
    '& a': {
      textDecoration: 'none'
    }
  }),

  foodAndDrinks: () => ({
    overflowY: 'auto',
    flex: 1,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    // Change the color of Chrome scrollBar
    '&::-webkit-scrollbar': {
      width: 15
    }
  }),

  coursesTableHeader: () => ({
    minHeight: 30,
    backgroundColor: primaryColor,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'white',
    paddingLeft: 15,
    paddingRight: 15
  }),

  coursesTableGridHeader: () => ({
    minHeight: 30,
    backgroundColor: primaryColor,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'white',
    paddingLeft: 15,
    paddingRight: 15
  }),

  tableHeaderTextBold: () => ({
    fontWeight: 'bold',
    fontSize: 20
  }),
  tableHeaderText: () => ({
    fontWeight: 'normal',
    fontSize: 20
  }),

  // FbCourse.js style

  courseNumberContainer: () => ({
    borderBottom: `1.5px solid #B4DCED`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  }),

  courseQuantityContainer: () => ({
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    paddingLeft: 5,
    color: mainTextColor
  }),

  courseNameContainer: () => ({
    minHeight: 55,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    color: mainTextColor
  }),

  courseDetailedNumber: () => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    fontSize: 10
  }),

  // OrderIconComponent.js style

  detailedCourseIcon: () => ({
    borderRadius: '50%',
    backgroundColor: primaryColor,
    width: 16,
    height: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),

  detailedCourseText: () => ({
    color: 'white',
    fontSize: 9
  }),

  // DetailedOrder.js style

  detailedTableContainer: () => ({
    overflowY: 'auto',
    // Change the color of Chrome scrollBar
    '&::-webkit-scrollbar': {
      width: 15
    }
  }),

  courseTableContainer: () => ({
    border: borderTheme,
    height: 80,
    borderRadius: 10,
    marginBottom: 25,
    width: '100%'
  }),

  detailedHeaderContainer: () => ({
    minHeight: 40,
    width: '100%',
    backgroundColor: primaryColor,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15
  }),

  detailedHeaderInfo: () => ({
    fontWeight: 'bold',
    fontSize: 15,
    color: 'white'
  }),

  smallCourseImageContainer: () => ({
    overflowX: 'auto',
    height: 36,
    paddingTop: 2,
    alignItems: 'center',
    paddingLeft: 5,
    whiteSpace: 'nowrap',
    /* [5] */
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }),

  showOrderButton: () => ({
    justifyContent: 'center',
    paddingTop: 30,
    paddingRight: 15
  }),

  // DetailedSummaryOrder.js style

  tableInfoHeader: () => ({
    paddingRight: 16,
    marginLeft: 9,
    width: '99.1%'
  }),

  tableInfoTypo: () => ({
    fontWeight: 'bold',
    fontSize: 20,
    color: 'white'
  }),

  courseTableHeader: () => ({
    height: 40,
    backgroundColor: secondaryColor,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1.5px solid #B4DCED`
  }),

  tableHeaderTypo: () => ({
    fontSize: 16,
    fontWeight: 'bold',
    color: mainTextColor
  }),

  tableRowContainer: () => ({
    minHeight: 70,
    borderBottom: `1.5px solid #B4DCED`,
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row'
  }),

  courseStatusContainer: () => ({
    width: '10%',
    height: '100%',
    fontSize: 16,
    color: mainTextColor,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingLeft: 15,
    position: 'relative'
  }),

  courseNumberTypo: () => ({
    fontWeight: 'bold',
    paddingLeft: 10,
    color: mainTextColor
  }),

  detailedCourseRow: () => ({
    width: '90%',
    flexDirection: 'column'
  }),

  detailedCourseInfoContainer: () => ({
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    minHeight: 70,
    alignItems: 'center'
  }),

  detailedCourseInfo: () => ({
    fontSize: 16,
    color: mainTextColor
  }),

  orderIconContainer: () => ({
    width: '17%',
    fontSize: 16,
    color: mainTextColor,
    display: 'flex',
    flexDirection: 'row'
  }),

  orderIconPosition: () => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    fontSize: 10
  }),

  imgStyle: () => ({
    height: 15,
    marginRight: 10,
    '& path': {
      fill: 'white'
    }
  }),

  dishQuantity: () => ({
    fontSize: 10,
    paddingLeft: 5,
    color: mainTextColor
  })
};
export default makeStyles(styles);
