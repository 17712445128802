import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from '../../theme';

const styles = {
  container: {
    '& button:not(:last-child)': {
      marginRight: 10
    }
  },
  tabItem: ({ selected }) => ({
    backgroundColor: selected ? primaryColor : '#EDFAFF',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    color: selected ? 'white' : '#3399CC',
    fontSize: 20,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    width: '50%',
    maxWidth: 'unset',
    '& div:last-child': {
      '& div:last-child': {
        height: 30,
        width: 35
      }
    }
  })
};

export default makeStyles(styles);
