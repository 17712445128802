import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import typy from 'typy';
import arrowDownIcon from '../../assets/images/cash/arrow_down.svg';
import styles from '../../styles/cash/totalKeyboardStyle';
import { TranslateTextComponent } from '../../translator';
import Keyboard from '../shared/Keyboard';
import { MaterialIcon, redButton } from '../../common-fe/src';

const TotalKeyboard = ({
  total,
  showKeyboard,
  keyboardLabel,
  activeBillTabUUID,
  orderBills,
  openDiscountModal,
  onDeleteDiscount,
  onDeleteDiscountPercentage,
  closeKeyboard,
  onSubmit
}) => {
  const classes = styles();
  const [value, setValue] = useState('');
  const [currentOrderBill, setCurrentOrderBill] = useState({});

  const onKeyboardValueChangeHandler = (v) => {
    switch (v) {
      case 0:
        if (value.length <= 0) setValue((prev) => prev + '0.');
        else setValue((prev) => prev + 0);
        break;
      case 1:
        setValue((prev) => prev + 1);
        break;
      case 2:
        setValue((prev) => prev + 2);
        break;
      case 3:
        setValue((prev) => prev + 3);
        break;
      case 4:
        setValue((prev) => prev + 4);
        break;
      case 5:
        setValue((prev) => prev + 5);
        break;
      case 6:
        setValue((prev) => prev + 6);
        break;
      case 7:
        setValue((prev) => prev + 7);
        break;
      case 8:
        setValue((prev) => prev + 8);
        break;
      case 9:
        setValue((prev) => prev + 9);

        break;
      case 'tax-code':
        break;
      case 'dot':
        if (!value.includes('.') && value.length > 0) setValue((prev) => prev + '.');
        if (value === '') setValue((prev) => prev + '0.');
        break;
      case 'backspace':
        if (value === '0.') setValue('');
        else setValue((prev) => prev.substring(0, prev.length > 0 ? prev.length - 1 : 0));
        break;
      case 'cancel':
        setValue('');
        break;
      case 'return':
        onSubmit(value);
        setValue('');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const order = typy(orderBills).safeArray.find((orderBill) => orderBill.uuid === activeBillTabUUID) || {};
    if (Object.keys(order).length > 0) {
      setCurrentOrderBill({ ...order });
    }
  }, [orderBills, activeBillTabUUID]);

  return (
    <Box>
      {showKeyboard && (
        <>
          {/* <CustomButton
            style={{
              display: 'flex',
              margin: '0 auto',
              width: '95%',
              opacity: 0.2,
              background: '#FFFFFF 0% 0% no-repeat padding-box',
              borderRadius: 9,
              border: '1px solid #00B7FF',
              color: '#00B7FF'
            }}
            disabled={disableOrderButton}
          >
            <TranslateTextComponent uppercase>submit-order</TranslateTextComponent>
          </CustomButton> */}
          <div
            style={{ cursor: `url(${arrowDownIcon}) 10 10, auto`, height: 15 }}
            onClick={() => {
              closeKeyboard();
              setValue('');
            }}
            onKeyDown={() => {
              closeKeyboard();
              setValue('');
            }}
            role="button"
            tabIndex="0"
          />
        </>
      )}
      <Grid container className={classes.container}>
        {!showKeyboard && currentOrderBill && currentOrderBill.discountPercentage && (
          <Grid className={classes.discountArea} item xs={12}>
            <Box style={{ gridArea: 'title' }}>
              <TranslateTextComponent capitalize style={{ fontWeight: 'bold' }}>
                discount%
              </TranslateTextComponent>
              {currentOrderBill.discountPercentageNotes ? (
                <span style={{ fontStyle: 'italic' }}> ({currentOrderBill.discountPercentageNotes}):</span>
              ) : (
                ':'
              )}
            </Box>
            <Box style={{ gridArea: 'discount' }}>{currentOrderBill.discountPercentage}</Box>
            <Box style={{ gridArea: 'buttons', display: 'flex', justifyContent: 'flex-end' }}>
              <MaterialIcon
                iconName="Create"
                style={{ cursor: 'pointer', margin: '0 5px', color: 'yellow' }}
                onClick={() => openDiscountModal('percentage')}
              />
              <MaterialIcon iconName="Close" style={{ cursor: 'pointer', margin: '0 5px', color: redButton }} onClick={onDeleteDiscountPercentage} />
            </Box>
          </Grid>
        )}
        {!showKeyboard && currentOrderBill && currentOrderBill.discount && (
          <Grid className={classes.discountArea} item xs={12}>
            <Box style={{ gridArea: 'title' }}>
              <TranslateTextComponent capitalize style={{ fontWeight: 'bold' }}>
                discount€
              </TranslateTextComponent>
              {currentOrderBill.discountNotes ? <span style={{ fontStyle: 'italic' }}> ({currentOrderBill.discountNotes}):</span> : ':'}
            </Box>
            <Box style={{ gridArea: 'discount' }}>{currentOrderBill.discount}</Box>
            <Box style={{ gridArea: 'buttons', display: 'flex', justifyContent: 'flex-end' }}>
              <MaterialIcon
                iconName="Create"
                style={{ cursor: 'pointer', margin: '0 5px', color: 'yellow' }}
                onClick={() => openDiscountModal('money')}
              />
              <MaterialIcon iconName="Close" style={{ cursor: 'pointer', margin: '0 5px', color: redButton }} onClick={onDeleteDiscount} />
            </Box>
          </Grid>
        )}
        <Grid className={classes.titleArea} item xs={8}>
          {showKeyboard ? (
            <TranslateTextComponent capitalize>{keyboardLabel === 'quantity' ? 'set-quantity' : 'set-price'}</TranslateTextComponent>
          ) : (
            <TranslateTextComponent capitalize>total</TranslateTextComponent>
          )}
        </Grid>
        <Grid className={classes.priceArea} item xs={4}>
          {!showKeyboard && (total || total === 0)
            ? `€ ${typy(total).safeNumber.toFixed(2).toString().replace('.', ',')}`
            : !showKeyboard
            ? '-'
            : null}
          {showKeyboard && value.replace('.', ',')}
        </Grid>
      </Grid>
      {showKeyboard && <Keyboard onClick={onKeyboardValueChangeHandler} />}
    </Box>
  );
};

export default TotalKeyboard;
