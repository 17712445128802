/* eslint-disable import/prefer-default-export */
import React from 'react';
import { TranslateTextComponent } from '../../translator';

const translateText = (text) => {
  return <TranslateTextComponent capitalize>{text}</TranslateTextComponent>;
};

export const DAYS = [
  { id: 1, label: translateText('monday'), shortLabel: translateText('mon') },
  { id: 2, label: translateText('tuesday'), shortLabel: translateText('tue') },
  { id: 3, label: translateText('wednesday'), shortLabel: translateText('wed') },
  { id: 4, label: translateText('thursday'), shortLabel: translateText('thu') },
  { id: 5, label: translateText('friday'), shortLabel: translateText('fri') },
  { id: 6, label: translateText('saturday'), shortLabel: translateText('sat') },
  { id: 0, label: translateText('sunday'), shortLabel: translateText('sun') }
];
