import gql from 'graphql-tag';

export const ADD_MENU_ITEM = gql`
  mutation addFbMenuItem($fbMenuCategoryUUID: String!, $fbMenuItemData: FbMenuItemInput!) {
    addFbMenuItem(fbMenuCategoryUUID: $fbMenuCategoryUUID, fbMenuItemData: $fbMenuItemData) {
      id
      uuid
      name
      price
      description
      images {
        uuid
        path
        name
        isPrimary
      }
    }
  }
`;

export const EDIT_MENU_ITEM = gql`
  mutation editMenuItem($fbMenuItemUUID: String!, $fbMenuCategoryUUID: String, $fbMenuItemData: FbMenuItemInput!) {
    editFbMenuItem(fbMenuItemUUID: $fbMenuItemUUID, fbMenuCategoryUUID: $fbMenuCategoryUUID, fbMenuItemData: $fbMenuItemData) {
      id
      uuid
      fbMenuCategoryId
      name
      price
      description
      images {
        uuid
        path
        name
        isPrimary
      }
    }
  }
`;

export const ADD_MENU_CATEGORY = gql`
  mutation addFbMenuCategory($fbOutletUUID: String!, $fbMenuMacroCategoryUUID: String!, $fbMenuCategoryData: FbMenuCategoryInput!) {
    addFbMenuCategory(fbOutletUUID: $fbOutletUUID, fbMenuMacroCategoryUUID: $fbMenuMacroCategoryUUID, fbMenuCategoryData: $fbMenuCategoryData) {
      id
      uuid
      name
      description
    }
  }
`;

export const EDIT_MENU_CATEGORY = gql`
  mutation editFbMenuCategory($fbMenuCategoryUUID: String!, $fbMenuCategoryData: FbMenuCategoryInput!) {
    editFbMenuCategory(fbMenuCategoryUUID: $fbMenuCategoryUUID, fbMenuCategoryData: $fbMenuCategoryData) {
      id
      uuid
      name
      description
    }
  }
`;

export const DELETE_MENU_ITEM = gql`
  mutation deleteFbMenuItem($fbMenuItemUUID: String!) {
    deleteFbMenuItem(fbMenuItemUUID: $fbMenuItemUUID)
  }
`;

export const DELETE_MENU_CATEGORY = gql`
  mutation deleteFbMenuCategory($fbMenuCategoryUUID: String!) {
    deleteFbMenuCategory(fbMenuCategoryUUID: $fbMenuCategoryUUID)
  }
`;
