import React from 'react';
import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, CircularProgress } from '@material-ui/core';
import { CustomModal, Dropdown } from '../../../common-fe/src';
import InputWithLabel from '../../shared/InputWithLabel';
import { TranslateTextComponent } from '../../../translator';
import Buttons from '../../shared/Buttons';
import MultiSelect from '../../shared/MultiSelect';
import styles from '../../../styles/settings/tabs';
import useCustomQuery from '../../../hooks/useCustomQuery';
import { GET_FB_OUTLETS } from '../../../graphql/outlets/queries';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;

  & > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const RowContainer = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 20px;
  }
`;

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  role: Yup.string().required('Required'),
  fbOutletUUIDs: Yup.array().required('Required')
});

const StaffUserModal = ({ data, showModal, roles, loading, closeModal, addNewUser, updateUser }) => {
  const classes = styles();

  // Queries
  const getFbOutlets = useCustomQuery(GET_FB_OUTLETS);

  const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleReset, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      firstName: data?.firstName ?? '',
      lastName: data?.lastName ?? '',
      email: data?.email ?? '',
      role: data?.roles?.[0]?.name ?? '',
      fbOutletUUIDs: data?.outlets?.map((o) => ({ label: o.name, value: o.uuid })) ?? []
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (data !== null) updateUser(values);
      else addNewUser(values);
      setSubmitting(false);
    }
  });

  return (
    <CustomModal
      styledHeader
      height="auto"
      width={857}
      stylePaper
      header={<TranslateTextComponent uppercase>new-user</TranslateTextComponent>}
      styleBody={{ padding: '10px 60px' }}
      showModal={showModal}
      onClose={() => {
        closeModal();
        resetForm();
      }}
    >
      <Container>
        <RowContainer>
          <InputWithLabel label="first-name" error={touched.firstName && errors.firstName}>
            <TextField
              name="firstName"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.firstName}
              error={touched.firstName && !!errors.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
          <InputWithLabel label="last-name" error={touched.lastName && errors.lastName}>
            <TextField
              name="lastName"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.lastName}
              error={touched.lastName && !!errors.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </InputWithLabel>
        </RowContainer>
        <RowContainer>
          <InputWithLabel label="email" error={touched.email && errors.email}>
            <TextField
              name="email"
              fullWidth
              variant="outlined"
              color="primary"
              value={values.email}
              error={touched.email && !!errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={data}
            />
          </InputWithLabel>
          <InputWithLabel label="role" error={touched.role && errors.role}>
            <Dropdown
              dropdownStyle={{ width: '100%', minHeight: 40 }}
              items={roles.map((role) => ({ label: role.description?.replace('role', ''), value: role.name }))}
              value={values.role}
              error={touched.role && !!errors.role}
              onChange={({ target: { value } }) => setFieldValue('role', value)}
            />
          </InputWithLabel>
        </RowContainer>
        <RowContainer>
          <InputWithLabel label="outlets" error={touched.fbOutletUUIDs && errors.fbOutletUUIDs}>
            <MultiSelect
              className={classes.dropdown}
              loading={getFbOutlets.loading}
              loadingText={<CircularProgress />}
              options={getFbOutlets.data?.getFbOutlets?.data?.map((outlet) => ({ label: outlet.name, value: outlet.uuid })) ?? []}
              value={values.fbOutletUUIDs}
              getOptionSelected={(option, { value }) => option.value === value}
              onChange={(e, value) => setFieldValue('fbOutletUUIDs', value)}
            />
          </InputWithLabel>
        </RowContainer>
        <Buttons
          buttons={[
            {
              buttonType: 'reset',
              type: 'cancel',
              disabled: loading,
              style: {
                textTransform: 'uppercase',
                minWidth: 125,
                minHeight: 40,
                fontSize: 18,
                fontWeight: 500
              },
              onClick: handleReset
            },
            {
              buttonType: 'submit',
              type: 'confirm',
              label: 'save',
              disabled: loading || isSubmitting,
              isLoading: loading,
              style: {
                textTransform: 'uppercase',
                minWidth: 125,
                minHeight: 40,
                fontSize: 18,
                fontWeight: 500
              },
              onClick: handleSubmit
            }
          ]}
        />
      </Container>
    </CustomModal>
  );
};

export default StaffUserModal;
