import { makeStyles } from '@material-ui/core/styles';
import { primaryColor, borderTheme } from '../theme';

const styles = {
  customButton: () => ({
    color: 'white',
    width: '100%',
    height: 50,
    fontSize: 20,
    fontFamily: 'Roboto',
    borderRadius: 9
  })
};

export default makeStyles(styles);
