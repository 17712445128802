import React, { useState, useContext, useEffect, useRef } from 'react';
import { t } from 'typy';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, Typography, TextField, Button, ButtonBase, Chip } from '@material-ui/core/';
import { OrderContext, initialState } from '../../contexts/OrderContext';
import { LanguageContext } from '../../contexts/LanguageContext';
import { TranslateTextComponent, translate, capitalizeFirstLetter } from '../../translator';
import { ReactComponent as MaleImg } from '../../assets/images/order/male.svg';
import { ReactComponent as FemaleImg } from '../../assets/images/order/female.svg';
import styles from '../../styles/order/chairsBox';
import { primaryColor, invertedPrimary, greenButton, redButton, hoverGreenButton, hoverRedButton } from '../../styles/theme';
import ChairCarousel from './ChairCarousel';
import { SEX_CODES } from '../shared/ChairItem';
import { suggestionsIngredients } from '../../fixtures/order/order';

const ID_BUTTON_WITHOUT = 'redButton';
const ID_BUTTON_WITH = 'greenButton';

const initialStateFbChair = {
  guest: null,
  guestFbReservation: null
};

const ChairOrder = ({ dish }) => {
  const classes = styles({ mainColor: primaryColor, defaultTextColor: 'white', hoverColor: invertedPrimary });
  const cancelButton = styles({ mainColor: redButton, defaultTextColor: 'white', hoverColor: hoverRedButton });
  const saveButton = styles({ mainColor: greenButton, defaultTextColor: 'white', hoverColor: hoverGreenButton });

  const withoutButton = styles({ mainColor: redButton, hoverColor: hoverRedButton });
  const withButton = styles({ mainColor: greenButton, hoverColor: hoverGreenButton });

  const orderContext = useContext(OrderContext);

  const { fbReservationTable } = orderContext;
  const fbChairs = t(fbReservationTable, 'fbTable.fbChairs').safeObject;

  fbChairs.sort((prev, next) => prev.number - next.number);

  const [activeChairIndex, setActiveChairIndex] = useState(0);
  const [activeSharedIndexes, setActiveSharedIndexes] = useState(0);

  const [fbChair, setFbChair] = useState(initialStateFbChair);

  const [errorMessage, setErrorMessage] = useState(null);

  const [isCenterPieceSharedClicked, setIsCenterPieceSharedClicked] = useState(null);

  const chairOrder = orderContext && orderContext.chairOrder;

  const [withWithoutButton, setWithWithoutButton] = useState(null);

  const [withIngredients, setWithIngredients] = useState(chairOrder.with.map((ingr) => ({ label: ingr, color: greenButton, type: 'with' })));
  const [withoutIngredients, setWithoutIngredients] = useState(
    chairOrder.without.map((ingr) => ({ label: ingr, color: redButton, type: 'without' }))
  );

  const languageContext = useContext(LanguageContext);

  const textInputIngredientsRef = useRef(null);

  useEffect(() => {
    const { id, name, fbMenuCategoryId } = dish;
    const updateFbMenuItem = {
      id,
      fbMenuCategoryId
    };
    if (chairOrder.sharedBy.length > 1) {
      setIsCenterPieceSharedClicked(true);
    }

    if (chairOrder.fbChair && (chairOrder.fbChair.guest || chairOrder.fbChair.guestFbReservation)) {
      const updateGuest = { ...chairOrder.fbChair.guest };
      const updateGuestFbReservation = { ...chairOrder.fbChair.guestFbReservation };

      setFbChair({ ...fbChair, guest: updateGuest, guestFbReservation: updateGuestFbReservation });
    } else {
      setFbChair({ ...fbChair, guest: null, guestFbReservation: null });
    }

    orderContext.updateChairOrder({
      ...chairOrder,
      fbMenuItemId: id,
      name,
      fbMenuItem: updateFbMenuItem
    });
  }, [chairOrder.id]);

  const onCancelClick = () => {
    const updateFbMenuItem = { ...initialState.fbMenuItem };
    orderContext.updateChairOrder({ ...initialState, fbMenuItem: updateFbMenuItem });
    orderContext.updateSelectedDish(null);
  };

  const onSaveNewItemClick = () => {
    if (chairOrder.sharedBy) chairOrder.sharedBy.sort((prev, next) => prev - next);
    else chairOrder.sharedBy = [];
    chairOrder.with = withIngredients.map((ingredient) => ingredient.label);
    chairOrder.without = withoutIngredients.map((ingredient) => ingredient.label);
    if (chairOrder.fbChairId || chairOrder.centerpiece || chairOrder.sharedBy.length > 1) {
      if (orderContext.isEditingCourse) {
        orderContext.editFbCourse(chairOrder);
      } else {
        orderContext.addFbMenuItem(chairOrder);
      }

      const previousChairOrder = { ...chairOrder };
      orderContext.updateChairOrder({
        ...orderContext.chairOrder,
        quantity: 1,
        fbChairId: null,
        fbChairNumber: null,
        name: previousChairOrder.name,
        fbMenuItemId: previousChairOrder.fbMenuItemId,
        centerpiece: true,
        halfPortion: false,
        sharedBy: [],
        with: [],
        without: [],
        notes: ''
      });
      setWithIngredients([]);
      setWithoutIngredients([]);
      setWithWithoutButton(null);
      setIsCenterPieceSharedClicked(false);
      setErrorMessage(null);
    } else {
      setErrorMessage('save-chair-error');
    }
  };

  const handleWithWithoutButton = (id) => {
    if (withWithoutButton === id) {
      setWithWithoutButton(false);
    } else {
      setTimeout(() => {
        textInputIngredientsRef.current.focus();
      }, 100);
      setWithWithoutButton(id);
    }
  };

  const onAddIngredient = (ingredient) => {
    if (!withIngredients.find((ingr) => ingr.label === ingredient) && !withoutIngredients.find((ingr) => ingr.label === ingredient)) {
      if (withWithoutButton === ID_BUTTON_WITH) {
        setWithIngredients([...withIngredients, { label: ingredient, color: greenButton, type: 'with' }]);
      }
      if (withWithoutButton === ID_BUTTON_WITHOUT) {
        setWithoutIngredients([...withoutIngredients, { label: ingredient, color: redButton, type: 'without' }]);
      }
    }
  };

  const onRemoveIngredient = (ingredient) => {
    if (ingredient.type === 'with') {
      setWithIngredients(withIngredients.filter((ingr) => ingr.label !== ingredient.label));
    }
    if (ingredient.type === 'without') {
      setWithoutIngredients(withoutIngredients.filter((ingr) => ingr.label !== ingredient.label));
    }
  };

  const handleButtonCenterPiece = () => {
    setIsCenterPieceSharedClicked(false);

    orderContext.updateChairOrder({
      ...chairOrder,
      centerpiece: !chairOrder.centerpiece,
      guestFbReservationId: null,
      sharedBy: [],
      // halfPortion: false,
      fbChairId: null,
      fbChairNumber: null
    });
  };

  const handleButtonCenterPieceShared = () => {
    let updatedSharedBy = [...chairOrder.sharedBy];

    if (
      (chairOrder.fbChairNumber && !updatedSharedBy.includes(chairOrder.fbChairNumber) && updatedSharedBy.length === 0) ||
      updatedSharedBy.length === 1
    ) {
      updatedSharedBy = [];
      updatedSharedBy.push(chairOrder.fbChairNumber);
    }
    setIsCenterPieceSharedClicked((prevState) => !prevState);

    orderContext.updateChairOrder({ ...chairOrder, centerpiece: false, halfPortion: false, sharedBy: updatedSharedBy });
  };

  const handleButtonHalfPortion = () => {
    orderContext.updateChairOrder({ ...chairOrder, halfPortion: !chairOrder.halfPortion });
  };

  const handleSelectedChair = (chair) => {
    const updatedChairOrder = { ...chairOrder };
    let updateGuest = { ...fbChair.guest };
    let updateGuestFbReservation = { ...fbChair.guestFbReservation };
    if (chairOrder.fbChairNumber === chair.number) {
      updatedChairOrder.fbChairId = null;
      updatedChairOrder.fbChairUUID = null;
      updatedChairOrder.fbChairNumber = null;
      updateGuest = null;
      updateGuestFbReservation = null;
    } else {
      const chairFromId = orderContext.getChairFromId(chair.id);
      updatedChairOrder.fbChairId = chair.id;
      updatedChairOrder.fbChairUUID = chair.uuid;
      updatedChairOrder.fbChairNumber = chair.number;
      if (chairFromId.guest) {
        updateGuest.firstname = chairFromId.guest.firstname;
        updateGuest.lastname = chairFromId.guest.lastname;
        updateGuest.gender = chairFromId.guest.gender;
        updateGuest.id = chairFromId.guest.id;

        updateGuestFbReservation = null;
      } else if (chairFromId.guestFbReservation) {
        updateGuestFbReservation.gender = chairFromId.guestFbReservation.gender;
        updateGuestFbReservation.guestPhysicalCategoryId = chairFromId.guestFbReservation.guestPhysicalCategoryId;
        updateGuestFbReservation.id = chairFromId.guestFbReservation.id;

        updateGuest = null;
      } else {
        updateGuestFbReservation.gender = null;
        updateGuestFbReservation.guestPhysicalCategoryId = null;
        updateGuestFbReservation.id = null;

        updateGuest = null;
      }
    }
    setIsCenterPieceSharedClicked(false);
    setFbChair({ ...fbChair, guest: updateGuest, guestFbReservation: updateGuestFbReservation });
    orderContext.updateChairOrder({
      ...updatedChairOrder,
      centerpiece: false,
      quantity: chairOrder.quantity,
      sharedBy: []
    });
  };

  const handleSharedChairs = (chair) => {
    const chairIndex = chairOrder.sharedBy.findIndex((chairNumber) => chair.number === chairNumber);
    const newSharedChairs = [...chairOrder.sharedBy];

    const updatedChairOrder = { ...chairOrder };
    if (chairIndex > -1) {
      newSharedChairs.splice(chairIndex, 1);
    } else {
      newSharedChairs.push(chair.number);
    }

    if (newSharedChairs.length > 1 || newSharedChairs[chairIndex] === chairOrder.fbChairNumber) {
      updatedChairOrder.fbChairNumber = null;
    }
    orderContext.updateChairOrder({ ...updatedChairOrder, sharedBy: newSharedChairs });
  };

  const updateChairOrder = (key, value) => {
    orderContext.updateChairOrder({ ...chairOrder, [key]: value });
  };

  const fbChairsCarousel = fbChairs.map((chair) => {
    return {
      ...chair,
      isSelected: chairOrder && chairOrder.fbChairNumber && chairOrder.fbChairNumber === chair.number
    };
  });

  const fbChairsSharedCarousel = fbChairs.map((chair) => {
    return {
      ...chair,
      isSelected: chairOrder.sharedBy && chairOrder.sharedBy.includes(chair.number)
    };
  });

  const getGenderIcon = () => {
    if (fbChair.guest?.gender === SEX_CODES.male || fbChair.gender === SEX_CODES.male) {
      return <MaleImg style={{ height: 20, width: 20 }} />;
    }
    if ((fbChair.guest && fbChair.guest.gender === SEX_CODES.female) || fbChair.gender === SEX_CODES.female) {
      return <FemaleImg style={{ height: 20, width: 20 }} />;
    }
    return null;
  };

  return (
    <Grid container direction="column" justify="space-between" className={classes.chairsBox} onClick={(e) => e.stopPropagation()}>
      <Grid className={classes.chairsRow}>
        <Typography className={classes.dishName}>{chairOrder.name}</Typography>
      </Grid>
      <Grid className={classes.chairsRow} style={{ paddingLeft: 20, paddingRight: 20, width: 'inherit' }}>
        <ChairCarousel
          fbChairs={fbChairsCarousel}
          setActiveItemIndex={setActiveChairIndex}
          activeItemIndex={activeChairIndex}
          handleSelectedChair={(c) => handleSelectedChair(c)}
          chairOrder={chairOrder}
        />
      </Grid>

      <Grid container justify="center" alignItems="center" className={classes.chairsRow}>
        <Grid item xs={3}>
          <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
            <TranslateTextComponent capitalize>quantity</TranslateTextComponent>
          </Typography>
        </Grid>
        <Grid item style={{ display: 'flex', marginLeft: 10 }}>
          <ButtonBase
            className={[classes.counterButton, classes.counterButtonSub].join(' ')}
            onClick={() => updateChairOrder('quantity', chairOrder.quantity - 1)}
          >
            <Typography className={[classes.counterButtonTypography, classes.counterButtonSubTypography].join(' ')}>-</Typography>
          </ButtonBase>
          <Typography className={classes.counterTypography}>
            {chairOrder && chairOrder.quantity < 1 ? updateChairOrder('quantity', 1) : chairOrder.quantity}
          </Typography>
          <ButtonBase
            className={[classes.counterButton, classes.counterButtonAdd].join(' ')}
            onClick={() => updateChairOrder('quantity', chairOrder.quantity + 1)}
          >
            <Typography className={[classes.counterButtonTypography, classes.counterButtonAddTypography].join(' ')}>+</Typography>
          </ButtonBase>
        </Grid>
      </Grid>
      {fbChair.guest && (
        <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 15, marginBottom: 10 }}>
          <Typography style={{ fontSize: 12, paddingRight: 10 }}>
            {fbChair.guest.firstname} {fbChair.guest.lastname}
          </Typography>
          {getGenderIcon()}
        </Grid>
      )}
      <Grid container alignItems="center" justify="center" className={classes.buttonRow}>
        <Grid container direction="row">
          <Grid container alignItems="center" justify="center" item xs={12} style={{ marginBottom: 10 }}>
            <Button
              variant="contained"
              color="primary"
              className={chairOrder.centerpiece ? [classes.button, 'selected'].join(' ') : classes.button}
              onClick={() => handleButtonCenterPiece()}
            >
              <TranslateTextComponent capitalize>centerpiece</TranslateTextComponent>
            </Button>
          </Grid>

          <Grid container justify="space-between" spacing={1}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                className={isCenterPieceSharedClicked ? [classes.button, 'selected'].join(' ') : classes.button}
                style={{ fontSize: 12 }}
                onClick={() => handleButtonCenterPieceShared()}
              >
                <TranslateTextComponent capitalize>centerpiece/2</TranslateTextComponent>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                className={chairOrder.halfPortion ? [classes.button, 'selected'].join(' ') : classes.button}
                style={{ fontSize: 12 }}
                onClick={() => handleButtonHalfPortion()}
              >
                <TranslateTextComponent capitalize>half-portion</TranslateTextComponent>
              </Button>
            </Grid>
          </Grid>
          {isCenterPieceSharedClicked && (
            <Grid style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10, width: 'inherit' }}>
              <ChairCarousel
                fbChairs={fbChairsSharedCarousel}
                setActiveItemIndex={setActiveSharedIndexes}
                activeItemIndex={activeSharedIndexes}
                handleSelectedChair={(c) => handleSharedChairs(c)}
                chairOrder={chairOrder}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      {orderContext.fbMacroCategory === 'food' && (
        <Grid container justify="space-between" spacing={1} className={classes.buttonRow}>
          <Grid item xs={6} style={{ padding: '0px 4px 0px 0px' }}>
            <Button
              variant="contained"
              className={
                withWithoutButton === ID_BUTTON_WITHOUT
                  ? [withoutButton.withOrWithoutButton, 'selected'].join(' ')
                  : withoutButton.withOrWithoutButton
              }
              onClick={() => handleWithWithoutButton(ID_BUTTON_WITHOUT)}
            >
              <TranslateTextComponent uppercase>without</TranslateTextComponent>&nbsp;-
            </Button>
          </Grid>
          <Grid item xs={6} style={{ padding: '0px 0px 0px 4px' }}>
            <Button
              variant="contained"
              className={
                withWithoutButton === ID_BUTTON_WITH ? [withButton.withOrWithoutButton, 'selected'].join(' ') : withButton.withOrWithoutButton
              }
              onClick={() => handleWithWithoutButton(ID_BUTTON_WITH)}
            >
              <TranslateTextComponent uppercase>with</TranslateTextComponent>&nbsp;+
            </Button>
          </Grid>
          {withWithoutButton && (
            <Grid item xs={12} style={{ padding: 0 }}>
              <Autocomplete
                multiple
                freeSolo
                classes={{
                  paper: classes.autocompletePaper,
                  inputRoot: classes.autocompleteInputRoot
                }}
                id="tags-standard"
                options={suggestionsIngredients}
                getOptionLabel={(option) => option}
                value={[...withIngredients, ...withoutIngredients]}
                onChange={(e, v) => {
                  if (v.length > [...withIngredients, ...withoutIngredients].length && v[v.length - 1]) {
                    onAddIngredient(v[v.length - 1]);
                  }
                }}
                renderTags={(values) => {
                  return values.map((tag, index) => (
                    <Chip
                      key={index}
                      className={classes.chip}
                      label={tag.label}
                      onDelete={() => onRemoveIngredient(tag)}
                      style={{ backgroundColor: tag.color, color: 'white', height: 26, fontSize: 12 }}
                    />
                  ));
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      className="autocompleteTextField"
                      inputRef={textInputIngredientsRef}
                      {...params}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      style={{ minHeight: 50 }}
                    />
                  );
                }}
              />
            </Grid>
          )}
        </Grid>
      )}

      <Grid container justify="center" className={classes.buttonRow}>
        <TextField
          id="outlined-name"
          autoComplete="off"
          label={capitalizeFirstLetter(translate('notes', languageContext.language))}
          fullWidth
          style={{ margin: 15 }}
          value={chairOrder.notes}
          onChange={(e) => updateChairOrder('notes', e.target.value)}
          className={classes.notesTextField}
          margin="normal"
          variant="outlined"
        />
      </Grid>
      {errorMessage && (
        <Grid container justify="center" style={{ marginTop: 10 }}>
          <Typography className={classes.errorMessage}>
            <TranslateTextComponent capitalize>{errorMessage}</TranslateTextComponent>
          </Typography>
        </Grid>
      )}
      <Grid container justify="space-between" spacing={1} style={{ padding: 10 }}>
        <Grid item xs={6}>
          <Button variant="contained" className={cancelButton.button} onClick={() => onCancelClick()}>
            <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant="contained" className={saveButton.button} onClick={() => onSaveNewItemClick()}>
            <TranslateTextComponent uppercase>save</TranslateTextComponent>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChairOrder;
