import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Tabs, Tab, Button, CircularProgress } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import PageLayout from '../shared/PageLayout';
import styles from '../../styles/billsManagement/billsManagement';
import { LanguageContext } from '../../contexts/LanguageContext';
import { translate, TranslateTextComponent } from '../../translator';
import ProfileForm from './form/GuestProfileEditForms/ProfileForm';
import PersonalTraitsForm from './form/GuestProfileEditForms/PersonalTraitsForm';
import ExperientialForm from './form/GuestProfileEditForms/ExperientialForm';
import useCustomQuery from '../../hooks/useCustomQuery';
import { GET_PROFILE_DATA, GET_TAGS } from '../../graphql/user/queries';
import { SAVE_PROFILE_DATA } from '../../graphql/guests/mutations';
import { ModalContext } from '../../contexts/ModalContext';
import modalStyle from '../../styles/shared/modalStyle';
import { getApolloErrors } from '../../apollo/ApolloProvider';
import { SAVE_CHECKIN_PREFERENCES } from '../../graphql/user/mutations';
import PersonalDataForm from './form/GuestProfileEditForms/PersonalDataForm';
import { ROUTE_GUEST_PROFILES } from '../../router/routes';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: white;
  padding: 15px 15px 0px 15px;
`;

const TABS = {
  PROFILE_REGISTRY: 'PROFILE_REGISTRY',
  PERSONAL_TRAITS_PROFILE: 'PERSONAL_TRAITS_PROFILE',
  EXPERIENTIAL_PROFILE: 'EXPERIENTIAL_PROFILE',
  PERSONAL_DATA: 'PERSONAL_DATA'
};
const GuestProfile = ({ profileUUID }) => {
  const history = useHistory();
  const { language } = useContext(LanguageContext);
  const modalContext = useContext(ModalContext);

  const [currentTab, setCurrentTab] = useState(TABS.PROFILE_REGISTRY);
  const [formValues, setFormValues] = useState({});
  const [newProfileUUID, setNewProfileUUID] = useState(null);

  const classes = styles();
  const modalClasses = modalStyle();

  // Queries
  const getProfileData = useCustomQuery(GET_PROFILE_DATA, {
    variables: { profileUUID: profileUUID || newProfileUUID },
    skip: !profileUUID && !newProfileUUID
  });
  const getTags = useCustomQuery(GET_TAGS);

  // Mutations
  const [saveProfileData] = useMutation(SAVE_PROFILE_DATA);
  const [saveCheckinPreferences] = useMutation(SAVE_CHECKIN_PREFERENCES);

  /** Use only if it's an array of objects with label and value */
  const getValuesOnly = (array) => {
    if (array) {
      return array.map((item) => item.value);
    }

    return null;
  };

  const onSaveProfileData = (data) => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>edit-guest-profile-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>edit-guest-profile-description</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            modalContext.setModalLoading(true);
            const profileData = getProfileData?.data?.getProfileData;
            saveProfileData({
              variables: {
                profileUUID: profileUUID || newProfileUUID,
                profileData: {
                  firstName: data?.firstName ?? profileData?.firstName,
                  lastName: data?.lastName ?? profileData?.lastName,
                  fullName: data?.fullName ?? profileData?.fullName,
                  email: data?.email ?? profileData?.email,
                  sexCode: data?.sexCode ?? profileData?.sexCode,
                  // eslint-disable-next-line no-nested-ternary
                  dateOfBirth: data?.dateOfBirth
                    ? moment(data.dateOfBirth).format('YYYY-MM-DD')
                    : profileData?.dateOfBirth
                    ? moment(profileData?.dateOfBirth).format('YYYY-MM-DD')
                    : null,
                  languageCode: data?.languageCode ?? profileData?.language?.ISO639_1,
                  spokenLanguageCodes: getValuesOnly(data?.spokenLanguageCodes) ?? profileData?.spokenLanguages?.map((l) => l.ISO639_1),
                  bestAttention: data?.bestAttention ?? profileData?.bestAttention,
                  otherNotes: data?.otherNotes ?? profileData?.otherNotes,
                  notes: data?.notes ?? profileData?.notes,
                  addresses: data?.line1
                    ? {
                        id: profileData?.addresses?.[0]?.id,
                        line1: data?.line1 ?? profileData?.addresses?.[0]?.line1,
                        postalCode: data?.postalCode ?? profileData?.addresses?.[0]?.postalCode,
                        city: data?.city ?? profileData?.addresses?.[0]?.city,
                        province: data?.province ?? profileData?.addresses?.[0]?.province,
                        countryCode: data?.countryCode ?? profileData?.addresses?.[0]?.countryCode
                      }
                    : null,
                  maritalStatus: data?.maritalStatus ?? profileData?.maritalStatus,
                  phone: data?.phone ?? profileData?.phone,
                  mobilePhone: data?.mobilePhone ?? profileData?.mobilePhone,
                  // Personal data
                  permissionContactBookingTroublesViaMail:
                    data?.permissionContactBookingTroublesViaMail || profileData?.permissionContactBookingTroublesViaMail || null,
                  permissionContactBookingTroublesViaPhone:
                    data?.permissionContactBookingTroublesViaPhone || profileData?.permissionContactBookingTroublesViaPhone || null,
                  permissionContactBookingTroublesViaEmail:
                    data?.permissionContactBookingTroublesViaEmail || profileData?.permissionContactBookingTroublesViaEmail || null,
                  permissionContactBookingTroublesViaSms:
                    data?.permissionContactBookingTroublesViaSms || profileData?.permissionContactBookingTroublesViaSms || null,
                  permissionContactInvoiceTroublesViaMail:
                    data?.permissionContactInvoiceTroublesViaMail || profileData?.permissionContactInvoiceTroublesViaMail || null,
                  permissionContactInvoiceTroublesViaPhone:
                    data?.permissionContactInvoiceTroublesViaPhone || profileData?.permissionContactInvoiceTroublesViaPhone || null,
                  permissionContactInvoiceTroublesViaEmail:
                    data?.permissionContactInvoiceTroublesViaEmail || profileData?.permissionContactInvoiceTroublesViaEmail || null,
                  permissionContactInvoiceTroublesViaSms:
                    data?.permissionContactInvoiceTroublesViaSms || profileData?.permissionContactInvoiceTroublesViaSms || null,
                  permissionContactAdvertisingViaMail:
                    data?.permissionContactAdvertisingViaMail || profileData?.permissionContactAdvertisingViaMail || null,
                  permissionContactAdvertisingViaPhone:
                    data?.permissionContactAdvertisingViaPhone || profileData?.permissionContactAdvertisingViaPhone || null,
                  permissionContactAdvertisingViaEmail:
                    data?.permissionContactAdvertisingViaEmail || profileData?.permissionContactAdvertisingViaEmail || null,
                  permissionContactAdvertisingViaSms:
                    data?.permissionContactAdvertisingViaSms || profileData?.permissionContactAdvertisingViaSms || null,
                  profileInfo: {
                    // Profile registry
                    partnerInfo: data?.partnerInfo ?? profileData?.profileInfo?.partnerInfo,
                    childrenInfo: data?.childrenInfo ?? profileData?.profileInfo?.childrenInfo,
                    // Personal traits
                    employment: data?.employment ?? profileData?.profileInfo?.employment,
                    characterInfo: getValuesOnly(data?.characterInfo) ?? profileData?.profileInfo?.characterInfo,
                    otherPersonalInfo: getValuesOnly(data?.otherPersonalInfo) ?? profileData?.profileInfo?.otherPersonalInfo,
                    physicalLimitations: getValuesOnly(data?.physicalLimitations) ?? profileData?.profileInfo?.physicalLimitations,
                    // Experiential profile
                    favoriteExperiences: getValuesOnly(data?.favoriteExperiences) ?? profileData?.profileInfo?.favoriteExperiences,
                    vip: data?.vip ?? profileData?.profileInfo?.vip
                  },
                  fbProfilePreferences: {
                    // Personal Traits
                    foodRestrictions: getValuesOnly(data?.foodRestrictions) ?? profileData?.fbProfilePreferences?.foodRestrictions,
                    allergens: getValuesOnly(data?.allergens) ?? profileData?.fbProfilePreferences?.allergens,
                    intolerances: getValuesOnly(data?.intolerances) ?? profileData?.fbProfilePreferences?.intolerances,
                    // Experiential profile
                    favoriteInternalRestaurants:
                      getValuesOnly(data?.favoriteInternalRestaurants) ?? profileData?.fbProfilePreferences?.favoriteInternalRestaurants,
                    favoriteExternalRestaurants: data?.favoriteExternalRestaurants ?? profileData?.fbProfilePreferences?.favoriteExternalRestaurants
                  }
                }
              }
            })
              .then(async ({ reservationData }) => {
                setNewProfileUUID(reservationData?.saveProfileData?.uuid ?? null);
                if (data?.checkins?.length > 0) {
                  await Promise.all(
                    data.checkins.map((checkin) => {
                      return saveCheckinPreferences({
                        variables: {
                          checkinUUID: checkin?.uuid,
                          checkinInput: {
                            arrangement: checkin?.arrangement ?? profileData.checkins.find((c) => c.uuid === checkin.uuid)?.arrangement,
                            checkinPreferences: {
                              transportationInfo:
                                checkin?.transportationInfo ??
                                profileData.checkins.find((c) => c.uuid === checkin.uuid)?.checkinPreferences?.transportationInfo,
                              reservedLocalAdviser:
                                checkin?.reservedLocalAdviser ??
                                profileData.checkins.find((c) => c.uuid === checkin.uuid)?.checkinPreferences?.reservedLocalAdviser,
                              localAdvisers:
                                getValuesOnly(checkin?.localAdvisers) ??
                                profileData.checkins.find((c) => c.uuid === checkin.uuid)?.checkinPreferences?.localAdvisers,
                              reasonOfTrip:
                                checkin?.reasonOfTrip ?? profileData.checkins.find((c) => c.uuid === checkin.uuid)?.checkinPreferences?.reasonOfTrip,
                              checkinMoodIDs:
                                getValuesOnly(checkin?.checkinMoodIDs) ??
                                profileData.checkins.find((c) => c.uuid === checkin.uuid)?.checkinPreferences?.checkinMoodIDs,
                              checkoutMoodIDs:
                                getValuesOnly(checkin?.checkoutMoodIDs) ??
                                profileData.checkins.find((c) => c.uuid === checkin.uuid)?.checkinPreferences?.checkoutMoodIDs,
                              requestedServices:
                                getValuesOnly(checkin?.requestedServices) ??
                                profileData.checkins.find((c) => c.uuid === checkin.uuid)?.checkinPreferences?.requestedServices,
                              complaints:
                                getValuesOnly(checkin?.complaints) ??
                                profileData.checkins.find((c) => c.uuid === checkin.uuid)?.checkinPreferences?.complaints,
                              housewife:
                                checkin?.housewife ?? profileData.checkins.find((c) => c.uuid === checkin.uuid)?.checkinPreferences?.housewife,
                              checkinNotes:
                                checkin?.checkinNotes ?? profileData.checkins.find((c) => c.uuid === checkin.uuid)?.checkinPreferences?.checkinNotes,
                              checkoutFeedback:
                                checkin?.checkoutFeedback ??
                                profileData.checkins.find((c) => c.uuid === checkin.uuid)?.checkinPreferences.checkoutFeedback
                            }
                          }
                        }
                      });
                    })
                  );
                }
                if (!profileUUID) {
                  modalContext.openModal({
                    class: 'success',
                    title: <TranslateTextComponent capitalize>guest-profile-successfully-created-title</TranslateTextComponent>,
                    text: <TranslateTextComponent capitalize>guest-profile-successfully-created-description</TranslateTextComponent>
                  });
                  history.push(ROUTE_GUEST_PROFILES);
                } else {
                  modalContext.openModal({
                    class: 'success',
                    title: <TranslateTextComponent capitalize>guest-profile-successfully-edited-title</TranslateTextComponent>,
                    text: <TranslateTextComponent capitalize>guest-profile-successfully-edited-description</TranslateTextComponent>
                  });
                }
                if (profileUUID || newProfileUUID) {
                  getProfileData.refetch({ variables: { profileUUID: profileUUID || newProfileUUID } });
                }
                modalContext.setModalLoading(false);
              })
              .catch((err) => {
                modalContext.setModalLoading(false);

                if (err?.networkError?.result?.errors?.[0]?.message?.includes('permissionContact')) {
                  modalContext.openModal({
                    class: 'danger',
                    title: <TranslateTextComponent capitalize>an-error-occurred</TranslateTextComponent>,
                    text: getApolloErrors(err)[0] ?? (
                      <TranslateTextComponent capitalize>in-personal-data-advertising-fields-are-required</TranslateTextComponent>
                    )
                  });
                } else {
                  modalContext.openModal({
                    class: 'danger',
                    title: <TranslateTextComponent capitalize>an-error-occurred</TranslateTextComponent>,
                    text: getApolloErrors(err)[0] ?? <TranslateTextComponent capitalize>an-error-occurred</TranslateTextComponent>
                  });
                }
              });
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>
      ]
    });
  };

  const onFormValuesChange = (values) => {
    setFormValues((prev) => ({ ...prev, ...values }));
  };

  const getForm = () => {
    switch (currentTab) {
      case TABS.PROFILE_REGISTRY:
        return (
          <ProfileForm
            data={getProfileData.data?.getProfileData}
            formValues={formValues}
            onFormValuesChange={onFormValuesChange}
            tags={getTags.data?.getTags?.data ?? []}
            loading={getProfileData.loading}
            saveProfileData={onSaveProfileData}
          />
        );
      case TABS.PERSONAL_TRAITS_PROFILE:
        return (
          <PersonalTraitsForm
            data={getProfileData.data?.getProfileData}
            formValues={formValues}
            onFormValuesChange={onFormValuesChange}
            tags={getTags.data?.getTags?.data ?? []}
            loading={getTags.loading}
            saveProfileData={onSaveProfileData}
          />
        );
      case TABS.EXPERIENTIAL_PROFILE:
        return (
          <ExperientialForm
            data={getProfileData.data?.getProfileData}
            formValues={formValues}
            onFormValuesChange={onFormValuesChange}
            tags={getTags.data?.getTags?.data ?? []}
            loading={getTags.loading}
            saveProfileData={onSaveProfileData}
          />
        );
      case TABS.PERSONAL_DATA:
        return (
          <PersonalDataForm
            data={getProfileData.data?.getProfileData}
            formValues={formValues}
            onFormValuesChange={onFormValuesChange}
            saveProfileData={onSaveProfileData}
          />
        );
      default:
        return (
          <ProfileForm
            data={getProfileData.data?.getProfileData}
            formValues={formValues}
            onFormValuesChange={onFormValuesChange}
            tags={getTags.data?.getTags?.data ?? []}
            loading={getProfileData.loading}
            saveProfileData={onSaveProfileData}
          />
        );
    }
  };

  return (
    <PageLayout style={{ padding: 0, borderTopLeftRadius: 15, overflow: 'hidden' }}>
      <Tabs className={classes.tabsContainer} variant="fullWidth" value={currentTab} onChange={(e, value) => setCurrentTab(value)}>
        {Object.keys(TABS).map((key, index) => (
          <Tab
            key={index}
            className={currentTab === TABS[key] ? classes.selectedTab : ''}
            label={translate(TABS[key].toLowerCase().replace(/_/g, '-'), language)}
            value={TABS[key]}
          />
        ))}
      </Tabs>
      <Container>{getProfileData.loading ? <CircularProgress style={{ margin: 'auto' }} /> : getForm()}</Container>
    </PageLayout>
  );
};

export default GuestProfile;
