/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { Button, Typography, Grid, Radio, RadioGroup, FormControlLabel } from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from '../../../styles/settings/settingsStyle';
import { DAYS } from '../../../constants/settings/segments';
import { TranslateTextComponent } from '../../../translator';
import { MaterialIcon } from '../../../common-fe';

const SegmentServicesRow = ({ service, fbServices, serviceIndex, saveEditedSegmentsService, deleteFromSegmentsService }) => {
  const classes = styles();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedDays, setSelectedDays] = useState(null);
  const [serviceId, setServiceId] = useState(service.fbServiceId);

  useEffect(() => {
    setSelectedDays([...service.days]);
  }, []);

  const isSelectedDay = (day) => {
    return selectedDays && selectedDays.length ? selectedDays.findIndex((dayNumber) => dayNumber === day.id) > -1 : false;
  };

  const saveSegmentService = () => {
    saveEditedSegmentsService(serviceIndex, {
      fbServiceId: Number(serviceId),
      days: selectedDays
    });
    setIsEditing(false);
  };

  const editSegmentService = () => {
    setIsEditing(true);
  };

  const restoreSegmentService = () => {
    setServiceId(service.fbServiceId);
    setSelectedDays([...service.days]);
    setIsEditing(false);
  };

  const handleRadioSegmentChange = (e) => {
    setServiceId(e.target.value);
  };

  const onSelectDay = (dayNumber) => {
    if (isEditing) {
      const dayIndex = selectedDays.findIndex((dayN) => dayN === dayNumber);
      const newSelectedDays = [...selectedDays];
      if (dayIndex > -1) {
        newSelectedDays.splice(dayIndex, 1);
      } else {
        newSelectedDays.push(dayNumber);
      }
      setSelectedDays(newSelectedDays);
    }
  };

  return (
    <Grid container direction="row" justify="space-between" alignItems="center" style={{ marginBottom: 38 }}>
      {isEditing ? (
        <Grid container justify="space-between" style={{ marginBottom: 15 }}>
          {fbServices.map((serv, index) => (
            <RadioGroup key={`segmentRadio${index}`} row value={`${serviceId}`} defaultValue={null} onChange={(e) => handleRadioSegmentChange(e)}>
              <FormControlLabel
                value={serv.id}
                control={<Radio checked={Number(serviceId) === serv.id} />}
                label={
                  <Grid container direction="row" alignItems="center">
                    <MaterialIcon iconName={serv.icon} /> &nbsp; {serv.name}
                  </Grid>
                }
              />
            </RadioGroup>
          ))}
        </Grid>
      ) : (
        service.fbServiceId && (
          <Grid item xs={1} md={2} lg={1} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <MaterialIcon iconName={fbServices.find((fbService) => fbService.id === Number(service.fbServiceId)).icon} /> &nbsp;
            <Typography>{fbServices.find((fbService) => fbService.id === Number(service.fbServiceId)).name}</Typography>
          </Grid>
        )
      )}
      <Grid
        item
        xs={11}
        md={12}
        lg={11}
        style={{ display: 'flex', justifyContent: 'space-between', marginLeft: isEditing ? '8.3%' : null, borderLeft: '1px solid' }}
      >
        <Grid container>
          <Grid item xs={4} md={3} lg={3} style={{ borderRight: '1px solid', display: 'flex', alignItems: 'center', padding: '0 10px' }}>
            <Typography>
              <TranslateTextComponent capitalize>start-time</TranslateTextComponent>&nbsp;
              {fbServices.find((fbService) => fbService.id === Number(service.fbServiceId)).startTime}
            </Typography>
            <Typography>
              <TranslateTextComponent capitalize>end-time</TranslateTextComponent>&nbsp;
              {fbServices.find((fbService) => fbService.id === Number(service.fbServiceId)).endTime}
            </Typography>
          </Grid>

          <Grid
            item
            xs={5}
            md={6}
            lg={6}
            style={{ borderRight: '1px solid', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 10, paddingRight: 10 }}
          >
            {DAYS.map((day, i) => {
              return (
                <Grid
                  lg={2}
                  md={2}
                  xs={2}
                  item
                  key={`grid${i}`}
                  className={styles({ dimension: 'small', isSelectedDay: isSelectedDay(day) }).gridDays}
                  onClick={() => onSelectDay(day.id)}
                >
                  <Typography className={classes.typographyDays}>{day.shortLabel}</Typography>
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={3} md={3} lg={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 10 }}>
            <Button
              color="primary"
              variant="contained"
              className={classes.buttonList}
              style={{ marginRight: 20 }}
              onClick={isEditing ? () => saveSegmentService() : () => editSegmentService()}
            >
              {isEditing ? (
                <TranslateTextComponent capitalize>save</TranslateTextComponent>
              ) : (
                <TranslateTextComponent capitalize>edit</TranslateTextComponent>
              )}
            </Button>
            {isEditing ? (
              <Button
                color="primary"
                variant="contained"
                className={classes.buttonList}
                style={{ width: 113 }}
                onClick={() => restoreSegmentService()}
              >
                <TranslateTextComponent capitalize>cancel</TranslateTextComponent>
              </Button>
            ) : (
              <Button
                color="primary"
                variant="contained"
                className={classes.buttonList}
                style={{ width: 113 }}
                onClick={() => deleteFromSegmentsService(serviceIndex)}
              >
                <DeleteIcon />
                <TranslateTextComponent capitalize>delete</TranslateTextComponent>
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default SegmentServicesRow;
