import React, { useContext } from 'react';
import {
  Button,
  Typography,
  Box,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Input,
  MenuItem,
  ListItemText,
  Select
} from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import SegmentServicesRow from './SegmentServicesRow';
import { TranslateTextComponent, translate, capitalizeFirstLetter } from '../../../translator';
import { LanguageContext } from '../../../contexts/LanguageContext';
import { InputTextLabel, MaterialIcon, DataPicker } from '../../../common-fe';
import Footer from '../Footer';
import { DAYS } from '../../../constants/settings/segments';
import styles from '../../../styles/settings/settingsStyle';

const inputStyle = { height: 30 };
const dataPickerStyle = { width: 180, minHeight: 30 };

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 230,
      width: 400
    }
  }
};

const SegmentsForm = ({
  newSegment,
  checkInputValidityForDays,
  onDayClick,
  selectedDays,
  selectedServices,
  updateSegmentsSettings,
  onClickSave,
  onClickDelete,
  checkIfOneDayIsSelected,
  handleCheckboxChange,
  fbServices,
  setAllDaysSelected,
  deleteCurrentSegment,
  addNewSegment,
  saveEditedSegmentsService,
  deleteFromSegmentsService,
  editItemId
}) => {
  const classes = styles();

  const getService = () => {
    const services = fbServices.filter((fbService) => selectedServices.includes(fbService.id));

    return services.map((service, index) => (
      <Box key={index} style={{ display: 'flex', alignItems: 'center', minWidth: 90 }}>
        <MaterialIcon iconName={service.icon} style={{ marginRight: 10 }} />
        <Typography>{service.name} </Typography>
        {index !== services.length - 1 ? <Typography style={{ margin: '0 10px' }}> - </Typography> : null}
      </Box>
    ));
  };

  const languageContext = useContext(LanguageContext);

  return (
    <Grid container justify="space-between" alignItems="center" className={classes.footerGridContainer}>
      <Box style={{ display: 'flex', width: '100%' }}>
        <Box style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '20%' }}>
          <InputTextLabel
            inputStyle={inputStyle}
            title={capitalizeFirstLetter(translate('name', languageContext.language))}
            placeholder="Comune"
            className={classes.noteTextField}
            classNameBox={classes.firstBox}
            onChange={(event) => updateSegmentsSettings('name', event.target.value)}
            value={newSegment.name}
          />
        </Box>

        <Box style={{ display: 'flex', alignItems: 'center', width: '30%' }}>
          <Typography className={classes.formTypography}>
            <TranslateTextComponent capitalize>day-start</TranslateTextComponent>
          </Typography>
          <DataPicker value={newSegment.startDate} dataPickerStyle={dataPickerStyle} onChange={(date) => updateSegmentsSettings('startDate', date)} />
        </Box>

        <Box style={{ display: 'flex', alignItems: 'center', width: '30%' }}>
          <Typography className={classes.formTypography}>
            <TranslateTextComponent capitalize>day-end</TranslateTextComponent>
          </Typography>

          <DataPicker
            value={newSegment.endDate}
            dataPickerStyle={dataPickerStyle}
            onChange={(date) => {
              updateSegmentsSettings('endDate', date);
            }}
          />
        </Box>

        <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '20%' }}>
          <Typography className={classes.formTypography}>
            <TranslateTextComponent capitalize>state</TranslateTextComponent>
          </Typography>
          <RadioGroup
            row
            value={newSegment.isActive}
            defaultValue={newSegment.isActive}
            onChange={(e) => updateSegmentsSettings('isActive', e.target.value)}
            className={classes.footerRadioGroup}
          >
            <FormControlLabel
              value="activated"
              control={<Radio checked={newSegment.isActive === 'activated'} />}
              label={capitalizeFirstLetter(translate('activated', languageContext.language))}
            />
            <FormControlLabel
              value="disabled"
              control={<Radio checked={newSegment.isActive === 'disabled'} />}
              label={
                <Typography style={{ fontSize: 14 }}>
                  <TranslateTextComponent capitalize>disabled-state</TranslateTextComponent>
                </Typography>
              }
            />
          </RadioGroup>
        </Box>
      </Box>
      <Box
        style={{
          marginTop: 45,
          opacity: !checkInputValidityForDays() ? 0.2 : null,
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center'
        }}
      >
        <Box style={{ width: '10%' }}>
          <Typography className={classes.formTypography}>
            <TranslateTextComponent capitalize>working-days</TranslateTextComponent>
          </Typography>
        </Box>

        <Box style={{ display: 'flex', justifyContent: 'flex-end', width: '90%' }}>
          {DAYS.map((day, index) => {
            return (
              <Box
                key={`day${day.id}`}
                style={{ width: '14.2%' }}
                className={styles({ dimension: 'big', isSelectedDay: selectedDays.includes(day.id) }).gridDays}
                onClick={() => onDayClick(index, day)}
              >
                <Typography className={classes.typographyDays}>{day.label}</Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      {(checkIfOneDayIsSelected() || newSegment.fbSegmentsServices.length > 0) && (
        <Grid
          container
          alignItems="center"
          style={{
            marginTop: 35,
            opacity: checkIfOneDayIsSelected() ? null : 0.2
          }}
        >
          <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Typography className={classes.formTypography}>
              <TranslateTextComponent capitalize>service</TranslateTextComponent>
            </Typography>

            <Select
              disabled={!checkIfOneDayIsSelected()}
              multiple
              disableUnderline
              value={selectedServices}
              onChange={(e) => handleCheckboxChange(e, e.target.value)}
              input={<Input id="select-multiple-checkbox" />}
              className={classes.dropdown}
              renderValue={() => {
                return (
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      width: '100%',
                      flexWrap: 'nowrap'
                    }}
                  >
                    {getService()}
                  </Box>
                );
              }}
              MenuProps={MenuProps}
            >
              {fbServices.map((service) => (
                <MenuItem key={service.id} value={service.id}>
                  <Checkbox color="primary" checked={selectedServices.includes(service.id)} />
                  <ListItemText
                    primary={
                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <MaterialIcon iconName={service.icon} />
                        <Typography style={{ margin: '0 10px' }}>{service.name}</Typography>
                        &nbsp;
                        <Typography style={{ color: '#939393' }}>hh:mm</Typography>
                        &nbsp;
                        <Typography style={{ color: '#939393' }}>
                          {service.startTime}-{service.endTime}
                        </Typography>
                      </Box>
                    }
                  />
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {selectedServices.length > 0 && (
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
              <Button color="primary" variant="contained" className={classes.buttonList} onClick={() => setAllDaysSelected()}>
                <TranslateTextComponent capitalize>repeat-for-all-days</TranslateTextComponent>
              </Button>

              <Button
                color="primary"
                variant="contained"
                className={classes.buttonList}
                style={{ margin: '0 20px' }}
                onClick={() => deleteCurrentSegment()}
              >
                <DeleteIcon />
                <TranslateTextComponent capitalize>delete</TranslateTextComponent>
              </Button>
              <Button color="primary" variant="contained" className={classes.buttonList} onClick={() => addNewSegment()}>
                <TranslateTextComponent capitalize>save</TranslateTextComponent>
              </Button>
            </Grid>
          )}
        </Grid>
      )}
      {newSegment.fbSegmentsServices.length > 0 && (
        <Box style={{ width: '100%', marginTop: 38 }}>
          {newSegment.fbSegmentsServices.map((service, index) => {
            if (service && service.fbService) {
              service.fbServiceId = service.fbService.fbServiceId;
            }
            return (
              <SegmentServicesRow
                key={`segmentRow${service.fbServiceId}-${newSegment.id}`}
                fbServices={fbServices}
                service={service}
                serviceIndex={index}
                saveEditedSegmentsService={saveEditedSegmentsService}
                deleteFromSegmentsService={deleteFromSegmentsService}
                editItemId={editItemId}
              />
            );
          })}
        </Box>
      )}
      {newSegment.fbSegmentsServices.length > 0 && <Footer onClickSave={() => onClickSave()} onClickDelete={() => onClickDelete()} />}
    </Grid>
  );
};
export default SegmentsForm;
