import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { CustomModal } from '../../../../common-fe/src';
import InputWithLabel from '../../../shared/InputWithLabel';
import TilesStepper from '../../../shared/TilesStepper';
import Buttons from '../../../shared/Buttons';
import { ADD_MENU_ITEM_STOCK } from '../../../../graphql/moh/inventory/mutations';
import { ModalContext } from '../../../../contexts/ModalContext';
import { TranslateTextComponent, capitalizeFirstLetter, translate } from '../../../../translator';
import { getApolloErrors } from '../../../../apollo/ApolloProvider';
import { LanguageContext } from '../../../../contexts/LanguageContext';

const RowContainer = styled.div`
  display: flex;
  margin-bottom: ${({ withoutMarginBottom }) => (withoutMarginBottom ? '0px' : '20px')};

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  & input[type='number'] {
    appearance: textfield;
  }

  & > div:not(:last-child) {
    margin-right: 20px;
  }
`;

const InventoryItemModal = ({ data, showModal, onClose }) => {
  const modalContext = useContext(ModalContext);
  const { language } = useContext(LanguageContext);

  // Mutations
  const [addMenuItemStock, { loading }] = useMutation(ADD_MENU_ITEM_STOCK);

  const validationSchema = Yup.object().shape({
    stockQuantity: Yup.number().required(capitalizeFirstLetter(translate('quantity-not-valid', language)))
  });

  const { values, errors, touched, setFieldValue, handleBlur, handleSubmit, handleReset } = useFormik({
    enableReinitialize: true,
    initialValues: { stockQuantity: 0 },
    validationSchema,
    onSubmit: ({ stockQuantity }, { setSubmitting }) => {
      addMenuItemStock({
        variables: {
          quantity: Number(stockQuantity),
          menuItemUUID: data.uuid
        }
      })
        .then(() => {
          onClose();
          handleReset();
          modalContext.openModal({
            class: 'success',
            title: <TranslateTextComponent capitalize>success</TranslateTextComponent>,
            text: <TranslateTextComponent capitalize>inventory-quantity-edit</TranslateTextComponent>
          });
        })
        .catch((err) => {
          onClose();
          handleReset();
          modalContext.setModalLoading(false);
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - ')
          });
        });
      setSubmitting(false);
    }
  });

  return (
    <CustomModal
      showModal={showModal}
      height="auto"
      width="auto"
      header={<TranslateTextComponent vars={{ id: data?.id }}>product</TranslateTextComponent>}
      styledHeader
      styleBody={{ flexFlow: 'column' }}
      onClose={() => {
        onClose();
        handleReset();
      }}
    >
      <RowContainer>
        <InputWithLabel label="name">
          <TextField variant="outlined" color="primary" value={data?.name} />
        </InputWithLabel>
        <InputWithLabel label="id">
          <TextField variant="outlined" color="primary" value={data?.id} />
        </InputWithLabel>
        <InputWithLabel label="price">
          <TextField variant="outlined" color="primary" value={data?.price} />
        </InputWithLabel>
      </RowContainer>
      <RowContainer>
        <InputWithLabel label="quantity" error={touched.stockQuantity && errors.stockQuantity}>
          <TextField
            name="stockQuantity"
            fullWidth
            type="number"
            variant="outlined"
            color="primary"
            value={values.stockQuantity}
            error={touched.stockQuantity && !!errors.stockQuantity}
            onBlur={handleBlur}
            onChange={({ target: { value } }) => {
              if (!value || value.match(/[0-9]|\.|-/)) setFieldValue('stockQuantity', value);
            }}
          />
        </InputWithLabel>
      </RowContainer>
      <RowContainer>
        <TilesStepper
          value={values.stockQuantity ? Number(values.stockQuantity) : 0}
          onIncrease={() => setFieldValue('stockQuantity', Number(values.stockQuantity) + 1)}
          onDecrease={() => setFieldValue('stockQuantity', Number(values.stockQuantity) - 1)}
        />
      </RowContainer>
      <RowContainer withoutMarginBottom>
        <Buttons
          containerStyle={{ justifyContent: 'space-between' }}
          buttons={[
            { buttonType: 'reset', type: 'cancel', style: { fontSize: 20 }, onClick: handleReset },
            {
              buttonType: 'submit',
              type: 'confirm',
              label: 'save',
              style: { fontSize: 20 },
              disabled: loading || !values.stockQuantity || values.stockQuantity === 0,
              isLoading: loading,
              onClick: handleSubmit
            }
          ]}
        />
      </RowContainer>
    </CustomModal>
  );
};

export default InventoryItemModal;
