import { makeStyles } from '@material-ui/core/styles';

const modalStyle = {
  languageItem: {
    display: 'flex',
    padding: '10px 0px',
    '&:hover': {
      backgroundColor: '#EBEBEB'
    }
  },
  flagContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 34,
    justifyContent: 'center',
    paddingLeft: 5
  },
  textContainer: {
    width: 33,
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: 10
  },
  userOptionsItems: {
    padding: '10px 25px',
    display: 'flex',
    '&:hover': {
      backgroundColor: '#EBEBEB'
    }
  }
};

export default makeStyles(modalStyle);
