import { makeStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    paddingRight: 10,
    gridArea: 'guests-container',
    display: 'grid',
    gridTemplateAreas: '"guests" "options"',
    gridTemplateRows: 'auto auto',
    gridRowGap: 30,
    height: '52vh',
    overflow: 'auto',
    scrollbarColor: '#00B7FF #B4DCED',
    scrollbarWidth: 'thin',
    // Chrome only
    '& ::-webkit-scrollbar-track': {
      borderRadius: 10
    }
  }
};

export default makeStyles(styles);
