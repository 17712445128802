import React, { useState, useRef } from 'react';
import typy from 'typy';
import ReactToPrint from 'react-to-print';
import Spinner from 'react-loader-spinner';
import SignatureCanvas from 'react-signature-canvas';
import * as htmlToImage from 'html-to-image';
import { Box, Button, Modal, Fade, Paper, Grid, Typography } from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Cancel';
import * as documentTypes from '../../constants/cash/documentTypes';
import { GET_FB_BILL } from '../../graphql/cash/queries';
import { TranslateTextComponent, capitalizeFirstLetter } from '../../translator';
import receiptStyle from '../../styles/shared/receiptStyle';
import useCustomQuery from '../../hooks/useCustomQuery';

const BACKGROUND_COLOR = 'white';

const BillReceipt = ({ isOpen, isDigitalCheck, isPaid, handleClose, fbBillUUID, onPayMasterCharge }) => {
  const classes = receiptStyle();
  const customBackground = receiptStyle({ backgroundColor: BACKGROUND_COLOR });
  const customButton = receiptStyle({ backgroundColor: '#C3BEB8', color: 'white' });
  const componentRef = useRef();

  const getFbBillResponse = useCustomQuery(GET_FB_BILL, {
    variables: {
      fbBillUUID
    },
    skip: !fbBillUUID,
    fetchPolicy: 'network-only'
  });

  const items = typy(getFbBillResponse, 'data.getFbBill.fbBillsRows').safeArray;
  const fbBill = typy(getFbBillResponse, 'data.getFbBill').safeObject;
  const fbReservation = typy(getFbBillResponse, 'data.getFbBill.fbOrder.fbReservationTable.fbReservation').safeObject;
  const fbTable = typy(getFbBillResponse, 'data.getFbBill.fbOrder.fbReservationTable.fbTable').safeObject;
  const bookedBy = typy(fbReservation, 'bookedBy').safeObject;

  const [enablePay, setEnablePay] = useState(false);
  const [signature, setSignature] = useState({});
  const [printButtonPressed, setPrintButtonPressed] = useState(false);

  const handleResetSignature = () => {
    setEnablePay(false);
    signature.clear();
  };

  const handlePayAndSaveSignature = () => {
    const documentType = isDigitalCheck ? documentTypes.DIGITAL_CHECK : documentTypes.PAPER_CHECK;
    if (isDigitalCheck) {
      signature.off();
      htmlToImage.toPng(document.querySelector('#bill-receipt')).then((dataUrl) => {
        onPayMasterCharge({ base64image: dataUrl, documentType });
      });
    } else onPayMasterCharge({ documentType });
  };

  return (
    <Box>
      <Modal open={isOpen} onClose={handleClose} style={{ opacity: 1, backdropFilter: 'blur(10px)', height: 'auto' }}>
        <Fade in className={classes.fadeContainer}>
          <Paper style={{ paddingTop: 0 }}>
            <Box id="bill-receipt" ref={componentRef} style={{ backgroundColor: 'white' }}>
              <Grid container className={customBackground.bookedByContainer} style={{ borderBottom: '1px solid #55595C' }}>
                <Grid item xs={4} style={{ paddingTop: 7, paddingBottom: 3 }}>
                  <Typography className={classes.typographyClientData}>
                    {bookedBy ? capitalizeFirstLetter(`${bookedBy.firstname}`) : null}{' '}
                    {' ' && bookedBy ? capitalizeFirstLetter(`${bookedBy.lastname}`) : null}
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{ paddingTop: 7, paddingBottom: 3, paddingRight: 23 }}>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <Typography className={classes.typographyClientData}>
                        <TranslateTextComponent capitalize>n-pax</TranslateTextComponent>:{' '}
                        {fbReservation ? capitalizeFirstLetter(`${fbReservation.nPax}`) : null}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.typographyClientData}>
                        <TranslateTextComponent capitalize>t-numb</TranslateTextComponent>:{' '}
                        {fbTable ? capitalizeFirstLetter(`${fbTable.number}`) : null}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item style={{ paddingBottom: 3, textAlign: 'end' }}>
                    <Typography className={classes.typographyClientData}>
                      <TranslateTextComponent capitalize>reservation-time</TranslateTextComponent>:{' '}
                      {fbReservation ? capitalizeFirstLetter(`${fbReservation.reservationTime}`) : null}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {getFbBillResponse.loading ? (
                <Box style={{ display: 'flex', justifyContent: 'center', border: 'none', margin: 'auto', padding: 50 }}>
                  <Spinner type="TailSpin" color={BACKGROUND_COLOR} height={100} width={100} />
                </Box>
              ) : (
                <Box className={classes.foodAndDrinks}>
                  <Grid container style={{ paddingTop: 20, paddingBottom: 20 }}>
                    {items.map((item, index) => {
                      return (
                        <Grid container key={index}>
                          <Grid item xs={12} className={classes.dishes}>
                            <Grid container justify="space-between">
                              <Grid item className={classes.gridQuantity}>
                                {item.quantity} x € {item.price.toFixed(2)}
                                <Grid item style={{ marginLeft: 15, fontSize: 14 }}>
                                  {item.name?.length > 44 ? item.name.substring(0, 44) : item.name}
                                  {item?.fbCoursesMenuItem?.with?.length > 0 ? (
                                    <Grid container>
                                      <Grid item>
                                        <Typography className={classes.withAndWithout}>
                                          <TranslateTextComponent uppercase>with</TranslateTextComponent>:
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography className={classes.withAndWithout}>{item.fbCoursesMenuItem.with.join(', ')}</Typography>
                                      </Grid>
                                    </Grid>
                                  ) : null}
                                  {item?.fbCoursesMenuItem?.without?.length > 0 ? (
                                    <Grid container>
                                      <Grid item>
                                        <Typography className={classes.withAndWithout}>
                                          <TranslateTextComponent uppercase>without</TranslateTextComponent>:
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography className={classes.withAndWithout}>{item.fbCoursesMenuItem.without.join(', ')}</Typography>
                                      </Grid>
                                    </Grid>
                                  ) : null}
                                </Grid>
                              </Grid>

                              <Grid item xs={3}>
                                <Typography className={classes.gridPrice}>€ {item.total.toFixed(2)}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              )}
              <Box className={classes.boxSignature} style={{ borderTop: '1px solid #55595C' }}>
                {fbBill?.discountPercentage && (
                  <Grid container className={customBackground.gridDiscount} justify="space-between" style={{ paddingTop: 10 }}>
                    <Grid item className={classes.total} style={{ fontSize: 14 }}>
                      <TranslateTextComponent uppercase>discount%</TranslateTextComponent>
                      {` (${fbBill?.discountPercentageNotes})`}:
                    </Grid>
                    <Grid item className={classes.total}>
                      <Typography style={{ fontSize: 14, color: '#55595C', fontWeight: 500 }}>
                        {fbBill?.discountPercentage}% = € {fbBill && ((fbBill.subtotal * fbBill.discountPercentage) / 100).toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {fbBill?.discount && (
                  <Grid container className={customBackground.gridDiscount} justify="space-between" style={{ paddingTop: 10 }}>
                    <Grid item className={classes.total} style={{ fontSize: 14 }}>
                      <TranslateTextComponent uppercase>discount€</TranslateTextComponent>
                      {` (${fbBill?.discountNotes})`}:
                    </Grid>
                    <Grid item className={classes.total}>
                      <Typography style={{ fontSize: 14, color: '#55595C', fontWeight: 500 }}>€ {fbBill?.discount?.toFixed(2)}</Typography>
                    </Grid>
                  </Grid>
                )}
                <Grid container className={customBackground.gridSubTotal} justify="space-between">
                  <Grid item className={classes.total} style={{ fontSize: 14 }}>
                    <TranslateTextComponent uppercase>subtotal</TranslateTextComponent>:
                  </Grid>
                  <Grid item className={classes.total}>
                    <Typography style={{ fontSize: 14, color: '#55595C', fontWeight: 500 }}>€ {fbBill?.subtotal?.toFixed(2)}</Typography>
                  </Grid>
                </Grid>
                <Grid container className={customBackground.gridTotal} style={{ borderBottom: '1px solid #55595C' }} justify="space-between">
                  <Grid item className={classes.total}>
                    <TranslateTextComponent uppercase>total</TranslateTextComponent>:
                  </Grid>
                  <Grid item className={classes.total}>
                    <Typography style={{ fontSize: 20, color: '#55595C', fontWeight: 500 }}>€ {fbBill?.total?.toFixed(2)}</Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} className={customBackground.signatureGrid}>
                  <Typography>
                    <TranslateTextComponent capitalize>signature</TranslateTextComponent>:
                  </Typography>
                  {isDigitalCheck ? (
                    <Grid container alignItems="center" style={{ paddingBottom: 10, position: 'relative' }}>
                      <Grid item xs={10}>
                        <SignatureCanvas
                          backgroundColor="#ebf2f4"
                          canvasProps={{ width: 400, height: 70 }}
                          ref={(ref) => setSignature(ref)}
                          maxWidth={2}
                          onEnd={() => setEnablePay(true)}
                        />

                        <CloseIcon
                          className={classes.iconDeleteSignature}
                          onClick={() => {
                            if (!isPaid) handleResetSignature();
                          }}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Box style={{ borderBottom: `2px dashed ${'#55595C'}`, width: '100%', height: 50, marginBottom: 20 }} />
                  )}
                </Grid>
              </Box>
            </Box>
            <Grid container justify="space-around" style={{ padding: 10, paddingTop: 50 }}>
              <Button
                className={customButton.payButton}
                onClick={handlePayAndSaveSignature}
                disabled={(isDigitalCheck && !enablePay) || (!isDigitalCheck && !printButtonPressed) || isPaid}
              >
                <TranslateTextComponent capitalize>charge</TranslateTextComponent>
              </Button>
              <ReactToPrint
                trigger={() => (
                  <Button className={customButton.payButton}>
                    <TranslateTextComponent capitalize>print</TranslateTextComponent>
                  </Button>
                )}
                content={() => componentRef.current}
                onAfterPrint={() => setPrintButtonPressed(true)}
              />
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </Box>
  );
};

export default BillReceipt;
