import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Button, Grid, Typography, ClickAwayListener, Icon, IconButton } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { PanZoom } from 'react-easy-panzoom';
import omit from 'lodash/omit';
import typy from 'typy';
import SaveIcon from '@material-ui/icons/Save';
import RestoreIcon from '@material-ui/icons/Restore';
import moment from 'moment';
import { GET_FB_SERVICES, GET_CURRENT_SERVICE } from '../../graphql/settings/service/queries';
import { GET_TABLES } from '../../graphql/tables/queries';
import { GET_FB_RESERVATIONS } from '../../graphql/reservations/queries';
import {
  SET_RESERVATION_TABLES,
  FREE_TABLE,
  MOVE_TABLE,
  MOVE_CHAIR,
  MERGE_TABLES,
  SPLIT_TABLE,
  ADD_CHAIR,
  ASSIGN_CHAIR,
  ASSIGN_FAST_FB_TABLE,
} from '../../graphql/tables/mutations';
import { PLAY_FB_COURSE } from '../../graphql/order/mutations';
import { MOVE_FB_ROOM_TABLES } from '../../graphql/roomEditor/mutations';
import { GET_ROOMS } from '../../graphql/roomEditor/queries';
import { ModalContext } from '../../contexts/ModalContext';
import { ReservationContext } from '../../contexts/ReservationContext';
import { TranslateTextComponent, translate } from '../../translator';
import { getApolloErrors } from '../../apollo/ApolloProvider';
import modalStyle from '../../styles/shared/modalStyle';
import layoutStyle from '../../styles/leftSidebarLayout/leftSidebarLayoutStyle';
import style from '../../styles/viewTables/style';
import Guests from './Guests/Guests';
import Options from './Guests/Options';
import Tabs from './Tables/Tabs';
import Tables from './Tables/Tables';
import FloatingGuests from './FloatingGuests/FloatingGuests';
import FloatingBusyTableOptions from './FloatingBusyTableOptions/FloatingBusyTableOptions';
import { CustomModal, Dropdown, MaterialIcon } from '../../common-fe';
import FastReservation from '../shared/FastReservation';
import RoomService from './RoomService/RoomService';
import { LanguageContext } from '../../contexts/LanguageContext';
import { AuthContext } from '../../contexts/AuthContext';
import SplitTableModal from './Modals/SplitTableModal';
import AddChairModal from './Modals/AddChairModal';
import FB_RESERVATION_STATUSES from '../../constants/showReservations/reservationsStatuses';
import useCustomQuery from '../../hooks/useCustomQuery';

const MULTI_SELECT_FUNCTIONS = ['merge-table', 'transfer-table', 'transfer-chair'];

const ViewTablesContainer = () => {
  const ref = useRef(null);
  const layoutClasses = layoutStyle();
  const classes = style();

  const panZoomEl = useRef(null);

  const modalClasses = modalStyle();
  const modalContext = useContext(ModalContext);
  const { language } = useContext(LanguageContext);
  const reservationContext = useContext(ReservationContext);
  const { appOutletUUID } = useContext(AuthContext);

  const floatingGuestsMenuSizes = { width: 212, height: 459 };
  const floatingBusyTableMenuSizes = { width: 143, height: 363 };

  //* States

  // DROPDOWN AREA
  const [selectedServiceUUID, setSelectedServiceUUID] = useState();
  // GUESTS AREA
  const [guestsList, setGuestsList] = useState([]);
  const [showFloatingGuests, setShowFloatingGuests] = useState(false);
  const [floatingGuestsCoords, setFloatingGuestsCoords] = useState({ top: 0, left: 0, isSet: false });
  const [selectedGuestUUID, setSelectedGuestUUID] = useState(null);
  const [selectedOptionButton, setSelectedOptionButton] = useState(null);
  const [showNewReservationModal, setShowNewReservationModal] = useState(false);

  // TABLES AREA
  const [roomTables, setRoomTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [selectedTableUUID, setSelectedTableUUID] = useState(null);
  const [secondarySelectedTableUUID, setSecondarySelectedTableUUID] = useState(null);
  const [showSplitTableModal, setShowSplitTableModal] = useState(false);
  const [showAddChairModal, setShowAddChairModal] = useState(false);

  // CHAIRS AREA
  const [selectedChairUUID, setSelectedChairUUID] = useState(null);
  const [secondarySelectedChairUUID, setSecondarySelectedChairUUID] = useState(null);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [isTableBusy, setIsTableBusy] = useState(false);
  const [showFloatingBusyTableOptions, setShowFloatingBusyTableOptions] = useState(false);
  const [floatingBusyTableOptionsCoords, setFloatingBusyTableOptionsCoords] = useState({ top: 0, left: 0, isSet: false });
  const [showButtons, setShowButtons] = useState(false);
  const [layoutInitialized, setLayoutInitialized] = useState(false);
  const [initialRoomTables, setInitialRoomTables] = useState([]);
  const [initialLayout, setInitialLayout] = useState([]);
  const [layout, setLayout] = useState([]);

  // TABS AREA
  const [tabsRooms, setTabsRooms] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);
  const [isRoomEditable, setIsRoomEditable] = useState(false);

  const isGuestNameShowed = false;

  const getFbServices = useCustomQuery(GET_FB_SERVICES, {
    variables: { fbOutletUUID: appOutletUUID },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const dropdownServiceItems = typy(getFbServices, 'data.getFbServices.data').safeArray.map((service) => ({
    value: service.uuid,
    label: service.name,
    icon: service.icon ? <MaterialIcon iconName={service.icon} /> : null,
  }));
  dropdownServiceItems.unshift({ value: '', label: '-' });

  const getCurrentService = useCustomQuery(GET_CURRENT_SERVICE, {
    variables: { fbOutletUUID: appOutletUUID },
    fetchPolicy: 'network-only',
  });

  const currentService = typy(getCurrentService, 'data.getCurrentService').safeObject;

  useEffect(() => {
    if (currentService) setSelectedServiceUUID(currentService.uuid);
  }, [currentService]);

  useEffect(() => {
    if (!layoutInitialized && Object.keys(layout).length > 0) {
      setLayoutInitialized(true);
      setInitialLayout(layout);
    }
  }, [layout]);

  useEffect(() => {
    if (!isRoomEditable) {
      setLayout([...initialLayout]);
      setShowButtons(false);
    }
  }, [isRoomEditable]);

  useEffect(() => {
    if (panZoomEl) {
      panZoomEl.current.container.current.children[0].style.display = '';
    }
  }, [panZoomEl]);

  // GUEST-ITEM-BUTTON FUNCTIONS
  useEffect(() => {
    if (selectedTableUUID && selectedGuestUUID) {
      modalContext.openModal({
        class: 'primary',
        title: <TranslateTextComponent capitalize>assign-table</TranslateTextComponent>,
        text: <TranslateTextComponent capitalize>assign-table-modal-text</TranslateTextComponent>,
        actionButtons: [
          <Button
            key={0}
            variant="outlined"
            color="primary"
            className={modalClasses.invertedButton}
            onClick={() => {
              deselectAll();
              setSelectedGuestUUID(false);
              modalContext.closeModal();
            }}
          >
            <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
          </Button>,
          <Button
            key={1}
            color="primary"
            variant="contained"
            className={modalClasses.buttonStyle}
            onClick={() => {
              setReservationTables({ variables: { fbTableUUIDs: [selectedTableUUID], fbReservationUUID: selectedGuestUUID } })
                .then(() => {
                  updateAllQueries();
                  deselectAll();
                  setSelectedGuestUUID(false);
                })
                .catch((err) => {
                  modalContext.openModal({
                    class: 'danger',
                    title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                    text: getApolloErrors(err).join(' - '),
                  });
                });
              deselectAll();
              modalContext.closeModal();
              setSelectedGuestUUID(false);
            }}
          >
            <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
          </Button>,
        ],
      });
    }
  }, [selectedGuestUUID, selectedTableUUID]);

  //* OPTIONS-BUTTON FUNCTIONS
  useEffect(() => {
    if (!MULTI_SELECT_FUNCTIONS.includes(selectedOptionButton)) {
      if (selectedOptionButton && (selectedTableUUID || selectedChairUUID)) {
        optionsButtonsFunctionsHandler();
      }
    } else if (MULTI_SELECT_FUNCTIONS.includes(selectedOptionButton)) {
      setShowMessageBox(true);
      if ((selectedTableUUID && secondarySelectedTableUUID) || (selectedChairUUID && secondarySelectedChairUUID)) {
        if (selectedOptionButton === 'add-chair') setShowAddChairModal(true);
        else if (selectedOptionButton === 'split-table') setShowSplitTableModal(true);
        optionsButtonsFunctionsHandler();
      }
    }

    if (!selectedOptionButton) {
      setShowMessageBox(false);
    }
  }, [selectedOptionButton, selectedTableUUID, secondarySelectedTableUUID, selectedChairUUID, secondarySelectedChairUUID]);

  //* GRAPHQL QUERIES
  const roomTablesRes = useCustomQuery(GET_TABLES, {
    variables: { fbRoomUUID: selectedTab },
    skip: !selectedTab || isRoomEditable,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const newLayout = [];
      typy(data, 'getFbTables.data').safeArray.forEach((layoutRes) => {
        layout.forEach((oldLayout) => {
          if (layoutRes.id === parseInt(oldLayout.i, 10)) {
            newLayout.push({ ...oldLayout, x: layoutRes.positionX, y: layoutRes.positionY });
          }
        });
      });
      setLayout([...newLayout]);
      setRoomTables([...typy(data, 'getFbTables.data').safeArray.filter((tab) => tab.isActive || tab.fbReservationTables.length)]);
      setInitialRoomTables([...typy(data, 'getFbTables.data').safeArray.filter((tab) => tab.isActive || tab.fbReservationTables.length)]);
    },
  });

  const reservationsRes = useCustomQuery(GET_FB_RESERVATIONS, {
    onCompleted: (data) => {
      setGuestsList(
        [...typy(data, 'getFbReservations.data').safeArray]
          .filter(
            (guest) =>
              guest.fbReservationTables.length <= 0 &&
              (guest.status === FB_RESERVATION_STATUSES.open.label || guest.status === FB_RESERVATION_STATUSES.working.label)
          )
          .sort((prev, next) => {
            if (prev.id > next.id) {
              return -1;
            }
            return 0;
          })
      );
      if (selectedTab) {
        roomTablesRes.refetch();
      }
    },
    variables: { fbOutletUUID: appOutletUUID, date: moment().format('YYYY-MM-DD'), fbServiceUUID: selectedServiceUUID },
    fetchPolicy: 'network-only',
    pollInterval: 5000,
    notifyOnNetworkStatusChange: true,
  });

  const roomsRes = useCustomQuery(GET_ROOMS, {
    onCompleted: (data) => {
      setTabsRooms([...typy(data, 'getFbRooms.data').safeArray].sort((prev, next) => next.isActive - prev.isActive));
      if (typy(data, 'getFbRooms.data').safeArray.length > 0) {
        setSelectedTab(selectedTab || typy(data, 'getFbRooms.data').safeArray.sort((prev, next) => next.isActive - prev.isActive)[0].uuid);
      } else {
        setSelectedTab(selectedTab);
      }
    },
    variables: { fbOutletUUID: appOutletUUID },
    skip: !tabsRooms,
    fetchPolicy: 'network-only',
    pollInterval: 5000,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (selectedTab && selectedTab !== 'roomService') {
      roomTablesRes.refetch();
    }
    deselectAll();
  }, [selectedTab]);

  //* GRAPHQL MUTATIONS
  const [setReservationTables] = useMutation(SET_RESERVATION_TABLES);
  const [assignFastFbTable] = useMutation(ASSIGN_FAST_FB_TABLE);
  const [saveRoomTables] = useMutation(MOVE_FB_ROOM_TABLES);
  const [freeTable] = useMutation(FREE_TABLE);
  const [moveTable] = useMutation(MOVE_TABLE);
  const [mergeTables] = useMutation(MERGE_TABLES);
  const [splitTable] = useMutation(SPLIT_TABLE);
  const [addChair] = useMutation(ADD_CHAIR);
  const [moveChair] = useMutation(MOVE_CHAIR);
  const [assignChair] = useMutation(ASSIGN_CHAIR);
  const [playFbCourse] = useMutation(PLAY_FB_COURSE);

  const onRoomTableEditButtonClickHandler = () => {
    if (!isRoomEditable) setShowButtons(false);

    setIsRoomEditable((prevState) => {
      panZoomEl.current.reset();
      setShowButtons(!prevState);
      return !prevState;
    });
  };

  const onTabClickHandler = (id) => {
    setSelectedTab(id);
  };

  const onAssignFastFbTable = () => {
    setShowFloatingGuests(false);
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>assign-table</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>assign-table-modal-text</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            deselectAll();
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent capitalize>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            assignFastFbTable({ variables: { fbTableUUID: selectedTableUUID } })
              .then(() => {
                updateAllQueries();
                deselectAll();
              })
              .catch((err) => {
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - '),
                });
                deselectAll();
              });
            deselectAll();
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent capitalize>confirm</TranslateTextComponent>
        </Button>,
      ],
    });
  };

  const onTableClickHandler = (e, table) => {
    const { clientWidth, clientHeight } = document.body;

    if (table.fbReservationTables.length > 0 && !selectedOptionButton) setIsTableBusy(true);
    else setIsTableBusy(false);

    if (selectedTableUUID === table.uuid) {
      setSelectedTableUUID(null);
      setSelectedTable(null);
      setShowFloatingGuests(false);
      setShowFloatingBusyTableOptions(false);
    } else if (selectedTableUUID && selectedTableUUID !== table.uuid && selectedOptionButton === 'transfer-table') {
      setSecondarySelectedTableUUID(table.uuid);
    } else if (selectedTableUUID && selectedTableUUID !== table.uuid && selectedOptionButton === 'merge-table') {
      setSecondarySelectedTableUUID(table.uuid);
    } else {
      setSelectedTableUUID(table.uuid);
      setSelectedTable(table);
      reservationContext.handleChangeReservationData('tableNumber', table.number);
      if (!isRoomEditable) {
        setFloatingBusyTableOptionsCoords({
          top: e.clientY + floatingBusyTableMenuSizes.height > clientHeight ? clientHeight - floatingBusyTableMenuSizes.height : e.clientY,
          left: e.clientX + floatingBusyTableMenuSizes.width > clientWidth ? clientWidth - floatingBusyTableMenuSizes.width : e.clientX,
          isSet: true,
        });
        if (!selectedGuestUUID && !selectedOptionButton && table.fbReservationTables.length <= 0) {
          setShowFloatingGuests(true);
          setFloatingGuestsCoords({
            top: e.clientY + floatingGuestsMenuSizes.height > clientHeight ? clientHeight - floatingGuestsMenuSizes.height : e.clientY,
            left: e.clientX + floatingGuestsMenuSizes.width > clientWidth ? clientWidth - floatingGuestsMenuSizes.width : e.clientX,
            isSet: true,
          });
        } else setShowFloatingBusyTableOptions(true);
      }
    }
  };

  const onChairClickHandler = (chair) => {
    if (showMessageBox) {
      if (!selectedChairUUID && !secondarySelectedChairUUID) {
        setSelectedChairUUID(chair.uuid);
        setShowMessageBox(false);
      }
    } else {
      setSelectedChairUUID(chair.uuid);
    }

    if (selectedChairUUID && selectedChairUUID !== chair.uuid && !secondarySelectedChairUUID) {
      setSecondarySelectedChairUUID(chair.uuid);
    }
  };

  //* GUESTS/GUEST-ITEM FUNCTIONS
  const onFloatingGuestItemClickHandler = (id) => {
    setShowFloatingGuests(false);
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>assign-table</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>assign-table-modal-text</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            deselectAll();
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent capitalize>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            setReservationTables({ variables: { fbTableUUIDs: [selectedTableUUID], fbReservationUUID: id } })
              .then(() => {
                updateAllQueries();
                deselectAll();
              })
              .catch((err) => {
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - '),
                });
                deselectAll();
              });
            deselectAll();
            modalContext.closeModal();
          }}
        >
          <TranslateTextComponent capitalize>confirm</TranslateTextComponent>
        </Button>,
      ],
    });
  };

  const onNewReservationButtonClickHandler = () => {
    setShowFloatingGuests(false);
    setShowNewReservationModal(true);
  };

  const onCloseNewReservationButtonClickHandler = () => {
    setShowNewReservationModal(false);
    deselectAll();
    reservationContext.reservationResetData();
  };

  const onNewReservationComplete = () => {
    setShowNewReservationModal(false);
    deselectAll();
    reservationContext.reservationResetData();
    updateAllQueries();
  };

  const onGuestItemAddButtonClickHandler = (id) => {
    setSelectedGuestUUID(id);
  };

  //* OPTIONS COMPONENT FUNCTIONS
  const onOptionButtonClickHandler = (selectedButton) => {
    setShowFloatingGuests(false);
    setShowFloatingBusyTableOptions(false);
    setSelectedOptionButton(selectedButton);
  };

  const optionsButtonsFunctionsHandler = () => {
    let title = '';
    let text = '';
    let confirm = null;
    let cancel = null;
    let showModalContext = true;

    switch (selectedOptionButton) {
      case 'merge-table':
        title = <TranslateTextComponent capitalize>merge-table-modal-title</TranslateTextComponent>;
        text = <TranslateTextComponent capitalize>merge-table-modal-text</TranslateTextComponent>;
        confirm = () =>
          mergeTables({ variables: { fbTableUUID1: selectedTableUUID, fbTableUUID2: secondarySelectedTableUUID } })
            .then(() => {
              updateAllQueries();
              deselectAll();
            })
            .catch((err) => {
              modalContext.openModal({
                class: 'danger',
                title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                text: getApolloErrors(err).join(' - '),
              });
              deselectAll();
            });
        cancel = () => {
          deselectAll();
        };
        break;
      case 'split-table':
        showModalContext = false;
        setShowSplitTableModal(true);
        break;
      case 'free-table':
        title = <TranslateTextComponent capitalize>free-table-modal-title</TranslateTextComponent>;
        text = <TranslateTextComponent capitalize>free-table-modal-text</TranslateTextComponent>;
        confirm = () =>
          freeTable({ variables: { fbTableUUID: selectedTableUUID } })
            .then(() => {
              updateAllQueries();
              deselectAll();
            })
            .catch((err) => {
              modalContext.openModal({
                class: 'danger',
                title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                text: getApolloErrors(err).join(' - '),
              });
              deselectAll();
            });
        cancel = () => {
          deselectAll();
        };
        break;
      case 'transfer-chair':
        title = <TranslateTextComponent capitalize>transfer-chair-modal-title</TranslateTextComponent>;
        text = <TranslateTextComponent capitalize>transfer-chair-modal-text</TranslateTextComponent>;
        confirm = () => {
          moveChair({
            variables: {
              fbChairUUIDFrom: selectedChairUUID,
              fbChairUUIDTo: secondarySelectedChairUUID,
            },
          })
            .then(() => {
              modalContext.openModal({
                class: 'success',
                title: <TranslateTextComponent capitalize>transfer-chair</TranslateTextComponent>,
                text: <TranslateTextComponent capitalize>transfer-ok</TranslateTextComponent>,
              });
              updateAllQueries();
              setSelectedChairUUID(null);
              setSecondarySelectedChairUUID(null);
              setShowMessageBox(false);
              setSelectedOptionButton(null);
            })
            .catch((err) => {
              modalContext.openModal({
                class: 'danger',
                title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                text: getApolloErrors(err).join(' - '),
              });
              setSelectedChairUUID(null);
              setShowMessageBox(false);
              setSecondarySelectedChairUUID(null);
              setSelectedOptionButton(null);
            });
        };
        cancel = () => {
          setSelectedChairUUID(null);
          setShowMessageBox(false);
          setSecondarySelectedChairUUID(null);
          setSelectedOptionButton(null);
        };
        break;
      case 'transfer-table':
        title = <TranslateTextComponent capitalize>transfer-table-modal-title</TranslateTextComponent>;
        text = <TranslateTextComponent capitalize>transfer-table-modal-text</TranslateTextComponent>;

        confirm = () => {
          moveTable({ variables: { fbTableUUIDFrom: selectedTableUUID, fbTableUUIDTo: secondarySelectedTableUUID } })
            .then(() => {
              modalContext.openModal({
                class: 'success',
                title: <TranslateTextComponent capitalize>transfer-table</TranslateTextComponent>,
                text: <TranslateTextComponent capitalize>transfer-ok</TranslateTextComponent>,
              });
              updateAllQueries();
              deselectAll();
            })
            .catch((err) => {
              modalContext.openModal({
                class: 'danger',
                title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                text: getApolloErrors(err).join(' - '),
              });
              deselectAll();
            });
        };
        cancel = () => {
          deselectAll();
        };
        break;
      case 'add-chair':
        showModalContext = false;
        setShowAddChairModal(true);
        break;
      case 'free-chair':
        title = <TranslateTextComponent capitalize>free-chair-modal-title</TranslateTextComponent>;
        text = <TranslateTextComponent capitalize>free-chair-modal-text</TranslateTextComponent>;
        confirm = () =>
          assignChair({ variables: { fbChairUUID: selectedChairUUID } })
            .then(() => {
              updateAllQueries();
              deselectAll();
            })
            .catch((err) => {
              modalContext.openModal({
                class: 'danger',
                title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                text: getApolloErrors(err).join(' - '),
              });
              deselectAll();
            });
        cancel = () => {
          deselectAll();
        };
        break;
      default:
        return;
    }

    if (showModalContext)
      modalContext.openModal({
        class: 'primary',
        title,
        text,
        actionButtons: [
          <Button
            key={0}
            variant="outlined"
            color="primary"
            className={modalClasses.invertedButton}
            onClick={() => {
              cancel();
              modalContext.closeModal();
              deselectAll();
            }}
          >
            <TranslateTextComponent capitalize>cancel</TranslateTextComponent>
          </Button>,
          <Button
            key={1}
            color="primary"
            variant="contained"
            className={modalClasses.buttonStyle}
            onClick={() => {
              confirm();
              modalContext.closeModal();
              deselectAll();
            }}
          >
            <TranslateTextComponent capitalize>confirm</TranslateTextComponent>
          </Button>,
        ],
      });
  };

  //* TABLES MANAGEMENT
  const onLayoutChangeHandler = (newLayout) => {
    setLayout([...newLayout]);
  };

  const onTableShift = (newLayout) => {
    if (isRoomEditable) {
      setShowButtons(true);
      const newRoomTables = roomTables;
      for (let i = 0; i < roomTables.length; i++) {
        for (let k = 0; k < newLayout.length; k++) {
          if (newRoomTables[i].id === parseInt(newLayout[k].i, 10)) {
            newRoomTables[i] = { ...newRoomTables[i], positionX: newLayout[k].x, positionY: newLayout[k].y };
          }
        }
      }
      setRoomTables([...newRoomTables]);
    }
  };

  const onTablesSaveHandler = () => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>room-save-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>room-save</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
            deselectAll();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            const tables = roomTables.map((roomTable) => omit(roomTable, ['__typename', 'fbReservationTables', 'fbChairs']));
            saveRoomTables({ variables: { fbRoomUUID: selectedTab, fbTables: tables } })
              .then(() => {
                setInitialLayout(layout);
                setIsRoomEditable(false);
                setShowButtons(false);
                deselectAll();
                updateAllQueries();
              })
              .catch((err) => {
                modalContext.openModal({
                  class: 'danger',
                  title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
                  text: getApolloErrors(err).join(' - '),
                });
              });
            modalContext.closeModal();
            deselectAll();
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>,
      ],
    });
  };

  const onTablesResetHandler = () => {
    modalContext.openModal({
      class: 'primary',
      title: <TranslateTextComponent capitalize>room-reset-title</TranslateTextComponent>,
      text: <TranslateTextComponent capitalize>room-reset</TranslateTextComponent>,
      actionButtons: [
        <Button
          key={0}
          variant="outlined"
          color="primary"
          className={modalClasses.invertedButton}
          onClick={() => {
            modalContext.closeModal();
            deselectAll();
          }}
        >
          <TranslateTextComponent uppercase>cancel</TranslateTextComponent>
        </Button>,
        <Button
          key={1}
          color="primary"
          variant="contained"
          className={modalClasses.buttonStyle}
          onClick={() => {
            setRoomTables([...initialRoomTables]);
            setLayout([...initialLayout]);
            setShowButtons(false);
            setIsRoomEditable(false);
            modalContext.closeModal();
            deselectAll();
          }}
        >
          <TranslateTextComponent uppercase>confirm</TranslateTextComponent>
        </Button>,
      ],
    });
  };

  const onSplitTableHandler = (value) => {
    splitTable({ variables: { fbTableUUID: selectedTableUUID, capacity1: value, capacity2: selectedTable.capacity - value } })
      .then(() => {
        updateAllQueries();
        deselectAll();
        closeModals();
      })
      .catch((err) => {
        modalContext.openModal({
          class: 'danger',
          title: <TranslateTextComponent capitalize>error</TranslateTextComponent>,
          text: getApolloErrors(err).join(' - '),
        });
        deselectAll();
        closeModals();
      });
  };

  const onAddChairHandler = (value) => {
    addChair({ variables: { fbTableUUID: selectedTableUUID, chairNumber: value } }).then(() => {
      updateAllQueries();
      deselectAll();
      closeModals();
    });
  };

  //* PLAY FB COURSE
  const getCourseUUID = () => {
    const fbOrder = typy(selectedTable, 'fbReservationTables[0].fbOrder').safeObject;
    const courseFound =
      fbOrder &&
      fbOrder.fbCourses
        .filter((fbC) => fbC.type === 'food')
        .sort((fbCa, fbCb) => fbCa.number - fbCb.number)
        .find((fbCourse) => fbCourse.fbCourseStatus === 'none');
    return courseFound && courseFound.uuid;
  };

  const onPlayFbCourse = () => {
    const courseUUID = getCourseUUID();
    if (courseUUID) {
      playFbCourse({ variables: { fbCourseUUID: courseUUID } })
        .then(() => {
          roomTablesRes.refetch();
          updateAllQueries();
        })
        .catch((err) => {
          modalContext.openModal({
            class: 'danger',
            title: <TranslateTextComponent>error</TranslateTextComponent>,
            text: getApolloErrors(err).join(' - '),
          });
        })
        .finally(() => deselectAll());
    } else {
      deselectAll();
    }
  };

  //* GENERIC FUNCTIONS
  const getMessageBoxLabel = () => {
    let label = '';

    if (selectedOptionButton === 'merge-table') {
      if (!selectedTableUUID) label = translate('merge-table-choose', language);
      else label = translate('second-merge-table-choose', language);
    } else if (selectedOptionButton === 'transfer-table') {
      if (!selectedTableUUID) label = translate('transfer-table-choose', language);
      else label = translate('second-transfer-table-choose', language);
    } else if (selectedOptionButton === 'transfer-chair') {
      if (!selectedChairUUID) label = translate('transfer-chair-choose', language);
      else label = translate('second-transfer-chair-choose', language);
    }

    return label;
  };

  const closeModals = () => {
    setShowSplitTableModal(false);
    setShowAddChairModal(false);
    deselectAll();
  };

  const deselectAll = () => {
    setSelectedTableUUID(null);
    setSecondarySelectedTableUUID(null);
    setSelectedTable(null);
    setSelectedOptionButton(null);
    setSelectedChairUUID(null);
    setSecondarySelectedChairUUID(null);
  };

  const updateAllQueries = () => {
    roomsRes.refetch();
    reservationsRes.refetch();
    roomTablesRes.refetch();
  };

  useEffect(() => {
    ref.current.addEventListener(
      'touchmove',
      function (e) {
        e.preventDefault();
      },
      false
    );

    return () =>
      ref.current.removeEventListener(
        'touchmove',
        function (e) {
          e.preventDefault();
        },
        false
      );
  }, []);

  return (
    <Box className={layoutClasses.layoutContainer}>
      <Box className={layoutClasses.leftSide} style={{ maxWidth: 310, paddingRight: 5 }}>
        <Box className={classes.leftContainer}>
          <Dropdown
            dropdownStyle={{ gridArea: 'services-dropdown', marginRight: 10 }}
            value={selectedServiceUUID}
            onChange={(e) => {
              setSelectedServiceUUID(e.target.value);
            }}
            items={dropdownServiceItems}
          />
          <Guests guests={guestsList} selectedGuestUUID={selectedGuestUUID} onGuestItemAddButtonClickHandler={onGuestItemAddButtonClickHandler} />
          <Options selectedOptionButton={selectedOptionButton} onOptionButtonClick={onOptionButtonClickHandler} />
        </Box>
      </Box>
      <Box ref={ref} className={layoutClasses.rightSide}>
        <Box className={classes.rightContainer}>
          {!isRoomEditable && (
            <Box style={{ position: 'absolute', bottom: 10, zIndex: 1, width: 50, marginLeft: 10 }}>
              <IconButton className={classes.panZoomButton} style={{ marginBottom: 5 }} onClick={() => panZoomEl.current.zoomTo(20, 20, 1.25)}>
                <Icon>zoom_in_icon</Icon>
              </IconButton>
              <IconButton className={classes.panZoomButton} onClick={() => panZoomEl.current.zoomTo(20, 20, 0.8)}>
                <Icon>zoom_out_icon</Icon>
              </IconButton>
            </Box>
          )}

          {!isRoomEditable && (
            <Box style={{ position: 'absolute', zIndex: 1, bottom: isRoomEditable ? 70 : 10, right: 50 }}>
              <Box style={{ position: 'relative' }}>
                <IconButton
                  className={classes.panZoomButton}
                  style={{ position: 'absolute', bottom: 40, right: 40 }}
                  onClick={() => panZoomEl.current.moveByRatio(0.25, 0, 1)}
                >
                  <Icon>arrow_left_icon</Icon>
                </IconButton>
                <IconButton
                  className={classes.panZoomButton}
                  style={{ position: 'absolute', bottom: 80, right: 0 }}
                  onClick={() => panZoomEl.current.moveByRatio(0, 0.25, 1)}
                >
                  <Icon>arrow_drop_up_icon</Icon>
                </IconButton>
                <IconButton
                  className={classes.panZoomButton}
                  style={{ position: 'absolute', bottom: 0, right: 0 }}
                  onClick={() => panZoomEl.current.moveByRatio(0, -0.25, 1)}
                >
                  <Icon>arrow_drop_down_icon</Icon>
                </IconButton>

                <IconButton
                  className={classes.panZoomButton}
                  style={{ position: 'absolute', bottom: 40, right: -40 }}
                  onClick={() => panZoomEl.current.moveByRatio(-0.25, 0, 1)}
                >
                  <Icon>arrow_right_icon</Icon>
                </IconButton>
              </Box>
            </Box>
          )}
          {!isTableBusy && !selectedGuestUUID && selectedTableUUID && showFloatingGuests && (
            <ClickAwayListener onClickAway={() => deselectAll()}>
              <Box>
                <FloatingGuests
                  guests={guestsList}
                  coords={floatingGuestsCoords}
                  showNewReservationModal={showNewReservationModal}
                  onAssignFastFbTable={onAssignFastFbTable}
                  onGuestItemClick={onFloatingGuestItemClickHandler}
                  onNewReservationButtonClick={onNewReservationButtonClickHandler}
                  onCloseNewReservationButtonClick={onCloseNewReservationButtonClickHandler}
                />
              </Box>
            </ClickAwayListener>
          )}

          {isTableBusy && !selectedGuestUUID && selectedTableUUID && showFloatingBusyTableOptions && (
            <ClickAwayListener onClickAway={() => deselectAll()}>
              <Box>
                <FloatingBusyTableOptions table={selectedTable} coords={floatingBusyTableOptionsCoords} onPlayFbCourse={onPlayFbCourse} />
              </Box>
            </ClickAwayListener>
          )}

          <Tabs
            tabsRooms={tabsRooms}
            selectedTab={selectedTab}
            onRoomTableEditButtonClick={onRoomTableEditButtonClickHandler}
            onTabClickHandler={onTabClickHandler}
          />

          {showMessageBox && MULTI_SELECT_FUNCTIONS.includes(selectedOptionButton) && (
            <Grid container className={selectedTableUUID || selectedChairUUID ? classes.secondGridMsgBox : classes.gridMsgBox}>
              <Typography className={selectedTableUUID || selectedChairUUID ? classes.SecExclamationPoint : classes.exclamationPoint}>!</Typography>
              <Typography className={classes.selectFirstTxt}>
                <TranslateTextComponent capitalize>select</TranslateTextComponent>
              </Typography>
              <Typography className={classes.secondChair}>{getMessageBoxLabel()}</Typography>
            </Grid>
          )}

          {selectedTab !== 'roomService' ? (
            <PanZoom
              realPinch
              disabled={isRoomEditable}
              ref={panZoomEl}
              className="panzoom"
              style={{ overflow: isRoomEditable ? 'scroll' : 'hidden' }}
              minZoom={0.5}
              maxZoom={3}
              enableBoundingBox
              disableDoubleClickZoom={isRoomEditable}
            >
              <Tables
                isGuestNameShowed={isGuestNameShowed}
                layout={layout}
                roomTables={roomTables}
                selectedTableUUID={selectedTableUUID}
                selectedOptionButton={selectedOptionButton}
                isRoomEditable={isRoomEditable}
                onLayoutChangeHandler={onLayoutChangeHandler}
                onTableClickHandler={onTableClickHandler}
                onChairClickHandler={onChairClickHandler}
                onTableShift={onTableShift}
              />
            </PanZoom>
          ) : (
            <RoomService />
          )}
          {showNewReservationModal && (
            <CustomModal
              header={<TranslateTextComponent>new-reservation</TranslateTextComponent>}
              styledHeader
              styleBody={{ overflowY: 'scroll' }}
              height="80vh"
              width="auto"
              showModal={showNewReservationModal}
              onClose={onCloseNewReservationButtonClickHandler}
            >
              <FastReservation
                table={selectedTable}
                onNewReservationComplete={onNewReservationComplete}
                onCloseReservationModal={onCloseNewReservationButtonClickHandler}
              />
            </CustomModal>
          )}
          {showButtons && (
            <Box className={classes.buttonsContainer}>
              <Button className={classes.button} variant="contained" size="medium" onClick={onTablesResetHandler}>
                <RestoreIcon className={classes.buttonIcon} />
                <TranslateTextComponent uppercase>reset</TranslateTextComponent>
              </Button>
              <Button className={classes.button} variant="contained" size="medium" color="primary" onClick={onTablesSaveHandler}>
                <SaveIcon className={classes.buttonIcon} />
                <TranslateTextComponent uppercase>save</TranslateTextComponent>
              </Button>
            </Box>
          )}
          {showSplitTableModal && (
            <SplitTableModal
              showSplitTableModal={showSplitTableModal}
              closeModals={closeModals}
              table={selectedTable}
              splitTable={onSplitTableHandler}
            />
          )}
          {showAddChairModal && <AddChairModal showAddChairModal={showAddChairModal} closeModals={closeModals} addChair={onAddChairHandler} />}
        </Box>
      </Box>
    </Box>
  );
};

export default ViewTablesContainer;
