import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import debounce from 'lodash/debounce';
import moment from 'moment';
import typy from 'typy';
import layoutStyle from '../../styles/leftSidebarLayout/leftSidebarLayoutStyle';
import { AuthContext } from '../../contexts/AuthContext';
import Tabs from './Tabs/Tabs';
import Filters from './Filters';
import getTablesColumns from '../../constants/recordsContainer/tablesColumns';
import getReservationsColumns from '../../constants/recordsContainer/reservationsColumns';
import { GET_HISTORICAL_RESERVATIONS, GET_HISTORICAL_TABLES } from '../../graphql/reservations/queries';
import TableDetail from './TableDetail/TableDetail';
import ReservationDetail from './TableDetail/ReservationDetail';
import Orders from './Orders/Orders';
import { CommonTable } from '../../common-fe';
import { TranslateTextComponent } from '../../translator';
import useCustomQuery from '../../hooks/useCustomQuery';

const initialFilters = {
  orderBy: 'fbReservation.reservationTime',
  orderType: 'DESC',
  date: null,
  page: 1,
  pageSize: 10,
  keyword: ''
};

const RecordsContainer = () => {
  const layoutClasses = layoutStyle();
  const { appOutletUUID } = useContext(AuthContext);
  const [tableFilters, setTableFilters] = useState({ ...initialFilters, fbOutletUUID: appOutletUUID });
  const [reservationsFilters, setReservationsFilters] = useState({ ...initialFilters, fbOutletUUID: appOutletUUID });
  const [selectedTab, setSelectedTab] = useState('tables');
  const [date, setDate] = useState(null);
  const [service, setService] = useState(null);

  useEffect(() => {
    if (selectedTab === 'tables') {
      setTableFilters({ ...initialFilters, fbOutletUUID: appOutletUUID });
      historicalTablesQuery.refetch().catch((e) => console.log(e));
    } else {
      setReservationsFilters({ ...initialFilters, fbOutletUUID: appOutletUUID });
      historicalReservationsQuery.refetch().catch((e) => console.log(e));
    }
  }, [selectedTab]);

  useEffect(() => {
    if (date !== null) {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      setNewFilter('date', formattedDate || null);
    } else setNewFilter('date', null);
  }, [date]);

  useEffect(() => {
    setNewFilter('fbServiceUUID', service);
  }, [service]);

  const historicalTablesQuery = useCustomQuery(GET_HISTORICAL_TABLES, {
    variables: {
      ...tableFilters
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: selectedTab !== 'tables'
  });

  const historicalReservationsQuery = useCustomQuery(GET_HISTORICAL_RESERVATIONS, {
    variables: {
      ...reservationsFilters
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: selectedTab !== 'reservations'
  });

  const setOrderType = (orderBy, orderType) => {
    if (selectedTab === 'tables') {
      const isDesc = tableFilters.orderBy === orderBy && orderType === 'desc';
      return isDesc ? 'ASC' : 'DESC';
    }
    const isDesc = reservationsFilters.orderBy === orderBy && orderType === 'desc';
    return isDesc ? 'ASC' : 'DESC';
  };

  const setTableSort = (orderBy, orderType) => {
    if (selectedTab === 'tables') {
      setTableFilters({
        ...tableFilters,
        orderBy,
        orderType: setOrderType(orderBy, orderType)
      });
    } else {
      setReservationsFilters({
        ...reservationsFilters,
        orderBy,
        orderType: setOrderType(orderBy, orderType)
      });
    }
  };

  const setNewFilter = (key, value) => {
    if (selectedTab === 'tables') {
      setTableFilters({
        ...tableFilters,
        page: 1,
        [key]: value || null
      });
    } else {
      setReservationsFilters({
        ...reservationsFilters,
        page: 1,
        [key]: value || null
      });
    }
  };

  const setTablePages = (page, pageSize) => {
    if (selectedTab === 'tables') {
      setTableFilters({
        ...tableFilters,
        page,
        pageSize
      });
    } else {
      setReservationsFilters({
        ...reservationsFilters,
        page,
        pageSize
      });
    }
  };

  const onDateChangeHandler = (value) => {
    if (value !== null) setDate(value);
    else setDate(null);
  };

  const onServiceChangeHandler = (value) => {
    setService(value);
  };

  const onValueChangeHandler = debounce((e) => {
    const { value } = e.target;
    if (value && value.trim().length > 0) {
      setNewFilter('keyword', value);
    } else {
      setNewFilter('keyword', '');
    }
  }, 1000);

  const onTabChangeHandler = (tabName) => {
    setSelectedTab(tabName);
  };

  const tablesColumns = getTablesColumns();
  const reservationsColumns = getReservationsColumns();

  return (
    <Box className={layoutClasses.layoutContainer}>
      <Box className={layoutClasses.rightSide} style={{ marginRight: 20 }}>
        <Tabs selectedTab={selectedTab} changeTab={onTabChangeHandler} />
        <Filters
          selectedTab={selectedTab}
          date={date}
          service={service}
          apolloQuery={selectedTab === 'tables' ? historicalTablesQuery : historicalReservationsQuery}
          onDateChange={onDateChangeHandler}
          onServiceChange={onServiceChangeHandler}
          onValueChange={onValueChangeHandler}
        />
        {selectedTab === 'tables' ? (
          <CommonTable
            isLoading={historicalTablesQuery.loading}
            key={1}
            page={tableFilters.page}
            pageSize={tableFilters.pageSize}
            setTablePages={setTablePages}
            orderType={tableFilters.orderType.toLocaleLowerCase()}
            orderBy={tableFilters.orderBy}
            setTableSort={setTableSort}
            totalRows={typy(historicalTablesQuery, 'data.getFbHistoricalTables.totalCount').safeNumber}
            items={typy(historicalTablesQuery, 'data.getFbHistoricalTables.data').safeArray}
            isPaginationEnabled
            tableHeader={
              <Typography
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#D9EDF6',
                  minHeight: 28
                }}
              >
                <TranslateTextComponent capitalize>max-covers</TranslateTextComponent>
                {': '}
                {typy(historicalTablesQuery, 'data.getFbHistoricalTables.totalCount').safeNumber}
              </Typography>
            }
            columns={tablesColumns}
            renderDetailsComponent={[
              {
                showIf: (row) => row.fbReservationTables && row.fbReservationTables[0] && row.fbReservationTables[0].fbOrder,
                buttonLabel: <TranslateTextComponent capitalize>order</TranslateTextComponent>,
                renderFunction: (fbTable) => {
                  return <Orders fbTable={fbTable} fbReservationsList={typy(historicalTablesQuery, 'data.getFbHistoricalTables.data').safeArray} />;
                }
              },
              {
                buttonLabel: <TranslateTextComponent capitalize>details</TranslateTextComponent>,
                renderFunction: (row) => {
                  return <TableDetail fbReservation={typy(row, 'fbReservationTables[0].fbReservation').safeObject} />;
                }
              }
            ]}
          />
        ) : (
          <CommonTable
            isLoading={historicalReservationsQuery.loading}
            key={2}
            page={reservationsFilters.page}
            pageSize={reservationsFilters.pageSize}
            setTablePages={setTablePages}
            orderType={reservationsFilters.orderType.toLocaleLowerCase()}
            orderBy={reservationsFilters.orderBy}
            setTableSort={setTableSort}
            totalRows={typy(historicalReservationsQuery, 'data.getFbHistoricalReservations.totalCount').safeNumber}
            items={typy(historicalReservationsQuery, 'data.getFbHistoricalReservations.data').safeArray}
            isPaginationEnabled
            tableHeader={
              <Typography
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#D9EDF6',
                  minHeight: 28
                }}
              >
                <TranslateTextComponent capitalize>max-covers</TranslateTextComponent>
                {': '}
                {typy(historicalReservationsQuery, 'data.getFbHistoricalReservations.totalCount').safeNumber}
              </Typography>
            }
            columns={reservationsColumns}
            renderDetailsComponent={[
              {
                showIf: (row) => row.fbReservationTables && row.fbReservationTables[0] && row.fbReservationTables[0].fbOrder,
                buttonLabel: <TranslateTextComponent capitalize>order</TranslateTextComponent>,
                renderFunction: (fbTable) => {
                  return (
                    <Orders
                      fbTable={fbTable}
                      fbReservationsList={typy(historicalReservationsQuery, 'data.getFbHistoricalReservations.data').safeArray}
                    />
                  );
                }
              },
              {
                buttonLabel: <TranslateTextComponent capitalize>details</TranslateTextComponent>,
                renderFunction: (row) => {
                  return <ReservationDetail fbReservation={row} />;
                }
              }
            ]}
          />
        )}
      </Box>
    </Box>
  );
};

export default RecordsContainer;
